﻿import { Inject, Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import * as _ from "lodash";
import { Config } from "./config.service";
import { WINDOW, WindowTokenModule } from "./window.service";
import { LoggerService } from "@core/services/logger.service";
import { Subject } from "rxjs";

@Injectable()
export class ToolsService implements OnDestroy {
    private destroy$ = new Subject<void>();
    constructor(@Inject(WINDOW) private window: Window, private config: Config, private router: Router, private log: LoggerService) {

        // log router events
        this.router.events.filter(event => event instanceof NavigationStart).subscribe((event: NavigationStart) => {
            this.log.debug("nav to " + event.url);
        });
    }

    public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }

    public buildUrl(service: string, entity: any, id: any, query: any): string {
        let url: string = "";
        let host: string = service;

        if (!host.endsWith("/")) {
            host += "/";
        }

        url += entity;

        if (id != null) {
            url += "/" + id;
        }

        if (query != null) {
            if (_.isString(query)) {
                url += query;
            } else if (_.isObject(query)) {
                let idx = 0;
                _.each(query, (val, name) => {
                    url += ((idx++ == 0) ? "?" : "&") + name + "=" + val;
                });
            }
        }
        return host + url;
    }

    public buildApiUrl(service: string, path: string): string {
        return service + "/" + path;
    }

    public buildSiteUrl(service: string, url: string): string {
        return url.replace("~", (service == "/") ? "" : service);
    }

    public extractHost(url: string): string {
        let hostname;
        if (url.indexOf("://") > -1) {
            hostname = url.split("/")[2];
        } else {
            hostname = url.split("/")[0];
        }
        hostname = hostname.split("?")[0];
        return hostname;
    }

    public appendUrlParam(url: string, key: string, val: string): string {
        const sep: string = (url.indexOf("?") > -1) ? "&" : "?";
        return url + key + "=" + encodeURIComponent(val);
    }

    public showErrorWarningDialog(title: string, message: any, callback: () => void): void {
        window.alert(title);
        if (callback) {
            callback();
        }
        return;
    }

    public sendToLoginPage(): void {

        const exclusions: string[] = [
            "Login",
            "login",
            "ForgotPassword",
            "PasswordResetEmail",
            "ExpiredPassword",
            "LogoutSSO",
            "SecurityLockedUser",
            "test",     //exclude temporarily
            "conduct",   //exclude temporarily
        ];

        const path: string = window.location.pathname + window.location.hash;
        let excluded: boolean = false;
        _.each(exclusions, (val: string) => {
            if (path.indexOf(val) > 0) {
                excluded = true;
            }
        });

        if (!excluded) {
            this.router.navigate(["login"]);
        }
    }

    public sendToOfflinePage(site: string, message: string): void {
        window.location.href = this.buildSiteUrl(site, "~/app_offline_holder.htm?message=" + encodeURIComponent(message));
    }

    public navigateTo(url: string) {
        this.router.navigate([url], { queryParamsHandling: "preserve", preserveFragment: true });
    }

    public isCordova(): boolean {
        const w = window as any;
        return w.cordova !== undefined && (/msapphost|ios|iphone|ipod|ipad|android/i).test(navigator.userAgent);
    }

    public isOnline(): boolean {
        return navigator.onLine;
    }

    // detect if running in application or browser (application = true, browser = false)
    public isApp(): boolean {
        return document.URL.indexOf("http://") === -1 && document.URL.indexOf("https://") === -1;
    }

    public isProdMode(): boolean {
        return process.env.NODE_ENV === "production" && this.isApp();
    }

    public hideSplashScreen() {
        try {
            const nav: any = window.navigator;
            nav.splashscreen.hide();
        } catch (e) {
        }
    }

}
