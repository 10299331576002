// Immer
import produce from "immer";

// Ramda
import { map } from "ramda";

// Material
import { SortDirection } from "@angular/material";

import {
  UsersActionTypes,
  UsersActionsUnion,
} from "./../actions/users.actions";

export interface State {
  loading: boolean;
  entities: any[];
  totalRows: number;
  sortActive: string;
  sortDirection: SortDirection;
  pageSize: number;
  pageIndex: number;
  filter: string;
}

const initialState: State = {
  loading: false,
  entities: [],
  totalRows: 0,
  pageSize: 10,
  pageIndex: 0,
  sortActive: "CreationDate",
  sortDirection: "desc",
  filter: "",
};

export function reducer(
  state = initialState,
  action: UsersActionsUnion,
): State {
  switch (action.type) {
    case UsersActionTypes.OptionsChanges: {
      return {
        ...state,
        pageSize: action.payload.pageSize,
        pageIndex: action.payload.pageIndex,
        sortActive:  action.payload.sortActive,
        sortDirection: action.payload.sortDirection,
        filter: action.payload.filter,
      };
    }

    case UsersActionTypes.Fetch: {
      return {
        ...state,
        loading: true,
      };
    }

    case UsersActionTypes.FetchSuccess: {
      return {
        ...state,
        loading: false,
        entities: map(entity => {
          return produce(entity, draft => {
            draft.RolesString = (draft.Roles || []).join(", ");
          });
        })(action.payload.Result),
        totalRows: action.payload.TotalRows,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;
export const getEntities = (state: State) => state.entities;
export const getTotalRows = (state: State) => state.totalRows;

export const getPageSize = (state: State) => state.pageSize;
export const getPageIndex = (state: State) => state.pageIndex;
export const getSortActive = (state: State) => state.sortActive;
export const getSortDirection = (state: State) => state.sortDirection;
export const getFilter = (state: State) => state.filter;
