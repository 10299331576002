import { Component, forwardRef, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatInput } from "@angular/material";
import { LocalizeService } from "@core/services";

@Component({
  selector: "ease-inline-edit",
  template: `
   <ng-container *ngIf="isInEditMode">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field color="accent" style="width: 200px;">
        <input [(ngModel)]="value"
               #taskEditText
               name="formValue" autocomplete="false"
               matInput required placeholder="{{ label | i18n}}"
               maxlength="100" autocomplete="off"
               (click)="inputClickHandler($event)"
               (change)="changeHandler(value)"
               (keydown)="keyDownHandler($event)">
      </mat-form-field>
      <button class="mat-icon-button"
              (click)="saveChangesHandler($event)">
        <mat-icon style="color: green">done</mat-icon>
      </button>
      <button class="mat-icon-button"
              (click)="cancelChangesHandler($event)">
        <mat-icon style="color: red">close</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!isInEditMode">
    <h4>
      {{ value | i18n }}
      <button class="mat-icon-button"
              (click)="initEditHandler($event)"
              *ngIf="canEdit">
        <mat-icon color="primary">edit</mat-icon>
      </button>
    </h4>
  </ng-container>
`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InlineEditComponent),
    },
  ],
})

export class InlineEditComponent implements ControlValueAccessor {

  @Input() public canEdit: boolean = true;
  @Output() public saveClicked = new EventEmitter<string>();
  @ViewChild("taskEditText") public taskEditText: MatInput;


  public isInEditMode = false;
  public value: string;
  private initialValue: string;

  public onChange: any = () => {};
  public onTouched: any = () => {};

  constructor(private localize: LocalizeService) {
  }

  public writeValue(value: string) {
    if (value) {
      this.value = this.localize.getLocalizedString(value);
      this.initialValue = this.value;
    }
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  public changeHandler(val) {
    this.onChange(val);
  }

  public initEditHandler($event) {
    this.isInEditMode = true;

    if (this.taskEditText) {
      this.taskEditText.focus();
    }
    $event.stopPropagation();
  }

  public saveChangesHandler($event) {
    this.isInEditMode = false;
    this.saveClicked.emit(this.value);
    $event.stopPropagation();
  }

  public cancelChangesHandler($event) {
    this.value = this.initialValue;
    this.isInEditMode = false;
    $event.stopPropagation();
  }

  public inputClickHandler($event) {
    $event.stopPropagation();
  }

  public keyDownHandler($event) {
    $event.stopPropagation();
    if ($event.keyCode === 13) {
      this.saveChangesHandler($event);
    }
  }
}
