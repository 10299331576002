import { AuthActionTypes, AuthActionsUnion } from "../actions/auth";

export interface State {
  error: string[] | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {
    case AuthActionTypes.RequestRestore: {
      return {
        ...state,
        pending: true,
      };
    }

    case AuthActionTypes.RequestRestoreSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case AuthActionTypes.RequestRestoreFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;
