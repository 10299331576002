import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { CollectionActionsUnion, CollectionActionTypes } from "../actions/collection.actions";
import { PermissionGroupActionTypes, PermissionGroupActionUnion } from "../actions/permission-goup.actions";

import { PermissionGroup } from "@app/admin/permission-groups/models/permission-group";

export interface State extends EntityState<PermissionGroup> {
  selectedRoleId: string | null;
}

export const adapter: EntityAdapter<PermissionGroup> = createEntityAdapter<PermissionGroup>({
  selectId: (role: PermissionGroup) => role.Id,
});

export const initialState: State = adapter.getInitialState({
  selectedRoleId: null,
});

export function reducer(
  state = initialState,
  action: PermissionGroupActionUnion | CollectionActionsUnion,
): State {
  switch (action.type) {
    case PermissionGroupActionTypes.Load: {
      return adapter.addOne(action.payload, {
        ...state,
        selectedRoleId: state.selectedRoleId,
        });
    }
    case CollectionActionTypes.LoadSuccess: {
      return adapter.upsertMany(action.payload, {
        ...state,
        selectedRoleId: state.selectedRoleId,
      });
    }
    case PermissionGroupActionTypes.Select: {
      return {
        ...state,
        selectedRoleId: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
