import { Action } from "@ngrx/store";

// Models
import {
  Release,
} from "../models/release";

export enum ReleaseCollectionActionTypes {
  Load = "[Release] Load Collection",
  LoadSuccess = "[Release] Load Collection Success",
  LoadFail = "[Release] Load Collection Fail",

}

export class Load implements Action {
  readonly type = ReleaseCollectionActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = ReleaseCollectionActionTypes.LoadSuccess;

  constructor(public payload: Release[]) {

  }
}

export class LoadFail implements Action {
  readonly type = ReleaseCollectionActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

export type ReleaseCollectionActionUnion =
  | Load
  | LoadSuccess
  | LoadFail;
