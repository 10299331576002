import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from "@ngrx/store";

import * as fromReleaseCollection from "./collection.reducer";
import * as fromReleaseDetail from "./release-detail.reducer";
import * as fromRoot from "app/reducers";

export interface ReleasesState {
  collection: fromReleaseCollection.State;
  details: fromReleaseDetail.State;
}

export interface State extends fromRoot.State {
  releasesState: ReleasesState;
}

export const reducers: ActionReducerMap<ReleasesState> = {
  collection: fromReleaseCollection.reducer,
  details: fromReleaseDetail.reducer,
};

export const getReleaseState = createFeatureSelector<ReleasesState>("system-admin-releases");

// Collection
export const getCollectionState = createSelector(
  getReleaseState,
  (state: ReleasesState) => state.collection,
);

export const getReleaseCollectionLoading = createSelector(
  getCollectionState,
  fromReleaseCollection.getLoading,
);

export const getReleaseCollection = createSelector(
  getCollectionState,
  fromReleaseCollection.getReleaseCollection,
);

// Details
export const getReleaseDetailState = createSelector(
  getReleaseState,
  (state: ReleasesState) => state.details,
);

export const getDetailState = createSelector(
  getReleaseDetailState,
  fromReleaseDetail.getReleaseDetail,
);

export const getReleaseDetailId = createSelector(
  getReleaseDetailState,
  fromReleaseDetail.getReleaseDetailId,
);

export const getReleaseName = createSelector(
  getReleaseDetailState,
  fromReleaseDetail.getReleaseName,
)
