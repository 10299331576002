import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  templateUrl: "./audit-history.component.html",
})

export class AuditHistoryComponent implements OnInit {

  public entity: string;
  public entityID: number;

  constructor(private activateRoute: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.entity = "Audit";
    this.activateRoute.params.subscribe((params: Params) => {
      this.entityID = params.id;
    });
  }
}
