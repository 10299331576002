import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Services
import { AuthGuard } from "app/auth/services/auth-guard.service";

// Components
import { WorkScheduleCalendarComponent } from "./calendar/work-schedule-calendar.component";
import { MyAccountComponent } from "./myAccount/myAccount.component";
import { UserProfileComponent } from "./profile/userprofile.component";
import { AuditHistoryListComponent } from "./auditHistory/auditHistory.component";
import { BreadcrumbUserCalendar } from "@core/components/breadcrumbs/breadcrumb.class";

const routes: Routes = [
  {
    path: "account/profile",
    component: UserProfileComponent,
    canActivate: [ AuthGuard ],
    data: { stripType: "", breadcrumb: "_BreadcrumbAccountProfile_" },
  },
  {
    path: "account/myAccount",
    component: MyAccountComponent,
    canActivate: [ AuthGuard ],
    data: { stripType: "", breadcrumb: "_BreadcrumbMyAccount_" },
  },
  {
    path: "account/auditHistory",
    component: AuditHistoryListComponent,
    canActivate: [ AuthGuard ],
    data: { stripType: "home", breadcrumb: "_BreadcrumbHistory_" },
  },
  {
    path: "calendar/auditor/:id",
    component: WorkScheduleCalendarComponent,
    canActivate: [ AuthGuard ],
    data: { stripType: "home", breadcrumb: new BreadcrumbUserCalendar() },
  },

];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
  providers: [],
})

export class AccountRoutingModule {

}
