
import {
  ReleaseCollectionActionTypes,
  ReleaseCollectionActionUnion,
} from "../actions/collection.actions";

import { Release} from "../models/release";

export interface State {
  loading: boolean;
  data: Release[];
}

const initialState: State = {
  loading: false,
  data: [],
};

export function reducer(
  state = initialState,
  action: ReleaseCollectionActionUnion,
): State {
  switch (action.type) {

    case ReleaseCollectionActionTypes.Load: {
      return {
        ...state,
        loading: true,
      };
    }

    case ReleaseCollectionActionTypes.LoadSuccess: {
      const payload = action.payload;
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }

    case ReleaseCollectionActionTypes.LoadFail: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;
export const getReleaseCollection = (state: State) => state.data;
