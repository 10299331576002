import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService } from "@core/services/logger.service";
import { Subject } from "rxjs";
import { AuditDetailService, MitigationArtifactService, MitigationDetailService } from "@core/services/common";
import { NotifyService, CurrentInfoService, ToolsService, StorageKey, StorageService } from "@core/services";
import { HomeService } from "../services/home.service";
import { UiNotificationService } from "@core/services/common/ui-notification.service";
import {
  EaseMitigationStatus,
  IAuditDetail,
  IMitigationDetail,
  IUserActivity,
} from "@core/models/ease-models";
import { ObservableMedia } from "@angular/flex-layout";

import * as _ from "lodash";

@Component({
  templateUrl: "./home.html",
  styleUrls: ["./home.scss"],
})

export class HomeComponent implements OnInit, OnDestroy {
  public activityType: string = "current-user";

  public currentUser: any[];
  public userActivities: IUserActivity[] = [];
  public auditDetails: IAuditDetail[] = [];
  public mitigationDetails: IMitigationDetail[] = [];
  public allMitigationDetails: IMitigationDetail[] = [];
  public selectedAudit: IAuditDetail = null;
  public isLoading: boolean = true;
  public isSummaryOnly: boolean = false;
  public activityClicked: boolean = false;
  public displayReportPanels: boolean = false;

  private subscription: Subject<void> = new Subject<void>();

  constructor(private router: Router,
              private notify: NotifyService,
              private log: LoggerService,
              private current: CurrentInfoService,
              private tools: ToolsService,
              private storage: StorageService,
              private auditDetailService: AuditDetailService,
              private route: ActivatedRoute,
              private zone: NgZone,
              private homeService: HomeService,
              private mitigationDetailService: MitigationDetailService,
              private mitigationArtifactService: MitigationArtifactService,
              private uiNotify: UiNotificationService,
              private media: ObservableMedia) {
  }

  public ngOnInit(): void {
    this.onCurrentInfoReady();
    this.media.asObservable()
      .subscribe(() => {
        this.checkDisplayReportPanels();
      });
  }

  public onCurrentInfoReady() {
    this.log.debug("home: current info is ready...");
    this.notify.broadcast("full-page-block", true);
    this.isLoading = true;
    this.isSummaryOnly = !this.tools.isApp();
    this.checkDisplayReportPanels();

    this.homeService.getHomeActivity(this.activityType, this.isSummaryOnly).subscribe((data: any) => {

      if (this.isSummaryOnly) {
        this.userActivities = data;
        this.notify.broadcast("full-page-block", false);
        this.isLoading = false;

      } else {
        this.zone.run(() => {
          const snackBarRef = this.uiNotify.open("_LoadingMyActivity_", true);
          snackBarRef.afterOpened().subscribe(() => {
            const activities: IUserActivity[] = data.activities;
            const auditDetails: IAuditDetail[] = data.auditDetails;
            this.allMitigationDetails = data.mitigationDetails;
            let mitigationDetails: IMitigationDetail[] = _.cloneDeep(this.allMitigationDetails);    // create new object mitigation details;
            mitigationDetails = mitigationDetails.filter(x => (x.Mitigation.Status === EaseMitigationStatus.Pending && x.Mitigation.ApprovingManagerUserID == this.current.info.user.ID) || (x.Mitigation.Status == EaseMitigationStatus.Open && x.Mitigation.ResponsiblePartyUserID == this.current.info.user.ID));

            let filteredActivities: IUserActivity[] = []; // without offline submitted audits/mitigations

            let syncedData: boolean = false;
            let syncedAuditArtifacts: boolean = false;
            this.auditDetailService.syncAuditAnswerArtifacts(auditDetails).subscribe((artifacts: any) => {
              // remove offline delete answer artifacts
              const artifactsDeleted = artifacts.artifactsDeleted;
              const artifactsAdded = artifacts.artifactsAdded;
              _.each(auditDetails, (auditDetail: IAuditDetail) => {
                if (artifactsAdded && artifactsAdded.length > 0) {
                  for (const item of artifactsAdded) {
                    if (auditDetail.Audit.ID === item.auditID) {
                      auditDetail.AuditAnswerArtifacts = item.auditanswerartifacts;
                      this.writeArtifacts(auditDetail.AuditAnswerArtifacts, "AnswerArtifact");
                      syncedAuditArtifacts = true;
                    }
                  }
                }

                if (artifactsDeleted && artifactsDeleted.length > 0) {
                  for (const item of artifactsDeleted) {
                    if (auditDetail.Audit.ID === item.AuditID) {
                      const filtered = auditDetail.AuditAnswerArtifacts.filter(aa => aa.ID === item.Artifact.ID);
                      if (filtered && filtered.length > 0) {
                        const index = auditDetail.AuditAnswerArtifacts.indexOf(filtered[0]);
                        auditDetail.AuditAnswerArtifacts.splice(index, 1);
                        syncedAuditArtifacts = true;
                      }
                    }
                  }
                }
              });
              this.log.debug("home: audit artifacts synced: " + syncedAuditArtifacts);

              this.auditDetailService.syncAudits(auditDetails).subscribe((syncAuditDetails: any) => {
                const auditDetaildata: number[] = syncAuditDetails.submittedAuditIDs;
                const hasSyncedAudits: boolean = syncAuditDetails.isSync;
                if (auditDetaildata && auditDetaildata.length > 0) {
                  // remove offline submitted audits
                  this.auditDetails = auditDetails.filter((d: IAuditDetail) => auditDetaildata.indexOf(d.Audit.ID) === -1);

                  if (!this.tools.isOnline()) {
                    // if offline, display offline submitted audits in my activity page
                    const offlineSubmittedAudits = activities.filter((activity: IUserActivity) => activity.Type === 0 && auditDetaildata.indexOf(activity.ID) > -1);
                    offlineSubmittedAudits.forEach((activity: IUserActivity) => {
                      activity.PendingUpload = true;
                    });
                    filteredActivities = filteredActivities.concat(offlineSubmittedAudits);
                  }

                  const audits = activities.filter((activity: IUserActivity) => activity.Type === 0 && auditDetaildata.indexOf(activity.ID) === -1);
                  filteredActivities = filteredActivities.concat(audits);

                  syncedData = true;
                } else {
                  this.auditDetails = auditDetails;
                  const audits = activities.filter((activity: IUserActivity) => activity.Type === 0);
                  filteredActivities = filteredActivities.concat(audits);
                }

                // mitigation details
                this.mitigationDetailService.syncMitigations(mitigationDetails).subscribe((syncedMitigationDetails: any) => {
                  const syncedMitigations: number[] = syncedMitigationDetails.submittedMitigationIDs;
                  const hasSyncedMitigations: boolean = syncedMitigationDetails.isSync;
                  if (syncedMitigations && syncedMitigations.length > 0) {
                    // remove offline submitted mitigations
                    this.mitigationDetails = mitigationDetails.filter((detail: IMitigationDetail) => syncedMitigations.indexOf(detail.Mitigation.ID) === -1);
                    const mitigations = activities.filter((activity: IUserActivity) => activity.Type !== 0 && syncedMitigations.indexOf(activity.ID) === -1);
                    filteredActivities = filteredActivities.concat(mitigations);

                    syncedData = true;
                  } else {
                    this.mitigationDetails = mitigationDetails;
                    const mitigations = activities.filter((activity: IUserActivity) => activity.Type !== 0);
                    filteredActivities = filteredActivities.concat(mitigations);
                  }

                  const hasSyncedRecords = syncedAuditArtifacts || hasSyncedAudits || hasSyncedMitigations;
                  if (this.tools.isOnline() && this.tools.isApp()) {
                    let syncedMitigationArtifacts: boolean = false;
                    this.mitigationArtifactService.syncMitigationArtifacts(mitigationDetails).subscribe((syncedArtifacts: any) => {
                      const mitigationArtifactsDeleted = syncedArtifacts.artifactsDeleted;
                      const mitigationArtifactsAdded = syncedArtifacts.artifactsAdded;
                      _.each(this.mitigationDetails, (detail: IMitigationDetail) => {
                        if (mitigationArtifactsAdded && mitigationArtifactsAdded.length > 0) {
                          for (const item of mitigationArtifactsAdded) {
                            if (detail.Mitigation.ID === item.mitigationID) {
                              detail.MitigationArtifacts = item.mitigationArtifacts;
                              syncedData = true;
                              syncedMitigationArtifacts = true;
                              break;
                            }
                          }
                        }

                        if (mitigationArtifactsDeleted && mitigationArtifactsDeleted.length > 0) {
                          for (const item of mitigationArtifactsDeleted) {
                            if (detail.Mitigation.ID === item.MitigationID) {
                              const filtered = detail.MitigationArtifacts.filter(aa => aa.ID === item.Artifact.ID);
                              if (filtered && filtered.length > 0) {
                                const index = detail.MitigationArtifacts.indexOf(filtered[0]);
                                detail.MitigationArtifacts.splice(index, 1);
                                syncedData = true;
                                syncedMitigationArtifacts = true;
                              }
                            }
                          }
                        }
                      });

                      // write auditDetails and mitigationDetails to file system
                      this.writeToFileSystem();

                      this.displayUserActivities(filteredActivities, (hasSyncedRecords || syncedMitigationArtifacts));
                      this.log.debug("home: activity synced: " + syncedData);
                    });
                  } else {
                    this.displayUserActivities(filteredActivities, hasSyncedRecords);
                    this.log.debug("home: activity synced: " + syncedData);
                  }
                });
              });
            });
          });
        });
      }

    });
  }

  public ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  public writeToFileSystem() {
    this.log.debug("writeToFileSystem");
    if (this.tools.isOnline() && this.tools.isApp()) {
      if (this.auditDetails) {
        // write audits
        const auditKey = this.storage.buildCurrentStorageKey("Audits", "AuditDetails");
        this.writeFile(auditKey, this.auditDetails);
      }

      if (this.mitigationDetails) {
        // write mitigationDetails
        const key = this.storage.buildCurrentStorageKey("Mitigations", "MitigationDetails");
        this.writeFile(key, this.allMitigationDetails);
      }

      // write each audit
      for (const audit of this.auditDetails) {
        this.log.debug("writing audits to filesystem..." + audit);

        const key = this.storage.buildCurrentStorageKey("Audits", audit.Audit.ID);
        audit.Audit.IsNotSynced = false;
        this.writeFile(key, audit, true, audit.AuditAnswerArtifacts, "AnswerArtifact");
        this.writeArtifacts(audit.AuditQuestionArtifacts, "AuditQuestionArtifact");
        this.writeArtifacts(audit.DocumentArtifacts, "AuditReferencesArtifact");
      }

      // write each mitigation
      for (const mitigation of this.mitigationDetails) {
        this.log.debug("writing mitigation to filesystem..." + mitigation);

        const key = this.storage.buildCurrentStorageKey("Mitigations", mitigation.Mitigation.ID);

        // combine mitigation artifacts and artifact objects
        const artifacts: any[] = [];
        _.each(mitigation.MitigationArtifacts, (img: any) => {
          let artifact: any[] = [];

          if (mitigation.Artifacts) {
            artifact = mitigation.Artifacts.filter(artifact => artifact.ID === img.ArtifactID);
          }

          if (artifact.length > 0) {
            artifacts.push({
              ID: img.ID,
              MitigationID: img.MitigationID,
              ArtifactID: artifact[0].ID,
              Url: artifact[0].Url,
              Filename: artifact[0].FileName,
              FileFormatID: artifact[0].FileFormatID
            });
          }
        });

        mitigation.Mitigation.IsNotSynced = false;
        this.writeFile(key, mitigation, true, artifacts, "MitigationDetailArtifact");

        key.entityName = "Audits";
        key.entityID = mitigation.Mitigation.AuditID;

        this.writeFile(key, mitigation.AuditDetail, true);
        if (mitigation.AuditDetail !== null && mitigation.AuditDetail.AuditAnswerArtifacts && mitigation.AuditDetail.AuditAnswerArtifacts.length > 0) {
          this.writeArtifacts(mitigation.AuditDetail.AuditAnswerArtifacts, "AnswerArtifact");
        }
      }
    }
  }

  public writeFile(key: StorageKey, obj: any, singleRecord: boolean = false, objArtifacts: any = null, artifactEntityName: string = null) {
    if (this.tools.isApp()) {
      if (obj) {
        this.storage.storeObject(key, obj).subscribe(
          () => {
            this.log.debug("home component: object stored...");
            if (singleRecord) {
              this.writeArtifacts(objArtifacts, artifactEntityName);
            }
          },
          (err: any) => {
            this.log.error("storage.service: failed to store object - " + JSON.stringify(err));
            this.log.debug("End Test: Storage Service");
          },
        );
      } else {
        // just write artifacts
        this.writeArtifacts(objArtifacts);
      }
    } else {
      // need to store data in local storage for web app
    }
  }

  public writeArtifacts(objArtifacts: any = null, artifactEntityName: string = null) {
    if (objArtifacts && objArtifacts.length > 0) {
      for (const artifact of objArtifacts) {
        let url: string = "";
        const fileFormatID: number = artifact.FileFormatID;

        url = (this.storage.isDoc(fileFormatID) || artifact.ArtifactUrl) ? artifact.ArtifactUrl : artifact.Url;

        if (url !== null) {
          this.log.debug("home: downloading artifact..." + artifact.ID + " file: " + artifact.FileName);

          const artifactKey = this.storage.buildCurrentStorageKey(artifactEntityName, artifact.ID, artifact.Filename, artifact.FileFormatID);
          this.storage.storeObject(artifactKey, artifact).subscribe(
            () => {
              this.log.debug("storage.service: audit artifact: " + artifact.ID + "stored");
            },
            (err: any) => {
              this.log.error("storage.service: failed to store artifact - " + JSON.stringify(err));
              this.log.debug("End Test: Storage Service");
            },
          );
        }
      }
    }
  }

  public conductAudit(activity: IUserActivity) {
    this.auditDetailService.getAuditByID(activity.ID, "Audits").subscribe((details: any) => {
      this.log.debug("conductAudit getAuditID success: " + details);

      if (!this.tools.isOnline() && details.auditDetail.Audit.SiteID && this.current.info.activeSite && (details.auditDetail.Audit.SiteID !== this.current.info.activeSite.ID)) {
        this.uiNotify.open("Action unavailable offline", true);
      } else {
        // write audit
        const auditKey = this.storage.buildCurrentStorageKey("Audits", activity.ID);

        this.zone.run(() => {
          if (details && activity.Status === 0) { // not started
            this.writeFile(auditKey, details.auditDetail, true, details.auditDetail.AuditAnswerArtifacts, "AnswerArtifact");
            this.writeArtifacts(details.auditDetail.AuditQuestionArtifacts, "AuditQuestionArtifact");
          }
          this.router.navigate(["./conduct", activity.ID], {relativeTo: this.route});
        });
      }
    });
  }

  public manageActivity(a: IUserActivity) {

    if (!this.activityClicked) {
      switch (a.Type) {
        case 0:
          this.conductAudit(a);
          break;
        case 1:
        case 2:
          if (a.AuditID) {
            this.router.navigate(["./audit", a.AuditID], { relativeTo: this.route });
          } else {
            this.router.navigate(["/home/mitigation", a.ID], { queryParams: { prep: false } });
          }
          break;
        case 3:
          this.router.navigate(["/report/corrective-actions", a.ID]);
          break;
        case 4:
          this.router.navigate(["/report/corrective-actions/" + a.ID + "/task"]);
          break;
      }
      this.activityClicked = true;
    }
  }

  private checkDisplayReportPanels() {
    this.displayReportPanels = !this.tools.isApp() && !this.media.isActive("xs") &&
      (this.current.info.roleIsAdmin || this.current.info.roleIsManager);
  }

  private displayUserActivities(filteredActivities: IUserActivity[], hasSyncedRecords: boolean): void {
    if (hasSyncedRecords) {
      const message: string = this.tools.isOnline() ? "_SyncingComplete_" : "_SyncingNotComplete_";
      const snackBarRefComplete = this.uiNotify.open(message, true);
      snackBarRefComplete.afterOpened().subscribe(() => {
        // display activities without offline submitted audits/mitigations
        this.userActivities = filteredActivities;

        this.notify.broadcast("full-page-block", false);
        this.isLoading = false;
      });
    } else {
      // display activities without offline submitted audits/mitigations
      this.userActivities = filteredActivities;

      this.notify.broadcast("full-page-block", false);
      this.isLoading = false;
    }
  }
}
