import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { CalendarEventType, ICalendarEvent, IClientUser, IUser } from "@core/models/ease-models";
import { AuditReassignData } from "./audit-reassign.types";
import * as moment from "moment";

import { CurrentInfoService } from "@core/services";
import { DateInternationalizePipe } from "@shared/pipes";

@Injectable()
export class AuditReassignDeleteService {
  constructor(private http: HttpClient,
              private Current: CurrentInfoService,
              private dateInternationalize: DateInternationalizePipe) {
  }

  public getAuditReassignData(selected: any): Observable<AuditReassignData> {
    const oldAuditorID = selected.auditorID;
    let oldAuditorName = selected.auditorName;

    const start = moment(selected.start).toDate();
    const end = moment(selected.end).add("day", 1).toDate();

    // convert edited date based on site's time zone
    let sdt = new Date(0, 0, 0, 0, 0, 0, 0);
    sdt.setFullYear(start.getFullYear(), start.getMonth(), start.getDate());
    let edt = new Date(0, 0, 0, 0, 0, 0, 0);
    edt.setFullYear(end.getFullYear(), end.getMonth(), end.getDate());

    if (!this.Current.IsUsingSiteTimeZone()) {
      sdt = this.Current.SiteDateInUTC(sdt);
      edt = this.Current.SiteDateInUTC(edt);
    }

    // date based on site timezone
    const startDate = sdt;
    const endDate = edt.addSeconds(-1);

    return Observable.forkJoin([
      this.http.get<ICalendarEvent[]>(`/calendarevent?q=user&eventType=${CalendarEventType.EmployeeTimeOff}&from=${startDate.toUTCString()}&to=${endDate.toUTCString()}`),
      this.http.get<IClientUser[]>(`/user?filteredRoles=Auditor`),
    ])
      .map((data: any[]) => {
        let auditors: IClientUser[] = [];
        data[1].forEach((auditor: IClientUser) => {
          if (auditor.ID === oldAuditorID) {
            if (oldAuditorName == null) {
              oldAuditorName = auditor.FirstName + " " + auditor.LastName;
            }
          } else {
            if (this.auditorHasNoTimeOff(auditor.ID, selected, data[0])) {
              auditor.FullName = auditor.FirstName + " " + auditor.LastName;
              auditors.push(auditor);
            }
          }
        });

        // sort by fullname
        auditors = auditors.sort((a, b) => a.FullName.localeCompare(b.FullName));

        // add option 'None (Unassign)'
        let item = { FirstName: "None (Unassign)", LastName: "", ID: -1 } as IClientUser;
        auditors.splice(0, 0, item);

        // add option 'None (Delete)'
        item = { FirstName: "None (Delete)", LastName: "", ID: 0 } as IClientUser;
        auditors.splice(1, 0, item);

        const auditReassignData: AuditReassignData = {
          OldAuditorID: oldAuditorID,
          OldAuditorName: oldAuditorName,
          SiteStartDate: startDate,
          SiteEndDate: endDate,
          UserCalenderEvents: data[0],
          Auditors: auditors,
        };
        return auditReassignData;
      });
  }

  public auditReasssignDelete(oldAuditorID: number, newAuditorID: number,
                              timeOffStartDate: string, timeOffEndDate: string,
                              unassignAudits: boolean, deleteAudits: boolean,
                              locationID: number = 0, isSiteCalendar: boolean = false): Observable<void> {
    const url = `/user/${oldAuditorID}?newAuditorID=${newAuditorID}&timeOffStartDate=${timeOffStartDate}&timeOffEndDate=${timeOffEndDate}&unassignAudits=${unassignAudits}&deleteAudits=${deleteAudits}&locationID=${locationID}&isSiteCalendar=${isSiteCalendar}`;
    return this.http.put<void>(url, {});
  }

  private auditorHasNoTimeOff(userID: number, selected: any, userCalendarEvents: ICalendarEvent[]): boolean {
    let hasNoTimeOff: boolean = true;

    let counter: number = 0;

    const from = moment(selected.start).toDate();
    const to = moment(selected.end).toDate();

    const selectedStart = from.epochDate();
    const selectedEnd = to.epochDate();

    const filtered: ICalendarEvent[] = userCalendarEvents.filter((event) => event.UserID === userID);
    for (counter = 0; counter <= filtered.length - 1; counter++) {
      const start = new Date(this.dateInternationalize.transform(filtered[counter].Start));
      const due = new Date(this.dateInternationalize.transform(filtered[counter].Due));

      const eventStart = start.epochDate();
      const eventDue = due.epochDate();

      if ((selectedStart >= eventStart && selectedStart <= eventDue) ||
          (selectedEnd >= eventStart && selectedEnd <= eventDue)) {
        hasNoTimeOff = false;
        break;
      }
    }

    return hasNoTimeOff;
  }
}
