import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {LocalizeService} from "../../core/services/localize.service";

@Component({
  templateUrl: "./audit-list-modal.component.html",
})

export class AuditListModalComponent {

  public selectedAuditIDs: number[] = [];
  public completedOnly: boolean = false;
  public showIdColumn: boolean = false;
  public showAuditTypeColumn: boolean = false;
  public showDimensionColumns: boolean = true;
  public showSearchInput: boolean = false;
  public showDateRange: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AuditListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private localize: LocalizeService) {

    this.initModal(data);
  }

  public initModal(data) {
    this.selectedAuditIDs = data.selectedAuditIDs;
    this.completedOnly = data.completedOnly;
    this.showIdColumn = data.showIdColumn;
    this.showAuditTypeColumn = data.showAuditTypeColumn;
    this.showDimensionColumns = data.showDimensionColumns;
    this.showSearchInput = data.showSearchInput;
    this.showDateRange = data.showDateRange;
  }

  public cancelHandler() {
    this.dialogRef.close(false);
  }
  public saveHandler() {
    this.dialogRef.close(this.selectedAuditIDs);
  }
  public auditSelectedHandler(selectedAudits: number[]): void {

    this.selectedAuditIDs = selectedAudits;
  }
}
