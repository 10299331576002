import {Injectable} from "@angular/core";
import {LocalService} from "../../core/services/local.service";
import {IDateRange} from "../date/date-types";
import {IListViewStateInfo, IPaginationModel, ListViewFilterSortData} from "./list-view-models";

@Injectable()
export class ListViewStorageService {

    public listViewIdentifier: string = "";

    constructor(private local: LocalService) {

    }

    public init(id: string): void {
        this.listViewIdentifier = id;
    }

    private getLocalStorageVariableName(gridType: string = "") {
        let gridName = "";
        switch (gridType) {
            case "filter":
                gridName = "gridFilters_";
                break;
            case "sort":
                gridName = "gridSort_";
                break;
            default:
                gridName = "gridItemsPerPage_";
                break;
        }
        const localStorageName = gridName + this.listViewIdentifier;
        return localStorageName;
    }

    public restoreFromLocalStorage(): IListViewStateInfo {

        const state: IListViewStateInfo = {};
        state.pagingInfo = this.restorePagingFromLocalStorage();
        state.filterSortData = this.restoreFilterSortFromLocalStorage();
        state.dateRange = this.restoreDateRangeFromLocalStorage();

        state.pagingInfo.currentPage = 1;
        return state;
    }

    private restoreDateRangeFromLocalStorage(): IDateRange {
        const results: IDateRange = {};

        const dateRangeKey = "dateRangeFilter." + this.listViewIdentifier;
        if (this.local.containsItem(dateRangeKey)) {
            const state = JSON.parse(this.local.getItem(dateRangeKey));

            results.startDate = (state.startDate != null) ? new Date(state.startDate) : null;
            results.endDate = (state.endDate != null) ? new Date(state.endDate) : null;
        }

        return results;
    }

    private restoreFilterSortFromLocalStorage(): ListViewFilterSortData {
        let results: ListViewFilterSortData = null;

        const localFilterStorageName = this.getLocalStorageVariableName("filter");
        const localSortStorageName = this.getLocalStorageVariableName("sort");

        const hasFilterSaved = this.local.containsItem(localFilterStorageName);
        const hasSortSaved = this.local.containsItem(localSortStorageName);

        if (hasFilterSaved || hasSortSaved) {
            results = new ListViewFilterSortData();
        }

        // restore filter from local storage
        if (hasFilterSaved) {
            const ls = this.local.getItem(localFilterStorageName);
            results.filters = JSON.parse(ls);
        }

        // restore sort from local storage
        if (hasSortSaved) {
            const ls = this.local.getItem(localSortStorageName);
            results.currentSort = JSON.parse(ls);
        }

        return results;
    }

    private restorePagingFromLocalStorage(): IPaginationModel {
        const results: IPaginationModel = {};

        const localStorageName = this.getLocalStorageVariableName();

        //restore grid items per page if local storage name exists
        if (this.local.containsItem(localStorageName)) {
            results.pageSize = parseInt(this.local.getItem(localStorageName));
        }
        return results;
    }

    public saveFilterSortToLocalStorage(gridType: string, filterSortData: ListViewFilterSortData) {
        const localStorageName = this.getLocalStorageVariableName(gridType);
        const storedValue = (gridType == "filter") ? filterSortData.filters : filterSortData.currentSort;
        //store filter parameters

        this.local.setItem(localStorageName, storedValue);
    }

    public savePagingInfoToLocalStorage(pagingInfo: IPaginationModel) {
        //append the page name to grid items per page localstorage
        const localStorageName = this.getLocalStorageVariableName();

        //store grid items per page
        this.local.setItem(localStorageName, pagingInfo.pageSize);
    }

    public saveDateRangeToLocalStorage(dateRange: IDateRange) {
        const dateRangeKey = "dateRangeFilter." + this.listViewIdentifier;

        this.local.setItem(dateRangeKey, dateRange);
    }

    public clearAllFilters() {
        this.local.removeItem(this.getLocalStorageVariableName("filter"));
    }

}
