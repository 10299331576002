﻿import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "ease-numeric-rating",
    templateUrl: "./numeric-rating.component.html",
})

export class NumericRatingComponent implements OnInit {
    public selectedScoreValue: number;

    @Output() public selectedScoreChange = new EventEmitter<number>();

    @Input("minValue") public minValue: number;
    @Input("maxValue") public maxValue: number;
    @Input()
    get selectedScore() {
        return this.selectedScoreValue;
    }

    set selectedScore(value: number) {
        this.selectedScoreValue = value;
        this.selectedScoreChange.emit(this.selectedScoreValue);
    }

    ratingValues: any;
    private isPrint: boolean = false;

    constructor() {
    }

    public ngOnInit() {
        this.ratingValues = [];
        for (let i = +this.minValue; i <= +this.maxValue; i++) {
            this.ratingValues.push({ value: i + 1, isSelected: i + 1 == this.selectedScore });
        }
    }

    private setActiveRating(item: number) {
        this.selectedScore = item;
        if (this.ratingValues && this.ratingValues.length) {
            for (let i = 0; i < this.ratingValues.length; i++) {
                if (this.ratingValues[i].value == this.selectedScore) {
                    this.ratingValues[i].isSelected = true;
                } else {
                    this.ratingValues[i].isSelected = false;
                }
            }
        }
    }
}
