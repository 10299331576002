import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Observable, Subject } from "rxjs";

import "rxjs/Rx";
import { ConfirmationModalComponent } from "./confirmation-modal.component";
import { DialogConfigSm } from "../dialogs/index";
import { IConfirmationModalData } from "@shared/confirmation/confirmation-modal.component";


@Injectable()
// The purpose of this service is to provide a simple generic confirmation and notification modal dialog (Similar to Ease1)
export class ConfirmationDialogService implements OnDestroy {

    private componentDestroyed: Subject<boolean> = new Subject();
    private dialogRef: any;

    constructor(private dialog: MatDialog) {

    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next(true);
        this.componentDestroyed.combineAll();
    }

    public getResponse(): Observable<boolean> {
        return this.dialogRef.afterClosed();
    }

    public open(title: string, message: string,
                isConfirmationDialog: boolean = true,
                confirmButtonText: string = "",
                cancelButtonText: string = "",
                lists: string[] = []): void {

        const config = new DialogConfigSm<IConfirmationModalData>();
        config.data = {
            title,
            message,
            lists,
            isConfirmationDialog,
            confirmButtonText,
            cancelButtonText,
        };
        this.dialogRef = this.dialog.open(ConfirmationModalComponent, config);
    }
}
