import { Action } from "@ngrx/store";

export enum CollectionActionTypes {
  Load = "[Permission Groups Collection] Load",
  LoadSuccess = "[Permission Groups Collection] Load Success",
  LoadFail = "[Permission Groups Collection] Load Fail",
  RemoveGroup = "[Permission Groups Collection] Remove Group",
  RemoveGroupSuccess = "[Permission Groups Collection] Remove Group Success",
  RemoveGroupFail = "[Permission Groups Collection] Remove Group Fail",
  TableOptionsChanges = "[Permission Groups Collection] Table Changes",
  TableOptionsChangesSuccess = "[Permission Groups Collection] Table Changes Success",
}

export class Load implements Action {
  readonly type = CollectionActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = CollectionActionTypes.LoadSuccess;

  constructor(public payload: any[]) {}
}

export class LoadFail implements Action {
  readonly type = CollectionActionTypes.LoadFail;

  constructor(public payload: any[]) {}
}

export class RemoveGroup implements Action {
  readonly type = CollectionActionTypes.RemoveGroup;

  constructor(public payload: any) {}
}

export class RemoveGroupSuccess implements Action {
  readonly type = CollectionActionTypes.RemoveGroupSuccess;

  constructor(public payload: any) {}
}

export class RemoveGroupFail implements Action {
  readonly type = CollectionActionTypes.RemoveGroupFail;

  constructor(public payload: any) {}
}

export class TableOptionsChanges implements Action {
  readonly type = CollectionActionTypes.TableOptionsChanges;

  constructor(public payload: any) {}
}

export class TableOptionsChangesSuccess implements Action {
  readonly type = CollectionActionTypes.TableOptionsChangesSuccess;

  constructor(public payload: any) {}
}

export type CollectionActionsUnion =
  | Load
  | LoadSuccess
  | LoadFail
  | RemoveGroup
  | RemoveGroupSuccess
  | RemoveGroupFail
  | TableOptionsChanges
  | TableOptionsChangesSuccess;
