import { Component } from "@angular/core";

@Component({
  selector: "ease-page",
  template: `<ng-content></ng-content>`,
  styles: [ `
    :host {
      display: block;
      padding: 15px;
    }
  ` ],
  host: {
    "[class]": "'ease-page'",
  },
})
export class PageComponent {
}

@Component({
  selector: "ease-page-sm",
  template: `
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.sm="90" fxFlex.gt-sm="40">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [ `
    :host {
      display: block;
      padding: 15px;
    }
  ` ],
  host: {
    "[class]": "'ease-page-sm'",
  },
})
export class PageSmComponent {
}

@Component({
  selector: "ease-page-md",
  template: `
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.sm="70" fxFlex.gt-sm="50">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [ `
    :host {
      display: block;
      padding: 15px;
    }
  ` ],
  host: {
    "[class]": "'ease-page-md'",
  },
})
export class PageMdComponent {
}

@Component({
  selector: "ease-page-lg",
  template: `
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.sm="90" fxFlex.gt-sm="70">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [ `
    :host {
      display: block;
      padding: 15px;
    }
  ` ],
  host: {
    "[class]": "'ease-page-lg'",
  },
})
export class PageLgComponent {
}
