import { Injectable, NgZone } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { DomSanitizer } from "@angular/platform-browser";

import { FileUploadData, FileUploadTypes, FileUploadResponse } from "@shared/file-upload/file-upload.class";
import { IFileFormat } from "@core/models/ease-models";

import { IdentityService, LocalService, NotifyService, StorageService } from "@core/services";
import { ArtifactService } from "@core/services/common/artifact.service";
import { HttpClient } from "@angular/common/http";
import { isNullOrUndefined } from "util";
import { UiNotificationService } from "@core/services/common/ui-notification.service";
import { LoggerService } from "@core/services/logger.service";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import { take } from "rxjs/operators";

@Injectable()
export class FileUploadService {

  private token: string;
  private fileFormats: IFileFormat[] = null;

  constructor(private store: Store<fromAuth.State>,
              private notify: NotifyService,
              private zone: NgZone,
              private local: LocalService,
              private identity: IdentityService,
              private storage: StorageService,
              private sanitizer: DomSanitizer,
              private artifactService: ArtifactService,
              private http: HttpClient,
              private uiNotify: UiNotificationService,
              private log: LoggerService) {

    this.store.pipe(
      select(fromAuth.getToken),
      take(1),
    ).subscribe(value => this.token = value);
  }
  public initFileUploadData(type: FileUploadTypes,
                            artifacts: any[],
                            limit: number = null,
                            allowedFileExts: string[] = null,
                            params: string = "",
                            headers: any[] = []): Observable<FileUploadData> {

    const result = new ReplaySubject<FileUploadData>(1);

    artifacts.forEach((artifact) => {
      artifact.FlaggedForDeletion = false;

      if (this.storage.isDoc(artifact.FileFormatID)) {
        artifact.Url = this.artifactService.getImageUrl(artifact);
      }

    });

    const data: FileUploadData = new FileUploadData();
    data.fileUploadType = type;
    data.artifacts = artifacts;
    data.limit = limit;
    data.headers = headers;

    this.getAcceptedFileFormats().subscribe((fileFormats) => {
      this.initUploader(data, limit, fileFormats, allowedFileExts, params);
      data.updateTotalArtifacts();

      result.next(data);
      result.complete();
    })

    return result.asObservable();
  }

  // For all images in "uploader" queue, POST them
  // For all artifacts that are flagged for deletion, DELETE them
  public postFileUploadChange(entityID: number, data: FileUploadData): Observable<boolean> {

    if (data) {
      return this.uploadArtifact(entityID, data)
        .flatMap(() => {
          return this.postArtifactDeletions(data)
            .map(() => {
              return true;
            });
        });
    } else {
      return Observable.of(false);
    }
  }

  public uploadArtifact(entityID: number, data: FileUploadData): Observable<FileUploadResponse> {

    const result = new ReplaySubject<FileUploadResponse>(1);
    const response: FileUploadResponse = { success: true, response: "" };

    if (data.uploader !== null && data.uploader.queue.length > 0) {
      const headerName = data.getApiHeaderName();
      const tauth = `Token ${this.token}`;

      if (!isNullOrUndefined(headerName) && headerName !== "") {
        data.uploader.queue.forEach((item: FileItem) => {
          item.headers = [{name: headerName, value: entityID}];
        });
      }

      data.headers.forEach((header) => {
        data.uploader.queue.forEach((item: FileItem) => {
          item.headers.push({ name: header.name, value: header.value });
        });
      })

      data.uploader.authTokenHeader = "Authorization";
      data.uploader.authToken = tauth;

      data.uploader.onProgressAll = this.zone.run(() => (progress: number) => {
        this.notify.broadcast("full-page-block", true);
      });

      if (data.limit === 1) {
        data.uploader.onCompleteItem = (item, res, status, headers) => {
          this.notify.broadcast("full-page-block", false);
          response.response = JSON.parse(res);
          result.next(response);
          result.complete();
        };
      } else {
        data.uploader.onCompleteAll = this.zone.run(() => () => {
          this.notify.broadcast("full-page-block", false);
          result.next(response);
          result.complete();
        });
      }

      data.uploader.onErrorItem = (item, res, status, headers) => {
        response.success = false;
        response.response = res;
        result.next(response);
        result.complete();
      };

      data.uploader.uploadAll();
    } else {
      result.next(response);
      result.complete();
    }

    return result.asObservable();
  }

  private postArtifactDeletions(data: FileUploadData): Observable<any> {

    const requests: any[] = [];
    const apiName = data.getApiName();

    data.artifacts.forEach((artifact) => {
      if (artifact.FlaggedForDeletion) {
        const id = artifact.ID;
        requests.push(this.http.delete(`${apiName}/${id}`));
      }
    });

    if (requests.length === 0) {
      return Observable.of(null);
    } else {
      return Observable.forkJoin(requests);
    }
  }
  private initUploader(data: FileUploadData,
                       limit: number,
                       fileFormats: IFileFormat[],
                       allowedFileExts: string[],
                       params: string): void {

    const host = this.identity.endpoints.Service;
    let uploadUrl: string = "";
    if (host.length > 0) {
      uploadUrl = `${host}${data.getApiName()}?${params}`;
    }

    const config: FileUploaderOptions = {
      url: uploadUrl,
      removeAfterUpload: true,
    }

    if (limit) {
      config.queueLimit = limit;
    }

    const uploader = new FileUploader(config);

    uploader.onAfterAddingFile = (item: FileItem) => {

      const fileExt = item.file.name
        .split(".").pop().toLowerCase();

      // Todo: Would like to do a Mime type validation instead, but IE11 does not pass in the mime type
      // Because of this, we'll have to check the file ext
      if (!allowedFileExts || allowedFileExts.indexOf(fileExt) >= 0) {

        const fileFormatID = this.getFileFormatID(item._file.type, fileFormats);
        const urlPath = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(item._file)));

        if (this.storage.isDoc(fileFormatID)) {
          const img = { FileFormatID: fileFormatID, Url: urlPath };
          data.filePreviewPaths.push(this.artifactService.getImageUrl(img));
        } else {
          data.filePreviewPaths.push(urlPath);
        }

        // Make sure this file doesn't already exist
        const filter = uploader.queue.filter(i => i.file.rawFile === item.file.rawFile);
        if (filter.length === 0) {
          uploader.queue.push(item);
        }
        data.updateTotalArtifacts();
      } else {
        uploader.removeFromQueue(item);
        const userMessage = `File type ".${fileExt}" is not allowed`;
        this.uiNotify.open(userMessage, false, 10000);
      }

    };
    data.uploader = uploader;
  }

  private getAcceptedFileFormats(): Observable<IFileFormat[]> {

    const result = new ReplaySubject<IFileFormat[]>(1);
    // If the value already exists, use that
    if (this.fileFormats !== null) {
      result.next(this.fileFormats);
      result.complete();
    }
    const url = "/fileformat";
    this.http.get<IFileFormat[]>(url).subscribe((fileFormats) => {
      this.fileFormats = fileFormats;
      result.next(this.fileFormats);
      result.complete();
    });

    return result.asObservable();
  }

  private getFileFormatID(mimeType: string, fileFormats: IFileFormat[]): number {
    let result: number = 0;
    if (fileFormats) {
      const formats = fileFormats.filter((fileFormat: IFileFormat) => fileFormat.MimeType === mimeType);
      if (formats && formats.length > 0) {
        result = formats[0].ID;
      }
    }
    return result;
  }

}
