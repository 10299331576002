import { Component, Inject } from "@angular/core";
import {  MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    templateUrl: "./calendar-modal.component.html",
})
export class CalendarModalComponent {

    constructor(
        public dialogRef: MatDialogRef<CalendarModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

}
