import { Component, Input } from "@angular/core";

// State
import { select, Store } from "@ngrx/store";
import * as fromRelease from "app/system-admin/releases/reducers";

// Interface
import { BreadcrumbComponent } from "../breadcrumb.component";

@Component({
  selector: "ease-breadcrumb-release",
  template: `
    <ease-breadcrumb-base-item
      [loading]="!(details$ | async)"
      [placeholder]="placeholder"
      [ngClass]="{'breadcrumb-loading': !(details$ | async)}">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        {{ details$ | async }}
      </a>
    </ease-breadcrumb-base-item>
  `,
})
export class BreadcrumbReleaseComponent implements BreadcrumbComponent {
  @Input() data: any;
  details$ = this.store.pipe(select(fromRelease.getReleaseName));
  placeholder = "_Core:BreadcrumbRelease:Loading_";

  constructor(private store: Store<fromRelease.State>) {
  }
}
