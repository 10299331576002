﻿import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CurrentInfoService } from "../../core/services/current.service";
import { NotifyService } from "../../core/services/notify.service";
import { Subject } from "rxjs";
import { ToolsService } from "@core/services";

@Component({
  templateUrl: "./audit-complete.component.html",
  styleUrls: [
    "./audit-conduct.component.scss",
  ],
})

export class AuditCompleteComponent implements OnInit, OnDestroy {
  public auditID: number;
  private destroy$ = new Subject<void>();
  constructor(private notify: NotifyService, private current: CurrentInfoService, private router: Router,
              private route: ActivatedRoute, public tools: ToolsService) {
  }

  public ngOnInit() {
    this.auditID = parseInt(this.route.snapshot.params.id, 10);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
