
export enum RepeatEndBy {
  Count = 1,
  Date = 2,
}

export class RepeatingEventData {
  public start: Date;
  public interval: any;
  public intervalEvery: number;
  public ends: number;
  public occurrences: number;
  public endsOn: Date;
  public readOnly: boolean;
  public includeCutoffOptions: boolean;
  public includeSchedulingOptions: boolean;
  public repeatsOn: number[];
  public repeatSchedule: any[];
  public hasSelectedDayOfWeek: boolean;
  constructor() {
    this.start = new Date();
    this.interval = 0;
    this.intervalEvery = 1;
    this.ends = RepeatEndBy.Count;
    this.occurrences = 10;
    this.endsOn = null;
    this.readOnly = false;
    this.includeCutoffOptions = true;
    this.includeSchedulingOptions = false;
    this.repeatsOn = [0, 0, 0, 0, 0, 0, 0];
    this.repeatSchedule = [];
    this.hasSelectedDayOfWeek = false;
  }
}