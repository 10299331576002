import {
  OrganizationActionTypes,
  OrganizationActionsUnion,
} from "./../actions/organization.actions";

// Models
import { ApiError } from "@core/models/api";
import { AddOrganization } from "../models/organization";

export interface State {
  created: boolean;
  loading: boolean;
  error: ApiError | null;
  formValue: AddOrganization | null;
}

const initialState: State = {
  created: false,
  loading: false,
  error: null,
  formValue: {
    OrganizationName: "",
    OrganizationDescription: "",
    AdminFirstName: "",
    AdminLastName: "",
    AdminEmail: "",
    FullDomain: "",
    IsSubdomainEnabled: false,
  },
};

export function reducer(
  state = initialState,
  action: OrganizationActionsUnion,
): State {
  switch (action.type) {
    case OrganizationActionTypes.Changed: {
      return {
        ...state,
        formValue: action.payload,
      };
    }

    case OrganizationActionTypes.Create: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case OrganizationActionTypes.CreateSuccess: {
      return {
        ...state,
        created: true,
        loading: false,
      };
    }

    case OrganizationActionTypes.CreateFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case OrganizationActionTypes.Reset: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getFormValue = (state: State) => state.formValue;
export const getLoading = (state: State) => state.loading;
export const getCreated = (state: State) => state.created;
export const getError = (state: State) => state.error;
