import { Subject } from "rxjs";
import { filter, scan, withLatestFrom, buffer, mergeMap } from "rxjs/operators";

import { Inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Store, select } from "@ngrx/store";
import * as fromAuth from "@app/auth/reducers";

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6,
}

export const LOG_LEVEL = new InjectionToken<LogLevel>("LogLevel");
export const LOG_BATCH_SIZE = new InjectionToken<number>("LogBatchSize");

class LogEntity {
  constructor(public level: LogLevel,
              public message: string,
              public time: Date) {
  }
}

@Injectable()
export class LoggerService {
  private logEntities$ = new Subject();
  private loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));

  constructor(private store: Store<fromAuth.State>,
              private http: HttpClient,
              @Inject(LOG_LEVEL) private level: LogLevel,
              @Inject(LOG_BATCH_SIZE) private logBatchSize: number) {

    const filtered$ = this.logEntities$.pipe(
      filter(logEntity => (logEntity as LogEntity).level >= this.level),
    );

    const sendTrigger$ = filtered$.pipe(
      scan(acc => ++acc, 0),
      filter(count => count % this.logBatchSize === 0),
      withLatestFrom(this.loggedIn$),
      filter(([_, isLoggedIn]) => isLoggedIn),
    );

    filtered$.pipe(
      buffer(sendTrigger$),
      mergeMap(entities => this.http.post("/log/batch", entities)),
    ).subscribe();
  }

  trace(message: string): void {
    this.logEntities$.next(new LogEntity(LogLevel.Trace, message, new Date()));
  }

  debug(message: string): void {
    this.logEntities$.next(new LogEntity(LogLevel.Debug, message, new Date()));
  }

  info(message: string): void {
    this.logEntities$.next(new LogEntity(LogLevel.Information, message, new Date()));
  }

  warn(message: string): void {
    this.logEntities$.next(new LogEntity(LogLevel.Warning, message, new Date()));
  }

  error(message: string): void {
    this.logEntities$.next(new LogEntity(LogLevel.Error, message, new Date()));
  }

  critical(message: string): void {
    this.logEntities$.next(new LogEntity(LogLevel.Critical, message, new Date()));
  }
}
