import {DataSource} from "@angular/cdk/collections";
import {Observable, merge} from "rxjs";
import {map} from "rxjs/operators";
import { IChangeLogSummary } from "./changelog.model";
import { ChangelogService } from "../../core/services/common/changelog.service";
import { entityNames } from "./constants";

export interface IChangelogDetailRow {
    detailRow: boolean;
    data: IChangeLogSummary;
}

export class ChangelogDetails extends DataSource<any> {

    constructor(private changelogService: ChangelogService, private entityName: string, private entityID: string) {
        super();
    }

    public connect(): Observable<Array<(IChangeLogSummary | IChangelogDetailRow)>> {

        return this.changelogService.getChangelog(this.entityName, this.entityID).pipe(map(data => {

            const rows: Array<(IChangeLogSummary | IChangelogDetailRow)> = [];
            data.forEach(log => rows.push(log, {detailRow: true, data: log}));
            return rows;
        }));
    }

    public disconnect() {
        // Non-on
    }
}
