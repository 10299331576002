import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "../../material";

// Components
import { DatePickerComponent } from "./datepicker.component";
import { DateRangePickerComponent } from "./date-range-picker.component";

import { PipesModule } from "../pipes"

const COMPONENTS = [
    DatePickerComponent,
    DateRangePickerComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MaterialModule,

        PipesModule,
    ],
    exports: [
        ...COMPONENTS
    ],
    declarations: [
        ...COMPONENTS,
    ],
})
export class DateModule {}