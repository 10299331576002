import { Injectable } from "@angular/core";
import { TreeviewComponent, TreeviewEventParser } from "ngx-treeview";
import {LocationTreeItem} from "./location-tree-item";

@Injectable()
export class LocationTreeviewEventParser extends TreeviewEventParser {
    public getSelectedChange(component: TreeviewComponent): LocationTreeItem[] {
        const items = component.items;
        if (items) {
            let result: LocationTreeItem[] = [];
            items.forEach(item => {
                const links = this.getLinks(item as LocationTreeItem);
                if (links) {
                    result = result.concat(links);
                }
            });

            return result;
        }

        return [];
    }

    private getLinks(item: LocationTreeItem): LocationTreeItem[] {
        let result: LocationTreeItem[] = [];

        if (item.checked) {
            result.push(item);
        }

        if (item.children) {
            item.children.forEach(child => {
                const links = this.getLinks(child as LocationTreeItem);
                if (links) {
                    result = result.concat(links);
                }
            });
        }

        return result;
    }
}
