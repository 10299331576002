import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ShiftManager } from "@app/admin/admin-models";

import { ICalendar, ICalendarWorkSchedule, IDepartment, IShift, IUser } from "@core/models/ease-models";

@Injectable()
export class ShiftService {
  constructor(private http: HttpClient) {
  }

  public getShifts(query: any): Observable<IShift[]> {
    return this.http.get<IShift[]>("/shift", {
      params: query,
    });
  }

  public getShiftById(id: number): Observable<IShift> {
    return this.http.get<IShift>("/shift/" + id);
  }

  public create(shift: IShift): Observable<number> {
    return this.http.post<number>("/shift", shift);
  }

  public getDepartments(query: any): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>("/department", {
      params: query,
    });
  }

  public getCalendar(query: any): Observable<ICalendar[]> {
    return this.http.get<ICalendar[]>("/calendar", {
      params: query,
    });
  }

  public getCalendarSchedule(query: any) {
    return this.http.get<ICalendarWorkSchedule[]>("/calendarworkschedule", {
      params: query,
    });
  }

  public getUsers(query: any) {
    return this.http.get<IUser[]>("/User", {
      params: query,
    });
  }

  public getTimeZoneOffset(query: any) {
    return this.http.get<any>("/timezoneoffset", {
      params: query,
    });
  }

  public getShiftManagersOfLocation(locationId: number): Observable<ShiftManager[]> {
    return this.http.get<ShiftManager[]>("/shiftmanager/location?id=" + locationId);
  }

  public updateShiftManagers(locationId: number, shiftManagers: ShiftManager[]): Observable<void> {
    return this.http.put<void>("/shiftmanager/location?id=" + locationId, shiftManagers);
  }
}
