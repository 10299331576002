import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { EaseUIDateFormatService } from "../../admin/layers/date-format.service";
import { RepeatingEventData } from "./repeating-event-data";
import { MultiLabeledMenuItem } from "./multi-labeled-menu-item";
import { LabeledMenuItem } from "./labeled-menu-item";
import { AppSettingsService } from "@core/services/app-settings.service";
import { CurrentInfoService } from "@core/services/current.service";
import { NotifyService } from "@core/services/notify.service";
import { IdentityService } from "@core/services/identity.service";
import { LocalizeService } from "@core/services/localize.service";
import { LayerService } from "../../admin/layers/layer.service";

import { IOrgLayerSiteDetail } from "@core/models/ease-models";

@Component({
  selector: "ease-calendar-repeat",
  styleUrls: ["./calendar-repeat.component.scss"],
  templateUrl: "./calendar-repeat.component.html",
  providers: [EaseUIDateFormatService, LayerService],
})
export class CalendarRepeatComponent implements OnInit {
  @Input() public isAssignAudit: boolean;
  @Input() public easeCalendarRepeat: RepeatingEventData;
  @Input() public minDate: Date;
  @Input() public useLayers: boolean;
  @Input() public orgLayerDetail: IOrgLayerSiteDetail;
  @Output() public modelChanged: EventEmitter<any> = new EventEmitter<any>();

  public intervals: LabeledMenuItem[];
  public intervalsAltTxt: MultiLabeledMenuItem[];
  public intervalsEvery: LabeledMenuItem[];
  public occurrenceChoice: LabeledMenuItem[];
  public repeatSummary: string;
  public isDatePickerOpen: boolean;
  public dateTimeFormat: string;

  public isLimitOptionsEnabled: boolean;
  public isGracePeriodEnabled: boolean;
  public hasSelectedDayOfWeek: boolean = false;

  constructor(private current: CurrentInfoService,
              private appSettings: AppSettingsService,
              private router: Router,
              private notify: NotifyService,
              private identity: IdentityService,
              private dateFormat: EaseUIDateFormatService,
              private localize: LocalizeService,
              private layerService: LayerService) {}

  ngOnInit(): void {
    this.dateTimeFormat = this.dateFormat.buildDatePatternFromCurrentLocale();
    if (this.easeCalendarRepeat == null) {
      this.easeCalendarRepeat = new RepeatingEventData();
      this.easeCalendarRepeat.start = this.current.SiteDateToday(null, null);   // date today based on site's timezone
    }

    this.intervals = [// if new options are added to this array the id of the 'layerOption' from rRegisterEvents method should be adjusted
      { id: 0, label: this.localize.getLocalizedString("_Never_"), isSchedulingOption: false },
      { id: 1, label: this.localize.getLocalizedString("_Daily_"), isSchedulingOption: false },
      { id: 2, label: this.localize.getLocalizedString("_Weekly_"), isSchedulingOption: false },
      { id: 3, label: this.localize.getLocalizedString("_Monthly_"), isSchedulingOption: false },
      { id: 4, label: this.localize.getLocalizedString("_Yearly_"), isSchedulingOption: false },
    ];

    this.intervalsAltTxt = [
      { id: 0, label: "", labelp: "" },
      { id: 1, label: this.localize.getLocalizedString("_Day_").toLowerCase(), labelp: this.localize.getLocalizedString("_Days_").toLowerCase() },
      { id: 2, label: this.localize.getLocalizedString("_Week_").toLowerCase(), labelp: this.localize.getLocalizedString("_Weeks_").toLowerCase() },
      { id: 3, label: this.localize.getLocalizedString("_Month_").toLowerCase(), labelp: this.localize.getLocalizedString("_Months_").toLowerCase() },
      { id: 4, label: this.localize.getLocalizedString("_Year_").toLowerCase(), labelp: this.localize.getLocalizedString("_Years_").toLowerCase() },
      { id: 5, label: "", labelp: "" }];

    this.intervalsEvery = [];
    for (let i: number = 0; i < 30; i++) {
      this.intervalsEvery.push({ id: i + 1, label: (i + 1).toString() });
    }

    this.occurrenceChoice = [];
    for (let i: number = 0; i < 30; i++) {
      this.occurrenceChoice.push({ id: i + 1, label: (i + 1).toString() });
    }

    this.isDatePickerOpen = false;

    if (!this.easeCalendarRepeat.includeSchedulingOptions) {
      this.intervals = this.intervals.filter((interval: LabeledMenuItem) => interval.isSchedulingOption === false);
    }

    const sun = this.localize.getLocalizedString("_Sun_");
    const mon = this.localize.getLocalizedString("_Mon_");
    const tue = this.localize.getLocalizedString("_Tue_");
    const wed = this.localize.getLocalizedString("_Wed_");
    const thu = this.localize.getLocalizedString("_Thu_");
    const fri = this.localize.getLocalizedString("_Fri_");
    const sat = this.localize.getLocalizedString("_Sat_");
    let days = [sun.substr(0, 1), mon.substr(0, 1), tue.substr(0, 1), wed.substr(0, 1), thu.substr(0, 1), fri.substr(0, 1), sat.substr(0, 1)];

    const lang = window.localStorage.getItem("apiLanguage");
    if (lang === "zh-CN") {
      days = [sun.substr(0, 2), mon.substr(0, 2), tue.substr(0, 2), wed.substr(0, 2), thu.substr(0, 2), fri.substr(0, 2), sat.substr(0, 2)];
    }

    for (let idx = 0; idx < this.easeCalendarRepeat.repeatsOn.length; idx++) {
      this.easeCalendarRepeat.repeatSchedule[idx] = { name: days[idx], isSelected: (this.easeCalendarRepeat.repeatsOn[idx] === 1) };
    }

    this.isGracePeriodEnabled = this.appSettings.currentAppSettings.isGracePeriodEnabled;
    if (this.useLayers) {
      const layerOption = { id: 5, label: this.localize.getLocalizedString("_ByLayer_"), isSchedulingOption: true };
      const filteredOptions = this.intervals.filter(item => item.id === 5);
      // only add element if it is not added yet
      if (!filteredOptions || filteredOptions.length === 0) {
          this.intervals.push(layerOption);
      }
    }
    this.onSettingChange();
  }

  public onUpdateRepeatSummary() {
    const iat = this.intervalsAltTxt[this.easeCalendarRepeat.interval];
    const plural: boolean = (this.easeCalendarRepeat.intervalEvery > 1);
    const unitsLabelString = plural ? iat.labelp : iat.label;

    this.repeatSummary = (this.easeCalendarRepeat.interval === 0) ? "Does not repeat" : "Repeats every " + unitsLabelString;
  }

  public openDatePicker($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.isDatePickerOpen = true;
  }

  public onSettingChange() {

    this.isLimitOptionsEnabled = (this.easeCalendarRepeat.interval === 0 || !this.easeCalendarRepeat.includeCutoffOptions) ? false : true;
  }

  public onModelChanged() {
    this.onSettingChange();
    this.layerService.sendMessage("calendar model changed");
    this.modelChanged.emit();
  }

  public onValueChanged() {
    this.modelChanged.emit();
  }

  public getRepeatData(): RepeatingEventData {
    return this.easeCalendarRepeat;
  }

  public onDayOfWeekChange(): void {
    this.easeCalendarRepeat.hasSelectedDayOfWeek = false;
    for (let idx = 0; idx < this.easeCalendarRepeat.repeatSchedule.length; idx++) {
      this.easeCalendarRepeat.repeatsOn[idx] = (this.easeCalendarRepeat.repeatSchedule[idx].isSelected ? 1 : 0);
      if (this.easeCalendarRepeat.repeatSchedule[idx].isSelected) {
        this.easeCalendarRepeat.hasSelectedDayOfWeek = true;
      }
    }
    this.layerService.sendMessage("calendar model changed");
    this.modelChanged.emit();
  }
}
