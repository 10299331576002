﻿// Todo: needs refactoring
import { Injectable, Pipe, PipeTransform, NgModule } from "@angular/core";
import * as moment from "moment";
import { CurrentInfoService } from "../core/services/current.service";
import { LocalizeService } from "../core/services/localize.service";

// Pipes
import { i18n } from "./pipes/i18n.pipe";
import { EmptyPipe } from "./pipes/empty.pipe";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";

@Pipe({
    name: "filter",
    pure: false,
})

export class FilterPipe implements PipeTransform {
    public transform(items: any[], conditions: { [field: string]: any }): any[] {
        return items.filter(item => {
            for (const field in conditions) {
                if (item[field] !== conditions[field]) {
                    return false;
                }
            }
            return true;
        });
    }
}

@Pipe({
    name: "unique",
    pure: false,
})
export class UniquePipe implements PipeTransform {

    public transform(value: any, args?: any): any {

        // Remove the duplicate elements
        return value.filter((el: any, index: any, array: any) => {
            return array.indexOf (el) === index;
        });
    }
}

@Pipe({
    name: "dateInternationalize",
})
export class DateInternationalizePipe implements PipeTransform {

    constructor(private current: CurrentInfoService) {
    }

    public transform(value: any, siteOffset?: any): any {
        if (!value) { return value; }

        if (!siteOffset) {
            siteOffset = this.current.OffsetByDate(value);
        }

        return moment(moment(value).utcOffset((siteOffset))
            .format("YYYY-MM-DD HH:mm:ss"))
            .toDate()
            .getTime();
    }
}

@Pipe({
    name: "truncate",
})
export class TruncatePipe implements PipeTransform {

    constructor(private current: CurrentInfoService) {
    }

    public transform(text: string, length: number, end?: string): any {
        if (text !== undefined) {
            if (text == null) {
                return null;
            }

            if (isNaN(length)) {
                length = 10;
            }

            if (end === undefined) {
                end = "...";
            }

            if (text.length <= length || text.length - end.length <= length) {
                return text;
            } else {
                return String(text).substring(0, length - end.length) + end;
            }
        }
    }
}

@Pipe({
  name: "excludeSelectedCapaUser",
  pure: false,
})

@Injectable()
export class ExcludeSelectedCapaUserPipe implements PipeTransform {
  constructor(private localize: LocalizeService) {
  }

  public transform(items, objectToKeep,  mandatoryCapaUser, optionalCapaUsers) {
    let result = [];
    if (items && items.length) {
      if (!objectToKeep && !mandatoryCapaUser && (!optionalCapaUsers || optionalCapaUsers.length === 0)) {
        result = items;
      } else {
        if (mandatoryCapaUser && objectToKeep && mandatoryCapaUser !== objectToKeep) {
          result.push(objectToKeep);
        }
        if (optionalCapaUsers && optionalCapaUsers.length) {
          items.forEach((item) => {
            if (optionalCapaUsers.indexOf(item) < 0) {
              if (mandatoryCapaUser) {
                if (item !== mandatoryCapaUser) { // only add the mandatory user if it has no value
                  result.push(item);
                }
              } else {
                result.push(item);
              }
            }
          });
        }
      }
    }
    return result;
  }
}

@NgModule({
    imports: [],
    exports: [
        DateInternationalizePipe,
        FilterPipe,
        UniquePipe,
        TruncatePipe,
        ExcludeSelectedCapaUserPipe,
      i18n,
      EmptyPipe,
      YesNoPipe,
      SafeHtmlPipe,
    ],
    declarations: [
        DateInternationalizePipe,
        FilterPipe,
        UniquePipe,
        TruncatePipe,
        ExcludeSelectedCapaUserPipe,
      i18n,
      EmptyPipe,
      YesNoPipe,
      SafeHtmlPipe,
    ],
    providers: [
      DateInternationalizePipe,
    ],
})

export class PipesModule {}
