import { Inject, Injectable, NgZone } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { BehaviorSubject,  Observable,  ReplaySubject } from "rxjs";
import { ApiService } from "../api.service";
import { CurrentInfoService } from "../current.service";
import { IdentityService } from "../identity.service";
import { LocalService } from "../local.service";
import { NotifyService } from "../notify.service";
import { StorageKey, StorageService } from "../storage.service";
import { ToolsService } from "../tools.service";
import { UiNotificationService } from "./ui-notification.service";

import { EaseMitigationStatus, IClientAuditAnswer, IClientAuditQuestion, IMitigationActivity, IMitigationArtifact, IMitigationDetail } from "../../models/ease-models";

import * as _ from "lodash";
import "rxjs/Rx";

@Injectable()
export class MitigationArtifactService {
    private isCordova: boolean;

    constructor(private api: ApiService,
                private notify: NotifyService,
                private tools: ToolsService,
                private local: LocalService,
                private storage: StorageService,
                private identity: IdentityService,
                private current: CurrentInfoService,
                private log: LoggerService,
                private zone: NgZone,
                private uiNotify: UiNotificationService) {
        this.isCordova = this.tools.isCordova();
    }

    public getMitigationArtifactByMitigationIDOnline(id: number): Observable<any[]> {
        const result = new ReplaySubject<any[]>(1);
        this.api.Query("mitigationartifact", { mitigationID: IdentityService, thumbnail: true }, (artifacts: any[]) => {
            this.zone.run(() => {
                result.next(artifacts);
                result.complete();
            });
        });
        return result.asObservable();
    }

    public getMitigationArtifactByMitigationID(id: number): Observable<any> {
        const toDeletekey = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", "FileToDelete_" + id);
        const toAddkey = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", id);
        const result = new ReplaySubject<any>(1);

        if (this.tools.isApp())  {
            this.storage.containsObject(toDeletekey).subscribe((isExists: boolean) => {
                if (isExists) {
                    this.storage.retrieveObject(toDeletekey).subscribe((data: any) => {
                        this.retrieveArtifactsToBeAdded(toAddkey).subscribe((addArtifacts: any) => {
                            result.next({ toDeleteArtifacts: data, toAddArtifacts: addArtifacts });
                            result.complete();
                        });
                    }, (err: any) => {
                        this.log.error("failed to retrieve object in getMitigationArtifactByMitigationID: " + JSON.stringify(err));
                        result.error(err);
                    });
                } else {
                    this.retrieveArtifactsToBeAdded(toAddkey).subscribe((addArtifacts: any) => {
                        result.next({ toDeleteArtifacts: [], toAddArtifacts: addArtifacts });
                        result.complete();
                    });
                }
            });
        } else {
            const keyStr: string = this.storage.keyAsString(toDeletekey);
            let data: any = [];
            if (this.local.containsItem(keyStr)) {
                data = JSON.parse(this.local.getItem(keyStr));
            }
            const addKeyStr: string = this.storage.keyAsString(toAddkey);
            let addData: any = [];
            if (this.local.containsItem(addKeyStr)) {
                addData = JSON.parse(this.local.getItem(addKeyStr));
            }
            result.next({ toDeleteArtifacts: data, toAddArtifacts: addData });
            result.complete();
        }

        return result.asObservable();
    }

    private retrieveArtifactsToBeAdded(key: StorageKey): Observable<any> {
        const result = new ReplaySubject<any>(1);
        this.storage.containsObject(key).subscribe((fileExists: boolean) => {
            if (fileExists) {
                this.storage.retrieveObject(key).subscribe((addData: any) => {
                    result.next(addData);
                    result.complete();
                }, (err: any) => {
                    this.log.error("failed to retrieve to add mitigationartifacts error" + JSON.stringify(err));
                    result.error(err);
                });
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public syncMitigationArtifacts(onlineMitigations: IMitigationDetail[]): Observable<any[]> {
        const artifactsToDelete: any[] = [];
        const artifactsToAdd: any[] = [];
        const result = new ReplaySubject<any>(1);
        this.getMitigationArtifactsInFileSystem(onlineMitigations).subscribe((contents: any[]) => {
            this.zone.run(() => {
                const offlineAddedArtifactIDs: number[] = [];
                const offlineDeletedArtifactIDs: number[] = [];
                for (const content of contents) {
                    const deleteArtifacts = content.toDeleteArtifacts;
                    const addArtifacts = content.toAddArtifacts;

                    if (deleteArtifacts != null && deleteArtifacts.length > 0) {
                        for (const detail of onlineMitigations) {
                            if (detail.Mitigation.ID == deleteArtifacts[0].MitigationID) {
                                this.log.debug("syncMitigationArtifacts mitigation status: " + detail.Mitigation.Status);
                                if (detail.Mitigation.Status != EaseMitigationStatus.Closed) {
                                    for (const item of deleteArtifacts) {
                                        this.log.debug("syncMitigationArtifacts: " + item.ID);
                                        artifactsToDelete.push({ MitigationID: detail.Mitigation.ID, Artifact: item });
                                    }
                                }
                                break;
                            }
                        }
                    }

                    if (addArtifacts != null && addArtifacts.length > 0) {
                        for (const item of addArtifacts) {
                            if (item.ID == 0) {
                                artifactsToAdd.push(item);
                            }
                        }
                    }
                }

                if (artifactsToDelete.length > 0 && this.tools.isOnline()) {
                  const snackBarRef = this.uiNotify.open("_SyncingInProgress_", true);
                  snackBarRef.afterOpened().subscribe(() => {
                    artifactsToDelete.forEach((item, index) => {
                        this.api.Delete("mitigationartifact", item.Artifact.ID, (deleteData: any) => {
                            if (artifactsToDelete.length - 1 == index) {
                                this.deleteMitigationArtifactFiles("FileToDelete_" + item.MitigationID, () => {});
                                this.addMitigationArtifacts(artifactsToAdd).subscribe((addedMitigationArtifacts: any[]) => {
                                    result.next({ artifactsDeleted: artifactsToDelete, artifactsAdded: addedMitigationArtifacts });
                                    result.complete();
                                });
                            }
                        }, () => {
                            if (artifactsToDelete.length - 1 == index) {
                                result.next({ artifactsDeleted: artifactsToDelete, artifactsAdded: [] });
                                result.complete();
                            }
                        }, () => {});
                    });
                  });
                } else {
                    this.addMitigationArtifacts(artifactsToAdd).subscribe((addedMitigationArtifacts: any[]) => {
                        result.next({ artifactsDeleted: artifactsToDelete, artifactsAdded: addedMitigationArtifacts });
                        result.complete();
                    });
                }

            });
        });

        return result.asObservable();
    }

    private getMitigationArtifactsInFileSystem(onlineMitigations: IMitigationDetail[]) {
        const artifactsInFileSystem: Array<Observable<any>> = [];
        for (const detail of onlineMitigations) {
            //this.pushIfUnique(artifactsInFileSystem, this.getArtifactFileContent(detail.Mitigation.ID));
            artifactsInFileSystem.push(this.getArtifactFileContent(detail.Mitigation.ID));
        }
        if (artifactsInFileSystem && artifactsInFileSystem.length == 0) {
            //return empty file content observable if there are no artifacts to process
            artifactsInFileSystem.push(Observable.create((result: any) => {
                result.next({ toDeleteArtifacts: [], toAddArtifacts: [] });
                result.complete();
            }));
        }
        return Observable.forkJoin(artifactsInFileSystem);
    }

    public pushIfUnique(artifacts: Array<Observable<any>>, arrayVal: any) {
        if (artifacts.indexOf(arrayVal) == -1) {
            artifacts.push(arrayVal);
        }

    }

    private getArtifactFileContent(mitigationID: number): Observable<any[]> {
        return this.getMitigationArtifactByMitigationID(mitigationID).take(1).map(detail => {
            return detail;
        });
    }

    private processMitigations(mitigationDetails: IMitigationDetail[]) {
        const syncMitigations: Array<Observable<any>> = [];
        for (const detail of mitigationDetails) {
            syncMitigations.push(this.updateMitigation(detail.Mitigation));
        }
        return Observable.forkJoin(syncMitigations);
    }

    private updateMitigation(mitigation: IMitigationActivity): Observable<any> {
        const result = new ReplaySubject<any>(1);
        const isAdHoc: boolean = !mitigation.AuditID;
        let param = {};

        if (mitigation.Status == EaseMitigationStatus.Open && mitigation.Comment != null && mitigation.ApprovingPartyComment != null) {
            param = { sendRejectMitigationEmail: true, isAdHoc };
        } else if (mitigation.Status == EaseMitigationStatus.Pending) {
            param = { updateApprovingParty: isAdHoc, isAdHoc };
        } else {
            param = { isAdHoc };
        }

        this.api.UpdateX("Mitigation", mitigation.ID, mitigation, param, (data: any) => {
            this.zone.run(() => {
                result.next(mitigation);
                result.complete();
            });
        });
        return result.asObservable();
    }

    private deleteMitigationArtifactFiles(entityID: string, callback: any): void {
        // remove in mitigation detail artifact from file system after
        const key = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", entityID);
        if (this.tools.isApp()) {
            this.storage.deleteObject(key).subscribe(() => {
                this.zone.run(() => {
                    if (callback) { callback(); }
                });
            }, (err: any) => {
                this.log.error("failed to delete mitigation detail artifact file " + JSON.stringify(err));
            });
        } else {
            const keyStr: string = this.storage.keyAsString(key);
            this.local.removeItem(keyStr);

            if (callback) { callback(); }
        }
    }

    private deleteMitigationFiles(mitigationID: number, callback: any): void {
        // remove in mitigation infos from file system after
        const key = this.storage.buildCurrentStorageKey("Mitigations", mitigationID);
        if (this.tools.isApp()) {
            this.storage.deleteObject(key).subscribe(() => {
                this.zone.run(() => {
                    if (callback) { callback(); }
                });
            }, (err: any) => {
                this.log.error("failed to delete mitigation file " + JSON.stringify(err));
            });
        } else {
            const keyStr: string = this.storage.keyAsString(key);
            this.local.removeItem(keyStr);

            if (callback) { callback(); }
        }
    }

    private addMitigationArtifacts(artifactsToAdd: any[]): Observable<any> {
        const mitigationIDs: number[] = [];
        const result = new ReplaySubject<any>(1);
        if (artifactsToAdd.length > 0 && this.tools.isOnline()) {
          const snackBarRef = this.uiNotify.open("_SyncingInProgress_", true);
          snackBarRef.afterOpened().subscribe(() => {
            artifactsToAdd.forEach((file, index) => {
                this.api.AddX("mitigationartifact", file, { id: file.MitigationID }, () => {
                    mitigationIDs.push(file.MitigationID);
                    this.deleteMitigationArtifactFiles(file.MitigationID.toString(), () => {});
                    if (artifactsToAdd.length - 1 == index) {
                        this.getMitigationArtifactsOnline(mitigationIDs).subscribe((artifacts: any[]) => {
                            result.next(artifacts);
                            result.complete();
                        });
                    }
                }, () => {
                    if (artifactsToAdd.length - 1 == index) {
                        result.next([]);
                        result.complete();
                    }
                });
            });
          });
        } else {
            result.next([]);
            result.complete();
        }
        return result.asObservable();
    }

    private getMitigationArtifactsOnline(mitigationIDs: number[]) {
        const mitigationArtifacts: Array<Observable<any>> = [];
        for (const id of mitigationIDs) {
            mitigationArtifacts.push(this.getMitigationArtifactContentOnline(id));
        }
        if (mitigationArtifacts && mitigationArtifacts.length == 0) {
            //return empty file content observable if there are no artifacts to process
            mitigationArtifacts.push(Observable.create((result: any) => {
                result.next({ mitigationID: 0, mitigationArtifacts: [] });
                result.complete();
            }));
        }
        return Observable.forkJoin(mitigationArtifacts);
    }

    private getMitigationArtifactContentOnline(mitigationID: number): Observable<any> {
        return this.getMitigationArtifactByAuditIDOnline(mitigationID).take(1).map(detail => {
            return detail;
        });
    }

    public getMitigationArtifactByAuditIDOnline(id: number): Observable<any> {
        const result = new ReplaySubject<any>(1);
        this.api.Query("mitigationartifact", { thumbnail: true, mitigationID: id }, (artifacts: IMitigationArtifact[]) => {
            this.zone.run(() => {
                result.next({ mitigationID: id, mitigationArtifacts: artifacts });
                result.complete();
            });
        });
        return result.asObservable();
    }
}
