import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@app/../environments/environment";
import { Observable } from "rxjs/Rx";
import { RssEntry } from "../../models/rss";

@Injectable()
export class RssParserService {

  rssUrl: string = "";

  constructor(private http: HttpClient) {
    this.rssUrl = environment.remSvcUrl;
  }

  getNewRssData(): Observable<RssEntry[]> {
    return this.http.get<RssEntry[]>(this.rssUrl + "/rssfeed");
  }
}
