import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IUserActivity } from "@core/models/ease-models";

@Component({
  selector: "ease-my-activities",
  templateUrl: "./my-activities.component.html",
  styleUrls: ["./my-activities.component.scss"],
})
export class MyActivitiesComponent {
  @Input() userActivities;
  @Input() isLoading: boolean;
  @Input() staticHeight: boolean;
  @Output() activityClick = new EventEmitter<any>();

  public manageActivity(a: IUserActivity) {
    this.activityClick.emit(a);
  }
}
