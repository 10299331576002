import { Action } from "@ngrx/store";

// Models
import { UserSession, ChangeLanguagePayload } from "../models/user";

export enum SessionActionTypes {
  InitApp = "[Session] Init App",

  RestoreSession = "[Session] Restore Session",
  RestoreSessionSuccess = "[Session] Restore Session Success",
  RestoreSessionFailure = "[Session] Restore Session Failure",

  ChangeLanguage = "[Session] Change Language",
  ChangeLanguageSuccess = "[Session API] Change Language Success",
  ChangeLanguageFailure = "[Session API] Change Language Failure",

  GetTranslation = "[Session] Get Translation",
  GetTranslationSuccess = "[Session API] Get Translation Success",
  GetTranslationFailure = "[Session API] Get Translation Failure",

  GetCustomTranslation = "[Session] Get Custom Translation",
  GetCustomTranslationSuccess = "[Session API] Get Custom Translation Success",
  GetCustomTranslationFailure = "[Session API] Get Custom Translation Failure",
}

// Init App
export class InitApp implements Action {
  readonly type = SessionActionTypes.InitApp;
}

// Restore User Session.
export class RestoreSession implements Action {
  readonly type = SessionActionTypes.RestoreSession;
}
export class RestoreSessionSuccess implements Action {
  readonly type = SessionActionTypes.RestoreSessionSuccess;

  constructor(public payload: UserSession) {
  }
}
export class RestoreSessionFailure implements Action {
  readonly type = SessionActionTypes.RestoreSessionFailure;

  constructor(public payload: UserSession) {
  }
}

// Translation
export class ChangeLanguage implements Action {
  readonly type = SessionActionTypes.ChangeLanguage;

  constructor(public payload: ChangeLanguagePayload) {
  }
}
export class ChangeLanguageSuccess implements Action {
  readonly type = SessionActionTypes.ChangeLanguageSuccess;

  constructor(public payload: any) {
  }
}
export class ChangeLanguageFailure implements Action {
  readonly type = SessionActionTypes.ChangeLanguageFailure;

  constructor(public payload: any) {
  }
}
export class GetTranslation implements Action {
  readonly type = SessionActionTypes.GetTranslation;

  constructor(public payload: string) {
  }
}
export class GetTranslationSuccess implements Action {
  readonly type = SessionActionTypes.GetTranslationSuccess;

  constructor(public payload: any) {
  }
}
export class GetTranslationFailure implements Action {
  readonly type = SessionActionTypes.GetTranslationFailure;

  constructor(public payload: any) {
  }
}
export class GetCustomTranslation implements Action {
  readonly type = SessionActionTypes.GetCustomTranslation;

  constructor(public payload: number) {
  }
}
export class GetCustomTranslationSuccess implements Action {
  readonly type = SessionActionTypes.GetCustomTranslationSuccess;

  constructor(public payload: any) {
  }
}
export class GetCustomTranslationFailure implements Action {
  readonly type = SessionActionTypes.GetCustomTranslationFailure;

  constructor(public payload: any) {
  }
}

export type SessionActionsUnion =
  | InitApp

  | RestoreSession
  | RestoreSessionSuccess
  | RestoreSessionFailure

  | ChangeLanguage
  | ChangeLanguageSuccess
  | ChangeLanguageFailure

  | GetTranslation
  | GetTranslationSuccess
  | GetTranslationFailure

  | GetCustomTranslation
  | GetCustomTranslationSuccess
  | GetCustomTranslationFailure;
