import { NgModule } from "@angular/core";

// Modules
import { SharedModule } from "@shared/shared.module";
import { FileUploadModule } from "ng2-file-upload";
import { ResultsModule } from "@app/results/results-module";
import { RouterModule } from "@angular/router";

// Components
import { CapaEventFormComponent } from "@app/capa/components/capa-event-form/capa-event-form.component";
import { CapaEventTaskDetailsComponent } from "@app/capa/components/capa-event-task-details/capa-event-task-details.component";
import { CapaPrintComponent } from "@app/capa/containers/capa-print/capa-print.component";
import { CapaEventDetailsComponent } from "@app/capa/containers/capa-event-details/capa-event-details.component";
import { CorrectiveActionsListComponent } from "@app/capa/components/capa-event-list/corrective-actions.component";
import { CapaHistoryComponent } from "@app/capa/containers/capa-history/capa-history.component";

// Dialog Components
import { AddCApaEventModalComponent } from "@app/capa/dialogs/add-capa-event/add-capa-event-modal.component";
import { CorrectiveActionModalComponent } from "@app/capa/dialogs/capa-list/corrective-actions-modal.component";
import { ExtendCapaEventDueModalComponent } from "@app/capa/dialogs/capa-extend-date/extend-capa-event-due-modal.component";
import { AddEditCapaTaskModalComponent } from "@app/capa/dialogs/edit-capa-task/add-edit-capa-task-modal.component";

// Services
import { CorrectiveActionsDataAccessService } from "@app/capa/services/corrective-actions-data-access.service";
import { MitigationStatusService } from "@app/capa/services/mitigation-status.service";

const COMPONENTS = [
  CapaEventFormComponent,
  CapaEventTaskDetailsComponent,
  AddEditCapaTaskModalComponent,
  CapaPrintComponent,
  CapaEventDetailsComponent,
  AddCApaEventModalComponent,
  CorrectiveActionModalComponent,
  ExtendCapaEventDueModalComponent,
  CorrectiveActionsListComponent,
  CapaHistoryComponent,
];

const MODAL_COMPONENTS = [
  AddEditCapaTaskModalComponent,
  AddCApaEventModalComponent,
  CorrectiveActionModalComponent,
  ExtendCapaEventDueModalComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    RouterModule,
    SharedModule,
    FileUploadModule,
    ResultsModule,
  ],
  providers: [
    CorrectiveActionsDataAccessService,
    MitigationStatusService,
  ],
  exports: [
    ...COMPONENTS,
  ],
  entryComponents: [
    ...MODAL_COMPONENTS,
  ],
})

export class CapaModule {}
