import { take } from "rxjs/operators";

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { LoggerService } from "@core/services/logger.service";

import { Config } from "./config.service";
import { LocalService } from "./local.service";
import { NotifyService } from "./notify.service";
import { ToolsService } from "./tools.service";
import { environment } from "@app/../environments/environment";

import { Observable,  ReplaySubject  } from "rxjs";

// State
import { select, Store } from "@ngrx/store";
import { Login, Logout } from "app/auth/actions/auth";
import * as fromAuth from "app/auth/reducers";

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import * as _ from "lodash";

export interface EaseEndpoints {
  Name: string;
  Service: string;
  Refresh: string;
  SSO: string;
  Analytics?: string;
}

export enum AuthResponseStatus {
  SSOMustRedirect = -10,
  BadMobileVersion = -9,
  NoRolesAssigned = -8,
  BadCredentialsCaptchaRequired = -7,
  BadCredentialsLastAttempt = -6,
  BadCredentials = -5,
  OrgDisabled = -4,
  UserDisabled = -3,
  SecurityLocked = -2,
  UnknownError = -1,
  Success = 0,
}

export class AuthenticationRequest {
  public Login: string;
  public Password: string;
}

export class AuthenticationResponse {
  public Token: string;
  public Language: string;
  public Subdomain?: string;
  public Status: number;
}

@Injectable()
export class IdentityService {

  private statusMap: any[] =
    [
      { key: AuthResponseStatus.NoRolesAssigned, val: "_YourAccountNotAuthorized_" },
      { key: AuthResponseStatus.BadCredentialsCaptchaRequired, val: "_BadCredentialsCaptchaRequired_" },
      { key: AuthResponseStatus.BadCredentialsLastAttempt, val: "_BadCredentialsLastAttempt_" },
      { key: AuthResponseStatus.BadCredentials, val: "_InvalidUsernamePassword_" },
      { key: AuthResponseStatus.OrgDisabled, val: "_AccessDisabled_" },
      { key: AuthResponseStatus.UserDisabled, val: "_InvalidUsernamePassword_" },
      { key: AuthResponseStatus.SecurityLocked, val: "_SecurityLocked_" },
      { key: AuthResponseStatus.UnknownError, val: "_UnknownError_" },
      { key: AuthResponseStatus.Success, val: "_Success_" },
      { key: AuthResponseStatus.BadMobileVersion, val: "_BadMobileVersion_" },
      { key: AuthResponseStatus.BadMobileVersion, val: "_SSOMustRedirect_" },
    ];

  private eendpoints: EaseEndpoints = null;

  get endpoints(): EaseEndpoints {
    return this.eendpoints;
  }

  get loginid(): string {
    let ret: string = null;
    try {
      ret = this.local.getItem("identity.service.loginid");
    } catch (e) {
      this.log.debug("identity.service: failed to retrieve loginid");
    }
    return ret;
  }

  constructor(
              private store: Store<fromAuth.State>,
              private log: LoggerService,
              private http: HttpClient,
              private config: Config,
              private tools: ToolsService,
              private local: LocalService,
              private notify: NotifyService) {
    this.local.removeItem("identity.service.env"); // no longer used, clean up
    this.eendpoints = this.buildEndpoints();
    this.log.debug("identity.service: endpoints = (" + this.endpoints.Name + ")");
  }

  /**
   * @deprecated Use Login actions instead.
   */
  public login(path: string, request: AuthenticationRequest, callback: any, errorCallback: any) {
    this.log.debug("identity.service: attempting service login to authentication");
    this.http.post<AuthenticationResponse>(path, request, { observe: "response" }).subscribe(
      (response: HttpResponse<AuthenticationResponse>) => {
        if (response.body.Status === 0) {
          this.local.setItem("identity.service.token", response.body.Token);
          this.local.setItem("identity.service.tokenRefreshDate", (new Date()).toString());
          this.local.setItem("identity.service.loginid", request.Login);
          this.log.debug("identity.service: authenticated as " + request.Login);
        }
        if (callback) {
          callback(response.body);
        }
        this.notify.loggedIn.value = true;
        // this.store.dispatch(new Login());
      },
      (err: HttpErrorResponse) => {
        this.logout();
        let response: AuthenticationResponse = null;
        if (err.error != null) {
          response = err.error;
        }

        this.log.debug("identity.service: authentication failed [" + err.status + (response != null ? ("," + this.mapStatusToi18n(response.Status)) : "") + "]");
        errorCallback(err.status, response);
      },
    );
  }

  /**
   * @deprecated Use Logout action instead.
   */
  public logout(returnUrl = "") {
    this.notify.loggedIn.value = false;

    if (this.local.containsItem("identity.service.loginid")) {
      this.local.removeItem("identity.service.loginid");
    }

    if (this.local.containsItem("identity.service.token")) {
      this.local.removeItem("identity.service.token");
      this.log.debug("identity.service: removed stored local identity");
    }
  }

  public mapStatusToi18n(status: AuthResponseStatus): string {
    let val: string = "_Error_";
    const row: any = _.find(this.statusMap, (r) => r.key === status);
    if (row) {
      val = row.val;
    }
    return val;
  }

  public isLoggedIn(): boolean {
    let isLoggedIn = false;

    this.store.pipe(
      select(fromAuth.getLoggedIn),
      take(1),
    ).subscribe(value => isLoggedIn = value);

    return isLoggedIn;
  }

  // TODO Check usages
  /**
   * @deprecated Use RefreshToken action instead.
   */
  public refreshToken(activeSiteID: number, languageName: string) {

    if (!this.isLoggedIn()) {
      this.log.warn("identity.service: refreshToken ignored (not logged in)");
      return;
    }

    if (!activeSiteID) {
      const activeSiteStr = this.local.getItem("activeSiteID");
      activeSiteID = Number(activeSiteStr);
    }

    const url: string = this.endpoints.Refresh;
    const request: any = { siteID: activeSiteID, languageName };

    const tauth = "Token " + this.local.getItem("identity.service.token");
    const options: any = {
      headers: new HttpHeaders().set("Authorization", tauth),
      observe: "response",
    };

    this.http.post<AuthenticationResponse>(url, request, options).subscribe(
      (response: HttpResponse<AuthenticationResponse>) => {
        if (response.body.Status === 0) {
          this.local.setItem("identity.service.token", response.body.Token);
          this.log.debug("identity.service: refreshed token with " + url);
        }
        this.notify.broadcast("token-refreshed");
      },
      (err: HttpErrorResponse) => {
        this.logout();
        let response: AuthenticationResponse = null;
        if (err.error && "Token" in err.error) {
          response = err.error;
        }
        this.log.debug("identity.service: token refresh failed [" + err.status + (response != null ? ("," + this.mapStatusToi18n(response.Status)) : "") + "]");
      },
    );
  }

  /**
   * @deprecated auth.service.ts isSSOActiveHost() instead.
   */
  public isSSOActiveHost(hostname: string): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const url = this.endpoints.Service + "/Subdomain";

    this.http.get<boolean>(url, {
      observe: "body",
      params: { activeSSOCheck: hostname },
      reportProgress: false,
      responseType: "json",
      withCredentials: false,
      }).subscribe((active: boolean) => {
        result.next(active);
        result.complete();
      }, (error: any) => {
        result.error(error);
      });
    return result;
  }

  public buildEndpoints(): EaseEndpoints {
    // developers running on localhost & cordova mode always use apiUrl from current environment
    // developers: edit environment.apiUrl to point to a remote Ease.Service
    // web app otherwise connects to an Ease.Service api on the same website origin that the SPA is running
    const isLocalHost = window.location.origin.indexOf("localhost") > -1;
    const service: string = (isLocalHost || this.tools.isCordova()) ? environment.apiUrl : window.location.origin + "/svc/api";

    return {
      Name: environment.name,
      Service: service,
      Refresh: service + "/RefreshToken",
      SSO: new URL(service).origin + "/Account/LoginSSO",
      Analytics: environment.analytics,
    };
  }
}
