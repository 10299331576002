
import { Component, Inject } from "@angular/core";
import {  MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

import * as moment from "moment";
import { CurrentInfoService, LocalizeService } from "@core/services";
import { AuditReassignDeleteService } from "./audit-reassign.service";

@Component({
  templateUrl: "./confirm-holiday-modal.component.html",
})
export class ConfirmHolidayModalComponent {
  public message: string;
  private startDate: Date;
  private endDate: Date;
  private locationID: number;
  private isSiteCalendar: boolean = false;

  private selected: any;
  constructor(
      public dialogRef: MatDialogRef<ConfirmHolidayModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private localize: LocalizeService,
      private Current: CurrentInfoService,
      private auditDeleteService: AuditReassignDeleteService) {

          this.selected = data.selected;
          const start = moment(this.selected.start).toDate();
          const end = moment(this.selected.end).add("day", 1).toDate();

          // convert edited date based on site's time zone
          let sdt = new Date(0, 0, 0, 0, 0, 0, 0);
          sdt.setFullYear(start.getFullYear(), start.getMonth(), start.getDate());
          let edt = new Date(0, 0, 0, 0, 0, 0, 0);
          edt.setFullYear(end.getFullYear(), end.getMonth(), end.getDate());

          if (!this.Current.IsUsingSiteTimeZone()) {
              sdt = this.Current.SiteDateInUTC(sdt);
              edt = this.Current.SiteDateInUTC(edt);
          }

          this.startDate = sdt;
          this.endDate = edt.addSeconds(-1);

          this.locationID = this.selected.locationID;
          this.isSiteCalendar = this.selected.isSiteCalendar;

          const args = [];
          args.push(this.selected.totalAudits);
          args.push(this.selected.holidayDates.join(", "));
          this.message = this.localize.getLocalizedString("_XAuditsHaveBeenAssignedOnY_", args);
      }

  public ok(isDelete: boolean): void {
    if (isDelete) {
      this.auditDeleteService.auditReasssignDelete(
        0, 0, this.startDate.toUTCString(), this.endDate.toUTCString(),
        false, true, this.locationID, this.isSiteCalendar)
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    }
  }

}
