import { map } from "rxjs/operators";

import { Component, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

// State
import { select, Store } from "@ngrx/store";
import * as AuthActions from "../actions/auth";
import * as fromAuth from "../reducers";

// Models
import { ApiError } from "@core/models/api";
import { PasswordChange, SetPassword } from "../models/user";
import { RestorePasswordErrorCodes } from "../models/api";

@Component({
  selector: "ease-set-password",
  template: `
    <ease-auth-page>
      <ease-set-password-form (submitted)="onSubmitted()" (passwordChanged)="onPasswordChanged($event)"
                              [error]="error$ | async" [pending]="pending$ | async"

                              [haveLength]="haveLength$ | async" [haveLowercase]="haveLowercase$ | async"
                              [haveNumber]="haveNumber$ | async" [haveSpecialChar]="haveSpecialChar$ | async"
                              [haveUppercase]="haveUppercase$ | async" [passwordsMatch]="passwordsMatch$ | async">

        <mat-card-title class="title">
          <ng-content select=".title"></ng-content>
        </mat-card-title>
        <mat-card-subtitle class="subtitle">
          <ng-content select=".subtitle"></ng-content>
        </mat-card-subtitle>
        <span class="submit">
          <ng-content select=".submit"></ng-content>
        </span>

      </ease-set-password-form>
    </ease-auth-page>
  `,
})
export class SetPasswordComponent {
  @Output() submitted = new EventEmitter<SetPassword>();

  password: string;

  pending$ = this.store.pipe(select(fromAuth.getSetPasswordPagePending));
  error$ = this.store.pipe(
    select(fromAuth.getSetPasswordPageError),
    map(this.errorsMap.bind(this)),
  );

  haveNumber$ = this.store.pipe(select(fromAuth.getSetPasswordPageHaveNumber));
  haveLowercase$ = this.store.pipe(select(fromAuth.getSetPasswordPageHaveLowercase));
  haveUppercase$ = this.store.pipe(select(fromAuth.getSetPasswordPageHaveUppercase));
  haveSpecialChar$ = this.store.pipe(select(fromAuth.getSetPasswordPageHaveSpecialChar));
  haveLength$ = this.store.pipe(select(fromAuth.getSetPasswordPageHaveLength));

  passwordsMatch$ = this.store.pipe(select(fromAuth.getSetPasswordPagePasswordsMatch));

  constructor(private store: Store<fromAuth.State>,
              private route: ActivatedRoute) {
  }

  onSubmitted() {
    const password = this.password;
    const token = this.route.snapshot.params.token;

    this.submitted.emit({ password, token });
  }

  onPasswordChanged($event: PasswordChange) {
    this.password = $event.password;

    this.store.dispatch(new AuthActions.PasswordChanged($event));
  }

  private errorsMap(error: ApiError) {
    if (!error) {
      return null;
    }

    switch (error.Code) {
      case RestorePasswordErrorCodes.Unknown:
        return "_Auth:SetPassword:UnknownError_";

      case RestorePasswordErrorCodes.EmailNotSent:
        return "_Auth:SetPassword:PasswordResetEmailNotSentError_";

      case RestorePasswordErrorCodes.TokenExpired:
        return "_Auth:SetPassword:PasswordResetTokenExpiredError_";

      case RestorePasswordErrorCodes.TokenNotValid:
        return "_Auth:SetPassword:PasswordResetTokenNotValidError_";
    }
  }
}
