import { Injectable, NgZone } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class EaseUIDateFormatService {
    constructor(private zone: NgZone) {
    }

    public buildDatePatternFromCurrentLocale(): string {
        const shortDate = moment.localeData().toString();
        switch (shortDate) {
            // for 'M/d/yy', 'MM/dd/yyyy', and default case return 'MM/dd/yyyy'
            case "M/d/yy":
            case "MM/dd/yyyy":
            default:
                return "MM/dd/yyyy";
            // break;

            // for 'd/M/yy', 'dd/MM/yyyy', and default case return 'dd/MM/yyyy'
            case "d/M/yy":
            case "dd/MM/yyyy":
                return "dd/MM/yyyy";
            // break;

            // for 'yy/d/M' and 'yyyy/dd/MM'return 'yyyy/dd/MM'
            case "yy/d/M":
            case "yyyy/dd/MM":
                return "yyyy/dd/MM";
            // break;

            // for 'yy/M/d' and 'yyyy/MM/dd'return 'yyyy/MM/dd'
            case "yy/M/d":
            case "yyyy/MM/dd":
                return "yyyy/MM/dd";
            // break;

            // for 'M.d.yy' and 'MM.dd.yyyy' return 'MM.dd.yyyy'
            case "M.d.yy":
            case "MM.dd.yyyy":
                return "MM.dd.yyyy";
            // break;

            // for 'd.M.yy', 'dd.MM.yyyy', and default case return 'dd.MM.yyyy'
            case "d.M.yy":
            case "dd.MM.yyyy":
                return "dd.MM.yyyy";
            // break;

            // for 'yy.d.M' and 'yyyy.dd.MM'return 'yyyy.dd.MM'
            case "yy.d.M":
            case "yyyy.dd.MM":
                return "yyyy.dd.MM";
            // break;

            // for 'yy.M.d' and 'yyyy.MM.dd'return 'yyyy.MM.dd'
            case "yy.M.d":
            case "yyyy.MM.dd":
                return "yyyy.MM.dd";
            // break;
        }
    }
}