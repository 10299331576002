import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ITag } from "@core/models/ease-models";

@Injectable()
export class TagSelectionService {

  constructor(private http: HttpClient) {
  }

  public getTags(): Observable<ITag[]> {
    return this.http.get<ITag[]>("/tag");
  }

  public addTag(tag: ITag): Observable<any> {
    return this.http.post<ITag>("/tag", tag);
  }
}
