import { Injectable } from "@angular/core";
import { Subject,  Observable,  ReplaySubject } from "rxjs";
import { ApiService } from "../../core/services/api.service";
import { IOrgLayer, IOrgLayerSiteDetail } from "../../core/models/ease-models";

@Injectable()
export class LayerService {
    private subject = new Subject<any>();

    constructor(private api: ApiService) {}
    public sendMessage(message: string) {
        this.subject.next(message);
    }

    public getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    public deleteOrgLayer(orgLayerID: number): Observable<boolean> {
        const result = new ReplaySubject<boolean>();
        this.api.Delete("orglayersitedetail", orgLayerID,
            () => result.next(true),
            () => result.next(false));
        return result.asObservable();
    }

    public getLayers(query: any) {
        const result = new ReplaySubject<any>(1);
        this.api.Query("orglayersitedetail", query, (data: IOrgLayerSiteDetail[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getOrgLayers(query: any) {
      const result = new ReplaySubject<any>(1);
      this.api.Query("orglayer", query, (data: IOrgLayer[]) => {
        if (data && data.length) {
          result.next(data);
          result.complete();
        } else {
          result.next([]);
          result.complete();
        }
      });
      return result.asObservable();
    }
}
