import { Action } from "@ngrx/store";

// Models
import {
  AddOrganization,
  OrganizationForm,
  OrganizationFormData,
} from "../models/organization";

export enum OrganizationActionTypes {
  Select = "[Organization] Select Organization",

  Changed = "[Organization] Form Data Changed",
  Create = "[Organization] Create",
  CreateSuccess = "[Organization API] Create Success",
  CreateFailure = "[Organization API] Create Failure",
  Reset = "[Organization] Reset Creation",

  Load = "[Organization] Load Organization",
  LoadSuccess = "[Organization API] Load Organization Success",
  LoadFailure = "[Organization API] Load Organization Failure",
  ResetDetails = "[Organization] Reset Details",
  SubmitDetails = "[Organization] Details Submit",
  SubmitDetailsSuccess = "[Organization API] Details Submit Success",
  SubmitDetailsFailure = "[Organization API] Details Submit Failure",
  ChangedDetails = "[Organization] Details Form Changed",
  InitialDetails = "[Organization] Details Form Initial Data",
}

// Select
export class Select implements Action {
  readonly type = OrganizationActionTypes.Select;

  constructor(public payload: number) {}
}

// Form
export class Changed implements Action {
  readonly type = OrganizationActionTypes.Changed;

  constructor(public payload: AddOrganization) {}
}

// Create Organization
export class Create implements Action {
  readonly type = OrganizationActionTypes.Create;
}
export class CreateSuccess implements Action {
  readonly type = OrganizationActionTypes.CreateSuccess;

  constructor(public payload: any) {}
}
export class CreateFailure implements Action {
  readonly type = OrganizationActionTypes.CreateFailure;

  constructor(public payload: any) {}
}
export class Reset implements Action {
  readonly type = OrganizationActionTypes.Reset;
}
// Details
export class ResetDetails implements Action {
  readonly type = OrganizationActionTypes.ResetDetails;
}
export class Load implements Action {
  readonly type = OrganizationActionTypes.Load;
}
export class LoadSuccess implements Action {
  readonly type = OrganizationActionTypes.LoadSuccess;

  constructor(public payload: OrganizationForm) {}
}
export class LoadFailure implements Action {
  readonly type = OrganizationActionTypes.LoadFailure;

  constructor(public payload: any) {}
}
export class SubmitDetails implements Action {
  readonly type = OrganizationActionTypes.SubmitDetails;
}
export class SubmitDetailsSuccess implements Action {
  readonly type = OrganizationActionTypes.SubmitDetailsSuccess;

  constructor(public payload: OrganizationForm) {}
}
export class SubmitDetailsFailure implements Action {
  readonly type = OrganizationActionTypes.SubmitDetailsFailure;

  constructor(public payload: any) {}
}
export class ChangedDetails implements Action {
  readonly type = OrganizationActionTypes.ChangedDetails;

  constructor(public payload: OrganizationFormData) {}
}
export class InitialDetails implements Action {
  readonly type = OrganizationActionTypes.InitialDetails;

  constructor(public payload: OrganizationFormData) {}
}

export type OrganizationActionsUnion =
  | Select

  | Changed
  | Create
  | CreateSuccess
  | CreateFailure
  | Reset

  | Load
  | LoadSuccess
  | LoadFailure
  | ResetDetails
  | SubmitDetails
  | SubmitDetailsSuccess
  | SubmitDetailsFailure
  | ChangedDetails
  | InitialDetails;
