import { Component, Input, Output, EventEmitter, ViewChild, forwardRef, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatInput } from "@angular/material";
import { isNullOrUndefined } from "util";

// Types
import { ITag } from "@core/models/ease-models";
import { ENTER, COMMA, TAB } from "@angular/cdk/keycodes";

// Services
import { TagSelectionService } from "./tag-selection.service";

// RxJs
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "ease-tag-selection",
  templateUrl: "./tag-selection.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TagSelectionComponent),
    },
  ],
})

export class TagSelectionComponent implements ControlValueAccessor, OnInit {

  public control: FormControl = new FormControl();

  public filteredTags$: Observable<ITag[]>;
  public separatorKeysCodes = [ENTER, COMMA, TAB];

  @Input() public selectedTags: ITag[] = [];
  @Input() public tags: ITag[] = null;
  @Input() public placeholder: string;
  @Input() public inputDisabled: boolean = null;
  @Input() public disableAddNew: boolean = false;

  @Output() public selectedChange = new EventEmitter<ITag[]>();

  @ViewChild("chipInput") chipInput: MatInput;

  public isLoading: boolean = true;

  constructor(private tagService: TagSelectionService) {
  }

  public onChange: any = () => {};
  public onTouched: any = () => {};

  public writeValue(tags: ITag[]) {
    if (tags) {
      if (this.selectedTags.length === 0) {
        this.selectedTags = tags.map(x => Object.assign({}, x));
      } else {
        this.selectedTags = tags;
      }

      this.filterOutSelected();
    }
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  public ngOnInit() {
    this.placeholder = this.placeholder === undefined ? "_Tags_" : this.placeholder;

    if (this.tags === null) {
      this.tagService.getTags().subscribe(results => {
        this.tags = results;
        this.filterOutSelected();
        this.setupTagFilter();
      });
    } else {
      this.setupTagFilter();
    }
  }

  public filter(val: string): ITag[] {
    if (isNullOrUndefined(val) || typeof(val) !== "string") {
      return this.tags;
    } else {
      return this.tags.filter(option =>
        option.Label !== null && option.Label.toLowerCase().indexOf(val.toLowerCase()) === 0);
    }
  }

  public selectTagHandler(change: MatAutocompleteSelectedEvent) {

    const tag = change.option.value;
    const filtered = this.selectedTags.filter(s => s.ID === tag.ID);

    if (filtered.length === 0) {
      this.selectedTags.push(tag);
      this.selectedTagsChanged();
    }

    this.chipInput["nativeElement"].value = "";
    this.chipInput["nativeElement"].blur();
  }

  public removeTagHandler(tag: ITag) {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.tags.push(tag);
      this.selectedTags.splice(index, 1);
      this.selectedTagsChanged(tag);
    }
  }

  public addNewTagHandler(event: MatChipInputEvent) {
    const input = event.input;

    if (!this.disableAddNew) {
      const value = event.value;
      // Add our tag
      if ((value || "").trim()) {
        const newTag: ITag = { Label: value.trim() };

        this.tagService.addTag(newTag).subscribe(id => {

          newTag.ID = id;
          this.tags.push(newTag);
          this.selectedTags.push(newTag);
          this.selectedTagsChanged();
        });
      }
    }
    // Reset the input value
    if (input) {
      input.value = "";
      this.chipInput["nativeElement"].blur();
    }
  }

  private setupTagFilter() {

    this.filteredTags$ = this.control.valueChanges
      .debounceTime(300)
      .pipe(
        startWith(""),
        map(val => this.filter(val)),
      );
  }

  private selectedTagsChanged(tag?: ITag) {

    this.onChange((this.inputDisabled) ? tag : this.selectedTags);
    this.writeValue(this.selectedTags);
  }

  private filterOutSelected() {

    const filtered: ITag[] = [];

    if (this.tags) {
      this.tags.forEach((t) => {
        const selected = this.selectedTags.filter(s => s.ID === t.ID);
        if (selected.length === 0 && !isNullOrUndefined(t.Label)) {
          filtered.push(t);
        }
      });
      this.tags = filtered;
    }
  }
}
