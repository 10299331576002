import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LocationImportStatus, LocationTreeNode } from "@app/admin/admin-models";
import { ILocation, ILocationType, IDocument, IUser, IAuditQuestion, LocationTypes } from "../models/ease-models";
import {LocalizeService} from "./localize.service";
import { LocationTreeItem } from "@shared/location-tree/location-tree-item";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LocationService {
    private locationTypes: ILocationType [] = [
        { id: LocationTypes.None, name: null, selected: false, visible: false, icon: null },
        { id: LocationTypes.Organization, name: "_Organization_", selected: false, visible: true, icon: "fa-globe" },
        { id: LocationTypes.Division, name: "_Division_", selected: false, visible: true, icon: "fa-briefcase" },
        { id: LocationTypes.Site, name: "_Site_", selected: false, visible: true, icon: "fa-building" },
        { id: LocationTypes.Workgroup, name: "_Workgroup_", selected: false, visible: true, icon: "fa-sitemap" },
        { id: LocationTypes.Workcenter, name: "_Workcenter_", selected: false, visible: true, icon: "fa-cogs"},
        { id: LocationTypes.Workstation, name: "_Workstation_", selected: false, visible: true,  icon: "fa-cog" }];

    constructor(
      private http: HttpClient,
      private localizeService: LocalizeService,
    ) {}

    getLocationIcon(id: LocationTypes): string {
      const types = this.locationTypes;

      for (let i = 0, l = types.length; i < l; i++) {
        const type = types[i];

        if (id === type.id) {
          return type.icon;
        }
      }

      return null;
    }

    public getLocations(query: any): Observable<ILocation[]> {
        return this.http.get<ILocation[]>("/location", {
          params: query,
        });
    }

    public getLocationTree(inScope: boolean, activeSite: boolean): Observable<ILocation[]> {
        return this.http.get<ILocation[]>("/locationtree?inScope=" + inScope + "&activeSite=" + activeSite);
    }

    public getLocationTreeNode(id: number): Observable<ILocation> {
        return this.http.get<ILocation>("/locationtree/" + id);
    }

    public getLocationTypes = (): ILocationType [] => this.locationTypes.map(type => {
      return {...type};
    })

    public addLocation(id: number, name: string, description: string, type: LocationTypes, timeZoneId: string): Observable<number> {
      return this.http.post<number>("/locationtree/" + id, {
            name: name,
            description: description,
            timeZoneId: timeZoneId,
            locationTypeId: type,
        });
    }

    public importLocation(parentId: number, type: ILocationType, file: FormData): Observable<LocationImportStatus[]> {
      return this.http.post<LocationImportStatus[]>("/location/" + parentId + "?type=" + type.id, file);
    }

    public updateLocation(location: LocationTreeNode): Observable<boolean> {
        return this.http.put<boolean>("/location/" + location.id, location);
    }

    public deleteSite(siteId: number): Observable<void> {
      return this.http.delete<void>("/Site/" + siteId);
    }

    public deleteLocation(locationId: number): Observable<void> {
      return this.http.delete<void>("/Location/" + locationId);
    }

    public deleteAudits(locationId: number): Observable<void> {
      return this.http.delete<void>("/audit/location/?id=" + locationId);
    }

    public hasEntitiesWithAudits(locationId: number): Observable<boolean> {
        return this.http.get<boolean>("/location/hasEntitiesWithAudits?id=" + locationId);
    }

    public hasStartedAudits(locationId: number): Observable<boolean> {
      return this.http.get<boolean>("/location/hasStartedAudits?id=" + locationId);
    }

    public getUsersOfLocation(locationId: number): Observable<IUser[]> {
      return this.http.get<IUser[]>("/location/users?id=" + locationId);
    }

    public getDocumentsOfLocation(locationId: number): Observable<IDocument[]> {
      return this.http.get<IDocument[]>("/location/documents?id=" + locationId);
    }

    public getQuestionsOfLocation(locationId: number): Observable<IAuditQuestion[]> {
      return this.http.get<IAuditQuestion[]>("/location/questions?id=" + locationId);
    }

    public reassignLocation(oldLocationId: number, newLocationId: number): Observable<void> {
      return this.http.put<void>("/location/reassign?oldLocationId=" + oldLocationId
        + "&newLocationId=" + newLocationId, {});
    }

    public getOrganizationSubTypes() {
        return this.locationTypes.filter((type) => {
            return type.id === LocationTypes.Division || type.id === LocationTypes.Site;
        });
    }

    public getLocationSubType(parentId: number): ILocationType {
        switch (parentId) {
            case LocationTypes.Site:
                return this.locationTypes[LocationTypes.Workgroup];
            case LocationTypes.Workgroup:
                return this.locationTypes[LocationTypes.Workcenter];
            case LocationTypes.Workcenter:
                return this.locationTypes[LocationTypes.Workstation];
            case LocationTypes.Organization:
              return this.locationTypes[LocationTypes.Organization];
            case LocationTypes.Division:
              return this.locationTypes[LocationTypes.Division];
            default:
                return null;
        }
    }

    public createTreeNode = (item: ILocation): LocationTreeNode => {
        return this.create<LocationTreeNode>(item, (location, type, isExpanded, isSite) => new LocationTreeNode(location.ID,
            location.OrganizationID,
            location.Name,
            location.Description,
            location.ParentID,
            location.SiteID,
            location.TimeZoneID,
            location.IsInScope,
            type.icon,
            type.name,
            type.id,
            isExpanded,
            isSite,
          ));
    }

    public createTreeItem = (item: ILocation): LocationTreeItem => {
        return this.create<LocationTreeItem>(item, (location, type, isExpanded) => new LocationTreeItem({
                value: location.ID,
                text: location.Name,
                checked: false,
                collapsed: !isExpanded,
            }, type.icon, type));
    }

    private create<T>(location: ILocation,
                      create: (location: ILocation, type: ILocationType, isExpanded: boolean, isSite?: boolean) => any): T {
      if (!location) {
            return;
        }
      const type = JSON.parse(JSON.stringify(this.locationTypes[location.LocationTypeID]));

      const IsCustomTranslation = location.LocationTypeID !== LocationTypes.Organization
        && location.LocationTypeID !== LocationTypes.Division;

      if (location.SiteID !== null && IsCustomTranslation) {
          type.name = this.localizeService.getSiteTranslation(type.name, location.SiteID);
        }
      const isSite = location.LocationTypeID === LocationTypes.Site;
      const isExpanded = location.LocationTypeID < LocationTypes.Site;

      const treeItem = create(location, type, isExpanded, isSite);

      if (location.Children && location.Children.length > 0) {
            treeItem.children = location.Children.map((x: any) => {
                return this.create(x, create);
            });
        }
      return treeItem;
    }
}
