import { Component, OnDestroy, OnInit } from "@angular/core";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentInfoService } from "../../core/services/current.service";
import { NotifyService } from "../../core/services/notify.service";
import { Subject } from "rxjs";

@Component({
    templateUrl: "./about.component.html",
})
export class AboutComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(private current: CurrentInfoService, private notify: NotifyService, private router: Router) {
    }

    public ngOnInit() {
    }

    public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }

}
