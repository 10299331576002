import { Action } from "@ngrx/store";

// Models
import { TableCollectionChanges } from "@core/models/table";
import {
  FetchOrganizationsResponse,
} from "../models/organization";

export enum OrganizationsCollectionActionTypes {
  OptionsChanges = "[Organizations] Options Changes",
  Fetch = "[Organizations] Fetch",
  FetchSuccess = "[Organizations API] Fetch Success",
  FetchFail = "[Organizations API] Fetch Fail",
}

/**
 * Fetch Organizations Collection Actions
 */
export class OptionsChanges implements Action {
  readonly type = OrganizationsCollectionActionTypes.OptionsChanges;

  constructor(public payload: TableCollectionChanges) {
  }
}

export class Fetch implements Action {
  readonly type = OrganizationsCollectionActionTypes.Fetch;
}

export class FetchSuccess implements Action {
  readonly type = OrganizationsCollectionActionTypes.FetchSuccess;

  constructor(public payload: FetchOrganizationsResponse) {
  }
}

export class FetchFail implements Action {
  readonly type = OrganizationsCollectionActionTypes.FetchFail;

  constructor(public payload: any) {
  }
}

export type OrganizationsCollectionActionsUnion =
  | OptionsChanges
  | Fetch
  | FetchSuccess
  | FetchFail;
