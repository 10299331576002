import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { PageStateService } from "../../core/services/navigation/page-state.service";

@Component({
    selector: "ease-admin-control-bar",
    templateUrl: "./admin-control-bar.component.html",
    styleUrls: [
        "./admin-control-bar.component.scss",
    ],
})
export class AdminControlBarComponent implements OnDestroy {

    @Input() public isResetDisplayed: boolean;
    @Input() public isEnableDisableVisible: boolean;
    @Input() public isPageDisabled: boolean;
    @Input() public form: NgForm = null;

    @Input() public btnTitle: string;
    @Input() public btnOk: string;

    @Output() public saveButtonClicked = new EventEmitter<boolean>();
    @Output() public cancelButtonClicked = new EventEmitter<boolean>();
    @Output() public resetButtonClicked = new EventEmitter<boolean>();
    @Output() public enableDisabledButtonClicked = new EventEmitter<boolean>();

    public isLeftNavPinned: boolean = false;
    private leftNavStateSubscription: Subscription;

    constructor(
        private pageStateService: PageStateService,
    ) {
        if (this.btnOk == null || this.btnOk == "") {
            this.btnOk = "_Save_";
        }

        this.leftNavStateSubscription = this.pageStateService.getIsLeftNavPinned().subscribe((state: boolean) => { this.isLeftNavPinned = state; });
    }

    public ngOnDestroy() {
        if (this.leftNavStateSubscription) { this.leftNavStateSubscription.unsubscribe(); }
    }

    public onSaveButtonClicked() {
        this.saveButtonClicked.emit(true);

        if (this.form !== null) {
            this.form.form.markAsPristine();
        }
    }

    public onCancelButtonClicked() {
        this.cancelButtonClicked.emit(true);
    }

    public onResetDefaultButtonClicked() {
        this.resetButtonClicked.emit(true);
    }

    public onEnableDisableButtonClicked() {
        this.enableDisabledButtonClicked.emit(true);
    }

}
