import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

// Third party modules
import { FileUploadModule } from "ng2-file-upload/ng2-file-upload";
import { BsDropdownModule } from "ngx-bootstrap";

// Flex Layout
import { FlexLayoutModule } from "@angular/flex-layout";

// Material
import { MaterialModule } from "../material";

// Modules
import { ListViewModule } from "./list-view";
import { ConfirmationModalModule } from "./confirmation";
import { DateModule } from "./date";
import { NavigationModule } from "./navigation";
import { PipesModule } from "./pipes";
import { ClickOutsideModule } from "ng4-click-outside";

// Components
import { AuditHistoryComponent } from "@shared/audit-history/audit-history.component";
import { BrowseCaptureButtonComponent } from "./browse-capture-button.component";
import { DeviceComponent } from "./device/device.component";
import { ChangeLogComponent } from "./changelog-history/changelog.component";
import { ReleaseNotesModalComponent } from "./release-notes/release-notes-modal.component";
import { AdditionalInfoComponent } from "./additional-info/additional-info.component";
import { TagSelectionComponent } from "./tag-selection/tag-selection.component";
import { ThumbnailComponent } from "./thumbnail/thumbnail.component";
import { SchedulingDefaultComponent } from "./scheduling-defaults/scheduling-defaults.component";
import { CalendarRepeatComponent } from "./calendar/calendar-repeat.component";
import { FileUploadComponent } from "@shared/file-upload/file-upload.component";
import {
  DialogComponent,
  DialogConfirmComponent,
  InputDialogComponent,
  ImageDialogComponent,
  ImportDialogComponent,
} from "./dialogs";
import { PageComponent, PageMdComponent, PageLgComponent, PageSmComponent } from "./page.component";

import { MitigationActionPanelComponent } from "./mitigation-action-panel/mitigation-action-panel.component";
import { BulkEditSummaryModalComponent } from "./bulk-edit-summary-modal/bulk-edit-summary-modal.component";

// Directives
import { PositiveNumberOnlyDirective } from "./directives/positive-number.directive";
import { CustomMaxDirective } from "@shared/directives/custom-max.directive";
import { CustomMinDirective } from "@shared/directives/custom-min.directive";
import { FeedbackSurveyDirective } from "@shared/directives/feedback-survey.directive";

// Services
import { ReleaseNotesModalService } from "./release-notes/release-notes-modal.service";
import { TagSelectionService } from "./tag-selection/tag-selection.service";
// Todo: move FileUploadService to core
// In every place shared module is imported FileUploadService create
// a new instance of its own, are you sure that you need that?
import { FileUploadService } from "@shared/file-upload/file-upload.service";
import { ImageDialogService } from "@shared/dialogs/image-dialog/image-dialog.service";
import { InlineEditComponent } from "@shared/inputs/inline-edit/inline-edit.component";
import { InlineEditSelectComponent } from "@shared/inputs/inline-edit/inline-edit-select.component";
import { FeedbackService } from "@core/services";
import { ReportDrilldownModalComponent } from "@shared/reports/report-drilldown/report-drilldown-modal.component";
import { ResultsModule } from "@app/results/results-module";
import { AuditResultComponent } from "@shared/results/audit-result/audit-result.component";
import { AuditDetailComponent } from "@shared/results/audit-detail/audit-detail.component";
import { MitigationDetailComponent } from "@shared/results/mitigation-detail/mitigation-detail.component";
import { AuditResponseComponent } from "@shared/results/audit-response/audit-response.component";
import { ReportNotifyService } from "@shared/reports/report-notify.service";

const COMPONENTS = [
  AuditHistoryComponent,
  BrowseCaptureButtonComponent,
  DeviceComponent,
  PositiveNumberOnlyDirective,
  CustomMinDirective,
  CustomMaxDirective,
  FeedbackSurveyDirective,
  ChangeLogComponent,
  ReleaseNotesModalComponent,
  AdditionalInfoComponent,
  TagSelectionComponent,
  ThumbnailComponent,
  SchedulingDefaultComponent,
  CalendarRepeatComponent,
  FileUploadComponent,
  DialogComponent,
  DialogConfirmComponent,
  InputDialogComponent,
  ImageDialogComponent,
  MitigationActionPanelComponent,
  ImportDialogComponent,
  BulkEditSummaryModalComponent,
  InlineEditComponent,
  InlineEditSelectComponent,
  AuditResultComponent,
  AuditDetailComponent,
  MitigationDetailComponent,
  AuditResponseComponent,
  ReportDrilldownModalComponent,
  PageComponent,
  PageMdComponent,
  PageLgComponent,
  PageSmComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    FlexLayoutModule,

    MaterialModule,

    ConfirmationModalModule,
    DateModule,
    ListViewModule,
    NavigationModule,
    PipesModule,

    BsDropdownModule,
    FileUploadModule,
  ],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    ConfirmationModalModule,
    DateModule,
    ListViewModule,
    NavigationModule,
    PipesModule,
    ClickOutsideModule,

    ...COMPONENTS,
  ],
  declarations: [
    ...COMPONENTS,
    ReleaseNotesModalComponent,
  ],
  entryComponents: [
    DialogConfirmComponent,
    ReleaseNotesModalComponent,
    InputDialogComponent,
    ImageDialogComponent,
    ImportDialogComponent,
    BulkEditSummaryModalComponent,
    ReportDrilldownModalComponent,
  ],
  providers: [
    ReleaseNotesModalService,
    TagSelectionService,
    FileUploadService,
    ImageDialogService,
    FeedbackService,
    ReportNotifyService,
  ],
})

export class SharedModule {
}
