import { find, propEq } from "ramda";

import { Subject,  Observable, throwError } from "rxjs";

import { catchError, map, tap } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { LoggerService } from "./logger.service";
import { LocalService } from "./local.service";
import { NotifyService } from "./notify.service";
import { ToolsService } from "./tools.service";
import { StorageService } from "./storage.service";

interface EaseLanguage {
    name: string;
    code: string;
    prefix: string;
}

interface EaseTranslation {
  Key: string;
  Value: string;
  OrganizationID?: number;
  SiteID?: number;
  IsCustomizable: boolean;
  ContainsCustomizedWord: boolean;
}

@Injectable()
export class LocalizeService implements OnDestroy {
  public defaultLanguageCode: string = "en-US";

  public supportedLanguages: EaseLanguage[] = [
    { name: "English", code: this.defaultLanguageCode, prefix: "en" },
    { name: "Español", code: "es-MX", prefix: "es" },
    { name: "Deutsche", code: "de-DE", prefix: "de" },
    { name: "普通话", code: "zh-CN", prefix: "zh" },
    { name: "Français", code: "fr-FR", prefix: "fr" },
    { name: "Polski", code: "pl-PL", prefix: "pl" },
    { name: "Português", code: "pt-BR", prefix: "pt" },
    { name: "Italiano", code: "it-IT", prefix: "it" },
    { name: "Serbian", code: "sr-SP", prefix: "sr" },
    { name: "Czech", code: "cs-CZ", prefix: "cs" },
    { name: "Korean", code: "ko-KR", prefix: "ko" },
  ];
  private destroy$ = new Subject<void>();

  private translations: EaseTranslation[] = [];

  private localStorageKey = "localize.service";

  private _languageCode: string;
  get languageCode(): string {
    if (this._languageCode) {
      return this._languageCode;
    } else {
      this._languageCode = this.getSupportedLanguage();
      return this._languageCode;
    }
  }

  set languageCode(languageCode: string) {
    if (this.supportedLanguages.every((x) => x.code !== languageCode)) {
      this.log.error(`${languageCode} language code is not supported`);
      return;
    }

    this._languageCode = languageCode;
  }

  private _customTranslations: EaseTranslation[];
  get customTranslations(): EaseTranslation[] {
    return this._customTranslations;
  }

  set customTranslations(value: EaseTranslation[]) {
    this._customTranslations = value;
  }

  constructor(private http: HttpClient,
              private tools: ToolsService,
              private local: LocalService,
              private log: LoggerService,
              private notify: NotifyService,
              private storage: StorageService,
              private zone: NgZone) {
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getLocalizedString(key: string, args?: string[]): string {
    let results: string = key;

    if (this.translations && this.translations.length > 0) {
      const translation = find(propEq("Key", key))(this.translations);

      if (translation) {
        results = (args) ? this.appendParams(translation.Value, args) : translation.Value;

        if (translation.ContainsCustomizedWord) {
          results = this.replaceCustomWords(results);
        }
      }
    }

    return results;
  }

  public getSiteTranslation(key: string, siteId: number): string {
    const result = this.translations.find(
      translation => translation.SiteID === siteId && translation.Key === key);
    return (result) ? result.Value : key;
  }

  public initTranslations(code: string = null) {
    const languageCode = this.getSupportedLanguage(code);

    return Observable.create(observer => {
      if (this.languageCode === languageCode && this.translations.length > 0) {
        observer.next(this.translations);
        observer.complete();

        return;
      }

      this.downloadTranslations(languageCode).subscribe(response => {
        observer.next(response);
        observer.complete();
      });
    }).pipe(tap(_ => this.languageCode = languageCode));
  }

  public getCustomTranslations(siteId: number) {
    return this.http.get<EaseTranslation[]>(`/localization/custom`, {
      params: { siteId: siteId.toString() },
    }).pipe(tap(response => this.customTranslations = response));
  }

  private downloadTranslations(languageCode: string) {
    this.notify.localizationReady.value = false;

    return this.http.get<EaseTranslation[]>("/localization?lang=" + languageCode).pipe(
      map((translations) => {
        this.translations = translations;

        if (this.tools.isApp()) {
          this.storage.storeObject(this.getStorageKey(languageCode), translations);
        }

        this.notify.localizationReady.value = true;
        this._languageCode = languageCode;

        return translations;
      }),
      catchError(error => {
        this.log.error("localize.service: failed to download translations. Error: " + JSON.stringify(error));
        this.notify.localizationReady.value = true;

        return throwError(error);
      }));
  }

  private getSupportedLanguage(languageCode: string = null): string {
        const supportedLanguage = this.supportedLanguages.find(
          x =>  x.code === languageCode || x.prefix === window.navigator.language);
        return supportedLanguage ? supportedLanguage.code : this.defaultLanguageCode;
  }

  private appendParams(value: string, params: string[]): string {
    for (let i = 0; i < params.length; i++) {
      value = value.replace("{" + i + "}", params[i]);
    }
    return value;
  }

  private replaceCustomWords(value: string) {
    const customWords = value.match(/%(.*)%/g);

    if (!customWords || !this.customTranslations) {
      return value;
    }

    for (const customWord of customWords) {
      const customTranslation = this.customTranslations.find(x => x.Key === customWord.replace(/%/g, ""));

      if (!customTranslation) {
        continue;
      }

      value = value.replace(customWord, customTranslation.Value);
    }

    return value;
  }

  private getStorageKey(languageCode: string) {
    return this.storage.buildCurrentStorageKey("EaseTranslation", this.languageCode);
  }
}
