import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { LocalizeService } from "@core/services";

import { IBatchEditSummary } from "./bulk-edit-summary.types";

@Component({
  templateUrl: "./bulk-edit-summary-modal.component.html",
  styleUrls: ["./bulk-edit-summary-modal.component.scss"],
})

export class BulkEditSummaryModalComponent {
  public title: string = "_BulkEditUserSummary_";
  public message: string = "";
  public isWarning: boolean = false;
  public bulkEditSummaryList: IBatchEditSummary[];

  constructor(public dialogRef: MatDialogRef<BulkEditSummaryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public localize: LocalizeService) {
                this.initModal();
  }

  public cancel(): void {
    this.dialogRef.close("close");
  }

  public initModal(): void {
    this.bulkEditSummaryList = this.data.summary;
    this.bulkEditSummaryList.forEach((data: IBatchEditSummary) => {
      switch (data.Type) {
        case "ResendInvite":
          data.Message = this.localize.getLocalizedString("_BatchResendInviteSuccess_");
          break;
        case "EnableUsers":
          data.Message = this.localize.getLocalizedString("_BatchEnableUserMessage_");
          break;
        case "DisableUsers":
          data.Message = this.localize.getLocalizedString("_BatchDisableUserMessage_");
          break;
        case "DisableFailed":
          this.title = "_BatchEditUserFailed_";
          data.Message = this.setFailMessage(this.localize.getLocalizedString("_Disable_"), data.Message);
          break;
        case "DeleteUsers":
          data.Message = this.localize.getLocalizedString("_BatchDeleteUserMessage_");
          break;
        case "DeleteFailed":
          this.title = "_BatchEditUserFailed_";
          data.Message = this.setFailMessage(this.localize.getLocalizedString("_Delete_"), data.Message);
          break;
        case "UpdatedUsers":
          data.Message = this.localize.getLocalizedString("_BatchUpdateUserMessage_");
          break;
        case "UpdateFailed":
          this.title = "_BatchEditUserFailed_";
          data.Message = this.setFailMessage(this.localize.getLocalizedString("_Update_"), data.Message);
          break;
        case "DeletedAudits":
          data.Message = this.localize.getLocalizedString("_BatchWithDeletedAuditMessage_");
          break;
        case "ReplacementAuditor":
          data.Message = this.localize.getLocalizedString("_BatchWithReplacementAuditorMessageX_", [data.Message]);
          break;
        case "ReplacementManager":
          data.Message = this.localize.getLocalizedString("_BatchWithReplacementManagerMessageX_", [data.Message]);
          break;
        case "DeleteQuestions":
          this.title = "_BulkEditQuestionSummary_";
          data.Message = this.localize.getLocalizedString("_BatchDeleteQuestionMessage_");
          break;
        case "UpdateQuestions":
          this.title = "_BulkEditQuestionSummary_";
          data.Message = this.localize.getLocalizedString("_BatchUpdateQuestionMessage_");
          break;
      }
    });
    this.isWarning = (this.title === "_BatchEditUserFailed_");
  }

  private setFailMessage(dataType: string, dataMessage: string): string {
    let message: string = "";
    switch (dataMessage) {
      case "hasPartiallyCompletedAudits":
        message = this.localize.getLocalizedString("_PartiallyCompletedAuditsX_", [dataType]);
        break;
      case "NoRoles":
        message = this.localize.getLocalizedString("_NoRolesX_", [dataType]);
        break;
      case "LoggedOnUser":
        message = this.localize.getLocalizedString("_BatchLoggedOnUserX_", [dataType]);
        break;
      case "LoggedOnUserRoles":
        message = this.localize.getLocalizedString("_BatchLoggedOnUserRole_");
        break;
    }
    return message;
  }
}