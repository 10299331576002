import { Action } from "@ngrx/store";

import { ApiError } from "@core/models/api";
import { PermissionField } from "../models/permission-field";
import { PermissionGroup } from "../models/permission-group";

export enum GroupDetailsActionTypes {
  Load = "[Permission Group Details] Load",
  LoadSuccess = "[Permission Group Details] Load Success",
  LoadFail = "[Permission Group Details] Load Fail",
  GetAvailablePermissions = "[Permission Group Details] Get Permissions",
  GetAvailablePermissionsSuccess = "[Permission Group Details] Get Permissions Success",
  GetAvailablePermissionsFail = "[Permission Group Details] Get Permissions Fail",
  Create = "[Permission Group Details] Create",
  CreateSuccess = "[Permission Group Details] Create Success",
  CreateFail = "[Permission Group Details] Create Fail",
  Update = "[Permission Group Details] Update",
  UpdateSuccess = "[Permission Group Details] Update Success",
  UpdateFail = "[Permission Group Details] Update Fail",
}

export class Load implements Action {
  readonly type = GroupDetailsActionTypes.Load;

  constructor(public payload: string) {}
}

export class LoadSuccess implements Action {
  readonly type = GroupDetailsActionTypes.LoadSuccess;

  constructor(public payload: PermissionGroup) {}
}

export class LoadFail implements Action {
  readonly type = GroupDetailsActionTypes.LoadFail;

  constructor(public payload: PermissionField) {}
}

export class GetAvailablePermissions implements Action {
  readonly type = GroupDetailsActionTypes.GetAvailablePermissions;
}

export class GetAvailablePermissionsSuccess implements Action {
  readonly type = GroupDetailsActionTypes.GetAvailablePermissionsSuccess;

  constructor(public payload: any) {}
}

export class GetAvailablePermissionsFail implements Action {
  readonly type = GroupDetailsActionTypes.GetAvailablePermissionsFail;

  constructor(public payload: any) {}
}

export class Create implements Action {
  readonly type = GroupDetailsActionTypes.Create;

  constructor(public payload: any) {}
}

export class CreateSuccess implements Action {
  readonly type = GroupDetailsActionTypes.CreateSuccess;

  constructor(public payload: any) {}
}

export class CreateFail implements Action {
  readonly type = GroupDetailsActionTypes.CreateFail;

  constructor(public payload: any) {}
}

export class Update implements Action {
  readonly type = GroupDetailsActionTypes.Update;

  constructor(public payload: PermissionGroup) {}
}

export class UpdateSuccess implements Action {
  readonly type = GroupDetailsActionTypes.UpdateSuccess;

  constructor(public payload: any) {}
}

export class UpdateFail implements Action {
  readonly type = GroupDetailsActionTypes.UpdateFail;

  constructor(public payload: ApiError[]) {}
}

export type GroupDetailsActionUnion =
  | Load
  | LoadSuccess
  | LoadFail
  | GetAvailablePermissions
  | GetAvailablePermissionsSuccess
  | GetAvailablePermissionsFail
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateSuccess
  | UpdateFail;
