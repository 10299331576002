import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";

import { LocalService } from "@core/services/local.service";
import {
  IPaginationModel,
  IStandardQueryResponseColumn,
  ListViewFilter,
  ListViewFilterSortData,
  ListViewFilterSortEvent,
} from "./list-view-models";
import { isNullOrUndefined } from "util";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "ease-list-view-filter-sort",
  templateUrl: "./list-view-filter-sort.component.html",

})

export class ListViewFilterSortComponent implements OnChanges {

  public isFilterEnabled: boolean = false;
  public rightJustified: boolean = false;
  public isSortEnabled: boolean = false;
  public isSortedDescending: boolean = false;
  public currentState: string = "";
  public distinct: any [];

  @Input() public sortOn: string;
  @Input() public pagingInfo: IPaginationModel;
  @Input() public colName: string;
  @Input() public colDisplayName: string;
  @Input() public sortOnly: boolean;
  @Input() public cols: IStandardQueryResponseColumn [];
  @Input() public sortData: ListViewFilterSortData = new ListViewFilterSortData();
  @Output() public filterSortChanged = new EventEmitter<ListViewFilterSortEvent>();
  @Output() public pageSizeChanged = new EventEmitter<number>();

  public prevSortData: ListViewFilterSortData = new ListViewFilterSortData();

  constructor(private local: LocalService) {

    this.initializeColFilters();
  }

  // Angular does not check the properties of an object to determine if an object has changed, so we have to write our own method
  public detectFilterSortChange(): boolean {

    let hasChanged = false;
    if (this.prevSortData.currentSort !== this.sortData.currentSort ||
      this.prevSortData.filters.length !== this.sortData.filters.length) {

      hasChanged = true;
    }

    // If there's been a change, update the previous sort data
    if (hasChanged) {
      this.prevSortData.currentSort = this.sortData.currentSort;
      this.prevSortData.filters = [];
      this.sortData.filters.forEach((filter: ListViewFilter) => {

        const newFilter = new ListViewFilter(filter.colname, filter.ids);
        this.prevSortData.filters.push((newFilter));
      });
    }

    return hasChanged;
  }

  // Checks to see if the current filter is being used
  public checkCurrentFilterSort() {

    this.isSortEnabled = false;
    this.isFilterEnabled = false;

    const currentSort = this.sortData.currentSort;
    const currentFilters = this.sortData.filters;

    let sortCol = "";
    let sortDir = "";
    if (currentSort.indexOf(":")) {
      const parts = currentSort.split(":");
      sortCol = parts[0];
      sortDir = parts[1];
    }

    if (sortCol === this.colName) {
      this.isSortEnabled = true;

      if (sortDir === "desc") {
        this.isSortedDescending = true;
      }
    }

    currentFilters.forEach((filter: ListViewFilter) => {

      if (filter.colname === this.colName) {
        this.isFilterEnabled = true;
        this.distinct.forEach((dist) => {

          dist.IsSelected = false;
          if (filter.ids.indexOf(dist.Name) >= 0) {
            dist.IsSelected = true;
          }
        });
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {

    if (changes.cols.currentValue != null) {
      this.cols = (changes.cols.currentValue as any);
    }

    this.initializeColFilters();

    // If the cols have changed, there's a good chance that the filter/sort has changed as well
    if (this.detectFilterSortChange()) {
      this.checkCurrentFilterSort();
    }

  }

  public initiateColFilter(menu: any) {
    let ids = "";

    for (let i = 0; i < this.distinct.length; i++) {
      const distinct = this.distinct[i];
      if (distinct.IsSelected) {
        ids += distinct.Value + "|";
      }
    }

    this.initiateChange("filter", this.colName, ids);

  }

  public clearColFilter() {
    this.initiateChange("clear", this.colName);
  }

  public initiateColSort() {

    this.isSortEnabled = true;
    this.isSortedDescending = !this.isSortedDescending;
    const order = (this.isSortedDescending) ? "desc" : "asc";

    const event: ListViewFilterSortEvent = {
      action: "sort",
      sortOn: this.sortOn,
      colName: this.colName,
      data: order,
      updateFilter: false,
    };

    this.filterSortChanged.emit(event);
  }

  public initializeColFilters() {

    if (this.cols != null) {
      for (let i = 0; i < this.cols.length; i++) {
        const col = this.cols[i];
        if (col.ColName === this.colName) {
          this.distinct = col.Distinct.filter(x => x.Value !== "");
          // this.distinct = this.$filter('orderBy')(col.Distinct, 'Name');
        }
      }

      if (!isNullOrUndefined(this.distinct)) {
        this.distinct = this.distinct
          .filter(x => x.Name !== null)
          .sort((l, b) => l.Name.localeCompare(b.Name));
      }
    }
  }

  public initiateChange(action: string, colname: string, data: any = null, sortOn: string = "", updateFilter: boolean = false) {

    const event: ListViewFilterSortEvent = {
      action,
      colName: this.colName,
      data, sortOn:
      this.sortOn,
      updateFilter,
    };
    this.filterSortChanged.emit(event);
  }
}
