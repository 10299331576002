import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
 selector: "[positiveNumberOnly]",
})
export class PositiveNumberOnlyDirective {
 // Allow decimal numbers and negative values
 private regex: RegExp = new RegExp(/^[0-9]\d*$/);
 // Allow key codes for special events. Reflect :
 // Backspace, tab, end, home
 private specialKeys: string[] = [ "Backspace", "Tab", "End", "Home", "Delete" ];

 constructor(private el: ElementRef) {
 }
 @HostListener("keydown", [ "$event" ])
 private onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
     if (this.specialKeys.indexOf(event.key) !== -1) {
        return;
    }
     const current: string = this.el.nativeElement.value;
     const next: string = current.concat(event.key);
     if (next && !String(next).match(this.regex)) {
        event.preventDefault();
    }
 }
}