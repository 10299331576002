import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IAuditSchedule, IAuditScheduleResponse, ICapaEvent } from "@core/models/ease-models";

@Injectable()
export class AdHocAuditService {
    constructor(private http: HttpClient) {}

    public saveAdHocAuditSchedule(auditSchedule: IAuditSchedule, selectedAuditorID: number, auditCompletionDate: string,
                                  applicableLocationTierLevels: string, isDataEntry: boolean = true, selectedSiteID: number = 0): Observable<IAuditScheduleResponse> {
      let url: string = "";
      if (isDataEntry === true) {
        url = "/auditschedule?auditorUserID=" + selectedAuditorID + "&auditCompletionDate=" + auditCompletionDate + "&applicableLocationTierLevels=" + applicableLocationTierLevels;
      } else {
        url = "/auditschedule?onDemand=true" + "&applicableLocationTierLevels=" + applicableLocationTierLevels + "&selectedSiteID=" + selectedSiteID + "&isAdhocAudit=true";
      }
      return this.http.post<IAuditScheduleResponse>(url, auditSchedule);
    }

    public getClosedCapaEvents(): Observable<ICapaEvent[]> {
      return this.http.get<any>("/capaeventlist?CapaStatus=2|3|4")
        .map(data => data.Result);
    }
}
