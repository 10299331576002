import { Component } from "@angular/core";

// State
import { Store, select } from "@ngrx/store";
import * as fromRoot from "app/reducers/index";

@Component({
  selector: "ease-tabs",
  template: `
    <nav mat-tab-nav-bar color="accent">
      <a mat-tab-link *ngFor="let tab of tabs$ | async"
         [active]="tab.isActive"
         [routerLink]="tab.routerLink">

        {{ tab.title | i18n }}
      </a>
    </nav>
  `,
  styles: [`
    .mat-tab-nav-bar {
      border-bottom: 0;
    }
  `],
})
export class TabsComponent {
  tabs$ = this.store.pipe(select(fromRoot.getTabs));

  constructor(private store: Store<fromRoot.State>) {
  }
}
