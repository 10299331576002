import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";

@Injectable()
export class CacheBustInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const nextReq = req.clone({
      headers: req.headers.set("Cache-Control", "no-cache")
        .set("Pragma", "no-cache")
        .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT")
        .set("If-Modified-Since", "0"),
    });

    return next.handle(nextReq);
  }
}
