import { LogLevel } from "@core/services/logger.service";

export const environment = {
  name: "Dev",
  production: false,
  apiUrl: "https://dev.easeinc.com/svc/api",
  remSvcUrl: "https://remoteservices.easeinc.com/remsvc",
  analytics: "",
  logLevel: LogLevel.Debug,
  logBatchSize: 5,
  enableProdMode: false,
};
