import { takeWhile, tap } from "rxjs/operators";

import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

// State
import { select, Store } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import {
  RefreshToken,
} from "app/auth/actions/auth";

@Component({
  selector: "ease-site-selector",
  templateUrl: "./site-selector.component.html",
})

export class SiteSelectorComponent implements OnDestroy {
  @Input() disabled: boolean = true;

  alive = true;

  sites$ = this.store.pipe(select(fromAuth.getSites));
  activeSiteID$ = this.store.pipe(
    select(fromAuth.getActiveSiteID),
    tap(siteId => this.form.get("siteId").setValue(siteId)),
  );

  form: FormGroup;

  constructor(private store: Store<fromAuth.State>) {
    this.store.pipe(
      takeWhile(_ => this.alive),
      select(fromAuth.getTokenRefreshPending),
    ).subscribe(this.toggleForm.bind(this));

    this.form = new FormGroup({
      siteId: new FormControl(null),
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onSiteIDChange(siteId) {
    this.store.dispatch(new RefreshToken(siteId));
  }

  private toggleForm(disable) {
    if (!this.form) {
      return;
    }

    if (disable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
