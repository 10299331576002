import {
  AuthActionTypes,
  AuthActionsUnion,
} from "../actions/auth";
import {
  SessionActionTypes,
  SessionActionsUnion,
} from "../actions/session";

// Models
import { ApiError } from "@core/models/api";

export interface State {
  error: ApiError | null;
  message: string | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  message: null,
  pending: false,
};

export function reducer(state = initialState, action: AuthActionsUnion | SessionActionsUnion): State {
  switch (action.type) {
    case SessionActionTypes.RestoreSession: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case SessionActionTypes.RestoreSessionSuccess:
    case SessionActionTypes.RestoreSessionFailure: {
      return {
        ...state,
        pending: false,
      };
    }

    case AuthActionTypes.Authenticate:
    case AuthActionTypes.AuthenticateSSO: {
      return {
        ...state,
        error: null,
        message: null,
        pending: true,
      };
    }

    case AuthActionTypes.AuthenticateSuccess:
    case AuthActionTypes.AuthenticateSSOSuccess: {
      return {
        ...state,
        error: null,
        message: "Authenticated",
        pending: false,
      };
    }

    case AuthActionTypes.AuthenticateFailure:
    case AuthActionTypes.AuthenticateSSOFailure: {
      return {
        ...state,
        error: action.payload,
        message: null,
        pending: false,
      };
    }

    case AuthActionTypes.Authorize: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case AuthActionTypes.AuthorizeSuccess: {
      return {
        ...state,
        error: null,
        message: "Authorized",
        pending: false,
      };
    }

    case AuthActionTypes.AuthorizeFailure: {
      return {
        ...state,
        error: action.payload.message,
        message: null,
        pending: false,
      };
    }

    case AuthActionTypes.HomeRedirect: {
      return {
        ...state,
        message: "Redirecting to Home Page",
        pending: true,
      };
    }

    // TODO Do we need here Login Redirects
    case AuthActionTypes.Logout:
    case AuthActionTypes.LoginRedirect: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getMessage = (state: State) => state.message;
export const getPending = (state: State) => state.pending;
