import { Component, Input } from "@angular/core";

@Component({
  selector: "ease-sso-login",
  templateUrl: "./sso-login.html",
  styleUrls: [ "./sso-login.scss" ],
})
export class SsoLoginComponent {
  @Input() pending: boolean;
  @Input() errorMessage: string | null;
  @Input() message: string | null;

  constructor() {
  }
}
