import { MatTableDataSource } from "@angular/material";
import { ApiService, LocalService, LocalizeService, NotifyService } from "@core/services";
import { CSVExportManager } from "../csv-export/CSVExportManager";
import { ListViewStorageService } from "./list-view-storage.service";

import {
  IListViewStateInfo,
  IPaginationModel,
  IStandardQueryResponseColumn,
  ListViewFilterSortData,
  ListViewFilterSortEvent,
} from "./list-view-models";

import { ActivatedRoute } from "@angular/router";
import { IDateRange } from "../date/date-types";

// Base class used for all server-side paging lists
export class ListViewBase {

  public pagingInfo: IPaginationModel = null;
  public colStats: IStandardQueryResponseColumn[] = null;

  public filterSortData: ListViewFilterSortData = new ListViewFilterSortData();

  public isFirstRequest: boolean = true;
  public isLoading: boolean = true;
  public results: any[] = null;
  public isSearching: boolean = false;
  public isFilteredBySearch: boolean = false;
  public argsQuery: any;
  public listViewIdentifier: string = "";
  public dateRange: IDateRange = {};

  public apiCall: string;
  public params: any;
  public stats: string;
  public parentCallback: any = null;

  public isGridInitialized: boolean = false;
  public searchTextHasValue: boolean = false;
  public enableScrollbars: boolean = false;

  constructor(public api: ApiService,
              public notify: NotifyService,
              public local: LocalService,
              public route: ActivatedRoute,
              public listViewStorageService: ListViewStorageService) {

    this.initPagingInfo();
    this.filterSortData.currentSort = "Name";
  }

  public ngOnInit() {
    const snapshot: any = this.route.snapshot;
  }

  public initFilterList(args: any, getStats = false): void {
    this.pagingInfo.currentPage = 1;
    this.isSearching = args.query !== "";
    this.argsQuery = args.query;
    if (!this.isSearching) {
      this.isFilteredBySearch = false;
    }
    this.getResults(getStats, args.query);
  }

  public initialize(apiCall: string,
                    params: any,
                    stats: string,
                    defaultSort: string,
                    listViewIdentifier: string,
                    callback: any = null) {

    this.apiCall = apiCall;
    this.params = params;
    this.stats = stats;
    this.parentCallback = callback;
    this.isGridInitialized = true;
    this.listViewIdentifier = listViewIdentifier;
    if (defaultSort) {
      this.filterSortData.currentSort = defaultSort;
    }
    this.restoreFromLocalStorage();
    this.getResults();
  }

  public restoreFromLocalStorage(): void {

    if (this.listViewStorageService !== null) {
      this.listViewStorageService.init(this.listViewIdentifier);
      const state: IListViewStateInfo = this.listViewStorageService.restoreFromLocalStorage();
      if (state != null) {
        if (state.filterSortData != null) {
          this.filterSortData = state.filterSortData;
        }
        if (state.pagingInfo != null && state.pagingInfo.pageSize != null) {
          this.pagingInfo.pageSize = state.pagingInfo.pageSize;
        }
        if (state.dateRange != null) {
          this.dateRange = state.dateRange;
        }
      }
    }
  }

  public refreshData(getStats: boolean, newParams: any = null) {

    if (newParams != null) {
      this.params = newParams;
    }
    this.getResults(getStats, "");
  }

  // Move this into the base class
  public getResults(getStats: boolean = false, searchString = ""): any {

    if (this.isGridInitialized) {
      this.notify.broadcast("full-page-block", true);
      let skip = 0;
      if (this.isFilteredBySearch) {
        skip = (this.pagingInfo.currentPage - 1) * this.pagingInfo.pageSize;
      } else {
        skip = !this.isSearching ? (this.pagingInfo.currentPage - 1) * this.pagingInfo.pageSize : 0;
        this.isFilteredBySearch = this.isSearching;
      }
      const stats = (this.isFirstRequest || getStats) ? this.stats : "";
      const query: any = {
        sort: this.filterSortData.currentSort,
        skip,
        take: this.pagingInfo.pageSize,
        stats,
        query: encodeURIComponent(searchString),
      };

      this.appendDateRangeToQuery(query);
      this.appendFilterParamsToQuery(query);
      this.appendAdditionalParamsToQuery(query);

      this.api.Query(this.apiCall, query, (data: any) => {

        this.results = data.Result;
        if (data.Stats != null) {
          this.pagingInfo.rows = data.Stats.Rows;
          this.pagingInfo.totalRows = data.Stats.TotalRows;
          this.colStats = data.Stats.Cols;
        } else {
          this.initPagingInfo();
        }

        this.isFirstRequest = false;
        this.isLoading = false;

        if (this.parentCallback != null) {
          this.parentCallback(this.results);
        }
        this.notify.broadcast("full-page-block", false);
      });
    }
  }

  public dateRangeChangeHandler() {

    if (this.listViewStorageService !== null) {
      this.listViewStorageService.saveDateRangeToLocalStorage(this.dateRange);
    }
    this.getResults(true);
  }

  public appendDateRangeToQuery(query: any) {

    if (this.dateRange.startDate != null) {
      query.from = this.dateRange.startDate.toShortDateString();
    }
    if (this.dateRange.endDate != null) {
      query.to = this.dateRange.endDate.toShortDateString();
    }
  }

  public appendFilterParamsToQuery(query: any) {
    const filters = this.filterSortData.filters;
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      query[filter.colname] = encodeURIComponent(filter.ids.toString());
    }
  }

  public appendAdditionalParamsToQuery(query: any) {

    const objs = this.params;
    for (const prop in objs) {
      if (objs.hasOwnProperty(prop)) {
        query[prop] = objs[prop];
      }
    }

  }

  public filterSortChangeHandler(args: ListViewFilterSortEvent) {

    const action = args.action;
    let colName = args.colName;
    const data = args.data;
    const sortOn = args.sortOn;
    const updateFilter = args.updateFilter;

    switch (action) {

      case "filter":
        this.applyFilters(colName, data, updateFilter);
        break;

      case "clear":
        this.clearSingleFilter(colName);
        break;

      case "sort":

        if (sortOn != null && sortOn !== "") {
          colName = sortOn;
        }
        this.sortColumn(colName, data);
        break;
    }
  }

  public sortColumn(colname: string, direction: string) {

    this.filterSortData.currentSort = colname + ":" + direction;
    if (this.listViewStorageService !== null) {
      this.listViewStorageService.saveFilterSortToLocalStorage("sort", this.filterSortData);
    }
    this.getResults(true);
  }

  public applyFilters(colname: string, ids: any, updateFilter = false) {
    let updateExisting = false;
    const filters = this.filterSortData.filters;

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.colname === colname) {
        filter.ids = ids;
        updateExisting = true;
      }
    }

    if (!updateExisting) {
      filters.push({colname, ids});
    }

    if (this.listViewStorageService !== null) {
      this.listViewStorageService.saveFilterSortToLocalStorage("filter", this.filterSortData);
    }
    if (!updateFilter) {
      this.getResults(true);
    }
  }

  public clearSingleFilter(colname: string, updateFilter = false) {
    const indx = 0;

    const filters = this.filterSortData.filters;
    for (let i = 0; i < filters.length; i++) {

      if (filters[i].colname === colname) {
        filters.splice(i, 1);
      }
    }

    if (this.listViewStorageService !== null) {
      this.listViewStorageService.saveFilterSortToLocalStorage("filter", this.filterSortData);
    }

    if (!updateFilter) {
      this.getResults(true);
    }
  }

  public clearAllFilters() {
    const filters = this.filterSortData.clear();

    if (this.listViewStorageService !== null) {
      this.listViewStorageService.clearAllFilters();
    }

    this.getResults(true);
  }

  public pageChangeHandler(pageIndex: number) {

    this.pagingInfo.currentPage = pageIndex + 1;
    this.getResults();
  }

  public pageSizeChangeHandler(pageSize: number) {
    this.pagingInfo.pageSize = pageSize;

    if (this.listViewStorageService !== null) {
      this.listViewStorageService.savePagingInfoToLocalStorage(this.pagingInfo);
    }

    this.getResults();
  }

  public searchTextChangeHandler(searchText: string) {

    this.pagingInfo.currentPage = 1;
    this.isSearching = true;
    this.argsQuery = searchText;
    if (!this.isSearching) {
      this.isFilteredBySearch = false;
    }
    this.getResults(false, searchText);
  }

  public exportCSV(gridColumnItems: any, colStats: any, localize: LocalizeService, startDate?: Date, endDate?: Date, documentType?: string): void {
    const IDs: number[] = [];

    colStats.forEach ((col) => {
      if (col.ColName === "ID") {
        for (let i = 0; i < col.Distinct.length; i++) {
          IDs.push(parseInt(col.Distinct[i].Value, 10));
        }
      }
    });

    const visibleColumns = gridColumnItems.filter((item: any) => item.IsSelected === true && item.IsEnabled === true);
    const visibleColumnNames: any[] = [];
    visibleColumns.forEach((column: any) => {
      visibleColumnNames.push(column.Name);
    });

    const edm = new CSVExportManager(this.api, localize, this.local, this.notify, this.route, IDs, visibleColumnNames);
    edm.exportCSV(startDate, endDate, documentType);
  }

  public searchTextClearHandler(): void {

    this.clearAllFilters(); // reset filters if exits
    this.searchTextHasValue = false;
  }

  public adjustLayout(numColumns: number) {
    this.enableScrollbars = (numColumns > 9);
  }

  private initPagingInfo() {
    this.pagingInfo = {
      pageSize: 15,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
    };
  }
}

// Base class used for all client-side paging lists
export class ListViewLocalPagingBase {

  public pageSize: number = 15;
  public prevPageSize: number = 0;
  public dataSource: MatTableDataSource<any>;

  constructor(listViewIdentifier: string, public listViewStorageService: ListViewStorageService) {

    this.listViewStorageService.init(listViewIdentifier);
    const state: IListViewStateInfo = this.listViewStorageService.restoreFromLocalStorage();
    if (state !== null && state.pagingInfo != null) {
      this.pageSize = state.pagingInfo.pageSize;
    }
  }

  public setDataSource(dataSource: MatTableDataSource<any>) {
    this.dataSource = dataSource;
  }

  public searchTextClearHandler(): void {
    this.dataSource.filter = "";
  }

  public searchTextChangeHandler(searchText: string) {
    this.dataSource.filter = searchText;
  }

  public pageChangeHandler(event: any) {
    const pageSize = event.pageSize;
    this.pageSize = pageSize;

    if (pageSize !== this.prevPageSize) {
      this.pageSizeChangeHandler(pageSize);
    }
  }

  public pageSizeChangeHandler(pageSize: number) {
    const pagingInfo = {
      pageSize,
    };

    this.listViewStorageService.savePagingInfoToLocalStorage(pagingInfo);
  }
}
