import { Injectable } from "@angular/core";
import { ApiService } from "../core/services/api.service";
import {
  IAuditCategory, IDocumentRev, IDocumentSubTypes, IHighChartResult, ILocation,
  IReportPanelLayoutItem,
  IUserSetting,
} from "../core/models/ease-models";
import { ReplaySubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ReportDataAccessService {
  constructor(private api: ApiService, private http: HttpClient) {
  }

  public getChartData(entity: string, data: any, query: any = {}): Observable<IHighChartResult> {
    const result = new ReplaySubject<IHighChartResult>(1);
    this.api.QueryX(entity, data, query, (highChartResult: IHighChartResult) => {
      if (highChartResult) {
        result.next(highChartResult);
        result.complete();
      }
    }, null, null, this);
    return result.asObservable();
  }

  public addLayout(layout: any, auditSubtypeID: number): Observable<any> {
    const result = new ReplaySubject<any>(1);
    this.api.AddX("UserSetting", layout, { auditSubtypeID }, (data: any) => {
      if (data) {
        result.next(data);
        result.complete();
      }
    });
    return result;
  }

  public getLayoutFromDB(query: any): Observable<IUserSetting[]> {
    const result = new ReplaySubject<IUserSetting[]>(1);
    this.api.Query("UserSetting", query, (data: IUserSetting[]) => {
      if (data && data.length) {
        result.next(data);
        result.complete();
      } else {
        result.next([]);
        result.complete();
      }
    });
    return result.asObservable();
  }

  public getReportPanelLayout(query: any): Observable<IReportPanelLayoutItem[]> {
    const result = new ReplaySubject<IReportPanelLayoutItem[]>(1);
    this.api.Query("ReportPanelLayout", query, (data: IReportPanelLayoutItem[]) => {
      if (data) {
        result.next(data);
        result.complete();
      } else {
        result.next([]);
        result.complete();
      }
    });
    return result.asObservable();
  }

  public loadAllAuditCategories(query: any): Observable<IAuditCategory[]> {
    const result = new ReplaySubject<IAuditCategory[]>(1);
    this.api.Query("AuditQuestionCategory", query, (data: IAuditCategory[]) => {
      if (data && data.length) {
        result.next(data);
        result.complete();
      } else {
        result.next([]);
        result.complete();
      }
    });
    return result.asObservable();
  }

  public getAuditConfig(query: any) {
    const result = new ReplaySubject<any>(1);
    this.api.Query("AuditConfiguration", query, (data: any[]) => {
      if (data && data.length) {
        result.next(data);
        result.complete();
      } else {
        result.next([]);
        result.complete();
      }
    });
    return result.asObservable();
  }

  // this generic method is used for CustomGroups, CustomCategories and CustomItems
  public getCustomObjects<T>(entity: string, query: any): Observable<T[]> {
    const result = new ReplaySubject<T[]>(1);
    this.api.Query(entity, query, (data: T[]) => {
      if (data && data.length) {
        result.next(data);
        result.complete();
      } else {
        result.next([]);
        result.complete();
      }
    });
    return result.asObservable();
  }

  public getLocations(query: any): Observable<ILocation[]> {
    return this.http.get<ILocation[]>(`/Location?listView=true`);
  }

  public getDocumentRevs(query: any): Observable<IDocumentRev[]> {
    const result = new ReplaySubject<IDocumentRev[]>(1);
    this.api.Query("documentrev", query, (data: IDocumentRev[]) => {
      if (data && data.length) {
        result.next(data);
        result.complete();
      } else {
        result.next([]);
        result.complete();
      }
    });
    return result.asObservable();
  }

  public getDocumentSubtypes(): Observable<IDocumentSubTypes[]> {
    return this.http.get<IDocumentSubTypes[]>("/DocumentSubType");
  }
}
