import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { PipesModule } from "@shared/pipes";

import { BreadcrumbHostDirective } from "./breadcrumbs-host.directive";
import {
  BreadcrumbAuditComponent,
  BreadcrumbBaseItemComponent,
  BreadcrumbCapaComponent,
  BreadcrumbCompanyComponent,
  BreadcrumbDepartmentComponent,
  BreadcrumbDocumentNameComponent,
  BreadcrumbDocumentTypeComponent,
  BreadcrumbGroupNameComponent,
  BreadcrumbItemComponent,
  BreadcrumbLayerComponent,
  BreadcrumbMitigationComponent,
  BreadcrumbScopeComponent,
  BreadcrumbShiftComponent,
  BreadcrumbSiteCalendarComponent,
  BreadcrumbTemplateTypeComponent,
  BreadcrumbUserCalendarComponent,
  BreadcrumbUserComponent,
  BreadcrumbPermissionComponent,
  BreadcrumbOrganizationComponent,
  BreadcrumbReleaseComponent,
} from "./breadcrumbs-items";
import { BreadcrumbsComponent } from "./breadcrumbs.component";

import { BreadcrumbsService } from "./breadcrumbs.service";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
  ],
  declarations: [
    BreadcrumbHostDirective,
    BreadcrumbsComponent,
    BreadcrumbItemComponent,
    BreadcrumbUserComponent,
    BreadcrumbDepartmentComponent,
    BreadcrumbScopeComponent,
    BreadcrumbLayerComponent,
    BreadcrumbShiftComponent,
    BreadcrumbTemplateTypeComponent,
    BreadcrumbBaseItemComponent,
    BreadcrumbCapaComponent,
    BreadcrumbDocumentNameComponent,
    BreadcrumbDocumentTypeComponent,
    BreadcrumbGroupNameComponent,
    BreadcrumbCompanyComponent,
    BreadcrumbMitigationComponent,
    BreadcrumbAuditComponent,
    BreadcrumbUserCalendarComponent,
    BreadcrumbSiteCalendarComponent,
    BreadcrumbPermissionComponent,
    BreadcrumbOrganizationComponent,
    BreadcrumbReleaseComponent,
  ],
  entryComponents: [
    BreadcrumbItemComponent,
    BreadcrumbUserComponent,
    BreadcrumbDepartmentComponent,
    BreadcrumbScopeComponent,
    BreadcrumbLayerComponent,
    BreadcrumbShiftComponent,
    BreadcrumbTemplateTypeComponent,
    BreadcrumbCapaComponent,
    BreadcrumbDocumentNameComponent,
    BreadcrumbDocumentTypeComponent,
    BreadcrumbGroupNameComponent,
    BreadcrumbCompanyComponent,
    BreadcrumbMitigationComponent,
    BreadcrumbAuditComponent,
    BreadcrumbUserCalendarComponent,
    BreadcrumbSiteCalendarComponent,
    BreadcrumbPermissionComponent,
    BreadcrumbOrganizationComponent,
    BreadcrumbReleaseComponent,
  ],
  providers: [
    BreadcrumbsService,
  ],
  exports: [
    BreadcrumbsComponent,
  ],
})
export class BreadcrumbsModule { }
