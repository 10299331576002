﻿import { Component, Input, OnInit } from "@angular/core";
import { ToolsService } from "@core/services/tools.service";

@Component({
  selector: "ease-browse-capture-button",
  templateUrl: "./browse-capture-button.component.html",
})

export class BrowseCaptureButtonComponent implements OnInit {
  @Input() public uploader: any;

  public forceImageCaptureOnly: boolean;
  public fileAccept: string;

  constructor(private tools: ToolsService) {
  }

  ngOnInit() {
    if (this.tools.isApp()) {
      this.fileAccept = "image/*";
    } else {
      this.fileAccept = "File";
    }
  }
}
