import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CanActivate } from "@angular/router";

@Injectable()
export class AntiforgeryGuard implements CanActivate {
  constructor(private http: HttpClient) {
  }

  public canActivate() {
    this.http.get("/authentication/antiforgerytoken").subscribe();

    return true;
  }
}
