import { environment } from "~/environments/environment";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

// Store
import { StoreModule } from "@ngrx/store";
import { StoreRouterConnectingModule, RouterStateSerializer } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { metaReducers, reducers } from "@app/reducers";

// Logger
import { LOG_BATCH_SIZE, LOG_LEVEL, LoggerService } from "@core/services/logger.service";

// Modules
import { CalendarModule } from "angular-calendar";
import { TreeviewModule } from "ngx-treeview";
import { MaterialModule } from "../material";
import { CookieModule } from "ngx-cookie";

// Components
import { LeftNavComponent } from "./components/left-nav/left-nav.component";
import { NotFoundPageComponent } from "./containers/not-found-page";
import { SiteSelectorComponent } from "./components/site-selector/site-selector.component";
import { TabStripComponent } from "./components/tab-strip/tab-strip.component";
import { TopNavComponent } from "./components/top-nav/top-nav.component";
import { BackButtonComponent } from "@core/components/back-button/back-button.component";
import { TabsComponent } from "./components/tabs.component";

// Shared Module
// Todo: Remove from Core Module
import { SharedModule } from "@shared/shared.module";

// Services
import {
  AnalyticsService,
  ApiService,
  AppSettingsService,
  Config,
  CurrentInfoService,
  DocumentRevService,
  IdentityService,
  FeedbackService,
  JobService,
  LocalService,
  LocalizeService,
  LocationService,
  NotifyService,
  ScopeService,
  StorageService,
  ToolsService,
  UserService,
} from "./services";

import { Ease1ToEase2MigrationGuard } from "@core/guards/Ease1ToEase2MigrationGuard";

// Interceptors
import { ProgressInterceptor } from "./interceptors/progress";
import { ErrorInterceptor } from "./interceptors/error";
import { TokenInterceptor } from "./interceptors/token";
import { UrlInterceptor } from "./interceptors/url";
import { CacheBustInterceptor } from "./interceptors/cache";

// Todo: Those services should be moved to feature related modules;
import { AdminService } from "./services/common/admin.service";
import { ArtifactService } from "./services/common/artifact.service";
import { AuditConfigService } from "./services/common/audit-config.service";
import { AuditTypeService } from "./services/common/audit-types.service";
import { AuditDetailService } from "./services/common/audit-detail.service";
import { ColumnSettingsService } from "./services/common/column-settings.service";
import { CompanySiteService } from "./services/common/company-site.service";
import { MitigationArtifactService } from "./services/common/mitigation-artifact.service";
import { MitigationDetailService } from "./services/common/mitigation-detail.service";
import { UiNotificationService } from "./services/common/ui-notification.service";
import { ChangelogService } from "./services/common/changelog.service";

import { PageStateService } from "./services/navigation/page-state.service";

// Todo: Module shouldn't be in *.service.ts file
import { WindowTokenModule } from "./services/window.service";
import { CustomRouterStateSerializer } from "@shared/utils";
import { BreadcrumbsModule } from "@core/components/breadcrumbs/breadcrumbs.module";

export const COMPONENTS = [
  LeftNavComponent,
  NotFoundPageComponent,
  SiteSelectorComponent,
  TabStripComponent,
  TopNavComponent,
  BackButtonComponent,
  TabsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: "XSRF-REQUEST-TOKEN",
      headerName: "X-XSRF-TOKEN",
    }),

    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
    }),

    StoreDevtoolsModule.instrument({
      name: "NgEase Store DevTools",
      logOnly: environment.production,
    }),

    EffectsModule.forRoot([]),

    TreeviewModule.forRoot(),
    CalendarModule.forRoot(),
    CookieModule.forRoot(),

    WindowTokenModule,
    BreadcrumbsModule,
  ],

  declarations: [ COMPONENTS ],

  exports: [ COMPONENTS,
  ],

  entryComponents: [],

  providers: [],
})
export class CoreModule {
  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        LoggerService,
        { provide: LOG_LEVEL, useValue: environment.logLevel },
        { provide: LOG_BATCH_SIZE, useValue: environment.logBatchSize },

        { provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheBustInterceptor, multi: true},

        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },

        AnalyticsService,
        ApiService,
        AppSettingsService,
        Config,
        CurrentInfoService,
        DocumentRevService,
        IdentityService,
        JobService,
        LocalService,
        LocalizeService,
        LocationService,
        NotifyService,
        Ease1ToEase2MigrationGuard,
        ScopeService,
        StorageService,
        ToolsService,
        UserService,

        AdminService,
        ArtifactService,
        AuditConfigService,
        AuditTypeService,
        AuditDetailService,
        ColumnSettingsService,
        CompanySiteService,
        MitigationArtifactService,
        MitigationDetailService,
        UiNotificationService,
        ChangelogService,

        PageStateService,
        FeedbackService,
      ],
    };
  }

  constructor(private log: LoggerService) {
    this.log.info("app.module: application startup...");
  }
}
