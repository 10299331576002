import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { Router } from "@angular/router";
import { AppSettingsService } from "../../services/app-settings.service";
import { ToolsService } from "../../services/tools.service";

import "rxjs/add/operator/filter";
import { CurrentInfoService } from "../../services/current.service";
import { NotifyService } from "../../services/notify.service";
import { UiNotificationService } from "../../services/common/ui-notification.service";
import { AuditDetailService } from "@core/services/common/audit-detail.service";
import { MitigationDetailService } from "@core/services/common/mitigation-detail.service";
import { Subject } from "rxjs";
import * as fromInfo from "@app/auth/reducers";
import { Store } from "@ngrx/store";

@Component({
  selector: "ease-tab-strip",
  templateUrl: "./tab-strip.component.html",
  styles: [`
    .mat-tab-nav-bar {
      border-bottom: 0px;
    }
  `],
})

export class TabStripComponent implements OnInit, OnDestroy {
  featureFlags$ = this.store.select(fromInfo.getFeatureFlags);
  isOrgAdmin$ = this.store.select(fromInfo.getRoleIsOrgAdmin);
  public stripType: string = null;
  public rootUrl: string;
  public parentUrl: string;
  public entityID: number;
  public type: string;
  public userID: number;
  public capaType: string;
  private subRoute: any;
  private useNewScheduler: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromInfo.State>,
    private router: Router,
    private route: ActivatedRoute,
    private tools: ToolsService,
    public current: CurrentInfoService,
    private appSettings: AppSettingsService,
    private notify: NotifyService,
    private auditDetails: AuditDetailService,
    private mitigationDetails: MitigationDetailService,
    private uiNotify: UiNotificationService) {

    router.events
      .filter(event => event instanceof NavigationEnd || event instanceof NavigationStart).takeUntil(this.destroy$)
      .subscribe((event: RouterEvent) => {
        // You only receive NavigationStart events
        if (event instanceof NavigationEnd) {
          this.getParentUrl();
          this.stripType = this.getRouteTabStripType(event.url);
        }
      });
    this.getUserID();
    this.useNewScheduler = this.appSettings.currentAppSettings.useNewScheduler;
  }

  public getUserID(): void {
    if (this.current.info.user) {
      this.userID = this.current.info.user.ID;
    }
  }
  public getRouteTabStripType( url: string ): string {
    let root = this.router.routerState.snapshot.root;

    this.getEntityID(url);

    while ( root ) {
      if ( root.children && root.children.length ) {
        root = root.children[ 0 ];
      } else if ( root.data && root.data[ "stripType" ] ) {
        this.type = root.params.type;
        return root.data[ "stripType" ];
      } else {
        return;
      }
    }
  }

  public getEntityID(url: string) {
    const parts: any [] = url.split("/");
    const id = parts[parts.length - 1];
    const entityID = parseInt(id, 10);

    if (entityID != NaN && entityID) {
      this.entityID = entityID;
    } else {
      let root = this.router.routerState.snapshot.root;

      while ( root ) {
        if ( root.children && root.children.length ) {
          root = root.children[ 0 ];
          this.entityID = root.params.id;
          this.capaType = root.data["mode"];
        } else {
          return;
        }
      }
    }
  }

  getParentUrl() {
    this.getEntityID(this.router.url);
    const urlSegments = this.router.url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (this.entityID !== +lastSegment) {
      urlSegments.splice(-1, 1);
      if (this.entityID && lastSegment.includes(this.entityID.toString())) {
        urlSegments.push(this.entityID.toString())
      }
    }
    this.parentUrl = urlSegments.join("/");
  }

  isOpenMitigations() {
    return this.mitigationDetails.isMitigationOpened;
  }

  auditHasReferences() {
    return this.auditDetails.auditHasReferences;
  }

  isAdhoc() {
    return this.route.snapshot.queryParams.isFromAdHocAudits === "true";
  }

  selectedSiteID() {
    return this.route.snapshot.queryParams.selectedSiteID;
  }

  public navigateToAdHocActivity() {
    if (this.tools.isOnline()) {
      this.router.navigate(["/home/audit-create"]);
    } else {
      this.uiNotify.open("Action unavailable offline", true);
    }
  }

  public ngOnInit() {
    this.getParentUrl();
    this.getUserID();
    const snapshot: any  = this.route.snapshot;
    const url: string = snapshot._routerState.url;
    this.stripType = this.getRouteTabStripType(url);
  }

  public ngOnDestroy() {
    if (this.subRoute) { this.subRoute.unsubscribe(); }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
