import { IArtifact, IFileFormat } from "@core/models/ease-models";
import { FileUploader } from "ng2-file-upload";

export enum FileUploadTypes {
  Document = 0,
  AuditQuestion = 1,
  Mitigation = 2,
  CorrectiveAction = 3,
  Import = 4,
  Organization = 5,
}

export class FileUploadData {

  artifacts: IArtifact[];
  uploader: FileUploader;

  totalArtifacts: number; // Includes both existing artifacts and artifacts in queue

  filePreviewPaths: any[];
  isFirstPass: boolean;
  fileUploadType: FileUploadTypes;
  fileFormats: IFileFormat[];
  limit: number;
  limitMet: boolean;

  headers: any[];

  private hostTypes: any[] = [
    { ID: 0, apiName: "/documentartifact", apiHeader: "DocumentID" },
    { ID: 1, apiName: "/auditquestionartifact", apiHeader: "AuditQuestionID" },
    { ID: 1, apiName: "/mitigationartifact", apiHeader: "MitigationID" },
    { ID: 3, apiName: "/capaartifact", apiHeader: "CapaEventID"},
    { ID: 4, apiName: "/importexport", apiHeader: "DataImportExportType"},
    { ID: 5, apiName: "/organizationartifact", apiHeader: ""},
  ];

  constructor() {
    this.totalArtifacts = 0;
    this.filePreviewPaths = [];
    this.isFirstPass = true;
    this.fileFormats = [];
    this.artifacts = [];
    this.uploader = null;
    this.limit = null;
    this.headers = [];
  }

  public getApiHeaderName(): string {
    return this.hostTypes[this.fileUploadType].apiHeader;
  }

  public getApiName(): string {
    return this.hostTypes[this.fileUploadType].apiName;
  }

  public updateTotalArtifacts(): void {
    this.totalArtifacts = this.artifacts.filter(a => !a.FlaggedForDeletion).length + this.uploader.queue.length;
    if (this.limit !== null) {
      this.limitMet = this.totalArtifacts >= this.limit;
    }
  }
}

export interface FileUploadResponse {
  success: boolean;
  response: any;
}
