import { Injectable } from "@angular/core";
import { ReplaySubject,  Observable } from "rxjs";
import { IRole } from "../models/ease-models";
import { ApiService } from "./api.service";

@Injectable()
export class RoleService {
    constructor(private api: ApiService) {}

    public getRoles(query: any) {
        const result = new ReplaySubject<any>(1);
        this.api.Query("Role", query, (data: IRole[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                // console.log("no users found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }
}
