import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";

// Services
import { IdentityService } from "../services/identity.service";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(public identity: IdentityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const baseUrl = this.identity.endpoints.Service;
    const schemeRe = /^http[s]?:\/\//;

    const modified = req.clone({
      url: req.url.includes(baseUrl) || schemeRe.test(req.url)
        ? req.url
        : baseUrl.concat(req.url),
    });
    return next.handle(modified);
  }
}
