import { Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { QuestionListView } from "@core/models/ease-models";

export interface InputDialogComponentData {
  title: string;
  label?: string;
  description?: string;
  value?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
  required?: boolean;
  lineItem?: QuestionListView;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h1 mat-dialog-title>
      <span>{{ data.title | i18n }}</span>
    </h1>
    <mat-dialog-content>
      <div class="padding-top-15 padding-bottom-30">
        <form #form="ngForm" autocomplete="off">
          <h4>{{data.description | i18n}}</h4>
          <mat-form-field color="accent">
            <textarea
              matInput
              name="formValue"
              placeholder="{{ data.label | i18n}}"
              matTextareaAutosize 
              matAutosizeMinRows="{{data.minRows}}"
              matAutosizeMaxRows="{{data.maxRows}}"
              [(ngModel)]="data.value"
              [required]="data.required"
              maxlength="{{data.maxLength}}" 
              autocomplete="off">
            </textarea>
          </mat-form-field>
        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button tabindex="2" (click)="close()">
        {{ data.cancelButtonText | i18n }}
      </button>
      <button mat-raised-button color="accent" (click)="save()" [disabled]="!form.valid">
        {{ data.confirmButtonText | i18n }}
      </button>
    </mat-dialog-actions>`,
})
export class InputDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDialogComponentData,
  ) {
    data.confirmButtonText = data.confirmButtonText || "_Save_";
    data.cancelButtonText = data.cancelButtonText || "_Cancel_";
    data.minRows = data.minRows || 1;
    data.maxRows = data.maxRows || 1;
    data.maxLength = data.maxLength || 100;
  }

  public save() {
    this.dialogRef.close(this.data.value || "");
  }

  public close() {
    this.dialogRef.close(false);
  }
}
