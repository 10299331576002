import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    templateUrl: "./change-password-modal-container.component.html",
})

export class ChangePasswordModalContainerComponent {

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordModalContainerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}
}