import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "ease-thumbnail",
    templateUrl: "./thumbnail.component.html",
    styleUrls: ["./thumbnail.component.scss"],
})

export class ThumbnailComponent {
    @Input() public imgUrl: string;
    @Input() public canEdit: boolean = true;
    @Input() public height: number = 100;

    @Output() public showImageDetails = new EventEmitter<string>();
    @Output() public removeImageClicked = new EventEmitter<boolean>();

    constructor() {
    }

    public removeImage(): void {
        this.removeImageClicked.emit(true);
    }

    public displayImageDetail() {
      this.showImageDetails.emit(this.imgUrl);
    }
}
