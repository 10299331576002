import {
  IAuditConfig,
  IHighChartResult,
} from "../../../core/models/ease-models";
import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BasePanel } from "@shared/reports/base-panel";
import { LocalizeService } from "../../../core/services/localize.service";
import { ReportDataAccessService } from "../../../report/report-data-access.service";
import { IDialogReportPanelMessage, ReportNotifyService, ReportPanelDialogMessageType } from "../../../shared/reports/report-notify.service";
import { MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import { LocalService } from "../../../core/services/index";
import { CurrentInfoService } from "../../../core/services/current.service";
import * as moment from "moment";

@Component({
  selector: "ease-recent-audits",
  templateUrl: "../../../report/reports/report-panel.html",
})

export class RecentAuditsComponent extends BasePanel implements OnInit, OnDestroy {
  public dashboardUpdatesSubscription: Subscription;
  public tooltipTemplate: string;
  private locationLevel: number;
  @Output() private onLoadComplete: () => void;
  @Output() private onInitComplete: () => void;

  constructor(private localize: LocalizeService,
              private notifyService: ReportNotifyService,
              private matDialog: MatDialog,
              private reportDataAccess: ReportDataAccessService,
              private dialogSvc: MatDialog,
              private localStorage: LocalService,
              public current: CurrentInfoService) {

    super(localize, notifyService, matDialog, localStorage);
  }

  public ngOnInit(): void {
    this.widgetFilters = {
      enableGroupingOption: false,
      enableSeperationOption: false,
      enableCategoryGroup: false,
      enableDataLabels: true,
      showDataLabels: true,
      showGearButton: false,
    };
    this.leftYAxisText = this.localize.getLocalizedString("_Total_");
    this.enableStacking = true;
    this.enableLegend = true;
    this.chartDescription = this.localize.getLocalizedString("_RecentAudits15Days_");
    this.registerEvents();
    this.init();
    if (this.onInitComplete) {
      this.onInitComplete();
    }
  }

  public ngOnDestroy(): void {
    if (this.dashboardUpdatesSubscription) {
      this.dashboardUpdatesSubscription.unsubscribe();
    }
  }

  public getChartData(): void {
    this.loading = true;

    const mend: moment.Moment = moment().utc().startOf("day");
    const mstart: moment.Moment = moment().utc().subtract(14, "day").startOf("day");

    this.reportDataAccess.getChartData("RecentAuditsChart", {startDate: mstart, endDate: mend}).subscribe((data: IHighChartResult) => {
      this.loading = false;
      this.drawChart(data);
      this.initialLoadComplete = true;
    });
  }

  public onSeriesClick(e): void {
    // Certain orgs (ex: Ancon) will want to view the dashboard at an org level, meaning they want to see site by site comparisons
    const isOrgLevel = this.locationLevel <= 3;
    this.showAuditListDlg(e.point.ids, isOrgLevel, null, false, false, null, false, null, true);
  }

  public onLegendItemClick(e): boolean {
    const myname = e.target.name;
    const myvis = !e.target.visible;

    e.target.chart.series.forEach((elem) => {
      if (elem.name === myname) {
        elem.setVisible(myvis, false);
      }
    });
    e.target.chart.redraw(); // after changing the visibility, call the chart.redraw() once.
    e.target.visible = !e.target.visible; // toggle the visibility for the clicked legend item
    return false;
  }

  private drawChart(data: IHighChartResult) {
    this.chartData = data.ChartData;
    this.categoryAxis = [];
    this.locationLevel = data.LocationLevel;

    // The series name needs to be translated
    this.chartData.forEach((chartData) => {
      chartData.name = this.localize.getLocalizedString(chartData.name);
    }, this);

    data.CategoryAxisLabels.forEach((cat) => {
      cat = this.localize.getLocalizedString(cat);
      this.categoryAxis.push(cat);
    }, this);

    // legend names needs to be translated
    data.ChartData.forEach((chartdata) => {
      chartdata.name = this.localize.getLocalizedString(chartdata.name);
    }, this);

    this.setTooltipTemplate();

    this.setChartConfig(null, this.widgetFilters.enableDataLabels);
    if (this.onLoadComplete) {
      this.onLoadComplete();
    }
  }

  private registerEvents(): void {
    this.dashboardUpdatesSubscription = this.reportNotifyService.getMessage().subscribe(message => {
      const messageFromReportFilterDialog: IDialogReportPanelMessage = message;
      if (messageFromReportFilterDialog && (messageFromReportFilterDialog.key || messageFromReportFilterDialog.key === 0)) {
        switch (messageFromReportFilterDialog.key) {
          case ReportPanelDialogMessageType.dashboardFilterChanged:
          {
            this.getChartData();
            break;
          }
        }
      }
    });
  }

  private setTooltipTemplate(): void {
    this.tooltipTemplate = "#=series.name #:  #=value #";
  }

  private init(): void {
    this.getChartData();
    this.registerEvents();
  }
}
