import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Component, Inject, Injectable, Injector, OnDestroy } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { LoggerService } from "@core/services/logger.service";
import { Observable,  Subject } from "rxjs";
import { Config } from "./config.service";
import { IdentityService, AuthenticationResponse } from "./identity.service";
import { LocalService } from "./local.service";
import { LocalizeService } from "./localize.service";
import { NotifyService } from "./notify.service";
import { ToolsService } from "./tools.service";

@Injectable()
export class ApiService {

    private easeIsLoggedIn: boolean = false;

    constructor(
        private log: LoggerService,
        private http: HttpClient,
        private config: Config,
        private tools: ToolsService,
        private notify: NotifyService,
        private localize: LocalizeService,
        private local: LocalService,
        private identity: IdentityService) {
    }

    private sendAjax(method: string, entity: string, data: any, callback: (data: any) => void, errorCallback?: (data: any, status: any, headers: any, config: any) => void, finallyCallback?:
      (data: any, status: any, headers: any, config: any) => void) {
        // const apiTokenOK = this.local.containsItem("identity.service.token");
        // if (!apiTokenOK) {
        //     this.log.debug("api.service - no token, redirecting to login page");
        //     this.tools.sendToLoginPage();
        // }
        this.sendAjaxImpl(method, entity, data, callback, errorCallback, finallyCallback, false);
    }

    // sends an ajax message with the security token
    // if the message returns "not authorized", makes another ajax call
    // to refresh the security token, and retries the original ajax message
    private sendAjaxImpl(method: string, entity: string, data: any, callback: any, errorCallback: any, finallyCallback: any, repeatAttempt: boolean) {
        // this.log.debug('entered _sendAjaxImpl: entity = {' + entity + '} ' + (repeatAttempt ? '(repeat attempt!)' : ''));

        // const tauth = "Token " + this.local.getItem("identity.service.token");

        let body = null;
        let params = null;
        if (method == "POST" || method == "PUT") {
            body = data;
        } else {
            params = data;
        }
        let options: any = {
            // headers: new HttpHeaders().set("Authorization", tauth),
            observe: "response",
            params,
        };
        // Due to the way Angular handles PUT, a 200 success is read as a failure when the HTTP server
        // tries to parse the empty body, setting the responseType as text allows this to be a success.
        // if(method == "PUT") {
        //     options.responseType = "text";
        // }
        // Server should use 204 with empty body https://en.wikipedia.org/wiki/List_of_HTTP_status_codes

        // ngProgressLite.start();
        if (method == "GET") {
            this.http.get<any>(entity, options).subscribe(
                (response: HttpResponse<any>) => this.handleAjaxResponse(response, entity, callback, finallyCallback),
                (err: HttpErrorResponse) => this.handleAjaxErrorResponse(err, entity, method, data, repeatAttempt, callback, errorCallback, finallyCallback));
        } else if (method == "POST") {
            this.http.post<any>(entity, body, options).subscribe(
                (response: HttpResponse<any>) => this.handleAjaxResponse(response, entity, callback, finallyCallback),
                (err: HttpErrorResponse) => this.handleAjaxErrorResponse(err, entity, method, data, repeatAttempt, callback, errorCallback, finallyCallback));
        } else if (method == "PUT") {
            this.http.put<any>(entity, body, options).subscribe(
                (response: HttpResponse<any>) => this.handleAjaxResponse(response, entity, callback, finallyCallback),
                (err: HttpErrorResponse) => this.handleAjaxErrorResponse(err, entity, method, data, repeatAttempt, callback, errorCallback, finallyCallback));
        } else if (method == "DELETE") {
            this.http.delete<any>(entity, options).subscribe(
                (response: HttpResponse<any>) => this.handleAjaxResponse(response, entity, callback, finallyCallback),
                (err: HttpErrorResponse) => this.handleAjaxErrorResponse(err, entity, method, data, repeatAttempt, callback, errorCallback, finallyCallback));
        }
    }

    private handleAjaxResponse(response: HttpResponse<any>, entity: string, callback: any, finallyCallback: any) {
        //this.log.debug('sendAjaxImpl: entity = {' + entity + '}, good response, calling callbacks');
        if (callback) { callback(response.body, response.status, response.headers); }
        if (finallyCallback) { finallyCallback(response.body); }
        // ngProgressLite.done();
    }

    private handleAjaxErrorResponse(err: HttpErrorResponse, entity: string, method: string, data: any, repeatAttempt: boolean, callback: any, errorCallback: any, finallyCallback: any) {
        const status: number = err.status;

        this.log.debug("_sendAjaxImpl: entity = {" + entity + "}, bad response (" + status + ")");
    }

    public AddX(entity: string, data: any, query: any, callback: any, errorCallback?: any, finallyCallback?: any) {
        this.sendAjax("POST", this.tools.buildUrl(this.identity.endpoints.Service, entity, null, query), data, callback, errorCallback, finallyCallback);
    }

    public Add(entity: string, data: any, callback: any, errorCallback?: any, finallyCallback?: any) {
            this.sendAjax("POST", this.tools.buildUrl(this.identity.endpoints.Service, entity, null, null), data, callback, errorCallback, finallyCallback);
    }

    public Query(entity: string, query: any, callback: any, errorCallback?: any, finallyCallback?: any, context?: any) {
        this.sendAjax("GET", this.tools.buildUrl(this.identity.endpoints.Service, entity, null, query), null, callback, errorCallback, finallyCallback);
    }

    public QueryX(entity: string, data: any, query: any, callback: any, errorCallback?: any, finallyCallback?: any, context?: any) {
            this.sendAjax("POST", this.tools.buildUrl(this.identity.endpoints.Service, entity, null, query), data, callback, errorCallback, finallyCallback);
    }

    public Get(entity: string, data: any, id: any, callback: any, errorCallback?: any, finallyCallback?: any) {
            this.sendAjax("GET", this.tools.buildUrl(this.identity.endpoints.Service, entity, id, { details: true }), null, callback, errorCallback, finallyCallback);
    }

    public Update(entity: string, id: any, data: any, callback: any, errorCallback?: any, finallyCallback?: any) {
            this.sendAjax("PUT", this.tools.buildUrl(this.identity.endpoints.Service, entity, id, null), data, callback, errorCallback, finallyCallback);
    }

    public UpdateX(entity: string, id: any, data: any, query: any, callback: any, errorCallback?: any, finallyCallback?: any) {
        this.sendAjax("PUT", this.tools.buildUrl(this.identity.endpoints.Service, entity, id, query), data, callback, errorCallback, finallyCallback);
    }

    public Delete(entity: string, id: any, callback: any, errorCallback?: any, finallyCallback?: any) {
            this.sendAjax("DELETE", this.tools.buildUrl(this.identity.endpoints.Service, entity, id, null), null, callback, errorCallback, finallyCallback);
    }

    public DeleteX(entity: string, id: any, query: any, callback: any, errorCallback?: any, finallyCallback?: any) {
            this.sendAjax("DELETE", this.tools.buildUrl(this.identity.endpoints.Service, entity, id, query), null, callback, errorCallback, finallyCallback);
    }
}

export enum ApiEntities {
    Documents = "Documents",
    User = "User",
    UserAuditSubtypeLimit = "UserAuditSubtypeLimit",
    AuditAnswerArtifact = "auditanswerartifact",
    SiteSetting = "sitesetting",
    OrgSetting = "organizationsetting",
    MitigationSetting = "mitigationsettings",
}
