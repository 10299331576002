import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalizeService } from "@core/services/localize.service";
import { LoggerService } from "@core/services/logger.service";

export interface Feedback {
  subject: string;
  text: string;
}

export interface AppSetting {
  Name: string;
  FieldTypeID: number;
  Value: string;
}

@Injectable()
export class FeedbackService {

  public wootricLanguageCodes = [
    { name: "English",    code: "en-US", wCode: "EN" },
    { name: "Español",    code: "es-MX", wCode: "ES" },
    { name: "Deutsche",   code: "de-DE", wCode: "DE" },
    { name: "普通话",      code: "zh-CN", wCode: "CN_S" },
    { name: "Français",   code: "fr-FR", wCode: "FR" },
    { name: "Polski",     code: "pl-PL", wCode: "PL" },
    { name: "Português",  code: "pt-BR", wCode: "PT" },
    { name: "Italiano",   code: "it-IT", wCode: "IT" },
    { name: "Serbian",    code: "sr-SP", wCode: "SR" }, //not currently supported by Wootric
    { name: "Czech",      code: "cs-CZ", wCode: "CS" }, //not currently supported by Wootric
  ];

  constructor(private http: HttpClient,
              private localize: LocalizeService,
              private log: LoggerService,) {}

  public send(feedback: Feedback): Observable<string> {
    return this.http.post<string>("/feedback", feedback);
  }

  public getInfo(id: number): Observable<boolean> {
    return this.http.get<boolean>("/userfeedback/" + id);
  }

  public setResponseDate(): Observable<boolean> {
    return this.http.post<boolean>("/userfeedback", {});
  }

  public getLanguageCode(): string {
    let wLanguage: string = "EN";
    let language: string = this.localize.languageCode;

    if (this.wootricLanguageCodes.every((x) => x.code !== language)) {
      this.log.error(`${language} language code is not supported. Defaulting to English`);
      return wLanguage;
    }
    wLanguage = this.wootricLanguageCodes.find(
      x =>  x.code === language).wCode;
    if (wLanguage === "")  //if not found default to English
    {
      wLanguage = "EN";
    }
    return wLanguage;
  }

  getWootricAuthToken(): Observable<any> {
    return this.http.get("/AppSetting?name=WootricAuthToken");
  }
}
