// Interfaces related to list views
import { IDateRange } from "../date/date-types";

export interface INameValueObject {
    Name: string;
    Value: string;
}

export interface IPaginationModel {

    pageSize?: number;
    currentPage?: number;
    rows?: number;
    totalRows?: number;
}

export interface IStandardQueryResponseColumn {
    ColName: string;
    Distinct: INameValueObject[];
}

// Classes related to list views

export class ListViewFilterSortData {

    constructor() {
        this.clear();
    }

    public clear() {
        this.currentSort = "";
        this.filters = [];
    }

    public currentSort?: string;
    public filters?: ListViewFilter[];
}

export class ListViewFilter {

    constructor(colName: string, ids: number[]) {
        this.colname = colName;
        this.ids = ids;
    }
    public colname: string;
    public ids: number[];
}

export class ListViewFilterSortEvent {
    public action: string;
    public colName: string;
    public data: any;
    public sortOn: string;
    public updateFilter: boolean;
}

export class IListViewStateInfo {
    public pagingInfo?: IPaginationModel;
    public dateRange?: IDateRange;
    public filterSortData?: ListViewFilterSortData;
}