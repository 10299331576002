import { Pipe } from "@angular/core";
import { LocalizeService } from "@core/services/localize.service";

@Pipe({
  name: "i18n",
})
export class i18n {
  constructor(private localize: LocalizeService) {
  }

  public transform(value: string, args?: string[]): string {
    return this.localize.getLocalizedString(value, args);
  }
}
