import {Component, Inject, ElementRef, ViewChild, AfterViewInit} from "@angular/core";
import { ApiService } from "../../core/services/api.service";
import { SoftwareVersion } from "./release-notes-modal.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ReleaseNotesModalService } from "./release-notes-modal.service";

@Component({
  templateUrl: "./release-notes-modal.component.html",
})

export class ReleaseNotesModalComponent implements AfterViewInit {

  public isLoading = true;
  public isLoaded = false;
  public currentSoftwareVersion: SoftwareVersion;
  public softwareVersionIDAndDateList: any[];
  public currentSoftwareVersionIndex: number;

  public showPreviousVersionButton = false;
  public showNextVersionButton = false;
  public previousButtonLabel: string;
  public nextButtonLabel: string;

  @ViewChild("versionInfoDiv") elem: ElementRef;

  constructor(
    private api: ApiService,
    private getVersionData: ReleaseNotesModalService,
    public dialogRef: MatDialogRef<ReleaseNotesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngAfterViewInit() {
    this.getVersionData.getSoftwareVersionInfo().subscribe((myData: any[]) => {
      this.softwareVersionIDAndDateList = myData;
      if (this.softwareVersionIDAndDateList.length && this.softwareVersionIDAndDateList[0].ID) {
        this.getVersionData.getThisSoftwareVersion(this.softwareVersionIDAndDateList[0].ID).subscribe((versionData) => {
          this.currentSoftwareVersion = versionData;
          this.currentSoftwareVersionIndex = 0;
          this.setPreviousVersionButtonStatus();
          this.setNextVersionButtonStatus();
          setTimeout(() => {
            this.displayVersionInfo();
            this.isLoading = false;
          }, 500);
        });
      }
    });
  }

  public previousVersion(): void {
    this.isLoading = true;
    if (this.currentSoftwareVersionIndex < this.softwareVersionIDAndDateList.length - 1) {
      this.currentSoftwareVersionIndex++;
      const previousVersionID = this.softwareVersionIDAndDateList[this.currentSoftwareVersionIndex].ID;
      this.getVersionData.getThisSoftwareVersion(previousVersionID).subscribe((versionData) => {
        this.currentSoftwareVersion = versionData;
        this.displayVersionInfo();
        this.setPreviousVersionButtonStatus();
        this.setNextVersionButtonStatus();
        this.isLoading = false;
      });
    }
  }
  public nextVersion(): void {
    this.isLoading = true;
    if (this.currentSoftwareVersionIndex > 0) {
      this.currentSoftwareVersionIndex--;
      const nextVersionID = this.softwareVersionIDAndDateList[this.currentSoftwareVersionIndex].ID;
      this.getVersionData.getThisSoftwareVersion(nextVersionID).subscribe((versionData) => {
        this.currentSoftwareVersion = versionData;
        this.displayVersionInfo();
        this.setPreviousVersionButtonStatus();
        this.setNextVersionButtonStatus();
        this.isLoading = false;
      });
    }
  }
  private setPreviousVersionButtonStatus(): void {
    this.showPreviousVersionButton = false;
    if (this.softwareVersionIDAndDateList.length > 1) {
      if (this.currentSoftwareVersionIndex < this.softwareVersionIDAndDateList.length - 1) {
        this.previousButtonLabel = this.softwareVersionIDAndDateList[this.currentSoftwareVersionIndex + 1].Version;
        this.showPreviousVersionButton = true;
      }
    }
  }
  private setNextVersionButtonStatus(): void {
    this.showNextVersionButton = false;
    if (this.softwareVersionIDAndDateList.length) {
      if (this.currentSoftwareVersionIndex > 0) {
        this.nextButtonLabel = this.softwareVersionIDAndDateList[this.currentSoftwareVersionIndex - 1].Version;
        this.showNextVersionButton = true;
      } else
        if (this.currentSoftwareVersionIndex === 0) {//only the label needs to be set
        this.nextButtonLabel = this.softwareVersionIDAndDateList[0].Version;
      }
    }
  }
  private displayVersionInfo() {
      this.elem.nativeElement.innerHTML = this.currentSoftwareVersion.Description;
  }
}
