import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

// Modules
import { MaterialModule } from "../../material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { PipesModule } from "../pipes";

// Components
import { ListViewColumnArrangementComponent } from "./list-view-column-arrangement.component";
import { ListViewColumnNameComponent } from "./list-view-column-name.component";
import { ListViewFilterSortComponent } from "./list-view-filter-sort.component";
import { ListViewPagingComponent } from "./list-view-paging.component";
import { ListViewSearchComponent } from "./list-view-search.component";

// Services
import { ListViewStorageService } from "./list-view-storage.service";

const COMPONENTS = [
    ListViewColumnArrangementComponent,
    ListViewColumnNameComponent,
    ListViewFilterSortComponent,
    ListViewPagingComponent,
    ListViewSearchComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MaterialModule,
        FlexLayoutModule,

        PipesModule,
    ],
    exports: [...COMPONENTS],
    declarations: [...COMPONENTS],
    providers: [
        ListViewStorageService
    ],
})
export class ListViewModule {}
