import { Observable } from "rxjs";
import { share } from "rxjs/operators";

import { Component, Input, OnInit } from "@angular/core";

import { BreadcrumbComponent } from "../breadcrumb.component";

import { BreadcrumbsService } from "../breadcrumbs.service";

@Component({
  selector: "ease-breadcrumb-document-name",
  template: `
    <ease-breadcrumb-base-item
      [loading]="!(details$ | async)"
      [placeholder]="placeholder"
      [ngClass]="{'breadcrumb-loading': !(details$ | async)}">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        {{ (details$ | async)?.Document.Name | i18n }}
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbDocumentNameComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  details$: Observable<object>;
  placeholder = "_BreadcrumbDocumentNameLoading_";

  constructor(
    private breadcrumbs: BreadcrumbsService,
  ) {}

  ngOnInit() {
    this.details$ = this.breadcrumbs.getDocumentDetails(this.data.params.id).pipe(share());
  }
}
