import { Inject, Injectable } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import { BehaviorSubject,  Observable,  ReplaySubject, Subject } from "rxjs";
import { Config } from "./config.service";
import { IdentityService } from "./identity.service";
import { LocalService } from "./local.service";
import { NotifyService } from "./notify.service";
import { ToolsService } from "./tools.service";
import { WINDOW, WindowTokenModule } from "./window.service";

declare let FileTransfer: any;

export interface StorageKey {
    login: string;
    hostName: string;
    entityName: string;
    entityID?: any;
    filename?: string;
    fileFormatID?: number;
}

export interface IStorageService {
    storeObject(key: StorageKey, obj: Object): Observable<void>;
    retrieveObject<T>(key: StorageKey): Observable<T>;
    containsObject(key: StorageKey): Observable<boolean>;
    deleteObject(key: StorageKey): Observable<void>;
    retrieveObjectEntries<T>(key: StorageKey): Observable<Entry[]>;
}

@Injectable()
export class StorageService implements IStorageService {

    private useFiles: boolean;
    private pathSep: string;

    constructor(@Inject(WINDOW) window: Window,
                private notify: NotifyService,
                private config: Config,
                private tools: ToolsService,
                private log: LoggerService,
                private local: LocalService,
                private identity: IdentityService) {
        this.useFiles = this.tools.isApp();
        this.pathSep = "/";
    }

    public buildCurrentStorageKey(entityName: string, entityID: any, filename: string = "", fileFormatID?: number): StorageKey {
        return {
            login: this.identity.loginid ? this.identity.loginid : "",  // objects stored when not logged in use login ''
            hostName: this.tools.extractHost(this.identity.endpoints.Service),
            entityName,
            entityID,
            filename,
            fileFormatID,
        };
    }

    public storeObject(key: StorageKey, obj: any): Observable<void> {
        const result = new ReplaySubject<void>(1);
        const keyStr = this.keyAsString(key);
        this.whenReady(() => {
            try {
                if (this.useFiles) {
                    // save this object into file storage
                    if (this.isArtifactShape(obj)) {
                        let artifactPath: string;
                        const artifactTarget = cordova.file.dataDirectory + this.keyAsString(key, this.fileExtensionFromFormat(obj.FileFormatID));
                        const artifactSourceUrl = (this.isDoc(obj.FileFormatID) || obj.ArtifactUrl) ? obj.ArtifactUrl : obj.Url;
                        this.log.debug("storage.service: downloading artifact file from " + artifactSourceUrl + " to " + artifactTarget);
                        this.fileDownload(artifactTarget, artifactSourceUrl).subscribe(
                            () => {
                                this.log.debug("storage.service: downloaded artifact file from " + artifactSourceUrl + " to " + artifactTarget);
                                obj.url = artifactTarget; // overwrite the one-time-use online url with our new local url
                                const objTarget = cordova.file.dataDirectory + keyStr;
                                this.fileCreate(objTarget, obj).subscribe(
                                    () => {
                                      this.log.debug("storage.service: stored object in file " + objTarget);
                                      result.next(void true);
                                      result.complete();
                                    },
                                    (err: FileError) => {
                                      result.error(err);
                                    },
                                );
                            },
                            (err: FileError) => {
                              result.error(err);
                            },
                        );
                    } else {
                        //Save object without artifacts
                        const objTarget = cordova.file.dataDirectory + keyStr;
                        this.fileCreate(objTarget, obj).subscribe(
                            () => {
                                this.log.debug("storage.service: locally stored " + key.entityName + "." + key.entityID  + " into local storage for " + (key.entityID ? key.entityID : "Unauthenticated") + " on " + key.hostName);
                                result.next(void true);
                                result.complete();
                            },
                            (err: FileError) => {
                                result.error(err);
                            },
                        );
                    }
                } else {
                    // save this object into local storage
                    try {
                        this.local.setItem(keyStr, obj);
                        this.log.debug("storage.service: locally stored " + key.entityName + "." + key.entityID + " into local storage for " + (key.entityID ? key.entityID : "Unauthenticated") + " on " + key.hostName);
                        result.next(void true);
                        result.complete();
                    } catch (e) {
                        this.log.error("storage.service: failed to stored object as JSON in local storage " + keyStr + "(" + e + ")");
                        result.error(e);
                    }
                }
            } catch (err) {
                result.error(err);
            }
        });
        return result.asObservable();
    }

    public retrieveObject<T>(key: StorageKey): Observable<T> {
        const result = new ReplaySubject<T>(1);
        this.whenReady(() => {
            try {
                if (this.useFiles) {
                    // load this object from file storage
                    const source = cordova.file.dataDirectory + this.keyAsString(key);
                    this.fileRead<T>(source).subscribe(
                        (obj: T) => {
                            result.next(obj);
                            result.complete();
                        },
                        (err: FileError) => {
                            result.error(err);
                        },
                    );
                } else {
                    // load this object from local storage
                    const keyStr: string = this.keyAsString(key);
                    const jsonStr: string = this.local.getItem(keyStr);
                    const obj: T = JSON.parse(jsonStr);
                    this.log.debug("storage.service: retrieved object from localstorage at " + keyStr);
                    result.next(obj);
                    result.complete();
                }
            } catch (e) {
                result.error(e);
            }
        });
        return result.asObservable();
    }

    public retrieveObjectEntries<T>(key: StorageKey): Observable<any[]> {
        const result = new ReplaySubject<any[]>(1);
        this.log.debug("retrieveObjectEntries getting data from storage");
        this.whenReady(() => {
            try {
                this.log.debug("useFiles: " + this.useFiles);
                if (this.useFiles) {
                    // load objects from file storage
                    const source = cordova.file.dataDirectory + this.keyAsString(key, null, null, false);
                    this.log.debug("retrieveObjectEntries source: " + source);
                    this.directoryRead<T>(source).subscribe(
                        (obj: any) => {
                            const objJson = JSON.parse(obj);
                            this.log.debug("directoryRead returned items: " + objJson.length + " " + objJson);
                            const entities: any[] = [];
                            for (const data in objJson) {
                                const item = objJson[data];
                                this.log.debug("retrieveObjectEntries directory read item: " + item);
                                const idIndex = item.filename.indexOf("_") + 1;

                                if (item.filename.indexOf("AuditDetails") == -1 || item.filename.indexOf("MitigationDetails") == -1) {
                                    entities.push(item.filename.substring(idIndex).replace(".json", ""));
                                }
                            }
                            this.log.debug("retrieveObjectEntries all entities: " + entities.length);
                            result.next(entities);
                            result.complete();
                        },
                        (err: FileError) => {
                            this.log.error("error reading directory: " + err);
                            result.error(err);
                        },
                    );
                }
            } catch (e) {
                this.log.error("error reading directory: " + e);
                result.error(e);
            }
        });
        return result.asObservable();

    }

    public containsObject(key: StorageKey): Observable<boolean> {
        const result = new ReplaySubject<boolean>(1);
        this.whenReady(() => {
            try {
                if (this.useFiles) {
                    const source = cordova.file.dataDirectory + this.keyAsString(key);
                    this.fileExists(source).subscribe(
                        (exists: boolean) => {
                            result.next(exists);
                            result.complete();
                        },
                        (err: FileError) => {
                            result.error(err);
                        },
                    );
                } else {
                    const keyStr: string = this.keyAsString(key);
                    const contains = this.local.containsItem(keyStr);
                    result.next(contains);
                    result.complete();
                }
            } catch (e) {
                result.error(e);
            }
        });
        return result.asObservable();
    }

    public deleteObject(key: StorageKey): Observable<void> {
        const result = new ReplaySubject<void>(1);
        this.whenReady(() => {
            try {
                if (this.useFiles) {
                    this.retrieveObject<any>(key).subscribe(
                        (obj: any) => {
                            if (this.isArtifactShape(obj)) {
                                const artifactTarget = cordova.file.dataDirectory + this.keyAsString(key, this.fileExtensionFromFormat(obj.FileFormatID));
                                this.fileDelete(artifactTarget).subscribe(
                                    () => {
                                        this.log.debug("storage.service: removed artifact file " + artifactTarget);
                                    },
                                    (err: FileError) => {
                                        this.log.debug("storage.service: failed to remove artifact file " + artifactTarget);
                                    },
                                );
                            }
                            const target = cordova.file.dataDirectory + this.keyAsString(key);
                            this.fileDelete(target).subscribe(
                                () => {
                                    this.log.debug("storage.service: removed object file " + target);
                                    result.next(void true);
                                    result.complete();
                                },
                                (err: FileError) => {
                                    result.error(err);
                                },
                            );
                        },
                        (err: FileError) => {
                            result.error(err);
                        },
                    );
                } else {
                    const keyStr: string = this.keyAsString(key);
                    this.local.removeItem(keyStr);
                    this.log.debug("storage.service: removed object from local storage at " + keyStr);
                    result.next(void true);
                    result.complete();
                }
            } catch (e) {
                result.error(e);
            }
        });
        return result.asObservable();
    }

    /*
    storeArtifact(key: StorageKey, sourceUrl: string): Observable<void> {
        let result = new ReplaySubject<void>(1);
        this.whenReady(() => {
            try {
                if (this.useFiles) {
                    // save this object into file storage
                    let target = cordova.file.dataDirectory + this.artKeyAsString(key);
                    this.fileDownloadArtifact(key,sourceUrl).subscribe(
                        () => {
                            result.next(void true);
                            result.complete();
                        },
                        (err: FileError) => {
                            result.error(err);
                        }
                    );
                } else {
                    // save the URL into local storage
                    this.local.setItem(this.artKeyAsString(key), sourceUrl);
                    result.next(void true);
                    result.complete();
                }
            }
            catch (e) {
                result.error(e);
            }
        });
        return result.asObservable();
    }
    */
    /*
    retrieveArtifact(key: StorageKey): Observable<string> {
        let result = new ReplaySubject<string>(1);
        this.whenReady(() => {
            try {
                let keyStr: string = this.artKeyAsString(key);
                let str: string = this.local.getItem(keyStr);
                this.log.debug('storage.service: retrieved artifact URL at ' + keyStr);
                result.next(str);
                result.complete();
            }
            catch (err) {
                result.error(err);
            }
        });
        return result.asObservable();
    }

    containsArtifact(key: StorageKey): Observable<boolean> {
        let result = new ReplaySubject<boolean>(1);
        this.whenReady(() => {
            try {
                let keyStr: string = this.artKeyAsString(key);
                let val: boolean = this.local.containsItem(keyStr);
                this.log.debug('storage.service: ' + (val ? 'contains' : 'does not contain') + ' artifact at ' + keyStr);
                result.next(val);
                result.complete();
            }
            catch (err) {
                result.error(err);
            }
        });
        return result.asObservable();
    }

    deleteArtifact(key: StorageKey): Observable<void> {
        let result = new ReplaySubject<void>(1);
        this.whenReady(() => {
            try {
                let keyStr: string = this.artKeyAsString(key);
                this.local.removeItem(keyStr);
                this.log.debug('storage.service: removed artifact at ' + keyStr);
                result.next(void true);
                result.complete();
            }
            catch (err) {
                result.error(err);
            }
        });
        return result.asObservable();
    }
    */

    public getKeyContent(key: StorageKey): Observable<any[]> {
        const result = new ReplaySubject<any[]>(1);

        if (this.tools.isApp()) {
            this.containsObject(key).subscribe((fileExists: boolean) => {
                if (fileExists) {
                    this.retrieveObject(key).subscribe((data: any) => {
                        result.next(data);
                        result.complete();
                    }, (err: any) => {
                        this.log.error("retrieve key content error " + JSON.stringify(err));
                        result.next([]);
                        result.complete();
                    });
                } else {
                    result.next([]);
                    result.complete();
                }
            });
        } else {
            const keyStr: string = this.keyAsString(key);
            if (this.local.containsItem(keyStr)) {
                const data: any = JSON.parse(this.local.getItem(keyStr));
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        }
        return result.asObservable();
    }

    private whenReady(task: () => void) {
        if (this.useFiles) {
            this.notify.filesystemReady.asObservable().subscribe(task);   // as soon as cordova filesystem plugin is ready
        } else {
            window.setTimeout(task.bind(this), 0); // during next event loop
        }
    }

    private fileExists(target: string): Observable<boolean> {
        const result = new ReplaySubject<boolean>(1);

        window.resolveLocalFileSystemURL(target, (fileEntry: FileEntry) => {
            result.next(fileEntry.isFile);
            result.complete();
        }, (err: FileError) => {
            result.next(false);
            result.complete();
        });

        return result.asObservable();
    }

    private fileCreate(target: string, obj: Object): Observable<void> {
       const result = new ReplaySubject<void>(1);

       const parentFolder = this.parentFolder(target);
       const fileName = this.fileFromPath(target);
       console.log("storage.service: fileCreate " + parentFolder);

       this.resolveFolder(parentFolder).subscribe(
           () => {
               window.resolveLocalFileSystemURL(parentFolder,
                   (dirEntry: DirectoryEntry) => {
                       dirEntry.getFile(fileName, { create: true, exclusive: false },
                            (fileEntry: FileEntry) => {
                                fileEntry.createWriter(
                                   (fileWriter: FileWriter) => {
                                       fileWriter.onwriteend = () => {
                                            result.next(void 0);
                                            result.complete();
                                        };
                                       fileWriter.onerror = (e) => {
                                           result.error(e);
                                       };

                                       const data = JSON.stringify(obj);
                                       const dataObj = new Blob([data], { type: "text/plain" });
                                       fileWriter.write(dataObj);
                                    },
                                   (err: any) => {
                                       result.error(err);
                                   },
                                );
                            },
                            (err: any) => {
                                result.error(err);
                            },
                        );
                   },
                   (err: any) => {
                       result.error(err);
                   });
           },
           (err: any) => {
               result.error(err);
           },
       );
       return result.asObservable();
   }

    private fileDownload(target: string, sourceUrl: string): Observable<void> {
        const result = new ReplaySubject<void>(1);

        this.whenReady(() => {
            this.resolveFolder(this.parentFolder(target)).subscribe(
                () => {
                  this.log.debug("downloading from " + sourceUrl + " to " + target);
                  const fileTransfer = new FileTransfer();
                  fileTransfer.download(sourceUrl, target,
                    (entry: FileEntry) => {
                      result.next(void true);
                      result.complete();
                    },
                    (err: any) => {
                      result.error(err);
                    },
                  );
                },
                (err: any) => {
                  result.error(err);
                },
            );
        });

        return result.asObservable();
    }

    private fileRead<T>(source: string): Observable<T> {
        const result = new ReplaySubject<T>(1);

        window.resolveLocalFileSystemURL(source, (fileEntry: FileEntry) => {
            fileEntry.file(
                (file: File) => {
                    const size = file.size;
                    if (size !== 0) {
                        const reader = new FileReader();
                        reader.onloadend = (evt: ProgressEvent) => {
                            const jsonStr: string = reader.result;
                            const obj: T = (jsonStr !== "") ? JSON.parse(jsonStr) : [];
                            result.next(obj);
                            result.complete();
                        };
                        // reader.onerror = (evt: ErrorEvent) => {
                        //     result.error(reader.error);
                        // };
                        reader.readAsText(file);
                    } else {
                        result.next(JSON.parse("[]"));  // return empty array if file size is zero
                        result.complete();
                    }
                },
                (err: FileError) => {
                    result.error(err);
                });
        }, (err: FileError) => { result.error(err); });

        return result.asObservable();
    }

    private directoryRead<T>(source: string): Observable<T> {
        const result = new ReplaySubject<any>(1);

        window.resolveLocalFileSystemURL(source, (dirEntry: DirectoryEntry) => {
            const obj: any[] = [];
            const directoryReader = dirEntry.createReader();
            directoryReader.readEntries((entries: Entry[]) => {
                for (const entry of entries) {
                    if (entry.isFile && entry.fullPath.indexOf(".json") != -1) {
                        console.log("file name: " + entry.name);
                        obj.push({ filename: entry.name });
                    }
                }
                console.log("directoryRead: number of audits read: " + obj.length);
                result.next(JSON.stringify(obj));
                result.complete();
            }, (err: FileError) => {
                result.error(err);
            });
        }, (err: FileError) => { result.error(err); });

        return result.asObservable();
    }

    private fileDelete(target: string): Observable<void> {
        const result = new ReplaySubject<void>(1);

        window.resolveLocalFileSystemURL(target, (fileEntry: FileEntry) => {
            fileEntry.remove(
                () => {
                    result.next(void true);
                    result.complete();
                },
                (err: FileError) => {
                    result.error(err);
                });
        }, (err: FileError) => { result.error(err); });

        return result.asObservable();
    }

    private sanitizeField(field: string): string {
       return field.replace(/[\W_]+/g, "");
   }

    public keyAsString(key: StorageKey, extension?: string, fileFormatID?: number, isFile: boolean = true): string {
      const entityName = (key.entityName.indexOf("IArtifact") == -1) ? key.entityName : "IArtifact";
      let ext = extension ? extension : "json";
      if (fileFormatID) {
        ext = this.fileExtensionFromFormat(fileFormatID);
      }

      const isDoc = fileFormatID ? this.isDoc(fileFormatID) : this.isDoc(key.fileFormatID);
      const packageName = "Beacon";
      let keyPath = packageName + this.pathSep + this.identity.endpoints.Name + this.pathSep + this.sanitizeField(key.login) + this.pathSep + this.sanitizeField(key.hostName) + this.pathSep + key.entityName;
      if (isFile) {
        if (!isDoc) {
          keyPath += this.pathSep + key.entityName + "_" + key.entityID + "." + ext;
        } else {
          keyPath += this.pathSep + key.filename + "." + ext;
        }
      } 
      return keyPath;
    }

    private isArtifactShape(obj: any) {
        return (obj && (obj.ArtifactID || obj.ID) && (obj.Url || obj.ArtifactUrl) && obj.FileFormatID);
    }

    // TJP_FIXME - move this to another service? (artifact service?)
    private fileFormat: any[] =
    [{ Extension: "", MimeType: "" }, { Extension: "tiff", MimeType: "image/tiff" }, { Extension: "bmp", MimeType: "image/bmp" }, { Extension: "gif", MimeType: "image/gif" }, { Extension: "tga", MimeType: "image/x-tga" }, { Extension: "jpg", MimeType: "image/jpeg" }, { Extension: "png", MimeType: "image/png" }, { Extension: "jpeg", MimeType: "image/jpeg" },
    { Extension: "doc", MimeType: "application/msword" }, { Extension: "ppt", MimeType: "application/vnd.ms-powerpoint" }, { Extension: "pdf", MimeType: "application/pdf" }, { Extension: "xls", MimeType: "application/vnd.ms-excel" }, { Extension: "txt", MimeType: "text/plain" }, { Extension: "htm", MimeType: "text/html" }, { Extension: "html", MimeType: "text/html" },
    { Extension: "mht", MimeType: "message/rfc822" }, { Extension: "mhtml", MimeType: "message/rfc822" }, { Extension: "docx", MimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }, { Extension: "docm", MimeType: "application/vnd.ms-word.document.macroEnabled.12" }, { Extension: "xlsx", MimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    { Extension: "xlsm", MimeType: "application/vnd.ms-excel.addin.macroEnabled.12" }, { Extension: "pptx", MimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation" }, { Extension: "pptm", MimeType: "application/vnd.ms-powerpoint.presentation.macroEnabled.12" }, { Extension: "ppsx", MimeType: "application/vnd.openxmlformats-officedocument.presentationml.slideshow" },
    { Extension: "wav", MimeType: "audio/wav" }, { Extension: "mpg", MimeType: "video/mpeg" }, { Extension: "avi", MimeType: "video/avi" }, { Extension: "wmv", MimeType: "video/x-ms-wmv" }, { Extension: "wmf", MimeType: "application/x-msmetafile" }, { Extension: "mp3", MimeType: "audio/mpeg" }, { Extension: "mp4", MimeType: "video/mp4" }, { Extension: "mov", MimeType: "video/quicktime" },
    { Extension: "m4v", MimeType: "video/mp4" }, { Extension: "3gp", MimeType: "video/3gpp" }, { Extension: "zip", MimeType: "application/x-zip-compressed" },
    { Extension: "pps", MimeType: "application/vnd.ms-powerpoint" }, { Extension: "rtf", MimeType: "text/rtf" }, { Extension: "csv", MimeType: "text/csv" }];

    private allowedFileExtensions = [
        "jpg", "png", "jpeg", "bmp", "gif",
        "pdf", "msword", "vnd.openxmlformats-officedocument.wordprocessingml.document", "plain", "octet-stream",
        "vnd.ms-excel", "vnd.openxmlformats-officedocument.spreadsheetml.sheet", "vnd.ms-powerpoint",
        "vnd.openxmlformats-officedocument.presentationml.presentation", "rtf"
    ];

    // TJP_FIXME: consider refactoring this to remain private?
    private fileExtensionFromFormat(fileFormatID: number): string {
        return this.fileFormat[fileFormatID].Extension;
    }

    private resolveFolder(path: string): Observable<void> {
        const result = new ReplaySubject<void>(1);

        window.resolveLocalFileSystemURL(path,
            (dirEntry: DirectoryEntry) => {
                result.next(void 0);
                result.complete();
            },
            (err: FileError) => {
                if (err.code != FileError.NOT_FOUND_ERR) {
                  result.error(err);
                } else {
                    const parentPath = this.parentFolder(path);
                    this.resolveFolder(parentPath).subscribe(
                        () => {
                            window.resolveLocalFileSystemURL(parentPath,
                                (dirEntry: DirectoryEntry) => {
                                    const childFolder = path.split(this.pathSep).pop();
                                    dirEntry.getDirectory(childFolder, { create: true },
                                        (subEntry: DirectoryEntry) => {
                                            //this.log.debug('storage.service: resolveFolder() is created folder ' + parentPath + this.pathSep + childFolder);
                                            result.next(void 0);
                                            result.complete();
                                        },
                                        (err: any) => {
                                            //this.log.debug('storage.service: resolveFolder() failed creating folder ' + parentPath + this.pathSep + childFolder);
                                            result.error(err);
                                        },
                                    );
                                },
                                (err: FileError) => {
                                    result.error(err);
                                },
                            );
                        },
                        (err: FileError) => {
                            result.error(err);
                        },
                    );
                }
            },
        );

        return result.asObservable();
    }

    private parentFolder(path: string): string {
       return path.slice(0, path.lastIndexOf(this.pathSep));
   }

    private fileFromPath(path: string): string {
       return path.split(this.pathSep).pop();
   }

    public isDoc(fileFormatID: number, file?: any): boolean {
        let isValid = false;
        if (fileFormatID != null) {
            if (this.fileFormat[fileFormatID].MimeType.indexOf("image") == -1) {
                isValid = true;
            }
        } else if (file != null) {
            const mimeType = file.type.slice(file.type.lastIndexOf("/") + 1);
            const item = this.fileFormat.filter((format: any) => format.MimeType == mimeType);
            if (item.length > 0) {
                isValid = true;
            }
        }
        return isValid;
    }

    public storeImage(key: StorageKey, extension: string, dataBlob: Blob): Observable<void> {
        const result = new ReplaySubject<void>(1);
        const keyStr = this.keyAsString(key, extension);

        this.whenReady(() => {
            try {
                if (this.useFiles) {
                    const objTarget = cordova.file.dataDirectory + keyStr;
                    this.createImage(objTarget, dataBlob).subscribe(
                        () => {
                            result.next(void true);
                            result.complete();
                        },
                        (err: FileError) => {
                            result.error(err);
                        },
                    );
                } else {
                    // save this object into local storage
                    try {
                        result.next(void true);
                        result.complete();
                    } catch (e) {
                        this.log.error("storage.service: failed to store image as JSON in local storage " + keyStr + "(" + e + ")");
                        result.error(e);
                    }
                }
            } catch (err) {
                result.error(err);
            }
        });
        return result.asObservable();
    }

    private createImage(target: string, dataBlob: Blob): Observable<void> {
        const result = new ReplaySubject<void>(1);

        const parentFolder = this.parentFolder(target);
        const fileName = this.fileFromPath(target);

        this.resolveFolder(parentFolder).subscribe(
            () => {
                window.resolveLocalFileSystemURL(parentFolder,
                    (dirEntry: DirectoryEntry) => {
                        dirEntry.getFile(fileName, { create: true, exclusive: false },
                             (fileEntry: FileEntry) => {
                                 fileEntry.createWriter(
                                    (fileWriter: FileWriter) => {
                                        fileWriter.onwriteend = () => {
                                             result.next(void 0);
                                             result.complete();
                                         };
                                        fileWriter.onerror = (e) => {
                                            result.error(e);
                                        };

                                        fileWriter.write(dataBlob);
                                     },
                                    (err: any) => {
                                        result.error(err);
                                    },
                                 );
                             },
                             (err: FileError) => {
                                 result.error(err);
                             },
                         );
                    },
                    (err: FileError) => {
                        result.error(err);
                    });
            },
            (err: FileError) => {
                result.error(err);
            },
        );
        return result.asObservable();
    }

    public renameImage(source: string, newName: string): Observable<void> {
        const result = new ReplaySubject<void>(1);

        const parentFolder = this.parentFolder(source);
        const fileName = this.fileFromPath(source);

        if (this.tools.isApp()) {
            this.resolveFolder(parentFolder).subscribe(
                () => {
                    window.resolveLocalFileSystemURL(parentFolder,
                        (dirEntry: DirectoryEntry) => {
                            dirEntry.getFile(fileName, { create: true, exclusive: false },
                                 (fileEntry: FileEntry) => {
                                     fileEntry.moveTo(dirEntry, newName, (entry: FileEntry) => {
                                        result.next(void 0);
                                        result.complete();
                                     },
                                     (err: FileError) => {
                                         result.error(err);
                                     });
                                 },
                                 (err: FileError) => {
                                     result.error(err);
                                 },
                             );
                        },
                        (err: FileError) => {
                            result.error(err);
                        });
                },
                (err: FileError) => {
                    result.error(err);
                },
            );
        } else {
            result.next(void 0);
            result.complete();
        }

        return result.asObservable();
    }

    public deleteImage(target: string): Observable<void> {
        const result = new ReplaySubject<void>(1);

        if (this.useFiles) {
            this.fileDelete(target).subscribe(
                () => {
                    this.log.debug("storage.service: delete image file " + target);
                    result.next(void true);
                    result.complete();
                },
                (err: FileError) => {
                    result.error(err);
                },
            );
        } else {
            result.next(void 0);
            result.complete();
        }
        return result.asObservable();
    }

    // for uploading images, to display the icon after browsing the file
    public isAllowedFileType(file): boolean {
        const mimeType = file.type.slice(file.type.lastIndexOf("/") + 1);
        return this.allowedFileExtensions.indexOf(mimeType) !== -1;
    }

}
