import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "ease-breadcrumb-base-item",
  template: `
    <span *ngIf="loading">{{ placeholder | i18n }}</span>
    <span [@loading]="state">
        <ng-content></ng-content>
    </span>`,
  animations: [
    trigger("loading", [
      state("inactive", style({
        position: "relative",
        opacity: "0",
      })),
      state("active",   style({
        position: "relative",
        opacity: "1",
      })),
      transition("inactive => active", animate("240ms ease-in")),
      transition("active => inactive", animate("240ms ease-out")),
    ]),
  ],
})
export class BreadcrumbBaseItemComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  @Input() placeholder: string;
  state = "inactive";
  constructor() { }

  ngOnInit() {

  }
  ngOnChanges() {
    this.state = this.loading ? "inactive" : "active";
  }
}
