import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from "@ngrx/store";
import * as fromRoot from "../../reducers";
import * as fromAuth from "./auth";
import * as fromSession from "./session";
import * as fromInfo from "./info";
import * as fromLoginPage from "./login-page";
import * as fromRequestRestorePage from "./request-restore-page";
import * as fromSetPasswordPage from "./set-password-page";

export interface AuthState {
  status: fromAuth.State;
  session: fromSession.State;
  info: fromInfo.State;
  loginPage: fromLoginPage.State;
  requestRestorePage: fromRequestRestorePage.State;
  setPasswordPage: fromSetPasswordPage.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  status: fromAuth.reducer,
  session: fromSession.reducer,
  info: fromInfo.reducer,
  loginPage: fromLoginPage.reducer,
  requestRestorePage: fromRequestRestorePage.reducer,
  setPasswordPage: fromSetPasswordPage.reducer,
};

export const selectAuthState = createFeatureSelector<AuthState>("auth");

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state.status,
);
export const getAuthenticated = createSelector(
  selectAuthStatusState,
  fromAuth.getAuthenticated,
);
export const getAuthorized = createSelector(
  selectAuthStatusState,
  fromAuth.getAuthorized,
);
// Combined Deriver that tells when user is Logged In
export const getLoggedIn = createSelector(
  getAuthenticated,
  getAuthorized,
  (authenticated, authorized) => authenticated && authorized,
);

export const getLanguage = createSelector(selectAuthStatusState, fromAuth.getLanguage);
export const getLogin = createSelector(selectAuthStatusState, fromAuth.getLogin);
export const getRememberMe = createSelector(selectAuthStatusState, fromAuth.getRememberMe);
export const getToken = createSelector(selectAuthStatusState, fromAuth.getToken);
export const getTokenRefreshDate = createSelector(selectAuthStatusState, fromAuth.getTokenRefreshDate);
export const getTokenRefreshPending = createSelector(selectAuthStatusState, fromAuth.getTokenRefreshPending);

// Session
export const selectAuthSessionState = createSelector(
  selectAuthState,
  (state: AuthState) => state.session,
);
export const getReady = createSelector(selectAuthSessionState, fromSession.getReady);
export const getLoading = createSelector(selectAuthSessionState, fromSession.getLoading);
export const getTranslates = createSelector(selectAuthSessionState, fromSession.getTranslates);
export const getCustomTranslates = createSelector(selectAuthSessionState, fromSession.getCustomTranslates);

// Combined Deriver that tells when Application is ready to interact
export const getAppIsReady = createSelector(
  getReady,
  getLoading,
  getLoggedIn,
  getTranslates,
  getCustomTranslates,
  (ready, loading, loggedIn, translates, customTranslates) => {
    return ready && !loading && (loggedIn ? !!translates && !!customTranslates : !!translates);
  },
);

// User Info
export const selectAuthInfoState = createSelector(
  selectAuthState,
  (state: AuthState) => state.info,
);
export const getImpersonatorLogin = createSelector(selectAuthInfoState, fromInfo.getImpersonatorLogin);
export const getUser = createSelector(selectAuthInfoState, fromInfo.getUser);
export const getUserID = createSelector(selectAuthInfoState, fromInfo.getUserID);
export const getUserLanguage = createSelector(selectAuthInfoState, fromInfo.getUserLanguage);
export const getActiveSiteID = createSelector(selectAuthInfoState, fromInfo.getActiveSiteID);
export const getActiveOrgID = createSelector(selectAuthInfoState, fromInfo.getActiveOrgID);
export const getSites = createSelector(selectAuthInfoState, fromInfo.getSites);
export const getRoleIsSysAdmin = createSelector(selectAuthInfoState, fromInfo.getRoleIsSysAdmin);
export const getRoleIsOrgAdmin = createSelector(selectAuthInfoState, fromInfo.getRoleIsOrgAdmin);
export const getFeatureFlags = createSelector(selectAuthInfoState, fromInfo.getFeatureFlags);

// Login Page
export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginPage,
);
export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.getError,
);
export const getLoginPageMessage = createSelector(
  selectLoginPageState,
  fromLoginPage.getMessage,
);
export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending,
);

// Request Restore Page
export const selectRequestRestorePageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.requestRestorePage,
);
export const getRequestRestorePageError = createSelector(
  selectRequestRestorePageState,
  fromRequestRestorePage.getError,
);
export const getRequestRestorePagePending = createSelector(
  selectRequestRestorePageState,
  fromRequestRestorePage.getPending,
);

// Set Password Page
export const selectSetPasswordPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.setPasswordPage,
);
export const getSetPasswordPageError = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getError,
);
export const getSetPasswordPagePending = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getPending,
);
export const getSetPasswordPageHaveNumber = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getHaveNumber,
);
export const getSetPasswordPageHaveLowercase = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getHaveLowercase,
);
export const getSetPasswordPageHaveUppercase = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getHaveUppercase,
);
export const getSetPasswordPageHaveSpecialChar = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getHaveSpecialChar,
);
export const getSetPasswordPageHaveLength = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getHaveLength,
);
export const getSetPasswordPagePasswordsMatch = createSelector(
  selectSetPasswordPageState,
  fromSetPasswordPage.getPasswordsMatch,
);
