import { ICapaTask } from "@core/models/ease-models";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { LocalizeService } from "@core/services/localize.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FileUploadTypes } from "@shared/file-upload/file-upload.class";
import { CorrectiveActionsDataAccessService } from "@app/capa/services/corrective-actions-data-access.service";
import { NotifyService } from "@core/services/notify.service";

@Component({
  templateUrl: "./extend-capa-event-due-modal.component.html",
})

export class ExtendCapaEventDueModalComponent implements OnInit {

  currentCapaEvent;
  comments: string;
  public capaEventExtendForm: FormGroup;
  initialDate;

  constructor(
    public dialogRef: MatDialogRef<ExtendCapaEventDueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dataAccess: CorrectiveActionsDataAccessService,
    private localize: LocalizeService, private formBuilder: FormBuilder, private notify: NotifyService) {
  }

  ngOnInit() {
    if (this.data && this.data.CurrentCapaEvent) {
      this.currentCapaEvent = this.data.CurrentCapaEvent;
      const minDate = this.dataAccess.getSiteMinDate(this.currentCapaEvent.Site.TimeZoneOffset);

      const initialDateWithDaysAfterOpen =
        new Date(new Date(this.currentCapaEvent.DateOpened)
          .setDate(new Date(this.currentCapaEvent.DateOpened)
            .getDate() + this.data.daysDueAfterOpened));

      if (this.data.daysDueAfterOpened === 0 || minDate > initialDateWithDaysAfterOpen) {
        this.initialDate = minDate;
      } else {
        this.initialDate = initialDateWithDaysAfterOpen;
      }
    }
    this.capaEventExtendForm = this.formBuilder.group({
      dueDate: new FormControl(this.currentCapaEvent.DueDate, [Validators.required]),
      comments: new FormControl("", [Validators.maxLength(2000)]),
    });
  }

  public cancelHandler() {
    this.dialogRef.close(false);
  }
  public saveHandler() {
    this.currentCapaEvent.Comments = this.capaEventExtendForm.controls.comments.value;
    const dueDate = this.capaEventExtendForm.controls.dueDate.value;
    const payload = {...this.currentCapaEvent};
    payload.DueDate = new Date(Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate())).toISOString();
    this.dataAccess.updateCapaEvent(this.currentCapaEvent.ID, payload).subscribe(() => {
      this.dialogRef.close(dueDate);
      this.currentCapaEvent.DueDate = dueDate;
      this.notify.broadcast("save-capa-tasks", this.currentCapaEvent);
    });
  }
}
