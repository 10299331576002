﻿// Please stick all DTO interface objects here

export interface IAudit {
    ID: number;
    Description: string;
    AuditCategory: number;
    CalendarEventID: number;
    CalendarEvent?: ICalendarEvent;
    DateRequired?: Date;
    DateUpdated: Date;
    ChangeToken: String;
    DateCompleted: Date;
    DateStarted: Date;
    SiteID: number;
    LocationID: number;
    Location?: ILocation;
    DocumentID: number;
    DocumentRevID: number;
    ShiftID?: number;
    Shift: number;
    ShiftManagerID: number;
    DepartmentID: number;
    Department?: IDepartment;
    AuditorUserID: number;
    AssignedByUserID: number;
    EnteredByUserID: number;
    PercentComplete: number;
    Score: number;
    Status: number;
    OrgLayerID: number;
    OrgLayer?: IOrgLayer;
    IsPassed: boolean;
    HasNonConformance: boolean;
    DeletedByUserID: number;
    DeleteDate: Date;
    JobID: number;
    Job?: IJob;
    AuditorUser: IUser;
    NonConformanceData: any[];
    IsUpdatable: boolean;
    IsOnDemand: boolean;

    AuditQuestions: IClientAuditQuestion[];
    ApplicableLocationTierLevels: string;
    CompanySiteID?: number;
    IsAdHoc: boolean;
    GracePeriodDays?: number;
    SiteName?: string;
    ShiftName?: string;
    DateEntered?: Date;

    AuditSummary: IAuditSummary;
    IsSchedulerNotified: boolean;
    IsAutoGenerated?: boolean;
    IsRedirectAuditEntry?: boolean;
    DateRequiredSiteLocalTime?: Date;
    PendingUpload: boolean;

    /* client use only */
    IsNotSynced: boolean;
    DueDate?: Date;
}

export interface IClientAudit extends IAudit {
    StatusLabel: string;
    IsSelected: boolean;
    CanConduct: boolean;
    DataEntry: boolean;
    auditDueDate: Date;
    CompanySite?: ICompanySite;
    PastDueDays: number;
    AuditorFullName: string;
    ShiftID: number;
    StatusString: string;
    ShiftString: string;
    IsOffline: boolean;
    AuditSortOrder: number;
    Result: string;
    AuditCategoryName: string;
    CustomerName: string;

    FirstName?: string;
    LastName?: string;
    OrgLayerSiteDetail?: IOrgLayerSiteDetail;
    OrgLayerName?: string;
    HasAdhocMitigation?: boolean;
}

export interface IAuditAnswer {
    ID: number;
    AuditID: number;
    AuditQuestionID: number;
    AuditQuestionCategoryID: number;

    Comment: string;
    AuditVerifyDate?: Date;
    AltManagerID?: number;
    PointValue?: number;
    IsPassed?: boolean;
    OperaterName: string;
    MitigationActivity?: IMitigationActivity[];
    CustomFieldValues?: ICustomFieldValue[];
    ImagesToRemove?: any[];
    images?: any[];
    MitigationComment?: string;
    RankID: number;
    CountermeasureCustomItemID?: number;
    MinimumPassingScore?: number;
    IsOptionalMitigationSelected?: boolean;
    HasArtifact?: boolean;
    IssueClassificationID?: number;
    IssueClassification: IIssueClassification;
    IsNA: boolean;

    /*client use */
    MitigationDetail?: IMitigationDetail[];
    SelectedCustomFieldValues?: ICustomFieldValue[];
}

export interface IClientAuditAnswer extends IAuditAnswer {
    images: any[];
    commentRequired: boolean;
    failureCategoryRequired: boolean;
    photoRequired: boolean;
    CustomCategory?: ICustomCategory;
    CustomItem?: ICustomItem;
}

export interface IAuditAnswerArtifact {
    ID: number;
    AuditAnswerID: number;
    ArtifactID: number;
    OrganizationID: number;
    Url: string;
    FileFormatID: number;
    Filename: string;
}

export interface IAuditAnswerNonConformance {
    ID: number;
    AuditID: number;
    AuditQuestionGroup: number;
    Count: number;
    FailureModeID: number;
    FailureCategoryID: number;

    FailureMode?: IFailureMode;
}

export interface IAuditCompliance {
    OrganizationID: number;
    DocumentID: number;
    StartDate?: Date;
    EndDate?: Date;
    ComplianceState: number;
    AuditID: number;
}

export interface IAuditConfig {
    AuditCategoryID: number;
    Description: string;
    IsInspection: boolean;
    UseDocTeam: boolean;
    UseApproval: boolean;
    UseCoverage: boolean;
    QuestionOptions: IAuditConfigQuestionOptions;
    IsPointBased: boolean;
    IsNonConformanceBased: boolean;
    AuditTargets: AuditTargetTypes[];
    EnableRandomLocations: boolean;
    UseFailureCategories: boolean;
    UseLayers: boolean;
    BaseAuditSubtypeID: number;
    CanSchedule: boolean;
}

export interface IAuditorAssignment {
    AuditorID: number;
    Count: number;
    EpochDate: number;
}

export interface IAuditConfigQuestionOptions {
    UseWeight?: boolean;
    MaxPointValue?: number;
    UsePassFail?: boolean;
    UsePointValue?: boolean;
    UseMultipleChoice?: boolean;
    DefaultQuestionType?: number;
    UsePhotoRequiredCheckbox?: boolean;
    UseInputRequiredCheckbox?: boolean;
    UseAltManager?: boolean;
    UseOperatorName?: boolean;
    UseStations?: boolean;
    UseReasonText?: boolean;
    UseFailureReason?: boolean;
    DefaultMitigationRequired?: boolean;
    AllowCriticalOnly?: boolean;
    UseTextEntry?: boolean;
    UseNumericEntry?: boolean;
    DefaultIsRequired?: boolean;
    OrganizationID?: number;
    DefaultPhotoInput?: number;
}

export interface IAuditDetail {
    Audit: IAudit;
    FailureCategoriesCustomCategoryLineItems: ICustomCategoryLineItem[];
    AuditConfiguration: IAuditConfig;
    DocumentRev: IDocumentRev;
    AuditQuestionGroups: IAuditQuestionGroup[];
    AuditLineItems: IAuditLineItem[];
    DocumentArtifactIDs: number[];
    Managers: IUser[];
    CountermeasuresCustomCategoryLineItems: ICustomCategoryLineItem[];
    UserDetails: IUser;
    Ranks: IRank[];
    AcceptedFileFormats: IFileFormat[];
    Artifacts: IArtifact[];
    AuditAnswerArtifacts: IAuditAnswerArtifact[];
    AuditQuestionArtifacts: IAuditQuestionArtifact[];
    DocumentArtifacts: IDocumentArtifact[];
    CustomCategoryLineItems: ICustomCategoryLineItem[];
    DataEntryUser: IUser;
    AssignedByUser: IUser;
    Customer: ICompanySite;
}

export interface IAuditQuestionTag {

    ID?: number;
    OrganizationID?: number;
    AuditQuestionID: number;
    TagID: number;
    TagLabel?: string;
    Tag?: ITag;
}

export interface IAuditSummary {
    TotalPassed: number;
    TotalFailed: number;
    TotalNA: number;
    TotalScore: number;
    ContainsScoredQuestions: boolean;
    ContainsPassFailQuestions: boolean;
}

export interface IFailureCategory {
    ID: number;
    Description: string;
}

export interface IFailureMode {
    ID: number;
    Num: number;
    Code: string;
    Description: string;
    FailureCategoryID: number;
    FailureCategory?: IFailureCategory;
    SiteIDs?: number[];
}

export interface IAddress {
    ID: number;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    PostalCode: string;
    CountryID?: number;
    ProvinceID?: number;
}

export interface IArtifact {
    ID: number;
    OrganizationID: number;
    StorageProvider: number;
    StorageContainer: string;
    StorageKey: string;
    FileName: string;
    FileSize: number;
    FileFormatID?: number;
    IsTransparentImage: boolean;
    Url?: string;
    uploader?: any;
    ArtifactUrl?: string;
    ArtifactType: any;
    ParentID: number;
    OriginalArtifactUrl?: string;
    FlaggedForDeletion: boolean;
}

export interface IDocumentArtifact extends IArtifact {
  ID: number;
  DocumentID: number;
  Description: string;
  ArtifactID?: number;
}

export interface IAuditLineItem {
    ID?: number;
    OrganizationID?: number;
    DocumentRevID?: number;
    AuditQuestionID?: number;
    SequenceNum?: number;
    IsGeneric?: boolean;
    AuditQuestion?: IAuditQuestion;
    DocumentRev?: IDocumentRev;
    RandomQuestionConfig?: IRandomQuestionConfig;
    IsRequired?: boolean;
    AuditQuestionGroupID?: number;
    tags?: any[];
    AltResponsiblePartyID?: number;
    IsRequiredQuestion?: boolean;
    UpdateAltResponsiblePartyID?: boolean;
    UpdateWorkingDocuments?: boolean;

    // bulk edit properties
    IsRequiredDefault?: boolean;
    IsRequiredChecked?: boolean;
    IsRequiredOldValue?: boolean;
    IsRequiredHas3States?: boolean;
}

export interface IAuditQuestion {
    ID?: number;
    Description?: string;
    QuestionText?: string;
    AuditSubtypeID?: number;
    IsRequired?: boolean;
    LocationID?: number;
    PointValue?: number;
    Weight?: number;
    AuditQuestionGroupID?: number;
    AuditSubtype?: any;
    Artifacts?: any[];
    IsCritical?: boolean;
    AuditAnswer?: IAuditAnswer;
    MitigationResponseOption?: number;
    AllowAuditorToAssignAltManager?: boolean;
    QuestionType?: QuestionResponseTypes;
    ReasonText?: string;
    EnablePassFail?: boolean;
    images?: any[];
    AuditQuestionGroup?: IAuditQuestionGroup;
    CustomFieldID?: number;
    CustomField?: ICustomField;
    IsGeneric?: boolean;
    QuestionTextFormatted?: string;
    ReasonTextFormatted?: string;
    PhotoInputResponseOption?: number;
    OriginalID?: number;
    MinimumPassingScore?: number;
    ReactionPlan?: string;
    ReactionPlanFormatted?: string;

    Rev?: number;
    RevBy?: number;
    IsLatestRev?: boolean;
    AuditQuestionSetID?: number;

    //For force wrapping text
    WordBreakAll?: boolean;

    ScopeID?: number;
    ScopeName?: string;

    AltResponsiblePartyID?: number;
    IsCriticalQuestion?: boolean;
    IsAllowAuditorToAssignAltManager?: boolean;

    PassingMin?: number;
    PassingMax?: number;
    Comparison?: number;
    IsPassFailType?: boolean;
    IsAltRespPartyChecked?: boolean;

}

export interface IAuditQuestionArtifact extends IArtifact {
    ID: number;
    AuditQuestionID: number;
    ArtifactID: number;
    OrganizationID: number;
    Url: string;
    FileFormatID: number;
    Filename: string;
}

export interface ICapaArtifact extends IArtifact {
  ArtifactID: number;
  CapaEventID: number;
}

export interface IAuditQuestionGroup {
    ID?: number;
    Description?: string;
    Sequence?: number;
    Count?: number;
    DocumentRevID?: number;
}

export interface IAuditSubtype {
    ID: number;
    Name: string;
    IsEnabled: boolean;
    OrganizationID: number;
    IsLPA: boolean;
    IsReadOnly: boolean;
    BaseAuditSubtypeID?: number;
    IsSupplierTemplate: boolean;
}
export interface ICalendarEvent {
    ID?: number;
    OrganizationID?: number;
    CalendarSeriesID?: number;
    CalendarID?: number;
    EventType?: CalendarEventType;
    EventDetails?: number;
    Title?: string;
    Description?: string;
    AllDay?: boolean;
    Start?: Date;
    Due?: Date;
    CalendarSeries?: ICalendarSeries;
    UserID?: number;
    inheritedHoliday?: boolean;
}

export interface ICalendarSeries {
    ID?: number;
    OrganizationID?: number;
    CalendarID?: number;
    EventType: CalendarEventType;
    Title: string;
    Description: string;
    Length: number;
    Scale: CalendarRepeatScale;
    AllDay: boolean;
    SeriesStart: Date;
    SeriesRepeatInterval?: number;
    SeriesRepeatScale?: CalendarRepeatScale;
    SeriesRepeatLimitCount?: number;
    SeriesRepeatLimitDate?: Date;
    RepeatOn?: number[];
    EventsPerDay?: number;

}

export interface ICalendar {
    ID: number;
    CalendarType: number;
    Name: string;
    OrganizationID?: number;
    SiteID?: number;
    LocationID: number;
    DepartmentID: number;
    UserID: number;
    ShiftID?: number;

}

export interface ICalendarWorkSchedule {
    ID: number;
    CalendarID: number;
    OrganizationID: number;
    Name: string;
    Start: Date;
    End: Date;
    WorkingDays: number[];
    DaysInCycle: number;
    StartSiteLocalTime?: Date;

}

export interface IWorkday {
    index: number;
    name: string;
    status: boolean;
    DayOfWeek: string;
    Date: number;
}

export interface IClientAuditQuestion extends IAuditQuestion {
    images?: any[];
    currentImageIndex?: number;
    uploader?: any;
    isComplete?: boolean;
    isNonConformance?: boolean;
    mitigateNow?: boolean;
    customCategories?: ICustomCategory[];
    imagesToRemove?: any;
    tags?: ITag;
    isQuestionRequired?: boolean;
    isExpanded?: boolean;

    CustomGroupID?: number;
    MinValue?: number;
    MaxValue?: number;

    // Properties that are only used by the client
    ActiveDocumentRevs?: IDocumentRev[];
    DraftDocumentRevs?: IDocumentRev[];
    DocumentRevs?: IDocumentRev[];
    Revise?: boolean;
    Update?: boolean;
    DisplayCustomItemsInGroup?: boolean;
    SelectedCustomCategories?: any[];

    // bulk edit properties
    IsCriticalDefault?: boolean;
    IsCriticalChecked?: boolean;
    IsCriticalOldValue?: boolean;
    IsCriticalHas3States?: boolean;

    SetupPassRangeDefault?: boolean;
    SetupPassRangeChecked?: boolean;
    SetupPassRangeOldValue?: boolean;
    SetupPassRangeHas3States?: boolean;

    IsAltRespPartyDefault?: boolean;
    IsAltRespPartyOldValue?: boolean;
    IsAltRespPartyHas3States?: boolean;

    UseMinPassingScoreDefault?: boolean;
    UseMinPassingScoreChecked?: boolean;
    UseMinPassingScoreOldValue?: boolean;
    UseMinPassingScoreHas3States?: boolean;
    UseMinPassingScoreValue?: boolean;

    UseValidRangeDefault?: boolean;
    UseValidRangeChecked?: boolean;
    UseValidRangeOldValue?: boolean;
    UseValidRangeHas3States?: boolean;
    UseValidRangeValue?: boolean;

    PassDescription?: string;
    FailDescription?: string;
}

export interface ICompany {
    ID?: number;
    Name?: string;
    OrganizationID?: number;
    PartnerOrganizationID?: number;
    PartnerLinkRequested?: boolean;
    HasCustomer?: number;
    HasSupplier?: number;
    HasCustomerLabel?: string;
    HasSupplierLabel?: string;

    TargetApproverID?: number;
    MappedBy?: number;
    MappedOn?: Date;
    IsModified?: boolean;
}

export interface ICompanySite {
    ID?: number;
    OrganizationID?: number;
    SiteID?: number;
    CompanyID?: number;
    Company?: ICompany;
    Name?: string;
    IsSupplier: boolean;
    IsCustomer: boolean;
    PartnerSiteID?: number;

    ContactID?: number;
    Contact?: IContact;
    AddressID?: number;
    Address?: IAddress;

    CustomerLabel?: string;
    SupplierLabel?: string;
    RequestStatusLabel?: string;
    PartnerLink?: IPartnerLink;
    PartnerLinkID?: number;

    MappedBy?: number;
    MappedOn?: Date;
    IsModified?: boolean;

    CityStateZip?: string;
    IsSelected?: boolean;
    IsSelectionDisabled?: boolean;
}

export interface IContact {
    ID?: number;
    OrganizationID?: number;
    SiteID?: number;
    Name?: string;
    JobTitle?: string;
    WorkPhone?: string;
    CellPhone?: string;
    Details?: string;
    AddressID?: number;
    Address?: IAddress;
    CompanyID?: number;
    Company?: ICompany;
    CompanySiteID?: number;
    CompanySite?: ICompanySite;
    IsPrimaryContactAtSite?: boolean;
    IsPrimaryContactAtCompany?: boolean;
}

export interface ISelectedtable {

    IsSelected?: boolean;
    IsComplete?: boolean;
}

export interface ICustomCategory extends ISelectedtable {
    ID?: number;
    OrganizationID?: number;
    Name: string;
    CustomGroupID?: number;

    CustomItems?: ICustomItem[];
    IsCustomCategoryActive?: boolean;

    //client side use only
    Subtotal?: number;
    SelectedCustomItems?: ICustomItem[];
}

export interface ICustomItem extends ISelectedtable {
    ID?: number;
    OrganizationID?: number;
    Name: string;
    Tags?: ITag[];
    CustomItemTags?: ICustomItemTag[];

    // These 2 fields are only being used in the client and never returned from the server
    CustomCategoryID?: number;
    CustomCategory?: ICustomCategory;
    CustomFieldValue?: ICustomFieldValue;

    // used for data loader
    PercentMode?: number;
}

export interface ICustomCategoryLineItem {
    ID?: number;
    OrganizationID?: number;
    CustomCategoryID: number;
    CustomItemID: number;

    CustomCategory: ICustomCategory;
    CustomItem: ICustomItem;

    /* client use only*/
    Tags?: ITag[];
}

export interface ICustomField {
    ID?: number;
    OrganizationID?: number;
    FieldTypeID?: number;
    Name?: string;
    EntityID?: number;
    EntityTypeID?: number;
    EntitySubType?: number;
    EntityFilter?: string;
    RestrictEntry?: string;
    RestrictRange?: string;
    RestrictToGroup?: number;
    RestrictToCategory?: number;
    Cardinality?: string;
    Nullable?: boolean;
    CustomGroupID?: number;
    CustomCategoryID?: number;
    CustomItem?: ICustomItem;
    CustomItemID?: number;
    RestrictToGroupItems?: ICustomItem[];
    CustomFieldValue?: ICustomFieldValue;
}

export interface ICustomFieldValue {
    ID?: number;
    OrganizationID?: number;
    ParentEntityTypeID?: number;
    ParentEntityID?: number;
    EntityTypeID?: number;
    EntityID?: number;
    CustomFieldGroupID?: number;
    CustomFieldID?: number;
    FieldValueStr?: string;
    FieldValueNum?: number;
    FieldValueDate?: Date;
    FieldValueCustomItemID?: number;
    FieldValueCustomCategoryID?: number;
    MitigationActivityID?: number;
    MultiSelectItems?: number[];

    //Properties used in audit details
    MitigateNow?: boolean;
    MitigationActivity?: IMitigationActivity;
    Name?: string;

    //client side use only
    MitigationDetail?: IMitigationDetail;
}

export interface ICustomItemTag {
    ID?: number;
    OrganizationID?: number;
    CustomItemID: number;
    TagID: number;
    Tag: ITag;
}

export interface IDepartment {
    ID: number;
    SiteID?: number;
    Site?: ISite;
    Name: string;
    Description?: string;
    ManagerID?: string;
    Manager?: IUser;
    CanBeDeleted?: boolean;
}

export interface IDocument {
    ID: number;
    Name: string;
    IsPublished: boolean;
    ScopeID: number;
    LocationTierLevels: string;
    Properties: any;
    WorkingDocRevID?: number;
    AltResponsiblePartyID?: number;
    AltResponsibleParty?: IUser;
}

export interface IDocumentRev {
    ID: number;
    DocumentID: number;
    Document: IDocument;
    PartID: number;
    DepartmentID: number;
    CreatedByID: number;
    CreatedBy: IUser;
    CreatedOn: Date;
    EffectiveFrom: Date;
    EffectiveTo: Date;
    InUseByID: number;
    CheckedOutByID: number;
    CheckedOutOn: Date;
    OriginalFilePath: string;
    OriginalFileName: string;
    OriginalFileFormatID: number;
    SiteID: number;
    RequestingParty: string;
    ManagerID: number;
    DocumentTypeID: number;
    DocumentSubtype: number;
    Location: any;
    LocationID: number;
    ScopeID: number;
    DocumentSubtypeName: string;
    DocumentTypeName: string;

    Rev: number;
    RevBy: number;
    RevByUser: IUser;
    RevOn: Date;
    ReleaseStatusID: DocumentStatus;
    ReleaseStatus: any;
    ChangeRequestID: number;
    WorkingDocDataID: number;
    ReleaseDocDataID: number;

    Properties: any;
    AuditComplianceEntries?: IAuditCompliance[];
    IsWorkingDoc: boolean;

    // Properties that are only used by the client
    AuditCategoryName: string;
    IsPublishedFlag: string;
    IsPublishedLabel: string;
    LocationIDs?: number[];
    HasAuditQuestions?: boolean;
    DocLocationTypes?: ILocationType[];
    //location tier for the selected document
    DocLocations?: ILocationDoc[];
    IsUsingRandomLocation?: boolean;
    HasApproval?: boolean;
    expanded?: boolean;
    Approvers?: any[];
    featureFlags?: any;
}

export interface IDocumentRevDocLocs {
    ID?: number;
    DocumentID?: number;
    Document?: IDocument;
    PartID?: number;
    DepartmentID?: number;
    CreatedByID?: number;
    CreatedBy?: IUser;
    CreatedOn?: Date;
    EffectiveFrom?: Date;
    EffectiveTo?: Date;
    InUseByID?: number;
    CheckedOutByID?: number;
    CheckedOutOn?: Date;
    OriginalFilePath?: string;
    OriginalFileName?: string;
    OriginalFileFormatID?: number;
    SiteID?: number;
    RequestingParty?: string;
    ManagerID?: number;
    DocumentTypeID?: number;
    DocumentSubtype?: number;
    Location?: any;
    LocationID?: number;
    ScopeID?: number;
    DocumentSubtypeName?: string;
    DocumentTypeName?: string;
    AltResponsibleParty?: IUser;
    AltResponsiblePartyID?: number;

    Rev?: number;
    RevBy?: number;
    RevByUser?: IUser;
    RevOn?: Date;
    ReleaseStatusID?: number;
    ReleaseStatus?: number;
    ChangeRequestID?: number;
    WorkingDocDataID?: number;
    ReleaseDocDataID?: number;

    Properties?: any;
    AuditComplianceEntries?: IAuditCompliance[];
    IsWorkingDoc?: boolean;

    // Properties that are only used by the client
    AuditCategoryName?: string;
    IsPublishedFlag?: string;
    IsPublishedLabel?: string;

    // location tier for the selected document
    DocLocations?: ILocationDoc;
    // locations view-> will hold the document schedule days for the selected location
    LocDocScheduleDays?: IScheduleDateItem;
}

export interface IDocumentArtifact {
    ID: number;
    DocumentID: number;
    Description: string;
    Url?: string;
    ArtifactID?: number;
    FileFormatID?: number;
    ArtifactUrl?: string;
    uploader?: any;
}

export interface DocumentListView extends ISelectedtable {
    ID: number;
    OrganizationId: number;
    IsPublished: boolean;
    Description: string;
    PublishedStatus: string;
    DraftStatus: string;
    DocumentID: number;
    Category: string;
    DocumentType?: string;
    LocationTierLevels?: string;
    DocLocations: ILocation[];

    DocLocationTypes?: ILocationType[];
    IsUsingRandomLocation?: boolean;
    LocationID: number;
}

export interface QuestionListView {
  ID: number;
  OrganizationID: number;
  AuditQuestionID: number;
  Title: string;
  Question: string;
  AuditSubtypeID: string;
  IsCritical: boolean;
  Tags: ITag[];
  ArtifactIDs: string;
  ScopeID?: number;
  AuditQuestionGroupID?: number;
  SequenceNum?: number;
  IsGeneric: boolean;
  Artifacts: string [];
  IsSelected: boolean;
  RandomQuestionConfig: IRandomQuestionConfig;
  DocumentRevID: number;
  QuestionType?: number;
  IsRequired: boolean;
  AllowAuditorToAssignAltManager: boolean;
  MinimumPassingScore?: number;
  Comparison?: number;
  CustomField: ICustomField;
}

export interface ILocationType {
    id: number;
    name: string;
    selected: boolean;
    visible: boolean;
    icon: string;
}

export enum CalendarEventType { All, TimeOff, EmployeeTimeOff, Audit, OrgLayer }
export enum CalendarRepeatScale { None, Daily, Weekly, Monthly, Yearly, OrgLayer }
export enum LocationTypes {
    None = 0,
    Organization = 1,
    Division = 2,
    Site = 3,
    Workgroup = 4,
    Workcenter = 5,
    Workstation = 6,
}
export enum JobStatus { Open, Closed }
export enum AuditTargetTypes { NotDefined, Location, Job, Part }
export enum QuestionResponseTypes { None, PassFail, Point, MultipleChoice, MultiSelect, Text, Numeric }
export enum PhotoInputResponseOption { Off, Optional, RequiredOnFail, Required }
export enum MitigationResponseOption { Off, Optional, RequiredOnFail }
export enum PassRangeTypes { Between, Outside, LessThan, GreaterThan }
export enum EaseAuditStatus { NotStarted, Complete, Incomplete, Missed, CompletedLate, PastDue }
export enum ArtifactTypes { AuditQuestion, AuditAnswer, Audit }
export enum ApplicationTypes { Auditing = 1, LaborStandards = 2 }
export enum AuditCategories { CustomerSurvey }
export enum CompletionStatus { NotStarted, Complete, PartiallyComplete, Missed, CompletedLate, PastDue }
export enum MitigationDetailedStatus { OnTimeOpen, PastDueOpen, OnTimeClosed, PastDueClosed, OnTimePending,
PastDuePending }
export enum EaseMitigationStatus { Open, Closed, Pending }
export enum ListViewName { _AllAudits_, _AllMitigations_, _OpenMitigations_, _UserList_, _JobList_, _CapaEventList_ }
export enum EntityTypes { Undefined, Audit, AuditQuestion, AuditAnswer, Job, Customer, User, MitigationActivity, Shift,
ShiftManager, Site, Location, OrgLayer, Department, Scope, UserRole, Role, Rank, IssueClassification, CustomCategory,
CustomItem, DocumentRev }
export enum CustomFieldFieldTypes { None, Int, String, Json, Bool, Date, List, UserDefinedList }
export enum MitigationStatusTypes { Open, Closed, PendingApproval }

export interface IUserListViewPreferences {
    ID?: number;
    OrganizationID?: number;
    UserID?: number;
    ListViewName: string;
    Columns: any;
}

export interface IFileFormat {
    ID: number;
    Extension: string;
    MimeType: string;
}

export interface IIssueClassification {
    ID: number;
    FailureCategoryID: number;
    FailureModeID: number;

    CustomCategory: ICustomCategory;
    CustomItem: ICustomItem;
}

export interface IJob {
    ID?: number;
    OrganizationID?: number;
    Code?: string;
    Description?: string;
    SiteID?: number;
    Site?: ISite;
    CreateDate?: Date;
    CompanySiteID?: number;
    CompanySite?: ICompanySite;
    StartDate?: Date;
    ResponsiblePartyID?: number;
    ResponsiblePartyName?: string;
    JobTypeID?: number;
    JobType?: IJobType;
    Status?: JobStatus;
    StatusText?: string;
    EndDate?: Date;
}

export interface ISearchableJob {
    ID?: number;
    Code?: string;
    Description?: string;
    Customer?: string;
}

export interface IJobType {
    ID: number;
    Name: string;
    AutoScheduleAudit: boolean;
    DocumentID?: number;
    AuditStartOffset?: number;
    AuditDueOffset?: number;
}

export interface ILocation {
    ID: number;
    OrganizationID: number;
    LocationTypeID?: LocationTypes;
    SiteID?: number;
    Name: string;
    Description: string;
    Parent?: ILocation;
    Children?: ILocation[];
    TreeLeft?: number;
    TreeRight?: number;
    TreeDepth?: number;
    origName?: string;
    origDesc?: string;
    editing?: boolean;
    toggle?(): any;
    treeHandleSelected?: boolean;
    LocationTypeName?: string;
    Sequence?: number;
    ParentID?: number;
    IsCollapsed?: boolean;
    IsInScope?: boolean;
    TimeZoneID?: string;
    TimeZoneOffset?: number;
    ParentName?: string;
    IsSelected?: boolean;
}
export interface ILocationDoc {
    ID: number;
    OrganizationID: number;
    LocationTypeID?: LocationTypes;
    SiteID?: number;
    Name: string;
    Description: string;
    Parent?: ILocation;
    Children?: ILocation[];
    TreeLeft?: number;
    TreeRight?: number;
    TreeDepth?: number;
    origName?: string;
    origDesc?: string;
    editing?: boolean;
    toggle?(): any;
    treeHandleSelected?: boolean;
    LocationTypeName?: string;
    Sequence?: number;
    ParentID?: number;

    //documents view -> will hold the location schedule days for the selected document
    DocLocScheduleDays?: IScheduleDateItem[];

    //Documents found for the selected location
    LocDocs?: IDocumentRevDocLocs[];
}
export interface IMitigationActivity {
    ID?: number;
    OrganizationID?: number;
    SiteID?: number;
    Sequence?: number;
    ResponsiblePartyUserID?: number;
    ResponsiblePartyUser?: IUser;
    Status?: EaseMitigationStatus;
    AuditID?: number;
    Audit?: IAudit;
    Comment?: string;
    AuditAnswerID?: number;
    AuditAnswer?: IAuditAnswer;
    DateOpened?: Date;
    DateClosed?: Date;
    PastDue?: boolean;
    NonConformanceData?: IAuditAnswerNonConformance[];
    RankID?: number;
    Rank?: IRank;
    ApprovingManagerUserID?: number;
    CountermeasureCustomItemID?: number;
    CustomFieldValueID?: number;
    LocationID?: number;
    Location?: ILocation;
    Description?: string;
    CreatorID?: number;
    Name?: string;
    UserFullName?: string;
    ShiftName?: string;
    IssueClassificationID?: number;
    IssueClassification?: IIssueClassification;
    MitigationTypeID?: number;
    ApprovingPartyComment?: string;
    ResolutionPeriod?: Date;
    IsPartOfCapa: boolean;

    /* client use only */
    CommentFormatted?: string;
    ApprovingPartyCommentFormatted?: string;
    ResponsbilePartyFullName?: string;
    StatusString?: string;
    uploadedFiles?: any[];
    CountermeasureCustomItem?: ICustomItem;
    IsNotSynced: boolean;
    Images?: any[];
    Due?: Date;
}

// IMitigation interface witha additional fields used by the client
export interface IClientMitigationActivity extends IMitigationActivity {

    ResponsbilePartyFullName: string;
    StatusString: string;

    ProfilePicture: string;
    ProfilePictureID: number;
    DaysOpen: number;
    ResolutionPeriod: Date;

    // PastDue: number;
    QuestionText: string;
    MitigationLocationID: number;
    MitigationLocationName: string;
    MitigationFailureCatMode: string;
    QuestionComment: string;

    uploadedFiles?: any[];

    // for filter / sort use
    MitigationDepartment?: string;
    DepartmentID?: number;
    MitigationLocationSiteName?: string;
    MitigationJobCode?: string;
    MitigationCustomer?: string;
    CountermeasureCustomItem?: ICustomItem;
    CountermeasureCustomItemName?: string;

    /* client use only*/
    MitigationName?: string;
    MitigationLayerName?: string;
    UserFullName?: string;
    TemplateTypeName?: string;
    uploader?: any;
    IsSelected?: boolean;
    QuestionTitle?: string;
}

export interface IMitigationDetail {
    Mitigation: IMitigationActivity;
    Locations: ILocation[];
    FailureCategoriesCustomCategoryLineItems: ICustomCategoryLineItem[];
    CountermeasuresCustomCategoryLineItems: ICustomCategoryLineItem[];
    AuditQuestionCategory: IAuditSubtype[];
    MitigationCreator: IUser;
    Ranks: IRank[];
    Managers: IUser[];
    AuditDetail: IAuditDetail;
    MitigationArtifacts: IMitigationArtifact[];
    Artifacts: IArtifact[];
    AcceptedFileFormats: IFileFormat[];
}

export interface IMitigationArtifact {
    ID: number;
    MitigationID: number;
    ArtifactID: number;
    Url: string;
    FileFormatID: number;
    Filename: string;
}

export interface IOrgArtifact {
    ID: number;
    ArtifactID?: number;
    OrganizationID?: number;
    Url: string;
    Artifact?: any;
}

export interface IOrgLayer {
    ID: number;
    OrganizationID?: number;
    Number?: number;
    Selected?: boolean;
    OrgLayerSiteDetailID?: number;
    OrgLayerSiteDetail?: IOrgLayerSiteDetail;
}
export interface IOrgLayerSiteDetail {
    ID: number;
    OrganizationID?: number;
    SiteID?: number;
    OrgLayerID?: number;
    Name: string;
    Description?: string;
    CalendarSeries?: ICalendarSeries;
    CalendarSeriesID?: number;
    AuditStartOffset?: number;
    GracePeriodDays?: number;
    MaxAuditsPerDay?: number;
    CanBeDeleted?: boolean;
}

export interface IPartnerLink {
    ID?: number;
    RequestOrganizationID?: number;
    CompanySiteID?: number;
    PartnerOrganizationID?: number;
    TargetOrganizationID?: number;
    TargetSiteID?: number;
    RequestedBy?: number;
    RequestedOn?: Date;
    ReviewedBy?: number;
    ReviewedOn?: Date;
    RequestStatus?: any;
    RequestStatusLabel?: string;
    TargetSiteChanged?: boolean;

    RequestOrganizationName?: string;
    TargetSiteName?: string;
    ReviewedByName?: string;
}

export interface IRandomQuestionConfig {
  ID: number;
  OrganizationID: number;
  NumQuestions: number;
  TagIDs: number[];
  RandomQuestionTags: IRandomQuestionTag[];
}

export interface IRandomQuestionTag {
  OrganizationID: number;
  RandomQuestionConfigID: number;
  TagID: number;
  Tag: ITag;
}

export interface IRank {
    ID?: number;
    OrganizationID?: number;
    Name: string;
    IsNotificationEnabled: boolean;
    ResolutionPeriod: number;
    IsAdditionalApprovalEnabled: boolean;
    Description: string;
    IsDefault: boolean;

    expanded: boolean;
    isNew: boolean;
    forDeletion?: boolean;
}

export interface IRole {
    ID?: number;
    SiteID?: number;
    Name: string;
    Description: string;
    Assigned: boolean;
    CanAdd: boolean;
    CanDelete?: boolean;
    UserRoleID?: number;
    RoleID?: number;
    ManagerDetail: number[];
    RoleTypeID: number;
    HasChanges: boolean;
}
export interface IScheduleDateItem {
    ID?: number;
    Date: number;
    DateObj: Date;
    EpochDate: number; // days since Epoch used to easily compare dates
    DayOfWeek: string;

    IsInactive?: boolean;
    IsCovered?: boolean;
    IsEndOfWeek?: boolean;
    IsDaysPassed?: boolean;

    AssignedToList?: string;
    EventInfo?: IScheduleEvent;
    CssClass?: string;
    SiteOffset?: number;
}

export interface IScheduleEvent {
    // For now, we assume events are audit events if though (in the future) they can be other types of events
    Count: number;
    IDs?: number[];
    Status: number;
    OverallStatus?: number;
    Description?: string;
    Descriptions?: string[];
    AssignedTo?: any;
    LocationIDs?: number[];
    AuditorsAssigned?: string[];
    AuditID?: number; //we need to keep the AuditID to be able to tie validity period events to their parent event
}

export interface IScope {
    ID: number;
    OrganizationID?: number;
    Name: string;
    Locations: any[];
    SiteID?: number;
    IsOrgScope: boolean;
    IsSiteScope: boolean;
    IsReadOnly: boolean;
    CanBeDeleted?: boolean;
}

export interface IShift {
    ID?: number;
    OrganizationID?: number;
    SiteID?: number;
    Sequence?: number;
    Name: string;
    Description?: string;
    CanBeDeleted?: boolean;
}

export interface ISite {
    ID?: number;
    OrganizationID?: number;
    Name?: string;
    Description?: string;
    Code?: string;
    Enabled?: boolean;
    UseShifts?: boolean;
    TimeZoneID?: string;
    TimeZoneOffset?: number;
    TimeZoneAbbreviation?: string;
    CreationDate?: Date;

    HasDST?: boolean;
    DSTStart?: Date;
    DSTEnd?: Date;
    DSTStartOffset?: number;
    DSTEndOffset?: number;
    SiteSettings?: any;
}

export interface ITag {
    ID?: number;
    OrganizationID?: number;
    IsSystemTag?: boolean;
    Label: string;
    AuditSubtypeID?: number;
}

export interface IUser extends ISelectedtable {
    ID: number;
    OrganizationID: number;
    SiteID?: number;
    Site?: ISite;
    DefaultSite?: ISite;
    ScopeID: number;
    DepartmentID: number;
    Department?: IDepartment;
    OrgLayerID?: number;
    OrgLayer?: IOrgLayer;
    Login: string;
    FirstName: string;
    LastName: string;
    IsLocked: boolean;
    IsSecurityLocked: boolean;
    IsValidated: boolean;
    Email: string;
    UserRoles?: any[];
    Scope?: IScope;
    Shift?: IShift;
    ShiftID: number;

    UserScopes?: IUserScope[];
    PasswordExpiryDate?: Date;
    Language?: string;

    ScheduleData?: any[];
    FullName?: string;
    Roles?: IRole[];
    IsSysAdmin?: boolean;

    OrgLayerSiteDetail?: IOrgLayerSiteDetail;
    OrgLayerName?: string;
    StatusName?: string;
    ShiftName?: string;
    ScopeName?: string;
    AdminScopeID?: number;
}

export interface IUserRole {
  ID: number;
  UserID: number;
  RoleID: number;
  Role: IRole;
  ManagerDetail: any;
}

export interface IClientUser extends IUser {
    FullName?: string;
}

export interface IUserList extends ISelectedtable {
    ID: number;
    OrganizationID: number;
    SiteID: number;
    FirstName: string;
    LastName: string;
    FullName: string;
    Email: string;
    Department: string;
    Layer: string;
    Shift: string;
    UserRoles: string;
    Scope: string;
    Status: string;
    SiteName: string;

    UserRolesArray: string[];
    ScheduleData?: any[];
}

export interface IUserActivity {
    //general properties
    ID: number;
    Type: number;
    TranslatedType: string;
    Name: string;
    Due: Date;
    Status: number;
    Location: string;
    TranslatedStatus: string;
    CanConduct?: boolean; //this is set to true for mitigations in order to be displayed in the view
    DateUpdated?: Date;

    //audit activity only properties
    PastDueDays?: number;
    IsRedirectAuditEntry?: boolean;
    DataEntry?: boolean;

    // mitigation only properties
    AuditID?: number;

    //properties only for CAPA Task
    IsPartOfCapa?: boolean;
    CapaEventID?: number;
    CapaTaskID?: number;

    PendingUpload?: boolean;
}

export interface IUserScope {
        ID: number;
        OrganizationID: number;
        TreeLeft: number;
        TreeRight: number;
        Email: string;
        UserID: string;
}

export interface IAuditScheduleResponse {
    CalendarSeriesID?: number;
    AuditID?: number;
    ErrorCode?: number;
    ErrorDocumentLayerID?: number;
    ErrorOrgLayerID?: number;
    ErrorDocumentID?: number;
    ErrorLocationNames?: string[];
    ErrorLocationHolidays?: string[];
}

export interface IEaseSubscription {
    ID: number;
    OrganizationID: number;
    EaseProductID: number;
    PartnerSubscriptionCode: string;
    Status: number;
    LastUpdated: Date;
    IsExempt: boolean;
    IsDeleted: boolean;
    EaseProduct: IEaseProduct;
    StatusDescription: string;
}

export interface IEaseSubscriptionLineItem {
    ID: number;
    OrganizationID: number;
    EaseSubscriptionID: number;
    EaseProductLineItemID: number;
    CurrentCount: number;
    SiteID?: number;
    OverridingMaxCount?: number;
}

export interface IEaseProduct {
    ID: number;
    Name: string;
    PartnerProductCode: string;
    PartnerPage: string;
}

export enum EaseProvisionableTypes { None, Users, Sites, AuditTypes, PublishedDocs }

declare global {
    interface Date {
        addDays(offset: number): Date;
        addMonths(offset: number): Date;
        addYears(offset: number): Date;
        addSeconds(offset: number): Date;
        epochDate(): number;
        toShortDateString(): string;
        daysInMonth(): number;
    }
}

Date.prototype.toShortDateString = function(): string {

        let yyyy = this.getFullYear().toString();
        let mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
        let dd = this.getDate().toString();
        return yyyy + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + (dd[1] ? dd : "0" + dd[0]); // padding
    };

Date.prototype.addSeconds = function(offset): Date {
    let dat = new Date(this.valueOf());
    dat.setSeconds(dat.getSeconds() + offset);
    return dat;
};

Date.prototype.addDays = function(offset): Date {
    let dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + offset);
    return dat;
};

Date.prototype.addMonths = function(offset): Date {
    let dat = new Date(this.valueOf());
    dat.setMonth(dat.getMonth() + offset);
    return dat;
};

Date.prototype.addYears = function(offset): Date {
    let dat = new Date(this.valueOf());
    dat.setFullYear(dat.getFullYear() + offset);
    return dat;
};

Date.prototype.epochDate = function(): number {

    let d = new Date(this.valueOf());
    let t = d.getTime();
    // We need to consider the time zone offset because getTime() resturns the UTC time
    t = t - (d.getTimezoneOffset() * 60000);
    return Math.floor(t / 86400000);
};

Date.prototype.daysInMonth = function(): number {
    let dat = new Date(this.valueOf());
    let year = dat.getFullYear();
    let month = dat.getMonth() + 1;
    return new Date(year, month, 0).getDate();
};

export interface ICalendar {
    ID: number;
    CalendarType: number;
    Name: string;
    OrganizationID?: number;
    SiteID?: number;
    LocationID: number;
    DepartmentID: number;
    UserID: number;
    ShiftID?: number;
}

export interface CalEvent {
    id?: number;
    title?: string;
    start?: Date;
    end?: Date;
    overlap?: boolean;
    rendering?: string;
    color?: string;
    stick?: boolean;
    showPanel?: boolean;
    easeCalEvent?: ICalendarEvent;
    isTimeOff?: boolean;
    epochDate?: number;
    isAudit?: boolean;
}

export interface CalSchedule {
    id?: number;
    name?: string;
    start?: any;  // can be string or date
    end?: any;
    showPanel?: boolean;
    easeSchedule?: ICalendarWorkSchedule;
}

export interface ICalendarWorkSchedule {
    ID: number;
    CalendarID: number;
    OrganizationID: number;
    Name: string;
    Start: Date;
    End: Date;
    WorkingDays: number[];
    DaysInCycle: number;
    StartSiteLocalTime?: Date;
}

export interface Workday {
    index: number;
    name: string;
    status: boolean;
}

export interface ICalendarEvent {
    ID?: number;
    OrganizationID?: number;
    CalendarSeriesID?: number;
    CalendarID?: number;
    EventType?: CalendarEventType;
    EventDetails?: number;
    Title?: string;
    Description?: string;
    AllDay?: boolean;
    Start?: Date;
    Due?: Date;
    CalendarSeries?: ICalendarSeries;
    UserID?: number;
    inheritedHoliday?: boolean;
}

export interface IAuditType {
    ID?: number;
    Name?: string;
    IsEnabled?: boolean;
    OrganizationID?: number;
    IsLPA?: boolean;
    IsReadOnly?: boolean;
    BaseAuditSubtypeID?: number;
    IsSupplierTemplate?: boolean;

    StatusString?: string;
    AuditConfigurationID?: number;
    IsScheduleByLayer?: boolean;
    AuditTargetType?: IAuditTargetType[];
}

export interface IAuditReport {
    ID: number;
    Name: string;
    Due: Date;
    Completed: Date;
    Auditor: string;
    Status: AuditStatus;
    IsPassed: boolean;
    Score: number;
    HasNonconformance: boolean;
    Location: string;
    Department: string;
    Layer: string;
    Shift: string;
    Site: string;
    AuditorSite: string;
    TimeZoneID: string;
    JobCode: string;
    Customer: string;
    GracePeriodDays: number;
    PastDueDays: number;
    ShiftID: number;
    TemplateType: string;
    TreeDepth: number;
    TreeLeft: number;
    TreeRight: number;
    TranslatedStatus: string;
    Result: string;
    ParentName: string;

    JobID: number;
    CustomerID: number;
    AuditorID: number;
    TemplateTypeID: number;
    DepartmentID: number;
    LayerID: number;
    SiteID: number;
    OrganizationID: number;
    EventStartDate?: Date;
    IsAdHoc: number;
    DateUpdated: Date;
    ChangeToken: string;

    IsSelected?: boolean;
}

export enum AuditStatus {
  NotStarted,
  Complete,
  Incomplete,
  Missed,
  CompletedLate,
  PastDue,
}

export interface ISiteSettings {
    ID: number;
    OrganizationID: number;
    SiteID: number;
    Name: string;
    FieldTypeID: number;
    Value: string;
    IsInternal: boolean;
}

export interface IAuditTargetType {
    ID?: number;
    AuditConfigurationID?: number;
    Value: number;
    OrganizationID?: number;
    UserLayers?: boolean;
}
export enum EaseDashboardModes { None, Week, Month }

export interface IEaseDashboardDimensionFilters {
    departmentIDs?: number[];
    layerIDs?: number[];
    shiftIDs?: number[];
    selectedCategory: IDocumentSubTypes;
}

export interface IEaseDashboardFilters {

    startDate?: string;  // Internal representation: MM/DD/YYYY
    endDate?: string;  // Internal representation: MM/DD/YYYY

    // Dimension Filters
    // Failure Category Filters

    mode?: EaseDashboardModes;
    dimensions: IEaseDashboardDimensionFilters;
    failureCatIDs: number[];
    locationIDs: number[];
    selectedCategory: IDocumentSubTypes;
    currentAuditConfig?: IAuditConfig;
}

export interface IAuditCategory {
    ID?: number;
    Name?: string;
    IsEnabled?: boolean;
    OrganizationID?: number;
    IsLPA?: boolean;
    IsReadOnly?: boolean;
    BaseAuditSubtypeID?: number;
    IsSupplierTemplate?: boolean;

    StatusString?: string;
    AuditConfigurationID?: number;
    IsScheduleByLayer?: boolean;
    AuditTargetType?: IAuditTargetType[];
}

export interface IReportPanel {
    ID: number;
    Name: string;
    Summary: string;
    IsDeleted: boolean;
}

export interface IReportPanelLayoutItem extends ISelectedtable {
    ID?: number;
    AuditSubtypeID?: number;
    RowNumber?: number;
    ColumnNumber?: number;
    SizeX?: number;
    SizeY?: number;
    RoleTypeID?: number;
    ReportPanelID?: number;
    IsEnabled: boolean;
    ReportPanel: IReportPanel;
    UseLayers?: boolean;
}

export interface IGridsterReportPanelLayoutItem extends IReportPanelLayoutItem {
    sizeX: number;
    sizeY: number;
    row: number;
    col: number;
    Guid?: string;
}

export interface IGridsterReportPanelLayoutItem2 extends IReportPanelLayoutItem {
    cols: number;
    rows: number;
    y: number;
    x: number;
    Guid?: string;
}

export enum EaseAuditChartMetricType { None, Total, TotalNonConf, PctComplete, AvgScore, Pareto, Pie, Spider, Bar, ByDay, ByMonth, Average, Scatter, AuditsCompleted, AllActivity, All, Open, Closed, Late, OnTime, Count, Percentage }
export enum EaseAuditChartDimensionType { None, Layer, Department, Shift, Location, Status, Urgency, ResponsibleParty }
export enum EaseAuditChartGroupingType { None, Grouped, Stacked }
export enum EaseAuditChartStackByType { Status, Result, None }
export enum EaseAuditChartDateRangeOption { ToDate, EndOfPeriod }
export enum DocumentApprovalStatus { None, Pending, Approved, Rejected, PreviouslyApproved, PreviouslyRejected }

export interface IReportPanelSettings {

    metricType?: EaseAuditChartMetricType;
    selectedCategoryGroupID?: number;
    dimensionType?: EaseAuditChartDimensionType;
    groupingType?: EaseAuditChartGroupingType;
    stackBy?: EaseAuditChartStackByType;
    dateRange?: EaseAuditChartDateRangeOption;

    enabledMetricTypes?: number[];
    enableSeperationOption?: boolean;
    enableGroupingOption?: boolean;
    enableCategoryGroup?: boolean;
    enableStackByStatusOption?: boolean;
    enableStackByOption?: boolean;
    isLPACategory?: boolean;
    customGroups?: ICustomGroup[];
    showAuditListButton?: boolean;
    showDocumentDropdown?: boolean;
    documentDropdownItems?: IDocumentRev[];

    showDataLabels?: boolean;
    enableDataLabels?: boolean;

    enableDateRangeOption?: boolean;
    showGearButton?: boolean;
    showStackByStatusCheckbox?: boolean;
    enableGroupByOption?: boolean;
}

export interface ISelectedtable {

    IsSelected?: boolean;
    IsComplete?: boolean;
}

export interface ICustomGroup extends ISelectedtable {
    ID?: number;
    OrganizationID?: number;
    Name: string;
    IsSystemGroup?: boolean;
    SystemGroupType?: number;
}

export interface IUserSetting {
    ID: number;
    UserID?: number;
    Name: string;
    FieldTypeID: number;
    Value: string;
}

export enum ChartDimensionType { None, Layer, Department, Shift }
export enum ChartMetricType { None, TotalAudits, TotalNonConf, PercentComplete }

export interface IHighChartSeries {
    name: string;
    stack: string;
    data: IHighChartDataItem[];
    color: string;
}

interface IHighChartDataItem {
    y: number;
    category: string;
    id: number;
    color: string;
    name: string;
    ids?: number[];
}

export interface ILabelItem {
    ID: number;
    Name: string;
}

export interface IHighChartResult {
    ChartData: IHighChartSeries[];
    CategoryAxis: string[];
    SecondaryCategoryAxis: string[];
    CategoryAxisLabels: string[];
    Categories: string[];
    SecondaryCategories: string[];
    LocationLevel: number;
    IsLastLevel: boolean;
    Title: string;
    MaxValue?: number;
}

export enum DocumentTypes { Audit = 1, CAPA = 2 }

export interface IDocumentSubTypes {
    ID?: number;
    Name: string;
    Description: string;
    IsEnabled: boolean;
    DocumentType?: DocumentTypes;
    AuditTargetType: IAuditTargetType[];
}

// CAPA enums and interfaces
export enum CapaStatusTypes { InProgressOnTime, InProgressPastDue, ClosedOnTime, ClosedPastDue, PendingApproval }
export enum CapaEventApprovalStatuses { Open, Approved, Rejected }
export enum CapaEventValidationStatus { None, Pending, Complete, PastDue }

export interface ICapaEventLineItem {
    ID: number;
    OrganizationID: number;
    CapaTaskID: number;
    SequenceNum: number;
    CapaEventID: number;
    MitigationActivityID: number;
    IsLocked: boolean;
    CorrectiveActionID?: number;
    CapaTask: ICapaTask;
}

export interface IClientCapaEventLineItem extends ICapaEventLineItem {
    CanEditTask: boolean;
}

export interface ICapaTask {
    ID?: number;
    OrganizationID?: number;
    Title?: string;
    Description?: string;
    DueDate?: any;
    DueDateSiteLocalTime?: Date;
    IsAttachmentRequired?: boolean;
    IsApprovalRequired?: boolean;
    EnableNotifyNextTaskOwner?: boolean;
    EnableNotifyCapaOwner?: boolean;
    MitigationActivityID?: number;
    MitigationActivity?: IMitigationActivity;
    IsValidation?: boolean;
    DaysDueAfterOpened?: number | null;
}

export interface ICapaEvent {
    ID: number;
    OrganizationID: number;
    DueDate?: any;
    DueDateSiteLocalTime?: Date;
    Name: string;
    PastDueDays: number;
    GracePeriodDays: number;
    CapaStatus: number;
    ValidationStatus: number;
    DateOpened?: Date;
    SeverityCustomItemID?: number;
    EscapePointCustomItemID?: number;
    PotentialImpactCustomItemID?: number;
    SiteID: number;
    Site?: ISite;
    SiteName?: string;
    CreatedByUserID: number;
    Summary: string;
    DocumentRevID?: number;
    DocumentID?: number;
    CapaSettingID: number;
    IsDeleted: boolean;
    DeletedBy?: number;
    DateDeleted?: Date;

    CapaEventName?: string;
    CapaEventOwnerIDs?: number[];
    CapaEventApproverIDs?: number[];
    CapaEventCreator?: IUser;
    CapaEventApprovers?: ICapaEventApprover[];
    CapaEventOwners?: ICapaEventOwner[];
    CapaEventAssociations?: ICapaEventAssociation[];
    CapaEventAssociationSetups?: any[];
    CustomFields?: ICustomField[];

}

export interface IClientCapaEvent extends ICapaEvent {
    uploader?: any;
    images?: any[];
    imagesToRemove?: any;
}

export interface ICapaEventAssociation {
    ID?: number;
    OrganizationID?: number;
    CapaEventID?: number;
    CapaEventAssociationType?: number; // Ease.CapaEventAssociationType;
    IsRequired?: boolean;
    Name?: string;
    AuditID?: number;
    CompanySiteID?: number;
    LocationID?: number;

    CapaEvent?: ICapaEvent;
    Audit?: IAudit;
    CompanySite?: ICompanySite;
    Location?: ILocation;
}

export interface IEaseArtifact {
    isImage(fileFormatID: number);
    isDoc(fileFormatID: number);
    isAllowedFileType(file: any);
    getImageUrl(file);
}

export interface ICapaEventOwner {
    ID: number;
    OrganizationID: number;
    CapaEventID: number;
    UserID: number;
    User?: IUser;
    ReviewStatus: number;
    IsDeleted?: boolean;
    DeletedBy?: number;
    DateDeleted?: Date;
}

export interface ICapaEventApprover {
    ID: number;
    OrganizationID: number;
    CapaEventID: number;
    UserID: number;
    User?: IUser;
    ApprovalStatus: number;
    Comment?: string;
    IsDeleted?: boolean;
    DeletedBy?: number;
    DateDeleted?: Date;
}

export enum DocumentStatus {
    DRAFT = 1,
    SUBMITTED = 2,
    INREVIEW = 3,
    REJECTED = 4,
    APPROVED = 5,
    ACTIVE = 6,
    INACTIVE = 7,
}

export interface IFrequencyOrgLayer extends IOrgLayer {
    LayerFrequencyMessage?: string;
}

export interface IDocumentLayer {
    ID: number;
    OrganizationID: number;
    DocumentID: number;
    OrgLayerID: number;
    SchedulingMaximum?: number;
}

export interface IDocumentTeamMemberApproval {
  OrganizationID?: number;
  ID?: number;
  DocumentTeamMemberID?: number;
  DocumentRevID?: number;
  DocumentID?: number;
  Status?: DocumentApprovalStatus;
  Comment?: string;
  LastUpdate?: Date;
  DocumentTeamMember?: IDocumentTeamMember;
}
export interface IUserArtifact {
  ID?: number;
  UserID: number;
  ArtifactID: number;
  OrganizationID?: number;
  Url: string;
}

export interface IDocumentTeamMember {
  OrganizationID?: number;
  ID?: number;
  DocumentTeamID?: number;
  UserID?: number;
  User?: IUser;
  CanApprove?: boolean;
  CanModify?: boolean;
  Approval?: IDocumentTeamMemberApproval;
  DocumentRevID?: number;
}
export interface IDocRevSelection {
  DocumentID?: number;
  LocationID?: number;
  LocationTiers?: number[];
  Description?: string;
}
export interface IAuditSchedule {
  ID?: number;
  OrganizationID?: number;
  DocumentID?: number; // TQP Remove?
  CalendarSeriesID: number;
  CalendarSeries?: ICalendarSeries;
  DueDate?: Date;
  AuditorUserID?: number;
  Description: string;
  LocationID?: number; // TQP Remove?
  SiteID?: number;
  JobID: number;
  GracePeriodDays?: number;
  SelectedUserIDs?: number[];
  SelectedDocRevs?: IDocRevSelection[];
  AuditCategory?: number;
  IsDailyAudit?: boolean;
  DepartmentID?: number;
  Job?: IJob;
  IsAutoAssignAuditorEnabled?: boolean;
  SelectedCustomerID?: number;
}

export enum EaseCommentResponseOption { Optional, RequiredOnFail, AlwaysRequired }

export interface DocumentSettings {
  DocumentRevision: IDocumentRev;
  Scope: IScope;
}
