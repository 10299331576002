import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

// Modules
import { SharedModule } from "@shared/shared.module";
import { AccountModule } from "@app/account/account.module";
import { ResultsModule } from "@app/results/results-module";
import { ConductModule } from "@app/conduct/conduct.module";
import { CapaModule } from "@app/capa/capa.module";

// Services
import { AuthGuard } from "app/auth/services/auth-guard.service";
import { HomeService } from "./services";

// Components
import { HomeComponent } from "./containers";
import { AuditConductComponent } from "@app/conduct/conduct-audit/audit-conduct.component";
import { MitigationDetailComponent } from "@app/shared/results/mitigation-detail/mitigation-detail.component";
import { AdHocAuditComponent } from "@app/conduct/conduct-ad-hoc/ad-hoc-audit.component";
import { WorkScheduleCalendarComponent } from "@app/account/calendar/work-schedule-calendar.component";
import { AuditDetailComponent } from "@app/shared/results/audit-detail/audit-detail.component";
import { AuditResultComponent } from "@app/shared/results/audit-result/audit-result.component";
import { AuditCompleteComponent } from "@app/conduct/conduct-audit/audit-complete.component";
import { AuditHistoryComponent } from "@shared/audit-history/audit-history.component";
import { AuditHistoryListComponent } from "@app/account/auditHistory/auditHistory.component";
import { AuditPrintDetailComponent } from "@app/results/audit-print/audit-print-detail.component";
import { AuditPrintComponent } from "@app/conduct/conduct-audit-print/audit-print.component";
import { AuditReferencesComponent } from "@app/conduct/conduct-audit-references/audit-references.component";

import { CapaHistoryComponent } from "@app/capa/containers/capa-history/capa-history.component";
import { CapaEventDetailsComponent } from "@app/capa/containers/capa-event-details/capa-event-details.component";
import { CapaPrintComponent } from "@app/capa/containers/capa-print/capa-print.component";

import {
  BreadcrumbConductName,
  BreadcrumbAuditName,
  BreadcrumbUserCalendar,
  BreadcrumbMitigationName,
  BreadcrumbCapa } from "@core/components/breadcrumbs/breadcrumb.class";
import { ReportDataAccessService } from "@app/report/report-data-access.service";
import { RecentAuditsComponent } from "@app/home/components/recent-audits/recent-audits.component";
import { RecentMitigationsComponent } from "@app/home/components/recent-mitigations/recent-mitigations.component";
import { ChartModule } from "angular2-highcharts";
import { HighchartsStatic } from "angular2-highcharts/dist/HighchartsService";
import { MyActivitiesComponent } from "@app/home/components/my-activities/my-activities.component";

const CAPA_FLOW_ROUTES = {
  path: "corrective-action/:id",
  data: { stripType: "capa-event-details", breadcrumb: new BreadcrumbCapa() },
  children: [
    {
      path: "",
      pathMatch: "full",
      redirectTo: "event",
    },
    {
      path: "capa-history",
      component: CapaHistoryComponent,
      canActivate: [ AuthGuard ],
      data: { stripType: "capa-event-details", breadcrumb: new BreadcrumbCapa("capa"), mode: "capa" },
    },
    {
      path: "event",
      component: CapaEventDetailsComponent,
      data: { stripType: "capa-event-details", breadcrumb: new BreadcrumbCapa("capa"), mode: "capa" },
    },
    {
      path: "print",
      component: CapaPrintComponent,
      data: { stripType: "", breadcrumb: new BreadcrumbCapa("capa"), mode: "capa" },
    },
  ],
};

const CONDUCT_FLOW_ROUTES = [
  {
    path: "conduct/:id",
    canActivate: [ AuthGuard ],
    data: { stripType: "", breadcrumb: new BreadcrumbConductName() },
    children: [
      {
        path: "",
        component: AuditConductComponent,
        data: {
          stripType: "audit-conduct-management", breadcrumb: "",
        },
      },
      {
        path: "complete",
        component: AuditCompleteComponent,
        data: {
          stripType: "", breadcrumb: "_BreadcrumbComplete_",
        },
      },
      {
        path: "print",
        component: AuditPrintComponent,
        data: {
          stripType: "",
        },
      },
      {
        path: "references",
        component: AuditReferencesComponent,
        data: {
          stripType: "audit-conduct-management", breadcrumb: "",
        },
      },
      CAPA_FLOW_ROUTES,
    ],
  },
];

const AUDIT_FLOW_ROUTES = {
  path: "audit/:id",
  data: { stripType: "reports", breadcrumb: new BreadcrumbAuditName() },
  children: [
    {
      path: "",
      component: AuditResultComponent,
      data: { stripType: "audit-management", breadcrumb: "" },
    },
    {
      path: "details",
      component: AuditDetailComponent,
      data: { stripType: "audit-management", breadcrumb: "_BreadcrumbDetails_" },
    },
    {
      path: "history",
      component: AuditHistoryComponent,
      data: { stripType: "audit-management", breadcrumb: "_BreadcrumbHistory_" },
    },
    {
      path: "print",
      component: AuditPrintDetailComponent,
      data: { stripType: "" },
    },
  ],
};

export function highchartsFactory() {
  const hc = require("highcharts");
  const ex = require("highcharts/modules/exporting");
  ex(hc);

  return hc;
}

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: "home",
        canActivate: [ AuthGuard ],
        data: {
          stripType: "home", breadcrumb: "_BreadcrumbHome_",
        },
        children: [
          ...CONDUCT_FLOW_ROUTES,
          {
            path: "",
            component: HomeComponent,
            canActivate: [ AuthGuard ],
            data: {
              stripType: "home",
              breadcrumb: "",
              disableSiteSelector: true,
            },
          },
          {
            path: "mitigation/:id",
            component: MitigationDetailComponent,
            data: {
              stripType: "", breadcrumb: new BreadcrumbMitigationName(),
            },
          },
          AUDIT_FLOW_ROUTES,
          {
            path: "audit-create",
            data: {
              stripType: "home",
              breadcrumb: "_BreadcrumbAdHocActivity_",
              disableSiteSelector: false,
            },
            children: [
              {
                path: "",
                component: AdHocAuditComponent,
                data: {
                  stripType: "home", breadcrumb: "",
                },
              },
              ...CONDUCT_FLOW_ROUTES,
            ],
          },
          {
            path: "audit-enterstart",
            data: {
              stripType: "home", breadcrumb: "_BreadcrumbEnterAudit_",
            },
            children: [
              {
                path: "",
                component: AdHocAuditComponent,
                data: {
                  stripType: "home",
                  breadcrumb: "",
                  disableSiteSelector: false,
                },
              },
              ...CONDUCT_FLOW_ROUTES,
            ],
          },
          {
            path: "auditor-calendar/:id",
            canActivate: [ AuthGuard ],
            component: WorkScheduleCalendarComponent,
            data: {
              stripType: "home",
              breadcrumb: new BreadcrumbUserCalendar(),
              disableSiteSelector: true,
            },
          },
          {
            path: "audit/complete/:id",
            component: AuditCompleteComponent,
            canActivate: [ AuthGuard ],
            data: { stripType: "audit-complete", breadcrumb: "_BreadcrumbComplete_" },
          },
          {
            path: "audit-history",
            canActivate: [ AuthGuard ],
            data: {
              stripType: "home",
              breadcrumb: "_BreadcrumbHistory_",
              disableSiteSelector: false,
            },
            children: [
              {
                path: "",
                component: AuditHistoryListComponent,
                data: { stripType: "home", breadcrumb: "" },
              },
              ...CONDUCT_FLOW_ROUTES,
              AUDIT_FLOW_ROUTES,
            ],
          },
        ],
      },
    ]),
    AccountModule,
    ResultsModule,
    ConductModule,
    ChartModule,
    CapaModule,
  ],
  declarations: [
    HomeComponent,
    MyActivitiesComponent,
    RecentAuditsComponent,
    RecentMitigationsComponent,
  ],
  providers: [
    HomeService,
    ReportDataAccessService,
    {
      provide: HighchartsStatic,
      useFactory: highchartsFactory,
    },
  ],
})
export class HomeModule {
}
