import { Component } from "@angular/core";

// State
import { Store } from "@ngrx/store";
import * as AuthActions from "../actions/auth";
import * as fromAuth from "../reducers";

// Models
import { SetPassword } from "../models/user";

@Component({
  selector: "ease-change-password",
  template: `
    <ease-set-password (submitted)="onSubmitted($event)">
      <mat-card-title class="title">
        {{ "_Auth:ChangePassword:Title_" | i18n }}
      </mat-card-title>
      <span class="submit">
        {{ "_Auth:ChangePassword:Submit_" | i18n }}
      </span>
    </ease-set-password>
  `,
})
export class ChangePasswordComponent {
  constructor(private store: Store<fromAuth.State>) {
  }

  onSubmitted($event: SetPassword) {
    this.store.dispatch(new AuthActions.ChangePassword($event));
  }
}
