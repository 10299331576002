import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { IAuditTargetType, IAuditType } from "../../models/ease-models";
import { Observable,  ReplaySubject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AuditTypeService {
  constructor(private api: ApiService,
              private http: HttpClient) {
  }

  public getAuditTypes(includeDisabled: boolean = false): Observable<IAuditType[]> {
    return this.http.get<IAuditType[]>(`/AuditQuestionCategory?showAll=${includeDisabled}`);
  }

  public getAuditTypeByID(id: number): Observable<IAuditType> {
    let request = {showAll: true};
    let result = new ReplaySubject<any>(1);
    this.api.Get("AuditQuestionCategory", request, id, (data: any) => {
      result.next(data);
      result.complete();
    });
    return result.asObservable();
  }

  public addAuditType(auditType: IAuditType): Observable<number> {
    let result = new ReplaySubject<number>(1);
    this.api.Add("AuditQuestionCategory", auditType, (data: number) => {
      result.next(data);
      result.complete();
    });
    return result.asObservable();
  }

  public addAuditTargetType(auditTargetType: IAuditTargetType): Observable<number> {
    let result = new ReplaySubject<number>(1);
    this.api.Add("AuditTargetType", auditTargetType, (data: number) => {
      result.next(data);
      result.complete();
    });
    return result.asObservable();
  }

  public enableDisableTemplate(id: number, templateTypeDetail: IAuditType): Observable<boolean> {
    let result = new ReplaySubject<boolean>(1);
    this.api.UpdateX("AuditQuestionCategory", id, templateTypeDetail, {isEnableDisable: !templateTypeDetail.IsEnabled}, (data: boolean) => {
      result.next(data);
      result.complete();
    });
    return result.asObservable();
  }

  public saveAuditType(id: number, templateTypeDetail: IAuditType): Observable<boolean> {
    let result = new ReplaySubject<boolean>(1);
    this.api.Update("AuditQuestionCategory", id, templateTypeDetail, (data: boolean) => {
      result.next(data);
      result.complete();
    });
    return result.asObservable();
  }

  public deleteAuditTargetType(id: number, resetDocumentScopeToOrg: boolean, auditSubtype: number): void {
    this.api.DeleteX("AuditTargetType", id, {
      resetDocumentScopeToOrg: resetDocumentScopeToOrg,
      auditSubtypeID: auditSubtype
    }, (data: number) => {
    });
  }
}
