import { Observable } from "rxjs";
import { finalize,  tap } from "rxjs/operators";

import { Store } from "@ngrx/store";
import {
  StartProgress,
  EndProgress,
} from "../actions/layout";
import * as fromLayout from "../reducers/layout";

import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromLayout.State>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = req.clone({});

    return next.handle(modified)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
          } else {
            Promise.resolve().then(_ => this.store.dispatch(new StartProgress()));
          }
        }),
        finalize(() => {
          this.store.dispatch(new EndProgress());
        }),
      );
  }
}
