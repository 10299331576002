import { Component, EventEmitter, OnDestroy, OnInit, Output, Input, ChangeDetectionStrategy } from "@angular/core";
import { Subscription } from "rxjs";
import { isNullOrUndefined } from "util";

import { MatDialog } from "@angular/material";
import { DialogConfigMd } from "@shared/dialogs";
// Types
import { AuditFilters, AuditSelectedFilters } from "./audit-filters.interface";
import { IDocumentSubTypes, DocumentTypes, EaseDashboardModes } from "@core/models/ease-models";
import { LocationTreeModalInput } from "@shared/location-tree/location-tree.interfaces";
// Components
import { LocationTreeModalComponent } from "@shared/location-tree/location-tree-modal.component";
// Services
import { AuditFiltersService } from "./audit-filters.service";
import { LocalizeService, LocalService } from "@core/services";

@Component({
  templateUrl: "./audit-filters.component.html",
  styleUrls: ["./audit-filters.component.scss"],
  selector: "ease-audit-filters",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AuditFiltersService ],
})

export class AuditFiltersComponent implements OnInit, OnDestroy {

  @Input() public documentType: number = null;
  @Input() public activeSiteOnly: boolean = true;
  @Input() public localStorageKey: string;
  @Input() public alwaysHideUserFilter: boolean = false;
  @Input() public alwaysHideChartIntervalFilter: boolean = false;

  @Output() public filterChanged = new EventEmitter<AuditSelectedFilters>();
  @Output() public documentSubtypeChanged = new EventEmitter<IDocumentSubTypes>();
  @Output() public filtersReady = new EventEmitter<IDocumentSubTypes>();

  public showLocationFilter: boolean = false;
  public filterData: AuditFilters = null;
  public hasFilter: boolean = false;
  public selectedLocationsDescription: string = "";
  public selected: AuditSelectedFilters = {};

  public showUserFilter: boolean = true;
  public showLayerFilter: boolean = true;
  public showDepartmentFilter: boolean = true;
  public showShiftFilter: boolean = true;
  public showChartIntervalFilter: boolean = true;

  private isLoading: boolean = true;
  private subscriptions: Subscription[] = [];

  constructor(private dialog: MatDialog,
              private scheduleFilterService: AuditFiltersService,
              private localize: LocalizeService,
              private localStorage: LocalService) {

  }

  public filterChangedHandler(): void {

    this.evaluateFilters();
    this.filterChanged.emit(this.selected);
    if (!this.isLoading && this.localStorageKey) {
      this.localStorage.setItem(this.localStorageKey, this.selected);
    }
  }

  public documentSubtypeChangedHandler(): void {

    if (!this.isLoading && this.localStorageKey) {
      this.localStorage.setItem(this.localStorageKey, this.selected);
    }
    this.documentSubtypeChanged.emit(this.selected.DocumentSubtype);
    this.adjustDisplayedFilters();
  }

  public ngOnInit(): void {
    this.clearSelectedFilters();

    this.subscriptions.push(
      this.scheduleFilterService.getFilterData().subscribe((data: AuditFilters) => {
        this.filterData = data;
        if (this.documentType !== null) {
          this.filterData.DocumentSubtype = this.filterData.DocumentSubtype.filter(s => s.DocumentType === this.documentType);
        }

        if (this.filterData.DocumentSubtype.length > 0) {
          const clonedFilterData = Object.assign([], data.DocumentSubtype);
          let orderedByIDData = clonedFilterData.sort((l, b) => l.ID - b.ID);
          orderedByIDData = orderedByIDData.filter(item => item.ID !== 0); // filter the 0 ID (CAPA)
          this.selected.DocumentSubtype = (orderedByIDData && orderedByIDData.length) ? orderedByIDData[0] : this.filterData.DocumentSubtype[0];
        }
        this.restoreLocalStorage();
        this.isLoading = false;
        this.filtersReady.emit(this.selected.DocumentSubtype);
      }));
  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public openLocationModalHandler(): void {
    const config = new DialogConfigMd<LocationTreeModalInput>();
    config.data = {
      activeSite: this.activeSiteOnly,
      scoped: true,
      preventNormalization: false,
      selectedLocations: this.selected.LocationData,
    };

    const dialogRef = this.dialog.open(LocationTreeModalComponent, config);

    dialogRef.afterClosed().subscribe(locations => {
      if (!isNullOrUndefined(locations)) {
        const locationIds: number [] = [];
        locations.forEach(l => locationIds.push(l.ID));
        this.selected.Locations = locationIds;
        this.selected.LocationData = locations;
        this.describeSelectedLocation();
      }
    });
  }

  public toggleShowLocationFilter(): void {
    this.showLocationFilter = !this.showLocationFilter;
  }

  public clearSelectedFilters(): void {

    const type = this.selected.DocumentSubtype;
    this.selected = {
      Users: [],
      OrgLayers: [],
      Departments: [],
      Shifts: [],
      DocumentSubtype: type,
      Locations: [],
      ChartInterval: EaseDashboardModes.Month,
    };
    this.filterChangedHandler();
  }

  private restoreLocalStorage() {
    if (this.localStorageKey) {
      const local =  this.localStorage.get<AuditSelectedFilters>(this.localStorageKey);
      if (local) {
        this.selected = local;
        this.filterData.DocumentSubtype.forEach((s) => {
          if (s.ID === this.selected.DocumentSubtype.ID) {
            this.selected.DocumentSubtype = s;
          }
        });
        this.evaluateFilters();
        this.adjustDisplayedFilters();
      }
    }
  }

  private describeSelectedLocation() {

    this.selectedLocationsDescription = "";
    if (this.selected.Locations.length > 0) {
      this.selectedLocationsDescription = this.localize
        .getLocalizedString("_XSelected_", [this.selected.Locations.length.toString()]);
    }
  }
  private evaluateFilters(): void {

    this.hasFilter = (
      this.selected.Users.length > 0 ||
      this.selected.OrgLayers.length > 0 ||
      this.selected.Departments.length > 0 ||
      this.selected.Shifts.length > 0 ||
      this.selected.Locations.length > 0);

    this.describeSelectedLocation();
  }

  private adjustDisplayedFilters(): void {

    this.showLayerFilter = true;
    this.showUserFilter = true;
    this.showDepartmentFilter = true;
    this.showShiftFilter = true;
    this.showChartIntervalFilter = true;

    const subtype = this.selected.DocumentSubtype;

    if (subtype.DocumentType === DocumentTypes.Audit) {

      let showLayerFilter: boolean = false;
      if (subtype.AuditTargetType) {
        subtype.AuditTargetType.forEach((target) => {
          if (target.UserLayers) {
            showLayerFilter = true;
          }
        });
      }
      if (subtype.Name === "Layered Process Audit") {
        showLayerFilter = true;
      }
      this.showLayerFilter = showLayerFilter;

    } else if (subtype.DocumentType === DocumentTypes.CAPA) {
      this.showLayerFilter = false;
      this.showUserFilter = false;
      this.showDepartmentFilter = false;
      this.showShiftFilter = false;
      this.showChartIntervalFilter = false;
    }
  }
}
