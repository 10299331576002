import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "ease-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit, OnDestroy {

  linksArr = [];
  backLink = "";
  saveLink = true;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
  ) {

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationStart),
    )
      .takeUntil(this.destroy$).subscribe(_ => {
        this.pushLink();
      });
  }

  ngOnInit() {
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  pushLink() {
    if (this.saveLink) {
      const link = this.router.url;
      if (this.linksArr && this.linksArr.length === 20) {
        this.linksArr.splice(0, 1);
      }
      if (link !== "/login") {
        this.linksArr.push(link);
      } else {
        this.linksArr = [];
      }
    }
  }

  navigateBack() {
    this.saveLink = false;
    if (this.linksArr && this.linksArr.length > 0) {
      this.backLink = this.linksArr[this.linksArr.length - 1];
      this.router.navigate([this.backLink]).then(() => {
        this.linksArr.splice(-1, 1);
        this.saveLink = true;
      });
    }
  }
}
