import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Organization } from "../models/organization";
import {
  OrganizationsCollectionActionsUnion,
  OrganizationsCollectionActionTypes,
} from "../actions/collection.actions";

import {
  OrganizationActionsUnion,
  OrganizationActionTypes,
} from "../actions/organization.actions";

export interface State extends EntityState<Organization> {
  selectedOrganizationId: number | null;
}

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
  selectId: (organization: Organization) => organization.ID,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedOrganizationId: null,
});

export function reducer(
  state = initialState,
  action: (OrganizationActionsUnion | OrganizationsCollectionActionsUnion),
): State {
  switch (action.type) {
    case OrganizationsCollectionActionTypes.FetchSuccess: {
      return adapter.addMany(action.payload.Result, {
        ...state,
        selectedOrganizationId: state.selectedOrganizationId,
      });
    }

    case OrganizationActionTypes.Select: {
      return {
        ...state,
        selectedOrganizationId: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getSelectedId = (state: State) => state.selectedOrganizationId;
