import { Action } from "@ngrx/store";

// Models
import { ApiError } from "@core/models/api";
import { SetPasswordPayload } from "@app/auth/models/user";

import {
  PasswordChange,
  RequestPasswordPayload,
  AuthenticationPayload,
  AuthenticationResponse,
  AuthenticationSSOPayload,
} from "../models/user";

export enum AuthActionTypes {
  Login = "[Auth] Login",
  LoginSuccess = "[Auth API] Login Success",
  LoginFailure = "[Auth API] Login Failure",

  Impersonate = "[Auth] Impersonate",
  ImpersonateSuccess = "[Auth API] Impersonate Success",
  ImpersonateFailure = "[Auth API] Impersonate Failure",

  Logout = "[Auth] Logout",
  LogoutFromDropDown = "[Auth] Logout From DropDown",
  LogoutFromInterceptor = "[Auth] Logout From Interceptor",
  LogoutFromGuard = "[Auth] Logout From Guard",

  Authenticate = "[Auth] Authenticate",
  AuthenticateSuccess = "[Auth API] Authenticate Success",
  AuthenticateFailure = "[Auth API] Authenticate Failure",

  AuthenticateSSO = "[Auth] Authenticate SSO",
  AuthenticateSSOSuccess = "[Auth API] Authenticate SSO Success",
  AuthenticateSSOFailure = "[Auth API] Authenticate SSO Failure",

  Authorize = "[Auth] Authorize",
  AuthorizeSuccess = "[Auth API] Authorize Success",
  AuthorizeFailure = "[Auth API] Authorize Failure",

  RefreshToken = "[Auth] Refresh Token",
  RefreshTokenSuccess = "[Auth API] Refresh Token Success",
  RefreshTokenFailure = "[Auth API] Refresh Token Failure",

  RequestRestore = "[Auth] Request Restore",
  RequestRestoreSuccess = "[Auth API] Request Restore Success",
  RequestRestoreFailure = "[Auth API] Request Restore Failure",

  ChangePassword = "[Auth] Change Password",
  ChangePasswordSuccess = "[Auth API] Change Password Success",
  ChangePasswordFailure = "[Auth API] Change Password Failure",

  CreatePassword = "[Auth] Create Password",
  CreatePasswordSuccess = "[Auth API] Create Password Success",
  CreatePasswordFailure = "[Auth API] Create Password Failure",

  PasswordChanged = "[Auth Set Password Form] Password Change",

  LoginRedirect = "[Auth] Login Redirect",
  SSOLoginRedirect = "[Auth] SSO Login Redirect",
  AuthedRedirect = "[Auth] Authed Redirect",
  HomeRedirect = "[Auth] Home Redirect",
  HomeRedirectFromRolesGuard = "[Auth] Redirect to Home from Roles Guard",
  HomeRedirectAfterImpersonate = "[Auth] Redirect to Home after Impersonate",
  SysAdminRedirect = "[Auth] System Admin Redirect",
}

// Login
export class Login implements Action {
  readonly type = AuthActionTypes.Login;
}
export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public payload: any) {
  }
}
export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {
  }
}

// Login
export class Impersonate implements Action {
  readonly type = AuthActionTypes.Impersonate;

  constructor(public payload: number) {
  }
}
export class ImpersonateSuccess implements Action {
  readonly type = AuthActionTypes.ImpersonateSuccess;

  constructor(public payload: any) {
  }
}
export class ImpersonateFailure implements Action {
  readonly type = AuthActionTypes.ImpersonateFailure;

  constructor(public payload: any) {
  }
}

// Logout
export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;

  constructor(public payload: string) {
  }
}
export class LogoutFromDropDown implements Action {
  readonly type = AuthActionTypes.LogoutFromDropDown;

  constructor(public payload: string) {
  }
}
export class LogoutFromInterceptor implements Action {
  readonly type = AuthActionTypes.LogoutFromInterceptor;

  constructor(public payload: string) {
  }
}

export class LogoutFromGuard implements Action {
  readonly type = AuthActionTypes.LogoutFromGuard;

  constructor(public payload: string) {
  }
}

export class RequestRestore implements Action {
  readonly type = AuthActionTypes.RequestRestore;

  constructor(public payload: RequestPasswordPayload) {
  }
}

export class RequestRestoreSuccess implements Action {
  readonly type = AuthActionTypes.RequestRestoreSuccess;
}

export class RequestRestoreFailure implements Action {
  readonly type = AuthActionTypes.RequestRestoreFailure;

  constructor(public payload: string[]) {
  }
}

// Change Password
export class ChangePassword implements Action {
  readonly type = AuthActionTypes.ChangePassword;

  constructor(public payload: SetPasswordPayload) {
  }
}
export class ChangePasswordSuccess implements Action {
  readonly type = AuthActionTypes.ChangePasswordSuccess;
}
export class ChangePasswordFailure implements Action {
  readonly type = AuthActionTypes.ChangePasswordFailure;

  constructor(public payload: ApiError) {
  }
}

// Create Password
export class CreatePassword implements Action {
  readonly type = AuthActionTypes.CreatePassword;

  constructor(public payload: SetPasswordPayload) {
  }
}
export class CreatePasswordSuccess implements Action {
  readonly type = AuthActionTypes.CreatePasswordSuccess;
}
export class CreatePasswordFailure implements Action {
  readonly type = AuthActionTypes.CreatePasswordFailure;

  constructor(public payload: ApiError) {
  }
}

// Redirect
export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}
export class SSOLoginRedirect implements Action {
  readonly type = AuthActionTypes.SSOLoginRedirect;
}
export class AuthedRedirect implements Action {
  readonly type = AuthActionTypes.AuthedRedirect;
}
export class HomeRedirect implements Action {
  readonly type = AuthActionTypes.HomeRedirect;
}
export class SysAdminRedirect implements Action {
  readonly type = AuthActionTypes.SysAdminRedirect;
}
export class HomeRedirectFromRolesGuard implements Action {
  readonly type = AuthActionTypes.HomeRedirectFromRolesGuard;
}
export class HomeRedirectAfterImpersonate implements Action {
  readonly type = AuthActionTypes.HomeRedirectAfterImpersonate;
}

export class PasswordChanged implements Action {
  readonly type = AuthActionTypes.PasswordChanged;

  constructor(public payload: PasswordChange) {
  }
}

// Authenticate SSO
export class AuthenticateSSO implements Action {
  readonly type = AuthActionTypes.AuthenticateSSO;

  constructor(public payload: AuthenticationSSOPayload) {
  }
}
export class AuthenticateSSOSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticateSSOSuccess;

  constructor(public payload: any) {
  }
}
export class AuthenticateSSOFailure implements Action {
  readonly type = AuthActionTypes.AuthenticateSSOFailure;

  constructor(public payload: ApiError) {
  }
}

// Authentication is the process of verifying who you are.
// When you log on to a PC with a user name and password you are authenticating.
export class Authenticate implements Action {
  readonly type = AuthActionTypes.Authenticate;

  constructor(public payload: AuthenticationPayload) {
  }
}

export class AuthenticateSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticateSuccess;

  constructor(public payload: AuthenticationResponse) {
  }
}

export class AuthenticateFailure implements Action {
  readonly type = AuthActionTypes.AuthenticateFailure;

  constructor(public payload: ApiError) {
  }
}

// Authorization is the process of verifying that you have access to something.
// Gaining access to a resource (e.g. directory on a hard disk)
// because the permissions configured on it allow you access is authorization.
export class Authorize implements Action {
  readonly type = AuthActionTypes.Authorize;
}

export class AuthorizeSuccess implements Action {
  readonly type = AuthActionTypes.AuthorizeSuccess;

  constructor(public payload: any) {
  }
}

export class AuthorizeFailure implements Action {
  readonly type = AuthActionTypes.AuthorizeFailure;

  constructor(public payload: any) {
  }
}

// Refresh Token
export class RefreshToken implements Action {
  readonly type = AuthActionTypes.RefreshToken;

  constructor(public payload: number) {
  }
}

export class RefreshTokenSuccess implements Action {
  readonly type = AuthActionTypes.RefreshTokenSuccess;

  constructor(public payload: AuthenticationResponse) {
  }
}

export class RefreshTokenFailure implements Action {
  readonly type = AuthActionTypes.RefreshTokenFailure;

  constructor(public payload: any) {
  }
}

export type AuthActionsUnion =
  | Login
  | LoginSuccess
  | LoginFailure

  | Impersonate
  | ImpersonateSuccess
  | ImpersonateFailure

  | Logout
  | LogoutFromDropDown
  | LogoutFromInterceptor
  | LogoutFromGuard

  | Authenticate
  | AuthenticateSuccess
  | AuthenticateFailure

  | AuthenticateSSO
  | AuthenticateSSOSuccess
  | AuthenticateSSOFailure

  | Authorize
  | AuthorizeSuccess
  | AuthorizeFailure

  | RefreshToken
  | RefreshTokenSuccess
  | RefreshTokenFailure

  | RequestRestore
  | RequestRestoreSuccess
  | RequestRestoreFailure

  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailure

  | CreatePassword
  | CreatePasswordSuccess
  | CreatePasswordFailure

  | LoginRedirect
  | SSOLoginRedirect
  | AuthedRedirect
  | HomeRedirect
  | SysAdminRedirect
  | HomeRedirectFromRolesGuard
  | HomeRedirectAfterImpersonate

  | PasswordChanged;
