import { Inject, Injectable, NgZone } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import * as _ from "lodash";
import { BehaviorSubject,  Observable, ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EaseAuditStatus, IArtifact, IAudit, IAuditAnswerArtifact, IAuditDetail, CompletionStatus } from "../../models/ease-models";
import { ApiService } from "../api.service";
import { CurrentInfoService } from "../current.service";
import { IdentityService } from "../identity.service";
import { LocalService } from "../local.service";
import { NotifyService } from "../notify.service";
import { StorageKey, StorageService } from "../storage.service";
import { ToolsService } from "../tools.service";
import { ArtifactService } from "./artifact.service";
import { UiNotificationService } from "./ui-notification.service";
import { isNullOrUndefined } from "util";

@Injectable()
export class AuditDetailService {
    private auditDetails: BehaviorSubject<IAuditDetail[]> = new BehaviorSubject<IAuditDetail[]>([]);
    private isCordova: boolean;
    private _auditHasReferences: boolean;

    constructor(private api: ApiService,
                private notify: NotifyService,
                private tools: ToolsService,
                private local: LocalService,
                private storage: StorageService,
                private identity: IdentityService,
                private current: CurrentInfoService,
                private log: LoggerService,
                private zone: NgZone,
                private artifact: ArtifactService,
                private http: HttpClient,
                private router: Router,
                private uiNotify: UiNotificationService) {
        this.isCordova = this.tools.isCordova();
    }

    get auditHasReferences(): boolean {
      return this._auditHasReferences;
    }

    set auditHasReferences(value) {
      this._auditHasReferences = value;
    }

    public getAuditsOnline(callback: any) {
        this.api.Query("auditdetail", { p: "within-window", q: 0, prep: this.tools.isApp() }, (data: any) => {
            this.auditDetails = data;
            if (callback) { callback(this.auditDetails); }
        });
    }

    public getAuditByID(id: number, entityName: string, readFromServer: boolean = false, isResult: boolean = false, prep: boolean = true): Observable<any> {
        const key = this.storage.buildCurrentStorageKey(entityName, id);
        const inAuditMitigationKey = this.storage.buildCurrentStorageKey("InAuditMitigations", id);
        const answerArtifactKey = this.storage.buildCurrentStorageKey("AnswerArtifact", id);
        const result = new ReplaySubject<any>(1);

        if ((this.tools.isApp() && !readFromServer && !isResult) || (this.tools.isApp() && isResult))  {
            this.storage.containsObject(key).subscribe((isExists: boolean) => {
                if (isExists) {
                    this.storage.retrieveObject(key).subscribe((data: any) => {
                      this.auditHasReferences = data.DocumentArtifacts.length > 0;
                      this.storage.containsObject(inAuditMitigationKey).subscribe((fileExists: boolean) => {
                          if (fileExists) {
                              this.storage.retrieveObject(inAuditMitigationKey).subscribe((mitigation: any) => {
                                  this.storage.getKeyContent(answerArtifactKey).subscribe((uploadedFiles: any) => {
                                      result.next({ auditDetail: data, inAuditMitigation: mitigation, uploadedFileArray: uploadedFiles });
                                      result.complete();
                                  });
                              }, (err: any) => {
                                  this.log.error("retrieve mitigationKey error" + JSON.stringify(err));
                                  result.error(err);
                              });
                          } else {
                              this.storage.getKeyContent(answerArtifactKey).subscribe((uploadedFiles: any) => {
                                  result.next({ auditDetail: data, inAuditMitigation: [], uploadedFileArray: uploadedFiles });
                                  result.complete();
                              });
                          }
                      });
                    }, (err: any) => {
                        this.log.error("failed to retrieve object in getAuditByID" + JSON.stringify(err));
                        result.error(err);
                    });
                } else {
                    result.next({ auditDetail: [], inAuditMitigation: [], uploadedFileArray: [] });
                    result.complete();
                }
            });
        } else {
            this.getOnlineAuditByID(id, prep).subscribe((audit: IAuditDetail) => {
                if (audit) {
                    // retrieved saved auditdetail
                    const savedAuditDetail = audit;
                    this.auditHasReferences = audit.DocumentArtifacts.length > 0;
                    // retrieved saved inAuditMitigation
                    const inAuditMitigationKeyStr: string = this.storage.keyAsString(inAuditMitigationKey);
                    let mitigationJsonStr: string;
                    let inAuditMitigationData = [];
                    if (this.local.containsItem(inAuditMitigationKeyStr)) {
                        mitigationJsonStr = this.local.getItem(inAuditMitigationKeyStr);
                        if (mitigationJsonStr !== undefined && mitigationJsonStr !== null) {
                            inAuditMitigationData = JSON.parse(mitigationJsonStr);
                        }
                    }

                    // retrieve saved answer artifacts
                    const answerArtifactKeyStr: string = this.storage.keyAsString(answerArtifactKey);
                    let uploadedJsonStr: string;
                    let uploadedArray = [];
                    if (this.local.containsItem(answerArtifactKeyStr)) {
                        uploadedJsonStr = this.local.getItem(answerArtifactKeyStr);
                        if (uploadedJsonStr !== undefined) {
                            uploadedArray = JSON.parse(uploadedJsonStr);
                        }
                    }

                    result.next({ auditDetail: savedAuditDetail, inAuditMitigation: inAuditMitigationData, uploadedFileArray: uploadedArray });
                    result.complete();
                } else {
                    result.next({ auditDetail: null, inAuditMitigation: [], uploadedFileArray: [] });
                    result.complete();
                }
            }, (err: HttpErrorResponse) => {
              this.notify.broadcast("full-page-block", false);
              if (err.error.Code === "audit" && !isNullOrUndefined(err.error.Data)) {
                this.displayAuditException(err.error.Data);
              }
            });
        }

        return result.asObservable();
    }

    public getOnlineAuditByID(id: number, prep: boolean = true): Observable<IAuditDetail> {
      return this.http.get<IAuditDetail>(`/auditdetail?id=${id}&prep=${prep}`);
    }

    public getAudits(entityName: string): Observable<any[]> {

        const result = new ReplaySubject<any>(1);

        if (this.tools.isApp() && !this.tools.isOnline()) {
            this.log.debug("get audit list from file system");
            const key = this.storage.buildCurrentStorageKey("Audits", "AuditDetails");
            this.storage.retrieveObjectEntries(key).subscribe((data: any[]) => {
                const audits: Array<Observable<any>> = [];
                this.log.debug("getAudits from fileSystem data: " + data);
                const auditDetails: any[] = [];

                // retrieve audit list from json file
                this.storage.retrieveObject(key).subscribe((data: any) => {
                    this.log.debug("success retrieveObj: " + data);
                    result.next(data);
                    result.complete();
                }, (err: any) => {
                    this.log.error("failed to retrieve object retrieveObject" + JSON.stringify(err));
                    result.error(err);
                });

            }, (err: any) => {
                this.log.error("failed to retrieve object retrieveObjectEntries" + JSON.stringify(err) + " get audit detail from server instead");
                this.getAuditsOnline((data: IAuditDetail[]) => {
                    if (data && data.length > 0) {
                        result.next(JSON.stringify(data));
                        result.complete();
                    } else {
                        this.log.error("no audit details found");
                        result.next(data);
                        result.complete();
                    }
                });
            });

        } else {
            this.log.debug("get audit detail from server");
            this.getAuditsOnline((data: IAuditDetail[]) => {
                if (data && data.length > 0) {
                    result.next(data);
                    result.complete();
                } else {
                    this.log.debug("no audit details found");
                    result.next(data);
                    result.complete();
                }
            });
        }

        return result.asObservable();
    }

    public syncAudits(onlineAudits: IAuditDetail[]): Observable<any> {
        const auditorID = this.current.info.user.ID;
        const synced: boolean = false;
        const result = new ReplaySubject<any>(1);
        if (this.tools.isApp()) {
            const auditDetails: IAuditDetail[] = [];
            this.getAuditDetailsInFileSystem(onlineAudits).subscribe((contents: any[]) => {
                this.zone.run(() => {
                    const offlineSubmittedAuditIDs: number[] = [];
                    for (const content of contents) {
                        const offlineAuditDetail = content.auditDetail;
                        const savedInAuditMitigation = content.inAuditMitigation;

                        if (!isNullOrUndefined(offlineAuditDetail) && !isNullOrUndefined(offlineAuditDetail.Audit)) {
                            if (auditorID !== offlineAuditDetail.Audit.AuditorUserID || offlineAuditDetail.Audit.Description === "") {
                                if (auditorID !== offlineAuditDetail.Audit.AuditorUserID) {
                                    this.log.debug("Current AuditorID [auditorID in filesystem" + offlineAuditDetail.Audit.AuditorUserID + ", Logged On UserID:" + auditorID + "] is different and so do not sync");
                                } else {
                                    this.log.debug("Description is blank and so removed the audit {" + offlineAuditDetail.Audit.ID + "} from the offline IAudit");
                                }
                            }

                            for (const audit of onlineAudits) {
                                if (audit.Audit.ID === offlineAuditDetail.Audit.ID) {
                                    if (offlineAuditDetail.Audit.Status === EaseAuditStatus.Complete) { // don't we have to cover for completedLate????
                                        offlineSubmittedAuditIDs.push(offlineAuditDetail.Audit.ID);
                                    }

                                    if (audit.Audit.Status !== EaseAuditStatus.Complete && audit.Audit.Status !== EaseAuditStatus.CompletedLate && offlineAuditDetail.Audit.IsNotSynced) {
                                        this.log.debug("syncAudits: " + offlineAuditDetail.Audit.ID);
                                        audit.Audit.IsNotSynced = false;
                                        audit.Audit = offlineAuditDetail.Audit;
                                        audit.Audit.AuditQuestions = offlineAuditDetail.Audit.AuditQuestions;
                                        audit.Audit.PendingUpload = false;
                                        auditDetails.push(audit);
                                    }
                                    break;
                                }
                            }
                        }
                    }

                    if (auditDetails.length > 0 && this.tools.isOnline()) {
                      const snackBarRef = this.uiNotify.open("_SyncingInProgress_", true);
                      snackBarRef.afterOpened().subscribe(() => {
                        this.processAudits(auditDetails).subscribe((audits: IAudit[]) => {
                          let auditCount = 0;
                          let navigatorMsgDisplayed = false;
                          if (audits.length > 0) {
                              for (const audit of audits) {
                                  auditCount++;
                                  if (audit.Status === EaseAuditStatus.Complete) {
                                      this.deleteSavedAudit(audit.ID, [], () => {
                                          if (auditCount === audits.length) {
                                              if (!navigatorMsgDisplayed && this.tools.isApp()) {
                                                  navigatorMsgDisplayed = !navigatorMsgDisplayed;
                                                  // navigator.notification.alert("Audits successfully synced", () => {}, "Ease mobile synced", "Ok");
                                              }
                                              result.next({submittedAuditIDs: offlineSubmittedAuditIDs, isSync: true});
                                              result.complete();
                                          }
                                      });
                                  } else {
                                      if (auditCount === audits.length) {
                                          if (!navigatorMsgDisplayed && this.tools.isApp()) {
                                              navigatorMsgDisplayed = !navigatorMsgDisplayed;
                                              // navigator.notification.alert("Audits successfully synced", () => {}, "Ease mobile synced", "Ok");
                                          }
                                          result.next({submittedAuditIDs: offlineSubmittedAuditIDs, isSync: true});
                                          result.complete();
                                      }
                                  }
                              }
                          } else {
                              result.next({submittedAuditIDs: offlineSubmittedAuditIDs, isSync: false});
                              result.complete();
                          }
                        });
                      });
                    } else {
                        result.next({submittedAuditIDs: offlineSubmittedAuditIDs, isSync: false});
                        result.complete();
                    }
                });
            });
        } else {
            this.processOfflineAuditArray(onlineAudits).subscribe((auditDetails: IAuditDetail[]) => {
                if (auditDetails.length > 0 && this.tools.isOnline()) {
                    this.processAudits(auditDetails).subscribe((syncAudits: any[]) => {
                        if (syncAudits.length > 0) {
                            for (const audit of syncAudits) {
                                this.removeAuditFromLocalStorage(audit.ID);
                            }
                            result.next({submittedAuditIDs: [], isSync: true});
                            result.complete();
                        } else {
                            result.next({submittedAuditIDs: [], isSync: false});
                            result.complete();
                        }
                    });
                } else {
                    result.next({submittedAuditIDs: [], isSync: false});
                    result.complete();
                }
            });
        }

        return result.asObservable();
    }

    public getAnswerArtifactByAuditID(id: number): Observable<any> {
        const toDeletekey = this.storage.buildCurrentStorageKey("AnswerArtifact", "FileToDelete_" + id);
        const toAddkey = this.storage.buildCurrentStorageKey("AnswerArtifact", id);
        const result = new ReplaySubject<any>(1);

        if (this.tools.isApp())  {
            this.storage.containsObject(toDeletekey).subscribe((isExists: boolean) => {
                if (isExists) {
                    this.storage.retrieveObject(toDeletekey).subscribe((data: any) => {
                        this.retrieveArtifactsToBeAdded(toAddkey).subscribe((addArtifacts: any) => {
                            result.next({ toDeleteArtifacts: data, toAddArtifacts: addArtifacts });
                            result.complete();
                        });
                    }, (err: any) => {
                        this.log.error("failed to retrieve object in getMitigationArtifactByMitigationID: " + JSON.stringify(err));
                        result.error(err);
                    });
                } else {
                    this.retrieveArtifactsToBeAdded(toAddkey).subscribe((addArtifacts: any) => {
                        result.next({ toDeleteArtifacts: [], toAddArtifacts: addArtifacts });
                        result.complete();
                    });
                }
            });
        } else {
            const keyStr: string = this.storage.keyAsString(toDeletekey);
            let data: any = [];

            if (this.local.containsItem(keyStr)) {
                data = JSON.parse(this.local.getItem(keyStr));
            }

            const addKeyStr: string = this.storage.keyAsString(toAddkey);
            let addData: any = [];

            if (this.local.containsItem(addKeyStr)) {
                addData = JSON.parse(this.local.getItem(addKeyStr));
            }
            result.next({ toDeleteArtifacts: data, toAddArtifacts: addData });
            result.complete();
        }

        return result.asObservable();
    }

    public syncAuditAnswerArtifacts(onlineAuditDetails: IAuditDetail[]): Observable<any> {
        const artifactsToDelete: any[] = [];
        const artifactsToAdd: any[] = [];
        const result = new ReplaySubject<any>(1);
        this.getAnswerArtifactsInFileSystem(onlineAuditDetails).subscribe((contents: any[]) => {
            this.zone.run(() => {
                const offlineDeletedArtifactIDs: number[] = [];
                for (const content of contents) {
                    const deleteArtifacts = content.toDeleteArtifacts;
                    const addArtifacts = content.toAddArtifacts;

                    if (deleteArtifacts != null && deleteArtifacts.length > 0) {
                        for (const item of deleteArtifacts) {
                            for (const detail of onlineAuditDetails) {
                                for (const answerArtifact of detail.AuditAnswerArtifacts) {
                                    if (item.ID === answerArtifact.ID) {
                                        this.log.debug("syncAuditAnswerArtifacts mitigation status: " + detail.Audit.Status);
                                        if (detail.Audit.Status !== EaseAuditStatus.Complete && detail.Audit.Status !== EaseAuditStatus.CompletedLate) {
                                            this.log.debug("syncAuditAnswerArtifacts: " + item.ID);
                                            artifactsToDelete.push({ AuditID: detail.Audit.ID, Artifact: item });
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (addArtifacts != null && addArtifacts.length > 0) {
                        for (const item of addArtifacts) {
                            if (item.auditAnswerArtifactID === 0) {
                                artifactsToAdd.push(item);
                            }
                        }
                    }
                }

                if (artifactsToDelete.length > 0 && this.tools.isOnline()) {
                  const snackBarRef = this.uiNotify.open("_SyncingInProgress_", true);
                  snackBarRef.afterOpened().subscribe(() => {
                    this.processDeletedAnswerArtifacts(artifactsToDelete).subscribe((auditIDs: number[]) => {
                        auditIDs = _.uniq(auditIDs);
                        auditIDs.forEach((auditID, index) => {
                            this.deleteAuditAnswerArtifactFiles("FileToDelete_" + auditID, () => {});
                            this.addAnswerArtifacts(artifactsToAdd).subscribe((addedAnswerArtifacts: any[]) => {
                                result.next({ artifactsDeleted: artifactsToDelete, artifactsAdded: addedAnswerArtifacts });
                                result.complete();
                            });
                        });
                    });
                  });
                } else {
                    this.addAnswerArtifacts(artifactsToAdd).subscribe((addedAnswerArtifacts: any[]) => {
                        result.next({ artifactsDeleted: artifactsToDelete, artifactsAdded: addedAnswerArtifacts });
                        result.complete();
                    });
                }

            });
        });

        return result.asObservable();
    }

    public removeAuditFromLocalStorage(auditID: number): void {
        this.local.removeItem("storeAudit");
        if (this.local.containsItem("offlineAuditArray") && this.local.getItem("offlineAuditArray") === "undefined") {
          this.local.removeItem("offlineAuditArray");
        }
        if (this.local.containsItem("offlineAuditArray")) {
            const oldOfflineAuditArray = JSON.parse(this.local.getItem("offlineAuditArray"));
            let offlineAuditArray: any[];
            oldOfflineAuditArray.forEach((oldStoredAudit: any) => {
                if (oldStoredAudit.AuditID === auditID) {
                    this.log.debug("removed the audit {" + auditID + "} from the local storage");
                    const auditIndex = oldOfflineAuditArray.indexOf(oldStoredAudit);
                    oldOfflineAuditArray.splice(auditIndex, 1);
                }
                offlineAuditArray = oldOfflineAuditArray;
            });
            this.local.setItem("offlineAuditArray", offlineAuditArray);
        }
    }

    public getAuditTranslatedStatus(status: CompletionStatus): string {
        let value: string = "";
        switch (status) {
            case CompletionStatus.NotStarted:
                value = "_NotStarted_";
                break;
            case CompletionStatus.Complete:
                value = "_Complete_";
                break;
            case CompletionStatus.PartiallyComplete:
                value = "_PartiallyComplete_";
                break;
            case CompletionStatus.Missed:
                value = "_Missed_";
                break;
            case CompletionStatus.CompletedLate:
                value = "_CompletedLate_";
                break;
            case CompletionStatus.PastDue:
                value = "_PastDue_";
                break;
            default:
                value = "";
                break;
        }
        return value;
    }

    public deleteAudit(entityID: number): Observable<boolean> {
        const result = new ReplaySubject<boolean>();
        this.api.Delete("Audit", entityID,
            () => result.next(true),
            () => result.next(false));

        return result.asObservable();
    }

    public resendEmail(auditId: number, resendEmail: boolean): Observable<object> {
      return this.http.put(`/audit/${auditId}?resendEmail=${resendEmail}`, {});
    }

    public deleteAuditAnswerArtifact(id: number): Observable<void> {
      return this.http.delete<void>(`/auditanswerartifact/${id}`);
    }

    public updateAuditChanges(audit: IAudit, param: any): Observable<IAudit> {
      return this.http.put<IAudit>(`/audit/${audit.ID}?${param}`, audit);
    }

    public updatePendingUpload(auditId: number): Observable<object> {
      return this.http.put(`/audit/${auditId}?resendEmail=false&updatePendingUpload=true`, {});
    }

    private retrieveArtifactsToBeAdded(key: StorageKey): Observable<any> {
        const result = new ReplaySubject<any>(1);
        this.storage.containsObject(key).subscribe((fileExists: boolean) => {
            if (fileExists) {
                this.storage.retrieveObject(key).subscribe((addData: any) => {
                    result.next(addData);
                    result.complete();
                }, (err: any) => {
                    this.log.error("failed to retrieve to add answerartifact error" + JSON.stringify(err));
                    result.error(err);
                });
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    private processAnswerArtifacts(auditID: number, artifactsToAdd: any[], artifactsToDelete: any[], result: ReplaySubject<any>) {
        this.deleteAuditAnswerArtifactFiles("FileToDelete_" + auditID, () => {});
        this.addAnswerArtifacts(artifactsToAdd).subscribe((addedAnswerArtifacts: any[]) => {
            result.next({ artifactsDeleted: artifactsToDelete, artifactsAdded: addedAnswerArtifacts });
            result.complete();
        });
    }

    private addAnswerArtifacts(artifactsToAdd: any[]): Observable<any> {
        const result = new ReplaySubject<any>(1);
        if (artifactsToAdd.length > 0 && this.tools.isOnline()) {
          const snackBarRef = this.uiNotify.open("_SyncingInProgress_", true);
          snackBarRef.afterOpened().subscribe(() => {
            this.artifact.getArtifactsToUpload(artifactsToAdd).subscribe((answerUploads: any[]) => {
                this.processAddedAnswerArtifacts(answerUploads).subscribe((auditIDs: number[]) => {
                    auditIDs = _.uniq(auditIDs);
                    this.getAuditAnswerArtifactsOnline(auditIDs).subscribe((artifacts: any[]) => {
                        artifacts.forEach((data, index) => {
                            this.deleteAuditAnswerArtifactFiles(data.auditID.toString(), () => {});
                        });
                        result.next(artifacts);
                        result.complete();
                    });
                });
            });
          });
        } else {
            result.next([]);
            result.complete();
        }
        return result.asObservable();
    }

    private processAddedAnswerArtifacts(artifactsToAdd: any[]) {
        const syncAnswerArtifacts: Array<Observable<number>> = [];
        for (const artifact of artifactsToAdd) {
            syncAnswerArtifacts.push(this.addAuditAnswerArtifacts(artifact));
        }
        return Observable.forkJoin(syncAnswerArtifacts);
    }

    private addAuditAnswerArtifacts(answerArtifact: any): Observable<number> {
        const result = new ReplaySubject<number>(1);

        this.api.AddX("auditanswerartifact", answerArtifact, { id: answerArtifact.answerID }, (auditAnswerArtifactID: number) => {
            this.zone.run(() => {
                const content = answerArtifact.imageName.split(".");
                const fileName = "AnswerArtifact_" + auditAnswerArtifactID + "." + content[1];
                this.storage.renameImage(answerArtifact.Url, fileName).subscribe(() => {
                    result.next(answerArtifact.auditID);
                    result.complete();
                }, (err: any) => {
                    this.log.error("error in renaming image file " + err);
                    result.error(err);
                    result.next(0);
                    result.complete();
                });
            });
        }, (status: any, msg: any, headers: any) => {
            result.next(0);
            result.complete();
        });

        return result.asObservable();
    }

    private processDeletedAnswerArtifacts(artifactsToDelete: number[]) {
        const syncAnswerArtifacts: Array<Observable<number>> = [];
        for (const artifact of artifactsToDelete) {
            syncAnswerArtifacts.push(this.deleteAuditAnswerArtifacts(artifact));
        }
        return Observable.forkJoin(syncAnswerArtifacts);
    }

    private deleteAuditAnswerArtifacts(item: any): Observable<number> {
        const result = new ReplaySubject<number>(1);

        this.api.Delete("auditanswerartifact", item.Artifact.ID, (deleteData: any) => {
            this.zone.run(() => {
                this.storage.deleteImage(item.Artifact.Url).subscribe(() => {
                    result.next(item.AuditID);
                    result.complete();
                }, (err: any) => {
                    this.log.error("error deleting image file " + err);
                    result.error(err);
                    result.next(0);
                    result.complete();
                });
            });
        }, (status: any, msg: any, headers: any) => {
            if (status === 200) {
                this.storage.deleteImage(item.Artifact.Url).subscribe(() => {
                    result.next(item.AuditID);
                    result.complete();
                }, (err: any) => {
                    this.log.error("error callback deleting image file" + err);
                    result.error(err);
                    result.next(0);
                    result.complete();
                });
            } else {
                result.next(0);
                result.complete();
            }
        });

        return result.asObservable();
    }

    private deleteAuditAnswerArtifactFiles(entityID: string, callback: any): void {
        // remove audit answer artifact from file system after
        const key = this.storage.buildCurrentStorageKey("AnswerArtifact", entityID);
        if (this.tools.isApp()) {
            this.storage.deleteObject(key).subscribe(() => {
                this.zone.run(() => {
                    if (callback) { callback(); }
                });
            }, (err: any) => {
                this.log.error("failed to delete answer artifact file " + JSON.stringify(err));
                if (callback) { callback(); }
            });
        } else {
            const keyStr: string = this.storage.keyAsString(key);
            this.local.removeItem(keyStr);

            if (callback) { callback(); }
        }
    }

    private getAnswerArtifactsInFileSystem(onlineAuditDetails: IAuditDetail[]) {
        const artifactsInFileSystem: Array<Observable<any>> = [];
        for (const detail of onlineAuditDetails) {
            artifactsInFileSystem.push(this.getArtifactFileContent(detail.Audit.ID));
        }
        if (artifactsInFileSystem && artifactsInFileSystem.length == 0) {
            //return empty file content observable if there are no artifacts to process
            artifactsInFileSystem.push(Observable.create((result: any) => {
                result.next({ toDeleteArtifacts: [], toAddArtifacts: [] });
                result.complete();
            }));
        }
        return Observable.forkJoin(artifactsInFileSystem);
    }

    private getArtifactFileContent(id: number): Observable<any> {
        return this.getAnswerArtifactByAuditID(id).take(1).map(detail => {
            return detail;
        });
    }

    private getAuditDetailsInFileSystem(onlineAudits: IAuditDetail[]) {
        const auditDetailsInFileSystem: Array<Observable<any>> = [];
        for (const detail of onlineAudits) {
            const readFromServer = detail.Audit.DocumentRevID == null;
            auditDetailsInFileSystem.push(this.getFileContent(detail.Audit.ID, readFromServer));
        }
        if (auditDetailsInFileSystem && auditDetailsInFileSystem.length == 0) {
            //return empty file content observable if there are no audits to process
            auditDetailsInFileSystem.push(Observable.create((result: any) => {
                result.next({ auditDetail: [], inAuditMitigation: [], uploadedFileArray: []});
                result.complete();
            }));
        }
        return Observable.forkJoin(auditDetailsInFileSystem);
    }

    private getFileContent(auditID: number, readFromServer: boolean): Observable<any> {
        return this.getAuditByID(auditID, "Audits", readFromServer).take(1).map(detail => {
            return detail;
        });
    }

    private processAudits(auditDetails: IAuditDetail[]) {
        const syncAudits: Array<Observable<any>> = [];
        for (const detail of auditDetails) {
            syncAudits.push(this.updateAudit(detail.Audit));
        }
        return Observable.forkJoin(syncAudits);
    }

    private updateAudit(audit: IAudit): Observable<any> {
        const result = new ReplaySubject<any>(1);
        let param = {};
        if (audit.Status == EaseAuditStatus.Complete && audit.DateCompleted != null) {
            param = { isConductingAudit: true, isSubmittedFromOffline: true };
        } else {
            param = { isConductingAudit: true };
        }

        this.api.UpdateX("Audit", audit.ID, audit, param, (data: any) => {
            this.zone.run(() => {
                result.next(data);
                result.complete();
            });
        });
        return result.asObservable();
    }

    public deleteSavedAudit(auditID: number, artifacts: IArtifact[], callback: any): void {
        // remove in audit infos from file system after
        const key = this.storage.buildCurrentStorageKey("Audits", auditID);
        const inAuditMitigationKey = this.storage.buildCurrentStorageKey("InAuditMitigations", auditID);
        if (this.tools.isApp()) {
            this.storage.deleteObject(key).subscribe(() => {
                this.storage.deleteObject(inAuditMitigationKey).subscribe(() => {
                    this.deleteArtifacts(artifacts).subscribe((isDeleted: boolean) => {
                        this.zone.run(() => {
                            if (callback) { callback(); }
                        });
                    });
                }, (err: any) => {
                    this.log.error("failed to delete inAuditMitigation file " + JSON.stringify(err));
                });
            }, (err: any) => {
                this.log.error("failed to delete audit file " + JSON.stringify(err));
            });
        } else {
            const keyStr: string = this.storage.keyAsString(key);
            this.local.removeItem(keyStr);

            const mitigationKeyStr: string = this.storage.keyAsString(inAuditMitigationKey);
            this.local.removeItem(mitigationKeyStr);

            if (callback) { callback(); }
        }
    }

    private deleteArtifacts(artifacts: IArtifact[]): Observable<boolean> {
        const result = new ReplaySubject<boolean>();
        if (this.tools.isApp() && artifacts != null && artifacts.length > 0) {
            let count = 0;
            _.each(artifacts, (artifact: IArtifact) => {
                const artifactKey = this.storage.buildCurrentStorageKey("AuditDetailArtifact", artifact.ID);
                this.storage.deleteObject(artifactKey).subscribe(() => {
                    count++;
                    if (count == artifacts.length) {
                        result.next(true);
                        result.complete();
                    }
                }, (err: any) => {
                    this.log.error("failed to delete artifact file " + JSON.stringify(err));
                    result.next(false);
                    result.complete();
                });
            });
        } else {
            result.next(false);
            result.complete();
        }
        return result.asObservable();
    }

    private getAuditAnswerArtifactsOnline(auditIDs: number[]) {
        const auditAnswerArtifacts: Array<Observable<any>> = [];
        for (const id of auditIDs) {
            auditAnswerArtifacts.push(this.getAuditAnswerArtifactContentOnline(id));
        }
        if (auditAnswerArtifacts && auditAnswerArtifacts.length == 0) {
            //return empty file content observable if there are no artifacts to process
            auditAnswerArtifacts.push(Observable.create((result: any) => {
                result.next({ auditID: 0, auditanswerartifacts: [] });
                result.complete();
            }));
        }
        return Observable.forkJoin(auditAnswerArtifacts);
    }

    private getAuditAnswerArtifactContentOnline(auditID: number): Observable<any> {
        return this.getAuditAnswerArtifactByAuditIDOnline(auditID).take(1).map(detail => {
            return detail;
        });
    }

    public getAuditAnswerArtifactByAuditIDOnline(id: number): Observable<any> {
        const result = new ReplaySubject<any>(1);
        this.api.Query("auditanswerartifact", { q: "audit", p: id, thumbnail: true }, (artifacts: IAuditAnswerArtifact[]) => {
            this.zone.run(() => {
                result.next({ auditID: id, auditanswerartifacts: artifacts });
                result.complete();
            });
        }, (status: any, msg: any, headers: any) => {
            result.next({ auditID: 0, auditanswerartifacts: [] });
            result.complete();
        });
        return result.asObservable();
    }

    private processOfflineAuditArray(onlineAudits: IAuditDetail[]): Observable<any> {
        const result = new ReplaySubject<any>(1);
        const auditDetails: IAuditDetail[] = [];
        if (this.local.containsItem("offlineAuditArray") && this.local.getItem("offlineAuditArray") === "undefined") {
          this.local.removeItem("offlineAuditArray");
        }

        if (this.local.containsItem("offlineAuditArray")) {
            const offlineAuditArray: any[] = JSON.parse(this.local.getItem("offlineAuditArray"));
            const auditorID = this.current.info.user.ID;
            const tempOfflineAudits: any[] = [];

            if (offlineAuditArray.length > 0) {
                // filter the offline audit to the current user id
                offlineAuditArray.forEach((offlineAudit: any) => {
                    if (auditorID !== offlineAudit.AuditorID || offlineAudit.Description === "") {
                        if (auditorID !== offlineAudit.AuditorID) {
                            this.log.debug("Current AuditorID [auditor in localStorage:" + offlineAudit.AuditorID + ", Logged on UserID:" + auditorID + "] is different and so removed the audit {" + offlineAudit.AuditID + "} from the offline Audit");
                        } else {
                            this.log.debug("Description is blank and so removed the audit {" + offlineAudit.AuditID + "} from the offline Audit");
                        }
                    } else {
                        tempOfflineAudits.push(offlineAudit);
                    }
                });

                onlineAudits.forEach((auditDetail: IAuditDetail) => {
                    if (auditDetail && auditDetail.Audit !== null) {
                        const auditID = auditDetail.Audit.ID;
                        const changeToken = auditDetail.Audit.ChangeToken;
                        tempOfflineAudits.forEach((offlineAudit) => {
                            if (auditID === offlineAudit.AuditID && changeToken !== offlineAudit.ChangeToken) {
                                this.log.debug("change Token is different and so removed the audit {" + auditID + "} from the offline Audit");
                                const offlineAuditIndex = onlineAudits.indexOf(auditDetail);
                                tempOfflineAudits.splice(offlineAuditIndex, 1);
                            }
                        });
                    }
                });

                // if the audit is in offline grid, remove it from the audit list to avoid duplicates
                tempOfflineAudits.forEach((offlineAudit: any) => {
                    const offlineAuditID: number = offlineAudit.AuditID;
                    const changeTokenInLocalStorage: string = offlineAudit.ChangeToken;
                    onlineAudits.forEach((auditDetail: IAuditDetail) => {
                        if (auditDetail && auditDetail.Audit !== null) {
                            if (offlineAuditID === auditDetail.Audit.ID) {
                                auditDetail.Audit.IsNotSynced = false;
                                auditDetail.Audit.AuditQuestions = offlineAudit.AuditQuestions;
                                auditDetail.Audit.Department = null;
                                auditDetail.Audit.Shift = null;
                                auditDetail.Audit.Location = null;
                                auditDetails.push(auditDetail);
                            }
                        }
                    });
                });
            }
        }

        result.next(auditDetails);
        result.complete();

        return result.asObservable();
    }

    private displayAuditException(errorCode: string): void {
      let key: string;
      switch (parseInt(errorCode, 10)) {
        case 1:
          key = "_AuditMissed_";
          break;
        case 2:
          key = "_AuditAlreadyComplete_";
          break;
        case 3:
          key = "_AuditAssigneeMismatch_";
          break;
        case 4:
          key = "_AuditRemoved_";
          break;
        case 5:
          key = "_AuditOutsideScope_";
          break;
        default:
          key = "_UnableToStartAudit_";
          break;
      }
      this.uiNotify.open(key);
      this.router.navigate(["/"]);
    }
}
