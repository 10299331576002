import {Component} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { IAuditConfig, IAuditType, IRole, IUser, IAuditReport } from "../../core/models/ease-models";
import { ApiService } from "../../core/services/api.service";
import {CurrentInfoService} from "../../core/services/current.service";
import { LocalizeService } from "../../core/services/localize.service";
import { LocalService } from "../../core/services/local.service";
import { NotifyService } from "../../core/services/notify.service";
import { ListViewBase } from "../../shared/list-view/list-view-base";
import { ListViewStorageService } from "../../shared/list-view/list-view-storage.service";

@Component({
    selector: "ease-audit-history-list",
    templateUrl: "./auditHistory.component.html",
})

export class AuditHistoryListComponent extends ListViewBase {

    public dataSource: any;
    public listViewOption: string = "_DocumentList_";
    public loading: boolean = true;
    public isJobTypeEnabled: boolean = false;
    public isCustomerTypeEnabled: boolean = false;
    public auditCategories: IAuditType[];
    public auditConfig: IAuditConfig[];
    public roles: IRole[];
    public user: IUser;
    public isDataEntry: boolean;

    constructor(public api: ApiService,
                private Current: CurrentInfoService,
                public notify: NotifyService,
                public local: LocalService,
                public route: ActivatedRoute,
                public myListViewStorageService: ListViewStorageService,
                private localize: LocalizeService,
                private router: Router) {

        super(api, notify, local, route, myListViewStorageService);
        // this.isJobTypeEnabled =  current.isJobTypeEnabled;

        this.getAuditCategories(() => {
            this.getAuditConfig();
        });

        this.initialize("auditreport",
            {querycols: "Name|JobCode|Auditor|Customer", AuditHistoryList: true},
            "Name|AuditorID|Status|JobID|CustomerID",
            "Name",
            "auditHistory",
            (data: any[]) => {

                this.dataSource = data;
                this.loading = false;
            });

        const cm = this.Current.info;
        this.isJobTypeEnabled = cm.isJobTypeEnabled;
        this.isCustomerTypeEnabled = cm.isCustomerTypeEnabled;
        this.roles = cm.roles;
        this.user = cm.user;
        this.isDataEntry = cm.roleIsDataEntry;
    }

    private getAuditCategories(callback: any): void {
        this.api.Query("AuditQuestionCategory", {},(data: IAuditType[]) => {
            _.each(data, (cat) => {
                cat.Name = this.localize.getLocalizedString(cat.Name);
            });

            this.auditCategories = data;
            if (callback) {callback();
            }
        });
    }

    private getAuditConfig(): void {
        this.api.Query("AuditConfiguration", {}, (data: any[]) => {
            this.auditConfig = data;
        });
    }

    private getAuditCategoryByID(id: number): IAuditType {
        // var filtered: IAuditCategory[] = this.filter('filter')(this.auditCategories, { ID: id }, true);
        const filtered = this.auditCategories.filter(categories => categories.ID == id);

        return (filtered != null && filtered.length !== 0) ? filtered[0] : null;
    }

    private getAuditConfigByCatID(id: number): IAuditConfig {
        // var filtered: IAuditConfig[] = this.$filter('filter')(this.auditConfig, { AuditCategoryID: id }, true);
        const filtered = this.auditConfig.filter(AuditCategoryID => AuditCategoryID.AuditCategoryID == id);
        return (filtered != null && filtered.length !== 0) ? filtered[0] : null;
    }

    private navigateToAuditDetails(auditID: any): void {
        this.router.navigate(["./audit", auditID], {relativeTo: this.route});
    }

    private navigateToConductAudit($event: any, audit: any): void {
        $event.stopPropagation();

        let isAuditDataEntry: boolean;
        let isConduct: boolean;

        // check if audit can be conducted by user with data entry role
        if (this.roles.length > 0 && this.isDataEntry) {
            if (audit.AuditorID === this.user.ID) {
                isAuditDataEntry = false;
            } else {
                isAuditDataEntry = true;
            }
        } else if (this.roles.length === 1 && this.isDataEntry) {
            isAuditDataEntry = true;
        }

        // check if redirect audit to data entry page
        let isRedirectAuditEntry;
        const templateType = this.getAuditCategoryByID(audit.TemplateTypeID);
        if (templateType != null) {
            audit.AuditCategoryName = templateType.Name;
            const config = this.getAuditConfigByCatID(audit.TemplateTypeID);
            isRedirectAuditEntry = this.checkIsRedirectAuditEntry(config, audit);
        }

        if (audit.TranslatedStatus === "_NotStarted_") {
            if (!isAuditDataEntry && this.isJobTypeEnabled) {
                if (audit.Status === AUDIT_STATUS.PAST_DUE && audit.DateAuditStarted == null) {
                    if (audit.JobID === 0) {
                        if (!isRedirectAuditEntry) {
                            isConduct = true;
                        } else {
                            isConduct = false;
                        }
                    } else {
                        isConduct = true;
                    }
                }
            } else if (!isAuditDataEntry && !this.isJobTypeEnabled) {
                if (audit.Status === AUDIT_STATUS.PAST_DUE && audit.DateAuditStarted == null) {
                    if (!isRedirectAuditEntry) {
                        isConduct = true;
                    } else {
                        isConduct = false;
                    }
                }
            } else if (isAuditDataEntry) {
                if ((audit.Status === AUDIT_STATUS.MISSED || audit.Status === AUDIT_STATUS.NOT_STARTED || (audit.Status === AUDIT_STATUS.PAST_DUE && audit.DateAuditStarted == null))) {
                    if (!isRedirectAuditEntry) {
                        isConduct = true;
                    } else {
                        isConduct = false;
                    }
                }
            }
        } else { // partially complete
            if (audit.Status === AUDIT_STATUS.INCOMPLETE || (audit.Status === AUDIT_STATUS.PAST_DUE && audit.DateAuditStarted != null)) {
                isConduct = true;
            }
        }
        // isConduct conditional removed. May be able to remove isConduct assignment code.
        this.router.navigate(["./conduct", audit.ID], {relativeTo: this.route});
    }

    private checkIsRedirectAuditEntry(config: any, audit: IAuditReport): boolean {

        let isRedirectAuditEntry: boolean = false;
        const notStarted = audit.Status === 0 || audit.Status === 5;
        if (notStarted && (config != null && (config.AuditTargets.indexOf(2) > -1 || config.AuditTargets.indexOf(4) > -1 || (config.AuditTargets.indexOf(5) > -1 && this.Current.info.org.IsSupplierEnabled))) && ((audit.IsAdHoc && this.user.ID !== audit.AuditorID) || !audit.IsAdHoc)) {
            isRedirectAuditEntry = true;
        }
        return isRedirectAuditEntry;
    }
}

// enums from old Ease1 ng-ease-enums.js
enum AUDIT_CHART_TYPES {
    AVG_SCORE = 0,
    TOTAL_AUDITS = 1,
    TREND_LINES = 2,
    NON_CONF_BY_SITE = 3,
    NON_CONF_BY_GROUP = 4,
}

enum AUDIT_STATUS {
    NOT_STARTED = 0,
    COMPLETE = 1,
    INCOMPLETE = 2,
    MISSED = 3,
    COMPLETED_LATE = 4,
    PAST_DUE = 5,
}

enum DOCUMENT_STATUS {
    DRAFT = 1,
    SUBMITTED = 2,
    INREVIEW = 3,
    REJECTED = 4,
    APPROVED = 5,
    ACTIVE = 6,
    INACTIVE = 7,
}

enum JOB_STATUS {
    OPEN = 0,
    CLOSED = 1,
}

enum APPLICATION_TYPES {
    AUDITING = 1,
    LABOR_STANDARDS = 2,
}

enum AUDIT_CATEGORIES {
    LPA = 1,
    JHAA = 5,
    SAFETY = 6,
    CUSTOMER_SURVEY = 9,
}
