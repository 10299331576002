import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { ICompanySite } from "../../models/ease-models";
import { ApiService } from "../api.service";

@Injectable()
export class CompanySiteService {
    constructor(private api: ApiService) {}
    public getCompanySites(query: any) {
        const result = new ReplaySubject<any>(1);
        this.api.Query("CompanySite", query, (data: ICompanySite[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                console.log("no company sites found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }
}