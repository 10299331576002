import { Observable } from "rxjs";
import { share } from "rxjs/operators";

import { Component, Input, OnInit } from "@angular/core";

// Components
import { BreadcrumbComponent } from "../breadcrumb.component";

// Services
import { BreadcrumbsService } from "../breadcrumbs.service";

@Component({
  selector: "ease-breadcrumb-capa",
  template: `
    <ease-breadcrumb-base-item
      *ngIf="data.type"
      [loading]="!(name$ | async)"
      [placeholder]="placeholder"
      [ngClass]="{'breadcrumb-loading': !(name$ | async)}">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        {{ (name$ | async) }}
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbCapaComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  name$: Observable<string>;
  placeholder = "_BreadcrumbCAPALoading_";

  constructor(
    private breadcrumbs: BreadcrumbsService,
  ) {}

  ngOnInit() {
    if (this.data.type === "capa") {
      this.name$ = this.breadcrumbs.getCapaName(this.data.params.id).pipe(share());
    }

    if (this.data.type === "task") {
      this.name$ = this.breadcrumbs.getCapaNameByTaskId(this.data.params.id).pipe(share());
    }
  }
}
