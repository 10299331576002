import { throwError as observableThrowError,  Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";

// Services
import { UiNotificationService } from "../services/common/ui-notification.service";
import { ConfirmationDialogService } from "@shared/confirmation/confirmation-dialog.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public uiNotify: UiNotificationService,
              public confirm: ConfirmationDialogService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = req.clone({});

    return next.handle(modified).pipe(
      catchError(error => {
        const exceptionList: string[] = ["bulkeditvalidation", "validation", "audit"];
        let exclude: boolean = false;
        if (error.error != null && error.error.Code != null) {
          exclude = exceptionList.filter((e: string) => e === error.error.Code).length > 0;
        }

        if (error.status === 403) {
          this.uiNotify.open("_AuthorizationErrorMessage_", true, 10000);
        } else if (error.status === 500 && error.error.Code === "sl.1") {
            this.confirm.open("_UnableToAddAdditionalUsers_", "_MaximumAllowedUsers_", false, "", "_OK_");
        } else if (error.status === 500 && !exclude) {
          this.uiNotify.open("_GenericErrorMessage_", true, 10000);
        }
        return observableThrowError(error);
      }),
    );
  }
}
