import {Component, Inject} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  templateUrl: "./corrective-actions-modal.component.html",
})

export class CorrectiveActionModalComponent {

  ids: number[];
  constructor(
    public dialogRef: MatDialogRef<CorrectiveActionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.ids = data.ids ;
  }

  public openInNewTab(url: string) {
    if (url) {
      window.open(url, "_blank");
    }
  }
  public cancelHandler(url: string) {
    this.dialogRef.close();
  }
}
