
import { Component, Inject } from "@angular/core";
import {  MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CalendarEventType, ICalendarEvent, IClientUser } from "@core/models/ease-models";

import { AuditReassignDeleteService } from "./audit-reassign.service";
import { AuditReassignData } from "./audit-reassign.types";

@Component({
  templateUrl: "./audit-reassign-modal.component.html",
})
export class AuditReassignModalComponent {
  public oldAuditorName: string = null;
  public auditors: IClientUser[];
  public replacementAuditorID: number;

  private oldAuditorID: number;
  private startDate: Date;
  private endDate: Date;
  private userCalendarEvents: ICalendarEvent[];

  constructor(public dialogRef: MatDialogRef<AuditReassignModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private auditReassignService: AuditReassignDeleteService) {

              this.auditReassignService.getAuditReassignData(data.selected)
              .subscribe((reassignData: AuditReassignData) => {
                this.oldAuditorID = reassignData.OldAuditorID;
                this.oldAuditorName = reassignData.OldAuditorName;
                this.startDate = reassignData.SiteStartDate;
                this.endDate = reassignData.SiteEndDate;
                this.userCalendarEvents = reassignData.UserCalenderEvents;
                this.auditors = reassignData.Auditors;

                // set default/first option to 'None (Delete)'
                this.replacementAuditorID = this.auditors[1].ID;
              });
  }

  public ok(): void {
    const newAuditorID: number = this.replacementAuditorID;
    const isUnassignAudits: boolean = (newAuditorID === -1);
    const isDeleteAudits: boolean = (newAuditorID === 0);

    this.auditReassignService.auditReasssignDelete(
      this.oldAuditorID, newAuditorID,
      this.startDate.toUTCString(), this.endDate.toUTCString(),
      isUnassignAudits, isDeleteAudits)
      .subscribe(() => {
        this.dialogRef.close(newAuditorID);
      });
  }
}
