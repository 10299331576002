import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "ease-auth-page",
  template: `
    <div class="wrapper" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="80" fxFlex.gt-xs="400px">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .wrapper {
      height: 100vh;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageComponent {
}
