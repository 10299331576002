// Material
import { SortDirection } from "@angular/material";

import {
  OrganizationsCollectionActionTypes,
  OrganizationsCollectionActionsUnion,
} from "./../actions/collection.actions";

export interface State {
  loading: boolean;
  ids: number[];
  totalRows: number;
  sortActive: string;
  sortDirection: SortDirection;
  pageSize: number;
  pageIndex: number;
  filter: string;
}

const initialState: State = {
  loading: false,
  ids: [],
  totalRows: 0,
  pageSize: 10,
  pageIndex: 0,
  sortActive: "CreationDate",
  sortDirection: "desc",
  filter: "",
};

export function reducer(
  state = initialState,
  action: OrganizationsCollectionActionsUnion,
): State {
  switch (action.type) {
    case OrganizationsCollectionActionTypes.OptionsChanges: {
      return {
        ...state,
        pageSize: action.payload.pageSize,
        pageIndex: action.payload.pageIndex,
        sortActive:  action.payload.sortActive,
        sortDirection: action.payload.sortDirection,
        filter: action.payload.filter,
      };
    }

    case OrganizationsCollectionActionTypes.Fetch: {
      return {
        ...state,
        loading: true,
      };
    }

    case OrganizationsCollectionActionTypes.FetchSuccess: {
      return {
        ...state,
        loading: false,
        ids: action.payload.Result.map(organization => organization.ID),
        totalRows: action.payload.TotalRows,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;
export const getIds = (state: State) => state.ids;
export const getTotalRows = (state: State) => state.totalRows;

export const getPageSize = (state: State) => state.pageSize;
export const getPageIndex = (state: State) => state.pageIndex;
export const getSortActive = (state: State) => state.sortActive;
export const getSortDirection = (state: State) => state.sortDirection;
export const getFilter = (state: State) => state.filter;
