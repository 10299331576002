import { Component, Input, OnInit } from "@angular/core";
import { CurrentInfoService } from "../../../core/services/current.service";
import { AuditResultComponent } from "../audit-result/audit-result.component";

import { IClientAuditQuestion, ICustomCategory, ICustomFieldValue, ICustomItem } from "../../../core/models/ease-models";

@Component({
    selector: "ease-audit-response",
    templateUrl: "./audit-response.component.html",
})

export class AuditResponseComponent implements OnInit {
    @Input() public question: IClientAuditQuestion;
    @Input() public customCategory: ICustomCategory;
    @Input() public customItem: ICustomFieldValue;
    public current: any;

    constructor(private auditResult: AuditResultComponent, private currentInfo: CurrentInfoService) {

    }

    public ngOnInit() {
        this.current = this.currentInfo.info;
    }

    public selectedCustomCategories(customCategories: ICustomCategory[]): ICustomCategory[] {
        const filteredCustomCategories = customCategories.filter((cat: ICustomCategory) => cat.IsSelected == true);
        return filteredCustomCategories;
    }

    public selectedCustomItems(customItems: ICustomItem[]): ICustomItem[] {
        const filteredCustomItems = customItems.filter((cat: ICustomItem) => cat.IsSelected == true);
        return filteredCustomItems;
    }
}
