import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {
    CapaEventApprovalStatuses, CapaEventValidationStatus,
    CapaStatusTypes, ICapaEventApprover, ICapaEventLineItem,
    ICapaTask,
    IClientCapaEvent,
    IClientCapaEventLineItem, ICustomItem,
    IUser,
} from "../../../core/models/ease-models";
import {LocalizeService} from "../../../core/services/localize.service";
import {CurrentInfoService} from "../../../core/services/current.service";
import {NotifyService} from "../../../core/services/notify.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {CorrectiveActionsDataAccessService} from "../../services/corrective-actions-data-access.service";
import {Observable, Subscription} from "rxjs";
import {IdentityService} from "../../../core/services/identity.service";
import {UiNotificationService} from "../../../core/services/common/ui-notification.service";
import {StorageService} from "../../../core/services/storage.service";
import {PageStateService} from "../../../core/services/navigation/page-state.service";
import {ConfirmationDialogService} from "../../../shared/confirmation/confirmation-dialog.service";
import {FileUploadData, FileUploadTypes} from "@shared/file-upload/file-upload.class";
import {ICapaArtifact} from "@core/models/ease-models";
import {FileUploadService} from "@shared/file-upload/file-upload.service";
import {ArtifactService} from "@core/services/common/artifact.service";

@Component({
    selector: "ease-capa-events-details",
    templateUrl: "./capa-event-details.component.html",
    styleUrls: ["./capa-event-details.component.scss"],
})
export class CapaEventDetailsComponent implements OnInit, OnDestroy {
    @ViewChild("capaEventFormComponent") public capaEventFormComponent;
    @Input() isPrintMode: boolean = false;
    loading: boolean = false;
    capaEventInitialized: boolean = false;
    lineItems: IClientCapaEventLineItem[];
    validationLineItems: IClientCapaEventLineItem[];

    isApprovedByCurrentUser: boolean = false;
    isCapaApprover: boolean = false;
    isCapaOwner: boolean = false;
    isCapaEventInitiator: boolean = false;

    canEdit: boolean = false;
    canEditValidationDueDate: boolean = false;
    canEditValidationUsers: boolean = false;
    canApprove: boolean = false;
    canAddCapaApprover: boolean = false;
    canDeleteCapaEvent: boolean = false;

    currentUserID: number;
    pageContentHeightWithTabs: number;
    isAllTasksComplete: boolean = false;

    capaEventID: number;
    targetCapaTaskID: number;

    capaRejectComments: string = "";
    enterCommentMessage: string = "";

    daysDueAfterOpened: number;

    enableAuditSelection: boolean = false;
    public isLeftNavPinned: boolean = false;
    public artifacts: ICapaArtifact[];
    public fileUploadData: FileUploadData;
    private leftNavStateSubscription: Subscription;

    private currentCapaEvent: IClientCapaEvent;
    private possibleCapaOwners: IUser[] = [];
    private possibleCapaApprovers: IUser[] = [];
    private host: string;
    private isSiteAdmin = false;

    constructor(
        private localize: LocalizeService, private storage: StorageService,
        private currentInfo: CurrentInfoService, private notify: NotifyService,
        private route: ActivatedRoute, private dataAccess: CorrectiveActionsDataAccessService,
        private router: Router, private identity: IdentityService, private uiNotify: UiNotificationService,
        private pageStateService: PageStateService, private confirm: ConfirmationDialogService,
        public fileUpload: FileUploadService, private artifactService: ArtifactService) {}

    public ngOnInit(): void {
        this.isSiteAdmin = this.currentInfo.info.roleIsSiteAdmin;

        const mode = this.route.snapshot.data["mode"];

        if (mode === "capa") {
          this.capaEventID = Number(this.route.snapshot.params.id);
          this.initCapaEvent();
        }

        if (mode === "task") {
          this.targetCapaTaskID = Number(this.route.snapshot.params.id);
          this.dataAccess.getCapaEventLineItem({ p: "capa-task", q: this.targetCapaTaskID })
            .subscribe((data: IClientCapaEventLineItem[]) => {
              if (data != null && data.length > 0) {
                this.capaEventID = data[0].CapaEventID;
                this.initCapaEvent();
              }
            });
        }

        this.leftNavStateSubscription = this.pageStateService.getIsLeftNavPinned().subscribe((state: boolean) => { this.isLeftNavPinned = state; });

        this.setTooltip();
    }

    public ngOnDestroy() {
        if (this.leftNavStateSubscription) { this.leftNavStateSubscription.unsubscribe(); }
    }

    initCapaEvent(): void {
        const currentModels = this.currentInfo.info;
        this.canDeleteCapaEvent = currentModels.roleIsSiteAdmin;
        this.currentUserID = currentModels.user.ID;

        this.getCapaEvent(() => {

            if (this.currentCapaEvent.SiteID !== currentModels.activeSite.ID) {
                this.notify.broadcast("request-token-refresh", { newSiteID: this.currentCapaEvent.SiteID });
            }

            this.getCapaEventLineItems();
            this.initMultiSelectCustomFields();
        });
        this.registerEventHandlers();

        // this.pageContentHeightWithTabs = this.$rootScope.pageContentHeightWithTabs;
    }

    registerEventHandlers(): void {
        this.router.events.subscribe((event) => {

            if (event instanceof NavigationStart) {
                if (this.capaEventFormComponent != null && this.capaEventFormComponent != null &&
                    this.capaEventFormComponent.capaEventForm.form.dirty) {
                    // event.preventDefault();

                    this.confirmNavigateAway(() => {
                        this.capaEventFormComponent.capaEventForm.form.markAsPristine();
                        this.changeState();
                    });
                }
            }
        });
    }

    private changeState(): void {
        // var prevState = this.$rootScope.previousState;
        // if (prevState && prevState.name !== '') {
        //
        //     this.$state.go(prevState.name);
        // }
        // else {
        //     prevState = JSON.parse(localStorage.getItem("previousState"));
        //     if (prevState) {//maybe cache was deleted
        //         this.$state.go(prevState.name);
        //     }
        //     else {
        //         this.$state.go('activity-dashboard');
        //     }
        // }
    }

    private getCapaEvent(callback) {
        this.loading = true;
        const capaEventID = this.capaEventID;
        this.dataAccess.getCapaEvent(capaEventID, { details: true }).subscribe((data: IClientCapaEvent) => {
            this.currentCapaEvent = data;

            // Check to see if current user is a CAPA owner
            this.currentCapaEvent.CapaEventOwners.forEach((owner) => {
                if (owner.UserID === this.currentUserID) {
                    this.isCapaOwner = true;
                }
            });

            // Check to see if current user is a CAPA approver
            this.currentCapaEvent.CapaEventApprovers.forEach((approver) => {

                if (approver.UserID === this.currentUserID) {
                    this.isCapaApprover = true;
                    if (approver.ApprovalStatus === 1) {
                        this.isApprovedByCurrentUser = true;  // Already approved by current user
                    }
                }
            });

            this.currentCapaEvent.CustomFields.forEach((cf) => {
              if (!cf.CustomFieldValue) {
                cf.CustomFieldValue = {};
              }
            });

            if (this.currentCapaEvent.CreatedByUserID === this.currentUserID) {
                this.isCapaEventInitiator = true;
            }

            const status = this.currentCapaEvent.CapaStatus;
            const isInProgress = status === CapaStatusTypes.InProgressOnTime ||
                status === CapaStatusTypes.InProgressPastDue;
            this.canEdit = this.isCapaOwner && isInProgress && !this.isPrintMode;

            this.canApprove = this.isCapaApprover && (status === CapaStatusTypes.PendingApproval) && !this.isPrintMode
                && !this.isApprovedByCurrentUser;
            this.canAddCapaApprover = (this.isCapaApprover || this.isCapaEventInitiator) && isInProgress
                && !this.isPrintMode;

            this.capaEventInitialized = true;
            this.enableAuditSelection = this.currentCapaEvent.CapaEventAssociationSetups.length > 0;
            if (callback) {callback(); }
        });
    }

    private getCapaEventThumbnails(): void {
        this.loading = true;
        if (this.currentCapaEvent) {
            this.currentCapaEvent.images = [];
            this.currentCapaEvent.imagesToRemove = [];
            this.dataAccess.getCapaEventThumbnails({ capaEventID: this.currentCapaEvent.ID, thumbnail: true }).
            subscribe((thumbs: any[]) => {
                thumbs.forEach((img) => {
                    this.currentCapaEvent.images.push(img);
                });
                this.setImagesUrl();
                this.artifacts = this.currentCapaEvent.images;
                this.fileUpload.initFileUploadData(FileUploadTypes.CorrectiveAction, this.artifacts).subscribe((results) => {
                  this.fileUploadData = results;
                });
                this.loading = false;
            });
        }
    }

    private setImagesUrl() {
        this.currentCapaEvent.images.forEach ((img) => {
            if (this.storage.isDoc(img.FileFormatID)) {
                img.Url = this.artifactService.getImageUrl(img);
            }
        });
    }

    private isDoc(fileFormatID: number): boolean {
        return this.storage.isDoc(fileFormatID);
    }

    // for now include only Admin role. We may expand this to include Managers
    private getUsersForSiteScope(): Observable<IUser[]> {
        let result: Observable<IUser[]>;
        if (this.currentCapaEvent && this.currentCapaEvent.SiteID > 0) {
            result = this.dataAccess.getUsersForSiteScope({
                siteID: this.currentCapaEvent.SiteID, filteredRoles: "SiteAdmin|Manager", includeScopedUsers: true });
        }
        return result;
    }

    private saveCapaEvent() {

        this.capaEventFormComponent.capaEventForm.form.markAsPristine();
        this.notify.broadcast("save-capa-tasks", null);
        this.saveMultiSelectCustomFields();
        const dueDate = new Date(this.currentCapaEvent.DueDate);
        const payload = {...this.currentCapaEvent};
        payload.DueDate = new Date(Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate())).toISOString();
        this.dataAccess.updateCapaEvent(this.currentCapaEvent.ID, payload).subscribe(() => {
            this.fileUpload.postFileUploadChange(this.currentCapaEvent.ID, this.fileUploadData).subscribe(() => {
              this.getCapaEventThumbnails();
              this.uiNotify.open(this.localize.getLocalizedString("_CapaSaved_"));
            });
        });
    }

    private submitCapaEvent(): void {
        this.currentCapaEvent.CapaStatus = 4;
        this.dataAccess.updateCapaEvent(this.currentCapaEvent.ID, this.currentCapaEvent).subscribe(() => {
            this.getCapaEvent(() => {
                this.capaEventFormComponent.capaEventForm.form.markAsPristine();
                this.router.navigate(["/report/corrective-actions"]);
            });
        });
    }

    private approveCapaEvent(): void {
        const query = {
            capaEventID: this.currentCapaEvent.ID, status: CapaEventApprovalStatuses.Approved,
            rejectionComment: this.capaRejectComments,
        }; // TQP TODO: Rename this to just: Comments
        this.dataAccess.updateItem<any>("capaeventapproval", 0, {}, query).subscribe(() => {
            this.getCapaEvent(() => {
                this.capaEventFormComponent.capaEventForm.form.markAsPristine();
                this.router.navigate(["/report/corrective-actions"]);
            });
        });
    }

    private rejectCapaEvent(): void {
        const query = {
            capaEventID: this.currentCapaEvent.ID, status: CapaEventApprovalStatuses.Rejected,
            rejectionComment: this.capaRejectComments,
        };
        this.dataAccess.updateItem<any>("capaeventapproval", 0, {}, query).subscribe(() => {
            this.getCapaEvent(() => {
                this.capaRejectComments = "";
                this.capaEventFormComponent.capaEventForm.form.markAsPristine();
                this.router.navigate(["/report/corrective-actions"]);
            });
        });
    }

    private getCapaEventLineItems(): void {
        const capaEventID = this.capaEventID;
        this.dataAccess.getCapaEventLineItem({p: "", q: capaEventID})
            .subscribe((data: IClientCapaEventLineItem[]) => {
                // Sort by sequence number
                data = data.sort((l, b) => l.SequenceNum - b.SequenceNum);
                this.initLineItems(data);
                this.daysDueAfterOpened = this.getDaysDueAfterOpened(data);
                this.setCanEditTaskFlag(this.lineItems);
                this.setCanEditTaskFlag(this.validationLineItems);
                this.checkAllTasksComplete(this.lineItems);
                this.checkIfContainsValidation(this.validationLineItems);
                this.getUsersForSiteScope().subscribe((users: IUser[]) => {
                    const allUsers = JSON.parse(JSON.stringify(users));
                    const managers = users.filter((item) => item.UserRoles
                        .filter((ur: any) => ur.Role.Name === "Manager").length > 0);
                    if (managers && managers.length > 0) {
                        this.possibleCapaOwners = managers;
                    }

                    const admins = allUsers.filter((item) => item.UserRoles
                        .filter((ur: any) => ur.Role.Name === "SiteAdmin").length > 0);
                    if (admins && admins.length > 0) {
                        this.possibleCapaApprovers = admins;
                    }
                    this.getCapaEventThumbnails();
                });
            });

    }

    private initLineItems(data: IClientCapaEventLineItem[]) {
        this.lineItems = [];
        this.validationLineItems = [];
        data.forEach((item) => {
            if (item.CapaTask) {
                if (item.CapaTask.IsValidation) {
                    this.validationLineItems.push(item);
                } else {
                    this.lineItems.push(item);
                }
            }
        });
    }

    private checkIfContainsValidation(lineItems): void {
        let isValidation = false;
        lineItems.forEach((li) => {
            const capaTask: ICapaTask = li.CapaTask;
            if (capaTask != null) {
                if (capaTask.IsValidation) {
                    isValidation = true;
                }
            }
        });
        if (isValidation) {
              const status = this.currentCapaEvent.CapaStatus;
              const validationStatus = this.currentCapaEvent.ValidationStatus;
              const validationIsInProgress = validationStatus === CapaEventValidationStatus.Pending || validationStatus === CapaEventValidationStatus.None;
              const eventIsInProgress = status === CapaStatusTypes.InProgressOnTime || status === CapaStatusTypes.InProgressPastDue;
              const eventIsClosed = status === CapaStatusTypes.ClosedOnTime || status === CapaStatusTypes.ClosedPastDue;
              this.canEditValidationDueDate = validationIsInProgress && (this.isCapaOwner && eventIsInProgress);
              this.canEditValidationUsers = validationIsInProgress && ((this.isCapaOwner && eventIsInProgress) || (this.isSiteAdmin && eventIsClosed));
          }
    }

    private setCanEditTaskFlag(lineItems: IClientCapaEventLineItem[]) {
        if (lineItems && lineItems.length) {
            for (const i of lineItems) {
                const currentLine = i;
                // CanEditTask should only be true if the task is open and the canEdit flag is also true
                currentLine.CanEditTask = this.canEdit && currentLine.CapaTask &&
                    currentLine.CapaTask.MitigationActivity && currentLine.CapaTask.MitigationActivity.Status === 0;
            }
        }
    }

    private checkAllTasksComplete(lineItems): void {
        let complete = true;
        lineItems.forEach((li) => {
            const mitigation = li.CapaTask.MitigationActivity;
            if (mitigation != null) {
                if (mitigation.Status !== 1) {
                    complete = false;
                }
            }
        });

        this.isAllTasksComplete = complete;
    }

    private onTaskStatusChanged(): void {
        const capaEventID = this.capaEventID;
        this.dataAccess.getCapaEventLineItem({ q: capaEventID })
            .subscribe((data: IClientCapaEventLineItem[]) => {
                const items: IClientCapaEventLineItem[] = [];
                data.forEach((item) => {
                  if (item.CapaTask && !item.CapaTask.IsValidation) { // only check the non-validation tasks
                    items.push(item);
                  }
                });
                // TQP: The reason we don't want to update [this.lineItems] is because it will cause the page to
                // reload and the accordians to collapse
                // Use a local variable to peform the checkAllTasksComplete calculation
                this.checkAllTasksComplete(data);

                // call the setCanEditTaskFlag() on the displayed items ([this.lineItems])
                this.setCanEditTaskFlag(this.lineItems);
                this.setCanEditTaskFlag(this.validationLineItems);
                this.checkIfContainsValidation(items);

                this.disableEditIfAllValidationsCompleted();
        });
    }

    private disableEditIfAllValidationsCompleted() {
      if (this.canEditValidationDueDate || this.canEditValidationUsers) {
        this.dataAccess.getCapaEvent(this.capaEventID, { details: true }).subscribe((data: IClientCapaEvent) => {
          if (data && data.ValidationStatus) {
            if (data.ValidationStatus === CapaEventValidationStatus.Complete) {
              this.canEdit = false;
              this.canEditValidationUsers = false;
              this.canEditValidationDueDate = false;
            }
          }
        });
      }
    }

    private confirmNavigateAway(proceedCallback): void {

        if (this.capaEventFormComponent.capaEventForm.form.dirty) {
            // this.EaseDialog.showErrorWarningDialog('_DiscardChanges_', '_DiscardChangesPrompt_', proceedCallback, '_Discard_', true, null, '_StayOnPage_');
        } else {
            proceedCallback();
        }
    }

    private setTooltip(): void {
        if (!this.capaRejectComments) {
            this.enterCommentMessage = (!this.capaRejectComments) ?
                this.localize.getLocalizedString("_EnterCAPARejectionComment_") : "";
        }
    }

    private initMultiSelectCustomFields(): void {
        this.currentCapaEvent.CustomFields.forEach((cf) => {
            if (cf.FieldTypeID === 7 && cf.Cardinality === "*") {
                cf.RestrictToGroupItems.forEach((item: ICustomItem) => {
                    if (cf.CustomFieldValue.MultiSelectItems.indexOf(item.ID) >= 0) {
                        item.IsSelected = true;
                    }
                });
            } else if (cf.FieldTypeID === 7 && cf.Cardinality === "1") {
                cf.RestrictToGroupItems.forEach((item: ICustomItem) => {
                    if (cf.CustomFieldValue != null && cf.CustomFieldValue.FieldValueCustomItemID === item.ID) {
                        cf.CustomFieldValue.FieldValueStr = item.Name;
                    }
                });
            }
        });
    }

    private saveMultiSelectCustomFields(): void {
        this.currentCapaEvent.CustomFields.forEach ((cf) => {
            if (cf.FieldTypeID === 7 && cf.Cardinality === "*") {
                cf.CustomFieldValue.MultiSelectItems = [];
                cf.RestrictToGroupItems.forEach((item: ICustomItem) => {
                    if (item.IsSelected) {
                        cf.CustomFieldValue.MultiSelectItems.push(item.ID);
                    }
                });
            }
        });
    }

    private deleteCapaEvent(): void {
        this.dataAccess.deleteItem("CapaEvent", this.currentCapaEvent.ID). subscribe((success: boolean) => {
            if (success) {
                this.router.navigate(["/report/corrective-actions"]);
            }
        });
    }

    private deleteCapaEventHandler() {

        this.confirm.open("_DeleteCapa_", "_AreYouSureDeleteCapa_", true, "_Delete_", "_Cancel_");

        this.confirm.getResponse().first().subscribe((response) => {

            if (response === true) {
                this.deleteCapaEvent();
            }
        });
    }

    private getDaysDueAfterOpened(data) {
        let dueDays = 0;
        if (data && data.length) {
          data.forEach(item => {
            dueDays = item.CapaTask.DaysDueAfterOpened && (item.CapaTask.DaysDueAfterOpened > dueDays) ?
              item.CapaTask.DaysDueAfterOpened : dueDays;
          });
        }

        return dueDays - 1;
    }
}
