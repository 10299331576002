import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Types
import { AuditFilters } from "./audit-filters.interface";
import { IDocumentSubTypes, IDepartment, IOrgLayer, IShift, IUser, ILocation, IAuditConfig } from "@core/models/ease-models";
// Services
import { LocalizeService } from "@core/services/localize.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AuditFiltersService {

  constructor(private http: HttpClient,
              private localize: LocalizeService) {

  }

  // TQP Todo: For now, we'll forkjoin the api calls until we have time to create a new API call
  // the new API call should return an object similar to AuditScheduleFilters

  public getFilterData(): Observable<AuditFilters> {

    return Observable.forkJoin([
      this.http.get<IUser[]>("/User"),
      this.http.get<IDepartment[]>("/Department"),
      this.http.get<IOrgLayer[]>("/OrgLayer"),
      this.http.get<IShift[]>("/Shift"),
      this.http.get<IDocumentSubTypes[]>("/DocumentSubtype"),
      this.http.get<IAuditConfig[]>("/AuditConfiguration"),
    ])
      .map((data: any[]) => {

        const docSubtypes: IDocumentSubTypes[] = [];
        data[3].forEach(s => s.Name = this.localize.getLocalizedString(s.Name));

        data[4].forEach((s) => {
          s.Name = this.localize.getLocalizedString(s.Name);
          const filtered = data[5].filter(x => x.AuditCategoryID === s.ID);
          if ((filtered.length === 0) || (filtered.length > 0 && filtered[0].CanSchedule)) {
            docSubtypes.push(s);
          }
        });

        const filters: AuditFilters = {
          Users: (data[0]) ? data[0].sort((l, b) => l.FirstName.localeCompare(b.FirstName)) : [],
          Departments: (data[1]) ? data[1].sort((l, b) => l.Name.localeCompare(b.Name)) : [],
          OrgLayers: (data[2]) ? data[2].sort((l, b) => l.OrgLayerSiteDetail.Name.localeCompare(b.OrgLayerSiteDetail.Name)) : [],
          Shifts: (data[3]) ? data[3].sort((l, b) => l.Name.localeCompare(b.Name)) : [],
          DocumentSubtype: (docSubtypes) ? docSubtypes.sort((l, b) => l.Name.localeCompare(b.Name)) : [],
        };

        return filters;
      });
  }
}
