import { SortDirection } from "@angular/material";
import { TableCollectionChanges } from "@core/models/table";

export interface FetchOrganization {
  skip: number;
  take: number;
  sort?: string;
}

export function generateMockOrgsCollectionChanges(): TableCollectionChanges {
  return {
    sortActive: "CreationDate",
    sortDirection: "desc",
    pageIndex: 0,
    pageSize: 10,
    filter: "",
  };
}

export interface FetchOrganizationsResponse {
  Result: Organization[];
  TotalRows: number;
}

export interface Organization {
  ID: number;
  Description: string;
  Name: string;
  CreationDate: string;
  IsDisabled: boolean;
  ExemptFromReports: boolean;
}

export function generateMockOrganizations(): Organization[] {
  return [
    {
      ID: 271,
      Description: "Description",
      Name: "Name",
      CreationDate: "2018-06-27T12:58:05Z",
      IsDisabled: false,
      ExemptFromReports: false,
    },
    {
      ID: 2712,
      Description: "Description2",
      Name: "Name2",
      CreationDate: "2018-06-20T07:57:45Z",
      IsDisabled: true,
      ExemptFromReports: true,
    },
  ];
}

export interface AddOrganization {
  OrganizationName: string;
  OrganizationDescription: string;
  AdminFirstName: string;
  AdminLastName: string;
  AdminEmail: string;
  FullDomain: string;
  IsSubdomainEnabled: boolean;
}

export function generateMockAddOrganization(): AddOrganization {
  return {
    OrganizationName: "Organization Name",
    OrganizationDescription: "Organization Description",
    AdminFirstName: "Admin First Name",
    AdminLastName: "Admin Last Name",
    AdminEmail: "admin@email.com",
    FullDomain: "",
    IsSubdomainEnabled: false,
  };
}

export function generateMockAddOrganizationEmpty(): AddOrganization {
  return {
    OrganizationName: "",
    OrganizationDescription: "",
    AdminFirstName: "",
    AdminLastName: "",
    AdminEmail: "",
    FullDomain: "",
    IsSubdomainEnabled: false,
  };
}

export interface AddOrganizationRequest {
  OrganizationName: string;
  OrganizationDescription: string;
  AdminFirstName: string;
  AdminLastName: string;
  AdminEmail: string;
  FullDomain: string;
  IsSubdomainDisabled: boolean;
}

export class OrganizationForm {
  Id: number;
  Name: string;
  Description: string;
  UseNewScheduler: boolean;
  IsCapaEnabled: boolean;
  IsExempt: boolean;
  ExemptFromReports: boolean;
  Subdomain: Subdomain;
  AllowSupplierLink: boolean;
  IsSupplierEnabled: boolean;
  SubscriptionLimits: SubscriptionLimit[];
  OrgAdmins: OrgAdmin[];

  constructor(data) {
    Object.assign(this, data);
  }
}

export interface OrganizationFormData {
  limits: SubscriptionLimit[];
  featureSettings: {
    IsExempt: boolean,
    ExemptFromReports: boolean,
    IsSupplierEnabled: boolean,
    AllowSupplierLink: boolean,
    IsCapaEnabled: boolean,
    UseNewScheduler: boolean,
  };
  SSOSettings: {
    Subdomain: Subdomain,
  };
}

export interface OrgAdmin {
  Id: number;
  Name: string;
  Email: string;
}

export interface SubscriptionLimit {
  Id: number;
  ComponentName: string;
  CurrentCount: number;
  Limit: number;
}

export interface Subdomain {
  ID: number;
  OrganizationID: number;
  Name: string;
  Description: string;
  IsEnabled: boolean;
  IsSSOEnabled: boolean;
  RequireSSO: boolean;
  ProviderUrl: string;
  SSOEntityID: string;
  FullDomain: string;
  ProviderKey: string;
}
