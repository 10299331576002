import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FileUploadService } from "@shared/file-upload/file-upload.service";
import { FileUploadData, FileUploadTypes, FileUploadResponse } from "@shared/file-upload/file-upload.class";
import { ImportDialogInputData, ImportResponse } from "./import-dialog.inerface";
import { DataImportExportType } from "@shared/dialogs/import-dialog/import-dialog.inerface";

@Component({
  selector: "ease-import-dialog",
  templateUrl: "./import-dialog.component.html",
})

export class ImportDialogComponent {
  @Input() dialogTitle;
  @Input() templateLink;
  @Input() formattingHelpLink;

  public importExportType: DataImportExportType;
  public importUsers: boolean = false;
  public importQuestions: boolean = false;
  public importLocations: boolean = false;
  public fileUploadData: FileUploadData;
  public apiDest: FileUploadTypes;
  public showResults: boolean = false;
  public importResults: ImportResponse[] = [];
  public isLoading: boolean = false;
  public totalTime: string = "";

  private timeStart: number = 0;
  private targetId: number;
  private subtype: number;

  constructor(
    private fileUploadService: FileUploadService,
    public dialogRef: MatDialogRef<ImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImportDialogInputData) {

    this.dialogTitle = data.dialogTitle;
    this.apiDest = data.ApiDestination;
    this.templateLink = data.templateLink;
    this.formattingHelpLink = data.formattingHelpLink;
    this.importExportType = data.importExportType;
    this.targetId = data.targetId;
    this.subtype = data.subtype;

    switch (this.importExportType) {
      case DataImportExportType.ImportQuestion:
        this.importQuestions = true;
        break;
      case DataImportExportType.ImportUser:
        this.importUsers = true;
        break;
      case DataImportExportType.ImportLocation:
        this.importLocations = true;
        break;
      default:
        break;
    }
    this.initFileUpload(this.apiDest);
  }

  public initFileUpload(apiDest: FileUploadTypes) {
    let params = "";
    let headers = [];
    const allowedFileExts = [
      "csv",
    ];

    if (this.importLocations) {
      params = `parentLocationID=${this.targetId}`;
      headers = [{ name: "locationtypeid", value: this.subtype }];
    }

    this.fileUploadService.initFileUploadData(apiDest, [], 1, allowedFileExts, params, headers)
      .subscribe((fileData) => {
        this.fileUploadData = fileData;
      });
  }

  public import() {
    this.isLoading = true;
    this.timeStart = Date.now() / 1000;
    this.fileUploadService.uploadArtifact(this.importExportType, this.fileUploadData)
      .subscribe((data: FileUploadResponse) => {
        this.importResults = data.response;
        this.showResults = true;
        this.isLoading = false;
        this.totalTime = (Date.now() / 1000 - this.timeStart).toLocaleString(undefined, {minimumFractionDigits: 2});
      });
  }

  public closeModal(refresh) {
    this.dialogRef.close(refresh);
  }
}
