// Todo: This page needs to be refactored and Api calls consolidated

import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";

import { LoggerService } from "@core/services/logger.service";
import * as moment from "moment";

import {
    IAuditConfig, IAuditScheduleResponse, IClientUser, ICompanySite, IDocumentRev, IJob, IJobType, ILocation, ILocationType, IScope,
    ISearchableJob, IUser, IUserScope, ISite, ICapaEvent,
} from "@core/models/ease-models";
import { ApiService } from "@core/services/api.service";
import { AuditConfigService } from "@core/services/common/audit-config.service";
import { CompanySiteService } from "@core/services/common/company-site.service";
import { CurrentInfoService } from "@core/services/current.service";
import { DocumentRevService } from "@core/services/document-rev.service";
import { JobService } from "@core/services/job.service";
import { LocationService } from "@core/services/location.service";
import { NotifyService } from "@core/services/notify.service";
import { ScopeService } from "@core/services/scope.service";
import { ToolsService } from "@core/services/tools.service";
import { UserService } from "@core/services/user.service";
import { AppSettingsService } from "@core/services/app-settings.service";
import { AdHocAuditService } from "./ad-hoc-audit.service";
import { Observable } from "rxjs";

@Component({
    templateUrl: "./ad-hoc-audit.component.html",
})

export class AdHocAuditComponent implements OnInit, OnDestroy {
    public loading: boolean = false;

    public jobs: IJob[];
    public locations: ILocation[];
    public docLocations: ILocation[];
    public siteLocations: ILocation[] = [];
    public allAuditDocs: IDocumentRev[];
    public locationSelectionRequired: boolean = true;
    public auditConfig: IAuditConfig[];
    public currentAuditConfig: IAuditConfig;
    public users: IUser[];
    public maxDate: Date = null;
    public minDate: Date = null;
    public searchableJobs: ISearchableJob[];
    public jobTypes: IJobType[];
    public isCreatingNewJob: boolean = false;
    public companySites: ICompanySite[] = null;
    public gracePeriodDays: number = 0;
    public isLocationSiteValid: boolean = false;
    public selectedLocationID: number = 0;
    public isCreatingAudit: boolean = false;
    public selectedDoc: IDocumentRev;
    public docName: string = "";
    public adhocEnabledSites: ISite[];
    public capaEventList$: Observable<ICapaEvent[]>;
    public isCordova: boolean = false;

    // Page status flags
    public showJobSelection: boolean = false;
    public showLocationSelection: boolean;
    public showAuditorSelection: boolean = false;
    public isDataEntry: boolean = false;
    public showCustomerSelection: boolean;
    public showSupplierSelection: boolean;
    public showAuditName: boolean;
    public showCapaEventSelection: boolean;

    // IJob info
    public SelectedJobObj: IJob;
    public NewJobCode: string;
    public NewJobDescription: string;
    public JobTypeID: number = 0;
    public JobCompanySiteID: number;
    public JobStartDate: any;
    public ResponsiblePartyID: number;

    public selectedSiteID: number = 0;
    public newAudit: any = {};
    public userScopes: IUserScope[];
    private locationTypes: ILocationType[];
    private managers: IUser[];
    private destroy$ = new Subject<void>();

    constructor(private router: Router,
                private api: ApiService,
                private notify: NotifyService,
                private log: LoggerService,
                public current: CurrentInfoService,
                private tools: ToolsService,
                private auditConfiguration: AuditConfigService,
                private route: ActivatedRoute,
                private jobService: JobService,
                private locationService: LocationService,
                private userService: UserService,
                private documentRevService: DocumentRevService,
                private scopeService: ScopeService,
                private companySiteService: CompanySiteService,
                private appSettings: AppSettingsService,
                public adhocService: AdHocAuditService) {

        this.log.debug("ad-hoc audit: constructor");
        this.loading = true;

        this.locationTypes = locationService.getLocationTypes();

    }

    public ngOnInit(): void {
      this.log.debug("ad-hoc audit: current info is ready...");
      this.init();
      this.docLocations = [];
      this.searchableJobs = [];
      this.getJobTypes();
      this.getManagersList();
      this.adhocEnabledSites = [];
      if (this.route.routeConfig.path === "audit-entry-start" || this.route.routeConfig.path === "audit/create" ||
        this.router.url.includes("enterstart") || this.router.url.includes("audit-create")) {
        this.isCreatingAudit = true;
      }
      this.log.debug("ad-hoc audit: ngOnInit");

      this.isCordova = this.tools.isCordova();
    }

    public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }

    public init(): void {
      if (this.route.routeConfig.path === "audit/enterstart" || this.router.url.includes("enterstart")) {
        this.showAuditorSelection = true;
        this.isDataEntry = true;
      }
      this.maxDate = this.current.SiteDateToday(false, null);
      this.auditConfiguration.getAuditConfig().takeUntil(this.destroy$).subscribe((data: IAuditConfig[]) => {
            this.auditConfig = data;
            this.searchableJobs = [];

            this.jobService.getJobs({}).subscribe((jobData: IJob[]) => {
                this.jobs = jobData.filter((job: IJob) => job.Status === 0);
                this.jobs = this.jobs.sort((a, b) => a.Code.toLowerCase().localeCompare(b.Code.toLowerCase()));
                this.jobs.forEach((job: IJob) => {
                  const searchableJob = {} as ISearchableJob;   // create empty object of an interface
                  searchableJob.ID = job.ID;
                  searchableJob.Code = job.Code;
                  if (job.CompanySite && job.CompanySite.Name) { searchableJob.Customer = job.CompanySite.Name; }
                  searchableJob.Description = job.Description;
                  this.searchableJobs.push(searchableJob);
                });
            });

            this.adhocEnabledSites = [];
            this.current.info.sites.forEach((site: ISite) => {
              if (site.SiteSettings.isAdHocEnabled) {
                this.adhocEnabledSites.push(site);
              }
            });

            this.locationService.getLocations({ listView: true }).subscribe((locationData: ILocation[]) => {
                this.locations = locationData;
                if (this.showAuditorSelection) {
                  this.selectedSiteID = this.current.info.activeSiteID;
                  this.onSiteChanged(); // load doc locations
                }
            });

            this.documentRevService.getDocumentRevs({ details: true, liveDocsOnly: true, includeDocLocations: true, documentTypeID: 1 }).subscribe((docRevData: IDocumentRev[]) => {
                if (docRevData && docRevData.length > 0) {
                  this.allAuditDocs = docRevData.sort((a, b) => a.Document.Name.toLowerCase().localeCompare(b.Document.Name.toLowerCase()));
                }
                this.loading = false;
            });

            const auditorID = this.current.info.user.ID as any;

            this.userService.getUser({ id: auditorID, details: false, UserScope: true }).subscribe((userData: IUser) => {
                this.userScopes = userData.UserScopes;
            });

            if (this.showAuditorSelection) {
                this.userService.getUsers({ filteredRoles: "Auditor" }).subscribe((auditorData: IClientUser[]) => {
                    this.users = [];
                    auditorData.forEach((user: IClientUser) => {
                      user.FullName = user.FirstName + " " + user.LastName;
                      this.users.push(user);
                    });
                    this.users = this.users.sort((a, b) => a.FullName.toLowerCase().localeCompare(b.FullName.toLowerCase()));
                });
            }
        });

    }

    onSiteChanged(): void {
      if (!this.showAuditorSelection) {
        this.selectedSiteID = this.newAudit.selectedSite.ID;
      }
      this.newAudit.selectedDoc = null;
      this.newAudit.selectedDocRev = null;
      this.newAudit.selectedLocation = null;
      this.newAudit.selectedLocationID = 0;
      this.allAuditDocs = [];
      this.documentRevService.getDocumentRevs({ details: true, liveDocsOnly: true, includeDocLocations: true, documentTypeID: 1 ,
                                                selectedSiteID: this.selectedSiteID}).subscribe((data: IDocumentRev[]) => {
            if (data && data.length > 0) {
                this.allAuditDocs = data;
                this.allAuditDocs = this.allAuditDocs.sort((a, b) => a.Document.Name.toLowerCase().localeCompare(b.Document.Name.toLowerCase()));
            }
            this.siteLocations = [];
            this.locations.forEach((siteLoc: ILocation) => {
              if (siteLoc.SiteID != null && siteLoc.SiteID === this.selectedSiteID) {
                this.siteLocations.push(siteLoc);
              }
            });
            this.loading = false;
        });
    }

    onDocChanged(): void {
        this.showAuditName = true;
        this.docName = this.newAudit.selectedDocRev.Document.Name;

        // Change layout based on select doc type, userscope
        const auditCategory = this.newAudit.selectedDocRev.DocumentSubtype;
        const config = this.auditConfig.filter((auditConfig: IAuditConfig) => auditConfig.AuditCategoryID === auditCategory);
        this.currentAuditConfig = (config.length !== 0) ? config[0] : null;
        this.isCreatingNewJob = false;

        if (this.currentAuditConfig != null) {
            // for now, Job selection feature is disabled.
            // this.showJobSelection = this.currentAuditConfig.AuditTargets.indexOf(2) > -1;
            this.showLocationSelection = this.currentAuditConfig.AuditTargets.indexOf(1) > -1;
            this.showCustomerSelection = this.currentAuditConfig.AuditTargets.indexOf(4) > -1;
            this.showSupplierSelection = this.currentAuditConfig.AuditTargets.indexOf(5) > -1 && this.current.info.org.IsSupplierEnabled;
            this.showCapaEventSelection = this.currentAuditConfig.AuditTargets.indexOf(6) > -1;
            this.getCompanySite();

            if (this.showCapaEventSelection) {
              this.getCapaEvents();
            }
        }

        const scopeID: number = this.newAudit.selectedDocRev.Document.ScopeID;
        let locationTierLevels = this.newAudit.selectedDocRev.Document.LocationTierLevels;
        if (locationTierLevels == null) { locationTierLevels = ""; }

        // Only show location types (tiers) that are assocated with the selected template
        this.locationTypes.forEach((tier: ILocationType) => {
          tier.visible = (locationTierLevels.indexOf(tier.id) > -1);
          tier.selected = false;
        });
        this.docLocations.length = 0;

        this.scopeService.getScopeById(scopeID).subscribe((scope: IScope) => {
            if (scope) {
              scope.Locations.forEach((scopeLoc: ILocation) => {
                this.siteLocations.forEach((loc: ILocation) => {
                  if (locationTierLevels.indexOf(loc.LocationTypeID) > -1 && (loc.TreeLeft >= scopeLoc.TreeLeft) && (loc.TreeRight <= scopeLoc.TreeRight)) {
                    // now cover userscope
                    let userLocScope: boolean = false;
                    this.userScopes.forEach((userScope) => {
                      if (loc.TreeLeft >= userScope.TreeLeft && loc.TreeRight <= userScope.TreeRight) {
                        userLocScope = true;
                      }
                    });
                    if (userLocScope) {
                      this.docLocations.push(loc);
                    }
                  }
                });
              });
              this.docLocations = this.docLocations.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
              if (this.docLocations.length === 0 && this.locationSelectionRequired) {
                    this.isLocationSiteValid = false;
                }
            } else {
                // failed
                this.docLocations.length = 0;
            }
        });
    }

    public isDateValid(): boolean {
      let valid = false;
      if (this.newAudit != null && this.newAudit.selectedCompletionDate != null && moment(this.newAudit.selectedCompletionDate).isValid()) {
        valid = true;
      }
      return valid;
    }

    public createAudit(): void {
      this.notify.broadcast("full-page-block", true);
      let jobCodeInvalid: boolean = false;
      // check to see the job already exist to avoid the FK constraint error
      if (this.isCreatingNewJob === true) {
        for (let counter = 0; counter <= this.jobs.length - 1; counter++) {
          const job: IJob = this.jobs[counter];

          if (job.Code.toUpperCase() === this.SelectedJobObj.Code.toUpperCase()) {
            jobCodeInvalid = true;
            break;
          }
        }
      }

      if (jobCodeInvalid === true) {
        this.tools.showErrorWarningDialog("_JobCodeInvalid_", "_TheJobCodeEnteredMustBeUnique_", () => { });
        this.notify.broadcast("full-page-block", false);
      } else {
        if (this.isCreatingNewJob && this.SelectedJobObj) {
          this.NewJobCode = this.SelectedJobObj.Code;
          this.NewJobDescription = this.SelectedJobObj.Description;
          this.JobTypeID = this.SelectedJobObj.JobTypeID;
          this.JobCompanySiteID = this.SelectedJobObj.CompanySiteID;
          this.JobStartDate = this.SelectedJobObj.StartDate;
          this.ResponsiblePartyID = this.SelectedJobObj.ResponsiblePartyID;
        }
        this.saveAudit();
      }
    }

    public cancel(): void {
      this.router.navigate(["/"]); // for now navigate to home. but update this to navigate to audit list
    }

    private onLocChange(): void {
      if (this.showJobSelection) { // this.AppSettings.AppSettings().isJobFunctionEnabled add this to condition too after porting AppSettings
            if (!this.showAuditorSelection) {
              this.selectedSiteID = this.newAudit.selectedSite.ID;
            }
            this.selectedLocationID = this.newAudit.selectedLocationID;
            const selectedLocation = this.docLocations.filter((loc: ILocation) => loc.ID == this.selectedLocationID) ;
            if (selectedLocation.length > 0 && this.selectedSiteID === selectedLocation[0].SiteID) {
                this.isLocationSiteValid = true;
            } else {
                this.isLocationSiteValid = false;
            }
        } else {
            this.isLocationSiteValid = true;
        }

    }

    private getCompanySite() {
        let listType: string = "customer";
        if (this.showSupplierSelection) {
            listType = "supplier";
        }
        this.companySiteService.getCompanySites({ detail: true, listType: listType }).subscribe((sites: ICompanySite[]) => {
          this.companySites = sites.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
        });
    }

    private getCapaEvents() {
      this.capaEventList$ = this.adhocService.getClosedCapaEvents();
    }

    private getJobTypes(): void {
        this.jobService.getJobTypes().subscribe((data: IJobType[]) => {
            this.jobTypes = data;
        });

    }

    private getManagersList(): void {
        this.userService.getUsers({ filteredRoles: "Manager" }).subscribe((data: IUser[]) => {
            this.managers = data;
        });
    }

    private onLocationTierChanged(): void {
        const filtered = this.locationTypes.filter((locationType: ILocationType) => locationType.selected);
        this.locationSelectionRequired =  (filtered.length === 0);
    }

    private saveAudit(): void {
        const docID: number = (this.newAudit.selectedDocRev.DocumentID);
        const locationID: number = this.newAudit.selectedLocationID;
        const tiers: number[] = [];

        this.locationTypes.forEach((loc) => {
            if (loc.selected) {
                tiers.push(loc.id);
            }
        });
        const locationTierLevels = tiers.join(",");

        const seriesStartDate = this.current.SiteDateInUTC(this.newAudit.selectedCompletionDate); // convert selected date based on site's timezone
        const auditorID = this.current.info.user.ID;
        const title = this.docName;

        if (docID > 0) {
            const auditSchedule: any = {
                UserID: auditorID,
                DocumentID: docID, // Schedule against the doc
                Description: title,
                AuditorUserID: auditorID,
                DueDate: new Date(),
                LocationID: locationID,
                SelectedCompanySiteID: this.newAudit.selectedCompanySiteID,
                CapaEventID: this.newAudit.CapaEventID,
                CalendarSeries: {
                    SeriesStart: new Date(),
                    AllDays: false,
                    CalendarID: 0,
                    Description: title,
                    EventType: 3,
                    Length: 1,
                    Scale: 1,
                    SeriesRepeatInterval: 0,
                    SeriesRepeatLimitCount: null,
                    SeriesRepeatLimitDate: null,
                    SeriesRepeatScale: 0,
                },
                Job: {
                    ID: (this.SelectedJobObj != null) ? this.SelectedJobObj.ID : 0,
                    Code: this.NewJobCode,
                    Description: this.NewJobDescription,
                    JobTypeID: this.JobTypeID,
                    CompanySiteID: this.JobCompanySiteID,
                    StartDate: this.JobStartDate,
                    ResponsiblePartyID: this.ResponsiblePartyID,
                },
                GracePeriodDays: this.gracePeriodDays,
            };
            const isDataEntry: boolean = this.showAuditorSelection;
            this.loading = true;
            this.adhocService.saveAdHocAuditSchedule(auditSchedule, this.newAudit.selectedAuditorID, seriesStartDate.toUTCString(),
              locationTierLevels, isDataEntry, this.selectedSiteID).subscribe((data: IAuditScheduleResponse) => {
              if (data) {
                const auditID = data.AuditID;
                if (isDataEntry) {
                  this.router.navigate(["./conduct", auditID], { relativeTo: this.route });
                } else {
                  this.router.navigate(["./conduct", auditID], { relativeTo: this.route, queryParams: { isFromAdHocAudits: true, selectedSiteID: this.newAudit.selectedSite.ID } });
                }
                this.notify.broadcast("full-page-block", false);
              }
            }, null, () => {
                this.loading = false;
            });
        }
    }

}
