import { Component, Input, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MitigationDetailService, ArtifactService } from "../../../core/services/common/index";
import { ApiService, CurrentInfoService, StorageService, ToolsService } from "../../../core/services/index";
import { ConfirmationDialogService } from "../../confirmation/confirmation-dialog.service";

import {
  IAuditSubtype,
  ICustomCategory,
  ICustomCategoryLineItem,
  ICustomItem,
  IFileFormat,
  ILocation,
  IMitigationActivity,
  IMitigationDetail,
  IRank,
  IUser,
} from "../../../core/models/ease-models";

import { Observable, ReplaySubject } from "rxjs";

class EaseMitigationUploaderObject {
  public mitigationID: number;
  public uploader: any;
}

@Component({
  selector: "ease-mitigation-detail",
  templateUrl: "./mitigation-detail.component.html",
})

export class MitigationDetailComponent implements OnInit {
  loading;

  @Input() public selectedMitigationID: number;
  @Input() public hideDelete: boolean = false;
  public isAdhocMitigation: boolean;
  public instance: MitigationDetailComponent;

  public isRejection: boolean = false;
  public locations: ILocation[];
  public creator: IUser;
  public mitigation: IMitigationActivity;
  public mitigationDetail: IMitigationDetail;
  public ranks: IRank[];
  public current: any;
  /*ICurrentModels;*/
  public isRankVisible: boolean = false;
  public managers: IUser[];
  public responsibleParties: any[];
  public isCountermeasuresEnabled: boolean = false;
  public isManagerRole: boolean = false;
  public customItems: ICustomItem[];
  public uploaderObjs: EaseMitigationUploaderObject[];
  public filteredUploaderObjs: EaseMitigationUploaderObject[];
  public fileFormats: IFileFormat[];
  public mitigationID: number = null;
  public failureCategories: ICustomCategory[];
  public failureModes: ICustomItem[];
  public filteredFailureModes: ICustomItem[];
  public auditSubtypes: IAuditSubtype[];
  public selectedAuditCategoryName: string;
  public location: ILocation = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private currentInfo: CurrentInfoService,
              private api: ApiService,
              private tools: ToolsService,
              private zone: NgZone,
              private storage: StorageService,
              private mitigationDetailService: MitigationDetailService,
              private artifactService: ArtifactService,
              private confirm: ConfirmationDialogService) {
    this.instance = this;
  }

  public ngOnInit() {
    this.mitigationID = (this.selectedMitigationID) ? this.selectedMitigationID : parseInt(this.route.snapshot.params.id, 10);
    this.current = this.currentInfo;
    this.initMitigation();
  }

  public filteredFiles(uploaderObjs: any[]): any[] {
    const filtered = uploaderObjs.filter((uploader: any) => uploader.mitigationID === this.mitigationID);
    return filtered;
  }

  public initMitigation() {
    this.mitigationDetailService.getMitigationDetailByID(this.mitigationID, "Mitigations", false, true).subscribe((data: IMitigationDetail) => {
      this.mitigationDetailService.isMitigationOpened = data.Mitigation.Status === 0;
      this.zone.run(() => {
        if (data) {
          this.location = data.Mitigation.Location;
          this.auditSubtypes = data.AuditQuestionCategory;
          this.getFailureCategories(data.FailureCategoriesCustomCategoryLineItems);

          this.mitigationDetail = data;
          this.mitigationDetail.Mitigation.uploadedFiles = [];
          this.mitigationDetail.MitigationArtifacts.forEach((ma: any) => {
            this.zone.run(() => {
              const artifact = this.mitigationDetail.Artifacts.filter(a => a.ID === ma.ArtifactID);
              if (artifact.length > 0) {
                const img: any = {
                  ID: ma.ID,
                  MitigationID: ma.MitigationID,
                  ArtifactID: artifact[0].ID,
                  Url: artifact[0].Url,
                  Filename: artifact[0].FileName,
                  FileFormatID: artifact[0].FileFormatID,
                };
                this.mitigationDetail.Mitigation.uploadedFiles.push({
                  ID: ma.ID,
                  MitigationID: ma.MitigationID,
                  ArtifactID: artifact[0].ID,
                  Url: this.setImageUrl(img),
                  Filename: artifact[0].FileName,
                  FileFormatID: artifact[0].FileFormatID,
                });
              }
            });
          });

          if (this.mitigationDetail.Mitigation.ResponsiblePartyUser !== null) {
            this.mitigationDetail.Mitigation.ResponsbilePartyFullName =
              this.mitigationDetail.Mitigation.ResponsiblePartyUser.FirstName
                + " " + this.mitigationDetail.Mitigation.ResponsiblePartyUser.LastName
          }

          this.mitigation = this.mitigationDetail.Mitigation;
          if (this.mitigation.MitigationTypeID != null) {
            const auditCategory = this.getAuditCategoryByID(this.mitigation.MitigationTypeID);
            if (auditCategory != null) {
              this.selectedAuditCategoryName = auditCategory.Name;
            }
          }

          // get issue classification's custom category and custom item
          if (this.mitigation.IssueClassification) {
            const customCategory = data.FailureCategoriesCustomCategoryLineItems.filter(x => x.CustomCategoryID === this.mitigation.IssueClassification.FailureCategoryID);
            if (customCategory.length > 0) {
              this.mitigation.IssueClassification.CustomCategory = customCategory[0].CustomCategory;
            }

            const customItem = data.FailureCategoriesCustomCategoryLineItems.filter(x => x.CustomItemID === this.mitigation.IssueClassification.FailureModeID);
            if (customItem.length > 0) {
              this.mitigation.IssueClassification.CustomItem = customItem[0].CustomItem;
            }
          }

        }
      });
    });

    this.current = this.currentInfo;
  }

  public allowFailureModeEdit(id: number): boolean {
    let allowEdit = false;
    const current = this.current.info;
    let isApprovingParty = false;
    let isMitigationOpen = false;
    if (this.mitigation != null) {
      if (current.user) isApprovingParty = current.user.ID === this.mitigation.ApprovingManagerUserID;

      isMitigationOpen = this.mitigation.Status === 0;
    }
    if ((current.roleIsSiteAdmin || current.isManagerRole || isApprovingParty) && isMitigationOpen) {
      allowEdit = true;
    }
    return allowEdit;
  }

  public getFailureCategories(customCategoryLineItem: ICustomCategoryLineItem[]): void {
    const categories: ICustomCategory[] = [];
    const items: ICustomItem[] = [];

    customCategoryLineItem.forEach((li: ICustomCategoryLineItem) => {
      const filtered = categories.filter((cat: ICustomCategory) => cat.ID === li.CustomCategoryID);
      if (filtered.length === 0) {
        categories.push(li.CustomCategory);
      }

      li.CustomItem.CustomCategoryID = li.CustomCategoryID;
      li.CustomItem.CustomCategory = li.CustomCategory;
      items.push(li.CustomItem);
    });

    this.failureModes = items;
    this.filteredFailureModes = items;
    this.failureCategories = categories;
  }

  public filterModesByCategory(catID: number): void {
    this.filteredFailureModes = this.failureModes.filter((mode: ICustomItem) => mode.CustomCategoryID === catID);
  }

  public deleteMitigation(): void {

    this.confirm.open("_DeleteMitigation_", "_AreYouSureDeleteMitigation_", true, "_Delete_", "_Cancel_");
    this.confirm.getResponse().first().subscribe((response) => {

      if (response === true) {
        this.api.Delete("Mitigation", this.mitigationID, (data: any) => {
          this.router.navigate(["/home"]);
        });
      }
    });
  }

  private getAuditCategoryByID(id: number): IAuditSubtype {
    const filtered: IAuditSubtype[] = this.auditSubtypes.filter(auditSubtype => auditSubtype.ID === id);
    return (filtered !== null && filtered.length !== 0) ? filtered[0] : null;
  }

  private setImageUrl(img: any): string {
    let url = this.artifactService.getImageUrl(img);

    if (!this.storage.isDoc(img.FileFormatID) && this.tools.isApp()) {
      const artifactKey = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", img.ID);
      url = cordova.file.dataDirectory + this.storage.keyAsString(artifactKey, null, img.FileFormatID);
    }

    return url;
  }
}
