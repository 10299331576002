import { ApiService } from "../../core/services/api.service";
import { LocalService } from "../../core/services/local.service";
import { LocalizeService } from "../../core/services/localize.service";

import { ActivatedRoute } from "@angular/router";
import * as FileSaver from "file-saver";
import { NotifyService } from "../../core/services/notify.service";

export enum CSVExportPage {
    AllAudits, AllMitigations, OpenMitigations, CapaEvents,
}

// Just a set of shared helper functions for csv export pages (All audits, All Mitigations, Open Mitigations)
export class CSVExportManager {

    constructor(private api: ApiService, private localize: LocalizeService,
                private local: LocalService, private notify: NotifyService,
                private route: ActivatedRoute, private filteredObjs: any, private visibleColumnNames: any) {

        this.filteredObjs = filteredObjs;
        this.visibleColumnNames = visibleColumnNames;
    }

    public exportCSV(startDate?: Date, endDate?: Date, documentType?: string): void {
        let pageName: string = "";
        let csvExportType: CSVExportPage;
        if (documentType) {

            switch (documentType) {
                case "all-audits":
                  pageName = documentType;
                  csvExportType = CSVExportPage.AllAudits;
                  break;
                case "all-mitigations":
                  pageName = documentType;
                  csvExportType = CSVExportPage.AllMitigations;
                  break;
                case "open-mitigations":
                  pageName = documentType;
                  csvExportType = CSVExportPage.OpenMitigations;
                  break;
                case "capa-list":
                    pageName = "corrective-actions";
                    csvExportType = CSVExportPage.CapaEvents;
                    break;
            }
        }

        const localLang = this.local.getItem("apiLanguage");
        const lang =  localLang ? localLang : null ;
        const query = lang ? { pageName: pageName, CSVExportPage: csvExportType, visibleColumnNames: this.visibleColumnNames, startDate: startDate, endDate: endDate, language: lang } : { pageName: pageName, CSVExportPage: csvExportType, visibleColumnNames: this.visibleColumnNames, startDate: startDate, endDate: endDate };
        this.notify.broadcast("full-page-block", true);
        this.api.AddX("CSVExport", this.filteredObjs, query,  (data: any) => {
            this.downloadFile(data);
        }, (data: any) => {
            this.notify.broadcast("full-page-block", false); //in case of error hide the spinner
        });
    }

    public downloadFile(data: any) {
        if (data && data.FileData) {
            // base64 string
            const base64str: string = data.FileData;
            // decode base64 string, remove space for IE compatibility
            const binary = atob(base64str.replace(/\s/g, ""));
            // get binary length
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            // create 8-bit Array
            const view = new Uint8Array(buffer);
            // save unicode of binary data into 8-bit Array
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "text/csv" });
            FileSaver.saveAs(blob, data.FileName);
        }
        this.notify.broadcast("full-page-block", false);
    }

}
