import { Observable,  of } from "rxjs";
import { tap, map, switchMap } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { ActivatedRoute, CanActivate } from "@angular/router";

// State
import { Store } from "@ngrx/store";

import {
  SSOLoginRedirect,
} from "../actions/auth";
import * as fromAuth from "../reducers";

// Services
import { AuthService } from "./auth.service";
import { ToolsService } from "@core/services";

@Injectable()
export class SSOGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>,
              private route: ActivatedRoute,
              private auth: AuthService,
              private tools: ToolsService) {
  }

  canActivate(): Observable<boolean> {
    return of(/ssotp=.+/.test(window.location.href)).pipe(
      switchMap(v => {
        if (v || this.tools.isApp()) {
          return of(true);
        } else {
          return this.auth.isSSOActiveHost().pipe(
            tap(isActive => {
              if (isActive) {
                this.store.dispatch(new SSOLoginRedirect());
              }
            }),
            map(isActive => !isActive),
          );
        }
      }),
    );
  }
}
