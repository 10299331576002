import { Component, Input, OnInit } from "@angular/core";

import { select, Store } from "@ngrx/store";
import * as fromGroupDetails from "app/admin/permission-groups/reducers/index";

// Components
import { BreadcrumbComponent } from "../breadcrumb.component";

// Services
import { BreadcrumbsService } from "../breadcrumbs.service";

@Component({
  selector: "ease-breadcrumb-permission",
  template: `
    <ease-breadcrumb-base-item
      [loading]="!(name$ | async)"
      [placeholder]="placeholder"
      [ngClass]="{'breadcrumb-loading': !(name$ | async)}">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
            {{ (name$ | async) }}
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbPermissionComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  name$ = this.store.pipe(select(fromGroupDetails.getPermissionGroupName));
  placeholder = "_Breadcrumb:RoleLoading_";

  constructor(
    private breadcrumbs: BreadcrumbsService,
    private store: Store<fromGroupDetails.State>,
  ) {}

  ngOnInit() {

  }
}
