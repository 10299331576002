import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

import { ReplaySubject } from "rxjs";
import { IJob, IJobType } from "../models/ease-models";

@Injectable()
export class JobService {
    public jobTypes: IJobType[];
    constructor(private api: ApiService) {

    }

    public getJobs(query: any) {
        const result = new ReplaySubject<any>(1);
        this.api.Query("Job", query, (data: IJob[]) => {

            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                console.log("no jobs found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getJobTypes() {
        const result = new ReplaySubject<any>(1);
        this.api.Query("JobType", {}, (data: IJobType[]) => {
            if (data && data.length > 0) {
                result.next(data);
                result.complete();
            } else {
                console.log("no job types found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }
}