// Angular node modules
import { Component, OnInit, OnDestroy } from "@angular/core";

// Services
import {ArtifactService} from "../../../core/services/common/artifact.service";
import {NotifyService} from "../../../core/services/notify.service";
// Components

// Models
import { IOrgArtifact } from "../../../core/models/ease-models";
// Reactive components
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "ease-capa-print",
    templateUrl: "./capa-print.component.html",
    styleUrls: ["./capa-print.component.scss"],
})
export class CapaPrintComponent implements OnInit, OnDestroy {
    destroy$ = new Subject();
    public logoData: string;

    constructor(private artifactService: ArtifactService,
                private notify: NotifyService) {
        this.notify.broadcast("hide-top-nav", true);
        this.notify.broadcast("hide-left-nav", true);
        this.notify.broadcast("print-window-on", true);
    }

    ngOnInit() {
        this.getLogoData();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    private getLogoData() {
        this.artifactService.getOrganizationArtifact().pipe(takeUntil(this.destroy$))
            .subscribe((orgArtifact: IOrgArtifact) => {
                this.logoData = orgArtifact ? orgArtifact.Url : null;
            });
    }
}