import { NgModule } from "@angular/core";
// Modules
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { LocationTreeModule } from "@shared/location-tree/location-tree.module";
// Services
import { DialogManagerService } from "./dialog-manager.service";
// Components
import { AuditListComponent } from "./audit-list/audit-list.component";
import { AuditListModalComponent } from "./audit-list/audit-list-modal.component";
import { DetailedImageModalComponent } from "./detailed-image-modal.component";
import { AuditPrintDetailComponent } from "./audit-print/audit-print-detail.component";
import { AuditFiltersComponent } from "@app/results/audit-filters/audit-filters.component";

const COMPONENTS = [
  AuditListComponent,
  AuditListModalComponent,
  DetailedImageModalComponent,
  AuditPrintDetailComponent,
  AuditFiltersComponent,

];

const MODAL_COMPONENTS = [
  AuditListModalComponent,
  DetailedImageModalComponent,
];

@NgModule({
  imports: [
    SharedModule,
    LocationTreeModule,
    RouterModule.forChild([
      // {
      //     path: "results/audit/detail/:id",
      //     component: AuditDetailComponent,
      //     canActivate: [ AuthGuard ],
      //     data: { stripType: "audit-management" },
      // },
      // {
      //     path: "results/audit/print/:id",
      //     component: AuditPrintDetailComponent,
      //     canActivate: [ AuthGuard ],
      //     data: { stripType: "" },
      // },
      // {
      //   path: "results/audit/:id",
      //   component: AuditResultComponent,
      //   canActivate: [ AuthGuard ],
      //   data: { stripType: "audit-management" },
      // },
    ]),
  ],

  exports: [
    ...COMPONENTS,
  ],

  declarations: [
    ...COMPONENTS,
  ],

  providers: [
    DialogManagerService,
  ],

  entryComponents: [
    ...MODAL_COMPONENTS,
  ],
})

export class ResultsModule {
  // This module will be used by Conduct, Schedule and Report feature modules
  // Audit Details, Audit Results, Audit List all belong here
}
