import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

// Components
import { BreadcrumbComponent } from "../breadcrumb.component";

// Services
import { CurrentInfoService } from "@core/services";
import { BreadcrumbsService } from "@core/components/breadcrumbs/breadcrumbs.service";

@Component({
  selector: "ease-breadcrumb-site-calendar",
  template: `
    <ease-breadcrumb-base-item [loading]="false">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
         {{ '_BreadcrumbCalendar_' | i18n }}
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbSiteCalendarComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  details$: Observable<object>;
  user;
  placeholder = "_BreadcrumbCalendarLoading_";

  constructor(
    private router: Router,
    private breadcrumbs: BreadcrumbsService,
  ) {}

  ngOnInit() {
    const params = this.getRouteParams();
    this.breadcrumbs.getLocationDetails(params.id, params.type).subscribe();
  }

  getRouteParams() {
    let root = this.router.routerState.snapshot.root;
    let params;
    while (root) {
      if (root.children && root.children.length) {
        root = root.children[0];
        params = root.params;
      } else {
        return params;
      }
    }
  }
}
