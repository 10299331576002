// RxJS
import { zip } from "rxjs";
import { takeWhile, tap, pluck, map, take, filter } from "rxjs/operators";

// Angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "../reducers";
import {
  HomeRedirect,
  SysAdminRedirect,
  Authenticate,
  AuthenticateSSO,
} from "../actions/auth";

// Models
import { AuthenticationPayload } from "../models/user";
import { AuthenticationErrorCodes } from "../models/api";
import { ApiError } from "@core/models/api";

@Component({
  selector: "ease-login-page",
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
      <div fxFlex>
        <ease-auth-page>
          <ease-sso-login *ngIf="ssotp$ | async; else elseBlock"
                          [pending]="pending$ | async"
                          [message]="message$ | async"
                          [errorMessage]="error$ | async">
          </ease-sso-login>

          <ng-template #elseBlock>
            <ease-login-form
              (submitted)="onSubmit($event)"
              [login]="login$ | async"
              [rememberMe]="rememberMe$ | async"
              [pending]="pending$ | async"
              [message]="message$ | async"
              [errorMessage]="error$ | async">
            </ease-login-form>
          </ng-template>
        </ease-auth-page>
      </div>
      <div fxFlex="40%" fxHide.lt-sm>
          <rss-feed></rss-feed>
      </div>
    </div>`,
  styles: [ `
  ` ],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  error$ = this.store.pipe(
    select(fromAuth.getLoginPageError),
    map(this.errorsMap.bind(this)),
  );
  message$ = this.store.pipe(select(fromAuth.getLoginPageMessage));
  login$ = this.store.pipe(select(fromAuth.getLogin));
  rememberMe$ = this.store.pipe(select(fromAuth.getRememberMe));
  ssotp$ = this.route.paramMap.pipe(
    pluck("params", "ssotp"),
    tap(ssotp => {
        if (ssotp) {
          this.ssoLogin(ssotp);
        }
      },
    ),
  );

  alive = true;

  constructor(private store: Store<fromAuth.State>,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    zip(
      this.store.pipe(
        select(fromAuth.getLoggedIn),
        filter(loggedIn => loggedIn),
      ),
      this.store.pipe(select(fromAuth.getRoleIsSysAdmin)),
    ).pipe(
      takeWhile(_ => this.alive),
      take(1),
    ).subscribe(([_, isSysAdmin]) => {
      if (isSysAdmin) {
        this.store.dispatch(new SysAdminRedirect());
      } else {
        this.store.dispatch(new HomeRedirect());
      }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onSubmit($event: AuthenticationPayload) {
    this.store.dispatch(new Authenticate($event));
  }

  private ssoLogin(ssotp) {
    this.store.dispatch(new AuthenticateSSO({ Token: ssotp }));
  }

  private errorsMap(error: ApiError) {
    if (!error) {
      return null;
    }

    switch (error.Code) {
      case AuthenticationErrorCodes.Unknown:
        return "_Auth:LoginPage:AuthenticationErrorUnknown";

      case AuthenticationErrorCodes.BadCredentials:
        return "_Auth:LoginPage:AuthenticationErrorBadCredentials_";

      case AuthenticationErrorCodes.UserLocked:
        return "_Auth:LoginPage:AuthenticationErrorUserLocked_";

      case AuthenticationErrorCodes.UserDisabled:
        return "_Auth:LoginPage:AuthenticationErrorUserDisabled_";

      case AuthenticationErrorCodes.UserDeleted:
        return "_Auth:LoginPage:AuthenticationErrorUserDeleted_";

      case AuthenticationErrorCodes.SingleSignOnRequired:
        return "_Auth:LoginPage:AuthenticationErrorSingleSignOnRequired_";

      case AuthenticationErrorCodes.TokenNotValid:
        return "_Auth:LoginPage:AuthenticationErrorTokenNotValid_";

      default:
        return "_Auth:LoginPage:AuthenticationError_";
    }
  }
}
