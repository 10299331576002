import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { ApiService } from "../../core/services/api.service";
import {SoftwareVersion} from "./release-notes-modal.model";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ReleaseNotesModalService {

  constructor(private http: HttpClient,
              private api: ApiService) {}


  public getSoftwareVersionInfo(): Observable<SoftwareVersion[]> {
    return this.http.get<SoftwareVersion[]>("/softwareversion?all=true");
  }
  public getThisSoftwareVersion(id: number): Observable<SoftwareVersion> {
    return this.http.get<SoftwareVersion>("/softwareversion/" + id);
  }
}
