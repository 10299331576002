import { Action } from "@ngrx/store";

// Models
import {
  Release,
} from "../models/release";
import { ApiError } from "@core/models/api";

export enum ReleaseDetailActionTypes {
  Load = "[Release] Load",
  LoadSuccess = "[Release] Load Success",
  LoadFail = "[Release] Load Fail",
  Create = "[Release] Create",
  CreateSuccess = "[Release] Create Success",
  CreateFail = "[Release] Create Fail",
  Update = "[Release] Update",
  UpdateSuccess = "[Release] Update Success",
  UpdateFail = "[Release] Update Fail",
  Delete = "[Release] Delete",
  DeleteSuccess = "[Release] Delete Success",
  DeleteFail = "[Release] Delete Fail",
  Clear = "[Release] Clear",
}

// Load
export class Load implements Action {
  readonly type = ReleaseDetailActionTypes.Load;

  constructor(public payload: any) {
  }
}

export class LoadSuccess implements Action {
  readonly type = ReleaseDetailActionTypes.LoadSuccess;

  constructor(public payload: Release) {
  }
}

export class LoadFail implements Action {
  readonly type = ReleaseDetailActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

// Create
export class Create implements Action {
  readonly type = ReleaseDetailActionTypes.Create;

  constructor(public payload: Release) {}
}

export class CreateSuccess implements Action {
  readonly type = ReleaseDetailActionTypes.CreateSuccess;

  constructor(public payload: any) {}
}

export class CreateFail implements Action {
  readonly type = ReleaseDetailActionTypes.CreateFail;

  constructor(public payload: any) {}
}

// Update
export class Update implements Action {
  readonly type = ReleaseDetailActionTypes.Update;

  constructor(public payload: Release) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = ReleaseDetailActionTypes.UpdateSuccess;

  constructor(public payload: any) {}
}

export class UpdateFail implements Action {
  readonly type = ReleaseDetailActionTypes.UpdateFail;

  constructor(public payload: ApiError[]) {}
}

// Delete
export class Delete implements Action {
  readonly type = ReleaseDetailActionTypes.Delete;

  constructor(public payload: string) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = ReleaseDetailActionTypes.DeleteSuccess;

  constructor(public payload: any) {}
}

export class DeleteFail implements Action {
  readonly type = ReleaseDetailActionTypes.DeleteFail;

  constructor(public payload: ApiError[]) {}
}

// Clear
export class Clear implements Action {
  readonly type = ReleaseDetailActionTypes.Clear;
}

export type ReleaseDetailActionsUnion =
  | Load
  | LoadSuccess
  | LoadFail
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Clear;
