import { Action } from "@ngrx/store";
import { PermissionGroup } from "../models/permission-group";

export enum PermissionGroupActionTypes {
  Load = "[Permission Group] Load",
  Select = "[Permission Group] Select",
}

export class Load implements Action {
  readonly type = PermissionGroupActionTypes.Load;

  constructor(public payload: PermissionGroup) {}
}

export class Select implements Action {
  readonly type = PermissionGroupActionTypes.Select;

  constructor(public payload: any) {}
}

export type PermissionGroupActionUnion =
  | Load
  | Select;
