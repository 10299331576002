import { FileUploadTypes } from "@shared/file-upload/file-upload.class";

export enum DataImportExportType { None, AuditDocument, Audit, ImportUser, ImportLocation, ImportQuestion }

export interface ImportDialogInputData {
  dialogTitle: string;
  templateLink: string;
  formattingHelpLink: string;
  ApiDestination: FileUploadTypes;
  importExportType: DataImportExportType;
  targetId?: number;
  subtype?: number;
}

export interface ImportResponse {
  Counter: number;
  Email: string;
  Description: string;
  Status: boolean;
  Message: string;
}
