import { NgModule } from "@angular/core";

// Modules
import { FileUploadModule } from "ng2-file-upload/ng2-file-upload";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";

// Components
import { AdHocAuditComponent } from "./conduct-ad-hoc/ad-hoc-audit.component";
import { AuditCompleteComponent } from "./conduct-audit/audit-complete.component";
import { AuditConductComponent } from "./conduct-audit/audit-conduct.component";
import { AuditConfirmComponent } from "./conduct-audit/audit-confirm.component";
import { InAuditMitigationComponent } from "./conduct-audit/in-audit-mitigation.component";
import { MultipleChoiceLayoutComponent } from "./conduct-audit/multiple-choice-layout.component";
import { NumericRatingComponent } from "./conduct-audit/numeric-rating.component";
import { SingleGroupPerPageLayoutComponent } from "./conduct-audit/single-group-per-page-layout.component";
import { SingleQuestionPerPageLayoutComponent } from "./conduct-audit/single-question-per-page-layout.component";
import { AuditPrintComponent } from "./conduct-audit-print/audit-print.component";
import { AdHocAuditService } from "./conduct-ad-hoc/ad-hoc-audit.service";
import { AuditReferencesComponent } from "./conduct-audit-references/audit-references.component";

@NgModule({
  declarations: [
    AdHocAuditComponent,
    AuditCompleteComponent,
    AuditConductComponent,
    SingleQuestionPerPageLayoutComponent,
    AuditConfirmComponent,
    InAuditMitigationComponent,
    MultipleChoiceLayoutComponent,
    SingleGroupPerPageLayoutComponent,
    NumericRatingComponent,
    AuditPrintComponent,
    AuditReferencesComponent,
  ],
  providers: [
    AdHocAuditService,
  ],
  imports: [
    SharedModule,
    RouterModule,
    FileUploadModule,
  ],
  entryComponents: [
  ],
})

export class ConductModule {
}
