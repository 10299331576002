import {Component, Inject, OnInit} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ListViewBase } from "../../list-view/list-view-base";
import { ApiService, LocalService, NotifyService } from "../../../core/services/index";
import { ActivatedRoute, Router } from "@angular/router";
import { ListViewStorageService } from "../../list-view/list-view-storage.service";
import { EaseAuditStatus } from "../../../core/models/ease-models";

@Component({
  selector: "ease-report-drilldown-component",
  templateUrl: "./report-drilldown-modal.component.html",
  styleUrls: ["./report-drilldown-modal.component.scss"],
})
export class ReportDrilldownModalComponent extends ListViewBase implements OnInit {
  public audits: any[];
  public mitigations: any[];

  public modalDescription: string;
  public isMitigationView: boolean = false;
  public includeMitigationCount: boolean = false;
  public showDueDate: boolean = false;
  public showAuditList: boolean = true;
  public isAdhocMitigationView: boolean = false;

  public showSiteInfo: boolean = false;
  public isPointBasedAudit: boolean = false;

  public selectedAuditID: number = 0;
  public selectedAudit: any = null;
  constructor(
    private dialogRef: MatDialogRef<ReportDrilldownModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public api: ApiService,
    public notify: NotifyService,
    public local: LocalService,
    public route: ActivatedRoute,
    public listViewStorageService: ListViewStorageService,
    private router: Router) {

    super(api, notify, local, route, listViewStorageService);
  }

  public ngOnInit() {
    this.isLoading = true;

    this.modalDescription = this.data.modalDescription;
    this.isMitigationView = this.data.isMitigationView;
    this.includeMitigationCount = this.data.includeMitigationCount;
    this.showDueDate = this.data.showDueDate;

    let filterIDs = this.data.auditIDs.join(",");

    if (this.isMitigationView) {
      this.initialize("mitigationreport", { FilterByIDs: filterIDs, IsDrilldown: true }, "MitigationDrilldownName|ResponsiblePartyID|Status", "ID", "mitigation-list-drilldown", (data) => {
        this.mitigations = data;

        if (this.mitigations && this.mitigations.length === 1) {
          this.viewMitigationDetail(this.mitigations[0]);
        } else {
          this.isLoading = false;
        }
      });
    } else {
      const auditIDs = [];
      const mitigationIDs = [];
      this.data.auditIDs.forEach((id, index) => {
        if ((this.data.isAdhocMitigation != null && !this.data.isAdhocMitigation[index]) || this.data.isAdhocMitigation == null) {
          auditIDs.push(id);
        } else {
          mitigationIDs.push(id);
        }
      });

      filterIDs = auditIDs.join(",");
      this.initialize("auditreport", { FilterByIDs: filterIDs, IsDrilldown: true }, "AuditorID|Name|SiteID|JobID|Score", "ID", "audit-list-drilldown", (data) => {
        this.audits = data;

        if (this.audits.length === 1) {
          this.viewAuditDetail(this.audits[0]);
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  public viewAuditList(): void {
    this.showAuditList = true;
  }

  public viewAuditDetail(audit: any): void {

    // Only show the audit if it is complete or completed late
    // or if adhoc mitigation is open
    if (audit && (audit.Status === EaseAuditStatus.Complete || audit.Status === EaseAuditStatus.CompletedLate || (audit.HasAdhocMitigation && audit.Status === 0))) {
      this.isLoading = true;
      this.selectedAuditID = audit.ID;
      this.selectedAudit = { ID: audit.ID };

      this.audits.forEach((a) => {
        a.IsSelected = false;
      }, true);

      audit.IsSelected = true;
      this.showAuditList = false;

      this.isAdhocMitigationView = (audit.HasAdhocMitigation != null && audit.HasAdhocMitigation) ? true : false;
      this.isLoading = false;
    }
  }

  public viewDetail(mitigation: any): void {
    // mitigation is adhoc
    if (mitigation.AuditID == null) {
      if (mitigation.Status === 1) {
        this.isLoading = true;
        this.selectedAuditID = mitigation.ID;
        this.selectedAudit = { ID: mitigation.ID };

        mitigation.IsSelected = true;
        this.showAuditList = false;

        this.isAdhocMitigationView = true;

        this.isLoading = false;
      }
    } else {
      this.viewMitigationDetail(mitigation);
    }
  }

  public viewMitigationDetail(mitigation): void {
    // Only show the audit if it is complete or completed late
    // or if adhoc mitigation is open
    if (mitigation && (mitigation.AuditStatus === EaseAuditStatus.Complete || mitigation.AuditStatus === EaseAuditStatus.CompletedLate || (mitigation.AuditStatus == null))) {
      this.isLoading = true;
      const ID = (mitigation.AuditID == null) ? mitigation.ID : mitigation.AuditID;
      this.selectedAuditID = ID;
      this.selectedAudit = { ID: ID };

      this.mitigations.forEach((m) => {
        m.IsSelected = false;
      }, true);

      this.isAdhocMitigationView = (mitigation.AuditID == null) ? true : false;

      this.showAuditList = false;
      this.isLoading = false;
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
