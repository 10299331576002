import { Injectable } from "@angular/core";
import { IScope } from "../models/ease-models";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ScopeService {
    constructor(private http: HttpClient) {}

    public getScopeById(id: number): Observable<IScope> {
        return this.http.get<IScope>("/scope/" + id);
    }

    public create(scope: IScope): Observable<number> {
        return this.http.post<number>("/scope/", scope);
    }

    public update(id, scope: IScope): Observable<number> {
        return this.http.put<number>("/scope/" + id, scope);
    }

    public delete(id): Observable<void> {
        return this.http.delete<void>("/scope/" + id);
    }

    public getScopesExcludingLocation(siteId: number, excludeLocationId: number): Observable<IScope[]> {
        const url = "/Scope?siteID=" + siteId + "&excludeLocationId=" + excludeLocationId;
        return this.http.get<IScope[]>(url);
    }
}
