import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone, Inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { NgForm } from "@angular/forms";

import { MatDialog } from "@angular/material";
import { CurrentInfoService } from "../../../core/services/current.service";
import { LocalizeService } from "../../../core/services/localize.service";
import { Config } from "../../../core/services/config.service";
import { LocalService } from "../../../core/services/local.service";
import { NotifyService } from "../../../core/services/notify.service";
import { ApiService } from "../../../core/services/api.service";
import { ArtifactService } from "../../../core/services/common/artifact.service";
import { IdentityService } from "../../../core/services/identity.service";
import { ToolsService } from "../../../core/services/tools.service";
import { AuditDetailService } from "../../../core/services/common/audit-detail.service";
import { MitigationDetailService } from "../../../core/services/common/mitigation-detail.service";
import { StorageService, StorageKey } from "../../../core/services/storage.service";
import { LoggerService } from "../../../core/services/logger.service";
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HttpHeaders } from "@angular/common/http";
import { ImageDialogComponent } from "../../dialogs/image-dialog/image-dialog.component";

import { IIssueClassification, IAuditDetail, IAuditConfig, IDocumentRev, IAuditLineItem, IClientAuditQuestion,
    IAuditAnswer, IClientAuditAnswer, IAuditAnswerArtifact, ICustomCategory, ICustomItem, ICustomCategoryLineItem,
    IFileFormat, IAudit, IAuditQuestionGroup, IUser, IRank, IArtifact, IMitigationActivity, IMitigationDetail,
    QuestionResponseTypes, PhotoInputResponseOption, MitigationResponseOption, PassRangeTypes, EaseAuditStatus,
    ArtifactTypes, ICustomFieldValue } from "../../../core/models/ease-models";

import { Subject, Observable, Subscription, ReplaySubject } from "rxjs";

import * as _ from "lodash";
import * as moment from "moment";

import { DecimalPipe } from "@angular/common";
import { DialogConfigSm } from "../../dialogs/index";

@Component({
    templateUrl: "./audit-result.component.html",
    selector: "ease-audit-result",
    styleUrls: ["audit-result.component.scss"],
})

export class AuditResultComponent implements OnInit {
    @Input() public auditID: number = null;

    public hasPointBasedQuestions: boolean = false;
    public hasPhotoBasedQuestions: boolean = false;
    public hasPassFailQuestions: boolean = false;
    public hasMultiChoiceQuestions: boolean = false;
    public hasMultiSelectQuestions: boolean = false;
    public hasOperatorNameInfo: boolean = false;
    public hasFailureReasons: boolean = false;
    public hasCustomValueQuestions: boolean = false;
    public hasMultipleChoiceQuestions: boolean = false;
    public showAllQuestions: boolean = false;
    public showReactionPlan: boolean = false;
    public loading: boolean = false;
    public isCordova: boolean = false;
    public instance: AuditResultComponent;

    public selectedAudit: any;

    public layoutProcessed: boolean;
    public isModalView: boolean;

    public auditDetail: IAuditDetail;
    public auditQuestions: IClientAuditQuestion[] = [];
    public DateUpdated: Date;
    public auditDoc: IDocumentRev;
    public auditConfig: IAuditConfig;
    public audit: IAudit;
    public failedToLoadAudit: boolean = false;
    public groupQuestions: boolean = false;
    public onePageAudit: boolean = false;
    public questionGroups: any[];
    public numImages: number = 0;
    public filteredFailureModes: ICustomItem[];
    public failureCategories: ICustomCategory[];
    public failureModes: ICustomItem[];
    public failureModeItems: any[];
    public filteredFailureModeItems: any[];
    public failureCategoryItems: any[];
    public auditAverageScore: any;
    public customCategoryLineItems: ICustomCategoryLineItem[];
    public customCategoryItems: any;

    constructor(private current: CurrentInfoService,
                private route: ActivatedRoute,
                private localize: LocalizeService,
                private config: Config,
                private local: LocalService,
                private notify: NotifyService,
                private api: ApiService,
                private identity: IdentityService,
                private tools: ToolsService,
                private router: Router,
                private log: LoggerService,
                private auditDetailService: AuditDetailService,
                private mitigationDetailService: MitigationDetailService,
                private storage: StorageService,
                private zone: NgZone,
                private dialog: MatDialog,
                private artifactService: ArtifactService) {
            this.instance = this;
    }

    // result for now is only for pass/fail audit
    public ngOnInit(): void {
        this.initAuditResult();
    }

    public initAuditResult(): void {
        this.loading = true;
        this.notify.broadcast("full-page-block", true);
        this.log.debug("audit result component onInit: " + this.loading);

        const id = parseInt(this.route.snapshot.params.id, 10);
        this.auditID = (this.auditID == null) ? id : this.auditID;

        this.isCordova = this.tools.isCordova();
        const prep = this.route.snapshot.queryParams.prep === "true";

        this.auditDetailService.getAuditByID(this.auditID, "Audits", false, true, false).subscribe((content: any) => {
            this.log.debug("getAuditByID returned data: " + content);
            if (content) {
                this.zone.run(() => {
                    this.auditDetail = content.auditDetail;
                    this.initAuditDetail(this.auditID);
                    this.loading = false;
                    this.notify.broadcast("full-page-block", false);
                });
            } else {
                this.log.debug("auditID not found: " + this.auditID);
            }
        });
    }

    public refreshResults(event: any) {
        this.zone.run(() => {
            if (event) {
                const filter = this.auditQuestions.filter(data => data.ID === event.ID);
                if (filter.length > 0) {
                    filter[0].isExpanded = false;
                }
            }
        });
    }

    public initAuditDetail(id: number): void {
        if (this.auditDetail != null) {
            this.customCategoryLineItems = this.auditDetail.CustomCategoryLineItems;
            this.getFailureCategories(this.auditDetail.FailureCategoriesCustomCategoryLineItems);
            this.getAuditDoc(id, this.auditDetail);
            this.formatQuestionsText();
            this.determineAuditLayout();
            this.mitigationDetailService.getMitigations(id).subscribe((data: IMitigationDetail[]) => {
                if (data && data.length > 0) {
                    // Search for the audit answer and hang the mitigation off that
                    this.zone.run(() => {
                        _.each(this.auditQuestions, (q: IClientAuditQuestion) => {
                            q.isExpanded = false;
                            const answerID = q.AuditAnswer.ID;

                            const mitigationActivity: IMitigationActivity[] = [];
                            const filtered: IMitigationDetail[] = data.filter((
                                ma: IMitigationDetail) => ma.Mitigation.AuditAnswerID === answerID);
                            if (filtered.length > 0) {
                                _.each(filtered, (ma: IMitigationDetail) => {
                                    if (ma.Mitigation.RankID > 0) {
                                        const ranks = ma.Ranks.filter(rank => rank.ID === ma.Mitigation.RankID);
                                        if (ranks.length > 0) {
                                            ma.Mitigation.Rank = ranks[0];
                                            ma.Mitigation.ResolutionPeriod = moment(ma.Mitigation.DateOpened)
                                            .add(ma.Mitigation.Rank.ResolutionPeriod, "day").utc().toDate();
                                        }
                                    }

                                    // Populate the responsible party name
                                    if (ma.Mitigation.ResponsiblePartyUser != null) {
                                        ma.Mitigation.ResponsbilePartyFullName =
                                        ma.Mitigation.ResponsiblePartyUser.FirstName
                                        + " " + ma.Mitigation.ResponsiblePartyUser.LastName;
                                    } else {
                                        ma.Mitigation.ResponsiblePartyUser = this.current.info.user;
                                    }

                                    ma.Mitigation.uploadedFiles = [];
                                    if (q.QuestionType !== QuestionResponseTypes.MultiSelect) {
                                        _.each(ma.MitigationArtifacts, (img: any) => {
                                            const artifact = ma.Artifacts.filter(
                                                mitigationArtifact => mitigationArtifact.ID === img.ArtifactID);
                                            if (artifact.length > 0) {
                                                ma.Mitigation.uploadedFiles.push(
                                                    {
                                                        ID: img.ID, MitigationID: img.MitigationID,
                                                        ArtifactID: artifact[0].ID, Url: artifact[0].Url,
                                                        Filename: artifact[0].FileName,
                                                        FileFormatID: artifact[0].FileFormatID});
                                            }
                                        });
                                    }
                                    mitigationActivity.push(ma.Mitigation);
                                });

                                // used for question types that are not multi-select
                                q.AuditAnswer.MitigationActivity = mitigationActivity;
                                q.AuditAnswer.MitigationDetail = filtered;

                                if (q.CustomField != null) {
                                    this.loadCustomFields(q, () => {
                                        this.initializeCustomFieldValues(q);
                                        q.SelectedCustomCategories = q.customCategories.filter(
                                            x => x.IsSelected === true);
                                        _.each(q.SelectedCustomCategories, (cc: ICustomCategory) => {
                                            q.AuditAnswer.SelectedCustomFieldValues = q.AuditAnswer.CustomFieldValues
                                            .filter(x => x.FieldValueCustomCategoryID === cc.ID);
                                        });
                                    });
                                }

                            }

                            if (q.AuditAnswer.IsPassed === false) {
                                this.showReactionPlan = true;
                            }

                        });
                    });
                } else {
                    this.auditQuestions.forEach((q: IClientAuditQuestion) => {
                        q.isExpanded = false;
                        if (q.CustomField != null) {
                            this.loadCustomFields(q, () => {
                                this.initializeCustomFieldValues(q);
                                q.SelectedCustomCategories = q.customCategories.filter(x => x.IsSelected === true);
                                _.each(q.SelectedCustomCategories, (cc: ICustomCategory) => {
                                    q.AuditAnswer.SelectedCustomFieldValues = q.AuditAnswer.CustomFieldValues.filter(
                                        x => x.FieldValueCustomCategoryID === cc.ID);
                                });
                            });
                        }
                    });
                }
            });
        }
    }

    public determineAuditLayout(): void {
        for (const question of this.auditQuestions) {
            const answer = question.AuditAnswer;
            if (question.QuestionType === QuestionResponseTypes.Point) {
                this.hasPointBasedQuestions = true;
            } else if (question.QuestionType === QuestionResponseTypes.PassFail) {
                this.hasPassFailQuestions = true;
            } else if (question.QuestionType === QuestionResponseTypes.MultipleChoice ||
                question.QuestionType === QuestionResponseTypes.MultiSelect) {
                this.hasMultiChoiceQuestions = true;
            } else if (question.QuestionType === QuestionResponseTypes.Text ||
                question.QuestionType === QuestionResponseTypes.Numeric) {
                this.hasCustomValueQuestions = true;
            }

            if (question.PhotoInputResponseOption > 0) {
                this.hasPhotoBasedQuestions = true;
            }
            if (answer.IssueClassificationID != null) {
                this.hasFailureReasons = true;
            }
            if (answer.OperaterName != null) {
                this.hasOperatorNameInfo = true;
            }

            this.layoutProcessed = true;

        }
    }

    public selectedCustomFieldValues(cfv: ICustomFieldValue[], catID: number): ICustomFieldValue[] {
        const filteredCustomItems = cfv.filter(x => x.FieldValueCustomCategoryID === catID);
        return filteredCustomItems;
    }

    public selectedCustomItem(customItems: ICustomItem[], customItemID: number): ICustomItem {
        const filteredCustomItems = customItems.filter(x => x.ID === customItemID);
        return filteredCustomItems.length > 0 ? filteredCustomItems[0] : null;
    }

    public getCustomCategoryByID(id: number): ICustomCategory {
        const filtered: any = this.failureCategories.filter((cat: ICustomCategory) => cat.ID === id);
        return (filtered.length !== 0) ? filtered[0] : null;
    }

    public getFailureModeByID(id: number): ICustomItem {
        const filtered: any[] = this.failureModes.filter((modes: any) => modes.ID === id);
        return (filtered.length !== 0) ? filtered[0] : null;
    }

    public filterModesByCategory(catID: number): void {
        this.filteredFailureModes = this.failureModes.filter((mode: ICustomItem) => mode.CustomCategoryID == catID);
    }

    public allowFailureModeEdit(auditAnswer: IClientAuditAnswer): boolean {
        let allowEdit = false;
        const current = this.current.info;
        let isResponsibleParty = false;
        let isApprovingParty = false;
        let isMitigationOpen = false;
        if (auditAnswer.MitigationActivity != null && auditAnswer.MitigationActivity.length > 0) {
          if (current.user) {
            isResponsibleParty = current.user.ID === auditAnswer.MitigationActivity[0].ResponsiblePartyUser.ID;
            isApprovingParty = current.user.ID === auditAnswer.MitigationActivity[0].ApprovingManagerUserID;
          }
          isMitigationOpen = auditAnswer.MitigationActivity[0].Status === 0;
        }
        if ((current.roleIsSiteAdmin || isResponsibleParty || isApprovingParty) && isMitigationOpen) {
            allowEdit = true;
        }
        return allowEdit;
    }

    public getQuestionData(grpQuestions: IClientAuditQuestion[]): any[] {
        const questions: any[] = (this.groupQuestions) ? grpQuestions : this.auditQuestions;
        return questions;
    }

    public formatQuestionsText(): void {
        _.each(this.auditQuestions, (question: IClientAuditQuestion) => {
            if (question.QuestionText) {
                try {
                    const text = this.createHyperLinks(question.QuestionText);
                    question.QuestionTextFormatted = text.replace(/\n/g, "<br/>");
                } catch (e) {

                }
            }

            if (question.ReasonText) {
                try {
                    const text = this.createHyperLinks(question.ReasonText);
                    question.ReasonTextFormatted = text.replace(/\n/g, "<br/>");
                } catch (e) {

                }
            }
            if (question.ReactionPlan) {
                try {
                    const text = this.createHyperLinks(question.ReactionPlan);
                    question.ReactionPlanFormatted = text.replace(/\n/g, "<br/>");
                } catch (e) {

                }
            }
        });
    }

    public getAuditAnswerImage(img: IArtifact): void {
        if (img) {
            const config = new DialogConfigSm();
            config.panelClass = "img-dialog";
            config.data = img.Url;
            this.dialog.open(ImageDialogComponent, config);
        }
    }

    public updateFailureModes(id: number, auditAnswer: IClientAuditAnswer) {
      this.filterFailureModeItemsByCatID(id);
      auditAnswer.IssueClassification.FailureCategoryID = id;
      auditAnswer.IssueClassification.CustomCategory = this.getCustomCategoryByID(id);
      auditAnswer.IssueClassification.FailureModeID = null;
      auditAnswer.IssueClassification.CustomItem = null;
    }

    public saveAuditAnswerIssueClassification(id: number, auditAnswer: IAuditAnswer) {
      auditAnswer.IssueClassification.FailureModeID = id;
      auditAnswer.IssueClassification.CustomItem = this.getFailureModeByID(id);
      const question = this.auditQuestions.filter(item => item.ID === auditAnswer.AuditQuestionID);
      if (question.length > 0) {
        question[0].AuditAnswer = auditAnswer;
        this.audit.AuditQuestions = this.auditQuestions;
      }
      this.auditDetailService.updateAuditChanges(this.audit, "isUpdatingFailureMode=true").subscribe((data: IAudit) => {
        this.audit = data;
      });
    }

    private filterFailureModeItemsByCatID(catID: number) {
      this.filteredFailureModeItems = JSON.parse(JSON.stringify(this.failureModeItems.filter((item: any) => item.catID === catID)));
    }

    private getFailureCategories(customCategoryLineItem: ICustomCategoryLineItem[]): void {
        const categories: ICustomCategory[] = [];
        const items: ICustomItem[] = [];
        const failureModeItems: any[] = [];
        const customCategoryItems: any[] = [];

        _.each(customCategoryLineItem, (li: ICustomCategoryLineItem) => {
            const filtered = categories.filter((cat: ICustomCategory) => cat.ID === li.CustomCategoryID);
            if (filtered.length === 0) {
                categories.push(li.CustomCategory);
                customCategoryItems.push({value: li.CustomCategory.ID, text: li.CustomCategory.Name});
            }

            li.CustomItem.CustomCategoryID = li.CustomCategoryID;
            li.CustomItem.CustomCategory = li.CustomCategory;
            items.push(li.CustomItem);
            failureModeItems.push({ value: li.CustomItem.ID, text: li.CustomItem.Name, catID: li.CustomCategoryID });
        });

        this.failureModes = items;
        this.filteredFailureModes = items;
        this.failureCategories = categories;
        this.failureModeItems =  failureModeItems;
        this.customCategoryItems = customCategoryItems;
    }

    private getAuditDoc(paramID: number, auditDetail: IAuditDetail): void {
        const audit: IAudit = auditDetail.Audit;
        const auditDoc: IDocumentRev = auditDetail.DocumentRev;
        const auditQuestionGroups: IAuditQuestionGroup[] = auditDetail.AuditQuestionGroups;
        const artifacts: IArtifact[] = auditDetail.Artifacts;

        this.auditID = paramID;
        this.DateUpdated = (audit != null) ? audit.DateUpdated : null;

        this.auditConfig = this.auditDetail.AuditConfiguration;
        this.audit = audit;
        this.log.debug("audit.NonConformanceData: " + audit.NonConformanceData);

        if (this.audit == null) {
            // Unable to retrieve audit.  Most likely a permissioning issue
            this.failedToLoadAudit = true;
            this.loading = false;
            // error code?
        } else {
            if (audit.DocumentRevID != null) {
                this.auditDoc = auditDoc;
                if (this.auditDoc == null) {
                    // Unable to retrieve audit.  Most likely a permissioning issue
                    this.failedToLoadAudit = true;
                    this.loading = false;
                    return;
                }

                const props = this.auditDoc.Document.Properties.hasOwnProperty("OnePageAudit") ?
                 this.auditDoc.Document.Properties : this.auditDoc.Properties;

                this.groupQuestions = props.GroupQuestions === "1" || this.onePageAudit;

                this.getQuestionGroups(this.audit.Description, auditQuestionGroups);
                this.getAuditQuestions(this.auditID, auditDetail);
            } else {
                this.log.debug("DocumentRevID is null");
                if (this.audit.Status === 0 || this.audit.Status === 3 || this.audit.Status === 5) {
                    this.log.debug("audit status: " + this.audit.Status);
                    this.getQuestionGroups(this.audit.Description, auditQuestionGroups);
                    this.getAuditQuestions(this.auditID, auditDetail);
                }
            }
        }
    }

    private getQuestionGroups(auditDescription: string, auditQuestionGroups: IAuditQuestionGroup[]): void {
        auditQuestionGroups = _.orderBy(auditQuestionGroups, [questionGroup => questionGroup.Sequence], ["asc"]);
        this.questionGroups = [];
        if (this.groupQuestions) {
            this.questionGroups.push({ ID: null, Name: "", Questions: [] });

            for (const questionGroup of auditQuestionGroups) {
                const desc = (this.onePageAudit) ? auditDescription : questionGroup.Description;
                this.questionGroups.push({ ID: questionGroup.ID, Name: desc, Questions: [] });
            }
        }
    }

    private getAuditQuestions(auditID: number, auditDetail: IAuditDetail): void {
        this.numImages = 0;
        if (auditID != null) {
            this.auditQuestions = [];

            let auditLineItem: IAuditLineItem[] = auditDetail.AuditLineItems;
            const auditAnswerArtifacts: IAuditAnswerArtifact[] = auditDetail.AuditAnswerArtifacts;

            auditLineItem = _.orderBy(auditLineItem, [lineItem => lineItem.SequenceNum], ["asc"]);
            _.each(auditLineItem, (li: IAuditLineItem, index: number) => {
                const question: IClientAuditQuestion = (<IClientAuditQuestion>li.AuditQuestion);
                const answer: IClientAuditAnswer = (question.AuditAnswer as IClientAuditAnswer);

                question.images = [];
                answer.images = [];
                question.currentImageIndex = 0;
                answer.IsOptionalMitigationSelected = false; // default to false
                // set the "IsRequired" field for each question
                question.IsRequired = li.IsRequired;
                question.AltResponsiblePartyID = li.AltResponsiblePartyID;
                question.AuditQuestionGroupID = li.AuditQuestionGroupID;
                question.AuditAnswer.MitigationActivity = [];
                const questionItems = (
                    question != null && question.QuestionText != null) ? question.QuestionText.split(" ") : [];
                if (questionItems.length > 0) {
                    for (const item of questionItems) {
                        question.WordBreakAll = (item.length > 50) ? true : false;
                    }
                }

                this.auditQuestions.push(question);

                // If we're grouping questions by question group...
                if (this.groupQuestions) {
                    const results: any[] = this.questionGroups.filter(
                        (questionGroup: any) => questionGroup.ID === li.AuditQuestionGroupID);
                    if (results.length !== 0) {
                        results[0].Questions.push(question);
                    }
                    this.calculateGroupedAuditResult();
                } else {
                    this.calcuteNonGroupedAuditResults();
                }

                if (index === 0 && answer.IssueClassificationID != null) {
                    this.filterModesByCategory(answer.IssueClassification.FailureCategoryID);
                    this.filterFailureModeItemsByCatID(answer.IssueClassification.FailureCategoryID);
                }

                // Populate question & answer with failure mode information
                if (answer.IssueClassification !== null && answer.IssueClassification.CustomCategory === null) {
                    // assign selected failure category / custom category
                    answer.IssueClassification.CustomCategory =
                        this.getCustomCategoryByID(answer.IssueClassification.FailureCategoryID);

                    // assign selected failure mode/ custom item
                    answer.IssueClassification.CustomItem =
                    this.getFailureModeByID(answer.IssueClassification.FailureModeID);
                }

                if (question.QuestionType === QuestionResponseTypes.Point) {
                    this.hasPointBasedQuestions = true;
                }

                if (question.CustomField != null) {
                    this.loadCustomFields(question, () => {
                        this.initializeCustomFieldValues(question);
                    });
                }
                // for multi-select questions with mitigation required, to separate details by custom items
                question.DisplayCustomItemsInGroup = question.QuestionType !== QuestionResponseTypes.MultiSelect ||
                (question.QuestionType === QuestionResponseTypes.MultiSelect
                    && !(question.MitigationResponseOption > 1));

                // loadQuestionAnswerImages
                this.loadQuestionAnswerImages(question, answer, auditAnswerArtifacts);
            });

            this.audit.AuditQuestions = this.auditQuestions;
        }

    }

    private calculateGroupedAuditResult(): void {
        this.auditAverageScore = 0;
        let totalAuditPoints = 0;
        let totalAuditPointValueQuestions = 0;

        if (this.questionGroups && this.auditQuestions.length > 0) {
            _.forEach(this.questionGroups, (qg) => {
                let groupContainsPointValueQuestion: boolean = false;
                let totalPts = 0;
                let totalPossiblePts = 0;
                let groupPointsValueQuestionCount = 0;

                const filteredByGroupQuestions: any[] = this.auditQuestions.filter(x => x.AuditQuestionGroupID === qg.ID);
                _.forEach(filteredByGroupQuestions, (question) => {
                    if (question.QuestionType === QuestionResponseTypes.Point) {
                        groupContainsPointValueQuestion = true;
                        const answer = question.AuditAnswer;
                        if (answer.PointValue > 1) {
                            if (question.PointValue > 1) {
                                totalPts += (answer.PointValue - 1);
                            }
                            groupPointsValueQuestionCount++;
                            totalAuditPointValueQuestions++;
                            totalPossiblePts += question.PointValue;
                        }
                    }
                });
                totalAuditPoints += totalPts;
                if (groupContainsPointValueQuestion) {
                    qg.Subtotal = {Average: totalPts / groupPointsValueQuestionCount, Percent: Math.round((totalPts / totalPossiblePts) * 100)};
                }
            });
            if (totalAuditPointValueQuestions) {
                this.auditAverageScore = totalAuditPoints / totalAuditPointValueQuestions;
            }
        }
    }

    private calcuteNonGroupedAuditResults(): void {
        this.auditAverageScore = 0;
        let totalAuditPointValueQuestions = 0;
        if (this.auditQuestions && this.auditQuestions.length > 0) {
            let totalPts = 0;
            _.forEach(this.auditQuestions, (question) => {
                if (question.QuestionType === QuestionResponseTypes.Point) {
                    const answer = question.AuditAnswer;
                    if (answer.PointValue && question.PointValue > 1) {
                        totalPts += (answer.PointValue - 1);
                        totalAuditPointValueQuestions++;
                    }
                }
            });
            if (totalAuditPointValueQuestions) {
                this.auditAverageScore = totalPts / totalAuditPointValueQuestions;
            }
        }
    }
    private initializeCustomFieldValues(question: IClientAuditQuestion): void {
        const answer = <IClientAuditAnswer> question.AuditAnswer;

        if (answer.CustomFieldValues != null && answer.CustomFieldValues.length > 0) {

            if (question.QuestionType === QuestionResponseTypes.MultipleChoice
                || question.QuestionType === QuestionResponseTypes.MultiSelect) {
                _.each(question.customCategories, (cat: ICustomCategory) => {
                    cat.SelectedCustomItems = [];
                    _.each(cat.CustomItems, (item: ICustomItem) => {

                        const filtered = answer.CustomFieldValues.filter(x => x.FieldValueCustomItemID === item.ID
                            && x.FieldValueCustomCategoryID === cat.ID);
                        if (filtered.length !== 0) {
                            item.IsSelected = true;
                            cat.IsSelected = true;
                            cat.SelectedCustomItems.push(item);

                            if (filtered[0].MitigationActivity != null) {
                                if (question.AuditAnswer.MitigationDetail !== undefined
                                    && question.AuditAnswer.MitigationDetail !== null) {
                                    const mitigationDetails = question.AuditAnswer.MitigationDetail
                                    .filter(x => x.Mitigation.ID === filtered[0].MitigationActivity.ID);
                                    if (mitigationDetails.length > 0) {
                                        filtered[0].MitigationDetail = mitigationDetails[0];
                                    }
                                }

                                if (filtered[0].MitigationActivity.Rank !== undefined
                                    && filtered[0].MitigationActivity.Rank !== null) {
                                    filtered[0].MitigationActivity.ResolutionPeriod
                                    = moment(filtered[0].MitigationActivity.DateOpened)
                                    .add("day", filtered[0].MitigationActivity.Rank.ResolutionPeriod).utc().toDate();
                                }
                            }
                        }
                    });

                });
                if (question.QuestionType === QuestionResponseTypes.MultiSelect
                    && question.MitigationResponseOption === MitigationResponseOption.Optional) {
                    answer.IsOptionalMitigationSelected = true;
                }
            }
        }
    }

    private loadCustomFields(question: IClientAuditQuestion, callback?: any) {
        const customField = question.CustomField;

        if (customField != null) {

            if (question.QuestionType === QuestionResponseTypes.MultipleChoice ||
                question.QuestionType === QuestionResponseTypes.MultiSelect) {

                this.hasMultipleChoiceQuestions = true;

                const groupID = customField.RestrictToGroup;
                const restrict = customField.RestrictRange;

                const filteredCustomCategoryLineItems = this.getCustomCategoryLineItemByCustomGroupID(groupID);
                // Based off the custom category line item, create a list of categories and nest the custom item under those categories
                // Restrict category values based on the custom field definition
                let validValues: string[] = [];
                if (restrict != null) {
                    validValues = restrict.split(",");
                }

                const categories: ICustomCategory[] = [];
                filteredCustomCategoryLineItems.forEach((li: ICustomCategoryLineItem) => {
                    if (restrict == null || validValues.indexOf(li.CustomCategoryID.toString()) >= 0) {
                        const catID = li.CustomCategoryID;
                        const filtered = categories.filter((cat: ICustomCategory) => cat.ID === catID);
                        if (filtered.length === 0) {
                            li.CustomCategory.CustomItems = [];
                            li.CustomCategory.CustomItems.push(li.CustomItem);
                            categories.push(li.CustomCategory);
                        } else {
                            filtered[0].CustomItems.push(li.CustomItem);
                        }
                    }
                });
                question.customCategories = _.cloneDeep(categories);    // create new object of categories
                if (callback) { callback(); }
            } else if (question.QuestionType === QuestionResponseTypes.Numeric) {
                const restrictRange = customField.RestrictRange;

                if (restrictRange != null) {
                    const dashCount = restrictRange.split(/(?=-)/);

                    if (dashCount.length === 2) { // range is negative to positive or positive to positive
                        question.MinValue = parseFloat(dashCount[0]);
                        question.MaxValue = parseFloat(dashCount[1].replace("-", ""));
                    } else if (dashCount.length === 3) { // range is negative to negative
                        question.MinValue = parseFloat(dashCount[0]);
                        question.MaxValue = parseFloat(dashCount[2]);
                    }
                }
            }
        } else if (callback) { callback(); }
    }

    private getCustomCategoryLineItemByCustomGroupID(customGroupID: number): ICustomCategoryLineItem[] {
        let results: ICustomCategoryLineItem[] = [];
        if (this.customCategoryLineItems && this.customCategoryLineItems.length > 0) {
            results = this.customCategoryLineItems.filter((c: ICustomCategoryLineItem) => c.CustomCategory && c.CustomCategory.CustomGroupID === customGroupID);
        }
        return results;
    }

    private populateCustomItems(restrict: string, ccLineItem: ICustomCategoryLineItem[]): ICustomCategory[] {
        // Based off the custom category line item, create a list of categories and nest the custom item under those categories
        // Restrict category values based on the custom field definition
        let validValues: any[] = [];
        if (restrict != null) {
            validValues = restrict.split(",");
        }

        const categories: ICustomCategory[] = [];
        _.each(ccLineItem, (li) => {
            if (restrict == null || validValues.indexOf(li.CustomCategoryID.toString()) >= 0) {
                const catID = li.CustomCategoryID;
                const filtered = categories.filter(x => x.ID === catID);
                if (filtered.length === 0) {
                    li.CustomCategory.CustomItems = [];
                    li.CustomCategory.CustomItems.push(li.CustomItem);
                    categories.push(li.CustomCategory);
                } else {
                    filtered[0].CustomItems.push(li.CustomItem);
                }
            }

        });

        return categories;
    }

    private onFailureCategoryChanged(question: IClientAuditQuestion, selected: any): void {
        if (question.AuditAnswer.IssueClassification == null) {
            let issueClassification: IIssueClassification;

            issueClassification.FailureCategoryID = selected;
            issueClassification.FailureModeID = null;

            question.AuditAnswer.IssueClassification = issueClassification;
        } else {
            question.AuditAnswer.IssueClassification.FailureCategoryID = selected;
            question.AuditAnswer.IssueClassification.FailureModeID = null;
        }

        // filter options in failure mode based on selected failure category
        this.filterModesByCategory(selected);
    }

    private loadQuestionAnswerImages(question: IClientAuditQuestion, answer: IClientAuditAnswer, answerArtifacts?: IAuditAnswerArtifact[]): void {
      this.numImages = 0;

      // load answer images
      if ((answer !== null && answer.HasArtifact) || (answerArtifacts.filter((artifact: IAuditAnswerArtifact) => artifact.AuditAnswerID === answer.ID).length > 0)) {
        const auditAnswerArtifacts: IAuditAnswerArtifact[] = answerArtifacts.filter((artifact: IAuditAnswerArtifact) => artifact.AuditAnswerID === answer.ID);
        _.each(auditAnswerArtifacts, (artifact: IAuditAnswerArtifact) => {
          answer.HasArtifact = true;
          artifact.Url = this.artifactService.setImageUrl(artifact, "AnswerArtifact");
          answer.images.push(artifact);
          this.numImages++;
        });
      }
    }

    private createHyperLinks(description: string): string {
        let ok = description.indexOf(")[");
        const http = "http://";
        const https = "https://";
        while (ok !== -1) {
            const questionFirstPart = description.substr(0, description.indexOf("("));
            const questionText = description.substr(ok + 1);
            let href = questionText.substr(1, questionText.indexOf("]") - 1);
            if (href.indexOf(http) === -1 && href.indexOf(https) === -1) {
                href = http + href;
            }
            const textPart = description.substr(0, description.indexOf(")"));
            const text = textPart.substr(description.indexOf("(") + 1);
            const hiperLink = "<a href='" + href + "' target='_blank'>" + text + "</a>";
            const questionSecondPart = questionText.substr(questionText.indexOf("]") + 1);
            description = questionFirstPart + hiperLink + questionSecondPart;
            ok = description.indexOf(")[");
        }
        return description;
    }
}
