import { ICapaEvent, ICapaTask } from "@core/models/ease-models";

export enum UserActivityType { Audit, Mitigation, MitigationApproval, CapaEvent, CapaTask }

export interface MyActivityListDetail {
  CapaEventOwnerActivity?: ICapaEvent[];
  CapaEventApproverActivity?: ICapaEvent[];
  CapaTaskActivity?: ICapaTask[];
}

export interface UserActivity {
  ID: number;
  Type: UserActivityType;
  Name: string;
  Status: number;
  TranslatedStatus?: string;
  LocationID?: number;
  SiteID: number;
  PastDueDays: number;
  AuditID?: number;
  Location: string;
  Due: Date;
  PastDue: boolean;
  PendingUpload?: boolean;
} 