import { Observable } from "rxjs";
import { map, take, withLatestFrom, filter } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

// State
import { Store, select } from "@ngrx/store";
import { AuthedRedirect } from "../actions/auth";
import * as fromAuth from "../reducers";

@Injectable()
export class IsAuthedGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getAppIsReady),
      filter(ready => ready),
      withLatestFrom(this.store.pipe(select(fromAuth.getLoggedIn))),
      map(([_, authed]) => {
        if (authed) {
          this.store.dispatch(new AuthedRedirect());
          return false;
        }

        return true;
      }),
      take(1),
    );
  }
}
