import { TreeItem, TreeviewItem } from "ngx-treeview";
import { ILocationType } from "../../core/models/ease-models";

export class LocationTreeItem extends TreeviewItem {
  constructor(private item: TreeItem,
              public icon: string,
              public type: ILocationType) {
    super(item);
  }

  get id(): number {
    return this.item.value;
  }

  get name(): string {
    return this.item.text;
  }
}
