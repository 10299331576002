import { Component, Inject, Input, OnInit } from "@angular/core";
import { LocalizeService } from "../../core/services/localize.service";
import { CurrentInfoService } from "../../core/services/current.service";
import { IDocumentRev, IGridsterReportPanelLayoutItem, IReportPanelSettings } from "../../core/models/ease-models";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ReportNotifyService, ReportPanelDialogMessageType } from "@shared/reports/report-notify.service"

@Component({
    templateUrl: "./chart-settings-modal.component.html",
    styleUrls: ["./report-dashboard.component.scss"],
})
export class ChartSettingsModalComponent implements OnInit {

    public widgetFilters: IReportPanelSettings;
    public panelItemInfo: IGridsterReportPanelLayoutItem;

    private selectedDocument: IDocumentRev;

    constructor(public dialogRef: MatDialogRef<ChartSettingsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private localize: LocalizeService,
                private Current: CurrentInfoService, private reportNotifyService: ReportNotifyService) {

    }
    public ngOnInit(): void {
        this.widgetFilters = this.data.widgetFilters;
        this.panelItemInfo = this.data.panelItemInfo;
    }

    public onRemovePanelClick(): void {
        if (this.panelItemInfo != null && this.panelItemInfo.Guid != null) {
            this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.panelRemove, value: this.panelItemInfo.Guid});
        }
    }

    public onMetricTypeUpdate(): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.metricTypeUpdate, value: this.widgetFilters, panelGuid: this.panelItemInfo.Guid});
    }

    public onCategoryGroupChange(depth: any): void {
        if (this.widgetFilters != null && this.widgetFilters.selectedCategoryGroupID != null) {
            this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.categoryGroupChanged, value: { selectedCategoryGroupID: this.widgetFilters.selectedCategoryGroupID, depth }, panelGuid: this.panelItemInfo.Guid});
        }
    }

    public onShowAuditListButtonClick(): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.panelShowAuditListClicked, value: null, panelGuid: this.panelItemInfo.Guid});
    }

    public onDocumentDropdownChange(): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.documentDropdownChanged, value: { documentRev: this.selectedDocument}, panelGuid: this.panelItemInfo.Guid});
    }

    public onShowDataLabelChange(): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.dashboardFilterChanged, value: 0, panelGuid: this.panelItemInfo.Guid});
    }

    public onEnableStackByStatusOptionChange(enableStackByStatusOption: boolean): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.stackByStatusOptionChanged, value: enableStackByStatusOption, panelGuid: this.panelItemInfo.Guid});
    }

    public onStackByChange(value: number): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.stackByChanged, value, panelGuid: this.panelItemInfo.Guid});
    }

    public onDimensionTypeChange(value: number) {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.dimensionTypeChanged, value, panelGuid: this.panelItemInfo.Guid});
    }

    public onDateRangeTypeChange(): void {
        this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.dateRangeTypeChanged, value: null, panelGuid: this.panelItemInfo.Guid});
    }
}
