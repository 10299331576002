export const mapChangeNames = [
    { key: 1, val: "_Created_" },
    { key: 2, val: "_Updated_" },
    { key: 3, val: "_Deleted_" }];

export const fieldNames = [
    { key: "ID", val: "_ID_" },
    { key: "SiteID", val: "_Site_" },
    { key: "DepartmentID", val: "_Department_" },
    { key: "FirstName", val: "_FirstName_" },
    { key: "LastName", val: "_LastName_" },
    { key: "Email", val: "_Email_" },
    { key: "LocationID", val: "_Location_" },
    { key: "ManagerID", val: "_Manager_" },
    { key: "CreatedByID", val: "_CreatedBy_" },
    { key: "DocumentTypeID", val: "_DocumentType_" },
    { key: "ResponsiblePartyUserID", val: "_ResponsibleParty_" },
    { key: "AuditID", val: "_Audit_" },
    { key: "DateRequired", val: "_DateRequired_" },
    { key: "Status", val: "_Status_" },
    { key: "Description", val: "_Description_" },
    { key: "RankID", val: "_Urgency_" },
    { key: "ScopeID", val: "_Scope_" },
    { key: "UserID", val: "_User_" },
    { key: "AuditAnswerID", val: "_AuditAnswer_" },
    { key: "RoleID", val: "_Role_" },
    { key: "AuditorUserID", val: "_Auditor_" },
    { key: "FailureCategoryID", val: "_FailureCategory_" },
    { key: "FailureModeID", val: "_FailureMode_" },
    { key: "ApprovingManagerUserID", val: "_ApprovingManager_" },
    { key: "File", val: "_File_" },
    { key: "TaskApprover", val: "_TaskApprover_" },
    { key: "TaskOwner", val: "_TaskOwner_" },
    { key: "SeverityCustomItemID", val: "_Severity_" },
    { key: "EscapePointCustomItemID", val: "_EscapePoint_" },
    { key: "EscapeValueCustomItemID", val: "_EscapeValue_" },
    { key: "DocumentID", val: "_Document_" },
    { key: "CreatedByUserID", val: "_Initiator_" },
    { key: "CompanySiteID", val: "_Customer_" },
    { key: "IsEnabled", val: "_Enabled_" },
];

export const entityNames = [
    { key: 1, val: "_Audit_" },
    { key: 2, val: "_Question_" },
    { key: 3, val: "_Answer_" },
    { key: 4, val: "_Job_" },
    { key: 5, val: "_Customer_" },
    { key: 6, val: "_User_" },
    { key: 7, val: "_Mitigation_" },
    { key: 8, val: "_Shift_" },
    { key: 9, val: "_ShiftManager_" },
    { key: 10, val: "_Site_" },
    { key: 11, val: "_Location_" },
    { key: 12, val: "_Layer_" },
    { key: 13, val: "_Department_" },
    { key: 14, val: "_Scope_" },
    { key: 15, val: "_UserRole_" },
    { key: 16, val: "_Role_" },
    { key: 17, val: "_Urgency_" },
    { key: 18, val: "_IssueClassification_" },
    { key: 19, val: "_CustomCategory_" },
    { key: 20, val: "_CustomItem_" },
    { key: 21, val: "_DocumentRev_" },
    { key: 22, val: "_CAPA_" },
    { key: 23, val: "_Owner_" },
    { key: 24, val: "_Approver_" },
    { key: 25, val: "_Attachment_" },
    { key: 26, val: "_CustomField_" },
    { key: 27, val: "_CAPATask_" },
    { key: 29, val: "_Attachment_" },
    { key: 30, val: "_Association_" },
    { key: 31, val: "_Association_" },
    { key: 32, val: "_Association_" },
    { key: 33, val: "_Settings_" },
];