import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";

// Models
import { AuthenticationPayload } from "../../models/user";

@Component({
  selector: "ease-login-form",
  templateUrl: "./login-form.html",
  styleUrls: [ "./login-form.scss" ],
})
export class LoginFormComponent implements OnInit {
  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;

    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() errorMessage: string | null;
  @Input() message: string | null;
  @Input()
  set login(value: string | null) {
    this.form.get("Login").setValue(value);
  }
  @Input()
  set rememberMe(value: string | null) {
    this.form.get("RememberMe").setValue(value);
  }
  @Output() submitted = new EventEmitter<AuthenticationPayload>();

  isPending: boolean;

  form: FormGroup = new FormGroup({
    Login: new FormControl(""),
    Password: new FormControl(""),
    RememberMe: new FormControl(false),
  });

  constructor() {
  }

  ngOnInit() {
  }

  submit() {
    this.submitted.emit(this.form.value);
  }
}
