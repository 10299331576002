import { environment } from "environments/environment";

import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from "@ngrx/store";

import * as fromRouter from "@ngrx/router-store";

import { RouterStateUrl } from "@shared/utils";

import { storeFreeze } from "ngrx-store-freeze";

import * as fromLayout from "@core/reducers/layout";
import * as fromTabs from "@core/reducers/tabs";

export interface State {
  layout: fromLayout.State;
  tabs: fromTabs.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  layout: fromLayout.reducer,
  tabs: fromTabs.reducer,
  router: fromRouter.routerReducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function(state: State, action: any): State {
        // console.log('state', state);
        // console.log('action', action);

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [ logger, storeFreeze ]
  : [];

/**
 * Router Reducers
 */
export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>("router");

export const getCurrentUrl = createSelector(
  getRouterState,
  state => state.state && state.state.url,
);

export const getRouterRoot = createSelector(
  getRouterState,
  state => {
    return state && state.state && state.state.root;
  },
);

/**
 * Layout Selectors
 */
export const getLayoutState = createFeatureSelector<fromLayout.State>("layout");

export const getShowSidenav = createSelector(
  getLayoutState,
  fromLayout.getShowSidenav,
);

export const getProgress = createSelector(
  getLayoutState,
  fromLayout.getProgress,
);

/**
 * Tabs Selectors
 */
export const getTabsState = createFeatureSelector<fromTabs.State>("tabs");

export const getTabs = createSelector(
  getTabsState,
  fromTabs.getEntities,
);
