import { AuthActionsUnion, AuthActionTypes } from "./../actions/auth";
import { SessionActionsUnion, SessionActionTypes } from "./../actions/session";

export interface State {
  authenticated: boolean;
  authorized: boolean;
  language: string;
  login: string | null;
  rememberMe: boolean | null;
  token: string | null;
  tokenRefreshDate: string | null;
  tokenRefreshPending: boolean;
}

export const initialState: State = {
  authenticated: false,
  authorized: false,
  language: "en-US",
  login: null,
  rememberMe: null,
  token: null,
  tokenRefreshDate: null,
  tokenRefreshPending: false,
};

export function reducer(state = initialState, action: AuthActionsUnion | SessionActionsUnion): State {
  switch (action.type) {
    case SessionActionTypes.RestoreSessionSuccess: {
      return {
        ...state,
        authenticated: true,
        authorized: true,
        language: action.payload.Language,
        login: action.payload.Login,
        rememberMe: action.payload.RememberMe,
        token: action.payload.Token,
        tokenRefreshDate: action.payload.TokenRefreshDate,
      };
    }

    case SessionActionTypes.RestoreSessionFailure: {
      return {
        ...state,
        language: action.payload.Language,
        login: action.payload.Login,
        rememberMe: action.payload.RememberMe,
      };
    }

    case AuthActionTypes.AuthenticateSuccess:
    case AuthActionTypes.AuthenticateSSOSuccess:
    case AuthActionTypes.ImpersonateSuccess: {
      return {
        ...state,
        authenticated: true,
        language: action.payload.Language,
        login: action.payload.Login,
        rememberMe: action.payload.RememberMe,
        token: action.payload.Token,
        tokenRefreshDate: action.payload.TokenRefreshDate,
      };
    }

    case AuthActionTypes.AuthorizeSuccess: {
      return {
        ...state,
        authorized: true,
      };
    }

    case AuthActionTypes.RefreshToken: {
      return {
        ...state,
        tokenRefreshPending: true,
      };
    }

    case AuthActionTypes.RefreshTokenSuccess: {
      return {
        ...state,
        token: action.payload.Token,
        tokenRefreshDate: action.payload.TokenRefreshDate,
        tokenRefreshPending: false,
      };
    }

    case AuthActionTypes.RefreshTokenFailure: {
      return {
        ...state,
        tokenRefreshPending: false,
      };
    }

    case AuthActionTypes.Logout:
    case AuthActionTypes.LogoutFromDropDown:
    case AuthActionTypes.LogoutFromInterceptor:
    case AuthActionTypes.LogoutFromGuard: {
      return {
        ...initialState,
        rememberMe: state.rememberMe,
        login: state.login,
      };
    }

    case SessionActionTypes.ChangeLanguageSuccess: {
      return {
        ...state,
        language: action.payload.user.Language,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAuthenticated = (state: State) => state.authenticated;
export const getAuthorized = (state: State) => state.authorized;
export const getToken = (state: State) => state.token;
export const getTokenRefreshDate = (state: State) => state.tokenRefreshDate;
export const getTokenRefreshPending = (state: State) => state.tokenRefreshPending;
export const getLanguage = (state: State) => state.language;
export const getLogin = (state: State) => state.login;
export const getRememberMe = (state: State) => state.rememberMe;
