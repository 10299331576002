import { Injectable } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import { ReplaySubject,  Observable } from "rxjs";
import { IAuditConfig } from "../../models/ease-models";
import { ApiService } from "../api.service";

@Injectable()
export class AuditConfigService {

    constructor(private api: ApiService,
                private log: LoggerService) {

    }

    //gets a list of audit configs
    public getAuditConfig(): Observable<IAuditConfig[]> {

        const result = new ReplaySubject<IAuditConfig[]>(1);

        this.log.debug("get audit config from server");
        this.api.Query("AuditConfiguration", {}, (data: IAuditConfig[]) => {
            if (data && data.length > 0) {
                result.next(data);
                result.complete();
            } else {
                this.log.debug("no audit details found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

}
