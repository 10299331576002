// Models
import { ApiError } from "@core/models/api";

import { AuthActionTypes, AuthActionsUnion } from "../actions/auth";

export interface State {
  error: ApiError | null;
  pending: boolean;
  password: string | null;
  confirmPassword: string | null;
  passwordsMatch: boolean;
  haveNumber: boolean;
  haveLowercase: boolean;
  haveUppercase: boolean;
  haveSpecialChar: boolean;
  haveLength: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  password: null,
  confirmPassword: null,
  passwordsMatch: false,
  haveNumber: false,
  haveLowercase: false,
  haveUppercase: false,
  haveSpecialChar: false,
  haveLength: false,
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {
    case AuthActionTypes.CreatePassword:
    case AuthActionTypes.ChangePassword: {
      return {
        ...state,
        pending: true,
      };
    }

    case AuthActionTypes.CreatePasswordSuccess:
    case AuthActionTypes.ChangePasswordSuccess: {
      return {
        ...state,
        pending: false,
      };
    }

    case AuthActionTypes.CreatePasswordFailure:
    case AuthActionTypes.ChangePasswordFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    case AuthActionTypes.PasswordChanged: {
      const { password, confirmPassword } = action.payload;

      return {
        ...state,
        error: null,
        password,
        confirmPassword,
        passwordsMatch: password === confirmPassword,

        haveNumber: /\d/.test(password),
        haveLowercase: /[a-z]/.test(password),
        haveUppercase: /[A-Z]/.test(password),
        haveSpecialChar: /\!|\@|\#|\$|\%|\^|\&|\*|\-|\_|\{|\}|\[|\]/.test(password),
        haveLength: password.length > 7 && password.length < 25,
      };
    }

    default: {
      return state;
    }
  }
}

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;
export const getHaveNumber = (state: State) => state.haveNumber;
export const getHaveLowercase = (state: State) => state.haveLowercase;
export const getHaveUppercase = (state: State) => state.haveUppercase;
export const getHaveSpecialChar = (state: State) => state.haveSpecialChar;
export const getHaveLength = (state: State) => state.haveLength;
export const getPasswordsMatch = (state: State) => state.passwordsMatch;
