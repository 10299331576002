import { Component, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { MitigationArtifactService } from "@core/services/common/mitigation-artifact.service";
import { MitigationDetailService } from "@core/services/common/mitigation-detail.service";
import { ApiService, AppSettingsService, IdentityService, CurrentInfoService, LocalService, LocalizeService,
         LoggerService, NotifyService, StorageKey, StorageService, ToolsService } from "@core/services";
import { AuditResultComponent } from "@app/shared/results/audit-result/audit-result.component";
import { ImageDialogComponent } from "../../shared/dialogs/image-dialog/image-dialog.component";
import { MitigationDetailComponent } from "@app/shared/results/mitigation-detail/mitigation-detail.component";
import { MitigationActionPanelService } from "./mitigation-action-panel.service";

import * as _ from "lodash";
import * as moment from "moment";
import {
  IArtifact,
  IAuditAnswer,
  IAuditAnswerArtifact,
  IClientAuditAnswer,
  IClientAuditQuestion,
  ICustomCategory,
  ICustomCategoryLineItem,
  ICustomItem,
  IFileFormat,
  IMitigationActivity,
  IMitigationArtifact,
  IMitigationDetail,
  IRank,
  IUser,
} from "../../core/models/ease-models";

import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { DialogConfigSm } from "../../shared/dialogs";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import { take } from "rxjs/operators";

export class EaseMitigationUploaderObject {
  public mitigationID: number;
  public uploader: any;
}

@Component({
  selector: "ease-mitigation-action-panel",
  templateUrl: "./mitigation-action-panel.component.html",
  providers: [MitigationActionPanelService],
})

export class MitigationActionPanelComponent implements OnInit {
  @Input() public mitigationDetail: IMitigationDetail;
  @Input() public question: IClientAuditQuestion;
  @Input() public isAdhoc: boolean;
  @Input() public parent: any;
  @Output() public refreshResults: EventEmitter<any> = new EventEmitter<any>();

  loading;
  token: string;

  public isRejection: boolean = false;
  public ranks: IRank[];
  public current: any;
  /*ICurrentModels;*/
  public isRankVisible: boolean = false;
  public managers: IUser[];
  public responsibleParties: any[];
  public isCountermeasuresEnabled: boolean = false;
  public isManagerRole: boolean = false;
  public customItems: ICustomItem[];
  public uploaderObjs: EaseMitigationUploaderObject[];
  public filteredUploaderObjs: EaseMitigationUploaderObject[];
  public fileFormats: IFileFormat[];
  public mitigation: IMitigationActivity;
  public auditResult: AuditResultComponent;
  public mitigationDetailComponent: MitigationDetailComponent;

  constructor(private store: Store<fromAuth.State>,
              private currentInfo: CurrentInfoService,
              private localize: LocalizeService,
              private api: ApiService,
              private appSettings: AppSettingsService,
              private identity: IdentityService,
              private tools: ToolsService,
              private zone: NgZone,
              private storage: StorageService,
              private log: LoggerService,
              private local: LocalService,
              private mitigationDetailService: MitigationDetailService,
              private mitigationArtifactService: MitigationArtifactService,
              private dialog: MatDialog,
              private mitigationActionService: MitigationActionPanelService) {

    this.store.pipe(
      select(fromAuth.getToken),
      take(1),
    ).subscribe(value => this.token = value);
  }

  public ngOnInit() {
    if (this.isAdhoc) {
      this.mitigationDetailComponent = this.parent;
    } else {
      this.auditResult = this.parent;
    }
    this.initMitigation();
  }

  public filteredFiles(uploaderObjs: any[]): any[] {
    const filtered = uploaderObjs.filter((uploader: any) => uploader.mitigationID == this.mitigation.ID);
    return filtered;
  }

  public initMitigation() {
    this.mitigation = this.mitigationDetail.Mitigation;
    this.initUploader();
    this.mitigationDetailService.getMitigationDetailByID(this.mitigation.ID, "Mitigations").subscribe((data: IMitigationDetail) => {
      this.zone.run(() => {
        if (data) {
          this.getManagers(data.Managers);
          this.getCustomItems(data.CountermeasuresCustomCategoryLineItems);
          this.ranks = data.Ranks;
          if (this.ranks && this.ranks.length > 1) {
            this.isRankVisible = true;
          }

          //init images
          this.mitigation.Images = [];
          this.formatMitigation();

          //set uploaded files attached when app is offline
          if (!this.tools.isOnline()) {
            this.mitigationArtifactService.getMitigationArtifactByMitigationID(this.mitigation.ID).subscribe((data: any) => {
              this.zone.run(() => {
                _.each(data.toAddArtifacts, (item: any) => {
                  if (item.ID == 0) {
                    this.mitigation.uploadedFiles.push({
                      ID: item.ID,
                      MitigationID: item.MitigationID,
                      ArtifactID: 0,
                      Url: item.Url,
                      Filename: item.imageName,
                      FileFormatID: item.FileFormatID
                    });
                  }
                });
              });
            });
          }
        }
      });
    });

    this.current = this.currentInfo;
    this.mitigation.ResolutionPeriod = moment(this.mitigation.DateOpened).add(this.mitigation.Rank.ResolutionPeriod, "day").utc().toDate();
    this.mitigation.DateOpened = moment(this.mitigation.DateOpened).utc().toDate();
    this.isManagerRole = this.current.info.roleIsManager;
    this.isCountermeasuresEnabled = this.appSettings.currentAppSettings.isCountermeasuresEnabled;
  }

  public formatMitigation(): void {
    let statusString = "";

    if (this.mitigation.Comment != null) {
      try {
        const text = this.createHyperLinks(this.mitigation.Comment);
        this.mitigation.CommentFormatted = text.replace(/\n/g, "<br/>");
      } catch (e) {

      }
    }

    if (this.mitigation.ApprovingPartyComment != null) {
      try {
        const text = this.createHyperLinks(this.mitigation.ApprovingPartyComment);
        this.mitigation.ApprovingPartyCommentFormatted = text.replace(/\n/g, "<br/>");
      } catch (e) {

      }
    }

    if (!this.mitigation.PastDue) {
      statusString = this.localize.getLocalizedString("_OnTime_");
    } else {
      statusString = this.localize.getLocalizedString("_PastDue_");
    }

    switch (this.mitigation.Status) {
      case 0:
        statusString += " - " + this.localize.getLocalizedString("_Open_");
        break;
      case 1:
        statusString += " - " + this.localize.getLocalizedString("_Closed_");
        break;
      case 2:
        statusString += " - " + this.localize.getLocalizedString("_Pending_");
        break;
    }

    this.mitigation.StatusString = statusString;

  }

  public commentAndCloseMitigation(isApprovalRequired: boolean = false): void {

    this.mitigation.Status = isApprovalRequired || (this.mitigation.Rank != null && this.mitigation.Rank.IsAdditionalApprovalEnabled) ? 2 : 1;

    let filter: any[] = [];
    if (this.isAdhoc) {
      filter.push(this.mitigation);
    } else {
      filter = this.question.AuditAnswer.MitigationActivity.filter(data => data.ID == this.mitigation.ID);
    }
    if (filter.length > 0) {
      filter[0] = this.mitigation;
    }

    this.formatMitigation();
    if (this.customItems && this.mitigation.CountermeasureCustomItemID) {
      const cmItems = this.customItems.filter((item: ICustomItem) => item.ID == this.mitigation.CountermeasureCustomItemID);
      if (cmItems.length > 0) {
        this.mitigation.CountermeasureCustomItem = cmItems[0];
      }
    }

    this.mitigation.IsNotSynced = !this.tools.isOnline();
    this.saveMitigationInFileSystem().subscribe(() => {
      this.updateResponsibleParty();
      if (this.tools.isOnline()) {
        this.mitigationActionService.updateMitigation(this.mitigation, this.isAdhoc, this.isAdhoc).subscribe((mitigation: IMitigationActivity) => {
          this.refreshMitigation(mitigation);
          this.refreshResults.emit(this.question);
        });
      } else {
        this.refreshResults.emit(this.question);
      }
    });
  }

  public saveMitigationDetail(responsiblePartyUserID) {
    this.mitigation.ResponsiblePartyUserID = responsiblePartyUserID;
    const managers = this.managers.filter(manager => manager.ID === responsiblePartyUserID);
    if (managers.length > 0) {
      this.mitigation.ResponsiblePartyUser = managers[0];
      this.mitigation.ResponsbilePartyFullName = this.mitigation.ResponsiblePartyUser.FirstName + " " + this.mitigation.ResponsiblePartyUser.LastName;
    }
    this.saveMitigation();
  }

  public saveMitigation(newRankID: string = ""): void {
    let isUpdateApprovingParty: boolean = false;
    if (newRankID !== "") {
      isUpdateApprovingParty = true;
      const selectedRank = this.ranks.filter((rank: IRank) => rank.ID === parseInt(newRankID, 10));
      if (selectedRank.length > 0) {
        this.mitigation.Rank = selectedRank[0];
        this.mitigation.ResolutionPeriod = moment(this.mitigation.DateOpened).add(selectedRank[0].ResolutionPeriod, "d").utc().toDate();
        if (selectedRank[0].IsAdditionalApprovalEnabled) {
          if (this.isAdhoc) {
            this.mitigation.ApprovingManagerUserID = this.mitigation.CreatorID;
          }
        } else {
          this.mitigation.ApprovingManagerUserID = 0;
        }
      }
    }

    this.formatMitigation();
    this.saveMitigationInFileSystem().subscribe(() => {
      this.updateResponsibleParty();
      if (this.tools.isOnline()) {
        this.mitigationActionService.updateMitigation(this.mitigation, isUpdateApprovingParty, this.isAdhoc).subscribe((mitigation: IMitigationActivity) => {
          this.refreshMitigation(mitigation);
          this.refreshResults.emit(this.question);
        });
      } else {
        this.refreshResults.emit(this.question);
      }
    });
  }

  public approveRejectMitigation(isApproved: boolean): void {
    if (isApproved) {
      this.mitigation.Status = 1;
      this.formatMitigation();
      if (this.tools.isOnline()) {
        this.saveMitigationInFileSystem().subscribe(() => {
          this.mitigationActionService.updateMitigation(this.mitigation, false, this.isAdhoc).subscribe((mitigation: IMitigationActivity) => {
            this.refreshMitigation(mitigation);
            this.refreshResults.emit(this.question);
          });
        });
      } else {
        this.saveMitigationInFileSystem().subscribe(() => {
          this.refreshResults.emit(this.question);
        });
      }
    } else {
      this.isRejection = true;
      const approvingPartyComment = this.mitigation.ApprovingPartyComment;
      if (approvingPartyComment == null || approvingPartyComment == "" || (approvingPartyComment != null && approvingPartyComment.trim().length == 0)) {
        //this.toaster.pop('error', '', this.localize.getLocalizedString("_CommentRequiredOnRejection_"));
      } else {
        this.mitigation.Status = 0;
        this.formatMitigation();
        if (this.tools.isOnline()) {
          this.saveMitigationInFileSystem().subscribe(() => {
            this.mitigationActionService.sendRejectMitigationEmail(this.mitigation, this.isAdhoc).subscribe((mitigation: IMitigationActivity) => {
              this.refreshMitigation(mitigation);
              this.refreshResults.emit(this.question);
            });
          });
        } else {
          this.saveMitigationInFileSystem().subscribe(() => {
            this.refreshResults.emit(this.question);
          });
        }
      }
    }
  }

  public removeFileFromMitigation(img: any, ma: any) {
    const id = img.ID;
    if (id > 0) {
      const index = ma.uploadedFiles.indexOf(img);
      ma.uploadedFiles.splice(index, 1);

      //delete file
      if (this.tools.isOnline()) {
        this.api.Delete("mitigationartifact", id, (deleteData: any) => {

        }, () => {
        }, () => {
        });
      } else {
        this.saveFileToDeleteInFileSystem(img);
      }

      const artifactKey = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", img.MitigationID);
      this.getFileContent(artifactKey, (data: any[]) => {
        //delete file if array cound in the file == 1
        if (data.length == 1) {
          if (this.tools.isApp()) {
            this.storage.deleteObject(artifactKey).subscribe(() => {
            });
          } else {
            const keyStr: string = this.storage.keyAsString(artifactKey);
            this.local.removeItem(keyStr);
          }
        } else {
          //store data with the remaining array content
          const deletedArtifact = data.filter(item => item.MitigationID == ma.ID);
          if (deletedArtifact.length > 0) {
            data.splice(data.indexOf(deletedArtifact), 1);
          }

          if (this.tools.isApp()) {
            this.storage.storeObject(artifactKey, data).subscribe(() => {
            }, (err: any) => {
              this.log.error("error in uploadedFileArray " + JSON.stringify(err));
            });
          } else {
            const keyStr: string = this.storage.keyAsString(artifactKey);
            this.local.setItem(keyStr, data);
          }
        }
      });
    } else if (img.MitigationID > 0) {
      this.removeMitigationFileFromFileSystem(img);

      const index = ma.uploadedFiles.indexOf(img);
      ma.uploadedFiles.splice(index, 1);
    }
  }

  public getMitigationImage(img: IArtifact): void {
    if (img) {
      const config = new DialogConfigSm();
      config.panelClass = "img-dialog";
      config.data = img.Url;

      this.dialog.open(ImageDialogComponent, config);
    }
  }

  private updateResponsibleParty() {

    let ma: IMitigationActivity[] = [];
    if (this.isAdhoc) {
      ma.push(this.mitigation);
    } else {
      ma = this.question.AuditAnswer.MitigationActivity.filter(data => data.ID === this.mitigation.ID);
    }

    if (ma.length > 0) {
      ma[0] = this.mitigation;
      const user = this.responsibleParties.filter(item => item.ID === ma[0].ResponsiblePartyUserID);
      if (user.length > 0) {
        ma[0].ResponsiblePartyUser = user[0];
        ma[0].ResponsbilePartyFullName = user[0].FirstName + " " + user[0].LastName;
      }
    }
  }

  private createHyperLinks(description: string): string {
    let ok = description.indexOf(")[");
    const http = "http://";
    const https = "https://";
    while (ok != -1) {
      const questionFirstPart = description.substr(0, description.indexOf("("));
      const questionText = description.substr(ok + 1);
      let href = questionText.substr(1, questionText.indexOf("]") - 1);
      if (href.indexOf(http) == -1 && href.indexOf(https) == -1) {
        href = http + href;
      }
      const textPart = description.substr(0, description.indexOf(")"));
      const text = textPart.substr(description.indexOf("(") + 1);
      const hiperLink = "<a href='" + href + "' target='_blank'>" + text + "</a>";
      const questionSecondPart = questionText.substr(questionText.indexOf("]") + 1);
      description = questionFirstPart + hiperLink + questionSecondPart;
      ok = description.indexOf(")[");
    }
    return description;
  }

  private saveMitigationInFileSystem(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    const key = this.storage.buildCurrentStorageKey("Mitigations", this.mitigation.ID);
    this.getFileContent(key, (data: IMitigationDetail) => {
      //set date completed if status is closed
      if (this.mitigation.Status == 1) {
        const dateCompleted = moment().utc().toDate();
        this.mitigation.DateClosed = dateCompleted;
      }

      data.Mitigation = this.mitigation;
      data.Mitigation.IsNotSynced = !this.tools.isOnline();

      if (this.tools.isApp()) {
        this.storage.storeObject(key, data).subscribe(() => {
          //update mitigation list json file
          key.entityID = "MitigationDetails";
          this.storage.retrieveObject(key).subscribe((items: IMitigationDetail[]) => {
            const detail = items.filter((ma: IMitigationDetail) => ma.Mitigation.ID == this.mitigation.ID);
            if (detail.length > 0) {
              detail[0].Mitigation = this.mitigation;
              this.storage.storeObject(key, items).subscribe(() => {
                result.next(true);
                result.complete();
              });
            } else {
              result.next(true);
              result.complete();
            }
          }, (err: any) => {
            this.log.error("failed to retrieve mitigation detail list in saveMitigationInFileSystem" + JSON.stringify(err));
            result.error(err);
          });

        }, (err: any) => {
          this.log.error("error in saveMitigationActivityInFileSystem " + JSON.stringify(err));
          result.error(err);
        });
      } else {
        const keyStr: string = this.storage.keyAsString(key);
        this.local.setItem(keyStr, data);
        result.next(true);
        result.complete();
      }
    });

    return result.asObservable();
  }

  private initUploader(): void {
    this.uploaderObjs = [];
    const host = this.identity.endpoints.Service;
    const self = this; // we need the current context inside the "onErrorItem" event call

    if (this.auditResult) {
      this.fileFormats = this.auditResult.auditDetail.AcceptedFileFormats;
    } else {
      this.fileFormats = this.mitigationDetail.AcceptedFileFormats;
    }

    let uploadUrl: string;
    if (host.length > 0) {
      uploadUrl = host + "/mitigationartifact";
    }

    const maUploader = new FileUploader({ url: uploadUrl, removeAfterUpload: true });

    const isExists = this.uploaderObjs.filter((ma: any) => ma.ID === this.mitigation.ID).length > 0;
    if (!isExists) {
      this.uploaderObjs.push({
        mitigationID: this.mitigation.ID, uploader: maUploader,
      });
    }

    maUploader.onAfterAddingFile = (item) => {
      if (!self.tools.isOnline()) {
        self.saveUploadedFileToFileSystem(item, this.mitigation);
      } else {
        self.saveUploadedFiles(() => {

        });
      }
    };

    maUploader.onSuccessItem = this.zone.run(() => (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      const data = JSON.parse(response); //success server response
      self.saveUploadedFileToFileSystem(item, this.mitigation, parseInt(response));
    });

    maUploader.onErrorItem = this.zone.run(() => (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      const error = JSON.parse(response); //error server response

      const exceptionErrorMessage = "FileNotSupportedException:";
      if (error && error.Message && error.Message.indexOf(exceptionErrorMessage) != -1) {
        error.Message = error.Message.substring(exceptionErrorMessage.length);
      }

      self.saveUploadedFileToFileSystem(item, this.mitigation);
      //self.saveChanges(self.audit.Status, () => {
      //});
    });

    maUploader.removeFromQueue = this.zone.run(() => (item: FileItem) => {
      const artifactKey = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", self.mitigation.ID);
      this.getFileContent(artifactKey, (data: any[]) => {
        //delete file if array cound in the file == 1
        if (data.length == 1) {
          if (this.tools.isApp()) {
            this.storage.deleteObject(artifactKey).subscribe(() => {
            });
          } else {
            const keyStr: string = this.storage.keyAsString(artifactKey);
            this.local.removeItem(keyStr);
          }
        } else {
          //store data with the remaining array content
          const deletedArtifact = data.filter(imgData => imgData.imageName == item.file.name);
          if (deletedArtifact.length > 0) {
            data.splice(data.indexOf(deletedArtifact), 1);
          }

          if (this.tools.isApp()) {
            this.storage.storeObject(artifactKey, data).subscribe(() => {
            }, (err: any) => {
              this.log.error("error in uploadedFileArray " + JSON.stringify(err));
            });
          } else {
            const keyStr: string = this.storage.keyAsString(artifactKey);
            this.local.setItem(keyStr, data);
          }
        }

        _.each(self.uploaderObjs, (obj: any) => {
          if (obj.uploader != null && obj.uploader.queue.length > 0) {
            const index = obj.uploader.queue.indexOf(item);
            obj.uploader.queue.splice(index, 1);
          }
        });
      });
    });
  }

  private getManagers(managers: IUser[]): void {
    this.managers = [];
    this.responsibleParties = [];
    _.each(managers, (manager: IUser) => {
      manager.FullName = manager.FirstName + " " + manager.LastName;
      this.responsibleParties.push({ value: manager.ID, text: manager.FullName });
      this.managers.push(manager);
    });
    this.managers = _.orderBy(this.managers, [manager => manager.FullName.toLowerCase()], ["asc"]);
    this.responsibleParties = _.orderBy(this.responsibleParties, [manager => manager.text.toLowerCase()], ["asc"]);
  }

  private getCustomItems(customCategoryLineItems: ICustomCategoryLineItem[]): void {
    this.customItems = [];
    const items: ICustomItem[] = [];
    let firstCustomCategoryID = 0;

    if (customCategoryLineItems.length > 0) {
      firstCustomCategoryID = customCategoryLineItems[0].CustomCategoryID;
    } else {
      this.isCountermeasuresEnabled = false;
    }

    _.forEach(customCategoryLineItems, (li: ICustomCategoryLineItem) => {
      if (li.CustomCategoryID == firstCustomCategoryID) {
        items.push(li.CustomItem);
      }
    });

    this.customItems = items;

    if (this.mitigation.CountermeasureCustomItemID) {
      const cmItems = this.customItems.filter((item: ICustomItem) => item.ID == this.mitigation.CountermeasureCustomItemID);
      if (cmItems.length > 0) {
        this.mitigation.CountermeasureCustomItem = cmItems[0];
      }
    }
  }

  private saveUploadedFileToFileSystem(item: FileItem, mitigation: any, mitigationArtifactID: number = 0): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const file: IMitigationArtifact = {
        ID: mitigationArtifactID,
        MitigationID: mitigation.ID,
        ArtifactID: 0,
        Filename: item._file.name,
        Url: reader.result,
        FileFormatID: this.getFileFormatID(item._file.type)
      };
      if (file.FileFormatID) {//the file we are trying to save to local storage has an accepted format
        mitigation.uploadedFiles.push(file);

        const key = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", mitigation.ID);
        // retrieved saved uploaded files
        let uploadedFileArray: any[] = [];
        this.getFileContent(key, (data: any[]) => {
          uploadedFileArray = data;

          _.each(mitigation.uploadedFiles, (img: any) => {
            const storedUploadedFile = {
              MitigationID: img.MitigationID,
              ID: img.ID,
              imageData: img.Url,
              imageName: img.Filename,
              FileFormatID: img.FileFormatID,
              Url: img.Url
            };
            const index = uploadedFileArray.indexOf(storedUploadedFile);
            if (index == -1) {
              uploadedFileArray.push(storedUploadedFile);
            }
          });

          if (this.tools.isApp()) {
            this.storage.storeObject(key, uploadedFileArray).subscribe(() => {
            }, (err: any) => {
              this.log.error("error in uploadedFileArray " + JSON.stringify(err));
            });
          } else {
            const keyStr: string = this.storage.keyAsString(key);
            this.local.setItem(keyStr, uploadedFileArray);
          }
        });
      } else {//wrong file format
        this.log.debug("wrong file format");
      }
    };
    reader.readAsDataURL(item._file);
  }

  private saveUploadedFiles(callback: any): void {
    _.each(this.uploaderObjs, (obj: any) => {
      const tauth = `Token ${this.token}`;
      if (obj.uploader != null && obj.uploader.queue.length > 0) {
        _.each(obj.uploader.queue, (item: FileItem) => {
          item.headers = [{ name: "MitigationID", value: obj.mitigationID }];
        });
        obj.uploader.authTokenHeader = "Authorization";
        obj.uploader.authToken = tauth;
        obj.uploader.uploadAll();
      }
    });

    // The uploadAll() function has no callback, so just wait a few ms before we return
    setTimeout(() => {
      callback();
    }, 1000);
  }

  //retrieves the format id from the preloaded list, based on the incoming mime type
  private getFileFormatID(mimeType: string): number {
    let result: number = 0;
    const formats = this.fileFormats.filter((fileFormat: IFileFormat) => fileFormat.MimeType == mimeType);
    if (formats && formats.length > 0) {
      result = formats[0].ID;
    }
    return result;
  }

  private getFileContent(key: StorageKey, callback: any): void {
    this.zone.run(() => {
      this.storage.containsObject(key).subscribe((fileExists: boolean) => {
        if (fileExists) {
          this.storage.retrieveObject(key).subscribe((data: any) => {
            if (callback) {
              callback(data);
            }
          }, (err: any) => {
            this.log.error("retrieve key error " + JSON.stringify(err));
            if (callback) {
              callback([]);
            }
          });
        } else {
          if (callback) {
            callback([]);
          }
        }
      });
    });
  }

  private saveFileToDeleteInFileSystem(file: any): void {
    const key = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", "FileToDelete_" + this.mitigation.ID);
    let fileToDeleteArray: any[] = [];
    this.getFileContent(key, (data: any[]) => {
      fileToDeleteArray = data;
      fileToDeleteArray.push(file);

      if (this.tools.isApp()) {
        this.storage.storeObject(key, fileToDeleteArray).subscribe(() => {
        }, (err: any) => {
          this.log.error("error in fileToDeleteArray " + JSON.stringify(err));
        });
      } else {
        const keyStr: string = this.storage.keyAsString(key);
        this.local.setItem(keyStr, fileToDeleteArray);
      }
    });
  }

  private removeMitigationFileFromFileSystem(file: any): void {
    const artifactKey = this.storage.buildCurrentStorageKey("MitigationDetailArtifact", this.mitigation.ID);
    this.getFileContent(artifactKey, (data: any[]) => {
      const oldUploadedFileArray = data;
      const filtered = oldUploadedFileArray.filter((oldData: any) => oldData.imageName == file.Filename && oldData.MitigationID == file.MitigationID);
      if (filtered && filtered.length > 0) {
        const fileIndex = oldUploadedFileArray.indexOf(filtered[0]);
        oldUploadedFileArray.splice(fileIndex, 1);
      }

      if (this.tools.isApp()) {
        this.storage.storeObject(artifactKey, oldUploadedFileArray).subscribe(() => {
        }, (err: any) => {
          this.log.error("error in oldUploadedFileArray " + JSON.stringify(err));
        });
      } else {
        const keyStr: string = this.storage.keyAsString(artifactKey);
        this.local.setItem(keyStr, oldUploadedFileArray);
      }
    });
  }

  private refreshMitigation(mitigation: IMitigationActivity): void {
    this.mitigation = mitigation;
    this.mitigation.ResolutionPeriod = moment(this.mitigation.DateOpened).add(this.mitigation.Rank.ResolutionPeriod, "day").utc().toDate();
    this.mitigation.DateOpened = moment(this.mitigation.DateOpened).utc().toDate();
    if (this.mitigation.ResponsiblePartyUser) {
      this.mitigation.ResponsbilePartyFullName = this.mitigation.ResponsiblePartyUser.FirstName + " " + this.mitigation.ResponsiblePartyUser.LastName;
    }
    this.formatMitigation();
  }
}
