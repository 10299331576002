import { Component } from "@angular/core";

// State
import { Store } from "@ngrx/store";
import * as AuthActions from "../actions/auth";
import * as fromAuth from "../reducers";

// Models
import { SetPassword } from "../models/user";

@Component({
  selector: "ease-create-password",
  template: `
    <ease-set-password (submitted)="onSubmitted($event)">
      <mat-card-title class="title">
        {{ "_Auth:CreatePassword:Title_" | i18n }}
      </mat-card-title>
      <mat-card-subtitle class="subtitle">
        {{ "_Auth:CreatePassword:SubTitle_" | i18n }}
      </mat-card-subtitle>
      <span class="submit">
        {{ "_Auth:CreatePassword:Submit_" | i18n }}
      </span>
    </ease-set-password>
  `,
})
export class CreatePasswordComponent {
  constructor(private store: Store<fromAuth.State>) {
  }

  onSubmitted($event: SetPassword) {
    this.store.dispatch(new AuthActions.CreatePassword($event));
  }
}
