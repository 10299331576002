import { Observable,  of } from "rxjs";

import {
  map,
  catchError,
  switchMap,
  tap,
} from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

// State
import { Store } from "@ngrx/store";
import { Effect, Actions, ofType } from "@ngrx/effects";

import * as fromAuth from "../reducers";

import {
  RestoreSession,
  RestoreSessionSuccess,
  RestoreSessionFailure,

  ChangeLanguage,
  ChangeLanguageSuccess,
  ChangeLanguageFailure,

  GetTranslation,
  GetTranslationSuccess,
  GetTranslationFailure,

  GetCustomTranslation,
  GetCustomTranslationSuccess,
  GetCustomTranslationFailure,

  SessionActionTypes,
} from "../actions/session";

// Services
import { LocalizeService, CurrentInfoService, LocalService } from "@core/services";
import { AuthService } from "../services/auth.service";

// Models
import {
  UserSession,
} from "../models/user";

@Injectable()
export class SessionEffects {
  @Effect()
  init$ = this.actions$.pipe(
    ofType(SessionActionTypes.InitApp),
    switchMap(() => of(new RestoreSession())),
  );

  @Effect()
  restore$ = this.actions$.pipe(
    ofType(SessionActionTypes.RestoreSession),
    switchMap(() => this.getSessionData().pipe(
      map((session: UserSession) => new RestoreSessionSuccess(session)),
      catchError(err => of(new RestoreSessionFailure(err.session))),
    )),
  );

  @Effect()
  restoreFailure$ = this.actions$.pipe(
    ofType(SessionActionTypes.RestoreSessionFailure),
    map((action: RestoreSessionFailure) => action.payload),
    map(session => new GetTranslation(session.Language)),
  );

  @Effect()
  restoreSuccess$ = this.actions$.pipe(
    ofType(SessionActionTypes.RestoreSessionSuccess),
    map((action: RestoreSessionSuccess) => action.payload),
    tap(session => {
      this.current.info = session.User;
    }),
    switchMap(session => [
      new GetTranslation(session.Language),
      new GetCustomTranslation(session.User.activeSite.ID),
    ]),
  );

  @Effect()
  changeLanguage$ = this.actions$.pipe(
    ofType<ChangeLanguage>(SessionActionTypes.ChangeLanguage),
    map((action: ChangeLanguage) => action.payload),
    switchMap(payload => {
      return this.auth.changeLanguage(payload.Language).pipe(
        map(response => new ChangeLanguageSuccess({
          user: response,
          ActiveSiteID: payload.ActiveSiteID,
        })),
        catchError(error => of(new ChangeLanguageFailure(error))),
      );
    }),
  );

  @Effect()
  changeLanguageSuccess$ = this.actions$.pipe(
    ofType<ChangeLanguageSuccess>(SessionActionTypes.ChangeLanguageSuccess),
    map((action: ChangeLanguageSuccess) => action.payload),
    tap(payload => this.local.setItem("Language", payload.user.Language)),
    switchMap(payload => [
      new GetTranslation(payload.user.Language),
      new GetCustomTranslation(payload.ActiveSiteID),
    ]),
  );

  @Effect()
  getTranslation$ = this.actions$.pipe(
    ofType<GetTranslation>(SessionActionTypes.GetTranslation),
    map((action: GetTranslation) => action.payload),
    switchMap(code => {
      return this.localize.initTranslations(code).pipe(
        map(response => new GetTranslationSuccess(response)),
        catchError(error => of(new GetTranslationFailure(error))),
      );
    }),
  );

  @Effect()
  getCustomTranslation$ = this.actions$.pipe(
    ofType<GetCustomTranslation>(SessionActionTypes.GetCustomTranslation),
    map((action: GetCustomTranslation) => {
      return action.payload;
    }),
    switchMap(siteId => {
      return this.localize.getCustomTranslations(siteId).pipe(
        map(response => new GetCustomTranslationSuccess(response)),
        catchError(error => of(new GetCustomTranslationFailure(error))),
      );
    }),
  );

  constructor(private actions$: Actions,
              private store: Store<fromAuth.State>,
              private router: Router,
              private http: HttpClient,
              private auth: AuthService,
              private localize: LocalizeService,
              private local: LocalService,
              private current: CurrentInfoService) {
  }

  private getSessionData(): Observable<UserSession> {
    const RememberMeFromStorage = JSON.parse(this.local.getItem("RememberMe"));

    const session: UserSession = {
      Language: JSON.parse(this.local.getItem("Language")) || null,
      Login: JSON.parse(this.local.getItem("Login")) || null,
      Token: JSON.parse(this.local.getItem("Token")) || null,
      TokenRefreshDate: JSON.parse(this.local.getItem("TokenRefreshDate")) || null,
      RememberMe: typeof RememberMeFromStorage === "boolean" ? RememberMeFromStorage : null,
      User: JSON.parse(this.local.getItem("User")) || null,
    };

    return Observable.create(observer => {
      if (session.Token && session.TokenRefreshDate && session.User) {
        observer.next(session);
        observer.complete();
      } else {
        observer.error({ session });
      }
    });
  }
}
