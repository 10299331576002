import { Injectable } from "@angular/core";

// Material
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from "@angular/material";

// Services
import { LocalizeService } from "../localize.service";

@Injectable()
// The purpose of this service is to wrap the snack bar functionality
// in case we ever decide to change it to something other than a snack bar
export class UiNotificationService {

    constructor(private localize: LocalizeService,
                private snackBar: MatSnackBar) {

    }

    public open(message: string, hideCloseButton: boolean = false, duration: number = 5000): MatSnackBarRef<SimpleSnackBar> {

      const config = new MatSnackBarConfig();

      config.horizontalPosition = "center";
      config.verticalPosition = "bottom";
      config.duration = duration;

      const i18nMessage = this.localize.getLocalizedString(message);
      let closeMessage = this.localize.getLocalizedString("_Close_");

      if (hideCloseButton) {
          closeMessage = "";
      }
      return this.snackBar.open(i18nMessage, closeMessage, config);
  }
}