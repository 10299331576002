import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "ease-dialog-confirm",
  template: `
    <mat-dialog-content>
      {{ data.message }}
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>
        {{ "_Cancel_" | i18n }}
      </button>
      <button mat-raised-button cdkFocusInitial color="accent" (click)="confirm()">
        {{ "_Confirm_" | i18n }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DialogConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
