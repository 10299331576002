import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IDepartment } from "../../models/ease-models";

@Injectable()
export class BreadcrumbsService {
  private textOptions = { responseType: "text" as "text" };

  constructor(
    private http: HttpClient,
  ) { }
  public getDepartmentDetails(id): Observable<any> {
    return this.http.get("/department/" + id);
  }

  public getScopeDetails(id): Observable<any> {
    return this.http.get("/scope/" + id);
  }

  public getUserDetails(id): Observable<any> {
    return this.http.get("/user/" + id);
  }

  public getLayerDetails(id): Observable<any> {
    return this.http.get("/orglayersitedetail/" + id);
  }

  public getShiftDetails(id): Observable<any> {
    return this.http.get("/shift/" + id);
  }

  public getTemplateDetails(id): Observable<any> {
    return this.http.get("/AuditQuestionCategory/" + id);
  }

  public getCapaName(id): Observable<string> {
    return this.http.get("/CapaEvent/Name?id=" + id, this.textOptions);
  }

  public getCapaNameByTaskId(taskId): Observable<string> {
    return this.http.get("/CapaEventLineItem/CapaEventName?taskId=" + taskId, this.textOptions);
  }

  public getDocumentDetails(id): Observable<any> {
    return this.http.get("/documentrev/" + id);
  }

  public getGroupDetails(id): Observable<any> {
    return this.http.get("/CustomGroup/" + id);
  }

  public getCompanyDetailByID(id): Observable<any> {
    return this.http.get("/company/" + id);
  }

  public getMitigationDetails(id): Observable<any> {
    return this.http.get("/mitigationdetail/" + id);
  }

  public getAuditDetails(id): Observable<any> {
    return this.http.get("/auditdetail/?id=" + id);
  }

  public getLocationDetails(id, locationType): Observable<any> {
    const params = new HttpParams().set("details", "true");
    return this.http.get("/" + locationType + "/" + id, {params: params});
  }
}
