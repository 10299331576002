import { Inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import { BehaviorSubject,  Observable,  ReplaySubject,  Subject } from "rxjs";
import { ApiService } from "./api.service";
import { Config } from "./config.service";
import { IdentityService } from "./identity.service";
import { LocalService } from "./local.service";
import { NotifyService } from "./notify.service";
import { ToolsService } from "./tools.service";

import { ApplicationTypes, AuditCategories, ISiteSettings } from "../models/ease-models";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";

import * as _ from "lodash";

export class CurrentAppProvisions {
    public isAuditingEnabled: boolean;
    public isLaborStandardsEnabled: boolean;
}

export class CurrentAppSettings {
    public isOsha300Enabled: boolean;
    public metricsLocationLevel: number;
    public isLpaEnabled?: boolean;
    public isAdhocAuditsEnabled?: boolean;
    public isInAuditMitigationEnabled: boolean;
    public isPortalPageEnabled: boolean;
    public isPasswordResetEnabled: boolean;
    public passwordResetDays: number;
    public isCustomerSurveysEnabled?: boolean;
    public isCountermeasuresEnabled: boolean;
    public countermeasuresCustomGroupID: number;
    public isGracePeriodEnabled: boolean;
    public gracePeriodDays: number;
    public isViewQuestionScopeEnabled: boolean;
    public isShowLocationHierarchy?: boolean;
    public isCaptchaEnabled: boolean;
    public captchaAttemptsValue: number;
    public isLockAccountEnabled: boolean;
    public loginAttemptsValue: number;
    public isCapaEnabled?: boolean;
    public useNewScheduler?: boolean;
}

@Injectable()
export class AppSettingsService implements OnDestroy{

    private _ready = new BehaviorSubject<boolean>(false);
    private _refreshed = new BehaviorSubject<boolean>(false);
    private loading: boolean = false;

    private emptyCurrentAppProvisions: CurrentAppProvisions = {
        isAuditingEnabled: false,
        isLaborStandardsEnabled: false,
    };

    private emptyCurrentAppSettings: CurrentAppSettings = {
        isOsha300Enabled: false,
        metricsLocationLevel: 0,
        isLpaEnabled: false,
        isAdhocAuditsEnabled: true,
        isInAuditMitigationEnabled: true,
        isPortalPageEnabled: false,
        isPasswordResetEnabled: false,
        passwordResetDays: 90,
        isCustomerSurveysEnabled: false,
        isCountermeasuresEnabled: false,
        countermeasuresCustomGroupID: 0,
        isGracePeriodEnabled: false,
        gracePeriodDays: 0,
        isViewQuestionScopeEnabled: false,
        isShowLocationHierarchy: false,
        isCaptchaEnabled: false,
        captchaAttemptsValue: 4,
        isLockAccountEnabled: false,
        loginAttemptsValue: 7,
        isCapaEnabled: false,
        useNewScheduler: false,
    };

    public currentAppSettings: CurrentAppSettings;
    public currentAppProvisions: CurrentAppProvisions;
    public ready: Observable<boolean> = this._ready.asObservable();
    public refreshed: Observable<boolean> = this._refreshed.asObservable();
    public siteSettings: ISiteSettings;
    private destroy$ = new Subject<void>();

    constructor(
        private log: LoggerService,
        private api: ApiService,
        private notify: NotifyService,
        private zone: NgZone) {
        this.log.debug("app-settings.service: constructor");
        this.currentAppSettings = this.emptyCurrentAppSettings;
        this.currentAppProvisions = this.emptyCurrentAppProvisions;
        this.notify.loggedIn.asObservable().subscribe((value: boolean) => this.onLoggedIn(value));
        this.notify.on("token-refreshed").subscribe(() => this.onTokenRefreshed());
    }

    public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }

    private onLoggedIn(loggedIn: boolean) {
        if (loggedIn) {
            this.log.debug("app-settings.service: logged in, so refreshing settings");
            this.refreshCurrentSettings();
        }
    }

    private onTokenRefreshed() {
      this.log.debug("app-settings.service: token refresh, so refreshing settings");
      this.refreshCurrentSettings();
    }

    private refreshCurrentSettings() {
        this.currentAppProvisions.isAuditingEnabled = true;

        // Get Org Application settings
        this.api.Query("OrganizationSetting", { q: "" }, (settings: any[]) => {
            this.currentAppSettings = {
                isOsha300Enabled: this.getBooleanSettingsValue(settings, "Osha300"),
                metricsLocationLevel: this.getNumericSettingsValue(settings, "MetricsLocationLevel", 5),
                isAdhocAuditsEnabled: this.getBooleanSettingsValue(settings, "AdHocAudits"),
                isInAuditMitigationEnabled: this.getBooleanSettingsValue(settings, "InAuditMitigation"),
                isPortalPageEnabled: this.getBooleanSettingsValue(settings, "PortalPage"),
                isPasswordResetEnabled: this.getBooleanSettingsValue(settings, "EnablePasswordReset"),
                passwordResetDays: this.getNumericSettingsValue(settings, "PasswordResetDays", 90),
                isCountermeasuresEnabled: this.getBooleanSettingsValue(settings, "Countermeasures"),
                countermeasuresCustomGroupID: this.getNumericSettingsValue(settings, "CountermeasuresCustomGroup"),
                isGracePeriodEnabled: this.getBooleanSettingsValue(settings, "EnableGracePeriod"),
                gracePeriodDays: this.getNumericSettingsValue(settings, "GracePeriodDays", 0),
                isViewQuestionScopeEnabled: this.getBooleanSettingsValue(settings, "ViewQuestionScopes"),
                isCaptchaEnabled: this.getBooleanSettingsValue(settings, "EnableCaptcha"),
                captchaAttemptsValue: this.getNumericSettingsValue(settings, "CaptchaAttemptsValue", 4),
                isLockAccountEnabled: this.getBooleanSettingsValue(settings, "EnableLockAccountAttempts"),
                loginAttemptsValue: this.getNumericSettingsValue(settings, "LoginAttemptsValue", 7),
                isCapaEnabled: this.getBooleanSettingsValue(settings, "IsCapaEnabled"),
                useNewScheduler: this.getBooleanSettingsValue(settings, "UseNewScheduler"),
            };

            this.api.Query("SiteSetting", { q: "" }, (siteSettings: any[]) => {
                this.zone.run(() => {

                this.currentAppSettings.isOsha300Enabled = this.getBooleanSettingsValue(settings, "Osha300");
                this.currentAppSettings.metricsLocationLevel = this.getNumericSettingsValue(settings, "MetricsLocationLevel", 5);
                this.currentAppSettings.isPortalPageEnabled = this.getBooleanSettingsValue(settings, "PortalPage");
                this.currentAppSettings.isPasswordResetEnabled = this.getBooleanSettingsValue(settings, "EnablePasswordReset");
                this.currentAppSettings.passwordResetDays = this.getNumericSettingsValue(settings, "PasswordResetDays", 90);
                this.currentAppSettings.isCaptchaEnabled = this.getBooleanSettingsValue(settings, "EnableCaptcha");
                this.currentAppSettings.captchaAttemptsValue = this.getNumericSettingsValue(settings, "CaptchaAttemptsValue", 4);
                this.currentAppSettings.isLockAccountEnabled = this.getBooleanSettingsValue(settings, "EnableLockAccountAttempts");
                this.currentAppSettings.loginAttemptsValue = this.getNumericSettingsValue(settings, "LoginAttemptsValue", 7);

                // Get site settings
                this.currentAppSettings.isAdhocAuditsEnabled = this.getBooleanSettingsValue(siteSettings, "AdHocAudits");
                this.currentAppSettings.isInAuditMitigationEnabled = this.getBooleanSettingsValue(siteSettings, "InAuditMitigation");
                this.currentAppSettings.isCountermeasuresEnabled = this.getBooleanSettingsValue(siteSettings, "Countermeasures");
                this.currentAppSettings.countermeasuresCustomGroupID = this.getNumericSettingsValue(siteSettings, "CountermeasuresCustomGroup");
                this.currentAppSettings.isGracePeriodEnabled = this.getBooleanSettingsValue(siteSettings, "EnableGracePeriod");
                this.currentAppSettings.gracePeriodDays = this.getNumericSettingsValue(siteSettings, "GracePeriodDays", 0);
                this.currentAppSettings.isViewQuestionScopeEnabled = this.getBooleanSettingsValue(siteSettings, "ViewQuestionScopes");
                this.currentAppSettings.isShowLocationHierarchy = this.getBooleanSettingsValue(siteSettings, "ShowLocationHierarchy");

                this.currentAppSettings.isLpaEnabled = false;
                this.api.Query("AuditQuestionCategory", {}, (auditCategories: any[]) => { //(provisions.filter((provision: any) => provision.ApplicationID == ApplicationTypes.Auditing)).length > 0;
                    // debugger;
                    this.currentAppSettings.isLpaEnabled = auditCategories.filter((category: any) => category.IsLPA === true).length > 0;
                    this.currentAppSettings.isCustomerSurveysEnabled = auditCategories.filter((category: any) => category.BaseAuditSubtypeID === AuditCategories.CustomerSurvey).length > 0;

                    let exclude = false;
                    const exclusions = ["ResetPassword", "WelcomeNew"];
                    _.each(exclusions, (val: any) => {
                        if (window.location.pathname.indexOf(val) > 0) { exclude = true; }
                    });

                    if (window.location.toString().indexOf("ExpiredPassword") == -1 && !exclude) {
                        this.notify.currentAppSettingsReady.value = true;
                        this.notify.broadcast("currentAppSettingsReady");
                    } else {
                        this.notify.currentAppSettingsReady.value = false;
                        this.notify.broadcast("currentAppSettingsReady");
                    }

                });
            });
            });
        });
    }

    public getAdHocAuditSiteSettings() {
        const result = new ReplaySubject<any>(1);
        this.api.Query("SiteSetting", { key: "AdHocAudits" }, (data: ISiteSettings[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                console.log("no adhoc site setting found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getNumericSettingsValue(settings: any, name: string, defVal?: number) {
        let results = defVal;
        const filtered = settings.filter((setting: any) => setting.Name === name);
        if (filtered.length > 0) {
            results = parseInt(filtered[0].Value);
        }
        return results;
    }

    public getBooleanSettingsValue(settings: any, name: string) {
        let results = false;
        const filtered = settings.filter((setting: any) => setting.Name == name);
        if (filtered.length > 0) {
            results = filtered[0].Value == "1";
        }
        return results;
    }
}
