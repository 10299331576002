import { Component, OnInit } from "@angular/core";
import { RssEntry } from "../../models/rss";
import { RssParserService } from "@app/auth/components/rss-feed/rss-parser.service";
import { Observable } from "rxjs";

@Component({
  selector: "rss-feed",
  providers: [ RssParserService ],
  styleUrls: [ "./rss-feed.component.scss" ],
  template: `
    <ng-container *ngIf="rssEntry$ | async">
      <p class="title">What we've been doing</p>
      <ul>
        <li *ngFor="let entry of rssEntry$ | async; let i = index">
          <p class="subTitle" *ngIf="i === 0">New Blog Entries</p>
          <p class="subTitle" *ngIf="i === 3">New Events</p>
          <p class="lineItem"><div [innerHTML] = "entry.title | safeHtml"></div>
            <p class="pubDate"> ({{entry.publicationDate}})
            <hr>
        </li>
      </ul>
    </ng-container>
  `,
})
export class RssFeedComponent implements OnInit {
  rssEntry$: Observable<RssEntry[]>;

  constructor(private rss: RssParserService) { }

  ngOnInit() {
    this.rssEntry$ = this.rss.getNewRssData();
  }
}
