import {
  GroupDetailsActionTypes,
  GroupDetailsActionUnion,
} from "@app/admin/permission-groups/actions/group-details.actions";

import { ApiError } from "@core/models/api";

import { PermissionField } from "../models/permission-field";

export interface State {
  Name: string;
  Id: number;
  Permissions: PermissionField[];
  loading: boolean;
  errors: ApiError[];
}

const initialState: State = {
  Name: "",
  Id: null,
  loading: false,
  Permissions: [],
  errors: [],
};

export function reducer(
  state = initialState,
  action: GroupDetailsActionUnion,
): State {
  switch (action.type) {
    case GroupDetailsActionTypes.Load: {
      return {
        ...state,
        Name: "",
        Permissions: [],
        loading: true,
        errors: [],
      };
    }
    case GroupDetailsActionTypes.LoadSuccess: {
      return {
        ...state,
        Name: action.payload.Name,
        Id: action.payload.Id,
        loading: false,
        Permissions: action.payload.Permissions,
      };
    }
    case GroupDetailsActionTypes.LoadFail: {
      return {
        ...state,
        loading: false,
      };
    }
    case GroupDetailsActionTypes.GetAvailablePermissions: {
      return {
        ...state,
        loading: true,
        Name: "",
        Permissions: [],
        errors: [],
      };
    }
    case GroupDetailsActionTypes.GetAvailablePermissionsSuccess: {
      return {
        ...state,
        loading: false,
        Permissions: action.payload,
      };
    }
    case GroupDetailsActionTypes.Update: {
      return {
        ...state,
        loading: true,
      };
    }
    case GroupDetailsActionTypes.UpdateSuccess: {
      return {
        ...state,
        loading: false,
      };
    }
    case GroupDetailsActionTypes.UpdateFail: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getPermissions = (state: State) => state.Permissions;
export const getGroupName = (state: State) => state.Name;
export const getGroupId = (state: State) => state.Id;
export const getError = (state: State) => state.errors;
export const getPermissionGroupLoading = (state: State) => state.loading;
