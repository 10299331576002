import { Component, forwardRef, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatInput } from "@angular/material";
import { LocalizeService } from "@core/services";
import { IUser} from "@core/models/ease-models";

@Component({
  selector: "ease-inline-edit-select",
  templateUrl: "./inline-edit-select.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InlineEditSelectComponent),
    },
  ],
})

export class InlineEditSelectComponent implements ControlValueAccessor {

  @Input() isInEditMode = false;
  @Input() canEdit: boolean = true;
  @Input() values;
  @Input() labelName;
  @Input() labelDescription;
  @Input() hideButtons: boolean = false;
  @Output() saveClicked = new EventEmitter<string>();

  public value: string;
  private initialValue: string;

  constructor(private localize: LocalizeService) {
  }
  public dataInitEditHandler($event) {
    this.isInEditMode = true;
    $event.stopPropagation();
  }

  public onChange: any = ($event) => {
    this.dataInitEditHandler($event);
  };
  public onTouched: any = () => {};
  public writeValue(value: string) {
    if (value) {
      this.value = this.localize.getLocalizedString(value);
      this.initialValue = this.value;
    }
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  public changeHandler($event) {
    if (this.hideButtons) {
      this.saveClicked.emit(this.value);
      this.isInEditMode = false;
    }
  }

  public saveChangesHandler($event) {
    this.isInEditMode = false;
    this.saveClicked.emit(this.value);
    $event.stopPropagation();
  }

  public cancelChangesHandler($event) {
    this.value = this.initialValue;
    this.isInEditMode = false;
    $event.stopPropagation();
  }

  public inputClickHandler($event) {
    $event.stopPropagation();
  }

  public keyDownHandler($event) {
    $event.stopPropagation();
    if ($event.keyCode === 13) {
      this.saveChangesHandler($event);
    }
  }
}
