import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { isNullOrUndefined } from "util";

const noop = () => {
};

@Component({
    selector: "ease-date-picker",
    templateUrl: "./datepicker.component.html",
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatePickerComponent),
        multi: true,
      },
    ],
})

export class DatePickerComponent implements OnInit, ControlValueAccessor {
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() isRequired: boolean;
    @Input() isDisabled: boolean;

    @Input() placeholder: string;
    @Input() isEndDate: boolean;
    @Input() isFluid: boolean;
    @Input() disableClear: boolean;

    @Input() value: Date;
    @Output() valueChange = new EventEmitter<Date>();
    onChange: (value: any) => void = () => {};
    onTouched: () => any = () => {};

    registerOnChange(fn) {
      this.onChange = fn;
    }

    registerOnTouched(fn) {
      this.onTouched = fn;
    }

    writeValue(value) {
      if (value) {
        this.value = value;
      }
    }

    public minMaxFilter = (date: Date): boolean => {
        let isValid: boolean = true;

        if (!isNullOrUndefined(this.minDate) && date < this.minDate) {
            isValid = false;
        }

        if (!isNullOrUndefined(this.maxDate) && date > this.maxDate) {
            isValid = false;
        }

        return isValid;
    }

  public valueChangeHandler(val: any) {
    this.valueChange.emit(val);
    this.onChange(val);
  }

    public clearDateSelection() {
        this.value = null;
        this.valueChange.emit(null);
    }

    public ngOnInit() {

        if (!isNullOrUndefined(this.minDate)) {
            this.minDate = new Date(this.minDate);
        }
        if (!isNullOrUndefined(this.maxDate)) {
            this.maxDate = new Date(this.maxDate);
        }
    }

}
