import { map } from "rxjs/operators";

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { MatDialog } from "@angular/material";

// State
import { select, Store } from "@ngrx/store";
import * as fromRoot from "app/reducers";
import * as fromAuth from "app/auth/reducers";
import {
  LogoutFromDropDown,
} from "app/auth/actions/auth";

// Services
import {
  CurrentInfoService,
  IdentityService,
  NotifyService,
  ToolsService,
  ApiService,
  FeedbackService,
} from "@core/services";
import { UiNotificationService } from "@core/services/common/ui-notification.service";
import { ArtifactService } from "@core/services/common/artifact.service";

import { IOrgArtifact } from "../../models/ease-models";
import { DialogConfigMd, DialogConfigLg } from "@shared/dialogs";

import { ReleaseNotesModalComponent } from "@shared/release-notes/release-notes-modal.component";

import { BreadcrumbComponent } from "@core/components/breadcrumbs/breadcrumb.component";
import { BreadcrumbItem } from "@core/components/breadcrumbs/breadcrumb.class";
import { Subject } from "rxjs";

// Todo Remove this.
// We cannot use files that are part of build system.
// Now in our build artifacts package.json present and it is kind of security issue.
// Also `required` is forbidden, use `import` instead.

@Component({
  selector: "ease-top-nav",
  templateUrl: "./top-nav.component.html",
  styleUrls: [ "./top-nav.component.scss" ],
})

export class TopNavComponent implements OnInit, OnDestroy {
  @Input() public activeIndex: number;
  @Input() public isLeftNavPinned: boolean = false;
  @Output() public leftNavExpanded = new EventEmitter<boolean>();

  disableSiteSelector$ = this.store.pipe(
    select(fromRoot.getRouterRoot),
    map(this.getSiteSelectorData),
  );

  public isSysAdmin: boolean = this.current.info.roleIsSysAdmin;
  impersonatorLogin$ = this.store.pipe(select(fromAuth.getImpersonatorLogin));
  public isLoggedIn: boolean = false;
  public freshWidgetUrl: string;
  public pulseFeedbackButton: boolean = false;
  public showReleaseNotes: boolean = false;
  public status: { isopen: boolean } = { isopen: false };

  public showAccountMenu: boolean = false;
  public showLeftNav: boolean = false;
  public logoData: string;
  public isTransparentLogo: boolean = false;

  public isTopNavHidden: boolean = false;

  public versionString: string;

  public showFBIcon: boolean = false;
  public showFeedbackRequestWidget: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(private store: Store<fromRoot.State>,
              private api: ApiService,
              public current: CurrentInfoService,
              private router: Router,
              private notify: NotifyService,
              private identity: IdentityService,
              public tools: ToolsService,
              private sanitizer: DomSanitizer,
              private uiNotify: UiNotificationService,
              private artifacts: ArtifactService,
              private dialog: MatDialog,
              private feedback: FeedbackService) {
    this.notify.on("hide-top-nav").takeUntil(this.destroy$).subscribe((args: boolean) => this.hideTopNav(args));
  }

  public ngOnInit(): void {
    this.notify.loggedIn.asObservable().takeUntil(this.destroy$).subscribe((value: boolean) => {
      this.showAccountMenu = this.current.info.roleIsOrgAdmin && !this.tools.isApp();
      this.showLeftNav = !this.tools.isApp();
      this.setLogo();
      if (this.tools.isApp()) {
        (<any> window.cordova).getAppVersion.getVersionNumber().then(versionNumber => {
          this.versionString = versionNumber;
        });
      } else {
        this.versionString = this.current.info.versionNumber;
      }
      this.onLoggedIn(value);
      this.pulseFeedbackButton = this.current.info.requestFeedback;

    });

    this.notify.on("logo-updated").takeUntil(this.destroy$).subscribe(() => {
      this.setLogo();
    });

    this.notify.currentInfoReady.asObservable().takeUntil(this.destroy$).subscribe((value: boolean) => {
      if (value) {
        this.checkReleaseNotes();
      }
    });
  }

  public checkReleaseNotes(): void {
    this.showReleaseNotes = this.current.info.showReleaseNotes;
    if (this.showReleaseNotes && (this.current.info.roleIsManager ||
      this.current.info.roleIsSiteAdmin || this.current.info.roleIsAuditAuthor ||
      this.current.info.roleIsOrgAdmin)) {
      this.openReleaseNotes();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onClickLogout() {
    if (!this.tools.isOnline()) {
      navigator.notification.alert("Any audits saved will not be synced until you log in again", () => {
      }, "Ease Offline", "OK");
    }

    if (!this.tools.isApp()) {
      if (this.notify.online.value) {
        this.identity.isSSOActiveHost(window.location.hostname).takeUntil(this.destroy$).subscribe((active: boolean) => {
          if (active) {
            window.location.replace("/account/LogoutSSO?returnUrl=" + encodeURIComponent(window.location.href));
          } else {
            this.store.dispatch(new LogoutFromDropDown(""));
          }
        }, (err: any) => {
          this.store.dispatch(new LogoutFromDropDown(""));
        });
      }
    } else {
      this.store.dispatch(new LogoutFromDropDown(""));
    }
  }

  public onLoggedIn(loggedIn: boolean) {
    this.isLoggedIn = loggedIn;
    this.setFreshWidgetUrl();
  }

  public expandLeftNav() {
    this.leftNavExpanded.emit(true);
  }

  public toggleDropdown($event: MouseEvent): void {
    this.status.isopen = !this.status.isopen;
  }

  public setFreshWidgetUrl(): void {
    if (this.current.info.user) {
      let email = this.current.info.user.Email;
      email = encodeURIComponent(email);
      const url = "https://easeinc.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=yes&helpdesk_ticket[requester]=" + email;
      this.freshWidgetUrl = this.sanitizer.bypassSecurityTrustUrl(url).toString();
    }
  }

  public change(value: boolean): void {
    this.status.isopen = value;
  }

  public navigateToExternalLink(linkType: string): void {
    if (this.tools.isOnline()) {
      let url = window.location.href = "mailto:support@easeinc.com?subject=Support%20request%20from%20userID%20" + this.current.info.user.ID;
      if (linkType === "suggestions") {
        url = "https://easeinc.tpondemand.com/helpdesk/requests";
      }
      window.location.href = url;
    } else {
      this.uiNotify.open("Action unavailable offline", true);
    }
  }

  private setLogo() {
    this.artifacts.getOrganizationArtifact().subscribe((data: IOrgArtifact) => {
      if (data !== null) {
        this.logoData = data.Url;

        if (data.Artifact !== null) {
          this.isTransparentLogo = data.Artifact.IsTransparentImage;
        }
      } else {
        this.logoData = null;
      }
    });
  }

  private hideTopNav(isHidden: boolean) {
    this.isTopNavHidden = isHidden;
  }

  private openReleaseNotes(): void {
    const config = new DialogConfigMd();
    const dialogRef = this.dialog.open(ReleaseNotesModalComponent, config);
    dialogRef.afterClosed().takeUntil(this.destroy$).subscribe(result => {
    });
  }

  private launchFeedbackModal() {
    if (!this.showFeedbackRequestWidget) {
      this.showFeedbackRequestWidget = true;
      const result = this.feedback.setResponseDate().subscribe(data => {});
    } else {
      this.showFeedbackRequestWidget = false;
    }
    this.pulseFeedbackButton = false;

  }

  private getSiteSelectorData(route) {
    while (route) {
      if (route.children && route.children.length) {
        route = route.children[ 0 ];

        if (route.data && route.data[ "disableSiteSelector" ]) {
          return true;
        }
      } else {
        return false;
      }
    }
  }
}
