import { Component } from "@angular/core";

import { Store } from "@ngrx/store";
import * as fromAuth from "../reducers/index";
import * as AuthActions from "../actions/auth";

@Component({
  selector: "ease-change-password-success",
  template: `
    <ease-auth-page>
      <mat-card>
        <img mat-card-image src="assets/images/ease-beacon.png">
        <mat-card-content>
          <p>{{ "_Auth:ChangePasswordSuccess:YourPasswordChanged_" | i18n }}</p>
        </mat-card-content>
        <mat-card-actions align="center">
          <button mat-raised-button color="accent" (click)="redirect()">
            {{ "_Auth:ChangePasswordSuccess:ReturnToSignIn_" | i18n }}
          </button>
        </mat-card-actions>
      </mat-card>
    </ease-auth-page>
  `,
  styles: [ `
    .mat-card-image {
      width: 100%;
      padding-top: 24px;
      margin: 0 0 10px;
    }
  ` ],
})
export class ChangePasswordSuccessComponent {
  constructor(private store: Store<fromAuth.State>) {
  }

  redirect() {
    this.store.dispatch(new AuthActions.LoginRedirect());
  }
}
