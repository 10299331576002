import { Injectable, NgZone } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import { ApiService } from "../api.service";

import "rxjs/Rx";
import { IUser } from "../../models/ease-models";

@Injectable()
export class AdminService {

    constructor(private api: ApiService,
                private log: LoggerService,
                private zone: NgZone) {
    }

    public getUserList(callback: any) {
        this.api.Query("UserList", {}, (data: IUser []) => {
            callback(data);
        });
    }
}