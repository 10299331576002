import { Injectable } from "@angular/core";
import { Observable,  ReplaySubject } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { IAudit, ICompanySite, IJob } from "../../core/models/ease-models";

@Injectable()
export class CompanySiteService {
    constructor(private http: HttpClient) {
    }

    public getCompanySites(listType: string): Observable<ICompanySite[]> {
        return this.http.get<ICompanySite[]>("/companysite?q=" + listType);
    }

    public getCompanySitesByDetail(params: any): Observable<ICompanySite[]> {
      const url = `/companysite?detail=${params.detail}&companyID=${params.companyID}&name=${params.name}`;
      return this.http.get<ICompanySite[]>(url);
    }

    public delete(id): Observable<void> {
      return this.http.delete<void>(`/companysite/${id}`);
    }

    public add(companySite: ICompanySite) {
      const url = "/companysite/";
      return this.http.post<number>(url, companySite);
    }

    public update(id: number, companySite: ICompanySite) {
      return this.http.put<boolean>(`/companysite/${id}`, companySite);
    }

    public getAuditsOfCompanySite(id: number): Observable<IAudit[]> {
      return this.http.get<IAudit[]>("/companysite/audits?id=" + id);
    }
}
