import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {LocalizeService} from "../../../core/services/localize.service";
import {ICapaTask} from "../../../core/models/ease-models";

@Component({
    templateUrl: "./add-edit-capa-task-modal.component.html",
})

export class AddEditCapaTaskModalComponent {

    capaTask: ICapaTask = {};
    isReadOnly: boolean;
    hideDeleteButton: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AddEditCapaTaskModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private localize: LocalizeService) {

        this.initModal(data);
    }

    public initModal(data) {
        if (this.data && this.data.capaTask) {
            this.capaTask = this.data.capaTask;
            this.isReadOnly = this.data.isReadOnly;
        } else {
            this.hideDeleteButton = true;
        }
    }

    public cancelHandler() {
        this.dialogRef.close(false);
    }
    public saveHandler() {
        this.dialogRef.close(this.capaTask);
    }
}
