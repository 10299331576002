import { Tab } from "../models/tab";
import { TabsActionsUnion, TabsActionTypes } from "../actions/tabs";

export interface State {
  entities: Tab[];
}

export const initialState: State = {
  entities: [],
};

export function reducer(
  state = initialState,
  action: TabsActionsUnion,
): State {
  switch (action.type) {
    case TabsActionTypes.Open: {
      return {
        ...state,
        entities: action.payload,
      };
    }

    case TabsActionTypes.Close: {
      return {
        ...state,
        entities: [],
      };
    }

    default: {
      return state;
    }
  }
}

export const getEntities = (state: State) => state.entities;
