import { BreadcrumbItemComponent,
  BreadcrumbUserComponent,
  BreadcrumbDepartmentComponent,
  BreadcrumbScopeComponent,
  BreadcrumbLayerComponent,
  BreadcrumbShiftComponent,
  BreadcrumbTemplateTypeComponent,
  BreadcrumbCapaComponent,
  BreadcrumbDocumentNameComponent,
  BreadcrumbDocumentTypeComponent,
  BreadcrumbGroupNameComponent,
  BreadcrumbCompanyComponent,
  BreadcrumbMitigationComponent,
  BreadcrumbAuditComponent,
  BreadcrumbUserCalendarComponent,
  BreadcrumbSiteCalendarComponent,
  BreadcrumbPermissionComponent,
  BreadcrumbOrganizationComponent,
  BreadcrumbReleaseComponent,
} from "./breadcrumbs-items";

export class BreadcrumbItem {
  component = BreadcrumbItemComponent;
}

export class BreadcrumbUser {
  component = BreadcrumbUserComponent;
}

export class BreadcrumbDepartment {
  component = BreadcrumbDepartmentComponent;
}

export class BreadcrumbScope {
  component = BreadcrumbScopeComponent;
}

export class BreadcrumbLayer {
  component = BreadcrumbLayerComponent;
}

export class BreadcrumbShift {
  component = BreadcrumbShiftComponent;
}

export class BreadcrumbTemplateType {
  component = BreadcrumbTemplateTypeComponent;
}

export class BreadcrumbCapa {
  component = BreadcrumbCapaComponent;

  constructor(public type = null) {}
}

export class BreadcrumbDocumentName {
  component = BreadcrumbDocumentNameComponent;
}

export class BreadcrumbDocumentType {
  component = BreadcrumbDocumentTypeComponent;
}

export class BreadcrumbGroupName {
  component = BreadcrumbGroupNameComponent;
}

export class BreadcrumbCompany {
  component = BreadcrumbCompanyComponent;
}
export class BreadcrumbMitigationName {
  component = BreadcrumbMitigationComponent;
}

export class BreadcrumbAuditName {
  component = BreadcrumbAuditComponent;
  type = "audit";
}

export class BreadcrumbConductName {
  component = BreadcrumbAuditComponent;
  type = "conduct";
}

export class BreadcrumbUserCalendar {
  component = BreadcrumbUserCalendarComponent;
}

export class BreadcrumbSiteCalendar {
  component = BreadcrumbSiteCalendarComponent;
}

export class BreadcrumbPermission {
  component = BreadcrumbPermissionComponent;
}

export class BreadcrumbOrganization {
  component = BreadcrumbOrganizationComponent;
}

export class BreadcrumbRelease {
  component = BreadcrumbReleaseComponent;
}

