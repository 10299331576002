import { of } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  User,
  AuthenticationPayload,
  AuthenticationResponse,
  RequestPasswordPayload,
  SetPasswordPayload,
  AuthenticationSSOPayload,
} from "../models/user";

// Services
import {
  CurrentInfoService,
  IdentityService,
  UserService,
} from "@core/services";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient,
              private currentInfo: CurrentInfoService,
              private user: UserService,
              private identity: IdentityService) {
  }

  authenticate({ Login, Password }: AuthenticationPayload) {
    return this.http.post<AuthenticationResponse>("/authentication", { Login, Password });
  }

  authenticateSSO({ Token }: AuthenticationSSOPayload) {
    return this.http.post<AuthenticationResponse>("/authentication/sso", { Token });
  }

  impersonate(id) {
    return this.http.post(`/Impersonate/${id}`, {});
  }

  authorize() {
    return this.currentInfo.refreshCurrentModels();
  }

  isSSOActiveHost() {
    return this.http.get<boolean>("/Subdomain", {
      params: { activeSSOCheck: window.location.hostname },
    });
  }

  refreshToken(siteID, languageName) {
    const url = this.identity.endpoints.Refresh;

    return this.http.post<AuthenticationResponse>(url, {
      languageName,
      siteID,
    });
  }

  logout() {
    return of(true);
  }

  requestPassword({ email, callbackUrl }: RequestPasswordPayload) {
    return this.http.post("/password/sendresetlink", { email, callbackUrl });
  }

  setPassword({ password, token }: SetPasswordPayload) {
    return this.http.post("/password/restore", { password, token });
  }

  changeLanguage(lang) {
    return this.user.updateUser({ Language: lang });
  }
}
