import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from "@angular/core";
import { Subscription } from "rxjs";
import { ColumnSettingsService } from "../../core/services/common/column-settings.service";

@Component({
    selector: "ease-column-arrangement",
    templateUrl: "./list-view-column-arrangement.component.html",
})

export class ListViewColumnArrangementComponent implements OnInit, OnDestroy {

    public listViewItems: any[] = [];
    public userPreferenceSubscription: Subscription;

    @Input() public listViewOption: string;
    @Output() public columnArrangementChanged = new EventEmitter();

    constructor(private columnSettingService: ColumnSettingsService) {

        this.userPreferenceSubscription = this.columnSettingService.getUserPreferencesChanges().subscribe(preferences => {
            this.columnArrangementChanged.emit();
        });

    }

    public ngOnInit() {
        this.columnSettingService.getUserListViewPreferences(this.listViewOption);
        this.displayOptions();
    }

    public displayOptions() {
        this.listViewItems = this.columnSettingService.displayOptions(this.listViewOption);
    }

    public resetChanges() {
        this.columnSettingService.resetChanges(this.listViewOption);
    }

    public cancelChanges() {

    }

    public applyChanges() {
        this.columnSettingService.applyChanges(this.listViewItems, this.listViewOption);
    }

    public ngOnDestroy() {

        this.userPreferenceSubscription.unsubscribe();
    }

}
