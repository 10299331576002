import {Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { IDateRange } from "./date-types";

@Component({
    selector: "ease-date-range-picker",
    templateUrl: "./date-range-picker.component.html",
})

export class DateRangePickerComponent  {

    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public dateRange: IDateRange = {} ;
    @Input() public isTextInputDisabled: boolean;
    @Output() public dateRangeChange = new EventEmitter<IDateRange>();

    @Input() public startPlaceholder: string = "_From_";
    @Input() public endPlaceholder: string = "_to_";

    constructor() {

    }

    public rangeChangeHandler(val: any, changeType: string) {

        const range = this.dateRange;

        if (changeType == "start") {
            range.startDate = val;

            // Clear the "To" date if a user selects a later "From" date
            if (range.endDate != null && range.endDate < range.startDate) {
                range.endDate = null;
            }
        } else if (changeType == "end") {
            range.endDate = val;

            // Clear the "From" date if a user selects an earlier "To" date
            if (range.startDate != null && range.endDate != null && range.endDate < range.startDate) {
                range.startDate = null;
            }
        }

        this.dateRangeChange.emit(range);
    }

}
