
import { ReplaySubject,  Observable } from "rxjs";
import {Injectable} from "@angular/core";
import {ApiService} from "../../core/services/api.service";
import {
  IClientCapaEvent, IClientCapaEventLineItem, IClientMitigationActivity, IDocumentRev, IMitigationArtifact, ISite, IUser,
} from "../../core/models/ease-models";
import {HttpClient} from "@angular/common/http";
import {CapaDocumentLineItem} from "../../manage/documents/document-tasks/documet-task.model";

@Injectable()
export class CorrectiveActionsDataAccessService {
    constructor(private api: ApiService, private http: HttpClient) {  }

    public getCapaEventLineItem(query: any): Observable<IClientCapaEventLineItem[]> {
        const result = new ReplaySubject<IClientCapaEventLineItem[]>(1);
        this.api.Query("CapaEventLineItem", query, (data: IClientCapaEventLineItem[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getCapaEvent(capaEventID: number, query: any): Observable<IClientCapaEvent> {
        return this.http.get<IClientCapaEvent> (`/CapaEvent/${capaEventID}?details=true`);
    }

    public getUsersForSiteScope(query: any): Observable<IUser[]> {
        const result = new ReplaySubject<IUser[]>(1);
        this.api.Query("user", query, (data: IUser[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getCapaEventThumbnails(query: any): Observable<any[]> {
        const result = new ReplaySubject<IUser[]>(1);
        this.api.Query("capaartifact", query, (data: any[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public updateCapaEvent(id: number, capaEvent: IClientCapaEvent): Observable<boolean> {

        const result = new ReplaySubject<any>(1);
        this.api.Update("capaevent", id, capaEvent, (success: boolean) => {

            result.next(true);
            result.complete();
        });
        return result.asObservable();
    }

    public updateItem<T>(entity: string, id: number, item: T, query: any): Observable<boolean> {

        const result = new ReplaySubject<any>(1);
        this.api.UpdateX(entity, id, item, query, () => {

            result.next(true);
            result.complete();
        });
        return result.asObservable();
    }

    public deleteItem(entity: string, id: number): Observable<boolean> {

        const result = new ReplaySubject<any>(1);
        this.api.Delete(entity, id, (success: boolean) => {

            result.next(true);
            result.complete();
        });
        return result.asObservable();
    }

    public getManagers(query: any): Observable<IUser[]> {
        const result = new ReplaySubject<IUser[]>(1);
        this.api.Query("User", query, (data: IUser[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getMitigation(id: number): Observable<IClientMitigationActivity> {
        const result = new ReplaySubject<IClientMitigationActivity>(1);
        this.api.Get("Mitigation", null, id, (data: IClientMitigationActivity) => {
            if (data) {
                result.next(data);
                result.complete();
            } else {
                result.next(null);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getMitigationArtifacts(query: any): Observable<any[]> {
        const result = new ReplaySubject<IUser[]>(1);
        this.api.Query("mitigationartifact", query, (data: any[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getMitigationArtifactByID(id: number): Observable<IMitigationArtifact> {
        const result = new ReplaySubject<IMitigationArtifact>(1);
        this.api.Get("mitigationartifact", {}, id, (data: IMitigationArtifact) => {
            if (data) {
                result.next(data);
                result.complete();
            } else {
                result.next(null);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public getCapaArtifact(id: number): Observable<any> {
        const result = new ReplaySubject<any>(1);
        this.api.Get("capaartifact", null, id, (data) => {
            if (data) {
                result.next(data);
                result.complete();
            } else {
                result.next(null);
                result.complete();
            }
        });
        return result.asObservable();
    }

    public addCapaEventLineItem(capaEventLineItem: IClientCapaEventLineItem): Observable<IClientCapaEventLineItem> {
        const result = new ReplaySubject<IClientCapaEventLineItem>(1);
        this.api.Add("capaeventlineitem", capaEventLineItem, (data: any) => {
            if (data) {
                result.next(data);
                result.complete();
            } else {
                result.next(null);
                result.complete();
            }
        });
        return result;
    }

    public getDocumentRev(query: any): Observable<IDocumentRev[]> {
      const result = new ReplaySubject<IDocumentRev[]>(1);
      this.api.Query("documentrev", query, (documentRevs: IDocumentRev[]) => {
        if (documentRevs) {
          result.next(documentRevs);
          result.complete();
        } else {
          if (documentRevs) {
            result.next([]);
            result.complete();
          }
        }
      }, null, null, this);
      return result.asObservable();
    }

    public getSite(query: any): Observable<ISite[]> {
      const result = new ReplaySubject<ISite[]>(1);
      this.api.Query("Site", query, (sites: ISite[]) => {
        if (sites) {
          result.next(sites);
          result.complete();
        } else {
          if (sites) {
            result.next([]);
            result.complete();
          }
        }
      }, null, null, this);
      return result.asObservable();
    }

    public addCapaEvent(capaEvent: IClientCapaEvent): Observable<number> {
      const result = new ReplaySubject<number>(1);
      this.api.Add("capaevent", capaEvent, (data: number) => {
        if (data) {
          result.next(data);
          result.complete();
        } else {
          result.next(-1);
          result.complete();
        }
      });
      return result;
    }

    public getSiteMinDate(offset) {
      const d = new Date();
      const siteDateWithOffset = new Date(d.setTime(d.getTime() + offset * 60 * 1000));

      return new Date(siteDateWithOffset.getUTCFullYear(), siteDateWithOffset.getUTCMonth(), siteDateWithOffset.getUTCDate());
    }

    public getCapaDocumentMaxDueDays(documentRevID: number): Observable<number> {
      return this.http.get<number>(`/documentrev?q=maxTaskDue&DocumentRevID=${documentRevID}`);
    }
}
