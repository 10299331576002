import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IMitigationActivity } from "@core/models/ease-models";

@Injectable()
export class MitigationActionPanelService {

  constructor(private http: HttpClient) {
  }

  public updateMitigation(mitigation: IMitigationActivity, updateApprovingParty: boolean, isAdHoc: boolean): Observable<IMitigationActivity> {
    return this.http.put<IMitigationActivity>(`/mitigation/${mitigation.ID}?updateApprovingParty=${updateApprovingParty}&isAdHoc=${isAdHoc}`, mitigation);
  }

  public sendRejectMitigationEmail(mitigation: IMitigationActivity, isAdHoc: boolean): Observable<IMitigationActivity> {
    return this.http.put<IMitigationActivity>(`/mitigation/${mitigation.ID}?sendRejectMitigationEmail=true&isAdHoc=${isAdHoc}`, mitigation);
  }
}
