// Deprecated!!  Please use ObservableMedia instead


import { Injectable } from "@angular/core";
import { Inject } from "@angular/core";
import { Observable,  ReplaySubject,  BehaviorSubject } from "rxjs";
import { WINDOW } from "../window.service";

// Provides observables that help describe the state of the page.  The behavior of some page elements in Beacon are defined by these variables
// Reference ng-ease-nav.js from Ease1
@Injectable()
export class PageStateService {

    private isInitialized: boolean = false;
    private prevHeight: number = 0;
    private prevBootstrapSize: string;

    public isLeftNavPinned = new ReplaySubject<boolean>();
    public bootStrapSize = null;
    public windowHeight = new ReplaySubject<number>();

    constructor(
        @Inject(WINDOW) private window: Window) {

        this.init();
    }

    private init() {
        if (!this.isInitialized) {
            this.prevBootstrapSize = this.getBootstrapSize(this.window.innerWidth);
            this.prevHeight = this.window.innerHeight;
            this.setContentHeight(this.prevHeight);
            this.setIsLeftNavPinned(false);

            this.isInitialized = true;
            this.bootStrapSize = new BehaviorSubject<string>(this.prevBootstrapSize);
        }
    }

    public getIsLeftNavPinned(): Observable<boolean> {

        this.init();
        return this.isLeftNavPinned.asObservable();
    }

    public getBootStrapSizeChange(): Observable<string> {

        return this.bootStrapSize.asObservable();
    }

    public getPageHeightChange(): Observable<number> {

        return this.windowHeight.asObservable();
    }

    public setIsLeftNavPinned(isPinned: boolean): void {

        this.isLeftNavPinned.next(isPinned);
    }

    public windowResizeHandler(window: Window) {

        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;

        const newSize = this.getBootstrapSize(newWidth);
        if (this.prevBootstrapSize != this.getBootstrapSize(newWidth)) {
            this.bootStrapSize.next(newSize);
            this.prevBootstrapSize = newSize;
        }

        if (this.prevHeight != newHeight) {
            this.setContentHeight(newHeight);
            this.prevHeight = newHeight;
        }
    }

    private setContentHeight(height: number) {
        // Values from Ease1: ng-ease-nav.js

        this.windowHeight.next(height);
    }

    // Bootstrap 3 sizes
    private getBootstrapSize(width: number): string {

        let bsSize: string;

        if (width >= 1200) {
            bsSize = "lg";
        }
        else if (width >= 992) {
            bsSize = "md";
         }
        else if (width >= 768) {
            bsSize = "sm";
        }
        else {
            bsSize = "xs";
        }

        return bsSize;
    }
}
