// RxJS
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

// Angular
import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

// State
import { Store, select } from "@ngrx/store";

import {
  LogoutFromGuard,
} from "../actions/auth";
import * as fromAuth from "../reducers";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>) {
  }

  canActivate(): Observable<boolean> {
    // TODO: redirect to previous page
    return this.store.pipe(
      select(fromAuth.getLoggedIn),
      map(authed => {
        if (!authed) {
          this.store.dispatch(new LogoutFromGuard(""));
          return false;
        }

        return true;
      }),
      take(1),
    );
  }
}
