import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IPaginationModel } from "./list-view-models";

@Component({
  selector: "ease-list-view-paging",
  templateUrl: "./list-view-paging.component.html",
})
export class ListViewPagingComponent {

  public prevPageSize: number = 0;
  public prevPageIndex: number = 0;

  @Input() public pagingInfo: IPaginationModel;
  @Output() public pageChanged = new EventEmitter<number>();
  @Output() public pageSizeChanged = new EventEmitter<number>();

  public paginatorChangeHandler(event: any) {
    const pageIndex = event.pageIndex;
    const pageSize = event.pageSize;

    if (pageIndex !== this.prevPageIndex) {
      this.pageChanged.emit(pageIndex);
    }

    if (pageSize !== this.prevPageSize) {
      this.pageSizeChangeHandler(pageSize);
    }

    this.prevPageSize = pageSize;
    this.prevPageIndex = pageIndex;
  }

  public pageSizeChangeHandler(pageSize: number) {

    this.pageSizeChanged.emit(pageSize);
  }
}
