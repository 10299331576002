import {Component, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import * as _ from "lodash";
import { EaseProvisionableTypes, IEaseSubscription, IEaseSubscriptionLineItem, ISite } from "../../core/models/ease-models";
import {UiNotificationService} from "../../core/services/common/ui-notification.service";
import {ApiService} from "../../core/services/api.service";
import {CurrentInfoService} from "../../core/services/current.service";
import {LocalizeService} from "../../core/services/localize.service";

@Component({
    templateUrl: "./myAccount.component.html",
})

export class MyAccountComponent implements OnInit {

    public validInput: boolean = true; //SKL - replace .$valid
    public isLoading: boolean = true;

    public orgName: string = "";
    public easeSubscription: IEaseSubscription;
    public easeSubscriptionLineItems: IEaseSubscriptionLineItem[];
    public activeUsersBySite: any[];
    public currentOrgActiveUsers: any= {};
    public orgSites: ISite[];
    public allowedUsersCount: number = 0;
    public maxOrgUserLimitExceeded: boolean = false;

    // Hard code the English text for now, we can translate this later by creating an array of i18n keys instead
    public subscriptionStatuses = ["None", "Trialing", "TrialEnded", "Active", "Soft Failure", "Past Due", "Canceled", "Unpaid", "Expired", "Assessing", "Pending", "Suspended", "Paused", "FailedToCreate"];
    private pageForm: FormControl;

    constructor(private current: CurrentInfoService,
                private api: ApiService,
                private localize: LocalizeService,
                private uiNotify: UiNotificationService) {
    }

    public ngOnInit() {
        this.orgName = this.current.info.org.name;
        this.refreshData();
    }

    public editAllowedUsersPerSite(): void {
        let dataUpdated: boolean = false;
        if (this.validInput && this.activeUsersBySite) {
            _.each(this.activeUsersBySite, (au) => {
                _.each(this.easeSubscriptionLineItems, (li: IEaseSubscriptionLineItem) => {
                    if (au.SiteID && au.SiteID === li.SiteID) {
                        if (au.AllowedUsersCount !== li.OverridingMaxCount) {

                            li.OverridingMaxCount = au.AllowedUsersCount;
                            this.api.Update("EaseSubscriptionLineItem", li.ID, li, (data: any) => {
                                this.uiNotify.open("_ChangesSuccesfullySaved_");
                                dataUpdated = true;
                            });
                        }
                    }
                });
            });
            if (dataUpdated) {
              this.refreshData();
            }
        }
    }

    public cancel(): void {
        //force refresh the page
        this.refreshData();
    }

    public saveButtonClickHandler() {
        this.editAllowedUsersPerSite();
    }

    public cancelButtonClickHandler() {
        this.cancel();
    }

    private getSubscription(callback: any): void {
      this.api.Query("EaseSubscription", {}, (subscription: IEaseSubscription) => {
        subscription.StatusDescription = this.getEaseSubscriptionStatus(subscription.Status);
        this.easeSubscription = subscription;
        callback();
      });
    }

    private getSubscriptionLineItems(callback: any): void {
      this.api.Query("EaseSubscriptionLineItem", { q: "subscriptionid", p: this.easeSubscription.ID, allowNullOrZeroMaxCount: true }, (lineItems: IEaseSubscriptionLineItem[]) => {
        this.easeSubscriptionLineItems = lineItems;
        _.each(this.easeSubscriptionLineItems, (lineItem: any) => {
          if (lineItem.EaseProductLineItem.ProvisionableID == EaseProvisionableTypes.Users && !lineItem.SiteID) {//for org level entry the SiteId is null
            this.allowedUsersCount = lineItem.OverridingMaxCount;
          }
        });
        callback();
      });
    }

    private getActiveUsersBySite(): void {
      this.activeUsersBySite = [];
      this.currentOrgActiveUsers = {};

      this.api.Query("Site", {}, (sites: ISite[]) => {

        this.orgSites = sites;

        let orgUsersCount = 0;
        let orgAllowedUsersCount = 0;

        _.each(this.orgSites, (site: ISite) => {
          let siteUsersCount = 0;
          const siteAllowedUsersCount = 0;

          _.each(this.easeSubscriptionLineItems, (subscriptionLineItem: IEaseSubscriptionLineItem) => {
            if (subscriptionLineItem.SiteID && site.ID == subscriptionLineItem.SiteID) {
              siteUsersCount = subscriptionLineItem.CurrentCount;

              orgUsersCount += siteUsersCount;
              orgAllowedUsersCount += (subscriptionLineItem.OverridingMaxCount) ? subscriptionLineItem.OverridingMaxCount : 0;
              this.activeUsersBySite.push({ Site: site.Name, SiteID: site.ID, ActiveUsersCount: siteUsersCount, AllowedUsersCount: subscriptionLineItem.OverridingMaxCount, Selected: this.activeUsersBySite.length % 2 != 0 });
            }
          });
        });

        this.activeUsersBySite.push({ Site: this.localize.getLocalizedString("_Total_"), ActiveUsersCount: orgUsersCount, AllowedUsersCount: orgAllowedUsersCount, Selected: this.activeUsersBySite.length % 2 != 0, DisableEditing: true });
        this.currentOrgActiveUsers = { Total: this.localize.getLocalizedString("_Total_"), ActiveUsersCount: orgUsersCount, AllowedUsersCount: this.allowedUsersCount, ApplyRedText: true };
        this.isLoading = false;
        this.checkOrgUserLimit();
      });
    }

    private checkOrgUserLimit(): void {
      let siteAllowedUsersTotal = 0;
      _.each(this.activeUsersBySite, (li) => {
        if (!li.DisableEditing) {
          siteAllowedUsersTotal += (li.AllowedUsersCount) ? li.AllowedUsersCount : 0;
        }
      });

      // now update the site "total" allowed users row
      _.each(this.activeUsersBySite, (li) => {
        if (li.AllowedUsersCount && li.DisableEditing) {
          li.AllowedUsersCount = siteAllowedUsersTotal;
        }
      });

      if (siteAllowedUsersTotal > this.allowedUsersCount) {
        this.maxOrgUserLimitExceeded = true;
        // this.pageForm.$valid = false; Needs to be rewritten

      } else {
        this.maxOrgUserLimitExceeded = false;
        // if (this.pageForm) this.pageForm.$valid = true; Needs to be rewritten
      }
      let error: string;
      if (this.pageForm && this.pageForm.hasError(error)) {

        //SKL rewrote this
        const prop: string = "";
        _.each(prop, error => {
          if (error.hasOwnProperty(prop)) {
            this.validInput = false;
            return; //if any of the entered values is les then 1 or greater than the org allowed users (or any other error is encountered) the form is invalid
          }
        });
      }
    }

    private refreshData() {
      this.getSubscription(() => {
        this.getSubscriptionLineItems(() => {
          this.getActiveUsersBySite();
        });
      });
    }

    private getEaseSubscriptionStatus(status: number): string {
      let results: string;
      if (status <= this.subscriptionStatuses.length) {
        results = this.subscriptionStatuses[status];
      }
      return results;
    }

}
