import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from "@ngrx/store";
import * as fromRoot from "app/reducers";
import * as fromOrgs from "./organizations";
import * as fromOrgCollection from "./collection";
import * as fromOrgUsers from "./users-page";
import * as fromOrgCreatePage from "./create-page";
import * as fromOrgDetailsPage from "./details-page";

export interface OrgsState {
  orgs: fromOrgs.State;
  collection: fromOrgCollection.State;
  create: fromOrgCreatePage.State;
  details: fromOrgDetailsPage.State;
  users: fromOrgUsers.State;
}

export interface State extends fromRoot.State {
  systemAdminOrgs: OrgsState;
}

export const reducers: ActionReducerMap<OrgsState> = {
  orgs: fromOrgs.reducer,
  collection: fromOrgCollection.reducer,
  create: fromOrgCreatePage.reducer,
  details: fromOrgDetailsPage.reducer,
  users: fromOrgUsers.reducer,
};

export const getOrgsState = createFeatureSelector<OrgsState>("system-admin-organizations");

// Collection
export const getCollectionState = createSelector(
  getOrgsState,
  (state: OrgsState) => state.collection,
);

export const getCollectionIds = createSelector(
  getCollectionState,
  fromOrgCollection.getIds,
);

export const getCollectionTotalRows = createSelector(
  getCollectionState,
  fromOrgCollection.getTotalRows,
);
export const getPageSize = createSelector(
  getCollectionState,
  fromOrgCollection.getPageSize,
);
export const getPageIndex = createSelector(
  getCollectionState,
  fromOrgCollection.getPageIndex,
);
export const getSortActive = createSelector(
  getCollectionState,
  fromOrgCollection.getSortActive,
);
export const getSortDirection = createSelector(
  getCollectionState,
  fromOrgCollection.getSortDirection,
);
export const getFilter = createSelector(
  getCollectionState,
  fromOrgCollection.getFilter,
);

// Orgs
export const getOrgsEntitiesState = createSelector(
  getOrgsState,
  (state: OrgsState) => state.orgs,
);

export const getSelectedId = createSelector(
  getOrgsEntitiesState,
  fromOrgs.getSelectedId,
);

export const {
  selectIds: getOrgIds,
  selectEntities: getOrgEntities,
  selectAll: getAllOrg,
  selectTotal: getTotalOrgs,
} = fromOrgs.adapter.getSelectors(getOrgsEntitiesState);

export const getOrgCollection = createSelector(
  getOrgEntities,
  getCollectionIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  },
);

// Create Organization
export const getOrgState = createSelector(
  getOrgsState,
  (state: OrgsState) => state.create,
);

export const getCreateFormValue = createSelector(
  getOrgState,
  fromOrgCreatePage.getFormValue,
);

export const getCreateLoading = createSelector(
  getOrgState,
  fromOrgCreatePage.getLoading,
);

export const getCreateCreated = createSelector(
  getOrgState,
  fromOrgCreatePage.getCreated,
);

export const getCreateErrors = createSelector(
  getOrgState,
  fromOrgCreatePage.getError,
);

// Details
export const getDetailsState = createSelector(
  getOrgsState,
  (state: OrgsState) => state.details,
);
export const getDetailsLoading = createSelector(
  getDetailsState,
  fromOrgDetailsPage.getLoading,
);
export const getDetailsFormValue = createSelector(
  getDetailsState,
  fromOrgDetailsPage.getFormValue,
);
export const getDetailsFormData = createSelector(
  getDetailsState,
  fromOrgDetailsPage.getFormData,
);
export const getDetailsInitialFormData = createSelector(
  getDetailsState,
  fromOrgDetailsPage.getInitialFormData,
);
export const getDetailsName = createSelector(
  getDetailsState,
  fromOrgDetailsPage.getName,
);

// Users
export const getUsersState = createSelector(
  getOrgsState,
  (state: OrgsState) => state.users,
);

export const getUsersEntities = createSelector(
  getUsersState,
  fromOrgUsers.getEntities,
);

export const getUsersTotalRows = createSelector(
  getUsersState,
  fromOrgUsers.getTotalRows,
);
export const getUsersPageSize = createSelector(
  getUsersState,
  fromOrgUsers.getPageSize,
);
export const getUsersPageIndex = createSelector(
  getUsersState,
  fromOrgUsers.getPageIndex,
);
export const getUsersSortActive = createSelector(
  getUsersState,
  fromOrgUsers.getSortActive,
);
export const getUsersSortDirection = createSelector(
  getUsersState,
  fromOrgUsers.getSortDirection,
);
export const getUsersFilter = createSelector(
  getUsersState,
  fromOrgUsers.getFilter,
);
