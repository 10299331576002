import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

// Modules
import { MaterialModule } from "../../material";

import { PipesModule } from "../pipes";

// Components
import { LocationTreeComponent } from "./location-tree.component";
import { LocationTreeModalComponent } from "./location-tree-modal.component";

// Services
import { LocationTreeviewEventParser } from "./location-treeview-event-parser";

import { TreeModule } from "angular-tree-component";
import { TreeviewModule } from "ngx-treeview";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TreeModule,
        TreeviewModule,

        MaterialModule,

        PipesModule,
    ],

    exports: [
        LocationTreeComponent,
        TreeModule,
        TreeviewModule,
    ],

    declarations: [
        LocationTreeComponent,
        LocationTreeModalComponent,
    ],

    providers: [
        LocationTreeviewEventParser,
    ],

    entryComponents: [
        LocationTreeModalComponent,
    ],
})

export class LocationTreeModule {

}
