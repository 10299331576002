import { Directive, AfterViewInit, ElementRef } from '@angular/core';
import { AppSetting, CurrentInfoService, FeedbackService, AppSettingsService } from "@core/services";

@Directive({
  selector: "feedback-survey",
})
export class FeedbackSurveyDirective implements AfterViewInit {

  private cur: CurrentInfoService;
  private feedbackSvc: FeedbackService;

  constructor(private elementRef: ElementRef,
              feedbackService: FeedbackService,
              appSettings: AppSettingsService,
              current: CurrentInfoService) {

    this.cur = current;
    this.feedbackSvc = feedbackService;
  }

  ngAfterViewInit() {
    if (!this.cur.info.isEaseEmployee) {
      this.convertToScript();
    }
  }

  convertToScript() {

    const thisEmail = this.cur.info.user.Email;
    const timeNow = Math.floor(Date.now() / 1000);
    const languageCode = this.feedbackSvc.getLanguageCode();
    const orgID = this.cur.info.org.ID;
    const siteID = this.cur.info.activeSiteID;
    const orgLayerID = this.cur.info.user.OrgLayerID;

    let role1: string = "";
    let role2: string = "";

    if (this.cur.info.roleIsSiteAdmin) {
      role1 = "SiteAdmin";
    }
    if (this.cur.info.roleIsManager) {
      role2 = "Manager";
    }

    let settings: AppSetting;
    let authToken: string = "";

    window["wootric_survey_immediately"] = true;
    window["wootric_no_surveyed_cookie"] = true;

    this.feedbackSvc.getWootricAuthToken()
      .subscribe(x => {
        settings = x;
        authToken = settings.Value;
        console.log("auth Token:", authToken);

        window["wootricSettings"] = {
          email: thisEmail,
          created_at: timeNow,
          product_name: "Beacon Quality",
          account_token: authToken,
          language: languageCode,
          properties: {
            Org: orgID,
            Site: siteID,
            OrgLayerID: orgLayerID,
            Role1: role1,
            Role2: role2,
          }
        };

        /* TODO: Needs to be localized before enabling
        window["customMessages"] = {
            followup_question: "Can you tell us why you scored us the way you did?",
            followup_questions_list: {
              detractor_question: "Sorry to hear this! Can you tell us what we can do to get a 9 or 10 score?",
              passive_question: "Seems like you like the product. Can you tell us what we can do to get a 9 or 10 score?",
              promoter_question: "Thank you! What can we continue doing to always earn such a high score?",
              placeholder_text: "Thank you for giving us your feedback!",
            },
        };*/

        const script = document.createElement('script');

        script.type = "text/javascript";
        script.src = "https://cdn.wootric.com/wootric-sdk.js";
        script.async = true;
        script.onload = function() {
          window["WootricSurvey"].run(window["wootricSettings"]);
        };

        this.elementRef.nativeElement.appendChild(script);
      });
  }

}
