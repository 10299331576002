import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ConfirmationDialogService } from "../../../shared/confirmation/confirmation-dialog.service";
import { UiNotificationService } from "../../../core/services/common/ui-notification.service";
import {
  IAudit,
  IAuditReport,
    ICapaEventApprover, ICapaEventAssociation, ICapaEventLineItem, ICapaEventOwner, ICapaTask, IClientCapaEventLineItem,
  ILocation,
  IUser,
} from "../../../core/models/ease-models";
import { LocalizeService } from "../../../core/services/localize.service";
import { FileItem, FileUploader } from "ng2-file-upload";
import { StorageService } from "../../../core/services/storage.service";
import { NotifyService } from "../../../core/services/notify.service";
import { LocalService } from "../../../core/services/local.service";
import { CorrectiveActionsDataAccessService } from "../../services/corrective-actions-data-access.service";
import { MatDialog } from "@angular/material";
import { AuditListModalComponent } from "../../../results/audit-list/audit-list-modal.component";
import { IdentityService } from "../../../core/services/identity.service";
import { LocationTreeModalComponent } from "../../../shared/location-tree/location-tree-modal.component";
import { DialogManagerService } from "../../../results/dialog-manager.service";
import { AddEditCapaTaskModalComponent } from "../../dialogs/edit-capa-task/add-edit-capa-task-modal.component";
import { DialogConfigLg, DialogConfigMd, DialogConfigSm } from "../../../shared/dialogs";
import {FileUploadData} from "@shared/file-upload/file-upload.class";
import {CurrentInfoService} from "@core/services/current.service";
import { LocationTreeNode } from "@app/admin/admin-models";
import { CustomerListDialogComponent } from "@app/customer/companies/customer-list-dialog.component";
import { ICompanySite } from "@core/models/ease-models";
import { ExtendCapaEventDueModalComponent } from "@app/capa/dialogs/capa-extend-date/extend-capa-event-due-modal.component";

@Component({
  selector: "ease-capa-event-form",
  templateUrl: "./capa-event-form.component.html",
  styleUrls: ["./capa-event-form.component.scss"],
})
export class CapaEventFormComponent implements OnInit {

  @Input() public currentCapaEvent;
  @Input() public canEdit;
  @Input() public canEditValidationDueDate;
  @Input() public canEditValidationUsers;
  @Input() public currentUserID;
  @Input() public canAddCapaApprover;
  @Input() public capaEventInitialized;
  @Input() public possibleCapaOwners: IUser[];
  @Input() public possibleCapaApprovers: IUser[];
  @Input() public lineItems: IClientCapaEventLineItem[];
  @Input() public validationLineItems: IClientCapaEventLineItem[];
  @Input() isPrintMode: boolean = false;
  @Input() fileUploadData: FileUploadData;
  @Input() isSiteAdmin: boolean;
  @Input() daysDueAfterOpened: number;
  @Output() public capaTaskDeleted = new EventEmitter<any>();
  @Output() public taskStatusChanged = new EventEmitter<any>();
  @Output() public capaTaskAddedOrEdited = new EventEmitter<any>();
  @ViewChild("capaEventForm") public capaEventForm: NgForm;
  private showAddCapaOwnerSelect = false;
  private showAddCapaApproverSelect = false;
  private capaOwnerToAdd: IUser;
  private capaApproverToAdd: IUser;
  private uploader;
  private host: string;
  private url: string;
  private numAuditAssociations: number = 0;
  private numCustomerAssociations: number = 0;
  private numLocationAssociations: number = 0;

  constructor(private confirm: ConfirmationDialogService, private uiNotify: UiNotificationService,
              private localize: LocalizeService, private storage: StorageService,
              private identity: IdentityService, private notify: NotifyService, private local: LocalService,
              private dataAccess: CorrectiveActionsDataAccessService,
              private dialog: MatDialog, private dialogManager: DialogManagerService,
              private current: CurrentInfoService) {
  }

  public ngOnInit(): void {
    this.evalAssociations();
  }

  public deleteCapaEventApproverHandler(item: ICapaEventApprover) {

    this.confirm.open("", "_AreYouSureRemoveCAPAApprover_", true, "_Delete_", "_Cancel_");

    this.confirm.getResponse().first().subscribe((response) => {

      if (response === true) {
        this.removeCapaEventApprover(item);
      }
    });
  }

  public deleteCapaEventOwnerHandler(item: ICapaEventOwner) {

    this.confirm.open("", "_AreYouSureRemoveCAPAOwner_", true, "_Delete_", "_Cancel_");

    this.confirm.getResponse().first().subscribe((response) => {

      if (response === true) {
        this.removeCapaEventOwner(item);
      }
    });
  }

  private removeCapaEventApprover(approver: ICapaEventApprover) {
    const capaApprovers = this.currentCapaEvent.CapaEventApprovers;
    if (capaApprovers && capaApprovers.length > 0) {
      const index = capaApprovers.indexOf(approver);
      capaApprovers.splice(index, 1);
      this.capaEventForm.form.markAsDirty();
    }
  }

  private removeCapaEventOwner(owner: ICapaEventOwner) {
    const capaOwners = this.currentCapaEvent.CapaEventOwners;
    if (capaOwners && capaOwners.length > 0) {
      const index = capaOwners.indexOf(owner);
      capaOwners.splice(index, 1);
      this.capaEventForm.form.markAsDirty();
    }
  }

  private addCapaOwner() {
    if (this.capaOwnerToAdd) {
      const objectToAdd: ICapaEventOwner = {
        ID: 0, UserID: this.capaOwnerToAdd.ID, CapaEventID: this.currentCapaEvent.ID,
        OrganizationID: this.currentCapaEvent.OrganizationID, ReviewStatus: 0, User: this.capaOwnerToAdd,
      };
      if (this.capaOwnerToAdd) {
        let capaOwners = this.currentCapaEvent.CapaEventOwners;
        if (!capaOwners) {
          capaOwners = [];
          capaOwners.push(objectToAdd);
        } else {
          let index = -1;
          for (let i = 0; i < capaOwners.length; i++) {
            if (capaOwners[i].User && capaOwners[i].User.ID === this.capaOwnerToAdd.ID) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            this.uiNotify.open(this.localize.getLocalizedString("_CapaOwnerAlreadySelected_"));
          } else {
            capaOwners.push(objectToAdd);
          }
        }
      }

      this.capaOwnerToAdd = null;
      this.showAddCapaOwnerSelect = false;
    }
  }

  private addCapaApprover() {
    if (this.capaApproverToAdd) {
      const objectToAdd: ICapaEventApprover = {
        ID: 0, UserID: this.capaApproverToAdd.ID, CapaEventID: this.currentCapaEvent.ID,
        OrganizationID: this.currentCapaEvent.OrganizationID, ApprovalStatus: 0, User: this.capaApproverToAdd,
      };
      if (this.capaApproverToAdd) {
        let capaApprovers = this.currentCapaEvent.CapaEventApprovers;
        if (!capaApprovers) {
          capaApprovers = [];
          capaApprovers.push(objectToAdd);
        } else {
          let index = -1;
          for (let i = 0; i < capaApprovers.length; i++) {
            if (capaApprovers[i].User && capaApprovers[i].User.ID === this.capaApproverToAdd.ID) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            this.uiNotify.open(this.localize.getLocalizedString("_CapaApproverAlreadySelected_"));
          } else {
            capaApprovers.push(objectToAdd);
          }
        }
      }
      this.capaApproverToAdd = null;
      this.showAddCapaApproverSelect = false;
    }
  }

  private deleteCapaTaskHandler(item: ICapaEventLineItem) {

    this.confirm.open("_RemoveCapaTask_", "_AreYouSureDeleteCapaTask_", true, "_Delete_", "_Cancel_");

    this.confirm.getResponse().first().subscribe((response) => {

      if (response === true) {
        this.deleteCapaTask(item);
      }
    });
  }

  private deleteCapaTask(capaEventLineItem: ICapaEventLineItem): void {
    this.dataAccess.deleteItem("capaeventlineitem", capaEventLineItem.ID).subscribe(() => {
      this.capaTaskDeleted.emit();
    });
  }

  private onTaskStatusChanged(): void {
    this.taskStatusChanged.emit();
  }

  private removeCapaEventAssociation(indx): void {
    let curAssociations = this.currentCapaEvent.CapaEventAssociations;
    if (indx > -1) {
      curAssociations = curAssociations.splice(indx, 1);
    }
    this.evalAssociations();
    this.capaEventForm.form.markAsDirty();
  }

  private evalAssociations() {
    this.numAuditAssociations = 0;
    this.numCustomerAssociations = 0;
    this.numLocationAssociations = 0;

    const curAssociations = this.currentCapaEvent.CapaEventAssociations;

    curAssociations.forEach((assoc: ICapaEventAssociation) => {

      if (assoc.AuditID != null) {
        this.numAuditAssociations++;
      }
      if (assoc.CompanySiteID != null) {
        this.numCustomerAssociations++;
      }
      if (assoc.LocationID != null) {
        this.numLocationAssociations++;
      }

    });
  }

  private openAssociationSelection(type: number) {

    switch (type) {
      case 1:
        this.openAuditListSelectionModal();
        break;
      case 2:
        this.openLocationSelectionModal();
        break;
      case 3:
        this.openCustomerListSelectionModal();
        break;
    }
  }

  private openLocationSelectionModal(): void {
    const selectedLocations = [];
    const curAssociations = this.currentCapaEvent.CapaEventAssociations;
    curAssociations.forEach((assoc) => {
      if (assoc.LocationID != null) {
        selectedLocations.push(assoc.Location);
      }
    });

    const config = new DialogConfigMd();
    config.data = { activeSite: this.current.info.activeSiteID};
    const dialogRef = this.dialog.open(LocationTreeModalComponent, config);

    dialogRef.afterClosed().subscribe(locations => {
      if (locations !== null) {
        locations.forEach((location: ILocation) => {
          const filtered = curAssociations.filter(item => item.LocationID === location.ID);
          if (filtered.length === 0) {
            const newAssociation: ICapaEventAssociation = {
              LocationID: location.ID,
              CapaEventAssociationType: 2,
              CapaEventID: this.currentCapaEvent.ID,
              Location: location,
            };
            curAssociations.push(newAssociation);
          }
        });

        this.capaEventForm.form.markAsDirty();
        this.evalAssociations();
      }
    });
  }

  private openCustomerListSelectionModal() {
    const config = new DialogConfigLg();
    let existingCustomerAssociations: ICompanySite[] = [];
    if (this.currentCapaEvent && this.currentCapaEvent.CapaEventAssociations && this.currentCapaEvent.CapaEventAssociations.length) {
      existingCustomerAssociations = this.currentCapaEvent.CapaEventAssociations.filter(item => item.CompanySiteID);
    }
    config.data = { customerAssociations: existingCustomerAssociations};
    const curAssociations = this.currentCapaEvent.CapaEventAssociations;

    const dialogRef = this.dialog.open(CustomerListDialogComponent, config);

    dialogRef.afterClosed().subscribe((customers: ICompanySite[]) => {
      if (customers) {
        customers.forEach((customer: ICompanySite) => {
          const filtered = curAssociations.filter(item => item.CompanySiteID === customer.ID);
          if (filtered.length === 0) {
            const newAssociation: ICapaEventAssociation = {
              CompanySiteID: customer.ID,
              CapaEventAssociationType: 3,
              CapaEventID: this.currentCapaEvent.ID,
              CompanySite: customer,
            };
            curAssociations.push(newAssociation);
          }
        });

        this.capaEventForm.form.markAsDirty();
        this.evalAssociations();
      }
    });
  }

  private openAuditListSelectionModal() {

    const selectedIDs = [];
    const curAssociations = this.currentCapaEvent.CapaEventAssociations;
    curAssociations.forEach((assoc) => {
      if (assoc.AuditID != null) {
        selectedIDs.push(assoc.AuditID);
      }

    });

    const config = new DialogConfigLg();
    config.data = {
      selectedAuditIDs: selectedIDs,
      completedOnly: true,
      showIdColumn: true,
      showAuditTypeColumn: true,
      showDimensionColumns: false,
      showSearchInput: true,
      showDateRange: true,
    };
    const dialogRef = this.dialog.open(AuditListModalComponent, config);
    dialogRef.afterClosed().subscribe(auditList => {
      if (auditList && auditList.length) {
        auditList.forEach((audit: IAuditReport) => {

          const filtered = curAssociations.filter(item => item.AuditID === audit.ID);
          if (filtered.length === 0) {
            // dummy object with justt ID and Description correctly set
            const auditObject: IAudit = {
              ID: audit.ID,
              Description: audit.Name,
              AuditCategory: 0,
              ApplicableLocationTierLevels: "",
              AssignedByUserID: 0,
              CalendarEventID: 0,
              DateUpdated: null,
              ChangeToken: "",
              DateCompleted: null,
              DateStarted: null,
              SiteID: 0,
              LocationID: 0,
              DocumentID: 0,
              DocumentRevID: 0,
              Shift: null,
              ShiftManagerID: 0,
              DepartmentID: 0,
              AuditorUserID: 0,
              EnteredByUserID: 0,
              PercentComplete: 0,
              Score: 0,
              Status: 0,
              OrgLayerID: 0,
              IsPassed: false,
              HasNonConformance: false,
              DeletedByUserID: 0,
              DeleteDate: null,
              JobID: 0,
              AuditorUser: null,
              NonConformanceData: null,
              IsUpdatable: false,
              IsOnDemand: false,
              AuditQuestions: [],
              IsAdHoc: false,
              AuditSummary: null,
              IsSchedulerNotified: false,
              IsNotSynced: false,
              PendingUpload: false,
            };

            const newAssociation: ICapaEventAssociation = {
              AuditID: audit.ID,
              CapaEventAssociationType: 1,
              CapaEventID: this.currentCapaEvent.ID,
              Audit: auditObject,
            };
            curAssociations.push(newAssociation);
          }

        });

        this.capaEventForm.form.markAsDirty();
        this.evalAssociations();
      }
    });
  }

  private getCapaEventFullImage = (img) => {
      this.dataAccess.getCapaArtifact(img.ID).subscribe((data) => {
          if (this.storage.isDoc(img.FileFormatID)) {
              window.location.href = data.Url;
          } else {
               this.dialogManager.showDetailedImageDlg(data.Url);
          }
      });
  }

  private addCapaTask(): void {

    const config = new DialogConfigMd();
    const dialogRef = this.dialog.open(AddEditCapaTaskModalComponent, config);

    dialogRef.afterClosed().subscribe((capaTask: ICapaTask) => {
      if (capaTask) {
        const capaEventLineItem: any = {};
        capaEventLineItem.CapaEventID = this.currentCapaEvent.ID;
        capaEventLineItem.CapaTask = capaTask;
        this.dataAccess.addCapaEventLineItem(capaEventLineItem).subscribe((data) => {
          if (data) {
            this.capaTaskAddedOrEdited.emit();
          }
        });
      }
    });
  }

  private editCapaTask(capaEventLineItem: ICapaEventLineItem): void {
    // first obtain deep copy of the object
    const capaEventLineItemClone: IClientCapaEventLineItem = JSON.parse(JSON.stringify(capaEventLineItem));
    const capaTask = capaEventLineItemClone.CapaTask;
    const config = new DialogConfigMd();
    config.data = {isReadOnly: !this.canEdit, capaTask: capaTask};
    const dialogRef = this.dialog.open(AddEditCapaTaskModalComponent, config);

    dialogRef.afterClosed().subscribe((ct: ICapaTask) => {
      const newCapaEventLineItem: any = {};
      newCapaEventLineItem.CapaEventID = this.currentCapaEvent.ID;
      newCapaEventLineItem.CapaTask = ct;
      this.dataAccess.updateItem<IClientCapaEventLineItem>("capaeventlineitem", capaEventLineItemClone.ID, capaEventLineItemClone,
        {updateCapaTask: true}).subscribe((data) => {
        if (data) {
          this.capaTaskAddedOrEdited.emit();
        }
      });
    });
  }

  private uploaderFilesChanged(): void {
    this.capaEventForm.form.markAsDirty();
  }

  private extendDueDate(): void {
    // first obtain deep copy of the object
    const config = new DialogConfigSm();
    config.data = {CurrentCapaEvent: this.currentCapaEvent, daysDueAfterOpened: this.daysDueAfterOpened};
    const dialogRef = this.dialog.open(ExtendCapaEventDueModalComponent, config);

    dialogRef.afterClosed().subscribe(() => {

    });
  }


}
