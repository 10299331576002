import { LayoutActionTypes, LayoutActions } from "../actions/layout";

export interface State {
  showSidenav: boolean;
  progress: number;
}

const initialState: State = {
  showSidenav: false,
  progress: 0,
};

export function reducer(state: State = initialState, action: LayoutActions): State {
  switch (action.type) {
    case LayoutActionTypes.StartProgress:
      return {
        ...state,
        progress: state.progress + 1,
      };

    case LayoutActionTypes.EndProgress:
      return {
        ...state,
        progress: state.progress - 1,
      };

    case LayoutActionTypes.CloseSidenav:
      return {
        ...state,
        showSidenav: false,
      };

    case LayoutActionTypes.OpenSidenav:
      return {
        ...state,
        showSidenav: true,
      };

    default:
      return state;
  }
}

export const getShowSidenav = (state: State) => state.showSidenav;
export const getProgress = (state: State) => state.progress;
