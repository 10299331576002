import { OnDestroy } from "@angular/core";
import {
  IGridsterReportPanelLayoutItem2,
  IHighChartSeries,
  IReportPanelSettings,
} from "../../core/models/ease-models";
import { IDialogReportPanelMessage, ReportNotifyService, ReportPanelDialogMessageType } from "@shared/reports/report-notify.service";;
import { Subscription } from "rxjs";
import { LocalizeService } from "../../core/services/localize.service";
import { ChartSettingsModalComponent } from "../../report/reports/chart-settings-modal.component";
import { MatDialog } from "@angular/material";
import { DialogConfigSm, DialogConfigLg } from "../dialogs/index";
import { ReportDrilldownModalComponent } from "@shared/reports/report-drilldown/report-drilldown-modal.component";
import { LocalService } from "../../core/services/index";

export enum LegendLayout {
  horizontal,
  vertical,
}

export enum LegendAlign {
  left,
  center,
  right,
}

export class BasePanel implements OnDestroy {

  // the legend defaults to horizontal layout and center alignment
  public legendLayout: LegendLayout = LegendLayout.horizontal;
  public legendAlign: LegendAlign = LegendAlign.center;

  public chartConfig: any;
  public chartData: IHighChartSeries[];
  public categoryAxis: string[];
  public secondaryCategoryAxis: string[];
  public enableLegend: boolean = false;
  public enableStacking: boolean = false;
  public enableRightAxis: boolean = false;
  public clickedCategory: string = null;
  public maxValue: number = null;

  // Public Panel properties
  public widgetFilters: IReportPanelSettings;
  public chartDescription: string;
  public chartSubTitle: string = null;
  public leftYAxisText: string = "Total";
  public bottomXAxisText: string = "";
  public chartDepth: number = 0;
  public isLargePanel: boolean = false;
  public loading: boolean = true;
  public initialLoadComplete: boolean = false;
  public hideLegendSymbol = false;
  public showSecondaryChartDescription: boolean = false;
  public emptyData: boolean = false;
  public chartSecondDescription: string = ""; // this is needed only for some graphs (for example 'countermeasures')

  public panelResizedSubscription: Subscription;

  protected chart: any = null;

  public constructor(private i18n: LocalizeService,
                     protected reportNotifyService: ReportNotifyService,
                     private dialog: MatDialog,
                     private local: LocalService) {

    this.panelResizedSubscription = this.reportNotifyService.getMessage().subscribe(message => {
      // this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.panelResized, value: 0});
      const messageFromReportFilterDialog: IDialogReportPanelMessage = message;
      if (messageFromReportFilterDialog && (messageFromReportFilterDialog.key || messageFromReportFilterDialog.key === 0)) {
        switch (messageFromReportFilterDialog.key) {
          case ReportPanelDialogMessageType.panelResized: {
            if (this.chart != null) {
              this.chart.reflow();
            }
            break;
          }
        }
      }
    });

    this.reportNotifyService.sendMessage({key: ReportPanelDialogMessageType.panelResized, value: 0});
  }

  public ngOnDestroy(): void {
    if (this.panelResizedSubscription) {
      this.panelResizedSubscription.unsubscribe();
    }
  }
    // Derived class must implement this
  public onSeriesClick(e: any): void {
    return e;
  }

  public onLegendItemClick(e: any): void {
    return e;
  }

  public restorePanelSettings(panelItemInfo: IGridsterReportPanelLayoutItem2, id?: number): boolean {
    let restored = false;
    if (panelItemInfo != null) {
      const key = this.getLocalStorageKey(panelItemInfo, id);
      if (this.local.getItem(key) != null) {
        this.widgetFilters = JSON.parse(window.localStorage.getItem(key));
        restored = true;
      }
    }
    return restored;
  }

  public savePanelSettings(panelItemInfo: IGridsterReportPanelLayoutItem2, id?: number): void {
    if (panelItemInfo != null) {
      const key = this.getLocalStorageKey(panelItemInfo, id);
      this.local.setItem(key, this.widgetFilters);
    }
  }

  public setChartConfig(title: string = null, enableDataLabels: boolean = false, height: number = 322): void {
    const noData = this.i18n.getLocalizedString("_NoDataToDisplay_");
    this.chartConfig = {
      lang: {noData},
      chart: this.getChartSettings(),
      pane: this.setPane(),
      tooltip: this.setTooltip(),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: enableDataLabels,
            formatter() {
              if (this.y !== 0 && this.y !== "" && this.y !== "0") {
                if (this.series.name === "Pareto") {
                  return this.y + "%";
                } else {
                  return this.y;
                }
              } else {
                return null;
              }
            },
            color: "black",
          },

          cursor: "pointer",
          allowPointSelect: false,
          events: {
            legendItemClick: (event: any) => {
              this.onLegendItemClick(event);

            },
            click: (event: any) => {
              if (event.currentTarget.name !== "Pareto") {
                this.onSeriesClick(event);
              }
            },
          },
        },
        column: {
          stacking: (this.enableStacking) ? "normal" : null,
        },
        pie: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: this.enableLegend,
        layout: LegendLayout[this.legendLayout],
        align: LegendAlign[this.legendAlign],
        symbolHeight: (this.hideLegendSymbol) ? "0px" : null,
        itemStyle: {
          itemWidth: "100%",
        },
      },
      exporting: {
        csv: {
          columnHeaderFormatter: this.setColumnHeaderFormatter,
        },
        buttons: this.setExportMenuButtons(),
      },

      series: this.chartData,
      title: {
        text: title,
      },
      subtitle: {
        text: this.chartSubTitle,
      },
      size: {
        height,
      },
      loading: false,
      xAxis: this.setXAxis(),
      yAxis: [
        this.setLeftYAxis(enableDataLabels), this.setRightYAxis(enableDataLabels),
      ],

      credits: {
        enabled: false,
      },

    };
  }

  public showAuditListDlg(auditIDs: number[], isOrgLevel: boolean = false, description?: string, includeMitigationCount: boolean = false,
                          showResponsibleParty: boolean = false, $scope = null, isMitigationView: boolean = false, isAdHocMitigation: number[] = null, showDueDate: boolean = true): void {

    const config = new DialogConfigLg();
    config.data = {
      auditIDs: auditIDs,
      modalDescription: description,
      isOrgLevel: isOrgLevel,
      includeMitigationCount: includeMitigationCount,
      showResponsibleParty: showResponsibleParty,
      isMitigationView: isMitigationView,
      isAdhocMitigation: isAdHocMitigation,
      showDueDate: showDueDate
    };

    const dialogRef = this.dialog.open(ReportDrilldownModalComponent, config);
    dialogRef.afterClosed().subscribe(auditList => {
      // add here code to execute after the modal is closed
    });
  }

  protected saveChartInstance(chartInstance: any) {
    this.chart = chartInstance;
    setTimeout(() => {
      if (!this.isEmpty(this.chart)) {
        this.chart.reflow();
        // always put the export buton on top
        this.chart.exportSVGElements[0].toFront();
        // this.chart.tooltip.label.toFront();
      }
    }, 100);
  }

  // this method must be overwritten in child classes for different x-axis behavior
  protected setXAxis(): any[] {
    return [{
      currentMin: 0,
      currentMax: (this.categoryAxis != null) ? this.categoryAxis.length - 1 : 0,
      title: {text: this.bottomXAxisText},
      categories: this.categoryAxis,
      crosshair: true,
    }];
  }

  // this method must be overwritten in child classes for different chart settings (spider chart for example)
  protected getChartSettings() {
    const obj: any = {
      type: "column",
      marginRight: (this.enableRightAxis != null) ? 36 : null,
      plotBorderColor: "#D3D3D3",
      plotBorderWidth: 1,
    };
    return obj;
  }

  // this method must be overwritten in child classes for different chart settings (spider chart for example)
  protected setLeftYAxis(enableDataLabels: boolean = false): any {

    const obj = { // left y axis
      title: {
        text: this.leftYAxisText,
      },
      labels: {
        enabled: !enableDataLabels,
        align: "left",
        x: 3,
        y: 16,
        format: "{value:.,0f}",
      },
      showFirstLabel: false,
      allowDecimals: false,
      max: this.maxValue,
      gridLineWidth: (enableDataLabels === true) ? 0 : 1,
    };
    return obj;
  }

  protected setRightYAxis(enableDataLabels: boolean = false): any {

    const obj = { // right y axis
      visible: this.enableRightAxis,
      gridLineWidth: 0,
      opposite: true,
      title: {
        text: "Percentage (%)",
        style: {"color": "#ec5e0a", "font-weight": "bold"},
      },
      labels: {
        enabled: !enableDataLabels,
        max: 100,
        align: "right",
        x: -3,
        y: 16,
        format: "{value:.,0f}",
        style: {"color": "#ec5e0a", "font-weight": "bold"},
      },
      showFirstLabel: true,
      max: 100,
      min: 0,
      alignTicks: false,
    };
    return obj;
  }

  // this method must be overwritten in child classes for different chart settings (spider chart for example)
  protected setTooltip(): any {
    const obj = {
      style: {
        padding: 10,
        fontWeight: "bold",
      },

      formatter() {
        let seriesName = this.series.name;
        let plotValue = this.x;

        if (seriesName.indexOf("Series") > -1) {
          seriesName = "Value";
        }
        if (plotValue == null) {
          plotValue = "";
        }

        return seriesName + ": " + this.y;
      },
    };
    return obj;
  }

  // this method must be overwritten in child classes for different chart settings (spider chart for example)
  protected setPane(): any {
    const obj = {}; // the default is an empty object
    return obj;
  }

  // this method must be overriden in child classes for different chart settings (spider chart for example)
  protected setColumnHeaderFormatter(series: any, key: any, keyLength: any): any {
    // just keep the default behavior
    if (series.name) {
      return series.name + (keyLength > 1 ? " (" + key + ")" : "");
    }
  }

  // this method must be overriden in child classes for different export menu bahavior (summary metrics chart for example)
  protected setExportMenuButtons(): any {
    return {
      contextButton: {
        menuItems: [
          "printChart",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    };
  }

  private getLocalStorageKey(panelItemInfo: IGridsterReportPanelLayoutItem2, id?: number): string {

    const key = (id != null && id > 0) ? "panelSettings." + id + "." + panelItemInfo.Guid + ".v4" : "panelSettings." + panelItemInfo.Guid + ".v4";
    return key;
  }

  private openChartSettingsDialog(event: any, panelItemInfo: IGridsterReportPanelLayoutItem2): void {
    const config = new DialogConfigSm();
    let element: any = event.target;
    let elementType: string = element.nodeName.toLowerCase();

    while (elementType !== "button") {
      element = element.parentNode;
      elementType = element.nodeName.toLowerCase();
    }
    element.focus();

    config.data = {widgetFilters: this.widgetFilters, panelItemInfo: panelItemInfo};
    const dialogRef = this.dialog.open(ChartSettingsModalComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      element.blur();
    });
  }

  private isEmpty(obj: any): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

}
