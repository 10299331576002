import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromCollection from "./collection.reducer";
import * as fromGroups from "./permission-groups.reducer";
import * as fromRoot from "../../../reducers";
import * as fromGroupDetails from "./group-deatils.reducer";

export interface PermissionGroupsState {
  groups: fromGroups.State;
  collection: fromCollection.State;
  groupDetails: fromGroupDetails.State;
}

export interface State extends fromRoot.State {
  roles: PermissionGroupsState;
}

export const reducers: ActionReducerMap<PermissionGroupsState> = {
  groups: fromGroups.reducer,
  collection: fromCollection.reducer,
  groupDetails: fromGroupDetails.reducer,
};

export const getGroupsState = createFeatureSelector<PermissionGroupsState>("admin-permission-groups");

export const getRoleEntitiesState = createSelector(
  getGroupsState,
  state => state.groups,
);

export const {
  selectEntities: getGroupEntities,
} = fromGroups.adapter.getSelectors(getRoleEntitiesState);

export const getCollectionState = createSelector(
  getGroupsState,
  (state: PermissionGroupsState) => state.collection,
);

export const getTotalRows = createSelector(
  getCollectionState,
  fromCollection.getTotalRows,
);

export const getCollectionGroupIds = createSelector(
  getCollectionState,
  fromCollection.getIds,
);

export const getGroupCollection = createSelector(
  getGroupEntities,
  getCollectionGroupIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  },
);

export const getPermissionGroupState = createSelector(
  getGroupsState,
  state => state.groupDetails,
);

export const getPermissions = createSelector(
  getPermissionGroupState,
  fromGroupDetails.getPermissions,
);

export const getGroupLoading = createSelector(
  getPermissionGroupState,
  fromGroupDetails.getPermissionGroupLoading,
);

export const getPermissionGroupName = createSelector(
  getPermissionGroupState,
  fromGroupDetails.getGroupName,
);

export const getPermissionGroupId = createSelector(
  getPermissionGroupState,
  fromGroupDetails.getGroupId,
);

export const getErrorMessage = createSelector(
  getPermissionGroupState,
  fromGroupDetails.getError,
);

export const getPageIndex = createSelector(
  getCollectionState,
  fromCollection.getPageIndex,
);

export const getPageSize = createSelector(
  getCollectionState,
  fromCollection.getPageSize,
);

export const getSortActive = createSelector(
  getCollectionState,
  fromCollection.getSortActive,
);

export const getSortDirection = createSelector(
  getCollectionState,
  fromCollection.getSortDirection,
);

export const getFilter = createSelector(
  getCollectionState,
  fromCollection.getFilter,
);

export const getGroups = createSelector(
  getCollectionState,
  fromCollection.getGroups,
);
