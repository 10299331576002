// Angular node modules
import {
  Component, OnInit, OnDestroy, Output, EventEmitter,
  ViewChild, NgZone, Inject, Injectable,
} from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { NgForm } from "@angular/forms";

// Services
import { NotifyService } from "@core/services/notify.service";
import { ArtifactService } from "@core/services/common/artifact.service";
import { AuditDetailService } from "@core/services/common/audit-detail.service";
import { LoggerService } from "@core/services/logger.service";

// Models
import { IAudit, IAuditQuestionArtifact, IAuditAnswerArtifact, IAuditLineItem, IAuditQuestionGroup, IDocumentRev, IClientAuditQuestion, IClientAuditAnswer, IOrgArtifact,
         QuestionResponseTypes, ICustomCategory, ICustomItem, ICustomCategoryLineItem } from "../../core/models/ease-models";
import { AuditPrintData } from "./audit-print.types";
import { IClientAuditQuestionArtifact } from "../conduct-audit/audit-conduct.component.types";

// Reactive components
import { Observable,  Subject,  ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  styleUrls: ["../conduct-audit/audit-conduct.component.scss", "./audit-print.component.scss"],
  templateUrl: "./audit-print.component.html",
})

export class AuditPrintComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  public logoData: string;
  public auditID: number = 0;
  public audit: IAudit;
  public auditDoc: IDocumentRev;
  public auditQuestions: IClientAuditQuestion[];
  public groupQuestions: boolean = false;
  public onePageAudit: boolean = false;
  public failureCategories: ICustomCategory[];
  public failureModes: ICustomItem[];
  public questionGroups: any[];
  public auditLineItems: IAuditLineItem[];
  public customCategoryLineItems: ICustomCategoryLineItem[];
  private numImages: number = 0;

  constructor(private artifactService: ArtifactService,
              private auditDetailService: AuditDetailService,
              private notify: NotifyService,
              private route: ActivatedRoute,
              private log: LoggerService) {
    this.notify.broadcast("hide-top-nav", true);
    this.notify.broadcast("hide-left-nav", true);
    this.notify.broadcast("print-window-on", true);
  }

  ngOnInit() {
    const auditID = parseInt(this.route.snapshot.params.id, 10);
    this.getLogoData();
    this.getAuditPrintData(auditID);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private getLogoData() {
    this.artifactService.getOrganizationArtifact().pipe(takeUntil(this.destroy$))
      .subscribe((orgArtifact: IOrgArtifact) => {
        this.logoData = orgArtifact ? orgArtifact.Url : null;
      });
  }

  private getAuditPrintData(auditID: number): void {
    this.auditDetailService.getAuditByID(auditID, "Audits").subscribe((content: any) => {
      if (content) {
        this.audit = content.auditDetail.Audit;
        this.auditDoc = content.auditDetail.DocumentRev;
        this.auditLineItems = content.auditDetail.AuditLineItems;
        this.customCategoryLineItems = content.auditDetail.CustomCategoryLineItems;
        const auditQuestionArtifacts: IAuditQuestionArtifact[] = content.auditDetail.AuditQuestionArtifacts;
        const auditAnswerArtifacts: IAuditAnswerArtifact[] = content.auditDetail.AuditAnswerArtifacts;
        const auditQuestionGroups: IAuditQuestionGroup[] = content.auditDetail.AuditQuestionGroups;
        this.getQuestionGroups(this.audit.Description, auditQuestionGroups);
        this.auditQuestions = [];

        this.auditLineItems = this.auditLineItems.sort((l, b) => l.AuditQuestionGroupID - b.AuditQuestionGroupID || l.SequenceNum - b.SequenceNum || l.ID - b.ID);
        this.auditLineItems.forEach((li, index) => {
          const question: IClientAuditQuestion = (li.AuditQuestion as IClientAuditQuestion);
          const answer: IClientAuditAnswer = (question.AuditAnswer as IClientAuditAnswer);

          question.images = [];
          answer.images = [];
          question.currentImageIndex = 0;
          answer.IsOptionalMitigationSelected = false; // default to false
          // set the "IsRequired" field for each question
          question.IsRequired = li.IsRequired;
          question.AltResponsiblePartyID = li.AltResponsiblePartyID;
          question.AuditQuestionGroupID = li.AuditQuestionGroupID;

          const questionItems = (question !== null && question.QuestionText !== null) ? question.QuestionText.split(" ") : [];
          if (questionItems.length > 0) {
            for (const questionItem of questionItems) {
              question.WordBreakAll = (questionItem.length > 50);
            }
          }

          this.auditQuestions.push(question);

          // Populate question & answer with failure mode information
          if (answer.IssueClassification !== null && answer.IssueClassification.CustomCategory === null) {
            // assign selected failure category / custom category
            answer.IssueClassification.CustomCategory = this.getCustomCategoryByID(answer.IssueClassification.FailureCategoryID);

            // assign selected failure mode/ custom item
            answer.IssueClassification.CustomItem = this.getFailureModeByID(answer.IssueClassification.FailureModeID);
          }

          // If we're grouping questions by question group...
          if (this.groupQuestions) {
            const results: any[] = this.questionGroups.filter((questionGroup: any) => questionGroup.ID === li.AuditQuestionGroupID);
            if (results.length !== 0) {
              results[0].Questions.push(question);
            }
          }

          if (question.CustomField !== null) {
            this.loadCustomFields(question).subscribe((data) => {

            });
          }

          // for multi-select questions with mitigation required, to separate details by custom items
          question.DisplayCustomItemsInGroup = question.QuestionType !== QuestionResponseTypes.MultiSelect ||
            (question.QuestionType === QuestionResponseTypes.MultiSelect && !(question.MitigationResponseOption > 0));

          this.loadQuestionAnswerImages(question, answer, auditQuestionArtifacts, auditAnswerArtifacts);
        });

        if (this.auditDoc && this.auditDoc.Document && this.auditDoc.Document.Properties && this.auditDoc.Document.Properties.GroupQuestions) {
          const props = this.auditDoc.Document.Properties.hasOwnProperty("OnePageAudit") ? this.auditDoc.Document.Properties : this.auditDoc.Properties;

          this.onePageAudit = props.OnePageAudit === "1";
          this.groupQuestions = props.GroupQuestions === "1" || this.onePageAudit;
        } else {
          this.log.debug("Error: DocumentRev is null");
        }
      } else {
        this.log.debug("auditID not found: " + auditID);
      }
    });
  }

  private getCustomCategoryByID(id: number): ICustomCategory {
    const filtered: any = this.failureCategories.filter((cat: ICustomCategory) => cat.ID === id);
    return (filtered.length !== 0) ? filtered[0] : null;
  }

  // from AuditBase
  private getFailureModeByID(id: number): ICustomItem {
    const filtered: any[] = this.failureModes.filter((modes: any) => modes.ID === id);
    return (filtered.length !== 0) ? filtered[0] : null;
  }

  private getQuestionGroups(auditDescription: string, auditQuestionGroups: IAuditQuestionGroup[]): void {
    auditQuestionGroups = auditQuestionGroups.sort((a, b) => a.Sequence - b.Sequence);
    this.questionGroups = [];
    if (this.groupQuestions) {
      // this will handle the Group 'None'
      this.questionGroups.push({ ID: null, Name: "", Questions: [] });

      for (const questionGroup of auditQuestionGroups) {
        const desc = (this.onePageAudit) ? auditDescription : questionGroup.Description;
        this.questionGroups.push({ ID: questionGroup.ID, Name: desc, Questions: [] });
      }
    }
  }

  private loadQuestionAnswerImages(question: IClientAuditQuestion, answer: IClientAuditAnswer, auditQuestionArtifacts: IAuditQuestionArtifact[], answerArtifacts?: IAuditAnswerArtifact[]): void {
    const numImages: number = 0;

    // load question artifacts
    if (question.Artifacts !== null && question.Artifacts.length > 0) {
      const questionArtifacts: IAuditQuestionArtifact[] = auditQuestionArtifacts.filter((artifact: IAuditQuestionArtifact) => artifact.AuditQuestionID === question.ID);
      if (questionArtifacts !== null && questionArtifacts.length > 0) {
        questionArtifacts.forEach((artifact: IClientAuditQuestionArtifact) => {
          artifact.OriginalArtifactUrl = artifact.Url;
          artifact.Url = this.artifactService.setImageUrl(artifact, null);
          question.images.push(artifact);
          this.numImages++;
        });
      }
    }

    // load answer images
    if ((answer !== null && answer.HasArtifact) || (answerArtifacts.filter((artifact: IAuditAnswerArtifact) => artifact.AuditAnswerID === answer.ID).length > 0)) {
      const auditAnswerArtifacts: IAuditAnswerArtifact[] = answerArtifacts.filter((artifact: IAuditAnswerArtifact) => artifact.AuditAnswerID === answer.ID);
      auditAnswerArtifacts.forEach((artifact: IAuditAnswerArtifact) => {
        answer.HasArtifact = true;
        artifact.Url = this.artifactService.setImageUrl(artifact, null);
        answer.images.push(artifact);
        this.numImages++;
      });
    }
  }

  private loadCustomFields(question: IClientAuditQuestion): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const customField = question.CustomField;

    if (customField !== null) {
      if (question.QuestionType === QuestionResponseTypes.MultipleChoice ||
        question.QuestionType === QuestionResponseTypes.MultiSelect) {

        const groupID = customField.RestrictToGroup;
        const restrict = customField.RestrictRange;

        const filteredCustomCategoryLineItems = this.getCustomCategoryLineItemByCustomGroupID(this.customCategoryLineItems, groupID);
        // Based off the custom category line item, create a list of categories and nest the custom item under those categories
        // Restrict category values based on the custom field definition
        let validValues: any[] = [];
        if (restrict !== null) {
          validValues = restrict.split(",");
        }

        const categories: ICustomCategory[] = [];
        filteredCustomCategoryLineItems.forEach((li: ICustomCategoryLineItem) => {
          if (restrict === null || validValues.indexOf(li.CustomCategoryID.toString()) >= 0) {
            const catID = li.CustomCategoryID;
            const filtered = categories.filter((cat: ICustomCategory) => cat.ID === catID);
            if (filtered.length === 0) {
              li.CustomCategory.CustomItems = [];
              li.CustomCategory.CustomItems.push(li.CustomItem);
              categories.push(li.CustomCategory);
            } else {
              filtered[0].CustomItems.push(li.CustomItem);
            }
          }
        });
        question.customCategories = JSON.parse(JSON.stringify(categories));    // create new object of categories
        result.next(true);
        result.complete();
      } else if (question.QuestionType === QuestionResponseTypes.Numeric) {
        const restrictRange = customField.RestrictRange;

        if (restrictRange != null) {
          const dashCount = restrictRange.split(/(?=-)/);

          if (dashCount.length === 2) { // range is negative to positive or positive to positive
            question.MinValue = parseFloat(dashCount[0]);
            question.MaxValue = parseFloat(dashCount[1].replace("-", ""));
          } else if (dashCount.length === 3) { // range is negative to negative
            question.MinValue = parseFloat(dashCount[0]);
            question.MaxValue = parseFloat(dashCount[2]);
          }
        }
      }
    } else {
      result.next(false);
      result.complete();
    }

    return result.asObservable();
  }

  private getCustomCategoryLineItemByCustomGroupID(customCategoryLineItems: ICustomCategoryLineItem[], customGroupID: number): ICustomCategoryLineItem[] {
    let results: ICustomCategoryLineItem[] = [];
    if (customCategoryLineItems && customCategoryLineItems.length > 0) {
      results = customCategoryLineItems.filter((c: ICustomCategoryLineItem) => c.CustomCategory && c.CustomCategory.CustomGroupID === customGroupID);
    }
    return results;
  }
}
