import { Action } from "@ngrx/store";

// Models
import { Tab } from "../models/tab";

export enum TabsActionTypes {
  Open = "[Tabs] Open",
  Close = "[Tabs] Close",
}

export class Open implements Action {
  readonly type = TabsActionTypes.Open;

  constructor(public payload: Tab[]) {}
}

export class Close implements Action {
  readonly type = TabsActionTypes.Close;
}

export type TabsActionsUnion =
  | Open
  | Close;
