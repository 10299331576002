import { Component, Input, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { NgForm, ControlContainer } from "@angular/forms";
import { IAudit, IClientAuditAnswer, IClientAuditQuestion, ICustomCategoryLineItem, ICustomFieldValue, ICustomItem, IRank, IUser  } from "../../core/models/ease-models";
import { AppSettingsService } from "../../core/services/app-settings.service";
import { CurrentInfoService } from "../../core/services/current.service";

import * as _ from "lodash";

@Component({
    selector: "ease-in-audit-mitigation",
    templateUrl: "./in-audit-mitigation.component.html",
    styleUrls: [
      "./audit-conduct.component.scss",
    ],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ], // parent form
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class InAuditMitigationComponent implements OnInit {
    @Input() auditMitigation: any;
    @Input() isAuditAnswer: boolean;
    @Input() question: IClientAuditQuestion;
    @Input() audit: IAudit;
    @Input() preloadedRanks: IRank[];
    @Input() preloadedManagers: IUser[];
    @Input() preloadedCustomCategoryLineItems: ICustomCategoryLineItem[];
    @Input() preloadedAuditor: IUser;
    @Input() smallContainer: boolean = false;
    @Input() public activeIndex: number;

    public answer: any;
    public current: any; /*ICurrentModels;*/
    public sub: ICustomFieldValue;
    private customItems: ICustomItem[];

    public isCountermeasuresEnabled: boolean = false;
    public isAuditMitigationEnabled: boolean = false;

    public defaultRankID: number;

    public showAuditorSelection: boolean;
    public showAuditorText: boolean = false;

    public isFromEnterAudit: boolean = false;
    public defaultLayout: string;

    constructor(private currentInfo: CurrentInfoService,
                private appSettings: AppSettingsService) {

    }

    public ngOnInit() {
        this.isCountermeasuresEnabled = this.appSettings.currentAppSettings.isCountermeasuresEnabled;
        this.isAuditMitigationEnabled = this.appSettings.currentAppSettings.isInAuditMitigationEnabled;
        this.showAuditorSelection = false;
        this.isFromEnterAudit = false;
        this.defaultLayout = (this.smallContainer) ? "column" : "row";

        this.current = this.currentInfo.info;

        if (this.isAuditAnswer) {
            this.answer = this.auditMitigation;
            this.sub = { MitigateNow: false, MitigationActivity: null };
        } else {
            this.sub = this.auditMitigation;
        }

        this.getRanks();
        this.getManagers();
        this.getCustomItems();
        this.setDefaults();
    }

    public testData(): void {
        this.isCountermeasuresEnabled = true;
        this.isAuditMitigationEnabled = true;
        this.showAuditorSelection = false;

        this.current = { roleIsDataEntry: true, user: { ID: 8, OrganizationID: 1, SiteID: 1, FirstName: "Janet", LastName: "Ong" } };
        this.isFromEnterAudit = false;
        this.sub = { MitigateNow: false, MitigationActivity: null };
    }

    private getRanks(): void {
        //ranks are alreaddy preloaded, so just set the default rank
        //get first available rank as default rank
        if (this.preloadedRanks.length > 0) {
            this.defaultRankID = this.preloadedRanks[0].ID;
        }
    }

    private getManagers(): void {
        //managers list is already preloaded
        const filtered = this.preloadedManagers.filter((manager: IUser) => manager.ID == this.audit.ShiftManagerID);
        if (filtered.length == 0) {
            this.showAuditorText = true;
        }

        if (this.question.AllowAuditorToAssignAltManager) {
            this.showAuditorText = false;
        }
        console.log("showAuditorText: " + this.showAuditorText);
    }

    private getCustomItems(): void {
        //customCategoryLineItems are already preloaded
        const items: ICustomItem[] = [];
        let firstCustomCategoryID = 0;

        if (this.preloadedCustomCategoryLineItems.length > 0) {
            firstCustomCategoryID = this.preloadedCustomCategoryLineItems[0].CustomCategoryID;
        } else {
            this.isCountermeasuresEnabled = false;
        }

        _.each(this.preloadedCustomCategoryLineItems, (li: ICustomCategoryLineItem) => {
            if (li.CustomCategoryID == firstCustomCategoryID) {
                items.push(li.CustomItem);
            }
        });

        this.customItems = items;
    }

    private setDefaults(): void {
        if (this.isAuditAnswer) {
            if ((this.answer != null && (this.answer.RankID == null || this.answer.RankID == 0)) && this.defaultRankID > 0) {
                this.answer.RankID = this.defaultRankID;
            }

            if ((this.answer != null && (this.answer.AltManagerID == null || this.answer.AltManagerID == 0)) && this.audit.ShiftManagerID > 0) {
                this.answer.AltManagerID = this.audit.ShiftManagerID;
            }
        } else {
            if ((this.sub != null && (this.sub.MitigationActivity.RankID == null || this.sub.MitigationActivity.RankID == 0)) && this.defaultRankID > 0) {
                this.sub.MitigationActivity.RankID = this.defaultRankID;
            }

            if ((this.sub != null && (this.sub.MitigationActivity.ResponsiblePartyUserID == null || this.sub.MitigationActivity.ResponsiblePartyUserID == 0)) && this.audit.ShiftManagerID > 0) {
                this.sub.MitigationActivity.ResponsiblePartyUserID = this.audit.ShiftManagerID;
            }
        }
    }

    public toggleMitigateNow(question: IClientAuditQuestion): void {

        question.mitigateNow = !question.mitigateNow;
        const auditAnswer = question.AuditAnswer as IClientAuditAnswer;
        if (!question.mitigateNow) {
            auditAnswer.MitigationComment = "";
            auditAnswer.CountermeasureCustomItemID = null;
        }
        //this.$rootScope.$broadcast('question-mitigate-now', { ID: question });
    }

    public toggleMitigateCustomItemNow(cfv: ICustomFieldValue): void {

        cfv.MitigateNow = !cfv.MitigateNow;

        //need to set question.AuditAnswer.CustomFieldValues' MitigateNow since this is used for validation before submit button is enabled
        const item = this.question.AuditAnswer.CustomFieldValues.filter((fieldValue: ICustomFieldValue) => fieldValue.FieldValueCustomItemID == cfv.FieldValueCustomItemID);
        //var item = this.$filter('filter')(this.question.AuditAnswer.CustomFieldValues, { FieldValueCustomItemID: cfv.FieldValueCustomItemID });
        if (item.length > 0) {
            item[0].MitigateNow = cfv.MitigateNow;
        }

        if (!cfv.MitigateNow) {
            cfv.MitigationActivity.Comment = "";
            cfv.MitigationActivity.CountermeasureCustomItemID = null;
        }
        //this.$rootScope.$broadcast('cfv-mitigate-now', { ID: cfv.CustomFieldID });
    }
}
