﻿import { Inject, Injectable } from "@angular/core";
import { WINDOW } from "./window.service";
import { LoggerService } from "@core/services/logger.service";

@Injectable()
export class LocalService {
  private localStorage: Storage;

  constructor(@Inject(WINDOW) window: Window,private log: LoggerService) {
    this.localStorage = window.localStorage;
  }

  public containsItem(key: string): boolean {
    return (key in this.localStorage);
  }

  public getItem(key: string): string {
    return this.localStorage.getItem(key);
  }

  public get<T>(key: string): T {

    const value = this.localStorage.getItem(key);
    if (value === null || value === undefined || value === "undefined") {
      return null;
    }
    try {
      return JSON.parse(value) as T;
    } catch (e) {
        this.log.error(`Unable to parse JSON from local storage.  Key = ${key}, Value = ${value}`);
        return null;
    }
  }

  public setItem(key: string, value: {}) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  public removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }
  public removeStartsWith(key: string): void {
    const keys: string[] = [];
    for (let i = 0; i < this.localStorage.length; i++) {
      const itemKey = this.localStorage.key(i);
      if (itemKey.slice(0, key.length) === key) {
        keys.push(itemKey);
      }
    }
    for (const k of keys) {
      this.removeItem(k);
    }
  }
  public clear(): void {
    this.localStorage.clear();
  }
}
