﻿export class Config {
    public basePath: string;
    public environmentPath: any;
    public objectPath: string;

    constructor() {
        this.basePath = "Beacon";
        this.environmentPath = { Dev: "DEV", Uat: "UAT", Prod: "PROD" };
        this.objectPath = "Audits";
    }

}
