import { Component, Input, OnInit, } from "@angular/core";
import { IChangeLogSummary } from "./changelog.model";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { IChangelogDetailRow, ChangelogDetails } from "./changelog-detail";
import { ChangelogService } from "../../core/services/common/changelog.service";
import {ToolsService} from "../../core/services/tools.service";

@Component({
  selector: "changelog-history",
  templateUrl: "./changelog.component.html",
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0", visibility: "hidden" })),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})

export class ChangeLogComponent implements OnInit {
  public changelogWithDetail: ChangelogDetails;
  public displayedColumns = [ "Date", "Entity", "Title", "Action", "Field", "Previous", "New", "ChangedBy" ];
  public expandedRows = new Set<IChangeLogSummary>();

  @Input() private entityType: string;
  @Input() private entityID: string;

  constructor(
    private changelogService: ChangelogService,
    public toolService: ToolsService) {
  }

  public isDetailRow = (index: number, row: ChangelogDetails | IChangeLogSummary) => {

    const item = row.hasOwnProperty("detailRow");
    return item;
  };

  public ngOnInit(): void {
    this.getData(this.entityType, this.entityID);
  }

  private getData(entityName: string, entityID: string): void {

    if ( entityName != null && entityID != null ) {

      this.changelogWithDetail = new ChangelogDetails(this.changelogService, entityName, entityID);
    }
  }

  private expandRow(row: IChangeLogSummary) {

    if ( !row.IsSystem ) {
      row.Expanded = !row.Expanded;
      this.expandedRows.add(row);
    }
  }

  private checkDetailExpand(dataRow: IChangelogDetailRow): boolean {

    return dataRow.data.Expanded;
  }

  private checkWasExpandedHas(item: IChangeLogSummary): boolean {

    return this.expandedRows.has((item));
  }

}
