export enum AuthenticationErrorCodes {
  Unknown = 0,
  BadCredentials = 1,
  UserLocked = 2,
  UserDisabled = 3,
  UserDeleted = 4,
  SingleSignOnRequired = 5,
  TokenNotValid = 6,
}

export enum RestorePasswordErrorCodes {
  Unknown = 0,
  EmailNotSent = 1,
  TokenNotValid = 2,
  TokenExpired = 3,
}

export enum UserErrorCodes {
  Unknown = 0,
  EmailNotSent = 1,
  OrgUserLimitReached = 2,
  SiteUserLimitReached = 3,
  SiteIsNotAccessible = 4,
  ScopeIsNotAccessible = 5,
}
