import { MatFormFieldControl } from "@angular/material";
import { Subject } from "rxjs";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild} from "@angular/core";

import { LocationService } from "@core/services/location.service";
import { LocalService } from "@core/services/local.service";
import { ILocation } from "@core/models/ease-models";

import { TreeviewComponent, TreeviewConfig, TreeviewItem } from "ngx-treeview";
import { LocationTreeviewEventParser } from "./location-treeview-event-parser";
import { LocationTreeItem } from "./location-tree-item";


@Component({
    selector: "ease-location-tree",
    templateUrl: "./location-tree.component.html",
    styleUrls: ["./location-tree.component.scss"],
    providers: [{
      provide: MatFormFieldControl,
      useExisting: LocationTreeComponent,
    }],
})

export class LocationTreeComponent implements MatFormFieldControl<ILocation[]>,
  ControlValueAccessor, OnDestroy, OnInit {

    static nextId = 0;
    @HostBinding() public id = `location-tree-${LocationTreeComponent.nextId++}`;

    public source: LocationTreeItem[];

    @Input() public label: string;
    @Input() public scoped: boolean;
    @Input() public activeSite: boolean;
    @Input() public preventNormalization: boolean;
    @Input() public localStorageKey: string;
    @Input() public maxHeight: number;

    public placeholder: string;
    public errorState = false;
    public isLoading: boolean = true;

    public config: TreeviewConfig = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
    });

    @Input()
    get value() {
      return this._value;
    }
    set value(val) {
      this._value = val;
      this._empty = !this.value || this.value.length === 0;
      this.onTouched();
    }

    @Input() get required() {
      return this._required;
    }
    set required(req) {
      this._required = coerceBooleanProperty(req);
      this.stateChanges.next();
    }

    @Input()
    get disabled() {
      return this._disabled;
    }
    set disabled(dis) {
      this._disabled = coerceBooleanProperty(dis);
      this.stateChanges.next();
    }

    public get empty() {
      return this._empty;
    }

    public focused = false;
    public stateChanges = new Subject<void>();

    shouldLabelFloat = true;

    @HostBinding("attr.aria-describedby") describedBy = "";

    private _value: ILocation[];
    private _empty = true;
    private _disabled = false;
    private _required = false;
    private initiated = false;

    @ViewChild(TreeviewComponent) private tree: TreeviewComponent;

    constructor(@Optional() @Self() public ngControl: NgControl,
                private locationService: LocationService,
                private localStorage: LocalService,
                private eventParser: LocationTreeviewEventParser) {
      if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;
      }
    }

    public onChange: any = () => {};
    public onTouched: any = () => {};

    public ngOnInit() {

      this.locationService.getLocationTree(this.scoped, this.activeSite).subscribe(
        (locations: ILocation[]) => {
          this.source = locations.map(this.locationService.createTreeItem);
          this.isLoading = false;
      });

      if (this.localStorageKey) {
        this.value = this.localStorage.get<ILocation[]>(this.localStorageKey);
      }

      this.config.decoupleChildFromParent = this.preventNormalization;
      this.config.maxHeight = this.maxHeight;
    }

    public onSelectedChange(): void {
        const selectedLocations = this.eventParser.getSelectedChange(this.tree).map(this.toLocation);

        if (!selectedLocations) {
            return;
        }

        if (!this.initiated) {
          this.initiated = true;

          if (!this.empty) {
            this.source.forEach(x => this.preSelectRecursively(x, false));
          }

          return;
        }

        this.writeValue(selectedLocations);
        this.onChange(selectedLocations);

        this.stateChanges.next();

        if (this.localStorageKey) {
          this.localStorage.setItem(this.localStorageKey, this.value);
        }
    }

    registerOnChange(fn) {
      this.onChange = fn;
    }

    registerOnTouched(fn) {
      this.onTouched = fn;
    }

    public writeValue(value: ILocation[]) {
        if (value) {
            this.value = value;
        }
    }

    public setDescribedByIds(ids: string[]) {
      this.describedBy = ids.join(" ");
    }

    public onContainerClick(event: MouseEvent) {}

    public ngOnDestroy() {
      this.stateChanges.complete();
    }

    private preSelectRecursively = (location: TreeviewItem, isParentChecked: boolean): void => {
        if (isParentChecked || this.value.some(x => x.ID === location.value)) {
          location.checked = true;
        }

        if (location.children && location.children.length > 0) {
          location.children.forEach(x => this.preSelectRecursively(x, location.checked && !this.preventNormalization));
        }
        location.correctChecked();
        location.collapsed = !location.indeterminate;
    }

    private toLocation(treeItem: LocationTreeItem): ILocation {
      return {
        ID: treeItem.id,
        Name: treeItem.name,
        LocationTypeID: treeItem.type.id,
        LocationTypeName: treeItem.type.name,
      } as ILocation;
    }
}
