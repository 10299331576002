// Angular node modules
import {
  Component, OnInit, OnDestroy, Output, EventEmitter,
  ViewChild, NgZone, Inject, Injectable,
} from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material";

// Services
import { NotifyService, LoggerService, StorageService, ToolsService } from "@core/services";
import { ArtifactService } from "@core/services/common/artifact.service";
import { AuditDetailService } from "@core/services/common/audit-detail.service";

// Models
import { IAuditDetail, IDocumentArtifact } from "../../core/models/ease-models";

// Reactive components
import { Observable,  Subject,  ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { DialogConfigSm } from "@shared/dialogs";
import { ImageDialogComponent } from "@shared/dialogs/image-dialog/image-dialog.component";
import { letProto } from "rxjs-compat/operator/let";

@Component({
  styleUrls: ["../conduct-audit/audit-conduct.component.scss"],
  templateUrl: "./audit-references.component.html",
})

export class AuditReferencesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  public auditID: number = 0;
  public groupQuestions: boolean = false;
  public onePageAudit: boolean = false;
  public questionGroups: any[];
  public auditDetail: IAuditDetail;
  public documentArtifacts: IDocumentArtifact[];
  private isFromAdHocAudits: boolean = false;

  constructor(private artifactService: ArtifactService,
              private auditDetailService: AuditDetailService,
              private notify: NotifyService,
              private route: ActivatedRoute,
              private log: LoggerService,
              private zone: NgZone,
              private storage: StorageService,
              private dialog: MatDialog,
              private tools: ToolsService) {
  }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.params.id, 10);

    const readFromServer = this.tools.isOnline() ? true : false;
    this.auditDetailService.getAuditByID(id, "Audits", readFromServer).subscribe((content: any) => {
      if (content) {
        this.auditDetail = content.auditDetail;
        this.documentArtifacts = this.auditDetail.DocumentArtifacts;
        this.documentArtifacts.forEach(item => {
          if (item.ArtifactUrl !== null) {
            item.OriginalArtifactUrl = this.tools.isApp() ? this.setImageUrl(item, true) : item.ArtifactUrl;
            item.ArtifactUrl = (this.tools.isApp() || this.storage.isDoc(item.FileFormatID)) ? this.setImageUrl(item) : item.ArtifactUrl;
          }
        });
      } else {
        this.log.debug("auditID not found: " + id);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public getArtifactImage(documentArtifact: IDocumentArtifact): void {
    if (documentArtifact) {
      if (this.storage.isDoc(documentArtifact.FileFormatID)) {
        if (this.tools.isApp()) {
          (<any> cordova).plugins.fileOpener2.open(
            documentArtifact.OriginalArtifactUrl,
            "application/pdf",
            {
              error: (e) => {
                this.log.debug("error: " + e.status + " message: " + e.message);
              },
              succes: () => {
                this.log.debug("file opened");
              },
            },
          );
        } else {
          const link = document.createElement("a");
          link.download = "a";
          link.href = documentArtifact.OriginalArtifactUrl;
          document.body.appendChild(link);
          link.click();
        }
      } else {
        const config = new DialogConfigSm<string>();
        config.panelClass = "img-dialog";
        config.data = documentArtifact.ArtifactUrl ;

        this.dialog.open(ImageDialogComponent, config);
      }
    }
  }

  public openUrl(url: string): void {
    url = url.match(/^https?:/) ? url : (this.tools.isApp ? "http://" : "//") + url;
    window.open(url, "_blank");
  }

  public isValidImage(documentArtifact: IDocumentArtifact): boolean {
    let isImage = true;
    if (this.storage.isDoc(documentArtifact.FileFormatID)) {
      isImage = false;
    }
    return isImage;
  }

  private setImageUrl(img: any, isOriginalUrl: boolean = false): string {
    let url = this.artifactService.getImageUrl(img);

    if (this.tools.isApp()) {
      if (isOriginalUrl && this.storage.isDoc(img.FileFormatID)) {
        const artifactKey = this.storage.buildCurrentStorageKey("AuditReferencesArtifact", img.ID, img.Filename, img.FileFormatID);
        url = cordova.file.dataDirectory + this.storage.keyAsString(artifactKey, null, img.FileFormatID);
      } else if (!this.storage.isDoc(img.FileFormatID)) {
        const artifactKey = this.storage.buildCurrentStorageKey("AuditReferencesArtifact", img.ID);
        url = cordova.file.dataDirectory + this.storage.keyAsString(artifactKey, null, img.FileFormatID);
      }
    }
    return url;
  }
}
