
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ILocation } from "../../core/models/ease-models";
import { LocationTreeModalInput } from "@shared/location-tree/location-tree.interfaces";

@Component({
    templateUrl: "./location-tree-modal.component.html",
})
export class LocationTreeModalComponent {

    public selectedLocations: ILocation[];
    public activeSite: boolean = false;
    public scoped: boolean = false;
    public preventNormalization: boolean = false;
    public localStorageKey: string = "";

    constructor(
        public dialogRef: MatDialogRef<LocationTreeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LocationTreeModalInput) {

      this.activeSite = data.activeSite;
      this.preventNormalization = data.preventNormalization;
      this.scoped = data.scoped;
      this.localStorageKey = data.localStorageKey;
      this.selectedLocations = data.selectedLocations;
    }

    public saveHandler(): void {
        this.dialogRef.close(this.selectedLocations);
    }

    public cancelHandler(): void {
        this.dialogRef.close(null);
    }
}
