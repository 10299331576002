import { Component, Input, OnInit } from "@angular/core";

import { BreadcrumbComponent } from "../breadcrumb.component";

@Component({
  selector: "ease-breadcrumb-document-type",
  template: `
    <ease-breadcrumb-base-item [loading]="false">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        {{ documentType | i18n }}
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbDocumentTypeComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  documentType: string;

  constructor(
  ) {}

  ngOnInit() {
    const type = this.data.params.type;
    if (type === "capa") {
      this.documentType = "_BreadcrumbCAPA_";
    } else {
      this.documentType = "_Breadcrumb" + type.charAt(0).toUpperCase() + type.substring(1) + "_";
    }
  }
}
