import { MatDialogConfig } from "@angular/material";

export * from "./dialog.component";
export * from "./confirm.component";


export * from "./input-dialog/input-dialog.component";
export * from "./image-dialog/image-dialog.component";
export * from "./import-dialog/import-dialog.component";

class DialogBaseConfig<T> extends MatDialogConfig {
  disableClose = false; // Paul wants to set this to true, but certain dialogs don't have close buttons
  hasBackdrop = true;
  maxHeight = "90vh";
  data: T;
}

export class DialogConfigSm<T> extends DialogBaseConfig<T> {
  panelClass = "ease-dialog-sm-panel";
  width = "500px";
}

export class DialogConfigMd<T> extends DialogBaseConfig<T> {
  panelClass = "ease-dialog-md-panel";
  width = "700px";
}

export class DialogConfigLg<T> extends DialogBaseConfig<T> {
  panelClass = "ease-dialog-lg-panel";
  width = "90%";
}
