import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";

import { UiNotificationService } from "../../core/services/common/ui-notification.service";
import { ApiService } from "../../core/services/api.service";
import { CurrentInfoService } from "../../core/services/current.service";
import { ToolsService } from "../../core/services/tools.service";
import {ChangePasswordModalContainerComponent} from "./change-password-modal-container.component";

@Component({
    selector: "ease-change-password-account",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"],
})

export class ChangePasswordAccountComponent {

    public currentPassword: string;
    public newPassword: string;

    public invalid: boolean;
    public hasLength: boolean;
    public hasShortness: boolean;
    public hasDigit: boolean;
    public hasUpper: boolean;
    public hasLower: boolean;
    public hasSpecial: boolean;
    public hasValidPassword: boolean;

    constructor(
        private tools: ToolsService,
        private api: ApiService,
        private current: CurrentInfoService,
        private uiNotify: UiNotificationService,
        private dialogRef: MatDialogRef<ChangePasswordModalContainerComponent>,
    ) {}

    public onPasswordChange(value: string) {
        if (value == null) {
            this.hasValidPassword = false;
            this.hasLength = false;
            this.hasShortness = false;
            this.hasDigit = false;
            this.hasUpper = false;
            this.hasLower = false;
            this.hasSpecial = false;
        } else {
            this.hasLength = value.length >= 8;
            this.hasShortness = value.length < 24;
            this.hasDigit = value.search(/(?=.*\d)/) > -1;
            this.hasUpper = value.search(/(?=.*[A-Z])/) > -1;
            this.hasLower = value.search(/(?=.*[a-z])/) > -1;
            this.hasSpecial = value.search(/(?=.*[!@#$%^&\*\-\_\{\}\[\]])/) > -1;    // !@#$%^&*-_{}[]
        }
        this.hasValidPassword = this.hasLength && this.hasShortness && this.hasDigit && this.hasUpper && this.hasLower && this.hasSpecial;
    }

    public doPasswordChange(curPass: string, newPass: string) {
        if (this.tools.isOnline && this.hasValidPassword) {
            const creds = {Login: this.current.info.user.Login, Password: curPass, NewPassword: newPass};
            this.api.UpdateX("usercredential", this.current.info.user.ID, creds, {updatePassword: true}, (success: boolean) => {
                if (success) {
                    this.dialogRef.close();
                    this.uiNotify.open("_PasswordChanged_");
                } else {
                    this.invalid = true;
                }
            });
        }
    }
}
