import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { ICompany, ICompanySite } from "../../core/models/ease-models";
import { HttpClient } from "@angular/common/http";

// Services
import { ApiService, IdentityService } from "app/core/services";

@Injectable()
export class CompanyService {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private identity: IdentityService) { }

  public getCompanies(params: any): Observable<ICompany[]> {
    const url = `/company?details=${params.detail}`;
    return this.http.get<ICompany[]>(url);
  }

  public getCompanyDetailByID(id: number) {
    return this.http.get<ICompany>(`/company/${id}`);
  }

  public getCompanyByName(name: string) {
    return this.http.get<ICompany[]>(`/company?name=${name}`);
  }

  public delete(id): Observable<void> {
    return this.http.delete<void>(`/company/${id}`);
  }

  public add(company: ICompany) {
    const url = "/company/";
    return this.http.post<number>(url, company);
  }

  public update(id: number, company: ICompany, partnerLinkRequested: boolean = false) {
   const url = `/company/${id}?partnerLinkRequested${partnerLinkRequested}`;
   return this.http.put<any>(url, company);
  }

  public getCompanySitesOfCompany(id: number): Observable<ICompanySite[]> {
    return this.http.get<ICompanySite[]>(`/companysite?companyID=${id}`);
  }

  public getCompanySites(details: boolean, companyID: number, partnerLinkRequested: true): Observable<ICompanySite[]> {
    const url = `/companysite?companyID=${companyID}&partnerLinkRequested=${partnerLinkRequested}`;
    return this.http.get<ICompanySite[]>(url);
  }
}
