import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import { BehaviorSubject,  Observable,  ReplaySubject, Subject } from "rxjs";

interface BroadcastEvent {
    key: any;
    data?: any;
}

export class NotifyBoolean {
    private _value: boolean;
    private subject: ReplaySubject<boolean>;

    constructor() {
        this._value = false;
        this.subject = new ReplaySubject<boolean>();
    }
    get value() {
        return this._value;
    }
    set value(val) {
        this._value = val;
        try {
            this.subject.next(val);
        } catch (e) {
            console.debug("notify.service: broadcast fail:" + e);
        }
    }

    public asObservable(): Observable<boolean> {
        return this.subject.asObservable();
    }

    public clear(): void {
      this._value = false;
      this.subject = new ReplaySubject<boolean>();
    }
}

// usage example:
//          this.log.debug("ready is currently set to " + this.notify.ready.value);
//          this.notify.ready.asObservable().subscribe((value:boolean) => { this.log.debug("ready just changed to " + value); });
export class NotifyService {
    private eventBus: Subject<BroadcastEvent>;

    public ready = new NotifyBoolean();
    public online = new NotifyBoolean();
    public paused = new NotifyBoolean();
    /**
     * @deprecated Use getLoggedIn deriver from auth/reducers/index.ts instead.
     */
    public loggedIn = new NotifyBoolean();
    public filesystemReady = new NotifyBoolean();
    /**
     * @deprecated You dont need this because of getAppIsReady deriver show view only if current info is exist.
     */
    public currentInfoReady = new NotifyBoolean();
    public currentAppSettingsReady = new NotifyBoolean();
    /**
     * @deprecated Use getTranslates deriver from auth/reducers/index.ts instead.
     */
    public localizationReady = new NotifyBoolean();

    constructor() {
        this.eventBus = new Subject<BroadcastEvent>();
    }

    public broadcast(key: any, data?: any) {
        try {
            this.eventBus.next({ key, data });
        } catch (e) {
            console.debug("notify.service: broadcast fail: " + e);
        }
    }

    public on<T>(key: any): Observable<T> {
        return this.eventBus.asObservable()
            .filter(event => event.key === key)
            .map(event => event.data as T);
    }
}
