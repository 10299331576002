import { Observable,  BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "@core/services/logger.service";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import { IRole } from "../models/ease-models";
import { IdentityService } from "./identity.service";
import { LocalService } from "./local.service";
import { NotifyService } from "./notify.service";
import { ToolsService } from "./tools.service";
import { AnalyticsService } from "./analytics.service";
import { LocalizeService } from "@core/services/localize.service";

import * as moment from "moment";

// State
import { Store } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import { Logout } from "app/auth/actions/auth";

export class CurrentInfo {
    public org: any;
    public user: any;
    public activeSite: any;
    public activeSiteID: number;
    public featureFlags?: any;
    public sites: any[];
    public scope: any;
    public roles: any[];
    public roleIsAuditor: boolean;
    public roleIsAuditAuthor: boolean;
    public roleIsAdmin: boolean;
    public roleIsManager: boolean;
    public roleIsSysAdmin: boolean;
    public roleIsOrgAdmin: boolean;
    public roleIsSiteAdmin: boolean;
    public roleIsDataEntry: boolean;
    public isDataEntryOnly: boolean;
    public isFirstLogin: boolean;
    public isTrialProduct: boolean;
    public requestFeedback: boolean;
    public showReleaseNotes: boolean;
    public isClientTimezoneNotInSystem: boolean;
    public isJobTypeEnabled: boolean;
    public isCustomerTypeEnabled: boolean;
    public isSupplierEnabled: boolean;
    public isEaseEmployee: boolean;
    public ImpersonatorLogin: string;
    public isAdHocEnabled: boolean;
    public versionNumber: string;

}

@Injectable()
export class CurrentInfoService {
    public info: CurrentInfo;

    private ready = new BehaviorSubject<boolean>(false);
    private loading: boolean = false;

    private emptyInfo: CurrentInfo = {
        org: null,
        user: null,
        activeSite: null,
        activeSiteID: null,
        featureFlags: null,
        sites: [],
        scope: null,
        roles: [],
        roleIsSysAdmin: false,
        roleIsOrgAdmin: false,
        roleIsSiteAdmin: false,
        roleIsAdmin: false,
        roleIsAuditAuthor: false,
        roleIsManager: false,
        isDataEntryOnly: false,
        isFirstLogin: false,
        isTrialProduct: false,
        requestFeedback: false,
        showReleaseNotes: false,
        roleIsDataEntry: false,
        isClientTimezoneNotInSystem: false,
        roleIsAuditor: false,
        isJobTypeEnabled: false,
        isCustomerTypeEnabled: false,
        isSupplierEnabled: false,
        isEaseEmployee: false,
        ImpersonatorLogin: "",
        isAdHocEnabled: false,
        versionNumber: "",
    };

    private versionString: string = null;

    constructor(
        private store: Store<fromAuth.State>,
        private log: LoggerService,
        private http: HttpClient,
        private local: LocalService,
        private tools: ToolsService,
        private notify: NotifyService,
        private localize: LocalizeService,
        private identity: IdentityService,
        private analytics: AnalyticsService) {
        this.info = this.emptyInfo;

        this.analytics.init(this.identity.endpoints.Analytics);
    }

    public refreshCurrentModels() {
      return Observable.create(observer => {
        if (this.tools.isOnline()) {
          if (!this.loading) {
            this.loading = true;
            const params: any = this.buildTimezoneParams();
            this.log.debug("current.service: downloading details about " + this.identity.loginid + " from " + this.identity.endpoints.Name);
            this.http.get("/currentinfo", { params }).subscribe(
              (data: any) => {
                this.handleRefresh(data);
                observer.next(this.info);
                observer.complete();
              },
              (status: any) => {
                this.handleRefreshError(status);
                observer.error(status);
              });
          }
        } else {
          if (this.local.containsItem("User")) {
            this.log.debug("current.service: found cached current info in local.service");
            this.info = JSON.parse(this.local.getItem("User"));
            this.log.debug("current.service: retrieved current info from local.service");
            if (!this.ready.getValue()) {
              this.log.debug('current.service: broadcasts "ready"');
            } else {
              this.log.debug('current.service: broadcasts "refreshed"');
              // this.notify.broadcast("currentInfoRefreshed");
            }
            observer.next(this.info);
            observer.complete();
          } else {
            this.log.debug("current.service: did not find cached current info in local.service");
            this.log.debug("current.service: app is offline with no stored current info");
            observer.error("current.service: did not find cached current info in local.servic");
          }
        }
      });
    }

    public IsUsingSiteTimeZone(): boolean {
      return this.isUsingSiteTimeZone();
    }
    public SiteDateInUTC(date: Date): Date {
      return this.siteDateInUTC(date);
    }

    public SiteDateToday(isStartOf: boolean, dateToConvert: Date) {
      return this.siteDatetoday(isStartOf, dateToConvert);
    }

    public OffsetByDate(date: Date) {
      return this.offsetByDate(date);
    }

    buildTimezoneParams(): any {
        const userTimeZone = new Date();
        const timezone = userTimeZone.toString();
        const timezoneID = timezone.substring(timezone.lastIndexOf("(") + 1).replace(")", "").trim();
        const timezoneOffset = userTimeZone.getTimezoneOffset();
        const ignoreTimezoneDetail = false;
        return { timezoneID, timezoneOffset, ignoreTimezoneDetail };
    }

    private isSmartPhone(): boolean {
        return window.innerWidth < 768; // For now until we figure something out something better
    }

    handleRefresh(data: any) {
        this.loading = false;

        this.log.debug("current.service: retrieved current info from server");

        data.roleIsSysAdmin = data.org.ID === 1 && data.roles.some((x: IRole) => x.Name === "SysAdmin");
        data.roleIsOrgAdmin = data.roles.some((x: IRole) => x.Name === "OrgAdmin");
        data.roleIsSiteAdmin = data.roles.some((x: IRole) => x.Name === "SiteAdmin");
        data.roleIsDataEntry = data.roles.some((x: IRole) => x.Name === "DataEntry");
        data.roleIsManager = data.roles.some((x: IRole) => x.Name === "Manager");
        data.roleIsAuditAuthor =  data.roles.some((x: IRole) => x.Name === "AuditAuthor");
        data.roleIsAuditor =  data.roles.some((x: IRole) => x.Name === "Auditor");

        data.roleIsAdmin = data.roleIsSysAdmin ||  data.roleIsOrgAdmin || data.roleIsSiteAdmin;
        data.isDataEntryOnly = data.roles.length === 1 && data.roleIsDataEntry;

        const userChanged: boolean = (!this.info.user && data.user) || (this.info.user && data.user && this.info.user.ID !== data.user.ID);
        const previousActiveSiteId = this.local.get<number>("activeSiteID");

        this.info = data;
        this.info.activeSiteID = data.activeSite.ID;
        this.info.isEaseEmployee = data.user.Login.indexOf("easeinc.com") > 0;
        this.local.setItem("activeSiteID", this.info.activeSiteID);

        // Serverside
        if (this.info.user.IsLocked) { this.store.dispatch(new Logout("")); }

        // adjust analytics settings
        const analyticsProps: any = {};
        if (this.info && this.info.activeSite) {
            analyticsProps.currentsite = this.info.activeSite.Name;
            analyticsProps.currentsiteid = this.info.activeSite.ID;
        }
        if (this.info && this.info.user && this.info.user.DefaultSite) {
            analyticsProps.defaultsite = this.info.user.DefaultSite.Name;
            analyticsProps.defaultsiteid = this.info.user.DefaultSite.ID;
        }
        analyticsProps.SysAdmin = data.roleIsSysAdmin ? 1 : 0;
        analyticsProps.OrgAdmin = data.roleIsOrgAdmin ? 1 : 0;
        analyticsProps.SiteAdmin = data.roleIsSiteAdmin ? 1 : 0;
        analyticsProps.Manager = data.roleIsManager ? 1 : 0;
        analyticsProps.AuditAuthor = data.roleIsAuditAuthor ? 1 : 0;
        analyticsProps.Auditor = data.roleIsAuditor ? 1 : 0;
        analyticsProps.DataEntry = data.roleIsDataEntry ? 1 : 0;
        if (userChanged) {
            analyticsProps.organizationid = this.info.org.ID;
            analyticsProps.organization = this.info.org.Name;
            this.analytics.identify(this.info.user.Login);
        }
        this.analytics.addUserProperties(analyticsProps);

        const eventProps: any = {};
        eventProps.Impersonator = this.info.ImpersonatorLogin;
        eventProps.isMobileApp = this.tools.isApp();

        if (this.tools.isApp()) {
          (<any> window.cordova).getAppVersion.getVersionNumber().then(versionNumber => {
            eventProps.appVer = versionNumber;
            this.analytics.addEventProperties(eventProps);
          });
        } else {
          eventProps.appVer = this.info.versionNumber;
          this.analytics.addEventProperties(eventProps);
        }

        // TJP_FIXME: heap analytics integration here...
    }

    handleRefreshError(status: any) {
        this.loading = false;
        if (status === 0) {
            alert("Unable to connect to application services. (" + this.identity.endpoints.Service + ")");

            if (this.local.containsItem("User")) {
                this.log.debug("current.service: found cached current info in local.service");
                this.info = JSON.parse(this.local.getItem("User"));
                this.log.debug("current.service: retrieved current info from local.service");
            } else {
                this.log.debug("current.service: did not find cached current info in local.service");
                this.log.debug("current.service: app is online with no stored current info, logging out");
            }

        }
    }

    private siteDateInUTC(dt: Date): Date {
        const start = moment(dt).startOf("day").toDate();
        const millisecondOffset = (this.offsetByDate(dt) * -1) * 60 * 1000;	// invert site offset from TimeZoneInfo.GetSystemTimeZones()
        const siteDate = new Date(start.getTime() + millisecondOffset);
        return new Date(Date.UTC(siteDate.getFullYear(), siteDate.getMonth(), siteDate.getDate(), siteDate.getHours(), siteDate.getMinutes(), siteDate.getSeconds()));
    }

    private siteDatetoday(isStartOf: any, dateToConvert: any): Date {
        let siteDateToday;
        const dt = dateToConvert === null ? new Date() : new Date(dateToConvert);
        const userOffset = dt.getTimezoneOffset() * -1;
        const siteOffset = this.offsetByDate(dt);

        // convert user's date today to site's time zone
        if (userOffset !== siteOffset) {
            siteDateToday = moment(moment(dt).utcOffset((siteOffset)).format("YYYY-MM-DD HH:mm:ss")).toDate();
            if (isStartOf) {
                siteDateToday = moment(siteDateToday).startOf("day").toDate();
            }
        } else {
            siteDateToday = dt;
            if (isStartOf) {
                siteDateToday = new Date(siteDateToday.getFullYear(), siteDateToday.getMonth(), siteDateToday.getDate());
            }
        }
        return siteDateToday;
    }

    private offsetByDate(date: Date): number {
        let siteOffset = this.info.activeSite.TimeZoneOffset;

        if (this.info.activeSite.HasDST) {
            const startOffset = new Date(this.info.activeSite.DSTStart);
            const endOffSet = new Date(this.info.activeSite.DSTEnd);

            siteOffset = new Date(date) >= startOffset && new Date(date) <= endOffSet ?
              this.info.activeSite.DSTStartOffset : this.info.activeSite.DSTEndOffset;
        }

        return siteOffset;
    }

    private isUsingSiteTimeZone(): boolean {
      const userOffset = new Date().getTimezoneOffset() * -1; // user's offset today (need to invert the sign to be able to compare with offset retrieved from TimeZoneInfo.GetSystemTimeZones())
      const siteOffset = this.offsetByDate(new Date()); // site's offset today
      return siteOffset === userOffset;
    }
}
