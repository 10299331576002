// Angular node modules
import { Component, OnInit, OnDestroy, Output, EventEmitter,
         ViewChild, NgZone, Inject, Injectable, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { NgForm } from "@angular/forms";

// Services
import { NotifyService } from "@core/services/notify.service";
import { ArtifactService } from "@core/services/common/artifact.service";

// Components
import { AuditDetailComponent } from "@app/shared/results/audit-detail/audit-detail.component";
import { AuditResultComponent } from "../../shared/results/audit-result/audit-result.component";

// Models
import { IOrgArtifact } from "../../core/models/ease-models";

// Reactive components
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    styleUrls: ["./audit-print-detail.component.scss", "../../conduct/conduct-audit/audit-conduct.component.scss",
        "../../shared/navigation/admin-control-bar.component.scss"],
    templateUrl: "./audit-print-detail.component.html",
})

export class AuditPrintDetailComponent implements OnInit, OnDestroy {
    destroy$ = new Subject();
    public logoData: string;

    constructor(private artifactService: ArtifactService,
                private notify: NotifyService) {
        this.notify.broadcast("hide-top-nav", true);
        this.notify.broadcast("print-window-on", true);
    }

    ngOnInit() {
        this.getLogoData();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    private getLogoData() {
        this.artifactService.getOrganizationArtifact().pipe(takeUntil(this.destroy$))
            .subscribe((orgArtifact: IOrgArtifact) => {
            this.logoData = orgArtifact ? orgArtifact.Url : null;
        });
    }

}
