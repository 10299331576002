import { Injectable } from "@angular/core";
import { IArtifact } from "@core/models/ease-models";
import { DialogConfigSm } from "@shared/dialogs";
import { MatDialog } from "@angular/material";

import { ImageDialogComponent } from "@shared/dialogs/image-dialog/image-dialog.component";

import { StorageService } from "@core/services/storage.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ImageDialogService {

  constructor(private storage: StorageService,
              private dialog: MatDialog,
              private http: HttpClient) {

  }

  public getArtifactImage(url: string, fileFormatID: number): void {
    if (fileFormatID !== 0 && this.storage.isDoc(fileFormatID)) {
      // download file
      const link = document.createElement("a");
      link.download = "a";
      link.href = url;
      document.body.appendChild(link);
      link.click();
    } else {
      const config = new DialogConfigSm();
      config.panelClass = "img-dialog";
      config.data = url;
      this.dialog.open(ImageDialogComponent, config);
    }
  }
}
