import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService } from "app/core/services/logger.service";
import "rxjs/add/operator/takeUntil";
import { Subject } from "rxjs";
import { AuthenticationRequest, AuthenticationResponse, IdentityService } from "app/core/services/identity.service";
import {LocalService} from "app/core/services/local.service";
import { LocalizeService } from "app/core/services/localize.service";
import { NotifyService } from "app/core/services/notify.service";
import { ToolsService } from "app/core/services/tools.service";
import {AuthResponseStatus} from "app/core/services/index";

@Component({
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
    public model: any = {};
    public loading = true;
    public returnUrl: string;
    public message = "";
    public isMobileApp: boolean = true;
    public rememberMe: boolean = false;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private local: LocalService,
        private log: LoggerService,
        public notify: NotifyService,
        private identityService: IdentityService,
        private tools: ToolsService,
        private localize: LocalizeService) {
        this.isMobileApp = this.tools.isApp();
    }

    public ngOnInit() {
      this.notify.ready.asObservable().takeUntil(this.ngUnsubscribe).subscribe((state) => {this.onDeviceReady(state); });
      this.identityService.logout();
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/";

      let ssotp = null; // TJP_HACK - work around angular urlparam parsing and issues mixing url params and matrix params (revisit)
      if (this.returnUrl.indexOf(";ssotp=") > 0) {
        const parts = this.returnUrl.split(";ssotp=");
        if (parts[0].length > 0) {
          this.returnUrl = parts[0];
        }
        if (parts[1].length > 0) {
          ssotp = parts[1];
        }
      }

      this.log.info("login component, env=" + this.identityService.endpoints.Name + ", returnUrl " + this.returnUrl);
      this.restoreRememberMeOptions();
      if (!this.rememberMe) {
        this.model.username = "";
        this.model.password = "";
      }

      ssotp = ssotp != null ? ssotp : this.route.snapshot.params.ssotp;
      if (ssotp) {
        this.loginSSO(ssotp);
      } else {
        this.notify.online.asObservable().takeUntil(this.ngUnsubscribe).subscribe((online: boolean) => {
          if (online && !this.identityService.isLoggedIn()) {
            this.identityService.isSSOActiveHost(window.location.hostname).takeUntil(this.ngUnsubscribe)
              .subscribe((active: boolean) => {
                if (active) {
                  const loginSSOUrl = "/account/LoginSSO?returnUrl=" + encodeURIComponent(window.location.href);
                  this.log.info("login: nav to " + loginSSOUrl)
                  window.location.href = loginSSOUrl;
                } else {
                  this.loading = false;
                }
              }, () => {
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        });
      }
    }

    public onDeviceReady(ready: boolean) {
        if (ready) {
            this.notify.online.asObservable().takeUntil(this.ngUnsubscribe).subscribe((state: boolean) => this.onOnline(state));
        }
    }

    public onOnline(online: boolean) {
        if (online) {
            this.message = "";
        } else {
            this.log.debug("login: app is offline");
            this.message = "Your device is offline, please connect to network and try again";
        }
    }

    public login() {
        if (!this.tools.isOnline()) {
            return;
        }

        this.loading = true;
        this.log.debug("login: authenticating as " + this.model.username);
        const request: AuthenticationRequest = { Login: this.model.username, Password: this.model.password };
        this.identityService.login("/authentication",
            request,
            (response: AuthenticationResponse) => this.onLoginSuccess(response),
            (status: number, response: AuthenticationResponse) => this.onLoginFailure(status, response),
        );

        this.saveRememberMeOptions();
    }

    public loginSSO(ssotp: string) {
        if (!this.tools.isOnline()) {
            return;
        }

        let decode: string = atob(ssotp);
        decode = decodeURIComponent(decode);

        const obj: any = JSON.parse(decode);
        this.model.username = obj.login;
        this.log.info("login.component: attempting otp authenticate as " + this.model.username + " with otp " + obj.otp);
        const request: AuthenticationRequest = { Login: this.model.username, Password: obj.otp };
        this.identityService.login("/authentication/sso",
            request,
            (response: AuthenticationResponse) => this.onLoginSuccess(response),
            (status: number, response: AuthenticationResponse) => this.onLoginFailure(status, response),
        );
    }

    public onLoginSuccess(response: AuthenticationResponse) {
        if (this.returnUrl) {
            this.log.info("login: login success, navigate to " + this.returnUrl);
            this.router.navigateByUrl(this.returnUrl);
        } else {
          this.log.info("login: login success");
        }
    }

    public onLoginFailure(status: number, response: AuthenticationResponse) {
        const respObj = (typeof (response) !== "object") ? JSON.parse(response) : response;

        if (respObj.Status != null) {
            if (respObj.Status === AuthResponseStatus.SSOMustRedirect && respObj.Subdomain) {
              this.log.debug("login ")
              const here = new URL(window.location.href);
              const there = here.protocol + "//" + respObj.Subdomain + here.pathname + here.hash;
              this.log.info("login: login fail, sso must redirect to " + there);
              window.location.href = there;
            } else {
              const msg = this.identityService.mapStatusToi18n(respObj.Status);
              this.message = this.localize.getLocalizedString(msg);
              this.log.info("login: login fail, msg: " + this.message);
            }
        } else {
            this.message = "Network Error [" + status + "]";
            this.log.info("login: login fail, status: " + this.message);
        }
        this.loading = false;
    }

    public saveRememberMeOptions(): void {

        this.local.removeItem("rememberMe");
        this.local.removeItem("rememberMeLogin");

        this.local.setItem("rememberMe", this.rememberMe ? "1" : "0");

        if (this.rememberMe) {
            this.local.setItem("rememberMeLogin", this.model.username);
        }
    }

    public restoreRememberMeOptions(): void {

        if (this.local.getItem("rememberMe") != null) {
            this.rememberMe = this.local.getItem("rememberMe") === "1";
            if (this.rememberMe && (this.login == null || this.login.length === 0)) {
                this.model.username = this.local.getItem("rememberMeLogin");
            }
        } else {
            this.rememberMe = true;
        }
    }

    public ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
