import { Component, Input, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { NgForm, ControlContainer } from "@angular/forms";
import { IClientAuditQuestion, QuestionResponseTypes } from "../../core/models/ease-models";
import { AuditConductComponent } from "./audit-conduct.component";

@Component({
  selector: "ease-multiple-choice-layout",
  templateUrl: "./multiple-choice-layout.component.html",
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ], // parent form
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MultipleChoiceLayoutComponent implements OnInit {
  @Input() public question: IClientAuditQuestion;
  public isMultiSelectOrMultipleChoice: boolean;

  constructor(private auditConduct: AuditConductComponent) {

  }

  public ngOnInit() {
    this.isMultiSelectOrMultipleChoice = this.question.QuestionType === QuestionResponseTypes.MultipleChoice || this.question.QuestionType === QuestionResponseTypes.MultiSelect;
  }
}
