import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { NgForm, ControlContainer } from "@angular/forms";
import { AuditConductComponent } from "./audit-conduct.component";

@Component({
  selector: "ease-single-group-per-page-layout",
  templateUrl: "./single-group-per-page-layout.component.html",
  styleUrls: [
      "./audit-conduct.component.scss",
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ], // parent form
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SingleGroupPerPageLayoutComponent {
  @Input() public activeIndex: number;
  @Input() public attachmentCounter: number;

  constructor(public auditConduct: AuditConductComponent) {

  }
}
