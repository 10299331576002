import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import { IChangeLog, IChangeLogSummary } from "../../../shared/changelog-history/changelog.model";
import * as _ from "lodash";
import { mapChangeNames, fieldNames, entityNames } from "../../../shared/changelog-history/constants";
import { ApiService } from "../api.service";

@Injectable()
export class ChangelogService {

    constructor(private api: ApiService) {}

    public getChangelog(entityName: string, entityID: string): Observable<IChangeLogSummary[]> {

        const result = new ReplaySubject<any>(1);
        const request = { entityName, entityID };
        let summaries = [];

        this.api.Query("ChangeLog", request, (data: any) => {

            summaries = this.buildSummary(data);
            result.next(summaries);
            result.complete();
        });

        return result.asObservable();
    }

    private buildSummary(logs: IChangeLog[]): IChangeLogSummary[] {
        const resultArr: IChangeLogSummary[] = [];
        const summaries = {};

        _.each(logs, (log: IChangeLog) => {
            let summary = summaries[log.ChangeEvent];
            if (summary === undefined) {
                summary = {
                    ChangeEvent: log.ChangeEvent,
                    DateChanged: log.DateChanged,
                    EntityTypeID: log.EntityTypeID,
                    ChangeType: log.ChangeType,
                    WhoChangedID: log.WhoChangedID,
                    WhoChangedName: log.WhoChangedName,
                    Field: log.Field,
                    PreviousValue: log.PreviousValue,
                    NewValue: log.NewValue,
                    EntityTitle: log.EntityTitle ? log.EntityTitle : log.EntityID,
                    PreviousValueDesc: log.PreviousValueDesc,
                    NewValueDesc: log.NewValueDesc,
                    Logs: [],
                };
                summary.EntityTypeID = this.mapEntityName(summary.EntityTypeID);
                summary.ChangeType = this.mapChangeName(summary.ChangeType);
                summary.Field = this.mapFieldName(summary.Field);
                summaries[log.ChangeEvent] = summary;
            }
            log.Field = this.mapFieldName(log.Field);
            summary.Logs.push(log);

            summary.IsSystem = summary.Logs.length === 1;
        });

        _.each(summaries, (key: IChangeLogSummary) => {
            resultArr.push(key);
        });

        return resultArr;
    }

    private mapChangeName(changeType: number): string {

        return mapChangeNames.find(t => t.key === changeType).val;
    }

    private mapFieldName(inputString: string): string {

        const result: string = !(fieldNames.find(t => t.key === inputString) === undefined) ? fieldNames.find(t => t.key === inputString).val : inputString;

        return result;
    }

    private mapEntityName(entityType: number): string {

        return entityNames.find(t => t.key === entityType).val;
    }

}
