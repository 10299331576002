import { SortDirection } from "@angular/material";

import { CollectionActionTypes, CollectionActionsUnion } from "../actions/collection.actions";

import { PermissionGroupList } from "../models/permission-group";

export interface State {
  ids: any[];
  totalRows: number;
  sortActive: string;
  sortDirection: SortDirection;
  pageSize: number;
  pageIndex: number;
  filter: string;
  groups: PermissionGroupList[];
}

const initialState: State = {
  ids: [],
  totalRows: 0,
  pageSize: 15,
  pageIndex: 0,
  sortActive: "Name",
  sortDirection: "desc",
  filter: "",
  groups: [],
};

export function reducer(
  state = initialState,
  action: CollectionActionsUnion,
): State {
  switch (action.type) {
    case CollectionActionTypes.TableOptionsChanges: {
      return {
        ...state,
        pageSize: action.payload.pageSize || initialState.pageSize,
        pageIndex: action.payload.pageIndex || initialState.pageIndex,
        sortActive:  action.payload.sortActive || initialState.sortActive,
        sortDirection: action.payload.sortDirection || initialState.sortDirection,
        filter: action.payload.filter || initialState.filter,
      };
    }
    case CollectionActionTypes.TableOptionsChangesSuccess: {
      return {
        ...state,
        ids: action.payload.map(group => group.Id),
        totalRows: state.filter ? action.payload.length : state.groups.length,
      };
    }
    case CollectionActionTypes.Load: {
      return {
        ...state,
        ids: [],
      };
    }
    case CollectionActionTypes.LoadSuccess: {
      return {
        ...state,
        groups: action.payload,
        totalRows: action.payload.length,
      };
    }
    case CollectionActionTypes.RemoveGroupSuccess: {
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload),
        totalRows: state.ids.length - 1,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIds = (state: State) => state.ids;
export const getGroups = (state: State) => state.groups;
export const getTotalRows = (state: State) => state.totalRows;
export const getPageIndex = (state: State) => state.pageIndex;
export const getPageSize = (state: State) => state.pageSize;
export const getSortActive = (state: State) => state.sortActive;
export const getSortDirection = (state: State) => state.sortDirection;
export const getFilter = (state: State) => state.filter;
