import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {
    ListViewFilter, ListViewFilterSortData,
} from "./list-view-models";

@Component({
    selector: "ease-list-view-search",
    templateUrl: "./list-view-search.component.html",
})
export class ListViewSearchComponent implements OnChanges {

    @Input() public label: string;
    @Input() public filters: ListViewFilter [] = [];
    @Output() public filterChanged = new EventEmitter <string>();
    @Output() public filterCleared = new EventEmitter();

    public filterText: string = null;

    constructor() {}

    public ngOnChanges(changes: SimpleChanges) {

        if (changes.filters != null && changes.filters.currentValue != null) {
            this.filters = changes.filters.currentValue;
        }
    }

    public onFilterTextChanged() {

        this.filterChanged.emit(this.filterText);
        if (this.filterText === "") {
            this.filterText = null;
        }
    }

    public onFilterTextCleared() {

        this.filterText = null;
        this.filterCleared.emit();
    }

}