import { Component } from "@angular/core";
import { AuditConductComponent } from "./audit-conduct.component";

@Component({
  selector: "ease-audit-confirm",
  templateUrl: "./audit-confirm.component.html",
  styles: [".height-100 {height: 100px;}"],
})

export class AuditConfirmComponent {
  constructor(public auditConduct: AuditConductComponent) {

  }
}
