import { Action } from "@ngrx/store";

// Models
import { TableCollectionChanges } from "@core/models/table";
import {
  FetchOrganizationsResponse,
} from "../models/organization";

export enum UsersActionTypes {
  OptionsChanges = "[Organizations] Users Options Changes",
  Select = "[Organizations] Select Organization",
  Fetch = "[Organizations] Fetch Users",
  FetchSuccess = "[Organizations API] Fetch Users Success",
  FetchFail = "[Organizations API] Fetch Users Fail",
}

export class OptionsChanges implements Action {
  readonly type = UsersActionTypes.OptionsChanges;

  constructor(public payload: TableCollectionChanges) {
  }
}

export class Select implements Action {
  readonly type = UsersActionTypes.Select;

  constructor(public payload: number) {
  }
}

export class Fetch implements Action {
  readonly type = UsersActionTypes.Fetch;
}

export class FetchSuccess implements Action {
  readonly type = UsersActionTypes.FetchSuccess;

  constructor(public payload: any) {
  }
}

export class FetchFail implements Action {
  readonly type = UsersActionTypes.FetchFail;

  constructor(public payload: any) {
  }
}

export type UsersActionsUnion =
  | OptionsChanges
  | Select
  | Fetch
  | FetchSuccess
  | FetchFail;
