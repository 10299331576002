import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { ICompanySite } from "@core/models/ease-models";
import { DialogConfigMd } from "@shared/dialogs";
import { CompanySiteDetailModalComponent } from "@app/customer/company-sites/company-site-detail-modal.component";
import { NotifyService } from "@core/services";

@Component({
  templateUrl: "./customer-list-dialog.component.html",
})

export class CustomerListDialogComponent {
  public selectedCustomers: ICompanySite[] = [];
  constructor(
    public dialogRef: MatDialogRef<CustomerListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, public notify: NotifyService) {
      this.selectedCustomers = data.customerAssociations;
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public saveCustomerAssociations() {
    this.dialogRef.close(this.selectedCustomers);
  }

  public customerSelectionChangedHandler(selected: ICompanySite[]) {
    this.selectedCustomers = selected;
  }

  public openCompanySiteDialog(): void {
    const config = new DialogConfigMd();
    config.data = { addMode: true, listType: "customer" };

    const dialogRef = this.dialog.open(CompanySiteDetailModalComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notify.broadcast("refresh-customer-associations");
      }
    });
  }
}
