import { Injectable, NgZone } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import { ApiService } from "../api.service";
import { LocalService } from "../local.service";
import { StorageKey, StorageService } from "../storage.service";
import { ToolsService } from "../tools.service";
import { IOrgArtifact } from "../../models/ease-models";

import { Observable,  ReplaySubject } from "rxjs";
import {IdentityService} from "@core/services";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ArtifactService {

    constructor(private api: ApiService,
                private storage: StorageService,
                private tools: ToolsService,
                private local: LocalService,
                private identity: IdentityService,
                private log: LoggerService,
                private zone: NgZone,
                private http: HttpClient) {
    }

    public convertBase64AsImageFile(answerArtifact: any[], auditID: number) {
        const images: Array<Observable<any>> = [];

        for (const data of answerArtifact) {
            images.push(this.getImageData(data, auditID));
        }
        if (images && images.length === 0) {
            // return empty file content observable if there are no artifacts to process
            images.push(Observable.create((result: any) => {
                result.next(answerArtifact);
                result.complete();
            }));
        }
        return Observable.forkJoin(images);
    }

    public getArtifactsToUpload(artifactsToUpload: any[]) {
        const artifactsToAdd: Array<Observable<any>> = [];

        for (const detail of artifactsToUpload) {
            artifactsToAdd.push(this.getArtifactFileContent(detail));
        }
        if (artifactsToAdd && artifactsToAdd.length === 0) {
            // return empty file content observable if there are no artifacts to process
            artifactsToAdd.push(Observable.create((result: any) => {
                result.next(artifactsToUpload);
                result.complete();
            }));
        }
        return Observable.forkJoin(artifactsToAdd);
    }

    public getImageUrl(img: any): string {
        let url = img.Url;

        switch (img.FileFormatID) {
            case 8: // doc
            case 17: // docx
            case 18: // docm
            case 36: // rtf
                url = "assets/images/ms-word.png";
                break;
            case 12: // txt
                url = "assets/images/text-file.png";
                break;
            case 11: // xls
            case 19: // xlsx
            case 20: // xlsm
                url = "assets/images/ms-excel.png";
                break;
            case 10:
                url = "assets/images/pdf.png";
                break;
            case 37:
                url = "assets/images/csv.png";
                break;
            case 9: // ppt
            case 21: // pptx
            case 22: // pptm
            case 23: // ppsx
            case 35: // pps
                url = "assets/images/ms-powerpoint.png";
                break;
            default:
                url = img.Url;
                break;
        }

        return url;
    }

    public setImageUrl(img: any, path: string): string {
      let url = this.getImageUrl(img);

      if (!this.storage.isDoc(img.FileFormatID) && this.tools.isApp() && url.indexOf(cordova.file.dataDirectory) === -1) {
        const artifactKey = this.storage.buildCurrentStorageKey(path, img.ID);
        url = cordova.file.dataDirectory + this.storage.keyAsString(artifactKey, null, img.FileFormatID);
      }
      return url;
    }

    public getOrganizationArtifact(): Observable<IOrgArtifact> {
        const result = new ReplaySubject<any>(1);
        if (this.tools.isOnline() && this.identity.isLoggedIn()) {
          this.api.Query("OrganizationArtifact", { type: 0 }, (data: IOrgArtifact) => {
            if (data != null) {
              result.next(data);
              result.complete();

            } else {
              result.next(null);
              result.complete();
            }
          });
        } else {
          result.next(null);
          result.complete();
        }
        return result.asObservable();
    }
    public deleteOrganizationArtifact(id: number): Observable<void> {
      return this.http.delete<void>(`/OrganizationArtifact/${id}`);
    }

    /**
     * Convert a base64 string in a Blob according to the data and contentType.
     *
     * @param b64Data {String} Pure base64 string without contentType
     * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
     * @param sliceSize {Int} SliceSize to process the byteCharacters
     * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @return Blob
     */
    private b64toBlob(b64Data: string, contentType: string, sliceSize: number): Blob {
        contentType = contentType || "";

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    /**
     * Create a Image file according to its database64 content only.
     *
     * @param base64String {Base64 String} base64 encoded string
     * @param key {StorageKey} the storage key
     */
    private savebase64AsImageFile(base64String: string, key: StorageKey): Observable<string> {
        const result = new ReplaySubject<string>(1);

        // Split the base64 string in data and contentType
        const block = base64String.split(";");
        // Get the content type
        // the content type of the file i.e (image/jpeg - image/png - text/plain)
        const contentType = block[0].split(":")[1]; // In this case "image/png"
        // get the real base64 content of the file
        // Important : The content can't contain the following string (data:image/png[or any other format];base64,). Only the base64 string is expected.
        const content = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

        const dataType = contentType.split("/"); // In this case "png"
        // get file extension
        const extension = dataType[1];

        // Convert the base64 string in a Blob
        const DataBlob = this.b64toBlob(content, contentType, 512);

        this.storage.storeImage(key, extension, DataBlob).subscribe(
            () => {
                 // The path where the file will be created
                let folderpath = this.storage.keyAsString(key, extension);
                if (this.tools.isApp()) {
                    folderpath = cordova.file.dataDirectory + this.storage.keyAsString(key, extension); //"file:///storage/emulated/0/";
                }
                result.next(folderpath);
                result.complete();
            },
            (err: any) => {
                this.log.error("failed to store image in savebase64AsImageFile - " + JSON.stringify(err));
            },
        );

        return result.asObservable();
    }

    // Create a new name for the image
    private createTempFileName() {
        const d = new Date();
        return d.getTime();
    }

    private getImageData(data: any, auditID: number): Observable<any> {
        return this.getBase64asImage(data, auditID).take(1).map(imageData => {
            return imageData;
        });
    }

    private getBase64asImage(data: any, auditID: number): Observable<any> {
        const result = new ReplaySubject<any>(1);
        const entityID = (data.ID === 0) ? (auditID + "_" + this.createTempFileName()) : data.ID;
        const entityName = "AnswerArtifact";
        const key = this.storage.buildCurrentStorageKey(entityName, entityID);

        const block = data.Url.split(";");
        if (block.length > 1) {
            this.savebase64AsImageFile(data.Url, key).subscribe(
                (folderPath: string) => {
                    if (this.tools.isApp()) {
                        const filename = folderPath.split("/").pop();
                        data.Url = folderPath;
                        data.Filename = filename;
                    } else {
                        data.Url = this.setImageUrl(data, folderPath);
                    }
                    result.next(data);
                    result.complete();
                },
                (err: FileError) => {
                    this.log.error("getBase64asImage error: " + err);
                    result.error(err);
                },
            );
        } else {
            result.next(data);
            result.complete();
        }

        return result.asObservable();
    }

    private getArtifactFileContent(detail: any): Observable<any> {
        return this.retrieveContentAsBase64(detail).take(1).map(content => {
            return content;
        });
    }

    private retrieveContentAsBase64(detail: any): Observable<any> {
        const result = new ReplaySubject<any>(1);

        if (this.tools.isApp()) {
            this.getFileContentAsBase64(detail.Url).subscribe(
                (data: any) => {
                    detail.imageData = data;
                    result.next(detail);
                    result.complete();
                },
                (err: FileError) => {
                    result.error(err);
                },
            );
        } else {
            result.next(detail);
            result.complete();
        }

        return result.asObservable();
    }

    private getFileContentAsBase64(source: string): Observable<any> {
        const result = new ReplaySubject<any>(1);

        window.resolveLocalFileSystemURL(source, (fileEntry: FileEntry) => {
            fileEntry.file(
                (file: File) => {
                    const reader = new FileReader();
                    reader.onloadend = (evt: ProgressEvent) => {
                        result.next(reader.result);
                        result.complete();
                    };
                    reader.onerror = (evt: ErrorEvent) => {
                        result.error(reader.error);
                    };
                    reader.readAsDataURL(file);
                },
                (err: FileError) => {
                    result.error(err);
                });

        }, (err: FileError) => { result.error(err); });

        return result.asObservable();
    }

}
