import {
  Component, EventEmitter, Input, OnDestroy, OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { MatDialog } from "@angular/material";

// Services
import { CurrentInfoService } from "../../services/current.service";
import { NotifyService } from "../../services/notify.service";
import { Subject } from "rxjs";
import {FeedbackService} from "@core/services";

@Component({
  selector: "ease-left-nav",
  templateUrl: "./left-nav.component.html",
})

export class LeftNavComponent implements OnInit, OnDestroy {

  @Output() public leftNavTogglePin = new EventEmitter<boolean>();
  @Input() public isLeftNavPinned: boolean = false;

  public isLoggedIn: boolean = false;
  public current: any;
  public feedback: any;
  public feedbackCalled: boolean = false;
  public defaultNavID: string = "home";
  public expandedNavID: string;
  public validMenus: string[] = ["manage", "assign", "admin", "report", "config", "settings"];
  public showFeedbackRequestWidget: boolean = false;
  private destroy$ = new Subject<void>();
  public isManagerOrSiteAdmin = (): boolean => this.current.roleIsManager || this.current.roleIsSiteAdmin;
  public areManageMenusVisible = (): boolean =>
    this.isContentMenuVisible() || this.isJobsMenuVisible() || this.isCompaniesMenuVisible()
  public isContentMenuVisible = (): boolean => this.current.roleIsAuditAuthor;
  public isJobsMenuVisible = (): boolean => this.isManagerOrSiteAdmin() && this.current.isJobTypeEnabled;
  public isCompaniesMenuVisible = (): boolean =>
    (this.current.isCustomerTypeEnabled || this.current.isJobTypeEnabled || this.current.isSupplierEnabled)
    && this.isManagerOrSiteAdmin()

  constructor(private currentSvc: CurrentInfoService,
              private router: Router, private route: ActivatedRoute,
              private notify: NotifyService,
              private feedbackService: FeedbackService,
              private dialog: MatDialog) {
    this.current = currentSvc.info;
    router.events
      .filter(event => event instanceof NavigationStart).takeUntil(this.destroy$)
      .subscribe((event: NavigationStart) => {
        const menu = this.extractMenuFromUrl(event.url);
        this.expandedNavID = this.getExpandedNavID(menu);
      });

    this.notify.loggedIn.asObservable().takeUntil(this.destroy$).subscribe((value: boolean) => {
      this.isLoggedIn = value;
    });
    this.feedback = feedbackService;
  }

  public toggleExpandedNavID(navID: string) {
    this.expandedNavID = navID;
  }

  public toggleNavPin() {
    this.leftNavTogglePin.emit(true);
  }

  public ngOnInit(): void {
    const snapshot: any = this.route.snapshot;
    const url: string = snapshot._routerState.url;
    const menu = this.extractMenuFromUrl(url);
    this.expandedNavID = this.getExpandedNavID(menu);

  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private extractMenuFromUrl(url: string): string {
    const parts = url.split("/");

    if (parts.length > 1) {
      return parts[1];
    } else {
      return null;
    }
  }

  private getExpandedNavID(menu: string): string {

    if (menu != null && this.validMenus.indexOf(menu) >= 0) {

      if (menu === "config" || menu === "settings") {
        menu = "admin";
      }
      return menu;
    } else {
      return this.defaultNavID;
    }
  }

  private launchFeedbackModal() {
      this.showFeedbackRequestWidget = false;
    setTimeout(() => {
        this.showFeedbackRequestWidget = true;
        const result = this.feedback.setResponseDate().subscribe(data => {});
    }, 200);
  }
}
