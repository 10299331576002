
import {
  ReleaseDetailActionTypes,
  ReleaseDetailActionsUnion,
} from "../actions/release-detail.actions";

import {
  Release,
} from "../models/release";

export interface State {
  id: number;
  loading: boolean;
  release: Release;
}

const initialState: State = {
  id: 0,
  loading: false,
  release: {
    ID: 0,
    Version: "",
    Name: "",
    Description: "",
    IsVisible: false,
    ReleaseDate: "",
  },
};

export function reducer(
  state = initialState,
  action: ReleaseDetailActionsUnion,
): State {
  switch (action.type) {

    // Fetch
    case ReleaseDetailActionTypes.Load: {
      return {
        ...state,
        loading: true,
      };
    }

    case ReleaseDetailActionTypes.LoadSuccess: {
      return {
        ...state,
        loading: false,
        release: action.payload,
        id: action.payload.ID,
      };
    }

    case ReleaseDetailActionTypes.LoadFail: {
      return {
        ...state,
        loading: false,
      };
    }

    // Update
    case ReleaseDetailActionTypes.Update: {
      return {
        ...state,
        loading: true,
      };
    }

    case ReleaseDetailActionTypes.UpdateSuccess: {
      return {
        ...state,
        loading: false,
      };
    }

    case ReleaseDetailActionTypes.UpdateFail: {
      return {
        ...state,
        loading: false,
      };
    }

    // Create
    case ReleaseDetailActionTypes.Create: {
      return {
        ...state,
        loading: true,
      };
    }

    case ReleaseDetailActionTypes.CreateSuccess: {
      return {
        ...state,
        loading: false,
      };
    }

    case ReleaseDetailActionTypes.CreateFail: {
      return {
        ...state,
        loading: false,
      };
    }

    // Clear
    case ReleaseDetailActionTypes.Clear: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;
export const getReleaseDetail = (state: State) => state.release;
export const getReleaseDetailId = (state: State) => state.id;
export const getReleaseName = (state: State) => getReleaseDetail(state) && getReleaseDetail(state).Name;
