import {
  AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { IdentityService, LocalService, NotifyService, ToolsService } from "@core/services";
import { PageStateService } from "../services/navigation/page-state.service";
import { Subject } from "rxjs";

// State
import { select, Store } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";

@Component({
  selector: "ease-app-container",
  templateUrl: "./app-container.component.html",
})

// Responsible for handling all navigational aspects of the app
export class AppContainerComponent implements OnInit, AfterViewInit {

  isLoggedIn: boolean;
  public isLeftNavPinned: boolean = false;
  public isLongPageLoad: boolean = false;
  public isPrintMode: boolean = false;

  @ViewChild("easeSideNav") public easeSideNav: any;

  private componentDestroyed: Subject<boolean> = new Subject();

  constructor(private store: Store<fromAuth.State>,
              private router: Router,
              private local: LocalService,
              private notify: NotifyService,
              private tools: ToolsService,
              private pageStateService: PageStateService,
              private cdr: ChangeDetectorRef,
              private identity: IdentityService) {

    this.store.pipe(select(fromAuth.getLoggedIn)).subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;

      if (loggedIn) {
        this.restorePreviousPinState();
      } else {
        this.unPinLeftNavBar();
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  public onResize(event: any) {
    this.pageStateService.windowResizeHandler(event.target);
  }

  public leftNavExpandedHandler(isLeftNavExpanded: boolean) {

    if (isLeftNavExpanded) {
      this.easeSideNav.open();
    }
  }

  public leftNavTogglePinHandler(isLeftnavPinnned: boolean) {

    this.isLeftNavPinned = !this.isLeftNavPinned;

    if (this.isLeftNavPinned) {
      this.pinLeftNavBar();
    } else {
      this.unPinLeftNavBar();
    }

    this.local.setItem("app.container.navPinned", (this.isLeftNavPinned) ? "1" : "0");
  }

  public pinLeftNavBar() {
    if (this.easeSideNav != null) {
      this.easeSideNav.mode = "side";
      this.easeSideNav.open();
      this.setPinState(true);
    }
  }

  public unPinLeftNavBar() {
    if (this.easeSideNav != null) {
      this.easeSideNav.mode = "over";
      this.easeSideNav.close();
      this.setPinState(false);
    }
  }

  public ngOnInit() {
    this.registerSubscriptions();
  }

  public ngAfterViewInit() {
    this.restorePreviousPinState();
  }

  public ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.combineAll();
  }

  private restorePreviousPinState() {
    if (this.identity.isLoggedIn()) {
      const wasPinned = this.local.getItem("app.container.navPinned");
      if (wasPinned === "1") {
        this.pinLeftNavBar();
      }
    }
  }

  private setPinState(isPinned: boolean) {
    this.isLeftNavPinned = isPinned;
    this.pageStateService.setIsLeftNavPinned(isPinned);
  }

  private registerSubscriptions(): void {
    this.notify.on("hide-left-nav").subscribe((hide: boolean) => {
      if (hide) {
        this.unPinLeftNavBar();
      } else {
        this.pinLeftNavBar();
      }
    });
    this.router.events.takeUntil(this.componentDestroyed).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLongPageLoad = false;
        this.cdr.detectChanges();
      }
      if (this.easeSideNav != null && !this.isLeftNavPinned) {
        this.easeSideNav.close();
      }
    });
    this.notify.on("full-page-block").takeUntil(this.componentDestroyed).subscribe((data: boolean) => {
      this.isLongPageLoad = data;
      this.cdr.detectChanges();
    });
    this.pageStateService.getBootStrapSizeChange().takeUntil(this.componentDestroyed).subscribe((size: string) => {
      // Force left nav to be unpinned when window size changes to xs or sm
      if (size === "xs" || size === "sm") {
        this.unPinLeftNavBar();
      }
    });
    this.notify.on("print-window-on").subscribe((isPrintOn: boolean) => {
      this.isPrintMode = isPrintOn;
    });
  }
}
