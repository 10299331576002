import { throwError as observableThrowError,  Subject,  Observable,  combineLatest } from "rxjs";
import { tap, debounceTime, catchError } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Router } from "@angular/router";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import {
  LogoutFromInterceptor,
  RefreshToken,
} from "app/auth/actions/auth";

// Services
import {
  LoggerService,
  IdentityService,
  LocalService,
  NotifyService,
} from "@core/services";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private source$ = new Subject();
  private token: string;
  private tokenRefreshDate: string;
  private activeSiteID: number;

  constructor(private store: Store<fromAuth.State>,
              private local: LocalService,
              private notify: NotifyService,
              private identity: IdentityService,
              private router: Router,
              private log: LoggerService) {
    combineLatest(
      this.store.pipe(select(fromAuth.getToken)),
      this.store.pipe(select(fromAuth.getTokenRefreshDate)),
      this.store.pipe(select(fromAuth.getActiveSiteID)),
    ).subscribe(([ token, date, activeSiteID ]) => {
      this.token = token;
      this.tokenRefreshDate = date;
      this.activeSiteID = activeSiteID;
    });

    this.source$.pipe(
      debounceTime(2000),
    ).subscribe(() => {
      if (this.token && this.tokenRefreshDate && this.isRefreshNeeded()) {
        this.store.dispatch(new RefreshToken(this.activeSiteID));
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenReq = req.clone(this.token ?
      { setHeaders: { authorization: `Token ${this.token}` }, withCredentials: true } :
      { withCredentials: true });

    return next.handle(tokenReq)
      .pipe(
        tap(() => {
          this.source$.next(true);
        }),
        catchError(error => {
          if (error.status === 401) {
            if (!this.router.isActive("/login", false)) {
              this.log.debug("TokenInterceptor: Logout and Redirect to login page");
              this.store.dispatch(new LogoutFromInterceptor(""));
            }
          }

          return observableThrowError(error);
        }),
      );
  }

  private isRefreshNeeded() {
    const date = this.tokenRefreshDate;
    const diff = (new Date().getTime()) - (new Date(date)).getTime();
    const time24h = 1000 * 60 * 60 * 24;
    // const time10m = 1000 * 60 * 10;
    // const time1m = 1000 * 60;

    return diff > time24h;
  }
}
