import { Observable,  throwError } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

// State
import { select, Store } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";

// Models
import { IUser } from "../models/ease-models";

@Injectable()
export class UserService {
  userID: number;

  constructor(private store: Store<fromAuth.State>,
              private http: HttpClient) {
    this.store.pipe(select(fromAuth.getUserID)).subscribe(userID => {
      this.userID = userID;
    });
  }

  public getUsers(query: any): Observable<IUser[]> {
    return this.http.get<IUser[]>("/user", { params: query });
  }

  public getUser(query: any): Observable<IUser> {
    return this.http.get<IUser>("/user", { params: query });
  }

  public getUsersInScope(scopeId: number): Observable<IUser[]> {
    return this.http.get<IUser[]>("/user", {
      params: new HttpParams()
        .set("scopeId", scopeId.toString())
        .set("includeUserRoles", true.toString())
        .set("details", true.toString()),
    });
  }

  public updateUserSetting(user: IUser, updateEnrollBeta: boolean = false): Observable<any> {
    return this.http.put("/user/" + user.ID + "?updateEnrollBeta=" + updateEnrollBeta, user);
  }

  public updateUser(user): Observable<IUser> {
    if (!this.userID) {
      return throwError("User is not ready!");
    }

    const operations = [];

    for (const field in user) {
      operations.push({
        op: "replace",
        path: field,
        value: user[ field ],
      });
    }

    return this.http.patch<IUser>(`/user/${this.userID}`, operations);
  }

  public getManagers(siteId: number, includeScoped: boolean): Observable<IUser[]> {
    return this.http.get<IUser[]>("/user/managers", {
      params: new HttpParams()
        .set("siteId", siteId.toString())
        .set("includeScoped", includeScoped.toString()),
    });
  }
}
