import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { RequestPassword } from "../../models/user";

@Component({
  selector: "ease-request-restore-form",
  templateUrl: "./request-restore-form.html",
  styleUrls: [ "./request-restore-form.scss" ],
})
export class RequestRestoreFormComponent {
  @Output("easeSubmitted") submitted = new EventEmitter<RequestPassword>();
  @Input("easePending") pending: boolean;
  @Input("easeError") error: string[];

  form = new FormGroup({
    email: new FormControl("", [ Validators.required, Validators.email ]),
  });

  constructor() {
  }

  onSubmit() {
    this.submitted.emit(this.form.value);
  }

  required() {
    return this.form.get("email").hasError("required");
  }

  validEmail() {
    return this.form.get("email").hasError("email") && !this.required();
  }
}
