import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable,  ReplaySubject } from "rxjs";
import { AuditDetailService } from "@core/services/common/audit-detail.service";
import { MitigationDetailService } from "@core/services/common/mitigation-detail.service";
import { CurrentInfoService } from "@core/services/current.service";
import { LocalizeService } from "@core/services/localize.service";
import { UserActivity, UserActivityType, MyActivityListDetail } from "../models/home.interface";

import { isNullOrUndefined } from "util";
import { map } from "rxjs/operators";

import * as _ from "lodash";
import * as moment from "moment";

import {
  CapaStatusTypes,
  CompletionStatus, EaseMitigationStatus, IAudit, IAuditConfig, IAuditDetail, ICapaEvent, ICapaTask, IClientAudit,
  IMitigationActivity,
  IMitigationDetail, IRank, IUserActivity, MitigationDetailedStatus,
} from "@core/models/ease-models";

@Injectable()
export class HomeService {

  constructor(private localize: LocalizeService,
              private current: CurrentInfoService,
              private auditDetail: AuditDetailService,
              private mitigationDetail: MitigationDetailService,
              private zone: NgZone,
              private http: HttpClient) {
  }

  public getHomeActivity(activityType: string, summaryOnly: boolean): Observable<any> {

    if (summaryOnly) {
      return this.http.get("/CurrentUserActivity")
        .map((data: UserActivity[]) => {
          this.appendStatusText(data);
          return data;
        });
    } else {
      return this.getHomeActivityMobile(activityType);
    }
  }

  private appendStatusText(activities: UserActivity[]) {
    activities.forEach((a) => {
      switch(a.Type) {
        case UserActivityType.Audit:
          a.TranslatedStatus = this.getAuditTranslatedStatus(a.Status as CompletionStatus);
          break;
        case UserActivityType.Mitigation:
        case UserActivityType.MitigationApproval:
        case UserActivityType.CapaTask:
          a.TranslatedStatus = this.getMitigationTranslatedStatus(a.PastDue, a.Status);
          break;
        case UserActivityType.CapaEvent:
          a.TranslatedStatus = this.getCapaTranslatedStatus(a.Status);
          break;
      }
    });
  }

  // We need to eventually deprecate this.  Only used for mobile for now
  public getHomeActivityMobile(activityType: string): Observable<any> {

    const result = new ReplaySubject<any>();

    // get audit details
    this.auditDetail.getAudits("Audits").subscribe((audits: IAuditDetail[]) => {
      this.zone.run(() => {
        // get mitigation details
        this.mitigationDetail.getMitigations().subscribe((mitigations: IMitigationDetail[]) => {
          this.zone.run(() => {
            // get capa details if capa is enabled

            if (this.current.info.featureFlags.isCapaEnabled) {
              this.mitigationDetail.getCapaEvent().subscribe((capaEvent: MyActivityListDetail) => {
                // merge activities w capa
                this.mergeActivities(activityType, audits, mitigations, capaEvent).subscribe((mergeData: IUserActivity[]) => {
                  this.zone.run(() => {
                    result.next({ activities: mergeData, auditDetails: audits, mitigationDetails: mitigations });
                    result.complete();
                  });
                });
              });
            } else {
              // merge activites w/o capa
              this.mergeActivities(activityType, audits, mitigations).subscribe((mergeData: IUserActivity[]) => {
                this.zone.run(() => {
                  result.next({ activities: mergeData, auditDetails: audits, mitigationDetails: mitigations });
                  result.complete();
                });
              });
            }
          });
        });
      });
    });

    return result.asObservable();
  }

  public mergeActivities(activityType: string, audits: IAuditDetail[], mitigations: IMitigationDetail[], capaEvent?: MyActivityListDetail): Observable<any[]> {
    const result = new ReplaySubject<any>();
    let userActivities: IUserActivity[] = [];
    let filteredMitigations = _.cloneDeep(mitigations);

    filteredMitigations = filteredMitigations.filter(x => (x.Mitigation.Status === EaseMitigationStatus.Pending && x.Mitigation.ApprovingManagerUserID === this.current.info.user.ID) || (x.Mitigation.Status === EaseMitigationStatus.Open && x.Mitigation.ResponsiblePartyUserID === this.current.info.user.ID));

    //add all audit details
    const currentDate = moment();
    _.each(audits, (detail: IAuditDetail) => {
      if (detail.Audit) {
        const audit: IClientAudit = detail.Audit as IClientAudit;
        const startDate: Date = audit.CalendarEvent.Start;
        const canConduct: boolean = (activityType === "all-users") ? true : moment(startDate) <= currentDate;
        const cm = this.current.info;
        const dataEntry: boolean = cm.roleIsDataEntry && (cm.user.ID !== audit.AuditorUserID);
        const isRedirectAuditEntry: boolean = this.checkIsRedirectAuditEntry(detail.AuditConfiguration, detail.Audit);
        if (canConduct) {
          userActivities.push({
            ID: audit.ID,
            Type: 0,
            TranslatedType: this.localize.getLocalizedString("_Audit_"),
            Name: audit.Description,
            Due: audit.DateRequired,
            Status: audit.Status,
            TranslatedStatus: this.getAuditTranslatedStatus(audit.Status),
            Location: audit.Location.Name,
            CanConduct: canConduct,
            PastDueDays: audit.PastDueDays,
            IsRedirectAuditEntry: isRedirectAuditEntry,
            DataEntry: dataEntry,
            DateUpdated: audit.DateUpdated
          });
        }
      }
    });

    //add all mitigation details
    _.each(filteredMitigations, (detail: IMitigationDetail) => {
      if (detail.Mitigation) {
        const mitigation: IMitigationActivity = detail.Mitigation;
        let name: string = "";
        if (mitigation.AuditID != null) {
          name = mitigation.Audit.Description;
        } else {
          name = mitigation.Name;
        }

        let due = null;
        if (mitigation.RankID != null) {
          if (mitigation.Rank == null && detail.Ranks) {
            const filtered = detail.Ranks.filter((rank: IRank) => rank.ID === mitigation.RankID);
            if (filtered && filtered.length > 0) {
              mitigation.Rank = filtered[0];
            }
          }

          if (mitigation.Rank != null) {
            due = mitigation.Due; // date is internationalized in the api service
          }
        }

        const locationName: string = (mitigation.Location == null) ? "" : mitigation.Location.Name;

        if (!mitigation.IsPartOfCapa) {
          const isApproval = mitigation.ApprovingManagerUserID && mitigation.Status === EaseMitigationStatus.Pending;
          userActivities.push({
            ID: mitigation.ID,
            Type: (isApproval) ? 2 : 1,
            TranslatedType: (isApproval) ? this.localize.getLocalizedString("_MitigationApproval_") : this.localize.getLocalizedString("_Mitigation_"),
            Name: name,
            Due: due,
            Status: mitigation.Status,
            TranslatedStatus: this.getMitigationTranslatedStatus(mitigation.PastDue, mitigation.Status),
            Location: locationName,
            AuditID: mitigation.AuditID,
            CanConduct: true,
            DateUpdated: mitigation.DateOpened,
          });
        }
      }
    });

    if (capaEvent) {
      // add all CAPA Events
      const capaOwnerActivity = capaEvent.CapaEventOwnerActivity;
      const capaApproverActivity = capaEvent.CapaEventApproverActivity;
      const capaTaskActivity = capaEvent.CapaTaskActivity;
      if (!isNullOrUndefined(capaOwnerActivity) && capaOwnerActivity.length > 0) {
        capaOwnerActivity.forEach((activity: ICapaEvent) => {
          const capaTranslatedStatus = this.getCapaTranslatedStatus(activity.CapaStatus);
          userActivities.push({
            ID: activity.ID,
            Type: 3,
            TranslatedType: this.localize.getLocalizedString("_CAPAEvent_"),
            Name: activity.CapaEventName,
            Due: activity.DueDate,
            Status: activity.CapaStatus,
            Location: activity.SiteName,
            TranslatedStatus: capaTranslatedStatus,
            CapaEventID: activity.ID,
            CanConduct: true,
          });
        });
      }

      if (!isNullOrUndefined(capaApproverActivity) && capaApproverActivity.length > 0) {
        capaApproverActivity.forEach((activity: ICapaEvent) => {
          const capaTranslatedStatus = this.getCapaTranslatedStatus(activity.CapaStatus);
          userActivities.push({
            ID: activity.ID,
            Type: 3,
            TranslatedType: this.localize.getLocalizedString("_CAPAEvent_"),
            Name: activity.CapaEventName,
            Due: activity.DueDate,
            Status: activity.CapaStatus,
            Location: activity.SiteName,
            TranslatedStatus: capaTranslatedStatus,
            CapaEventID: activity.ID,
            CanConduct: true,
          });
        });
      }

      if (!isNullOrUndefined(capaTaskActivity) && capaTaskActivity.length > 0) {
        capaTaskActivity.forEach((activity: any) => {
          if (activity.IsPartOfCapa) {
            userActivities.push({
              ID: activity.ID,
              Type: 4,
              TranslatedType: this.localize.getLocalizedString("_CAPATask_"),
              Name: activity.Name,
              Due: activity.Due,
              Status: activity.Status,
              TranslatedStatus: activity.TranslatedStatus,
              CapaEventID: activity.CapaEventID,
              CapaTaskID: activity.CapaTaskID,
              Location: activity.Location,
              CanConduct: true,
            });
          }
        });
      }
    }

    if (activityType === "all-users") {
      userActivities = _.orderBy(userActivities, [activity => activity.DateUpdated], ["desc"]);  // sort by "DateUpdated" descending
      userActivities = _.take(userActivities, 10);    // keep the first 10 results
    }

    result.next(userActivities);
    result.complete();

    return result.asObservable();
  }

  public getCapaTranslatedStatus(status: CapaStatusTypes): string {
    let value: string = "";
    switch (status) {
      case CapaStatusTypes.ClosedOnTime:
        value = "_CloseOnTime_";
        break;
      case CapaStatusTypes.ClosedPastDue:
        value = "_ClosedPastDue_";
        break;
      case CapaStatusTypes.InProgressOnTime:
        value = "_InProgressOnTime_";
        break;
      case CapaStatusTypes.InProgressPastDue:
        value = "_InProgressPastDue_";
        break;
      case CapaStatusTypes.PendingApproval:
        value = "_PendingApproval_";
        break;
    }
    return value;
  }

  public checkIsRedirectAuditEntry(config: IAuditConfig, audit: IAudit): boolean {
    let isRedirectAuditEntry: boolean = false;
    const notStarted = audit.Status === 0 || audit.Status === 5;
    if (notStarted && (config != null && (config.AuditTargets.indexOf(2) > -1 || config.AuditTargets.indexOf(4) > -1 || (config.AuditTargets.indexOf(5) > -1 && this.current.info.org.IsSupplierEnabled))) && ((audit.IsAdHoc && this.current.info.user.ID != audit.AuditorUserID) || !audit.IsAdHoc)) {
      isRedirectAuditEntry = true;
    }
    return isRedirectAuditEntry;
  }

  public getAuditTranslatedStatus(status: CompletionStatus): string {
    let value: string = "";
    switch (status) {
      case CompletionStatus.NotStarted:
        value = "_NotStarted_";
        break;
      case CompletionStatus.Complete:
        value = "_Complete_";
        break;
      case CompletionStatus.PartiallyComplete:
        value = "_PartiallyComplete_";
        break;
      case CompletionStatus.Missed:
        value = "_Missed_";
        break;
      case CompletionStatus.CompletedLate:
        value = "_CompletedLate_";
        break;
      case CompletionStatus.PastDue:
        value = "_PastDue_";
        break;
      default:
        value = "";
        break;
    }
    return value;
  }

  public getMitigationTranslatedStatus(isPastDue: boolean, status: number): string {
    let value: string = "";

    let detailedStatus: number;
    if (!isPastDue) {
      if (status === 0) {
        detailedStatus = 0; // OnTime-Open
      } else if (status === 1) {
        detailedStatus = 2; // OnTime-Closed
      } else if (status === 2) {
        detailedStatus = 4; // OnTime-Pending
      }
    } else {
      if (status === 0) {
        detailedStatus = 1; // PastDue-Open
      } else if (status === 1) {
        detailedStatus = 3; // PastDue-Closed
      } else if (status === 2) {
        detailedStatus = 5; // PastDue-Pending
      }
    }

    switch (detailedStatus) {
      case MitigationDetailedStatus.OnTimeOpen:
        value = "_OnTimeOpen_";
        break;
      case MitigationDetailedStatus.PastDueOpen:
        value = "_PastDueOpen_";
        break;
      case MitigationDetailedStatus.OnTimeClosed:
        value = "_OnTimeClosed_";
        break;
      case MitigationDetailedStatus.PastDueClosed:
        value = "_PastDueClosed_";
        break;
      case MitigationDetailedStatus.OnTimePending:
        value = "_OnTimePending_";
        break;
      case MitigationDetailedStatus.PastDuePending:
        value = "_PastDuePending_";
        break;
      default:
        value = "";
        break;
    }

    return value;
  }
}
