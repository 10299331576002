import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import { CoreModule } from "./core";
import { AuthModule } from "@app/auth/auth.module";
import { MaterialModule } from "./material";

import { routes } from "./routes";

import { AppComponent } from "@core/containers/app";
import { AppContainerComponent } from "@core/containers/app-container.component";

import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,

} from "ngx-perfect-scrollbar";

// Feature Modules
// They should be lazy
import { AccountModule } from "./account/account.module";
import { ConductModule } from "./conduct/conduct.module";
import { ResultsModule } from "./results/results-module";
import { HomeModule } from "@app/home/home.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,

    RouterModule.forRoot(routes, { useHash: true }),

    CoreModule.forRoot(),

    AuthModule.forRoot(),

    MaterialModule,

    PerfectScrollbarModule,

    AccountModule,
    HomeModule,
    ConductModule,
    ResultsModule,
  ],
  declarations: [
    AppComponent,
    AppContainerComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule {}
