// Immer
import produce from "immer";

// Ramda
import { map } from "ramda";

import {
  OrganizationActionTypes,
  OrganizationActionsUnion,
} from "./../actions/organization.actions";

// Models
import { ApiError } from "@core/models/api";
import { OrganizationForm, OrganizationFormData } from "../models/organization";

export interface State {
  saved: boolean;
  loading: boolean;
  error: ApiError | null;
  formValue: OrganizationForm | null;
  initialFormData: OrganizationFormData;
  formData: OrganizationFormData;
}

const initialState: State = {
  saved: false,
  loading: false,
  error: null,
  formValue: null,
  initialFormData: null,
  formData: null,
};

export function reducer(
  state = initialState,
  action: OrganizationActionsUnion,
): State {
  switch (action.type) {
    case OrganizationActionTypes.Load: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case OrganizationActionTypes.LoadSuccess: {
      return {
        ...state,
        formValue: new OrganizationForm(action.payload),
        loading: false,
      };
    }

    case OrganizationActionTypes.LoadFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case OrganizationActionTypes.InitialDetails: {
      return {
        ...state,
        initialFormData: action.payload,
        formData: action.payload,
      };
    }

    case OrganizationActionTypes.ChangedDetails: {
      return {
        ...state,
        formData: action.payload,
      };
    }

    case OrganizationActionTypes.ResetDetails: {
      return {
        ...state,
        formData: state.initialFormData,
      };
    }

    case OrganizationActionTypes.SubmitDetails: {
      return {
        ...state,
        loading: true,
      };
    }

    case OrganizationActionTypes.SubmitDetailsSuccess: {
      return {
        ...state,
        formValue: new OrganizationForm(action.payload),
        loading: false,
      };
    }

    case OrganizationActionTypes.SubmitDetailsFailure: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getFormValue = (state: State) => state.formValue;
export const getName = (state: State) => getFormValue(state) && getFormValue(state).Name;
export const getLoading = (state: State) => state.loading;
export const getInitialFormData = (state: State) => state.initialFormData;
export const getFormData = (state: State) => state.formData;
export const getSaved = (state: State) => state.saved;
export const getError = (state: State) => state.error;
