import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from "@angular/core";
import { IOrgLayerSiteDetail } from "@core/models/ease-models";
import { CurrentInfoService, AppSettingsService } from "@core/services/";
import { RepeatingEventData } from "@shared/calendar/repeating-event-data";

@Component({
  selector: "ease-scheduling-defaults",
  templateUrl: "./scheduling-defaults.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SchedulingDefaultComponent implements OnInit {
  public isGracePeriodEnabled: boolean;

  @Input() public orgLayerDetail: IOrgLayerSiteDetail;
  @Input() public repeat: RepeatingEventData;
  @Input() public isAssignAudit: boolean;
  @Input() public minDate: Date;
  @Input() public useLayers: boolean;
  @Output() public modelChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private current: CurrentInfoService, private appSettings: AppSettingsService) {
  }

  public ngOnInit(): void {
    this.isGracePeriodEnabled = this.appSettings.currentAppSettings.isGracePeriodEnabled;
  }

  public onValueChanged() {
    this.modelChanged.emit();
  }
}
