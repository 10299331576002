import { ILocation, IShift, IUser } from "../core/models/ease-models";

export enum TreeNodeMode {
  View = "view",
  Edit = "edit",
}

export interface LocationImportStatus {
  Counter: number;
  Parent: string;
  Name: string;
  Status: string;
  Message: string;
}

export interface ShiftManager {
  ID: number;
  ShiftID?: number;
  Shift: IShift;
  OrganizationID: number;
  SiteID: number;
  LocationID: number;
  Location: ILocation;
  ManagerID?: number;
  Manager: IUser;
  IsModified: boolean;
}

export interface TreeNode {
  id: number;
  name: string;
  icon: string;
  type: string;
  isExpanded: boolean;
  mode: TreeNodeMode;
  children: TreeNode[];
}

export interface TimeZoneInfo {
  Id: string;
  DisplayName: string;
}

export class LocationTreeNode implements TreeNode {
  constructor(public id: number,
              public OrganizationID,
              public name: string,
              public description: string,
              public parentId: number,
              public siteId: number,
              public timeZoneId: string,
              public isInScope: boolean,
              public icon: string,
              public type: string,
              public locationTypeId: number,
              public isExpanded: boolean,
              public isSite?: boolean,
              public mode: TreeNodeMode = TreeNodeMode.View,
              public children: LocationTreeNode[] = []) {}
}
