import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { CalendarEvent } from "angular-calendar";

import { LoggerService } from "@core/services/logger.service";
import { ApiService, CurrentInfoService, IdentityService, LocalizeService,
         StorageKey, StorageService, NotifyService } from "@core/services";
import { ToolsService } from "@core/services/tools.service";
import { WorkScheduleCalendarService } from "./work-schedule-calendar.service";

import { CalendarModalComponent } from "./calendar-modal.component";
import { AuditReassignModalComponent } from "./audit-reassign-modal.component";
import { ConfirmHolidayModalComponent } from "./confirm-holiday-modal.component";

import * as moment from "moment";
import { CalendarEventType, CalEvent, CalSchedule, ICalendar, ICalendarEvent,
         ICalendarSeries, ICalendarWorkSchedule, IClientAudit, Workday,
         IUser, ISite } from "@core/models/ease-models";

import { DialogConfigLg, DialogConfigMd, DialogConfigSm } from "@shared/dialogs";
import { DateInternationalizePipe } from "@shared/pipes";
import { Subject } from "rxjs";

@Component({
  selector: "ease-my-calendar",
  styleUrls: ["./work-schedule-calendar.component.scss"],
  templateUrl: "./work-schedule-calendar.component.html",
})
export class WorkScheduleCalendarComponent implements OnDestroy {
  // configuration settings for our calendar directive
  public activeDayIsOpen: boolean;
  public title: string = "";
  public calendarType: string = "site";  // can be 'site','user','department', 'location' or 'shift'
  public calendarEntityID: number = null;   // can be site,user,department, locationID or shift
  public currentCalendar: ICalendar = null;
  public calendarDirectiveIDParm: number = null;
  public events: CalEvent[] = [];
  public eventSource: CalendarEvent[] = [];
  public schedules: CalSchedule[] = [];
  public selected: CalEvent = {};
  public selectedSchedule: CalSchedule = {};
  public selectedWeekSchedule: Workday[] = [];

  public tab1Active: boolean = true;
  public tab2Active: boolean = false;
  public showAddEventPanel: boolean = false;
  public showAddSchedulePanel: boolean = false;

  public loading: boolean = true;
  public lastChangedDate: string;
  public minDate: Date;
  public auditorName: string;
  public auditCount: number = 0;

  public viewDate: Date = new Date();
  public view: string = "month";

  // title displayed by the directive for the group of controls that it renders
  public currentInfo: any;

  public startDate: Date;
  public endDate: Date;

  public holidayDates: string[] = [];
  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private localize: LocalizeService,
              private api: ApiService,
              private current: CurrentInfoService,
              private dialog: MatDialog,
              private tools: ToolsService,
              private identity: IdentityService,
              private storage: StorageService,
              private log: LoggerService,
              private calendarService: WorkScheduleCalendarService,
              private dateInternationalize: DateInternationalizePipe,
              private notify: NotifyService) {
                this.currentInfo = this.current.info;
                this.getTitle();
                this.loading = false;
                this.init();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // user clicked on a day in our calendar view
  public onCalendarDayClick(date: any) {
    this.onAddButtonClick();
    this.clearSelected();
    this.selected.start = date;
    this.selected.end = date;
  }

  // user clicked an event in our calendar view
  public onCalendarEventClick(event: CalendarEvent) {
    if (!event.meta.isAudit) {
      const eventID: number = event.meta.id;
      this.showAddEventPanel = false;
      this.showEditPanelForEvent(eventID);
    }
  }

  public getTitle() {
    const id = +this.route.snapshot.params.id;
    const stateName = (this.route.snapshot.url.length > 1) ?
                      this.route.snapshot.url[1].path + "-" + this.route.snapshot.url[0].path :
                      this.route.snapshot.url[0].path;

    if ((stateName === "calendar" || stateName === "auditor-calendar" || this.route.routeConfig.path.includes("auditor-calendar")) && id) {
      this.calendarService.getUserByID(id).subscribe((data: IUser) => {
        if (data) {
          this.auditorName = data.FirstName + " " + data.LastName;
          const breadcrumbDescription = data.FirstName + " " + data.LastName + " " + this.localize.getLocalizedString("_Calendar_");
          this.title = this.localize.getLocalizedString("_WorkingDaysCalendarFor_") + " " + data.FirstName + " " + data.LastName;
        }
      });
    } else if (stateName === "location-calendar" && id) {
      this.title = this.localize.getLocalizedString("_WorkingDaysCalendar_");
    } else if (stateName === "site-calendar" || this.route.routeConfig.path.includes("site-calendar")) {
      this.calendarService.getSiteByID(id).subscribe((data: ISite) => {
        if (data) {
          const breadcrumbDescription = data.Name + " " + this.localize.getLocalizedString("_Calendar_");
        }
      });
      this.title = this.localize.getLocalizedString("_WorkingDaysCalendar_");
    }
  }

  // user clicked on the add button
  public onAddButtonClick() {
    this.hideAllEventListItemPanels();
    if (this.tab1Active === true) {
      this.clearSelected();
      this.showAddEventPanel = true;
    } else if (this.tab2Active === true) {
      this.clearSelected();
      this.showAddSchedulePanel = true;
    }
  }

  // utility methods
  public hideAllEventListItemPanels() {
    this.showEditPanelForEvent(-1);
  }

  public showEditPanelForEvent(id: number) {
    if (this.currentInfo.roleIsSiteAdmin) {
      this.events.forEach((event: CalEvent) => {
        const show: boolean = (event.id === id);
        event.showPanel = show;
        if (show) {
          this.selected = Object.assign({}, event);
          this.minDate = moment(this.selected.start).startOf("day").toDate();
        }
      }, this);
    }
  }

  // user clicked on the cancel button in the "Add event" panel
  public onAddEventCancelButtonClick() {
    this.showAddEventPanel = false;
    this.clearSelected();
  }

  public init() {
    // this function should only called 1 time, after the directive attribute data, and the current model data is available
    // directive attribute data is retrieved from the first call to the link function of the directive
    // current model info is has been ajaxloaded  (this data is not always available in the constructor!)

    if (this.calendarDirectiveIDParm) {  // if the directive passed us an argument for an ID to use - we use it...
      this.calendarEntityID = this.calendarDirectiveIDParm;
    } else { // otherwise, we reach out to the route params to figure out what to do...
      const stateAction = this.route.snapshot.params.action;
      const id = +this.route.snapshot.params.id;

      const stateName = (this.route.snapshot.url.length > 1) ?
                        this.route.snapshot.url[1].path + "-" + this.route.snapshot.url[0].path :
                        this.route.snapshot.url[0].path;
      if (stateAction && id) {
        this.calendarType = stateAction;
        this.calendarEntityID = id;
      } else if (stateName === "site-calendar") {
        this.calendarType = "site";
        this.calendarEntityID = id;
      } else if (stateName === "location-calendar") {
        this.calendarType = "location";
        this.calendarEntityID = id;
      } else if (stateName === "calendar" || stateName === "auditor-calendar" || this.route.routeConfig.path.includes("auditor-calendar")) {
        this.calendarType = "user";
        this.calendarEntityID = id;
      }

      if (this.calendarType === "site" && this.calendarEntityID !== this.currentInfo.activeSiteID) {
          // if the page url includes an id, then set the switcher to match the url
          this.notify.broadcast("request-token-refresh", { newSiteID: this.calendarEntityID });
      } else {
          if (this.calendarType === "site") {
            // if the page url doesn't include an id, then grab the ID from the current site switcher
            this.calendarEntityID = this.currentInfo.activeSiteID;
          } else if (this.calendarType === "user") {
            // if the page url doesn't include a user id, then grab the user id for the current user
            this.calendarEntityID = (id == null) ? this.currentInfo.userID : id;
          }
      }
    }

    this.getTitle();
    this.retrieveCalendarData();
  }

  public retrieveCalendarData() {
    // now that we know what calendar to show, go get it
    this.calendarService.getCalendarData(this.calendarType, this.calendarEntityID).subscribe((calendars: ICalendar[]) => {
      if (calendars.length > 0) {
        this.currentCalendar = null;
        if (calendars != null || calendars.length !== 0) {
          this.currentCalendar = calendars[0];
          this.getCalendarSchedules();
          this.getCalendarEvents();
        }
      }
    });
  }

  public getCalendarSchedules() {
    this.calendarService.getCalendarWorkSchedules(this.currentCalendar.ID).subscribe((data: ICalendarWorkSchedule[]) => {
      this.schedules.length = 0;
      data.forEach((val: ICalendarWorkSchedule) => {
        const o: CalSchedule = {
          id: val.ID,
          name: val.Name,
          start: moment(val.Start).format("YYYY-MM-DD"),
          end: moment(val.End).format("YYYY-MM-DD"),
          easeSchedule: val,
        };
        this.schedules.push(o);
        this.loading = false;
        this.reloadCalendar();
      });
    }, (err) => {
      this.loading = false;
    });
  }

  public getCalendarEvents() {
    const eventType = (this.calendarType === "user") ? CalendarEventType.EmployeeTimeOff : CalendarEventType.TimeOff; // 1==timeoff, 2==personaltimeoff
    this.calendarService.getCalendarEvents(this.calendarType, this.currentCalendar.ID, eventType, this.calendarEntityID)
    .subscribe((siteCalendarEvents: ICalendarEvent[]) => {
      this.events.length = 0;
      this.eventSource.length = 0;
      const holidayEvents = siteCalendarEvents.filter((event: any) => event.EventType === eventType);
      const auditEvents = siteCalendarEvents.filter((event: any) => event.EventType === CalendarEventType.Audit);
      holidayEvents.forEach((val: ICalendarEvent) => {
        val.Start = new Date(this.dateInternationalize.transform(val.Start));
        val.Due = new Date(this.dateInternationalize.transform(val.Due));

        const ce: CalEvent = {
          id: val.ID,
          title: val.Title,
          start: val.Start,
          end: val.Due,
          color: val.inheritedHoliday ? "#808080" : "#FD693A",   // set holiday background to orange (parent), gray (inherited holiday)
          easeCalEvent: val,
          isTimeOff: val.inheritedHoliday ? false : true,
          stick: true, // tell the calendar to store them instead of doing page (month) requests,
        };
        const cs: CalendarEvent<CalEvent> = {
          start: val.Start,
          end: val.Due,
          title: ce.title,
          color: {
              primary: ce.color,
              secondary: "#FAE3E3",
          },
          meta: ce,
        };
        this.events.push(ce);
        this.eventSource.push(cs);
      });

      if (this.calendarType === "user") {
        this.getAuditEvents(this.calendarType);
      } else if (this.calendarType === "location" || this.calendarType === "site") {
        this.displayAuditEvents(auditEvents);
      } else {
        this.reloadCalendar();
      }

      this.events = this.events.sort((a, b) => a.start.getTime() - b.start.getTime());
      this.loading = false;
    }, (err) => {
      this.loading = false;
    });
  }

  // the calendar directive is watching the configuration, changing it in any way will cause it to call 'calendarCtlRequestHandler' to reload the month
  public reloadCalendar() {
    const oldEventSource = Object.assign([], this.eventSource);
    this.eventSource = oldEventSource;
  }

  public removeTimeOffDialog(event: CalEvent): void {
    const config = new DialogConfigSm();
    const dialogRef = this.dialog.open(CalendarModalComponent, config);
    dialogRef.afterClosed().takeUntil(this.destroy$).subscribe(result => {
      if (result) {
        this.onEventListDeleteClick(event);
      }
    });
  }

  // user clicked on event row trash icon
  public onEventListDeleteClick(event: CalEvent) {
    this.hideAllEventListItemPanels();
    this.showAddEventPanel = false;
    this.clearSelected();
    this.calendarService.deleteCalendarEvent(event.id).subscribe(() => {
      this.getCalendarEvents();
    });
  }

  // user clicked on event row pencil icon
  public onEventListEditClick(event: CalEvent): void {
    if (event) {
      this.showEditPanelForEvent(event.id);
      this.showAddEventPanel = false;
      this.showAddSchedulePanel = false;
      this.selected = Object.assign({}, event);
    }
  }

  // user clicked on cancel in active row panel dialog
  public onEventListCancelEdit(event: CalEvent) {
    this.hideAllEventListItemPanels();
  }

  public addEventApplyValid(): boolean {
    const sel = this.selected as any;
    const s: boolean = (sel.start instanceof Date) || moment(sel.start, "YYYY-MM-DD", true).isValid();
    const e: boolean = (sel.end instanceof Date) || moment(sel.end, "YYYY-MM-DD", true).isValid();
    const ds: moment.Moment = (sel.start instanceof Date) ? moment(sel.start) : moment(sel.start, "YYYY-MM-DD");
    const de: moment.Moment = (sel.end instanceof Date) ? moment(sel.end) : moment(sel.end, "YYYY-MM-DD");
    const order: boolean = (ds.isBefore(de) || ds.isSame(de));
    const t: boolean = this.selected.title !== "";
    return (s && e && t && order);
  }

  public onAddEditEventClick(isAddEvent: boolean, event: CalEvent = null) {
    let hasAuditAssigned: boolean = false;
    let start: Date;
    let end: Date;

    if (isAddEvent) {
      start = this.selected.start;
      end = this.selected.end;
    } else {
      start = event.start;
      end = event.end;
    }

    if (this.calendarType === "user" || this.calendarType === "location" || this.calendarType === "site") {
      const getCount = this.calendarType === "location" || this.calendarType === "site";
      hasAuditAssigned = this.hasAssignedAudit(start, end, getCount);
    }

    if (this.calendarType === "user" && hasAuditAssigned) {
      this.loadReassignModal(start, end, isAddEvent, event);
    } else if ((this.calendarType === "location" || this.calendarType === "site") && hasAuditAssigned) {
      this.confirmHolidayModal(start, end, isAddEvent, event, this.auditCount, this.holidayDates);
    } else {
      if (isAddEvent) {
        this.onAddEventButtonClick();
      } else {
        this.onEventListApplyEdit(event);
      }
    }
  }

  // user clicked on apply in active row panel dialog
  public onEventListApplyEdit(event: CalEvent) {
    if (this.selected.easeCalEvent != null) {
      this.selected.easeCalEvent.Title = event.title;
      const start = moment(event.start);

      // Add one day so the end date can be the exact start (0:00h) of next day
      const end = moment(event.end).add("day", 1);

      const startDate = start.toDate();
      const endDate = end.toDate();

      // convert edited date based on site's time zone
      let sdt = new Date(0, 0, 0, 0, 0, 0, 0);
      sdt.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      let edt = new Date(0, 0, 0, 0, 0, 0, 0);
      edt.setFullYear(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

      if (!this.current.IsUsingSiteTimeZone()) {
        sdt = this.current.SiteDateInUTC(sdt);
        edt = this.current.SiteDateInUTC(edt);
      }

      this.selected.easeCalEvent.Start = sdt;
      this.selected.easeCalEvent.Due = edt.addSeconds(-1);

      this.calendarService.updateCalendarEvent(this.selected.easeCalEvent).subscribe(() => {
        this.hideAllEventListItemPanels();
        this.clearSelected();
        this.getCalendarEvents();
      });
    }
  }

  // user clicked on the add button in the "Add event" panel
  public onAddEventButtonClick() {
    let length: number = 1;
    let mend: moment.Moment = moment(this.selected.end);
    let sdt = new Date(0, 0, 0, 0, 0, 0, 0);

    if (mend.isValid()) {
      let mstart: moment.Moment = moment(this.selected.start);

      const startDate = mstart.toDate();
      const endDate = mend.toDate();

      // convert edited date based on site's time zone
      sdt.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      let edt = new Date(0, 0, 0, 0, 0, 0, 0);
      edt.setFullYear(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

      if (!this.current.IsUsingSiteTimeZone()) {
        sdt = this.current.SiteDateInUTC(sdt);
        edt = this.current.SiteDateInUTC(edt);
      }

      mstart = moment(sdt);
      mend = moment(edt);
      if (mstart.isValid()) {
        length = mend.diff(mstart, "days") + 1;
      }
    }

    const eventType: number = (this.calendarType === "user") ? CalendarEventType.EmployeeTimeOff : CalendarEventType.TimeOff; // 1==timeoff, 2==personaltimeoff

    const calendarSeries: ICalendarSeries = {
      ID: null,
      CalendarID: this.currentCalendar.ID,
      Title: this.selected.title,
      Description: "",
      Scale: 1,
      Length: length,
      EventType: eventType,
      AllDay: true,
      OrganizationID: null,
      SeriesRepeatScale: 0,
      SeriesRepeatInterval: 0,
      SeriesRepeatLimitCount: 0,
      SeriesRepeatLimitDate: null,
      SeriesStart: sdt,
    };

    this.calendarService.addCalendarSeries(calendarSeries).subscribe((id: number) => {
      this.getCalendarEvents();
      this.clearSelected();
    });
    this.saveCalendarSeries(calendarSeries);
    this.showAddEventPanel = false;
    this.clearSelected();
  }

  public onStartDateChange() {
    this.lastChangedDate = "start";
    this.validateDateRange();
    this.minDate = this.selected.start;
  }

  public onEndDateChange() {
    this.lastChangedDate = "end";
    this.validateDateRange();
  }

  private clearSelected() {
    this.selected = {
      id: null,
      title: "",
      start: null,
      end: null,
      easeCalEvent: null,
    };

    const emptySchedule: ICalendarWorkSchedule =  {
      ID: null,
      CalendarID: null,
      OrganizationID: null,
      Name: "",
      Start: null,
      End: null,
      WorkingDays: [0, 8, 8, 8, 8, 8, 0],
      DaysInCycle: 7,
    };

    this.selectedSchedule = {
      id: null,
      name: "",
      start: "",
      end: "",
      easeSchedule: emptySchedule,
    };
  }

  private getAuditEvents(calendarType: string) {
    this.calendarService.getAuditEventsByUserID(this.calendarEntityID).subscribe((data: IClientAudit[]) => {
      const auditEvents: any[] = [];
      data.forEach((audit: IClientAudit) => {
        const event = audit.CalendarEvent;
        if (event != null) {
          auditEvents.push(event);
        }
      });

      this.displayAuditEvents(auditEvents);
    });
  }

  private displayAuditEvents(allAuditEvents: ICalendarEvent[]) {
    const auditEvents: CalEvent[] = [];
    allAuditEvents.forEach((event: ICalendarEvent) => {
      if (event != null) {
        event.Start = new Date(this.dateInternationalize.transform(event.Due));
        event.Due = new Date(this.dateInternationalize.transform(event.Due));

        const start = moment(event.Start).format("YYYY-MM-DD");
        const filtered: CalEvent[] = auditEvents.filter((auditEvent) => auditEvent.start === event.Start);
        if (filtered.length === 0) {
          const ce: CalEvent = {
            id: event.ID,
            title: "1",
            start: event.Start,
            end: event.Due,
            color: "#5B90BF",
            easeCalEvent: event,
            isTimeOff: false,
            epochDate: event.Due.epochDate(),
            stick: true, // tell the calendar to store them instead of doing page (month) requests,
          };
          auditEvents.push(ce);
        } else {
          const w = parseInt(filtered[0].title, 10) + 1;
          filtered[0].title = w.toString();
        }
      }
    });

    auditEvents.forEach((data: CalEvent) => {
      const key = (parseInt(data.title, 10) > 1) ? "_XAuditsDue_" : "_XAuditDue_";
      const filtered: CalEvent[] = this.events.filter((event) => event.id === data.id);
      if (filtered.length === 0) {
        const ce: CalEvent = {
          id: data.id,
          title: this.localize.getLocalizedString(key, [data.title]),
          start: data.start,
          end: data.end,
          color: "#5B90BF",
          easeCalEvent: data as any,
          isTimeOff: false,
          epochDate: data.epochDate,
          stick: true, // tell the calendar to store them instead of doing page (month) requests,
          isAudit: true,
        };
        const cs: CalendarEvent<CalEvent> = {
          start: new Date(ce.start),
          end: new Date(ce.end),
          title: ce.title,
          color: {
              primary: "#5B90BF",
              secondary: "#FAE3E3",
          },
          meta: ce,
        };
        this.eventSource.push(cs);
      }
    });
    this.reloadCalendar();
  }

  private saveCalendarSeries(calendarSeries: ICalendarSeries, callback?: any): void {
    const key: StorageKey = {
      login: this.current.info.user.Login,
      hostName: this.tools.extractHost(this.identity.endpoints.Service),
      entityName: "CalendarSeries",
    };

    this.storage.storeObject(key, calendarSeries).takeUntil(this.destroy$).subscribe(() => {
      if (callback) { callback(); }
    }, (err: any) => {
      this.log.error("error in saveCalendarSeries " + JSON.stringify(err));
      if (callback) { callback(); }
    });
  }

  private hasAssignedAudit(start: Date, end: Date, isGetCount: boolean = false): boolean {
    let hasAudit = false;
    let totalAudits = 0;
    const holidays = [];

    const from = moment(start).toDate();
    const to = moment(end).toDate();

    let counter: number = 0;
    const filtered: CalendarEvent[] = this.eventSource.filter((event) => event.meta.isAudit && !event.meta.isTimeOff);
    for (counter = 0; counter <= filtered.length - 1; counter++) {
      const auditDue = filtered[counter].meta.epochDate;
      if (auditDue >= from.epochDate() && auditDue <= to.epochDate()) {
          hasAudit = true;
          if (isGetCount) {
              totalAudits += parseInt((filtered[counter].meta.easeCalEvent as CalEvent).title, 10);
              holidays.push(moment(filtered[counter].start).format("MM/DD/YYYY"));
          } else {
              break;
          }
      }
    }

    this.auditCount = totalAudits;
    const uniqueHolidayDates = Array.from(new Set(holidays)); // get unique holiday dates
    this.holidayDates = uniqueHolidayDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    return hasAudit;
  }

  private loadReassignModal(start: Date, end: Date, isAddEvent: boolean, event: CalEvent = null): void {
    const selected = {
      auditorID: this.calendarEntityID,
      auditorName: this.auditorName,
      start,
      end,
    };

    const config = new DialogConfigMd();
    config.data = { selected };
    const dialogRef = this.dialog.open(AuditReassignModalComponent, config);

    dialogRef.afterClosed().takeUntil(this.destroy$).subscribe(result => {
      // after unassigning/deleting/reassigning audits then add/edit timeoff event
      if (result !== "") {
        if (isAddEvent) {
          this.onAddEventButtonClick();
        } else {
          this.onEventListApplyEdit(event);
        }
      }
    });
  }

  private confirmHolidayModal(start: Date, end: Date, isAddEvent: boolean, event: CalEvent = null, auditCount: number = 0, holidayDates: string[] = []): void {
    const selected = {
      start,
      end,
      locationID: this.calendarEntityID,
      totalAudits: auditCount,
      holidayDates,
      isSiteCalendar: this.calendarType === "site",
    };

    const config = new DialogConfigLg();
    config.data = { selected };

    const dialogRef = this.dialog.open(ConfirmHolidayModalComponent, config);
    dialogRef.afterClosed().takeUntil(this.destroy$).subscribe((isSave: boolean) => {
      if (isSave) {
        if (isAddEvent) {
          this.onAddEventButtonClick();
        } else {
          this.onEventListApplyEdit(event);
        }
      }
    });
  }

  private validateDateRange(): void {
    const start = moment(this.selected.start);
    const end = moment(this.selected.end);
    const days = end.diff(start, "days");

    if (days < 0) {
      this.resetDate(true);
    } else if (days > 365) {
      this.resetDate();
    }
  }

  private resetDate(isStartDateGreater: boolean = false): void {
    if (this.lastChangedDate === "start") {
      if (isStartDateGreater) {
        this.selected.end = null;
      } else {
        this.selected.start = null;
      }
    } else if (this.lastChangedDate === "end") {
      if (isStartDateGreater) {
        this.selected.start = null;
      } else {
        this.selected.end = null;
      }
    }
  }
}
