import {IClientMitigationActivity} from "../../core/models/ease-models";
import {Injectable} from "@angular/core";
import {LocalizeService} from "../../core/services/localize.service";
@Injectable()
export class MitigationStatusService {
    constructor(private localize: LocalizeService) {}

    // if this method is updated, server side NotifyMitigationEscalation() method from AuditBackgroundWorker.cs
    // should also be updated
    public getStatusLabel(mitigation: IClientMitigationActivity, isCurrentUserView?: boolean): string {
        let statusString = "";

        statusString = (mitigation.PastDue) ? this.localize.getLocalizedString("_PastDue_") :
            this.localize.getLocalizedString("_OnTime_");

        switch (mitigation.Status) {
            case 0:
                statusString += " - " + this.localize.getLocalizedString("_Open_");
                break;
            case 1:
                statusString += " - " + this.localize.getLocalizedString("_Closed_");
                break;
            case 2:
                statusString += " - " + ((isCurrentUserView) ? this.localize.getLocalizedString("_PendingApproval_")
                    : this.localize.getLocalizedString("_Pending_"));
                break;
        }

        return statusString;
    }
}