// Dev note: Scaled down version of the audit list, only used in modal displays

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from "util";
// Services
import { ApiService, LocalService, NotifyService } from "@core/services";
import { ListViewBase } from "@shared/list-view/list-view-base";
// Types
import { IAuditReport } from "@core/models/ease-models";

@Component({
  templateUrl: "./audit-list.component.html",
  selector: "ease-audit-list",
  styleUrls: ["audit-list.component.scss"],
})
export class AuditListComponent extends ListViewBase implements OnInit {

  public loading: boolean = true;
  public audits: IAuditReport[];
  public isCheckedAll: boolean = false;

  @Input() public completedOnly: boolean = false;
  @Input() public showIdColumn: boolean = false;
  @Input() public showAuditTypeColumn: boolean = false;
  @Input() public showDimensionColumns: boolean = true;
  @Input() public filterByAuditIDs: number[] = []; // IDs to filter down our result set to
  @Input() public selectedAuditIDs: number[] = []; // Ids to auto select

  @Input() public isSelectable: boolean = false;
  @Input() public isReassign: boolean = false;
  @Input() public auditTypeID: number = 0;
  @Input() public showDueDate: boolean = false;
  @Input() public showSearchInput: boolean = false;
  @Input() public showDateRange: boolean = false;

  @Output() public selectedChanged = new EventEmitter<IAuditReport[]>();
  @Output() public auditClicked = new EventEmitter<number>();
  @Output() public loadingComplete = new EventEmitter<boolean>();

  constructor(public api: ApiService,
              public notify: NotifyService,
              public local: LocalService,
              public route: ActivatedRoute) {

    super(api, notify, local, route, null);
  }

  public ngOnInit() {
    if (isNullOrUndefined(this.filterByAuditIDs)) {
      this.filterByAuditIDs = [];
    }

    const params: any = {
      querycols: "Name|Location|Auditor",
      NoCache: true,
      FilterByIDs: this.filterByAuditIDs,
      ReassignAudit: this.isReassign,
    }

    if (this.completedOnly) {
      params.Status = "1|4";
    }
    if (this.showIdColumn) {
      params.querycols += "|ID";
    }

    const stats = "AuditorID|LocationID|LayerID|ShiftID|Results";

    if (this.auditTypeID !== 0) {
      params.TemplateTypeID = this.auditTypeID;
    }
    this.initialize("auditreport",
      params,
      stats,
      "Name", "audit-list", (data: any) => {

        this.audits = data;
        this.loading = false;

        this.initSelectedAuditIDs();
        this.loadingComplete.emit(true);
      });
  }

  public initSelectedAuditIDs() {
    if (this.selectedAuditIDs.length > 0) {
      this.audits.forEach((audit) => {
        if (this.selectedAuditIDs.indexOf(audit.ID) > -1) {
          audit.IsSelected = true;
        }
      });
      this.selectAuditHandler();
    }
  }

  public toggleCheckAllHandler() {
    this.isCheckedAll = !this.isCheckedAll;
    this.audits.forEach(a => a.IsSelected = this.isCheckedAll);
    this.selectAuditHandler();
  }

  public selectAuditHandler() {
    this.selectedChanged.emit(this.audits.filter(a => a.IsSelected === true));
  }

  public auditClickHandler(auditID: number) {
    this.auditClicked.emit(auditID);
  }
}
