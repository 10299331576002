import { Component } from "@angular/core";

import { Store, select } from "@ngrx/store";
import * as fromAuth from "../reducers";
import * as AuthActions from "../actions/auth";

import { RequestPassword } from "../models/user";

@Component({
  selector: "ease-request-restore",
  template: `
    <ease-auth-page>
      <ease-request-restore-form (easeSubmitted)="onSubmitted($event)"
                                 [easeError]="error$ | async"
                                 [easePending]="pending$ | async">
      </ease-request-restore-form>
    </ease-auth-page>
  `,
  styles: [ `
  ` ],
})
export class RequestRestoreComponent {
  pending$ = this.store.pipe(select(fromAuth.getRequestRestorePagePending));
  error$ = this.store.pipe(select(fromAuth.getRequestRestorePageError));

  constructor(private store: Store<fromAuth.State>) {
  }

  onSubmitted($event: RequestPassword) {
    const location = window.location;
    const email = $event.email;
    const callbackUrl = `${location.origin}${location.pathname}#/change-password`;

    this.store.dispatch(new AuthActions.RequestRestore({ callbackUrl, email }));
  }
}
