import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";

// Components
import { BreadcrumbComponent } from "../breadcrumb.component";

// Services
import { CurrentInfoService } from "@core/services";

@Component({
  selector: "ease-breadcrumb-user-calendar",
  template: `
    <ease-breadcrumb-base-item [loading]="false">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        {{ user.firstName }} {{ user.lastName }} {{ '_BreadcrumbCalendar_' | i18n }}
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbUserCalendarComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  details$: Observable<object>;
  user;
  placeholder = "_BreadcrumbCalendarLoading_";

  constructor(
    private current: CurrentInfoService,
  ) {}

  ngOnInit() {
    this.user = {
      firstName: this.current.info.user.FirstName,
      lastName: this.current.info.user.LastName,
    };
  }
}
