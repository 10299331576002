import { filter,  tap } from "rxjs/operators";

import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router, RouterEvent, NavigationEnd, UrlSegmentGroup, UrlSegment } from "@angular/router";

import { BreadcrumbComponent } from "./breadcrumb.component";
import { BreadcrumbHostDirective } from "./breadcrumbs-host.directive";

import { BreadcrumbItem } from "./breadcrumb.class";
import { Subject } from "rxjs";

@Component({
  selector: "ease-breadcrumbs",
  template: `<ng-template breadcrumb-host></ng-template>`,
  styleUrls: [ "./breadcrumbs.component.scss" ],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @ViewChild(BreadcrumbHostDirective) breadcrumbHost: BreadcrumbHostDirective;
  hostViewContainerRef;
  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      tap(_ => this.hostViewContainerRef.clear()),
    )
      .takeUntil(this.destroy$).subscribe(_ => {
        this.buildBreadcrumbs();
      });
  }

  ngOnInit() {
    this.hostViewContainerRef = this.breadcrumbHost.viewContainerRef;
    this.buildBreadcrumbs();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  buildBreadcrumbs() {
    let route = this.route.snapshot.root;
    let link = "";

    while (route) {
      if (route.children && route.children.length) {

        route = route.children[ 0 ];

        if (route.data && route.data[ "breadcrumb" ]) {
          const breadcrumb = route.data[ "breadcrumb" ];
          route.url.map(segment => {
            link += "/" + segment.path;
          });

          let componentFactory;
          let routeParams;

          if (typeof breadcrumb === "string") {
            componentFactory = this.componentFactoryResolver.resolveComponentFactory((new BreadcrumbItem()).component);
            const componentRef = this.hostViewContainerRef.createComponent(componentFactory);
            (componentRef.instance as BreadcrumbComponent).data = { title: breadcrumb, url: link };
          } else {
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(breadcrumb.component);
            const componentRef = this.hostViewContainerRef.createComponent(componentFactory);
            if (route.params) {
              routeParams = route.params;
            }
            (componentRef.instance as BreadcrumbComponent).data = {
              params: routeParams,
              url: link,
              type: breadcrumb.type,
            };
          }
        } else {
          return;
        }
      } else {
        return;
      }
    }
  }

  navigateBack() {
    this.location.back();
  }
}
