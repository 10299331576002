import { Routes } from "@angular/router";

import { AuthGuard } from "app/auth/services/auth-guard.service";
import { Ease1ToEase2MigrationGuard } from "@core/guards/Ease1ToEase2MigrationGuard";
import { SystemAdminModule } from "@app/system-admin/system-admin.module";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "admin",
    loadChildren: "./admin/admin.module#AdminModule",
    data: {
      breadcrumb: "_BreadcrumbAdmin_",
    },
  },
  {
    path: "manage",
    loadChildren: "./manage/manage.module#ManageModule",
    data: {
      breadcrumb: "_BreadcrumbManage_",
      disableSiteSelector: true,
    },
  },
  {
    path: "assign",
    loadChildren: "./assign/assign.module#AssignModule",
    data: {
      breadcrumb: "_BreadcrumbAssign_",
    },
  },
  {
    path: "report",
    loadChildren: "./report/report.module#ReportModule",
    data: {
      breadcrumb: "_BreadcrumbReport_",
    },
  },
  {
    path: "corrective-actions",
    loadChildren: "./capa/capa.module#CapaModule",
    data: {
      breadcrumb: "_BreadcrumbReport_",
    },
  },
  {
    path: "system-admin",
    loadChildren: "./system-admin/system-admin.module#SystemAdminModule",
    data: {
      breadcrumb: "_BreadcrumbSystemAdmin_",
    },
  },
  // {
  //   path: "config",
  //   loadChildren: "./config/config.module#ConfigModule",
  // },
  // {
  //   path: "settings",
  //   loadChildren: "./settings/settings.module#SettingsModule",
  // },
  // { path: "sandbox", component: SandboxComponent, canActivate: [AuthGuard], data: { stripType: "" } },
  {
    path: "**",
    redirectTo: "",
    canActivate: [
      Ease1ToEase2MigrationGuard,
      AuthGuard,
    ],
  },
];
