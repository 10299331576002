import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class Ease1ToEase2MigrationGuard implements CanActivate {
  private adminPages = [ "scope", "shift", "department", "user" ];

  private pagesMapping = {
    "organization/details": "/admin/organization/orgtree",
    "orglayer": "/admin/organization/layers",

    "audit/create": "/home/audit-create",
    "audit/enter": "/home/audit-enterstart",
    "audit/results/": "/home/audit-history/audit/{id}",
    "audit/entry/": "/home/conduct/{id}",
    "audit/conduct/": "/home/conduct/{id}",
    "audit/complete/": "/home/audit/complete/{id}",
    "calendar/auditor/": "/home/auditor-calendar/{id}",
    "auditschedule": "/assign/audits",
    "history": "/home/audit-history",
    
    "mitigation/details/": "/report/open-mitigations/mitigation/{id}",
    "capatask/": "/report/corrective-actions/{id}/task",
    "capa/": "/report/corrective-actions/{id}/event",

    "auditdocument/questions/": "/manage/content/documents/audit/{id}/questions",
    "auditdocument/settings/": "/manage/content/documents/audit/{id}/settings",
    "customgroups/details/": "/manage/content/groups/{id}",
    "document/questionlibrary": "/manage/content/questions",
    "customgroups/itemlist": "/manage/content/items",
    "customGroups": "/manage/content/groups",
    "document": "/manage/content/documents",
    "tags": "/manage/content/tags",
  };

  constructor(private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = document.location.href;

    for (const key in this.pagesMapping) {
      if (this.pagesMapping.hasOwnProperty(key)) {
        const index = url.indexOf(key);
        if (index > 0) {
          if (route.queryParams) {
            url = url.split("?")[0];
          }

          const id = url.substr(index + key.length);
          const newPath = this.pagesMapping[key].replace("{id}", id);

          this.router.navigate([ newPath ], { queryParams: route.queryParams });
          return false;
        }
      }
    }

    for (const adminPage of this.adminPages) {
      if (url.indexOf(adminPage) > 0) {
        this.router.navigate([ `admin/organization/${adminPage}s` ], { queryParams: route.queryParams });
        return false;
      }
    }

    return false;
  }
}
