import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { DialogConfigSm, InputDialogComponentData } from "app/shared/dialogs";

import { CompanySiteService } from "./company-site.service";
import { CompanyService } from "@app/customer/companies/company.service";
import { UiNotificationService } from "app/core/services/common/ui-notification.service";

import { ICompanySite, ICompany, IAddress } from "app/core/models/ease-models";
import { InputDialogComponent } from "app/shared/dialogs/input-dialog/input-dialog.component";

@Component({
  templateUrl: "./company-site-detail-modal.component.html",
  styleUrls: ["company-site-detail-modal.component.scss"],
})

export class CompanySiteDetailModalComponent implements OnInit {

  public isLoading = true;
  public isSaving: boolean = false;
  public companies: ICompany[];
  public isCustomer: boolean = false;
  public isSupplier: boolean = false;
  public hasSelectedDivision: boolean = false;
  public companySite: ICompanySite;
  public companyName: string = "";

  public companySiteData: ICompanySite;
  public address: IAddress = this.address = {
    Address1: "",
    Address2: "",
    PostalCode: "",
    State: "",
    City: "",
    ID: -1,
  };
  private listType: string;
  private addMode: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CompanySiteDetailModalComponent>,
    private dialog: MatDialog,
    private companySiteService: CompanySiteService,
    private companyService: CompanyService,
    private uiNotify: UiNotificationService) {
  }

  ngOnInit() {
    this.listType = this.data.listType;
    this.isCustomer = this.listType === "customer" || this.listType === "adHocCustomer";
    this.isSupplier = this.listType === "supplier" || this.listType === "adHocSupplier";

    this.addMode = this.data.addMode;

    if (this.data.ICompanySite) {
      this.companySiteData = this.data.ICompanySite;
      if (this.data.ICompanySite.Address) {
        this.address = this.data.ICompanySite.Address;
      }
    } else {
      this.companySiteData = {
        ID: -1,
        OrganizationID: null,
        SiteID: null,
        CompanyID: null,
        Name: "",
        IsSupplier: this.isSupplier,
        IsCustomer: this.isCustomer,
        ContactID: -1,
        Company: {
          Name: "",
          ID: -1,
        },
      };
      this.isLoading = false;
    }

    if (this.data.company) {
      this.companySiteData.CompanyID = this.data.company.ID;
      this.companySiteData.Company = this.data.company;
    }

    if (this.listType !== null) {
      this.getCompanyList();
    }

    this.onDivisionChange();
  }

  public onDivisionChange() {
    this.hasSelectedDivision = (this.companySiteData.IsCustomer || this.companySiteData.IsSupplier) ? true : false;
  }

  public getCompanyList(): void {
    this.companyService.getCompanies({ detail: true })
      .subscribe((data: ICompany[]) => {
        this.companies = data;
      });
  }

  public save() {
    this.validateData();
  }

  public closeModal() {
    this.dialogRef.close(false);
  }

  public createCompany(newCompanyName: string): void {
    const newCompany: ICompany = { ID: 0, Name: newCompanyName };

    this.companyService.add(newCompany)
        .subscribe((companyID: number) => {
            this.uiNotify.open("_CompanySaved_");
            this.getCompanyList();
            this.companySiteData.CompanyID = companyID;
    });
  }

  public openAddCompanyNameDialog(): void {
    const config = new DialogConfigSm<InputDialogComponentData>();
    config.data = {
      title: "_CreateCompany_",
      label: "_CompanyName_",
      required: true,
    };
    const dialogRef = this.dialog.open(InputDialogComponent, config);

    dialogRef.afterClosed().subscribe(results => {
        if (results) {
            this.checkCompanyName(results);
        }
      });

  }

  public checkCompanyName(newCompanyName: string) {
    this.companyService.getCompanyByName(newCompanyName)
        .subscribe((companies: ICompany[]) => {
            if (companies.length > 0) {
                this.uiNotify.open("_CompanyNameAlreadyUsed_");
                this.openAddCompanyNameDialog();
            } else {
               this.createCompany(newCompanyName);
            }
        });
  }

  private validateData(): void {
    this.companySiteService.getCompanySitesByDetail({
      detail: false, companyID: this.companySiteData.CompanyID, name: this.companySiteData.Name})
      .subscribe((data: ICompanySite[]) => {
        if (data == null || data.length === 0) {
          this.saveCompanySite();
        } else {
          if (data && data.length > 0 && data[0].ID !== this.companySiteData.ID) {
            this.uiNotify.open("_CompanySiteAlreadyUsed_");
          } else {
            this.saveCompanySite();
          }
        }
      });
  }

  private setAddressDefaults(address: IAddress): void {
    if (address !== null) {
      let strValue = address.Address1;
      address.Address1 = (strValue == null || undefined) ? "" : strValue;
      strValue = address.Address2;
      address.Address2 = (strValue == null || undefined) ? "" : strValue;
      strValue = address.City;
      address.City = (strValue == null || undefined) ? "" : strValue;
      strValue = address.State;
      address.State = (strValue == null || undefined) ? "" : strValue;
      strValue = address.PostalCode;
      address.PostalCode = (strValue == null || undefined) ? "" : strValue;

      this.companySiteData.Address
        = (address.Address1 === "" && address.Address2 === "" && address.City === "" && address.State === "" && address.PostalCode === "" && address.ID < 0)
         ? null : JSON.parse(JSON.stringify(address));
    }
  }

  private saveCompanySite(): void {
    this.setAddressDefaults(this.address);
    if (this.addMode) {
      this.companySiteService.add(this.companySiteData)
      .subscribe((companySiteID: number) => {
          this.onCompanySiteSaved();
      });
    } else {
      this.companySiteService.update(this.companySiteData.ID, this.companySiteData)
      .subscribe(() => {
          this.onCompanySiteSaved();
      });
    }
  }

  private onCompanySiteSaved(): void {
    this.uiNotify.open("_CompanySaved_");
    this.dialogRef.close(true);
  }
}
