import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: "ease-image-dialog",
    templateUrl: "./image-dialog.component.html",
})

export class ImageDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

    }
}