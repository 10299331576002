// Just a set of shared helper functions for opening up modal dialogs

import {Injectable} from "@angular/core";
import {DetailedImageModalComponent} from "./detailed-image-modal.component";
import {MatDialog} from "@angular/material";
import {DialogConfigLg, DialogConfigSm} from "../shared/dialogs";

@Injectable()
export class DialogManagerService {

    constructor(private dialog: MatDialog) {

    }

    public showDetailedImageDlg(imgUrl: string) {
        const config = new DialogConfigSm();
        config.data = { detailedAuditImage: imgUrl };
        config.panelClass = "img-dialog";

        const dialogRef = this.dialog.open(DetailedImageModalComponent, config);
        dialogRef.afterClosed().subscribe(auditList => {
            // add here code to execute after the modal is closed
        });
    }
}
