import { Component, Input, OnDestroy } from "@angular/core";
import { NotifyService } from "@core/services";
import { Subject } from "rxjs";

@Component({
  selector: "ease-dialog",
  template: `
    <mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>
    <ng-content></ng-content>
  `,
  styles: [
      `
      :host {
        display: block;
      }

      .mat-progress-bar {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
      }
    `,
  ],
})
export class DialogComponent implements OnDestroy {
  @Input() loading: boolean;

  private destroy$: Subject<boolean> = new Subject();

  // Todo: Once we integrate rxjs store, we can revisit this.  For now, this is a good user experience
  constructor(private notify: NotifyService) {
    this.notify.on("full-page-block")
      .takeUntil(this.destroy$).subscribe((data: boolean) => {
      this.loading = data;
    });
  }
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
