import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// State
import { Store } from "@ngrx/store";
import * as fromRoot from "app/reducers";

import {
  IdentityService,
  NotifyService,
  ToolsService,
  AppSettingsService,
  CurrentInfoService,
  LoggerService,
} from "@core/services";

import { AppAbstract } from "./app.class";

@Component({
  selector: "ease-beacon",
  template: `
    <mat-progress-bar *ngIf="progress$ | async" mode="indeterminate" color="accent"></mat-progress-bar>
    <div *ngIf="appIsReady$ | async; else elseBlock">
      <ease-app-container></ease-app-container>
    </div>
    <ng-template #elseBlock>
      <div class="app-preloader">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 55 40" width="55" height="40" preserveAspectRatio="xMidYMid meet">

          <path d="M43.37 20.59C43.37 15.84 41.23 10.82 36.5 7.88C36.3 8.31 34.68 11.78 34.48 12.21C36.68 14.04 38.11 16.76 38.11 20.34C38.11 23.78 36.77 26.53 34.48 28.38C34.68 28.8 36.26 32.2 36.46 32.62C40.77 30.03 43.37 25.72 43.37 20.59" fill="#f46c26"></path>
          <path d="M18.12 12.13C16.91 9.56 16.25 8.14 16.11 7.85C11.72 10.5 9.2 14.94 9.2 20.3C9.2 25.24 11.55 29.85 16.08 32.59C16.27 32.17 17.87 28.75 18.07 28.32C15.88 26.55 14.52 23.87 14.52 20.28C14.52 16.73 15.9 13.99 18.12 12.13" fill="#f46c26"></path>
          <path d="M5.28 20.37C5.28 12.75 8.85 7.18 14.31 3.99C14.13 3.61 12.7 0.57 12.53 0.18C4.61 4.07 0 11.38 0 20.36C0 28.62 4.26 36.31 12.46 40.32C12.64 39.93 14.07 36.87 14.25 36.48C8.83 33.49 5.28 28.1 5.28 20.37" fill="#f46c26"></path>
          <path d="M40.13 0C39.02 2.38 38.4 3.71 38.27 3.97C43.66 7.09 47.38 12.56 47.38 20.28C47.38 27.62 43.96 33.23 38.28 36.39C38.45 36.77 39.87 39.79 40.05 40.17C47.79 36.35 52.58 29.25 52.58 20.62C52.58 12.72 48.74 4.29 40.13 0" fill="#f46c26"></path>
        </svg>
      </div>
    </ng-template>
  `,
  styleUrls: [`./app.scss`],
})

export class AppComponent extends AppAbstract implements OnInit {
    constructor(protected store: Store<fromRoot.State>,
                private log: LoggerService,
                private tools: ToolsService,
                protected notify: NotifyService,
                private router: Router,
                private current: CurrentInfoService,
                private appSettings: AppSettingsService,
                private identity: IdentityService,
                private zone: NgZone) {
      super(store, notify);
    }

    public ngOnInit() {
        this.log.debug("app: no cordova, running as a web app. manually triggering deviceready...");
        window.setTimeout(() => this.onDeviceReady(), 0);
    }

    public onDeviceReady() {
        this.log.debug('app: broadcasting "deviceready"');
        this.zone.run(() => { this.notify.ready.value = true; });

        const w: any = window;
        window.addEventListener("online", () => {
            this.log.debug('app: broadcasting "online"');
            this.zone.run(() => { this.notify.online.value = true; });
        });
        window.addEventListener("offline", () => {
            this.log.debug('app: broadcasting "not online"');
            this.zone.run(() => { this.notify.online.value = false; });
        });
        window.addEventListener("pause", () => {
            this.log.debug('app: broadcasting "paused"');
            this.zone.run(() => { this.notify.paused.value = true; });
        });
        window.addEventListener("resume", () => {
            this.log.debug('app: broadcasting "resumed"');
            this.zone.run(() => { this.notify.paused.value = false; });
        });

        // TJP_FIXME: ugly differences between cordvova and web.  revist this.
        window.setTimeout(() => {
            this.zone.run(() => {
                this.log.debug('app: manually triggering the broadcast of "filesystemReady=true"');
                this.notify.filesystemReady.value = true;
                this.log.debug('app: manually triggering the broadcast of "online=' + navigator.onLine + '"');
                this.notify.online.value = navigator.onLine;
            });
        }, 0);
    }
}
