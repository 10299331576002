import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import { ColumnSettingsService } from "../../../core/services/common/column-settings.service";
import { UiNotificationService } from "../../../core/services/common/ui-notification.service";
import { ApiService } from "../../../core/services/api.service";
import { LocalService } from "../../../core/services/local.service";
import { LocalizeService } from "../../../core/services/localize.service";
import { NotifyService } from "../../../core/services/notify.service";
import { ListViewBase } from "../../../shared/list-view/list-view-base";
import { ListViewStorageService } from "../../../shared/list-view/list-view-storage.service";
import {CurrentInfoService} from "../../../core/services/current.service";
import {ConfirmationDialogService} from "../../../shared/confirmation/confirmation-dialog.service";
import {MatDialog} from "@angular/material";
import {ConfirmationModalComponent} from "../../../shared/confirmation/confirmation-modal.component";
import {IClientCapaEvent} from "../../../core/models/ease-models";
import {AddCApaEventModalComponent} from "../../dialogs/add-capa-event/add-capa-event-modal.component";
import {DialogConfigMd} from "../../../shared/dialogs";
import { Subject } from "rxjs";

@Component({
    selector: "ease-capa-list",
    templateUrl: "./corrective-actions.component.html",
})
export class CorrectiveActionsListComponent extends ListViewBase implements OnInit, OnDestroy {
    @Input() ids: number[];
    @Output() public detailsClicked = new EventEmitter();
    public isFilteredByIDs: boolean = false;
    public loading: boolean = false;
    public listViewOption = "_CapaEventList_";
    public gridColumnItems: any;
    public isCapaEventNameVisible: boolean = true;
    public isDocumentNameVisible: boolean = true;
    public isIDVisible: boolean = true;
    public isSiteVisible: boolean = true;
    public isDueDateVisible: boolean = true;
    public isDateOpenedVisible: boolean = false;
    public isCapaOwnerVisible: boolean = true;
    public isCapaApproverVisible: boolean = false;
    public isStatusVisible: boolean = true;
    public isSeverityVisible: boolean = true;
    public isEscapePointVisible: boolean = false;
    public isEscapeValueVisible: boolean = false;
    public isDateClosedVisible: boolean = false;
    public isPercentCompleteVisible: boolean = false;
    public showAddCapaButton: boolean = false;

    public correctiveActions: any[];

    public visibleColumnsCount: number;
    private destroy$ = new Subject<void>();

    // classes which use export functionality need to import LocalizeService
    constructor(public api: ApiService, public notify: NotifyService, public local: LocalService,
                public localize: LocalizeService, public route: ActivatedRoute,
                private columnSettingService: ColumnSettingsService,
                private uiNotify: UiNotificationService, public listViewStorageService: ListViewStorageService,
                private router: Router, private current: CurrentInfoService, private dialog: MatDialog) {

        super(api, notify, local, route, listViewStorageService);
    }

    public ngOnInit() {
      if (this.ids && this.ids.length) {
        this.isFilteredByIDs = true;
        this.initialize("capaeventlist", {querycols: "CapaEventName", FilterByIDs: this.ids.join(",")},
          "ID|DocumentName|SiteID|CapaStatus|CapaOwnersID|CapaApproversID|StatusString|SeverityCustomItemID|EscapePointCustomItemID|EscapeValueCustomItemID", "ID", "capa-list", (data: any) => {

            this.correctiveActions = data;
            this.loading = false;
            this.showAddCapaButton = false;
          });
      } else {
        this.initialize("capaeventlist", {querycols: "CapaEventName"},
          "ID|DocumentName|SiteID|CapaStatus|CapaOwnersID|CapaApproversID|StatusString|SeverityCustomItemID|EscapePointCustomItemID|EscapeValueCustomItemID",
          "ID", "capa-list", (data: any) => {

            this.correctiveActions = data;
            this.loading = false;
            this.showAddCapaButton = this.current.info.roleIsSiteAdmin;
          });

      }

      this.processCapaEventListSettings();
    }

    public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }

    public columnArrangementChangeHandler(): void {

        this.processCapaEventListSettings();
    }

    private processCapaEventListSettings(): void {
        const columnItems = this.columnSettingService.displayOptions(this.listViewOption);
        const visibleColumns = columnItems.filter((listItem: any) => listItem.IsSelected === true);
        this.visibleColumnsCount = visibleColumns.length;

        if (visibleColumns.length === 0) {
            this.uiNotify.open("_AtLeastOneColumnSelected_");
        } else {
            this.gridColumnItems = columnItems;
            this.processGridColumns();
        }

    }

    private processGridColumns(): void {
        // check how many columns are visible
        const visibleColumns = this.gridColumnItems.filter((listItem: any) => listItem.IsSelected === true && listItem.IsEnabled === true);
        let visibleColumnsCount = 0;

        if (visibleColumns != null && visibleColumns.length > 0) {
            visibleColumnsCount = visibleColumns.length;
            this.adjustLayout(visibleColumnsCount);

            this.isIDVisible = this.columnSettingService.checkColumnDisplay("_ID_", this.gridColumnItems);
            this.isCapaEventNameVisible = this.columnSettingService.checkColumnDisplay("_Name_", this.gridColumnItems);
            this.isDocumentNameVisible = this.columnSettingService.checkColumnDisplay("_Document_", this.gridColumnItems);
            this.isDueDateVisible = this.columnSettingService.checkColumnDisplay("_DueDate_", this.gridColumnItems);
            this.isDateOpenedVisible = this.columnSettingService.checkColumnDisplay("_DateOpened_", this.gridColumnItems);
            this.isCapaOwnerVisible = this.columnSettingService.checkColumnDisplay("_CapaOwners_", this.gridColumnItems);
            this.isCapaApproverVisible = this.columnSettingService.checkColumnDisplay("_CapaApprovers_", this.gridColumnItems);
            this.isStatusVisible = this.columnSettingService.checkColumnDisplay("_Status_", this.gridColumnItems);
            this.isSiteVisible = this.columnSettingService.checkColumnDisplay("_Site_", this.gridColumnItems);
            this.isSeverityVisible = this.columnSettingService.checkColumnDisplay("_Severity_", this.gridColumnItems);
            this.isEscapePointVisible = this.columnSettingService.checkColumnDisplay("_EscapePoint_", this.gridColumnItems);
            this.isEscapeValueVisible = this.columnSettingService.checkColumnDisplay("_EscapeValue_", this.gridColumnItems);
            this.isDateClosedVisible = this.columnSettingService.checkColumnDisplay("_DateClosed_", this.gridColumnItems);
            this.isPercentCompleteVisible = this.columnSettingService.checkColumnDisplay("_PercentComplete_", this.gridColumnItems);
        }
    }

    private navigateToCapaEventDetails(capaEventID: number): void {
      if (this.ids && this.ids.length) {
        this.detailsClicked.emit(window.location.origin + window.location.pathname + "#/report/corrective-actions/" + capaEventID);
      } else {

        this.router.navigate(["/report/corrective-actions/" + capaEventID, "event"]);
      }
    }

    private addCapaEvent(): void {
      const config = new DialogConfigMd();
      config.disableClose = true;
      const dialogRef = this.dialog.open(AddCApaEventModalComponent, config);
      dialogRef.afterClosed().takeUntil(this.destroy$).subscribe((capaEventID) => {
        if (capaEventID) {
          this.router.navigate(["/report/corrective-actions/" + capaEventID, "event"]);
        }
      });
    }
}
