import { Component } from "@angular/core";

import { Store } from "@ngrx/store";
import * as fromAuth from "../reducers/index";
import * as AuthActions from "../actions/auth";

@Component({
  selector: "ease-request-restore-success",
  template: `
    <ease-auth-page>
      <mat-card>
        <mat-card-title>{{ "_Auth:RequestRestoreSuccess:ResetYourPassword_" | i18n }}</mat-card-title>
        <mat-card-content>
          <p>{{ "_Auth:RequestRestoreSuccess:CheckYourEmail_" | i18n }}</p>
        </mat-card-content>
        <mat-card-actions align="center">
          <button mat-raised-button color="accent" (click)="redirect()">
            {{ "_Auth:RequestRestoreSuccess:ReturnToSignInButton_" | i18n }}
          </button>
        </mat-card-actions>
      </mat-card>
    </ease-auth-page>
  `,
  styles: [ `
  ` ],
})
export class RequestRestoreSuccessComponent {
  constructor(private store: Store<fromAuth.State>) {
  }

  redirect() {
    this.store.dispatch(new AuthActions.LoginRedirect());
  }
}
