import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { MaterialModule } from "../../material/material.module";

// Components
import { ConfirmationModalComponent } from "./confirmation-modal.component";

// Services
import { ConfirmationDialogService } from "./confirmation-dialog.service";

const COMPONENTS = [
    ConfirmationModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
    ],
    exports: [...COMPONENTS],
    declarations: [...COMPONENTS],
    providers: [
        ConfirmationDialogService
    ],
    entryComponents: [
        ConfirmationModalComponent,
    ],
})
export class ConfirmationModalModule {}