import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

export enum ReportPanelDialogMessageType {
  auditCategoryChanged, filterChanged, panelResized, panelRemove,
  resetGridsterLayout, dashboardFilterChanged, metricTypeUpdate, categoryGroupChanged, stackByStatusOptionChanged,
  dimensionTypeChanged, locationTreeDirty, dateRangeTypeChanged, documentDropdownChanged, panelShowAuditListClicked, stackByChanged
}

export interface IDialogReportPanelMessage {
  key: ReportPanelDialogMessageType;
  value: any;
  panelGuid?: string;
}

@Injectable()
export class ReportNotifyService {
  private subject = new Subject<IDialogReportPanelMessage>();

  public sendMessage(message: IDialogReportPanelMessage) {
    this.subject.next(message);
  }

  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
