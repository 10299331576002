import { Component, Input, OnInit } from "@angular/core";

// Components
import { BreadcrumbComponent } from "../breadcrumb.component";

// Services
import { BreadcrumbsService } from "../breadcrumbs.service";

@Component({
  selector: "ease-breadcrumb-audit",
  template: `
    <ease-breadcrumb-base-item
      [loading]="!details"
      [placeholder]="placeholder"
      [ngClass]="{'breadcrumb-loading': !details}">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        <span *ngIf="data.type === 'conduct' && details">
          {{ '_Conduct_' | i18n }} -
        </span>
          {{ details?.DocumentRev?.Document.Name }}
        <span *ngIf="details && !details.DocumentRev">
          {{ '_BreadcrumbAudit_' | i18n }}
        </span>
      </a>
    </ease-breadcrumb-base-item>`,
})
export class BreadcrumbAuditComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;
  details: any;
  placeholder = "_BreadcrumbAuditLoading_";

  constructor(
    private breadcrumbs: BreadcrumbsService,
  ) {}

  ngOnInit() {
    this.breadcrumbs.getAuditDetails(this.data.params.id).subscribe((res) => this.details = res);
  }
}
