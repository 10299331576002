import { Component, Input, OnInit } from "@angular/core";

import { BreadcrumbComponent } from "../breadcrumb.component";

@Component({
  selector: "ease-breadcrumbs-item",
  template: `
      <a routerLink="{{data.url}}" class="breadcrumb-item" [ngClass]="{'inactive-breadcrumb': !data.url}"
         routerLinkActive="inactive-breadcrumb" [routerLinkActiveOptions]="{exact: true}">
        {{ data.title | i18n }}
      </a>`,
})
export class BreadcrumbItemComponent implements OnInit, BreadcrumbComponent {
  @Input() data: any;

  constructor(
  ) { }

  ngOnInit() { }
}
