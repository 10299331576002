import { NgModule, ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";

// State
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { AuthEffects } from "./effects/auth.effects";
import { SessionEffects } from "./effects/session.effects";
import { reducers } from "./reducers";

import { SharedModule } from "@shared/shared.module";

// Containers
// Todo Remove after sso flow will be implemented.
import { LoginComponent } from "./containers/login/login.component";
import { LoginPageComponent } from "./containers/login-page.container";
import { RequestRestoreComponent } from "./containers/request-restore.container";
import { RequestRestoreSuccessComponent } from "./containers/request-restore-success.container";
import { SetPasswordComponent } from "./containers/set-password.container";
import { CreatePasswordComponent } from "./containers/create-password.container";
import { CreatePasswordSuccessComponent } from "./containers/create-password-success.container";
import { ChangePasswordComponent } from "./containers/change-password.container";
import { ChangePasswordSuccessComponent } from "./containers/change-password-success.container";
import { SignUpComponent } from "./containers/sign-up.container";

// Components
import { AuthPageComponent } from "./components/page";
import { LoginFormComponent } from "./components/login-form/login-form";
import { SsoLoginComponent } from "./components/sso-login/sso-login";
import { RequestRestoreFormComponent } from "./components/request-restore-form/request-restore-form";
import { SetPasswordFormComponent } from "./components/set-password-form/set-password-form";
import { RssFeedComponent } from "./components/rss-feed/rss-feed.component";

// Services
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth-guard.service";
import { RolesGuard } from "./services/roles-guard.service";
import { IsAuthedGuard } from "./services/is-authed-guard.service";
import { SSOGuard } from "./services/sso-guard.service";
import { AntiforgeryGuard } from "./services/antiforgery-guard.service";

const COMPONENTS = [
  LoginComponent,
  LoginPageComponent,
  RequestRestoreComponent,
  RequestRestoreSuccessComponent,
  SetPasswordComponent,
  CreatePasswordComponent,
  CreatePasswordSuccessComponent,
  ChangePasswordComponent,
  ChangePasswordSuccessComponent,
  SignUpComponent,

  AuthPageComponent,
  LoginFormComponent,
  SsoLoginComponent,
  RequestRestoreFormComponent,
  SetPasswordFormComponent,
  RssFeedComponent,
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: "login",
        component: LoginPageComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard, SSOGuard ],
      },
      {
        path: "join",
        component: SignUpComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
      {
        path: "request-restore",
        component: RequestRestoreComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
      {
        path: "restore-success",
        component: RequestRestoreSuccessComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
      {
        path: "change-password/:token",
        component: ChangePasswordComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
      {
        path: "password-change-success",
        component: ChangePasswordSuccessComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
      {
        path: "create-password/:token",
        component: CreatePasswordComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
      {
        path: "password-create-success",
        component: CreatePasswordSuccessComponent,
        canActivate: [ AntiforgeryGuard, IsAuthedGuard ],
      },
    ]),
    StoreModule.forFeature("auth", reducers),
    EffectsModule.forFeature([ AuthEffects, SessionEffects ]),
  ],
  declarations: [ ...COMPONENTS ],
  exports: [],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthGuard,
        RolesGuard,
        IsAuthedGuard,
        SSOGuard,
        AntiforgeryGuard,
      ],
    };
  }
}
