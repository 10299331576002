import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer } from "@angular/core";
import { Subscription } from "rxjs";
import { PageStateService } from "@core/services/navigation/page-state.service";

@Directive({ selector: "[scrollableArea]" })
export class PageScrollableContentDirective implements OnDestroy, OnInit {
  @Input() public scrollableArea: boolean = true;
  @Input() public hasTabStrip: boolean;
  @Input() public isWholePage: boolean;
  private pageHeightSubscription: Subscription;

  constructor(private el: ElementRef,
              private renderer: Renderer,
              private pageStateService: PageStateService) {
  }

  public ngOnInit() {
    this.pageHeightSubscription = this.pageStateService.getPageHeightChange().subscribe((height: number) => {
    let newHeight: number;
    newHeight = this.hasTabStrip ? height - 180 : this.isWholePage ? height : height - 127;

    const pageContentHeight = newHeight + "px";
    if (this.scrollableArea) {
        this.renderer.setElementStyle(this.el.nativeElement, "height", pageContentHeight);
        this.renderer.setElementClass(this.el.nativeElement, "scroller", true);
      }
    });
  }

  public ngOnDestroy() {
    this.pageHeightSubscription.unsubscribe();
  }
}