import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BasePanel } from "@shared/reports/base-panel";
import { IEaseDashboardFilters, IHighChartResult } from "@core/models/ease-models";
import { CurrentInfoService, LocalizeService, LocalService } from "@core/services";
import { IDialogReportPanelMessage, ReportNotifyService, ReportPanelDialogMessageType } from "@shared/reports/report-notify.service";
import { ReportDataAccessService } from "@app/report/report-data-access.service";
import { MatDialog } from "@angular/material";
import { Subscription } from "rxjs";

import * as moment from "moment";

@Component({
  selector: "ease-recent-mitigations",
  templateUrl: "../../../report/reports/report-panel.html",
})

export class RecentMitigationsComponent extends BasePanel implements OnInit, OnDestroy {

  @Input() public filters: IEaseDashboardFilters;

  public tooltipTemplate: string;
  public dashboardUpdatesSubscription: Subscription;

  @Output() private onLoadComplete: () => void;
  @Output() private onInitComplete: () => void;

  private locationLevel: number;

  constructor(private localize: LocalizeService,
              private notifyService: ReportNotifyService,
              private matDialog: MatDialog,
              private reportDataAccess: ReportDataAccessService,
              private dialogSvc: MatDialog,
              private localStorage: LocalService,
              public current: CurrentInfoService) {

    super(localize, notifyService, matDialog, localStorage);
  }

  public ngOnInit(): void {
    this.widgetFilters = {
      enableGroupingOption: false,
      enableSeperationOption: false,
      enableDataLabels: true,
      showDataLabels: false,
      showAuditListButton: false,
      showGearButton: false,
    };

    this.enableStacking = true;
    this.enableLegend = true;
    this.chartDescription = "_RecentMitigations15Days_";
    this.init();
  }

  public ngOnDestroy(): void {
    if (this.dashboardUpdatesSubscription) {
      this.dashboardUpdatesSubscription.unsubscribe();
    }
  }

  public getChartData(): void {

    this.loading = true;
    this.loading = true;

    let mend: moment.Moment = moment(this.current.SiteDateToday(true, null));
    let mstart: moment.Moment = moment(this.current.SiteDateToday(true, null)).subtract(14, "day");

    const startDate = mstart.toDate();
    const endDate = mend.toDate();

    // convert edited date based on site's time zone
    let sdt = new Date(0, 0, 0, 0, 0, 0, 0);
    sdt.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    let edt = new Date(0, 0, 0, 0, 0, 0, 0);
    edt.setFullYear(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    if (!this.current.IsUsingSiteTimeZone()) {
      sdt = this.current.SiteDateInUTC(sdt);
      edt = this.current.SiteDateInUTC(edt);
    }

    mstart = moment(sdt);
    mend = moment(edt);
    this.reportDataAccess.getChartData("RecentMitigationChart", {startDate: mstart, endDate: mend, metricType: this.widgetFilters.metricType}).subscribe((data: IHighChartResult) => {
      this.chartData = data.ChartData;
      this.categoryAxis = [];
      this.loading = false;
      this.initialLoadComplete = true;
      this.locationLevel = data.LocationLevel;

      if (data && data.CategoryAxisLabels && data.CategoryAxisLabels.length) {
        data.CategoryAxisLabels.forEach((cat) => {
          this.categoryAxis.push(cat);
        });
      }

      // legend names needs to be translated
      data.ChartData.forEach((chartdata) => {
        chartdata.name = this.localize.getLocalizedString(chartdata.name);
      });

      this.setTooltipTemplate();

      this.setChartConfig(null, this.widgetFilters.enableDataLabels);
      if (this.onLoadComplete) {
        this.onLoadComplete();
      }
    });
  }

  public onSeriesClick(e): void {
    // Certain orgs (ex: Ancon) will want to view the dashboard at an org level, meaning they want to see site by site comparisons
    const isOrgLevel = this.locationLevel <= 3;
    this.showAuditListDlg(e.point.ids, isOrgLevel, null, true, false, null, true, e.point.isAdhocMitigation);
  }

  public onLegendItemClick(e): boolean {
    const myname = e.target.name;
    const myvis = !e.target.visible;

    e.target.chart.series.forEach((elem) => {
      if (elem.name === myname) {
        elem.setVisible(myvis, false);
      }
    });
    e.target.chart.redraw(); // after changing the visibility, call the chart.redraw() once.
    e.target.visible = !e.target.visible; // toggle the visibility for the clicked legend item
    return false;
  }

  public reloadData(): void {
    this.loading = true;
    this.getChartData();
  }

  private registerEvents(): void {
    this.dashboardUpdatesSubscription = this.reportNotifyService.getMessage().subscribe(message => {
      const messageFromReportFilterDialog: IDialogReportPanelMessage = message;
      if (messageFromReportFilterDialog && (messageFromReportFilterDialog.key || messageFromReportFilterDialog.key === 0)) {
        switch (messageFromReportFilterDialog.key) {
          case ReportPanelDialogMessageType.dashboardFilterChanged:
          {
            this.getChartData();
            break;
          }
        }
      }
    });
  }

  private setTooltipTemplate(): void {
    this.tooltipTemplate = "#=series.name #:  #=value #";
  }

  private init(): void {
    this.getChartData();
    this.registerEvents();
  }
}
