// RxJS
import { Observable, zip } from "rxjs";
import { map, take, tap } from "rxjs/operators";

// Angular
import { Injectable } from "@angular/core";
import { CanActivate, RouterStateSnapshot } from "@angular/router";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "../reducers";
import {
  HomeRedirectFromRolesGuard,
} from "../actions/auth";

import { IS_SYS_ADMIN, IS_SITE_ADMIN } from "@app/auth/models/roles";

@Injectable()
export class RolesGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>) {
  }

  canActivate(_, state: RouterStateSnapshot): Observable<boolean> {
    let route = state.root;
    let requiredRoles = [];

    while (route) {
      if (route.children && route.children.length) {
        route = route.children[ 0 ];

        if (route.data && route.data[ "roles" ]) {
          requiredRoles = [ ...requiredRoles, ...route.data[ "roles" ] ];
        }
      } else {
        break;
      }
    }

    return zip(
      this.store.pipe(select(fromAuth.getRoleIsSysAdmin)),
      this.store.pipe(select(fromAuth.getRoleIsOrgAdmin)),
    )
      .pipe(
        map(([ isSysAdmin, isOrgAdmin ]) => {
          return {
            [ IS_SYS_ADMIN ]: isSysAdmin,
            [ IS_SITE_ADMIN ]: isOrgAdmin,
          };
        }),
        map(activeRoles => {
          return requiredRoles
            .map(role => activeRoles[ role ])
            .reduce((acc, curr) => acc && curr);
        }),
        tap(value => {
          if (!value) {
            this.store.dispatch(new HomeRedirectFromRolesGuard());
            return false;
          }
        }),
        take(1),
      );
  }
}
