import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { IUser, ISite, ICalendar, ICalendarWorkSchedule, IClientAudit,
         ICalendarEvent, ICalendarSeries, CalendarEventType } from "@core/models/ease-models";

@Injectable()
export class WorkScheduleCalendarService {
  constructor(private http: HttpClient) {
  }

  public getUserByID(id: number): Observable<IUser> {
    return this.http.get<IUser>(`/user/${id}?details=true`);
  }

  public getSiteByID(id: number): Observable<ISite> {
    return this.http.get<ISite>(`/site/${id}`);
  }

  public getCalendarData(calendarType: string, entityID: number): Observable<ICalendar[]> {
    return this.http.get<ICalendar[]>(`/calendar?q=${calendarType}&p=${entityID}`);
  }

  public getCalendarWorkSchedules(entityID: number): Observable<ICalendarWorkSchedule[]> {
    return this.http.get<ICalendarWorkSchedule[]>(`/calendarworkschedule?q=calendar&p=${entityID}`);
  }

  public getAuditEventsByUserID(userID: number): Observable<IClientAudit[]> {
    return this.http.get<IClientAudit[]>(`/audit?q=user&userID=${userID}`);
  }

  public deleteCalendarEvent(id: number): Observable<void> {
    return this.http.delete<void>(`/calendarevent/${id}`);
  }

  public updateCalendarEvent(calendarEvent: ICalendarEvent): Observable<void> {
    const url = `/calendarevent/${calendarEvent.ID}`;
    return this.http.put<void>(url, calendarEvent);
  }

  public addCalendarSeries(calendarSeries: ICalendarSeries): Observable<number> {
    return this.http.post<number>("/calendarseries", calendarSeries);
  }

  public getCalendarEvents(calendarType: string, calendarID: number, eventType: CalendarEventType, calendarEntityID: number): Observable<ICalendarEvent[]> {
    if (calendarType === "location" || calendarType === "site") {
      return this.http.get<ICalendarEvent[]>(`/calendarevent?q=${calendarType}&p=${calendarID}&eventType=${eventType}&sitelocationID=${calendarEntityID}`);
    } else {
      return this.http.get<ICalendarEvent[]>(`/calendarevent?q=calendar&p=${calendarID}&eventType=${eventType}`);
    }
  }
}
