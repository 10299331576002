import { Subject } from "rxjs";

import {
  EaseMitigationUploaderObject,
} from "@shared/mitigation-action-panel/mitigation-action-panel.component";
import {
  IArtifact,
  ICapaEvent, ICapaEventLineItem, ICapaEventOwner, ICapaTask, IClientCapaEventLineItem, IClientMitigationActivity,
  IClientUser, IMitigationActivity, IUser,
  MitigationStatusTypes,
} from "@core/models/ease-models";
import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { NgForm } from "@angular/forms";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { FileItem, FileUploader } from "ng2-file-upload";
import { ImageDialogComponent } from "@shared/dialogs/image-dialog/image-dialog.component";
import { DialogConfigSm } from "@shared/dialogs";

// Services
import { CurrentInfoService } from "@core/services/current.service";
import { LocalizeService } from "@core/services/localize.service";
import { MitigationDetailService } from "@core/services/common/mitigation-detail.service";
import { ArtifactService } from "@core/services/common";
import { ToolsService } from "@core/services/tools.service";
import { StorageService } from "@core/services/storage.service";
import { MitigationArtifactService } from "@core/services/common/mitigation-artifact.service";
import { IdentityService } from "@core/services/identity.service";
import { LocalService } from "@core/services/local.service";
import { LoggerService } from "@core/services/logger.service";
import { AppSettingsService } from "@core/services/app-settings.service";
import { NotifyService } from "@core/services/notify.service";
import { CorrectiveActionsDataAccessService } from "../../services/corrective-actions-data-access.service";
import { MitigationStatusService } from "../../services/mitigation-status.service";
import { UiNotificationService } from "@core/services/common/ui-notification.service";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import { take } from "rxjs/operators";

@Component({
  selector: "ease-capa-event-task-details",
  templateUrl: "./capa-event-task-details.component.html",
  styleUrls: ["./capa-event-task-details.component.scss"],
  // viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],

})
export class CapaEventTaskDetailsComponent implements OnInit, OnDestroy {
  public mitigation: IClientMitigationActivity;
  public loading: boolean = false;
  public isRejection: boolean = false;
  destroy$: Subject<boolean> = new Subject();

  // Bound to directive
  @Input() parentForm: NgForm;
  @Input() capaEventLineItem: ICapaEventLineItem;
  @Input() capaEvent: ICapaEvent;
  @Input() canEdit: boolean;  // Only the CAPA event owner can edit
  @Input() canEditValidationDueDate: boolean;
  @Input() canEditValidationUsers: boolean;
  @Input() isValidationTask: boolean;
  @Input() isPrintMode: boolean = false;
  @Output() onStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  canEditTask: boolean; // This should only be true if the task is open and the canEdit flag is also true
  public uploaderObjs: EaseMitigationUploaderObject[];

  capaTask: ICapaTask;
  currentUserID: number = 0;

  canClose: boolean = false;
  canApprove: boolean = false;
  canReopen: boolean = false;
  isCapaEventClosed: boolean;

  minDate;
  maxDate;

  mitigateForm: NgForm;

  showTaskApproverComments: boolean = false;
  showTaskOwnerComments: boolean = false;
  managers: IUser[];
  token: string;

  constructor(private store: Store<fromAuth.State>,
              private currentInfo: CurrentInfoService,
              private localize: LocalizeService,
              private appSettings: AppSettingsService,
              private identity: IdentityService,
              private tools: ToolsService,
              private zone: NgZone,
              private storage: StorageService,
              private log: LoggerService,
              private local: LocalService,
              private mitigationDetailService: MitigationDetailService,
              private mitigationArtifactService: MitigationArtifactService,
              private dialog: MatDialog,
              private notify: NotifyService,
              private dataAccess: CorrectiveActionsDataAccessService,
              private route: ActivatedRoute,
              private mitigationStatusService: MitigationStatusService,
              private uiNotify: UiNotificationService,
              private element: ElementRef,
              private artifact: ArtifactService) {

    this.store.pipe(
      select(fromAuth.getToken),
      take(1),
    ).subscribe(value => this.token = value);
  }

  public ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngAfterViewInit() {
    if (this.route.snapshot.data["mode"] === "task" && Number(this.route.snapshot.params.id) === this.capaTask.ID) {
      this.element.nativeElement.scrollIntoView();
    }
  }

  registerEventHandlers(): void {

    this.notify.on("save-capa-tasks").takeUntil(this.destroy$).subscribe((capaEvent: ICapaEvent) => {
      if (capaEvent) {
        this.setTaskDueDateOnEventDueDateExtended(capaEvent);
      }
      this.saveTask();
    });

  }

  setTaskDueDateOnEventDueDateExtended(capaEvent: ICapaEvent) {
    this.setCalendarRange();

    if (new Date(this.capaTask.DueDate) > new Date(this.capaEvent.DueDate)) {
      this.capaTask.DueDate = this.capaEvent.DueDate;
    }

    const offset = 30;
    if (capaEvent && capaEvent.DueDate && this.capaTask.IsValidation) {
      this.capaTask.DueDateSiteLocalTime = this.currentInfo.SiteDateInUTC(capaEvent.DueDate).addDays(offset);
      this.capaTask.DueDate = capaEvent.DueDate.addDays(offset);
    }
  }

  saveTask(): void {

    this.capaTask.MitigationActivity = this.mitigation;

    // TQP: Which should make sure the form is dirty, but that didn't seem to work for me
    this.capaEventLineItem.CapaTask = JSON.parse(JSON.stringify(this.capaTask));
    this.capaEventLineItem.CapaTask.DueDateSiteLocalTime = this.currentInfo
      .SiteDateInUTC(this.capaEventLineItem.CapaTask.DueDateSiteLocalTime); // always reference to the current
    // active site date

    const dueDate = new Date(this.capaEventLineItem.CapaTask.DueDate);
    const payload = { ...this.capaEventLineItem };

    payload.CapaTask.DueDate = new Date(Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate())).toISOString();

    this.dataAccess.updateItem<ICapaEventLineItem>("CapaEventLineItem", this.capaEventLineItem.ID,
      payload, { updateCapaTask: true, updateMitigation: true }).subscribe(() => {
      // add here code after the task is saved
    });
  }

  init(): void {

    const stateName = this.route.routeConfig.path;

    this.isPrintMode = stateName.indexOf("print") !== -1;
    this.registerEventHandlers();
    this.capaTask = this.capaEventLineItem.CapaTask;
    this.mitigation = this.capaTask.MitigationActivity as IClientMitigationActivity;
    this.initUploader(this.mitigation);
    this.isCapaEventClosed = this.capaEvent.CapaStatus === 2 || this.capaEvent.CapaStatus === 3;
    this.initMitigation();
    this.getManagers();
  }

  initMitigation(): void {

    const isOpen = this.mitigation.Status === MitigationStatusTypes.Open as number;
    const isPending = this.mitigation.Status === MitigationStatusTypes.PendingApproval as number;
    const isClosed = this.mitigation.Status === MitigationStatusTypes.Closed as number;

    const allowed = [0, 1, 5]; // 0 - in progress past due, 1 - in progress in time, 5 - Rejected
    this.getArtifacts(this.mitigation);
    this.currentUserID = this.currentInfo.info.user.ID;

    this.canClose = (this.currentUserID === this.mitigation.ResponsiblePartyUserID && isOpen);
    this.canApprove = (this.currentUserID === this.mitigation.ApprovingManagerUserID && isPending);
    this.canReopen = this.isCAPAEventOwner() && isClosed && allowed.indexOf(this.capaEvent.CapaStatus) >= 0;

    this.showTaskOwnerComments = (this.mitigation.Comment != null && this.mitigation.Status ===
      MitigationStatusTypes.Closed as number) || isPending;
    this.showTaskApproverComments = (this.mitigation.ApprovingPartyComment != null &&
      this.mitigation.ApprovingPartyComment !== "" && (isOpen || isClosed));

    this.canEditTask = this.canEdit && this.mitigation.Status === MitigationStatusTypes.Open as number;

    this.setCalendarRange();
    this.formatMitigation();
  }

  setCalendarRange() {
    if (this.isValidationTask) {
      this.minDate = moment(this.capaEvent.DueDate);
      this.maxDate = null;
    } else {
      const daysDueAfter = this.capaTask.DaysDueAfterOpened ? this.capaTask.DaysDueAfterOpened - 1 : 0;
      const siteMinDate = this.dataAccess.getSiteMinDate(this.capaEvent.Site.TimeZoneOffset);
      const minDateWithDaysDueAfter = daysDueAfter > 0 ? this.addDays(daysDueAfter, this.capaEvent.DateOpened) : null;

      this.minDate = (!minDateWithDaysDueAfter || siteMinDate > minDateWithDaysDueAfter) ?
        siteMinDate : minDateWithDaysDueAfter;

      this.maxDate = moment(this.capaEvent.DueDate);
    }
  }

  addDays(daysDueAfterOpened, date: Date): Date {
    const dt = new Date(date);
    return new Date(Date.UTC(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate() + daysDueAfterOpened),
    );
  }

  refreshTaskDetails(): void {
    this.onStatusChanged.emit();
    this.dataAccess.getMitigation(this.mitigation.ID).subscribe((data: IClientMitigationActivity) => {
      this.mitigation = data;
      this.initMitigation();
    });
  }

  protected getManagers(): void {
    this.dataAccess.getManagers({ filteredRoles: "Manager", siteID: this.mitigation.SiteID })
      .subscribe((data: IUser[]) => {
        this.managers = [];
        data.forEach((manager: IClientUser) => {
          manager.FullName = manager.FirstName + " " + manager.LastName;
          this.managers.push(manager);
        });
        this.managers = this.managers.sort((a, b) => a.FullName.localeCompare(b.FullName));
      });
  }

  private getArtifacts(ma): void {
    ma.uploadedFiles = new Array();
    this.dataAccess.getMitigationArtifacts({ mitigationID: ma.ID, thumbnail: true })
      .subscribe((artifacts) => {
        artifacts.forEach((img) => {
          ma.uploadedFiles.push(img);
        });
      });
  }

  private isCAPAEventOwner(): boolean {
    let isCAPAOwner: boolean = false;
    const CAPAOwners: ICapaEventOwner[] = this.capaEvent.CapaEventOwners;
    const filteredOwner = CAPAOwners.filter((item) => item.UserID === this.currentUserID);
    if (filteredOwner && filteredOwner.length > 0) {
      isCAPAOwner = true;
    }
    return isCAPAOwner;
  }

  private reopenCapaTask(): void {
    if (this.canReopen) {
      this.dataAccess.updateItem<IMitigationActivity>("Mitigation", this.mitigation.ID,
        this.mitigation, { isCAPATaskReopen: true }).subscribe(() => {
        this.refreshTaskDetails();
      });
    }
  }

  private formatMitigation(): void {

    if (this.mitigation.Comment != null) {
      this.mitigation.CommentFormatted = this.mitigation.Comment.replace(/\n/g, "<br/>");
    }

    if (this.mitigation.ApprovingPartyComment != null) {
      this.mitigation.ApprovingPartyCommentFormatted = this.mitigation.ApprovingPartyComment.replace(/\n/g, "<br/>");
    }

    this.mitigation.StatusString = this.mitigationStatusService.getStatusLabel(this.mitigation);
  }

  private initUploader(ma: any): void {
    this.uploaderObjs = [];
    const host = this.identity.endpoints.Service;
    let uploadUrl: string;
    if (host.length > 0) {
      uploadUrl = host + "/mitigationartifact";
    }

    const maUploader = new FileUploader({ url: uploadUrl, removeAfterUpload: true });

    maUploader.options.filters.push({
      name: "fileFilter",
      fn: (item /*{File|FileLikeObject}*/, options) => {
        return this.storage.isAllowedFileType(item);
      },
    });

    const isExists = this.uploaderObjs.filter((item) => item.mitigationID === ma.ID).length > 0;
    if (!isExists) {
      this.uploaderObjs.push({
        mitigationID: ma.ID, uploader: maUploader,
      });
    }

    maUploader.onAfterAddingFile = (item) => {
      this.saveUploadedFiles(() => {
        // add here callback code
      });
    };

    maUploader.onProgressAll = (progress) => {
      this.notify.broadcast("full-page-block", true);
    };

    maUploader.onSuccessItem = (fileItem, response, status, headers) => {
      this.dataAccess.getMitigationArtifactByID(parseInt(response, 10))
        .subscribe((data) => {
          if (data != null) {
            this.mitigation.uploadedFiles.push(data);
          }
        });
    };

    maUploader.onErrorItem = (fileItem, response, status, headers) => {
      const error = JSON.parse(response); // error server response

      const exceptionErrorMessage = "FileNotSupportedException:";
      if (error && error.Message && error.Message.indexOf(exceptionErrorMessage) !== -1) {
        this.uiNotify.open(this.localize.getLocalizedString("_InvalidFileType_"));
      }
    };

    maUploader.onCompleteAll = () => {
      this.notify.broadcast("full-page-block", false);
    };
  }

  private saveUploadedFiles(callback: any): void {
    this.uploaderObjs.forEach((obj: any) => {
      const tauth = `Token ${this.token}`;
      if (obj.uploader != null && obj.uploader.queue.length > 0) {
        obj.uploader.queue.forEach((item: FileItem) => {
          item.headers = [{ name: "MitigationID", value: obj.mitigationID }];
        });
        obj.uploader.authTokenHeader = "Authorization";
        obj.uploader.authToken = tauth;
        obj.uploader.uploadAll();
      }
    });

    // The uploadAll() function has no callback, so just wait a few ms before we return
    setTimeout(() => {
      callback();
    }, 1000);
  }

  private removeFileFromMitigation(img: any, ma: any): void {
    const id = img.ID;
    if (id > 0) {
      const index = ma.uploadedFiles.indexOf(img);
      ma.uploadedFiles.splice(index, 1);

      // delete file
      this.dataAccess.deleteItem("mitigationartifact", id).subscribe(() => {
        // add here code after deletion occurs
      });

    }
  }

  private getMitigationImage(img: IArtifact): void {
    if (img) {
      const config = new DialogConfigSm();
      config.panelClass = "img-dialog";
      config.data = this.artifact.getImageUrl(img);

      this.dialog.open(ImageDialogComponent, config);
    }
  }

  private commentAndCloseMitigation(isApprovalRequired: boolean = false, isAdhoc = false): void {
    this.mitigation.Status = (isApprovalRequired || (this.mitigation.Rank != null &&
      this.mitigation.Rank.IsAdditionalApprovalEnabled)) ? 2 : 1;

    this.dataAccess.updateItem("Mitigation", this.mitigation.ID, this.mitigation,
      { updateApprovingParty: isAdhoc, isAdHoc: isAdhoc }).subscribe(() => {
      this.refreshTaskDetails();
    });
  }

  private approveRejectMitigation(isApproved: boolean, isAdhoc = false): void {
    if (isApproved) {
      this.mitigation.Status = 1;
      this.dataAccess.updateItem("Mitigation", this.mitigation.ID, this.mitigation,
        { isAdHoc: isAdhoc }).subscribe(() => {
        this.refreshTaskDetails();
      });
    } else {
      this.isRejection = true;
      const approvingPartyComment = this.mitigation.ApprovingPartyComment;
      if (approvingPartyComment == null || approvingPartyComment === "" || (approvingPartyComment != null &&
          approvingPartyComment.trim().length === 0)) {
        this.uiNotify.open(this.localize.getLocalizedString("_CommentRequiredOnRejection_"));
      } else {
        this.mitigation.Status = 0;
        this.dataAccess.updateItem("Mitigation", this.mitigation.ID, this.mitigation,
          { sendRejectMitigationEmail: true, isAdHoc: isAdhoc }).subscribe(() => {
          this.refreshTaskDetails();
        });
      }
    }
  }

  private setParentFormDirty() {
    this.parentForm.form.markAsDirty();
  }
}
