import { AuthActionTypes, AuthActionsUnion } from "../actions/auth";
import { SessionActionTypes, SessionActionsUnion } from "../actions/session";

export interface State {
  activeSite: any;
  org: any;
  user: any ;
  sites: any[] | null;
  roleIsSysAdmin: boolean;
  roleIsOrgAdmin: boolean;
  featureFlags: any;
  ImpersonatorLogin: string | null;
}

export const initialState: State = {
  activeSite: null,
  org: null,
  user: null,
  sites: null,
  roleIsSysAdmin: false,
  roleIsOrgAdmin: false,
  featureFlags: {},
  ImpersonatorLogin: null,
};

export function reducer(state = initialState, action: AuthActionsUnion | SessionActionsUnion): State {
  switch (action.type) {
    case AuthActionTypes.AuthorizeSuccess: {
      return {
        ...action.payload,
      };
    }

    case SessionActionTypes.RestoreSessionSuccess: {
      return {
        ...action.payload.User,
      };
    }

    default: {
      return state;
    }
  }
}

export const getImpersonatorLogin = (state: State) => state.ImpersonatorLogin;
export const getUser = (state: State) => state.user;
export const getUserID = (state: State) => state.user && state.user.ID;
export const getUserLanguage = (state: State) => state.user && state.user.Language;

export const getActiveSiteID = (state: State) => state.activeSite && state.activeSite.ID;
export const getActiveOrgID = (state: State) => state.org && state.org.ID;
export const getSites = (state: State) => state.sites;
export const getFeatureFlags = (state: State) => state.featureFlags;

// Roles
export const getRoleIsSysAdmin = (state: State) => state.roleIsSysAdmin;
export const getRoleIsOrgAdmin = (state: State) => state.roleIsOrgAdmin;
