import { Component, Input } from "@angular/core";

// State
import { select, Store } from "@ngrx/store";
import * as fromOrgs from "app/system-admin/organizations/reducers";

// Interface
import { BreadcrumbComponent } from "../breadcrumb.component";

@Component({
  selector: "ease-breadcrumb-organization",
  template: `
    <ease-breadcrumb-base-item
      [loading]="!(details$ | async)"
      [placeholder]="placeholder"
      [ngClass]="{'breadcrumb-loading': !(details$ | async)}">
      <a routerLink="{{data.url}}"
         [routerLinkActiveOptions]="{ exact: true }"
         class="breadcrumb-item"
         routerLinkActive="inactive-breadcrumb">
        {{ details$ | async }}
      </a>
    </ease-breadcrumb-base-item>
  `,
})
export class BreadcrumbOrganizationComponent implements BreadcrumbComponent {
  @Input() data: any;
  details$ = this.store.pipe(select(fromOrgs.getDetailsName));
  placeholder = "_Core:BreadcrumbOrganization:Loading_";

  constructor(private store: Store<fromOrgs.State>) {
  }
}
