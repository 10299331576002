import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { IDocumentRev } from "../models/ease-models";
import { ApiService } from "./api.service";

@Injectable()
export class DocumentRevService {
    public documentRevs: IDocumentRev[];
    constructor(private api: ApiService) {

    }

    public getDocumentRevs(query: any) {
        const result = new ReplaySubject<any>(1);
        this.api.Query("documentrev", query, (data: IDocumentRev[]) => {
            if (data && data.length) {
                result.next(data);
                result.complete();
            } else {
                console.log("no document revs found");
                result.next([]);
                result.complete();
            }
        });
        return result.asObservable();
    }
}