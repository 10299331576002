import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SharedModule } from "../shared/shared.module";
import { AccountRoutingModule } from "./account-routing.module";

// Modules
import { CalendarModule } from "angular-calendar";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

// Services
import { WorkScheduleCalendarService } from "./calendar/work-schedule-calendar.service";
import { AuditReassignDeleteService } from "./calendar/audit-reassign.service";

// Components
import { AboutComponent } from "./about/about.component";
import { UserProfileComponent } from "./profile/userprofile.component";
import { AuditReassignModalComponent } from "./calendar/audit-reassign-modal.component";
import { CalendarModalComponent } from "./calendar/calendar-modal.component";
import { ConfirmHolidayModalComponent } from "./calendar/confirm-holiday-modal.component";
import { WorkScheduleCalendarComponent } from "./calendar/work-schedule-calendar.component";
import { MyAccountComponent } from "./myAccount/myAccount.component";
import { AuditHistoryListComponent } from "./auditHistory/auditHistory.component";
import { ChangePasswordModalContainerComponent } from "./profile/change-password-modal-container.component";
import { ChangePasswordAccountComponent } from "./profile/change-password-account.component";

@NgModule({
  declarations: [
    AboutComponent,
    UserProfileComponent,
    MyAccountComponent,
    ChangePasswordModalContainerComponent,
    ChangePasswordAccountComponent,
    WorkScheduleCalendarComponent,
    CalendarModalComponent,
    AuditReassignModalComponent,
    ConfirmHolidayModalComponent,
    AuditHistoryListComponent,
  ],
  imports: [
    RouterModule,
    PerfectScrollbarModule,
    SharedModule,
    AccountRoutingModule,
    CalendarModule,
  ],
  providers: [
    WorkScheduleCalendarService,
    AuditReassignDeleteService,
  ],
  entryComponents: [
    ChangePasswordModalContainerComponent,
    CalendarModalComponent,
    AuditReassignModalComponent,
    ConfirmHolidayModalComponent,
  ],
})

export class AccountModule {
}
