import { Component, OnInit } from "@angular/core";
import {Router, ActivatedRoute, Params} from "@angular/router";

@Component({
    templateUrl: "./capa-history.component.html",
})

export class CapaHistoryComponent implements OnInit {

    public entity: string;
    public entityID: number;

    constructor(private activateRoute: ActivatedRoute) { }

    public ngOnInit(): void {
        const capaType = this.activateRoute.snapshot.data["mode"];

        this.entity  = capaType === "capa" ? "CapaEvent" : "CapaTask";
        this.activateRoute.params.subscribe((params: Params) => {
            this.entityID = params.id;
        });
    }

}
