import { Component, Input, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { LocalizeService } from "@core/services/localize.service";

@Component({
  selector: "ease-additional-info",
  templateUrl: "./additional-info.component.html",
  styleUrls: ["./additional-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AdditionalInfoComponent implements OnInit {

  @Input() public description: string;
  @Input() public content: string;

  constructor(private localize: LocalizeService) {
  }

  public ngOnInit() {
    this.description = this.localize.getLocalizedString(this.description);
    this.content = this.localize.getLocalizedString(this.content);
  }
}
