import { Injectable, NgZone } from "@angular/core";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { LoggerService } from "@core/services/logger.service";
import { Observable,  ReplaySubject } from "rxjs";
import {
  EaseMitigationStatus,
  IMitigationActivity,
  IMitigationDetail,
  ICapaEvent,
} from "../../models/ease-models";
import { MyActivityListDetail } from "@app/home/models/home.interface";
import { ApiService } from "../api.service";
import { CurrentInfoService } from "../current.service";
import { LocalService } from "../local.service";
import { StorageService } from "../storage.service";
import { ToolsService } from "../tools.service";
import { filter } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { UiNotificationService } from "./ui-notification.service";

@Injectable()
export class MitigationDetailService {
  private isCordova: boolean;
  private _isMitigationOpened: boolean;

  constructor(private api: ApiService,
              private tools: ToolsService,
              private local: LocalService,
              private storage: StorageService,
              private current: CurrentInfoService,
              private log: LoggerService,
              private router: Router,
              private zone: NgZone,
              private http: HttpClient,
              private uiNotify: UiNotificationService) {
    this.isCordova = this.tools.isCordova();
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
    )
      .subscribe(_ => {
        this.isMitigationOpened = false;
      });
  }

  get isMitigationOpened(): boolean {
    return this._isMitigationOpened;
  }

  set isMitigationOpened(value: boolean) {
    this._isMitigationOpened = value;
  }

  public getMitigationActivity(auditID: number, callback: any) {
    this.api.Query("Mitigation", { q: "audit", qID: auditID }, (data: IMitigationActivity[]) => {
      callback(data);
    });
  }

  public getMitigationsOnline(id?: number): Observable<IMitigationDetail[]> {
    const result = new ReplaySubject<any>();
    this.api.Query("mitigationdetail", { p: "active-assignments", q: 0, auditID: (id ? id : 0) }, (data: any) => {
        this.zone.run(() => {
            result.next(data);
            result.complete();
        });
    });
    return result.asObservable();
  }

  public getCapaEventsOnline(userID: number): Observable<MyActivityListDetail> {
    return Observable.forkJoin([
      this.http.get<ICapaEvent[]>(`/capaeventlist?CapaStatus=0|1&CapaOwnersID=${userID}&AssignedToCurrentUserOnly=true`),
      this.http.get<ICapaEvent[]>(`/capaeventlist?CapaStatus=4&CapaApproversID=${userID}&CapaEventApprovalStatus=0&AssignedToCurrentUserOnly=true`),
      this.http.get<any[]>(`/mitigationreport?AssignedToCurrentUserOnly=true&IgnoreSite=true&IncludeCapaMitigations=true`),
    ])
      .map((data: any[]) => {
        const detail: MyActivityListDetail = {
          CapaEventOwnerActivity: data[0].Result,
          CapaEventApproverActivity: data[1].Result,
          CapaTaskActivity: data[2].Result,
        };
        return detail;
      });
  }

  public getCapaEvent(): Observable<MyActivityListDetail> {
    if (this.tools.isApp() || !this.tools.isOnline()) {
      // Need to add logic for offline app
      return Observable.of(null);
    } else {
      return this.getCapaEventsOnline(this.current.info.user.ID);
    }
  }

  public getMitigations(id?: number): Observable<any[]> {
    const result = new ReplaySubject<any>();
    if (this.tools.isApp() && !this.tools.isOnline()) {
      this.log.debug("get mitigation detail list from file system");
      const key = this.storage.buildCurrentStorageKey("Mitigations", "MitigationDetails");
      this.storage.retrieveObjectEntries(key).subscribe((data: any[]) => {
        const audits: Array<Observable<any>> = [];
        this.log.debug("getMitigations from fileSystem data: " + data.length);
        const mitigationDetails: any[] = [];

        // retrieve mitigation detail lists from json file
        this.storage.retrieveObject(key).subscribe((data: any) => {
          this.log.debug("success retrieveObj: " + data);
          result.next(data);
          result.complete();
        }, (err: any) => {
          this.log.error("failed to retrieve mitigation detail list " + JSON.stringify(err));
          result.error(err);
        });

      }, (err: any) => {
        this.log.error("failed to retrieveObjectEntries for mitigationDetail " + JSON.stringify(err) + " get mitigation detail from server instead");
        this.getMitigationsOnline().subscribe((data: IMitigationDetail[]) => {
          if (data && data.length == 0) {
            this.log.debug("no mitigation details found");
          }
          result.next(data);
          result.complete();
        });
      });

    } else {
      this.log.debug("get mitigation detail from server");
      this.getMitigationsOnline(id).subscribe((data: IMitigationDetail[]) => {
        if (data && data.length == 0) {
          this.log.debug("no mitigation details found");
        }
        result.next(data);
        result.complete();
      });
    }

    return result.asObservable();
  }

  public getMitigationByIDOnline(id: number): Observable<any> {
    const result = new ReplaySubject<any>(1);
    this.api.Get("mitigationdetail", null, id, (data: any) => {
      this.zone.run(() => {
        result.next(data);
        result.complete();
      });
    }, () => {
    }, () => {
    });
    return result.asObservable();
  }

  public getMitigationDetailByID(id: number, entityName: string, readFromServer: boolean = false, isResult: boolean = false): Observable<any> {
    const key = this.storage.buildCurrentStorageKey(entityName, id);
    const result = new ReplaySubject<any>(1);

    if ((this.tools.isApp() && !readFromServer && !isResult) || (this.tools.isApp && isResult && !this.tools.isOnline())) {
      this.storage.containsObject(key).subscribe((isExists: boolean) => {
        if (isExists) {
          this.storage.retrieveObject(key).subscribe((data: any) => {
            result.next(data);
            result.complete();
          }, (err: any) => {
            this.log.error("failed to retrieve object in getMitigationDetailByID: " + JSON.stringify(err));
            result.error(err);
          });
        } else {
          if (this.tools.isOnline()) {
            this.getMitigationByIDOnline(id).subscribe((data: any) => {
              result.next(data);
              result.complete();
            });
          } else {
            result.next(null);
            result.complete();
          }
        }
      });
    } else {
      this.getMitigationByIDOnline(id).subscribe((data: any) => {
        result.next(data);
        result.complete();
      });
    }

    return result.asObservable();
  }

  public syncMitigations(onlineMitigations: IMitigationDetail[]): Observable<any> {
    const mitigationDetails: IMitigationDetail[] = [];
    const result = new ReplaySubject<any>(1);
    if (this.tools.isApp()) {
      this.getMitigationDetailsInFileSystem(onlineMitigations).subscribe((contents: IMitigationDetail[]) => {
        this.zone.run(() => {
          const offlineSubmittedMitigationIDs: number[] = [];
          for (const content of contents) {
            if (content && content.Mitigation != null) {
              for (const detail of onlineMitigations) {
                if (detail.Mitigation.ID === content.Mitigation.ID) {
                  if (content.Mitigation.Status === EaseMitigationStatus.Closed || (content.Mitigation.Status === EaseMitigationStatus.Pending && content.Mitigation.ApprovingManagerUserID !== this.current.info.user.ID && content.Mitigation.IsNotSynced)) {
                    offlineSubmittedMitigationIDs.push(content.Mitigation.ID);
                  }

                  if (detail.Mitigation.Status !== EaseMitigationStatus.Closed && content.Mitigation.IsNotSynced) {
                    this.log.debug("syncMitigations: " + content.Mitigation.ID);
                    detail.Mitigation = content.Mitigation;
                    detail.Mitigation.IsNotSynced = false;
                    mitigationDetails.push(detail);
                  }
                  break;
                }
              }
            }
          }

          if (mitigationDetails.length > 0 && this.tools.isOnline()) {
            const snackBarRef = this.uiNotify.open("_SyncingInProgress_", true);
            snackBarRef.afterOpened().subscribe(() => {
              this.processMitigations(mitigationDetails).subscribe((mitigations: IMitigationActivity[]) => {
                let count = 0;
                if (mitigations.length > 0) {
                  for (const mitigation of mitigations) {
                    count++;
                    if (mitigation.Status === EaseMitigationStatus.Closed || (mitigation.Status === EaseMitigationStatus.Pending && mitigation.ApprovingManagerUserID !== this.current.info.user.ID)) {
                      this.deleteMitigationFiles(mitigation.ID, () => {
                        if (count === mitigations.length) {
                          result.next({submittedMitigationIDs: offlineSubmittedMitigationIDs, isSync: true});
                          result.complete();
                        }
                      });
                    } else {
                      if (count === mitigations.length) {
                        result.next({submittedMitigationIDs: offlineSubmittedMitigationIDs, isSync: true});
                        result.complete();
                      }
                    }
                  }
                } else {
                  result.next({submittedMitigationIDs: offlineSubmittedMitigationIDs, isSync: false});
                  result.complete();
                }
              });
            });
          } else {
            result.next({submittedMitigationIDs: offlineSubmittedMitigationIDs, isSync: false});
            result.complete();
          }
        });
      });
    } else {
      result.next({submittedMitigationIDs: [], isSync: true});
      result.complete();
    }

    return result.asObservable();
  }

  public syncMitigationArtifacts(onlineMitigations: IMitigationDetail[]): Observable<any[]> {
    const mitigations: IMitigationDetail[] = [];
    const result = new ReplaySubject<any>(1);
    this.getMitigationDetailsInFileSystem(onlineMitigations).subscribe((contents: IMitigationDetail[]) => {
      this.zone.run(() => {
        const offlineSubmittedMitigationIDs: number[] = [];
        for (const content of contents) {
          if (content && content.Mitigation != null) {
            for (const detail of onlineMitigations) {
              if (detail.Mitigation.ID == content.Mitigation.ID) {
                if (content.Mitigation.Status == EaseMitigationStatus.Closed || (content.Mitigation.Status == EaseMitigationStatus.Pending && content.Mitigation.ApprovingManagerUserID != this.current.info.user.ID)) {
                  offlineSubmittedMitigationIDs.push(content.Mitigation.ID);
                }

                if (detail.Mitigation.Status != EaseMitigationStatus.Closed) {
                  this.log.debug("syncMitigations: " + content.Mitigation.ID);
                  detail.Mitigation = content.Mitigation;
                  mitigations.push(detail);
                }
                break;
              }
            }
          }
        }

        if (mitigations.length > 0 && this.tools.isOnline()) {
          this.processMitigations(mitigations).subscribe((mitigations: IMitigationActivity[]) => {
            let count = 0;
            if (mitigations.length > 0) {
              for (const mitigation of mitigations) {
                count++;
                if (mitigation.Status == EaseMitigationStatus.Closed || (mitigation.Status == EaseMitigationStatus.Pending && mitigation.ApprovingManagerUserID != this.current.info.user.ID)) {
                  this.deleteMitigationFiles(mitigation.ID, () => {
                    if (count == mitigations.length) {
                      result.next(offlineSubmittedMitigationIDs);
                      result.complete();
                    }
                  });
                } else {
                  if (count == mitigations.length) {
                    result.next(offlineSubmittedMitigationIDs);
                    result.complete();
                  }
                }
              }
            } else {
              result.next(offlineSubmittedMitigationIDs);
              result.complete();
            }
          });
        } else {
          result.next(offlineSubmittedMitigationIDs);
          result.complete();
        }
      });
    });

    return result.asObservable();
  }

  private getMitigationDetailsInFileSystem(onlineMitigations: IMitigationDetail[]) {
    const detailsInFileSystem: Array<Observable<any>> = [];
    for (const detail of onlineMitigations) {
      detailsInFileSystem.push(this.getFileContent(detail.Mitigation.ID));
    }
    if (detailsInFileSystem && detailsInFileSystem.length == 0) {
      //return empty file content observable if there are no mitigations to process
      detailsInFileSystem.push(Observable.create((result: any) => {
        result.next([]);
        result.complete();
      }));
    }
    return Observable.forkJoin(detailsInFileSystem);
  }

  private getFileContent(mitigationID: number): Observable<any> {
    return this.getMitigationDetailByID(mitigationID, "Mitigations").take(1).map(detail => {
      return detail;
    });
  }

  private processMitigations(mitigationDetails: IMitigationDetail[]) {
    const syncMitigations: Array<Observable<any>> = [];
    for (const detail of mitigationDetails) {
      syncMitigations.push(this.updateMitigation(detail.Mitigation));
    }
    return Observable.forkJoin(syncMitigations);
  }

  private updateMitigation(mitigation: IMitigationActivity): Observable<any> {
    const result = new ReplaySubject<any>(1);
    const isAdHoc: boolean = !mitigation.AuditID;
    let param = {};

    if (mitigation.Status == EaseMitigationStatus.Open && mitigation.Comment != null && mitigation.ApprovingPartyComment != null) {
      param = { sendRejectMitigationEmail: true, isAdHoc };
    } else if (mitigation.Status == EaseMitigationStatus.Pending) {
      param = { updateApprovingParty: true, isAdHoc };
    } else {
      param = { isAdHoc, isSubmittedFromOffline: mitigation.Status == EaseMitigationStatus.Closed };
    }

    this.api.UpdateX("Mitigation", mitigation.ID, mitigation, param, (data: any) => {
      this.zone.run(() => {
        result.next(mitigation);
        result.complete();
      });
    });
    return result.asObservable();
  }

  private deleteMitigationFiles(mitigationID: number, callback: any): void {
    // remove in mitigation infos from file system after
    const key = this.storage.buildCurrentStorageKey("Mitigations", mitigationID);
    if (this.tools.isApp()) {
      this.storage.deleteObject(key).subscribe(() => {
        this.zone.run(() => {
          if (callback) {
            callback();
          }
        });
      }, (err: any) => {
        this.log.error("failed to delete mitigation file " + JSON.stringify(err));
      });
    } else {
      const keyStr: string = this.storage.keyAsString(key);
      this.local.removeItem(keyStr);

      if (callback) {
        callback();
      }
    }
  }
}
