﻿import { Pipe, PipeTransform } from "@angular/core";

// Services
import { LocalizeService } from "@core/services";

@Pipe({ name: "yesNo" })
export class YesNoPipe implements PipeTransform {
  constructor(public localize: LocalizeService) {
  }

  transform(value: any): string {
    const yes = this.localize.getLocalizedString("_YesNo:Yes_");
    const no = this.localize.getLocalizedString("_YesNo:No_");

    return value ? yes : no;
  }
}
