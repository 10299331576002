import { timer } from "rxjs";
import { tap, map, takeWhile } from "rxjs/operators";

import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "ease-set-password-form",
  templateUrl: "./set-password-form.html",
  styleUrls: [ "./set-password-form.scss" ],
})
export class SetPasswordFormComponent implements OnDestroy {
  @Output() submitted = new EventEmitter<boolean>();
  @Output() passwordChanged = new EventEmitter<string>();

  @Input() pending: boolean;
  @Input() error: string;

  @Input() haveNumber: boolean;
  @Input() haveLowercase: boolean;
  @Input() haveUppercase: boolean;
  @Input() haveSpecialChar: boolean;
  @Input() haveLength: boolean;
  @Input() passwordsMatch: boolean;

  form: FormGroup;

  alive = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.form = new FormGroup({
      password: new FormControl("", [ Validators.required ]),
      confirmPassword: new FormControl("", [ Validators.required ]),
    }, [], [this.validatePassword.bind(this)]);

    this.form.valueChanges.pipe(
      takeWhile(_ => this.alive),
    ).subscribe(value => this.passwordChanged.emit(value));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  clear() {
    this.form.reset({
      password: "",
      confrimPassword: "",
    });
  }

  submit() {
    this.submitted.emit(true);
  }

  private validatePassword() {
    return timer(100).pipe(
      map(_ => {
        return [
          this.haveNumber, this.haveLowercase, this.haveUppercase,
          this.haveSpecialChar, this.haveLength, this.passwordsMatch,
        ]
          .reduce((acc, cur) => acc && cur, true);
      }),
      map(value => value ? null : { weakPassword: true }),
      tap(_ => this.changeDetectorRef.markForCheck()),
    );
  }
}
