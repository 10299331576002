import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { MaterialModule } from "../../material";

import { PipesModule } from "../pipes";

// Components
import { AdminControlBarComponent } from "./admin-control-bar.component";
import { PageScrollableContentDirective } from "./page-scrollable-content.directive";

const COMPONENTS = [
    AdminControlBarComponent,
    PageScrollableContentDirective,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MaterialModule,

        PipesModule,
    ],
    exports: [...COMPONENTS],
    declarations: [...COMPONENTS],
})
export class NavigationModule {}
