import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { LocalizeService } from "../../core/services/localize.service";

export interface IConfirmationModalData {
    title: string;
    message: string;
    lists?: string[];
    isConfirmationDialog: boolean;
    confirmButtonText: string;
    cancelButtonText?: string;
}

@Component({
    templateUrl: "./confirmation-modal.component.html",
})

export class ConfirmationModalComponent {

    public title: string;
    public message: string;
    public isConfirmationDialog: boolean;
    public lists: string[];

    public confirmButtonText: string = "_Yes_";
    public cancelButtonText: string = "_No_";

    constructor(
        public dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationModalData,
        private localize: LocalizeService) {

        this.initModal(data);
    }

    public initModal(data: IConfirmationModalData) {
        this.title = this.localize.getLocalizedString(data.title);
        this.message =  this.localize.getLocalizedString(data.message);
        this.isConfirmationDialog = data.isConfirmationDialog;
        this.lists = data.lists;

        if (!this.isConfirmationDialog) {
            this.confirmButtonText = "_OK_";
        }

        if (data.confirmButtonText != "") {
            this.confirmButtonText = data.confirmButtonText;
        }

        if (data.cancelButtonText != "") {
            this.cancelButtonText = data.cancelButtonText;
        }

        this.confirmButtonText = this.localize.getLocalizedString(this.confirmButtonText);
        this.cancelButtonText = this.localize.getLocalizedString(this.cancelButtonText);
    }

    public cancelHandler() {
        this.dialogRef.close(false);
    }
    public confirmHandler() {
        this.dialogRef.close(true);
    }
}
