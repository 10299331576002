import { Action } from "@ngrx/store";

export enum LayoutActionTypes {
  StartProgress = "[Layout] Start Progress",
  EndProgress = "[Layout] End Progress",
  OpenSidenav = "[Layout] Open Sidenav",
  CloseSidenav = "[Layout] Close Sidenav",
}

export class StartProgress implements Action {
  readonly type = LayoutActionTypes.StartProgress;
}

export class EndProgress implements Action {
  readonly type = LayoutActionTypes.EndProgress;
}

export class OpenSidenav implements Action {
  readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = LayoutActionTypes.CloseSidenav;
}

export type LayoutActions =
  | OpenSidenav
  | CloseSidenav
  | StartProgress
  | EndProgress;
