import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {LocalizeService} from "../core/services/localize.service";

@Component({
    templateUrl: "./detailed-image-modal.component.html",
})

export class DetailedImageModalComponent {

    detailedAuditImage: string;

    constructor(
        public dialogRef: MatDialogRef<DetailedImageModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private localize: LocalizeService) {

        this.initModal(data);
    }

    public initModal(data) {
        this.detailedAuditImage = data.detailedAuditImage;
    }
}