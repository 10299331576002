﻿import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild, Inject, Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { LoggerService } from "@core/services/logger.service";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { ArtifactService } from "@core/services/common/artifact.service";
import { AuditDetailService } from "@core/services/common/audit-detail.service";
import { PageStateService} from "@core/services/navigation/page-state.service";
import { ImageDialogComponent } from "@shared/dialogs/image-dialog/image-dialog.component";
import { AppSettingsService, CurrentInfoService, ApiService, Config, IdentityService,
         LocalService, LocalizeService, NotifyService, StorageKey, StorageService,
         ToolsService } from "@core/services";

import { EaseAuditStatus, IArtifact, IAudit, IAuditAnswer, IAuditAnswerArtifact, IAuditConfig, IAuditDetail,
    IAuditLineItem, IAuditQuestionArtifact, IAuditQuestionGroup, IClientAuditAnswer, IClientAuditQuestion, ICustomCategory, ICustomCategoryLineItem, ICustomFieldValue, ICustomItem, IDocumentArtifact,
    IDocumentRev, IFileFormat, IIssueClassification, IRank, IUser, MitigationResponseOption, PassRangeTypes, PhotoInputResponseOption, QuestionResponseTypes } from "../../core/models/ease-models";
import { IClientAuditQuestionArtifact } from "./audit-conduct.component.types";

import { Observable,  Subject,  Subscription } from "rxjs";

import * as _ from "lodash";
import * as moment from "moment";
import "rxjs/add/observable/timer";
import { DialogConfigSm } from "@shared/dialogs";
import { isNullOrUndefined } from "util";

// State
import { Store, select } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import { take } from "rxjs/operators";
import { RefreshToken } from "app/auth/actions/auth";

class EaseAuditAnswerUploaderObject {
    public questionID: number;
    public answerID: number;
    public uploader: any;
}

// from EaseAuditBaseController
enum EaseCommentResponseOption { Optional, RequiredOnFail, AlwaysRequired }

@Component({
    selector: "ease-audit-conduct",
    styleUrls: ["./audit-conduct.component.scss", "../../shared/navigation/admin-control-bar.component.scss"],
    templateUrl: "./audit-conduct.component.html",
})

export class AuditConductComponent implements OnInit, OnDestroy {
    @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("conductAuditForm") public conductAuditForm: NgForm;

    public isOffline: boolean = false;
    public showLastSavedOfflineDate: boolean = false;
    public stepsListCollapsed: boolean = false;
    public wizardCurrentStep: number = 0;
    public wizardLastStep: number = 0;
    public auditInfo: string;
    public loadingAuditDocSettings: boolean;
    public smallScreenModeEnabled: boolean = false;
    public DateLastSavedOffline: any;

    public auditConfig: IAuditConfig;

    public showAuditorSelection: boolean;
    public isNonConformance: boolean = false;
    public isAuditComplete: boolean = false;
    public isAuditInfoComplete: boolean = false;
    public isSaving: boolean = false;
    public isOkToExit: boolean = false;
    public isSubmitting: boolean = false;
    public defaultRankID: number;
    public isCountermeasuresEnabled: boolean = false;
    public uploaderObjs: EaseAuditAnswerUploaderObject[];
    public useFailureCategories: boolean;
    public failureCategories: ICustomCategory[];
    public failureModes: ICustomItem[];
    public totalPts: number = 0;
    public totalPossiblePts: number = 0;
    public isScoredDocument: boolean = false;
    public preloadedRanks: IRank[];
    public preloadedManagers: IUser[];
    public preloadedCustomCategoryLineItems: ICustomCategoryLineItem[];
    public preloadedAuditor: IUser;
    public customCategoryLineItems: ICustomCategoryLineItem[];

    public auditDetail: IAuditDetail;
    public savedInAuditMitigation: any;
    public fileFormats: IFileFormat[];

    public counter: number = 0;
    public offlineMessage: string = "Seems like you've gone offline. will attempt to save the audit in every 20 seconds";
    public timeIntervalInSeconds: number = 20;  // will attempt to save the audit in every 20 seconds

    // extends from EaseAuditBaseController
    public auditID: number = 0;
    public loading: boolean = false;
    public failedToLoadAudit: boolean = false;
    public isAuditDeleted: boolean = false;
    public errorCode: number = 0;
    public groupQuestions: boolean = false;
    public questionGroups: any[];
    public auditQuestions: IClientAuditQuestion[] = [];
    public isLongTextLanguage: boolean = false;
    public commentResponseOption: EaseCommentResponseOption;
    public hasMultipleChoiceQuestions: boolean;
    public hasPointBasedQuestions: boolean;
    public audit: IAudit;
    public scoredDocSetting = { MinimumPassingScoreEnabled: false, MinimumPassingScoreValue: 0 };
    public DateUpdated: Date;

    public auditDoc: IDocumentRev;
    public onePageAudit: boolean = false;
    public numImages: number = 0;
    public filteredFailureModes: ICustomItem[];

    // from scope
    public pageContentHeight = "540px";

    public timer: Observable<number>;
    public timerSubscription: Subscription;
    public imageIndex: number = 0;
    public attachmentCounter: number = 0;

    private subscription: Subject<void> = new Subject<void>();
    private isCordova: boolean = false;
    private timerStarted: boolean = false;
    private isFromAdHocAudits: boolean = false;
    private selectedSiteID: number = 0;
    private isLeftNavPinned: boolean = false;
    private leftNavStateSubscription: Subscription;
    private bootstrapSizeSubscription: Subscription;
    private pageHeightSubscription: Subscription;
    private savedUploadedFileArray: any[] = [];
    private destroy$ = new Subject<void>();

    private token: string;

    constructor(private store: Store<fromAuth.State>,
                private current: CurrentInfoService, private route: ActivatedRoute, private localize: LocalizeService,
                private config: Config,
                private local: LocalService,
                private notify: NotifyService,
                private api: ApiService,
                private identity: IdentityService,
                private tools: ToolsService,
                private router: Router,
                private log: LoggerService,
                private auditDetailService: AuditDetailService,
                private storage: StorageService,
                private zone: NgZone,
                private artifact: ArtifactService,
                private pageStateService: PageStateService,
                private dialog: MatDialog,
                private appSettings: AppSettingsService) {

      this.store.pipe(
        select(fromAuth.getToken),
        take(1),
      ).subscribe(value => this.token = value);
    }

    public onOnline(online: boolean) {
        this.log.debug("conduct: onOnline: " + online);
        this.isOffline = !online;
    }

    public ngOnInit(): void {
        this.loading = true;
        this.log.debug("conduct onInit: " + this.loading);

        const id = parseInt(this.route.snapshot.params.id, 10);
        this.route.queryParams.takeUntil(this.destroy$).subscribe(params => {
            this.isFromAdHocAudits = (params["isFromAdHocAudits"] === "true") || false;
            this.selectedSiteID = parseInt(params["selectedSiteID"], 10) || 0;
        });

        this.isCordova = this.tools.isCordova();
        this.stepsListCollapsed = this.isCordova; // TQP FIXME
        this.smallScreenModeEnabled = this.isCordova;  // TQP FIXME
        this.isCountermeasuresEnabled = this.appSettings.currentAppSettings.isCountermeasuresEnabled;

        this.notify.broadcast("full-page-block", true);
        this.auditDetailService.getAuditByID(id, "Audits", this.isFromAdHocAudits).subscribe((content: any) => {
            if (content) {
                this.zone.run(() => {
                    this.auditDetail = content.auditDetail;
                    this.savedInAuditMitigation = content.inAuditMitigation;
                    this.savedUploadedFileArray = content.uploadedFileArray;
                    this.initAuditDetail(id);
                    this.notify.online.asObservable().takeUntil(this.destroy$).subscribe((state: boolean) => this.onOnline(state));
                    this.timer = Observable.timer(2000, 1000);
                    this.timerSubscription = this.timer.subscribe((t: number) => this.onAutoSave());
                    this.timerStarted = true;
                });
            } else {
                this.log.debug("auditID not found: " + id);
            }

            this.registerPageStateChangeHandlers();
        });
    }

    public onAutoSave(): void {
        if (this.counter != null) {
            this.counter++;
            if (this.counter === this.timeIntervalInSeconds) {
                // attempt to save only when the form is idle & dirty and not on the submit page
                const reviewPage: boolean = this.wizardCurrentStep >= this.wizardLastStep;
                const submitInProgress: boolean = this.isSubmitting;
                const saveInProgress: boolean = this.isSaving;
                const isFormDirty: boolean = this.conductAuditForm !== null && this.conductAuditForm.dirty;

                if (isFormDirty && !saveInProgress && !submitInProgress && !reviewPage) {
                    this.saveChanges(this.audit.Status, () => {
                      if (this.conductAuditForm) {
                        this.conductAuditForm.form.markAsPristine();
                      }
                    });
                }
                this.counter = 0; // reset the counter
            }
        }
    }

    public ngOnDestroy(): void {
        // unsubscribe to ensure no memory leaks
        if (this.timerStarted) { this.timerSubscription.unsubscribe(); }   // stop auto save
        if (this.leftNavStateSubscription) { this.leftNavStateSubscription.unsubscribe(); }
        if (this.bootstrapSizeSubscription) { this.bootstrapSizeSubscription.unsubscribe(); }
        if (this.pageHeightSubscription) { this.pageHeightSubscription.unsubscribe(); }

        this.subscription.next();
        this.subscription.complete();

        this.destroy$.next();
        this.destroy$.complete();
    }

    public initAuditDetail(id: number): void {
        this.uploaderObjs = [];
        this.loadingAuditDocSettings = true; // this will prevent the ng-ease-in-audit-mitigation directive to be instantiated to early

        if (this.auditDetail != null) {
            this.customCategoryLineItems = this.auditDetail.CustomCategoryLineItems;
            this.getFailureCategories(this.auditDetail.FailureCategoriesCustomCategoryLineItems);
            this.getAuditDoc(id, this.auditDetail);
            this.formatQuestionsText();

            this.getManagers(this.auditDetail.Managers);
            this.getCustomItems(this.auditDetail.CountermeasuresCustomCategoryLineItems);
            this.preloadedAuditor = this.auditDetail.UserDetails;

            this.preloadedRanks = this.auditDetail.Ranks;
            // get first available rank as default rank
            if (this.preloadedRanks != null && this.preloadedRanks.length > 0) {
                this.defaultRankID = this.preloadedRanks[0].ID;
            }

            this.fileFormats = this.auditDetail.AcceptedFileFormats;

            if (this.audit !== undefined && this.auditConfig !== undefined) {
                this.setActiveSiteAndAuditDoc();
            }

            if (this.tools.isApp() && this.isFromAdHocAudits) {
              this.writeArtifacts(this.auditDetail.DocumentArtifacts, "AuditReferencesArtifact");
            }
        } else {
            this.failedToLoadAudit = true;
            this.loading = false;
            // display error message
        }
    }

    public getAuditTypeDetails(): string {
        let results = "";
        let targets = 0;

        const uniqueTargets = this.auditConfig.AuditTargets.filter((v, i, a) => a.indexOf(v) === i);
        uniqueTargets.forEach((target) => {

            if (targets > 0) {
                results += ", ";
            }
            switch (target) {
                // Location
                case 1: {
                    results += this.localize.getLocalizedString("_Location_") + ": " + this.audit.Location.Name;
                    break;
                }
                // Job
                case 2: {
                    if (this.audit.Job != null) { results += this.localize.getLocalizedString("_Job_") + ": " + this.audit.Job.Description; }
                    break;
                }
                // Customer
                case 4: {
                    results += this.localize.getLocalizedString("_Customer_") + ": " + this.audit.CompanySiteID;
                    break;
                }
            }
            targets++;
        });
        return results;
    }

    public setCustomFieldValues(): void {
        _.each(this.auditQuestions, (q: IClientAuditQuestion) => {
            const answer = q.AuditAnswer as IClientAuditAnswer;
            let counter = 0; // to set custom field value's ID for conduct audit so that custom items used in different custom categories won't have the same mitigation values
            _.each(q.customCategories, (c: ICustomCategory) => {
                _.each(c.CustomItems, (item) => {
                    counter++;
                    const filtered: any[] = answer.CustomFieldValues.filter((fieldValue: any) => fieldValue.ParentEntityID === this.audit.ID &&
                                            fieldValue.EntityID === answer.ID && fieldValue.FieldValueCustomItemID === item.ID && fieldValue.FieldValueCustomCategoryID === c.ID &&
                                            fieldValue.OrganizationID === item.OrganizationID);
                    if (item.IsSelected) {
                        if (filtered.length === 0) {
                            const cfv: any = { // Ease.ICustomFieldValue
                                ID: counter,
                                FieldValueCustomItemID: item.ID,
                                FieldValueCustomCategoryID: c.ID,
                                EntityID: answer.ID,
                                ParentEntityID: this.audit.ID,
                                OrganizationID: item.OrganizationID,
                                CustomFieldID: q.CustomFieldID,
                            };

                            answer.CustomFieldValues.push(cfv);
                            item.IsSelected = false;
                            item.CustomFieldValue = cfv;

                        } else {
                            item.IsSelected = true;
                            item.CustomFieldValue = filtered[0];
                            if (filtered[0].MitigationActivity == null) {
                                filtered[0].MitigationActivity = {};

                                if (filtered[0].MitigationActivity.RankID == null || filtered[0].MitigationActivity.RankID === 0) {
                                    filtered[0].MitigationActivity.RankID = this.defaultRankID;
                                }

                                if (filtered[0].MitigationActivity.ResponsiblePartyUserID == null || filtered[0].MitigationActivity.ResponsiblePartyUserID === 0) {
                                    filtered[0].MitigationActivity.ResponsiblePartyUserID = this.audit.ShiftManagerID;
                                }
                            }
                        }
                    }
                });
            });

            // initialized customFieldValues
            if ((answer.CustomFieldValues && answer.CustomFieldValues.length === 0) &&
                (q.QuestionType !== QuestionResponseTypes.MultipleChoice && q.QuestionType !== QuestionResponseTypes.MultiSelect)) {
                const newCustomFieldValue = {} as ICustomFieldValue;    // create empty object of an interface
                answer.CustomFieldValues.push(newCustomFieldValue);
            }
        });
    }

    public toggleCollapseStepsList(): void {
        this.stepsListCollapsed = !this.stepsListCollapsed;
    }

    public wizardPrev(): void {
        this.wizardSetStep(this.wizardCurrentStep - 1);
    }

    public wizardNext(): void {
        this.wizardSetStep(this.wizardCurrentStep + 1);
    }

    // Handles question validation. This is where QUESTION VALIDATION happens
    public wizardSetStep(step: number): void {
        let questionRequired: boolean = false;
        const sectionComplete: boolean = true;
        const adjustedStep: number = -1;
        const indx: number = 0;
        if (this.questionGroups.length > 0) {
            _.each(this.auditQuestions, (q) => {
                questionRequired = this.checkQuestionRequired(q) || questionRequired;
            });
        } else {
            if (this.auditQuestions.length > 0 && this.wizardCurrentStep < this.auditQuestions.length) {
                let question: IClientAuditQuestion;
                question = this.auditQuestions[this.wizardCurrentStep];
                questionRequired = this.checkQuestionRequired(question);
                question.isQuestionRequired = questionRequired;
            }
        }

        _.each(this.auditQuestions, (question) => {
            this.getFailureDescriptions(question);
        });

        this.wizardCurrentStep = step;
        this.imageIndex = 0;

        const auditStatus = this.getOverallAuditStatus();
        if (this.wizardCurrentStep < this.auditQuestions.length) {
            if (this.auditQuestions[this.wizardCurrentStep].AuditAnswer.IssueClassification != null) {
                this.filterModesByCategory(this.auditQuestions[this.wizardCurrentStep].AuditAnswer.IssueClassification.FailureCategoryID);
            }
        }
    }

    public getCustomCategoryByID(id: number): ICustomCategory {
        const customCategoryID = typeof id === "string" ? parseInt(id, 10) : id;
        const filtered: any = this.failureCategories.filter((cat: ICustomCategory) => cat.ID === customCategoryID);
        return (filtered.length !== 0) ? filtered[0] : null;
    }

    // from AuditBase
    public getFailureModeByID(id: number): ICustomItem {
        const failureModeID = typeof id === "string" ? parseInt(id, 10) : id;
        const filtered: any[] = this.failureModes.filter((modes: any) => modes.ID === failureModeID);
        return (filtered.length !== 0) ? filtered[0] : null;
    }

    // from AuditBase
    public filterModesByCategory(catID: any): void {
        this.filteredFailureModes = this.failureModes.filter((mode: ICustomItem) =>
                                    mode.CustomCategoryID === parseInt(catID, 10));
    }

    // The purpose of this function is to really set the "dirty" flag.  Since we're not using a standard form input for pass/fail type questions, we have to explicity set the "dirty" flag
    public setPointValue(auditAnswer: IAuditAnswer, pointValue: number, question?: IClientAuditQuestion): void {
        auditAnswer.PointValue = pointValue;
        if (pointValue === 1 && this.auditQuestions[this.wizardCurrentStep].PhotoInputResponseOption === PhotoInputResponseOption.RequiredOnFail) {
            const answer = auditAnswer as IClientAuditAnswer;
            answer.photoRequired = false;
            if (this.auditQuestions[this.wizardCurrentStep].AltResponsiblePartyID != null) {
                answer.AltManagerID = this.auditQuestions[this.wizardCurrentStep].AltResponsiblePartyID;
            }
        }
        this.setAnswersIsPassedFlag();
        this.getOverallAuditStatus();
        this.conductAuditForm.form.markAsDirty();
        this.onChange.emit(this.auditQuestions);
    }

    public setNumericIsPassedFlag(question: IClientAuditQuestion, isSubmit?: boolean): void {
        if (!isSubmit) {
            question.AuditAnswer.IsNA = false;
            question.AuditAnswer.IsPassed = null;
        }

        let value;
        if (question.AuditAnswer.CustomFieldValues.length > 0) {
            value = question.AuditAnswer.CustomFieldValues[0].FieldValueNum;
        }

        if (value != null && question.IsPassFailType && question.AuditAnswer.IsNA === false) {
            question.AuditAnswer.IsPassed = true; // default
            this.audit.AuditSummary.TotalPassed += 1;
            const comparison: number = question.Comparison;
            switch (comparison) {
                case PassRangeTypes.Between:
                    if (value < question.PassingMin || value > question.PassingMax) {
                        question.AuditAnswer.IsPassed = false;
                        this.audit.AuditSummary.TotalFailed += 1;
                        this.audit.AuditSummary.TotalPassed -= 1;
                    }
                    break;

                case PassRangeTypes.Outside:
                    if (value >= question.PassingMin && value <= question.PassingMax) {
                        question.AuditAnswer.IsPassed = false;
                        this.audit.AuditSummary.TotalFailed += 1;
                        this.audit.AuditSummary.TotalPassed -= 1;
                    }
                    break;

                case PassRangeTypes.LessThan:
                    if (value > question.PassingMax) {
                        question.AuditAnswer.IsPassed = false;
                        this.audit.AuditSummary.TotalFailed += 1;
                        this.audit.AuditSummary.TotalPassed -= 1;
                    }
                    break;

                case PassRangeTypes.GreaterThan:
                    if (value < question.PassingMin) {
                        question.AuditAnswer.IsPassed = false;
                        this.audit.AuditSummary.TotalFailed += 1;
                        this.audit.AuditSummary.TotalPassed -= 1;
                    }
                    break;
            }
        }

        // added validation for number (min and max)
        if (value != null) {
            if (value < question.MinValue || value > question.MaxValue) {
                if (question.IsRequired) { question.AuditAnswer.IsPassed = null; }
                question.AuditAnswer.CustomFieldValues[0].FieldValueNum = null;
            }
        }
    }

    public setNAValue(question: IClientAuditQuestion): void {
        question.AuditAnswer.IsNA = true;
        question.AuditAnswer.IsPassed = null;
        this.audit.AuditSummary.TotalNA += 1;
        question.AuditAnswer.IsOptionalMitigationSelected = false;
        if (question.AuditAnswer.CustomFieldValues.length > 0) {
            question.AuditAnswer.CustomFieldValues[0].FieldValueNum = null;
        }

        this.getOverallAuditStatus();
        this.conductAuditForm.form.markAsDirty();
        this.onChange.emit(this.auditQuestions);
    }

    public removeImageFromQuestion(img: any, question: any): void {
        const id = img.ID;

        if (id > 0) {
            this.notify.broadcast("full-page-block", true);
            this.removeAuditUploadedAnswerFileFromFileSystem(img, true);
            const index = question.AuditAnswer.images.indexOf(img);
            question.AuditAnswer.images.splice(index, 1);

            if (this.tools.isApp()) {
                const artifactIndex = this.auditDetail.AuditAnswerArtifacts.indexOf(img);
                if (artifactIndex !== -1) {
                    this.auditDetail.AuditAnswerArtifacts.splice(artifactIndex, 1);
                }
                this.saveFileToDeleteToFileSystem(img);
                this.attachmentCounter++;
                this.notify.broadcast("full-page-block", false);
            } else {
              this.auditDetailService.deleteAuditAnswerArtifact(id).subscribe(() => {
                this.storage.deleteImage(img.Url).subscribe(() => {
                  this.attachmentCounter++;
                  this.notify.broadcast("full-page-block", false);
                });
              }, (err: any) => {
                if (err.status === 200) {
                  const key = this.storage.buildCurrentStorageKey("AnswerArtifact", this.auditID);
                  const keyStr: string = this.storage.keyAsString(key);
                  this.local.removeItem(keyStr);
                  this.attachmentCounter++;
                  this.notify.broadcast("full-page-block", false);
                } else {
                  this.saveFileToDeleteToFileSystem(img);
                  this.attachmentCounter++;
                  this.notify.broadcast("full-page-block", false);
                }
              });
            }
        } else if (img.AuditAnswerID > 0) { // id<=0 , but we have AuditAnswerID, just remove from local storage and also remove thumbnail
            // this is for questions uploaded in offline mode and then deleted, without being sent fist to server
            this.notify.broadcast("full-page-block", true);
            this.removeAuditUploadedAnswerFileFromFileSystem(img, true);
            this.storage.deleteImage(img.Url).subscribe(() => {
                this.notify.broadcast("full-page-block", false);
            });

            // remove from queue
            const filtered = this.uploaderObjs.filter((obj: any) => obj.answerID === img.AuditAnswerID);
            if (filtered && filtered.length !== 0) {
                if (filtered[0].uploader.queue && filtered[0].uploader.queue.length > 0) {
                    for (const item of filtered[0].uploader.queue) {
                        if (item._file.name === img.Filename) {
                            item.remove();
                            break;
                        }
                    }
                }
            }

            const index = question.AuditAnswer.images.indexOf(img);
            question.AuditAnswer.images.splice(index, 1);
            this.attachmentCounter++;
        }
    }

    public onScoreChanged() {
        this.setAnswersIsPassedFlag();
        this.conductAuditForm.form.markAsDirty();
        this.onChange.emit(this.auditQuestions);
    }

    public onCustomItemClicked(item: ICustomItem, question: IClientAuditQuestion): void {
        const newValue = item.IsSelected;

        // In a multiple choice response, we only allow one selection so unselect all
        if (newValue === true && question.QuestionType === QuestionResponseTypes.MultipleChoice) {
            _.each(question.customCategories, (cat: ICustomCategory) => {
                _.each(cat.CustomItems, (customItem: ICustomItem) => {
                    customItem.IsSelected = false;
                    customItem.CustomFieldValue = null;
                });
            });
        }
        item.IsSelected = newValue;

        if (item.IsSelected) {
            const ma: any = { // Ease.IMitigationActivity
                RankID: this.defaultRankID,
                ResponsiblePartyUserID: this.audit.ShiftManagerID,
                Comment: null,
            };

            const cfv: ICustomFieldValue = {
                FieldValueCustomItemID: item.ID,
                FieldValueCustomCategoryID: item.CustomCategoryID,
                EntityID: question.AuditAnswer.ID,
                ParentEntityID: this.audit.ID,
                OrganizationID: item.OrganizationID,
                CustomFieldID: question.CustomFieldID,
                MitigationActivity: ma,
                MitigateNow: false,
            };

            item.CustomFieldValue = cfv;
        } else {
            item.CustomFieldValue = null;
        }
        this.checkQuestionRequired(question);
    }

    public markAllPassed(): void {
        _.each(this.auditQuestions, (q) => {
            if (q.AuditAnswer.PointValue == null) {
                q.AuditAnswer.PointValue = q.PointValue;

                if (q.PointValue > 1) {
                    q.AuditAnswer.PointValue++;
                }
            }
        });
    }

    public changeEvent(event: any[]) {
        this.auditQuestions = event;
    }

    public submitAudit(): void {
        if (!this.isSubmitting) {
            this.isSubmitting = true;
            this.notify.broadcast("full-page-block", true);
            this.setAnswersIsPassedFlag();
            this.saveChanges(EaseAuditStatus.Complete, () => {
                this.conductAuditForm.form.markAsPristine();
                this.notify.broadcast("full-page-block", false);
                if (this.tools.isApp()) {
                  this.zone.run(() => {
                    this.isSubmitting = false;
                    if (this.tools.isOnline()) {
                      this.auditDetailService.updatePendingUpload(this.auditID).subscribe(() => {
                        this.router.navigate(["/"]); // navigate to home page
                      });
                    } else {
                      this.router.navigate(["/"]); // navigate to home page
                    }
                  });
                } else {
                  this.auditDetailService.deleteSavedAudit(this.auditID, this.auditDetail.Artifacts, () => {
                    this.zone.run(() => {
                      this.isSubmitting = false;
                      this.router.navigate(["./complete"], {relativeTo: this.route});
                    });
                 });
                }
            });
        }
    }

    public formatQuestionsText(): void {
        _.each(this.auditQuestions, (question: IClientAuditQuestion) => {
            if (question.QuestionText) {
                try {
                    const text = this.createHyperLinks(question.QuestionText);
                    question.QuestionTextFormatted = text.replace(/\n/g, "<br/>");
                } catch (e) {

                }
            }

            if (question.ReasonText) {
                try {
                    const text = this.createHyperLinks(question.ReasonText);
                    question.ReasonTextFormatted = text.replace(/\n/g, "<br/>");
                } catch (e) {

                }
            }
            if (question.ReactionPlan) {
                try {
                    const text = this.createHyperLinks(question.ReactionPlan);
                    question.ReactionPlanFormatted = text.replace(/\n/g, "<br/>");
                } catch (e) {

                }
            }
        });
    }

    public getAuditQuestionImage(questionArtifact: IClientAuditQuestionArtifact): void {
        if (questionArtifact) {
            if (this.storage.isDoc(questionArtifact.FileFormatID)) {
                // download file
                const link = document.createElement("a");
                link.download = "a";
                link.href = questionArtifact.OriginalArtifactUrl;
                document.body.appendChild(link);
                link.click();
            } else {

                const config = new DialogConfigSm<string>();
                config.panelClass = "img-dialog";
                config.data = questionArtifact.Url ;

                this.dialog.open(ImageDialogComponent, config);
            }
        }
    }

    // Adjusts page elements based on the "state" of the page.  Height, width, nav pinned state, etc....
    private registerPageStateChangeHandlers() {
        if (!this.tools.isApp()) {
            this.leftNavStateSubscription = this.pageStateService.getIsLeftNavPinned().subscribe((state: boolean) => { this.isLeftNavPinned = state; });
            this.bootstrapSizeSubscription = this.pageStateService.getBootStrapSizeChange().subscribe((size: string) => {
                this.stepsListCollapsed = (size === "xs" || size === "sm");
                this.smallScreenModeEnabled = (size === "xs" || size === "sm");
            });
        }
        // TQP TODO: Replace this with [scrollableArea] directive
        this.pageHeightSubscription = this.pageStateService.getPageHeightChange().subscribe((height: number) => {
          this.pageContentHeight = this.auditDetailService.auditHasReferences && this.smallScreenModeEnabled ? height - 212  + "px" :
          this.smallScreenModeEnabled ? height - 152 + "px" : height - 127 + "px";
        });
    }

    private getFailureCategories(customCategoryLineItem: ICustomCategoryLineItem[]): void {
        const categories: ICustomCategory[] = [];
        const items: ICustomItem[] = [];

        _.each(customCategoryLineItem, (li: ICustomCategoryLineItem) => {
            const filtered = categories.filter((cat: ICustomCategory) => cat.ID === li.CustomCategoryID);
            if (filtered.length === 0) {
                categories.push(li.CustomCategory);
            }

            li.CustomItem.CustomCategoryID = li.CustomCategoryID;
            li.CustomItem.CustomCategory = li.CustomCategory;
            items.push(li.CustomItem);
        });

        this.failureModes = items;
        this.filteredFailureModes = items;
        this.failureCategories = categories;
    }

    private getAuditDoc(paramID: number, auditDetail: IAuditDetail): void {
        const audit: IAudit = auditDetail.Audit;
        const auditDoc: IDocumentRev = auditDetail.DocumentRev;
        const auditQuestionGroups: IAuditQuestionGroup[] = auditDetail.AuditQuestionGroups;
        const artifacts: IArtifact[] = auditDetail.Artifacts;
        const answerArtifacts: IAuditAnswerArtifact[] = auditDetail.AuditAnswerArtifacts;

        this.auditID = paramID;
        this.DateUpdated = (audit != null) ? audit.DateUpdated : null;

        this.auditConfig = this.auditDetail.AuditConfiguration;
        this.audit = audit;

        if (this.audit == null) {
            // Unable to retrieve audit.  Most likely a permissioning issue
            this.failedToLoadAudit = true;
            this.loading = false;
            // error code?
        } else {
            if (audit.DocumentRevID != null) {
                this.auditDoc = auditDoc;
                if (this.auditDoc == null) {
                    // Unable to retrieve audit.  Most likely a permissioning issue
                    this.failedToLoadAudit = true;
                    this.loading = false;
                    return;
                }

                const props = this.auditDoc.Document.Properties.hasOwnProperty("OnePageAudit") ? this.auditDoc.Document.Properties : this.auditDoc.Properties;

                this.onePageAudit = props.OnePageAudit === "1";
                this.groupQuestions = props.GroupQuestions === "1" || this.onePageAudit;
                this.commentResponseOption = props.CommentResponseOption !== "0" || parseInt(props.CommentResponseOption, 10) > 0 ? 1 : 0;

                if (this.auditConfig.IsPointBased) {
                    this.scoredDocSetting.MinimumPassingScoreEnabled = (props.MinimumPassingScoreEnabled === "1") ? true : false;
                    if (props.MinimumPassingScoreValue != null) {
                        this.scoredDocSetting.MinimumPassingScoreValue = parseInt(props.MinimumPassingScoreValue, 10);
                    }
                }

                this.getQuestionGroups(this.audit.Description, auditQuestionGroups);
                this.getAuditQuestions(this.auditID, auditDetail);
                this.removeEmptyGroupQuestions();
            } else {
                if (this.audit.Status === 0 || this.audit.Status === 3 || this.audit.Status === 5) {
                    this.getQuestionGroups(this.audit.Description, auditQuestionGroups);
                    this.getAuditQuestions(this.auditID, auditDetail);
                    this.removeEmptyGroupQuestions();
                }
            }
        }
    }

    private getQuestionGroups(auditDescription: string, auditQuestionGroups: IAuditQuestionGroup[]): void {
        auditQuestionGroups = _.orderBy(auditQuestionGroups, [questionGroup => questionGroup.Sequence], ["asc"]);
        this.questionGroups = [];
        if (this.groupQuestions) {
            // this will handle the Group 'None'
            this.questionGroups.push({ ID: null, Name: "", Questions: [] });

            for (const questionGroup of auditQuestionGroups) {
                const desc = (this.onePageAudit) ? auditDescription : questionGroup.Description;
                this.questionGroups.push({ ID: questionGroup.ID, Name: desc, Questions: [] });
            }
        }
    }

    private getAuditQuestions(auditID: number, auditDetail: IAuditDetail): void {
        this.numImages = 0;
        if (auditID != null) {
            this.auditQuestions = [];

            let auditLineItem: IAuditLineItem[] = auditDetail.AuditLineItems;
            const auditAnswerArtifacts: IAuditAnswerArtifact[] = auditDetail.AuditAnswerArtifacts;
            const auditQuestionArtifacts: IAuditQuestionArtifact[] = auditDetail.AuditQuestionArtifacts;
            const documentArtifacts: IDocumentArtifact[] = auditDetail.DocumentArtifacts;

            auditLineItem = _.orderBy(auditLineItem, [lineItem => lineItem.SequenceNum], ["asc"]);
            _.each(auditLineItem, (li: IAuditLineItem, index: number) => {
                const question: IClientAuditQuestion = (li.AuditQuestion as IClientAuditQuestion);
                const answer: IClientAuditAnswer = (question.AuditAnswer as IClientAuditAnswer);

                question.images = [];
                answer.images = [];
                question.currentImageIndex = 0;
                answer.IsOptionalMitigationSelected = false; // default to false
                // set the "IsRequired" field for each question
                question.IsRequired = li.IsRequired;
                question.AltResponsiblePartyID = li.AltResponsiblePartyID;
                question.AuditQuestionGroupID = li.AuditQuestionGroupID;
                const questionItems = (question != null && question.QuestionText != null) ? question.QuestionText.split(" ") : [];
                if (questionItems.length > 0) {
                    for (const questionItem of questionItems) {
                        question.WordBreakAll = (questionItem.length > 50);
                    }
                }

                this.auditQuestions.push(question);

                if (index === 0 && answer.IssueClassificationID != null) {
                    this.filterModesByCategory(answer.IssueClassification.FailureCategoryID);
                }

                // Populate question & answer with failure mode information
                if (answer.IssueClassification != null && answer.IssueClassification.CustomCategory == null) {
                    // assign selected failure category / custom category
                    answer.IssueClassification.CustomCategory = this.getCustomCategoryByID(answer.IssueClassification.FailureCategoryID);

                    // assign selected failure mode/ custom item
                    answer.IssueClassification.CustomItem = this.getFailureModeByID(answer.IssueClassification.FailureModeID);
                }

                // If we're grouping questions by question group...
                if (this.groupQuestions) {
                    const results: any[] = this.questionGroups.filter((questionGroup: any) => questionGroup.ID === li.AuditQuestionGroupID);
                    if (results.length !== 0) {
                        results[0].Questions.push(question);
                    }
                }

                if (question.QuestionType === QuestionResponseTypes.Point) {
                    this.hasPointBasedQuestions = true;
                }

                if (question.CustomField != null) {
                    this.loadCustomFields(question, () => {
                        this.initializeCustomFieldValues(question);
                    });
                }

                // for multi-select questions with mitigation required, to separate details by custom items
                question.DisplayCustomItemsInGroup = question.QuestionType !== QuestionResponseTypes.MultiSelect ||
                                                     (question.QuestionType === QuestionResponseTypes.MultiSelect && !(question.MitigationResponseOption > 0));

                // loadQuestionAnswerImages
                this.loadQuestionAnswerImages(question, answer, auditQuestionArtifacts, auditAnswerArtifacts);
            });

        }

    }

    private loadCustomFields(question: IClientAuditQuestion, callback?: any): void {
        const customField = question.CustomField;

        if (customField != null) {
            if (question.QuestionType === QuestionResponseTypes.MultipleChoice ||
                question.QuestionType === QuestionResponseTypes.MultiSelect) {

                this.hasMultipleChoiceQuestions = true;

                const groupID = customField.RestrictToGroup;
                const restrict = customField.RestrictRange;

                const filteredCustomCategoryLineItems = this.getCustomCategoryLineItemByCustomGroupID(groupID);
                // Based off the custom category line item, create a list of categories and nest the custom item under those categories
                // Restrict category values based on the custom field definition
                let validValues: any[] = [];
                if (restrict != null) {
                    validValues = restrict.split(",");
                }

                const categories: ICustomCategory[] = [];
                _.each(filteredCustomCategoryLineItems, (li: ICustomCategoryLineItem) => {
                    if (restrict == null || validValues.indexOf(li.CustomCategoryID.toString()) >= 0) {
                        const catID = li.CustomCategoryID;
                        const filtered = categories.filter((cat: ICustomCategory) => cat.ID === catID);
                        if (filtered.length === 0) {
                            li.CustomCategory.CustomItems = [];
                            li.CustomCategory.CustomItems.push(li.CustomItem);
                            categories.push(li.CustomCategory);
                        } else {
                            filtered[0].CustomItems.push(li.CustomItem);
                        }
                    }
                });
                question.customCategories = _.cloneDeep(categories);    // create new object of categories
                if (callback) { callback(); }
            } else if (question.QuestionType === QuestionResponseTypes.Numeric) {
                const restrictRange = customField.RestrictRange;

                if (restrictRange != null) {
                    const dashCount = restrictRange.split(/(?=-)/);

                    if (dashCount.length === 2) { // range is negative to positive or positive to positive
                        question.MinValue = parseFloat(dashCount[0]);
                        question.MaxValue = parseFloat(dashCount[1].replace("-", ""));
                    } else if (dashCount.length === 3) { // range is negative to negative
                        question.MinValue = parseFloat(dashCount[0]);
                        question.MaxValue = parseFloat(dashCount[2]);
                    }
                }
            }
        } else if (callback) { callback(); }
    }

    private initializeCustomFieldValues(question: IClientAuditQuestion): void {
        const answer = question.AuditAnswer as IClientAuditAnswer;

        if (answer.CustomFieldValues != null && answer.CustomFieldValues.length > 0) {

            if (question.QuestionType === QuestionResponseTypes.MultipleChoice || question.QuestionType === QuestionResponseTypes.MultiSelect) {
                _.each(question.customCategories, (cat: ICustomCategory) => {
                    _.each(cat.CustomItems, (item: ICustomItem) => {
                        const filtered = answer.CustomFieldValues.filter((fieldValue: any) => fieldValue.FieldValueCustomItemID === item.ID && fieldValue.FieldValueCustomCategoryID === cat.ID);
                        if (filtered.length !== 0) {
                            item.IsSelected = true;
                            cat.IsSelected = true;

                            if (!isNullOrUndefined(filtered[0].MitigationActivity) && !isNullOrUndefined(filtered[0].MitigationActivity.Rank)) {
                                filtered[0].MitigationActivity.ResolutionPeriod = moment(filtered[0].MitigationActivity.DateOpened)
                                                                                    .add("day", filtered[0].MitigationActivity.Rank.ResolutionPeriod).utc().toDate();
                            }
                        }
                    });
                });
                if (question.QuestionType === QuestionResponseTypes.MultiSelect && question.MitigationResponseOption === MitigationResponseOption.Optional) {
                    answer.IsOptionalMitigationSelected = true;
                }
            }
        }
    }

    private getCustomCategoryLineItemByCustomGroupID(customGroupID: number): ICustomCategoryLineItem[] {
        let results: ICustomCategoryLineItem[] = [];
        if (this.customCategoryLineItems && this.customCategoryLineItems.length > 0) {
            results = this.customCategoryLineItems.filter((c: ICustomCategoryLineItem) => c.CustomCategory && c.CustomCategory.CustomGroupID === customGroupID);
        }
        return results;
    }

    private getManagers(managers: IUser[]): void {
        this.preloadedManagers = [];
        _.each(managers, (manager: IUser) => {
            manager.FullName = manager.FirstName + " " + manager.LastName;
            this.preloadedManagers.push(manager);
        });
        this.preloadedManagers = _.orderBy(this.preloadedManagers, [manager => manager.FullName.toLowerCase()], ["asc"]);
    }

    private getCustomItems(customCategoryLineItem: ICustomCategoryLineItem[]): void {
        this.preloadedCustomCategoryLineItems = [];
        _.each(customCategoryLineItem, (li: ICustomCategoryLineItem) => {
            li.CustomItem.Name = this.localize.getLocalizedString(li.CustomItem.Name);
        });

        if (customCategoryLineItem !== undefined && customCategoryLineItem != null) {
            this.preloadedCustomCategoryLineItems = customCategoryLineItem;
        }

        let firstCustomCategoryID = 0;

        if (this.preloadedCustomCategoryLineItems.length > 0) {
            firstCustomCategoryID = customCategoryLineItem[0].CustomCategoryID;
        } else {
            this.isCountermeasuresEnabled = false;
        }
    }

    private setActiveSiteAndAuditDoc(): void {
        let imgUploadUrl = "";
        if (this.audit.SiteID && this.current.info.activeSite &&
            ((this.audit.SiteID !== this.current.info.activeSite.ID && !this.isFromAdHocAudits) || (this.audit.SiteID !== this.selectedSiteID && this.isFromAdHocAudits))) {
            this.store.dispatch(new RefreshToken(this.audit.SiteID));
        } else {
            this.auditInfo = this.getAuditTypeDetails();

            if (this.auditConfig.IsPointBased) {
                this.isScoredDocument = true;
            }

            this.loadInAuditMitigationFromFileSystem();

            this.setCustomFieldValues();
            this.loadingAuditDocSettings = false;

            // Add file upload control to each question
            const host = this.identity.endpoints.Service;
            if (host.length > 0) {
                imgUploadUrl = host + "/auditanswerartifact";
            }

            this.zone.run(() => {
                _.each(this.auditQuestions, (question) => {
                    const answer = question.AuditAnswer;
                    if (answer.AltManagerID == null && question.AltResponsiblePartyID != null) {
                        answer.AltManagerID = question.AltResponsiblePartyID;
                    }

                    if (question.PointValue > 1) {
                        this.totalPts += (answer.PointValue - 1);
                    }
                    this.totalPossiblePts += question.PointValue;
                    if (question.PhotoInputResponseOption > 0) {
                        const auditUploader = new FileUploader({ url: imgUploadUrl, queueLimit: 10, removeAfterUpload: true });
                        const queue: any[] = [];
                        const self = this; // we need the current context inside the "onErrorItem" event call

                        auditUploader.onAfterAddingFile = (item) => {
                            const auditAnswer = question.AuditAnswer as IClientAuditAnswer;
                            auditAnswer.photoRequired = false;

                            if (this.tools.isApp()) {
                                this.notify.broadcast("full-page-block", true);
                                this.saveUploadedFileToFileSystem(item, auditAnswer);
                            } else {
                                this.saveUploadedImages(() => {

                                });
                            }
                        };

                        auditUploader.onErrorItem = this.zone.run(() => (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
                            const error = JSON.parse(response); // error server response

                            const exceptionErrorMessage = "FileNotSupportedException:";
                            if (error && error.Message && error.Message.indexOf(exceptionErrorMessage) !== -1) {
                                error.Message = error.Message.substring(exceptionErrorMessage.length);
                            } else {
                                this.isOffline = true;
                            }

                            self.saveUploadedFileToFileSystem(item, answer);
                            self.saveChanges(self.audit.Status, () => {
                              self.conductAuditForm.form.markAsPristine();
                            });
                        });

                        this.uploaderObjs.push({
                            questionID: question.ID, answerID: question.AuditAnswer.ID, uploader: auditUploader,
                        });

                        auditUploader.onSuccessItem = this.zone.run(() => (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
                            if (this.tools.isApp()) {
                                const data = JSON.parse(response); // success server response
                                self.saveUploadedFileToFileSystem(item, answer, parseInt(response, 10));
                            } else {
                                this.api.Get("AuditAnswerArtifact", null, parseInt(response, 10), (img: any) => {
                                    if (img != null) {
                                        img.Url = this.setImageUrl(img, null);
                                        answer.images.push(img);
                                        this.attachmentCounter++;
                                    }
                                });
                            }
                        });

                        auditUploader.onProgressAll = this.zone.run(() => (progress: number) => {
                            this.notify.broadcast("full-page-block", true);
                        });

                        auditUploader.onCompleteAll = this.zone.run(() => () => {
                            this.notify.broadcast("full-page-block", false);
                        });
                    }

                    this.loadUploadedFilesFromFileSystem(answer);
                });
            });

            this.useFailureCategories = this.failureCategories.length > 0 && this.auditConfig.UseFailureCategories;

            this.wizardInit();
            this.notify.broadcast("full-page-block", false);
            this.loading = false;
        }
    }

    private saveUploadedFileToFileSystem(item: FileItem, answer: any, auditAnswerArtifactID: number = 0): void {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            const file: IAuditAnswerArtifact = { ID: auditAnswerArtifactID, AuditAnswerID: answer.ID, ArtifactID: 0, OrganizationID: this.current.info.org.ID,
                                                 Filename: item._file.name, Url: reader.result, FileFormatID: this.getFileFormatID(item._file.type) };
            if (file.FileFormatID) {// the file we are trying to save to local storage has an accepted format
                const artifacts = [];
                artifacts.push(file);

                this.artifact.convertBase64AsImageFile(artifacts, this.auditID).subscribe((imageData: any[]) => {
                    answer.images.push(imageData[0]);

                    this.saveAuditInFileSystem(() => {
                      this.saveInAuditMitigationInFileSystem(() => {
                        const storedUploadedFile = { auditID: this.auditID, answerID: imageData[0].AuditAnswerID, imageData: imageData[0].Url,
                                                     imageName: imageData[0].Filename, FileFormatID: imageData[0].FileFormatID, Url: imageData[0].Url, auditAnswerArtifactID: imageData[0].ID };

                        // while offline, display file thumbnail and save correct image data to be able to sync
                        if (!this.tools.isApp() && imageData[0].hasOwnProperty("imageData") && !this.tools.isOnline()) {
                          storedUploadedFile.imageData = imageData[0].imageData;
                          storedUploadedFile.Url = imageData[0].imageData;
                        }

                        let filtered: any[];
                        if (imageData[0].ID === 0) {
                            filtered = this.savedUploadedFileArray.filter((uploaded: any) => uploaded.imageName === imageData[0].Filename && uploaded.answerID === imageData[0].AuditAnswerID);
                        } else {
                            filtered = this.savedUploadedFileArray.filter((uploaded: any) => uploaded.Url === imageData[0].Url && uploaded.answerID === imageData[0].AuditAnswerID);
                        }

                        if (filtered && filtered.length === 0) {
                          this.savedUploadedFileArray.push(storedUploadedFile);
                          if (imageData[0].ID !== 0) {
                              this.auditDetail.AuditAnswerArtifacts.push(imageData[0]);
                              this.saveAuditInFileSystem(() => {});
                          }
                        }

                        const answerKey = this.storage.buildCurrentStorageKey("AnswerArtifact", this.auditID);

                        if (this.tools.isApp()) {
                          this.savedUploadedFileArray = _.uniq(this.savedUploadedFileArray);
                          this.storage.storeObject(answerKey, this.savedUploadedFileArray).subscribe(() => {
                            this.attachmentCounter++;
                            this.notify.broadcast("full-page-block", false);
                          }, (err: any) => {
                            this.log.error("error in uploadedFileArray " + JSON.stringify(err));
                            this.notify.broadcast("full-page-block", false);
                          });
                        } else {
                          try {
                            const keyStr: string = this.storage.keyAsString(answerKey);
                            this.local.setItem(keyStr, this.savedUploadedFileArray);
                          } catch (e) {
                            this.log.error("unable to save uploaded file in local storage: " + e);
                          }
                        }
                      });
                    });
                });
            } else {// wrong file format
                this.log.debug("wrong file format");
                this.notify.broadcast("full-page-block", false);
            }
        };
        reader.readAsDataURL(item._file);
    }

    private getFileContent(key: StorageKey, callback: any): void {
        this.zone.run(() => {
            let data: any = [];
            if (this.tools.isApp()) {
                this.storage.containsObject(key).takeUntil(this.destroy$).subscribe((fileExists: boolean) => {
                    if (fileExists) {
                        this.storage.retrieveObject(key).takeUntil(this.destroy$).subscribe((dataObj: any) => {
                            if (callback) { callback(dataObj); }
                        }, (err: any) => {
                            this.log.error("retrieve key error " + JSON.stringify(err));
                            if (callback) { callback([]); }
                        });
                    } else {
                        this.log.error("file does not exists " + JSON.stringify(key));
                        if (callback) { callback([]); }
                    }
                });
            } else {
                if (!this.tools.isOnline()) {
                    const keyStr: string = this.storage.keyAsString(key);
                    if (this.local.containsItem(keyStr)) {
                        data = JSON.parse(this.local.getItem(keyStr));
                    }
                    if (callback) { callback(data); }
                } else {
                    if (callback) { callback(data); }
                }
            }
        });
    }

    // retrieves the format id from the preloaded list, based on the incoming mime type
    private getFileFormatID(mimeType: string): number {
        let result: number = 0;
        const formats = this.fileFormats.filter((fileFormat: IFileFormat) => fileFormat.MimeType === mimeType);
        if (formats && formats.length > 0) {
            result = formats[0].ID;
        }
        return result;
    }

    private onFailureCategoryChanged(question: IClientAuditQuestion, selected: any): void {
        if (question.AuditAnswer.IssueClassification == null) {
            const issueClassification = {} as IIssueClassification;   // create empty object of an interface
            issueClassification.FailureCategoryID = selected;
            issueClassification.FailureModeID = null;

            question.AuditAnswer.IssueClassification = issueClassification;
        } else {
            question.AuditAnswer.IssueClassification.FailureCategoryID = selected;
            question.AuditAnswer.IssueClassification.FailureModeID = null;
        }

        // filter options in failure mode based on selected failure category
        this.filterModesByCategory(selected);
        this.conductAuditForm.form.markAsDirty();
    }
    private wizardInit(): void {
        if (this.groupQuestions) {
            let numGroups: number = 0;
            _.each(this.questionGroups, (group) => {
                if (group.Questions.length > 0) {
                    numGroups++;
                }
            });
            this.wizardLastStep = numGroups;
        } else {
            this.wizardLastStep = this.auditQuestions.length;
        }
        if (this.audit.PercentComplete > 0) {
            this.getOverallAuditStatus();
        }
    }

    private checkQuestionRequired(question: IClientAuditQuestion): boolean {
        let questionRequired = false;
        let commentIsEmpty = false;
        let hasImageAttached = false;
        const answer = question.AuditAnswer as IClientAuditAnswer;

        if (question.QuestionType !== QuestionResponseTypes.MultiSelect || question.QuestionType === undefined) {
            const comment = answer.Comment;

            answer.failureCategoryRequired = false;
            answer.commentRequired = false;

            commentIsEmpty = (comment == null || comment.length === 0);
        }

        // If this is a pass/fail question and the auditor marked "fail"
        if (question.QuestionType === QuestionResponseTypes.PassFail && answer.PointValue === 0) {

            if (this.useFailureCategories && question.AuditAnswer.IssueClassification == null) {
                questionRequired = true;
                answer.failureCategoryRequired = true;
            } else if (question.AuditAnswer.IssueClassification != null && question.AuditAnswer.IssueClassification.FailureModeID == null) {
                questionRequired = true;
                answer.failureCategoryRequired = true;
            }

            if (this.commentResponseOption === EaseCommentResponseOption.RequiredOnFail && commentIsEmpty) {
                questionRequired = true;
                answer.commentRequired = true;
            }

            const filtered = this.uploaderObjs.filter((obj: any) => obj.questionID === question.ID);
            if (filtered.length !== 0) {
                hasImageAttached = filtered[0].uploader.queue.length > 0;
            }

            // validate only for pass/fail and requiredonfail
            if (question.PhotoInputResponseOption === PhotoInputResponseOption.RequiredOnFail && !hasImageAttached && question.AuditAnswer.images.length === 0) {
                questionRequired = true;
                answer.photoRequired = true;
            }

            // validate only for mitigationcomment not null if mitigatenow is true
            if (question.mitigateNow && question.AuditAnswer.MitigationComment == null) {
                questionRequired = true;
            }

            // validate only for countermeasure not null if countermeasure is enabled
            if (question.mitigateNow && this.isCountermeasuresEnabled && question.AuditAnswer.CountermeasureCustomItemID == null) {
                questionRequired = true;
            }

        }
        if (question.QuestionType === QuestionResponseTypes.Point && (question.MitigationResponseOption === 2 || question.AuditAnswer.IsOptionalMitigationSelected) &&
            question.AuditAnswer.PointValue > 0 && question.MinimumPassingScore != null && (question.AuditAnswer.PointValue - 1 < question.MinimumPassingScore)) {
            if (question.mitigateNow && (answer.MitigationComment == null || answer.MitigationComment === "")) {
                questionRequired = true;
            }
        }

        // if this is a multiple choince question
        if (question.QuestionType === QuestionResponseTypes.MultipleChoice && (question.MitigationResponseOption === 2 || question.AuditAnswer.IsOptionalMitigationSelected) &&
            question.mitigateNow && (answer.MitigationComment == null || answer.MitigationComment === "")) {
            questionRequired = true;
        }

        // if this is a multi-select question
        if (question.QuestionType === QuestionResponseTypes.MultiSelect && (answer.MitigationComment == null || answer.MitigationComment === "")) {
            _.each(question.customCategories, (cat) => {
                _.each(cat.CustomItems, (item) => {
                    const cfv = answer.CustomFieldValues.filter((fieldValue: any) => fieldValue.FieldValueCustomItemID === item.ID && fieldValue.FieldValueCustomCategoryID === cat.ID);
                    if (cfv.length > 0) {
                        if (cfv[0].MitigateNow && (cfv[0].MitigationActivity.Comment === undefined || cfv[0].MitigationActivity.Comment == null || cfv[0].MitigationActivity.Comment === "")) {
                            questionRequired = true;
                        }
                    }
                });
            });
        }

        // require photo for failed questions with "Question Type = Score or Number" and "Photo Input = Required on Fail"
        if ((question.QuestionType === QuestionResponseTypes.Point || question.QuestionType === QuestionResponseTypes.Numeric) &&
             question.AuditAnswer.IsPassed === false && question.PhotoInputResponseOption === 2) {
            hasImageAttached = false;
            const filtered = this.uploaderObjs.filter((obj: any) => obj.questionID === question.ID);
            if (filtered.length !== 0) {
                hasImageAttached = filtered[0].uploader.queue.length > 0;
            }

            // validate only for score/number and requiredonfail
            if (question.PhotoInputResponseOption === PhotoInputResponseOption.RequiredOnFail && !hasImageAttached && question.AuditAnswer.images.length === 0) {
                questionRequired = true;
                answer.photoRequired = true;
            }
        }

        // free form and numeric
        if (question.QuestionType === QuestionResponseTypes.Text && this.wizardCurrentStep &&
            ((answer.CustomFieldValues.length > 0 &&
                (answer.CustomFieldValues[0].FieldValueStr === undefined || answer.CustomFieldValues[0].FieldValueStr == null || answer.CustomFieldValues[0].FieldValueStr === "")) ||
            answer.CustomFieldValues.length === 0) && question.IsRequired) {
            questionRequired = true;
        }

        if ((question.QuestionType === QuestionResponseTypes.Numeric &&
            ((answer.CustomFieldValues.length === 0 || (answer.CustomFieldValues.length > 0 && (answer.CustomFieldValues[0].FieldValueNum === undefined || answer.CustomFieldValues[0].FieldValueNum == null)) && !answer.IsNA) ||
            (question.PhotoInputResponseOption === 3 && !hasImageAttached && question.AuditAnswer.images.length === 0)) && question.IsRequired) && this.wizardCurrentStep) {
            questionRequired = true;
        }

        // If the comment is required in all cases
        if (this.commentResponseOption === EaseCommentResponseOption.AlwaysRequired && commentIsEmpty) {
            answer.commentRequired = true;
            questionRequired = true;
        }

        return questionRequired;
    }

    private getFailureDescriptions(question: IClientAuditQuestion) {
        const answer = question.AuditAnswer;

        if (answer.IssueClassification != null) {
            answer.IssueClassification.CustomCategory = this.getCustomCategoryByID(answer.IssueClassification.FailureCategoryID);
            answer.IssueClassification.CustomItem = null;
            if (answer.IssueClassification.FailureModeID) {
                answer.IssueClassification.CustomItem = this.getFailureModeByID(answer.IssueClassification.FailureModeID);
            }
        }
    }

    // All the logic that flags a question as complete and/or in non-conformance is also handled here
    // Returns an object containing:
    // (a) % Complete
    // (b) Whether or not the audit is in non-conformance
    private getOverallAuditStatus(): any {
      const numQuestions: number = this.auditQuestions.length;
      let numCompleted: number = 0;
      let auditInNonConformance: boolean = false;

      const auditPassed: boolean = true;
      this.isAuditInfoComplete = true;
      this.isNonConformance = false;

      if (this.groupQuestions) {
        _.each(this.questionGroups, (group) => {
          let numInGroupCompleted: number = 0;
          _.each(group.Questions, (question) => {
            const info = this.isQuestionComplete(question);
            numInGroupCompleted += info.numCompleted;
            auditInNonConformance = info.auditInNonConformance;
          });

          numCompleted += numInGroupCompleted;
          if (group.Questions.length === numInGroupCompleted) {
            group.isComplete = true;
          }
        });
      } else {
        _.each(this.auditQuestions, (question) => {
          const info = this.isQuestionComplete(question);
          numCompleted += info.numCompleted;
          auditInNonConformance = info.auditInNonConformance;
        });
      }

      // calculate totalScore and totalMaxScore
      this.totalPts = 0;
      this.totalPossiblePts = 0;
      _.each(this.auditQuestions, (question) => {
        // Only check point response types since we can have a mix of different response types
        if (question.QuestionType === QuestionResponseTypes.Point) {
          const answer = question.AuditAnswer;
          if (answer.PointValue) {// only add to totalPts and totalPossiblePts if the question is answered
            if (question.PointValue > 1) {
              this.totalPts += (answer.PointValue - 1);
            }
            this.totalPossiblePts += question.PointValue;
          }
        }
      });

      // Calculate percent complete
      let pct: number = 0;
      if (numQuestions !== 0) {
        pct = parseInt((numCompleted / numQuestions) * 100 as any, 10);
      }

        // Set some controller scope variables
      this.isAuditComplete = (pct === 100);
      return { PercentComplete: pct, NonConformance: auditInNonConformance, Passed: auditPassed };
    }

    private isQuestionComplete(question: IClientAuditQuestion): any {
        let numCompleted: number = 0;
        let auditInNonConformance: boolean = false;

        question.isComplete = false;
        question.isNonConformance = false;

        question.isQuestionRequired = this.checkQuestionRequired(question);
        if (!question.isQuestionRequired) {
            const auditAnswer = question.AuditAnswer as IClientAuditAnswer;
            if (auditAnswer != null) {
                if (auditAnswer.PointValue != null) {
                    numCompleted++;
                    question.isComplete = true;
                    if (auditAnswer.PointValue === 0 && (question.IsCritical || question.MitigationResponseOption === MitigationResponseOption.RequiredOnFail)
                        || (question.MitigationResponseOption === MitigationResponseOption.Optional && auditAnswer.IsOptionalMitigationSelected === true)
                        && (!question.mitigateNow || (question.mitigateNow && (this.isNullOrEmpty(auditAnswer.MitigationComment) || this.isNullOrEmpty(auditAnswer.CountermeasureCustomItemID))))) {

                        if (question.QuestionType === QuestionResponseTypes.Point) {
                            if (question.AuditAnswer.PointValue > 0 && question.MinimumPassingScore != null && (question.AuditAnswer.PointValue - 1 < question.MinimumPassingScore)) {
                                question.isNonConformance = true;
                                auditInNonConformance = true;
                            }
                        } else {
                            question.isNonConformance = true;
                            auditInNonConformance = true;
                        }

                        if (question.mitigateNow && (this.isNullOrEmpty(auditAnswer.MitigationComment) || (this.isCountermeasuresEnabled && this.isNullOrEmpty(auditAnswer.CountermeasureCustomItemID)))) {
                            numCompleted--;
                            question.isComplete = false;
                        }
                    } else if (auditAnswer.PointValue > 0 &&
                                (question.MitigationResponseOption === MitigationResponseOption.RequiredOnFail ||
                                    (question.MitigationResponseOption === MitigationResponseOption.Optional && auditAnswer.IsOptionalMitigationSelected === true))) {
                        if (question.mitigateNow && (this.isNullOrEmpty(auditAnswer.MitigationComment) || (this.isCountermeasuresEnabled && (this.isNullOrEmpty(auditAnswer.CountermeasureCustomItemID))))) {
                            numCompleted--;
                            question.isComplete = false;
                        }
                    }
                } else if (question.QuestionType === QuestionResponseTypes.MultipleChoice || question.QuestionType === QuestionResponseTypes.MultiSelect) {
                    let oneSelected: boolean = false;

                    let isContinue: boolean = true;
                    _.each(question.customCategories, (cat) => {
                        _.each(cat.CustomItems, (item) => {
                            if (item.IsSelected) {
                                if (this.isCountermeasuresEnabled) {
                                    if (question.QuestionType === QuestionResponseTypes.MultipleChoice) {
                                        if (question.mitigateNow && auditAnswer.MitigationComment !== undefined && auditAnswer.MitigationComment != null && auditAnswer.MitigationComment !== ""
                                            && auditAnswer.CountermeasureCustomItemID !== undefined && auditAnswer.CountermeasureCustomItemID != null) {
                                            oneSelected = true;
                                        } else if (!question.mitigateNow) {
                                            oneSelected = true;
                                        }
                                    } else {
                                        if (isContinue && item.CustomFieldValue != null) {
                                            if (item.CustomFieldValue.MitigateNow) {
                                                oneSelected = false; // reset value so that if one of the questions is mitigate now and required fields are not entered, submit won't be enabled
                                                if (item.CustomFieldValue.MitigationActivity.Comment !== undefined && item.CustomFieldValue.MitigationActivity.Comment != null
                                                    && item.CustomFieldValue.MitigationActivity.Comment !== "" && item.CustomFieldValue.MitigationActivity.CountermeasureCustomItemID !== undefined
                                                    && item.CustomFieldValue.MitigationActivity.CountermeasureCustomItemID != null) {
                                                    oneSelected = true;
                                                } else {
                                                    isContinue = false; // stop validating other multiple select questions
                                                }
                                            } else if (!item.CustomFieldValue.MitigateNow) {
                                                oneSelected = true;
                                            }
                                        }
                                    }
                                } else {
                                    oneSelected = true;
                                }
                            }
                        });
                    });

                    if (oneSelected) {
                        question.isComplete = true;
                        numCompleted++;
                    }
                } else if (question.QuestionType === QuestionResponseTypes.Text) {

                    if (auditAnswer.CustomFieldValues.length > 0 && auditAnswer.CustomFieldValues[0].FieldValueStr != null && auditAnswer.CustomFieldValues[0].FieldValueStr.length > 0) {
                        question.isComplete = true;
                        numCompleted++;

                        if (question.mitigateNow && (this.isNullOrEmpty(auditAnswer.MitigationComment) || (this.isCountermeasuresEnabled && (this.isNullOrEmpty(auditAnswer.CountermeasureCustomItemID))))) {
                            numCompleted--;
                            question.isComplete = false;
                        }
                    }

                } else if (question.QuestionType === QuestionResponseTypes.Numeric) {
                    let value;

                    if (auditAnswer.CustomFieldValues.length > 0) {
                        value = auditAnswer.CustomFieldValues[0].FieldValueNum;
                    }

                    if ((value != null && question.MinValue !== undefined && question.MaxValue !== undefined && value >= question.MinValue && value <= question.MaxValue) ||
                        (value != null && (question.MinValue === undefined && question.MaxValue === undefined)) || auditAnswer.IsNA) {
                        question.isComplete = true;
                        numCompleted++;
                    }

                    if (auditAnswer.IsPassed === false && (question.MitigationResponseOption === MitigationResponseOption.RequiredOnFail
                        || (question.MitigationResponseOption === MitigationResponseOption.Optional && auditAnswer.IsOptionalMitigationSelected === true))
                        && (!question.mitigateNow || (question.mitigateNow && (this.isNullOrEmpty(auditAnswer.MitigationComment) || (this.isNullOrEmpty(auditAnswer.CountermeasureCustomItemID)))))) {
                        question.isNonConformance = true;
                        auditInNonConformance = true;

                        if (question.mitigateNow && (this.isNullOrEmpty(auditAnswer.MitigationComment) || (this.isCountermeasuresEnabled && (this.isNullOrEmpty(auditAnswer.CountermeasureCustomItemID))))) {
                            numCompleted--;
                            question.isComplete = false;
                        }
                    }

                }

                // only require photo for failed questions (for required questions)
                if ((question.QuestionType === QuestionResponseTypes.PassFail && question.PhotoInputResponseOption === PhotoInputResponseOption.RequiredOnFail && auditAnswer.PointValue === 0) ||
                    question.PhotoInputResponseOption === PhotoInputResponseOption.Required && question.IsRequired) {

                    let hasImageAttached = false;
                    const filtered = this.uploaderObjs.filter((obj: any) => obj.questionID === question.ID);
                    if (filtered.length !== 0) {
                        hasImageAttached = filtered[0].uploader.queue.length > 0 || auditAnswer.images.length > 0;
                    }

                    question.isComplete = (question.AuditAnswer.PointValue === -1) || (question.isComplete && hasImageAttached);

                    if (question.PhotoInputResponseOption === PhotoInputResponseOption.Required && !hasImageAttached && question.AuditAnswer.PointValue !== -1) {
                        numCompleted--;
                    }
                }

                // require photo for failed questions with "Question Type = Score or Number" and "Photo Input = Required on Fail"
                if ((question.QuestionType === QuestionResponseTypes.Point || question.QuestionType === QuestionResponseTypes.Numeric) &&
                    question.AuditAnswer.IsPassed === false && question.PhotoInputResponseOption === 2) {
                    let hasImageAttached = false;
                    const filtered = this.uploaderObjs.filter((obj: any) => obj.questionID === question.ID);
                    if (filtered.length !== 0) {
                        hasImageAttached = filtered[0].uploader.queue.length > 0 || auditAnswer.images.length > 0;
                    }

                    question.isComplete = hasImageAttached;

                    if (question.PhotoInputResponseOption === PhotoInputResponseOption.RequiredOnFail && !hasImageAttached) {
                        numCompleted--;
                    }
                }

                if (!question.IsRequired) {
                    // count the question as completed if the question is not required
                    if (numCompleted === 0) {// only increment numCompleted if it was not incremented already
                        numCompleted++;
                    }
                }
            }
        }
        return { numCompleted, auditInNonConformance };
    }

    private setAnswersIsPassedFlag() {
        if (this.auditQuestions && this.auditQuestions.length) {
            this.audit.AuditSummary = { TotalFailed: 0, TotalNA: 0, TotalPassed: 0, TotalScore: 0, ContainsScoredQuestions: false, ContainsPassFailQuestions: false };
            _.each(this.auditQuestions, (question) => {
                question.AuditAnswer.IsPassed = null;   // default
                // set the IsPassed Flag and IsNAFlag for PassFail questions
                if (question.QuestionType === QuestionResponseTypes.PassFail) {
                    question.AuditAnswer.IsNA = false;  // clear data
                    if (question.AuditAnswer.PointValue === 0) {
                        question.AuditAnswer.IsPassed = false;
                        this.audit.AuditSummary.TotalFailed += 1;
                    } else if (question.AuditAnswer.PointValue === -1) {
                        question.AuditAnswer.IsNA = true;
                        this.audit.AuditSummary.TotalNA += 1;
                    } else if (question.AuditAnswer.PointValue === 1) {
                        question.AuditAnswer.IsPassed = true;
                        this.audit.AuditSummary.TotalPassed += 1;
                    }
                } else if (question.QuestionType === QuestionResponseTypes.Point) {
                    question.AuditAnswer.IsNA = false;  // clear data
                    if (question.AuditAnswer.PointValue === 0) {
                        question.AuditAnswer.IsNA = true;
                        this.audit.AuditSummary.TotalNA += 1;
                    }
                    // question can pass/fail when the 'IsPassFailType' flag is set and it is answered. The auditor can skip answering the question, when required is not set in the question.
                    if (question.IsPassFailType && question.AuditAnswer.PointValue > 0) {
                        question.AuditAnswer.IsPassed = true;
                        this.audit.AuditSummary.TotalPassed += 1;
                        if (question.AuditAnswer.PointValue - 1 < question.MinimumPassingScore) {
                            question.AuditAnswer.IsPassed = false;
                            this.audit.AuditSummary.TotalPassed -= 1;
                            this.audit.AuditSummary.TotalFailed += 1;
                        }
                    }
                } else if ((question.QuestionType === QuestionResponseTypes.MultipleChoice || question.QuestionType === QuestionResponseTypes.MultiSelect)
                    && (question.MitigationResponseOption === 2 || (question.MitigationResponseOption === 1 && question.AuditAnswer.IsOptionalMitigationSelected === true))) {
                      if (question.AuditAnswer.CustomFieldValues.length > 0) {
                        question.AuditAnswer.IsPassed = false;
                        this.audit.AuditSummary.TotalFailed += 1;
                      }
                } else if (question.QuestionType === QuestionResponseTypes.Numeric) {
                    this.setNumericIsPassedFlag(question, true);
                }
            });
        }
    }

    private setAuditQuestionsCustomFieldValues(): void {
        _.each(this.audit.AuditQuestions, (q: IClientAuditQuestion) => {
            if (q.AuditAnswer.CustomFieldValues != null && q.AuditAnswer.CustomFieldValues.length > 0 &&
                (q.QuestionType === QuestionResponseTypes.Numeric || q.QuestionType === QuestionResponseTypes.Text)) {
                const item = this.auditQuestions.filter((question: any) => question.ID === q.ID);

                if (item.length > 0) {
                    item[0].AuditAnswer.CustomFieldValues = q.AuditAnswer.CustomFieldValues;
                }
            }
            q.uploader = null;
        });
    }

    private saveCustomFieldValues(): void {
        _.each(this.auditQuestions, (question) => {
            _.each(question.customCategories, (cat) => {
                const answer = question.AuditAnswer as IClientAuditAnswer;
                if (question.QuestionType === QuestionResponseTypes.MultipleChoice || question.QuestionType === QuestionResponseTypes.MultiSelect) {
                    _.each(cat.CustomItems, (val: ICustomItem) => {
                        if (val.IsSelected) {
                            const cfv: any = { // Ease.ICustomFieldValue
                                Name: val.Name,
                                FieldValueCustomItemID: val.ID,
                                FieldValueCustomCategoryID: cat.ID,
                                EntityID: answer.ID,
                                ParentEntityID: this.audit.ID,
                                OrganizationID: val.OrganizationID,
                                CustomFieldID: question.CustomFieldID,
                                MitigateNow: false,
                            };

                            if (val.CustomFieldValue != null) {
                                cfv.ID = val.CustomFieldValue.ID;
                                cfv.MitigateNow = val.CustomFieldValue.MitigateNow;
                            }

                            if (val.CustomFieldValue.MitigationActivity != null) {
                                cfv.MitigationActivity = val.CustomFieldValue.MitigationActivity;
                            }
                            const itemLength = answer.CustomFieldValues.filter((fieldValue: any) =>
                                                fieldValue.FieldValueCustomItemID === val.ID && fieldValue.FieldValueCustomCategoryID === cat.ID).length;

                            if (itemLength === 0) {
                                answer.CustomFieldValues.push(cfv);
                                val.CustomFieldValue = cfv;
                            }
                        } else {
                            const item = answer.CustomFieldValues.filter((fieldValue: any) => fieldValue.FieldValueCustomItemID === val.ID && fieldValue.FieldValueCustomCategoryID === cat.ID);

                            if (item.length > 0) {
                                const itemIndex = answer.CustomFieldValues.indexOf(item[0]);
                                if (itemIndex !== -1) {
                                    answer.CustomFieldValues.splice(itemIndex, 1);
                                    val.CustomFieldValue = null;
                                }
                            }
                        }

                    });
                }
            });
        });
    }

    private saveChanges(status: EaseAuditStatus, callback?: any): void {
        // Loop through audit answers and submit them to the server
        this.isSaving = true;
        this.isOkToExit = true;

        const auditStatus = this.getOverallAuditStatus(); // Maybe this should be calcuated at the server level
        let isSubmitting: boolean = false;

        this.audit.PercentComplete = auditStatus.PercentComplete;

        if (this.audit.DateStarted == null) {
          this.audit.DateStarted =  moment().utc().toDate();
        }

        // If we're submitting this audit, make sure it's complete
        if (status == null || (status === EaseAuditStatus.Complete && this.audit.PercentComplete !== 100)) {
            status = EaseAuditStatus.Incomplete;
        }

        this.saveCustomFieldValues();
        this.setAnswersIsPassedFlag();

        if (status === EaseAuditStatus.Complete) {
            isSubmitting = true;
            // If this audit is complete, calculate the score

            this.audit.HasNonConformance = auditStatus.NonConformance;
            this.audit.IsPassed = auditStatus.Passed;
            this.audit.Score = 0;

            const pctComplete: number = (this.totalPts / this.totalPossiblePts) * 100;
            this.audit.Score = parseInt(pctComplete as any, 10);
            this.audit.IsPassed = true;
            if (this.isScoredDocument) {
                if (!this.scoredDocSetting.MinimumPassingScoreEnabled) {
                    this.audit.IsPassed = true;
                } else {// minimum passing score is enabled for Scored Document
                    this.audit.IsPassed = (this.totalPts >= this.scoredDocSetting.MinimumPassingScoreValue) ? true : false;
                }
            }

            if (this.tools.isApp()) {
                const dateCompleted = moment().utc().toDate();
                this.audit.DateCompleted = dateCompleted;
                this.audit.PendingUpload = true;
            }
        }
        this.audit.Status = status as number;

        this.audit.AuditQuestions = this.auditQuestions;
        this.sendChangesToServer(status, callback);
    }

    private sendChangesToServer(status: EaseAuditStatus, callback?: any): void {
        // Before we submit this audit to the server we have to remove the uploader object from each question so we don't serialize it
        const param = this.showAuditorSelection ? "isConductingAudit=true&isSetDateCompleted=false" : "isConductingAudit=true";

        _.each(this.audit.AuditQuestions, (q: IClientAuditQuestion) => {
            if (q.AuditAnswer.PointValue !== 0) {
                if (q.AuditAnswer.IssueClassification != null && (q.AuditAnswer.IssueClassification.FailureModeID != null || q.AuditAnswer.IssueClassification.FailureCategoryID != null)) {
                    q.AuditAnswer.IssueClassification = null;
                    q.AuditAnswer.IssueClassificationID = null;
                }            }
            q.uploader = null;
        });

        this.saveAuditInFileSystem(() => {
            this.saveInAuditMitigationInFileSystem(() => {
              if (!this.tools.isApp()) {
                this.auditDetailService.updateAuditChanges(this.audit, param).subscribe((data: IAudit) => {
                  this.isOffline = false;
                  this.showLastSavedOfflineDate = false;
                  this.audit = data;

                  this.auditDetailService.removeAuditFromLocalStorage(this.auditID);
                  this.setAuditQuestionsCustomFieldValues();
                  // save customfieldvalues data in json file
                  this.sendUploaderLocalFilesToServer(() => {
                    this.saveAuditInFileSystem(() => {
                      this.saveInAuditMitigationInFileSystem(() => {
                        if (callback) {
                          callback();
                        }
                      });
                    });
                  });
                  this.isSaving = false;
                }, (err: any) => {
                  // status has the value 500 for the api, db error and null for the offline
                  if (err.status === null || err.status === 0) {
                    this.isOffline = true;
                    this.showLastSavedOfflineDate = true;
                    this.DateLastSavedOffline = (new Date()).toLocaleString();
                  }
                  this.isSaving = false;
                  if (callback) { callback(); }
                });
              } else {
                this.isSaving = false;
                if (callback) {
                  callback();
                }
              }
            });
        });
    }

    private saveAuditInFileSystem(callback: any): void {
        if (this.tools.isApp()) {
            const key = this.storage.buildCurrentStorageKey("Audits", this.auditID);
            this.auditDetail.Audit.IsNotSynced = true;
            this.storage.storeObject(key, this.auditDetail).subscribe(() => {
                if (callback) { callback(); }
            }, (err: any) => {
                this.log.error("error in saveAuditInFileSystem " + JSON.stringify(err));
                if (callback) { callback(); }
            });
        } else {
            // save audit to local storage
            const answers: any[] = [];
            const infoAnswers: any[] = [];
            this.auditQuestions.forEach((question: IClientAuditQuestion) => {
                answers.push(question.AuditAnswer);
            });
            // audit status to be incomplete when it is in local storage so that the user can continue doing the audit
            const storedAudit = { AuditorID: this.current.info.user.ID, AuditID: this.auditID, ChangeToken: this.audit.ChangeToken, AuditStatus: EaseAuditStatus.Incomplete,
                                  DateRequired: this.audit.DateRequired, DateLastSaved: this.DateLastSavedOffline, Description: this.audit.Description, AuditAnswers: answers,
                                  AuditInfoAnswers: infoAnswers, AuditQuestions: this.auditQuestions };
            this.local.setItem("storeAudit", storedAudit);
            // this audit maybe in local storage, remove it
            const offlineAuditArray: any[] = [];
            if (this.local.containsItem("offlineAuditArray") && this.local.getItem("offlineAuditArray") === "undefined") {
              this.local.removeItem("offlineAuditArray");
            }
            if (this.local.containsItem("offlineAuditArray")) {
                const oldOfflineAuditArray = JSON.parse(this.local.getItem("offlineAuditArray"));
                oldOfflineAuditArray.forEach((oldStoreAudit: any) => {
                    if (oldStoreAudit.AuditID === this.auditID) {
                        const auditIndex = oldOfflineAuditArray.indexOf(oldStoreAudit);
                        oldOfflineAuditArray.splice(auditIndex, 1);
                    }
                });
            }
            offlineAuditArray.push(storedAudit);
            this.local.setItem("offlineAuditArray", offlineAuditArray);
            this.log.debug("saved the audit {" + this.auditID + "} in local storage");
            if (callback) { callback(); }
        }
    }

    private saveInAuditMitigationInFileSystem(callback: any): void {
        // save in audit mitigation details in file system since it's not saved in the database until after submission of audit
        const answers: IAuditAnswer[] = [];
        const questionMitigateNowArray: any[] = [];
        _.each(this.auditQuestions, (question: IClientAuditQuestion) => {
            const currentAuditQuestions: IClientAuditQuestion[] = this.audit.AuditQuestions;
            if (currentAuditQuestions && currentAuditQuestions.length > 0) {
              const items = currentAuditQuestions.filter((auditQuestion: IClientAuditQuestion) => auditQuestion.ID === question.ID);
              if (items.length > 0 && items[0].AuditAnswer != null && items[0].AuditAnswer.IssueClassificationID != null) {
                question.AuditAnswer.IssueClassificationID = items[0].AuditAnswer.IssueClassificationID;
                if (question.AuditAnswer.IssueClassification != null && question.AuditAnswer.IssueClassification.ID == null) {
                  question.AuditAnswer.IssueClassification.ID = question.AuditAnswer.IssueClassificationID;
                }
              }
            }

            answers.push(question.AuditAnswer);

            if (question.QuestionType !== QuestionResponseTypes.MultiSelect) {
                questionMitigateNowArray.push(question.mitigateNow);
            }
        });

        // auditstatus to be incomplete when it is in file system so that the user can continue doing the audit
        const storedInAuditMitigation = { AuditID: this.auditID, AuditAnswers: answers, QuestionMitigateNowArray: questionMitigateNowArray };

        const key = this.storage.buildCurrentStorageKey("InAuditMitigations", this.auditID);
        if (this.tools.isApp()) {
            this.storage.storeObject(key, storedInAuditMitigation).subscribe(() => {
                if (callback) { callback(); }
            }, (err: any) => {
                this.log.error("error in storedInAuditMitigation " + JSON.stringify(err));
                if (callback) { callback(); }
            });
        } else {
            try {
                const keyStr: string = this.storage.keyAsString(key);
                this.local.setItem(keyStr, storedInAuditMitigation);
                if (callback) { callback(); }
            } catch (e) {
                this.log.error("unable to save inauditmitigation in local storage: " + e);
                if (callback) { callback(); }
            }
        }
    }

    private loadInAuditMitigationFromFileSystem(): void {
        let questionID: number = 0;
        // load the mitigation details from file system
        const oldInAuditMitigation: any = this.savedInAuditMitigation;
        _.each(this.auditQuestions, (question: IClientAuditQuestion) => {
            questionID = question.AuditAnswer.AuditQuestionID;
            _.each(oldInAuditMitigation.AuditAnswers, (answer: IAuditAnswer) => {
                if (questionID === answer.AuditQuestionID) {
                    // Issue Classification might have been added when Saved was clicked before saving to file system
                    // if it does, assign to a variable and assign variable back to question.AuditAnswer.IssueClassificationID
                    // to avoid multiple adding to database.
                    let currentIssueClassificationID;
                    if (question.AuditAnswer.IssueClassificationID != null) {
                        currentIssueClassificationID = question.AuditAnswer.IssueClassificationID;
                    }

                    // We need to be careful about copying the contents of the stored answer into our answer, since images aren't being stored correctly right now
                    const currentImages = question.AuditAnswer.images;
                    question.AuditAnswer = answer;
                    question.AuditAnswer.images = currentImages;
                    if (currentIssueClassificationID > 0) {
                        question.AuditAnswer.IssueClassificationID = currentIssueClassificationID;
                    }

                    // for non-multiselect question types only - mitigateNow is a property of question while for multi-select question types, 'mitigateNow' a property of AuditAnswer
                    const indexOfArray = oldInAuditMitigation.AuditAnswers.indexOf(answer);
                    if (indexOfArray !== -1) {
                        question.mitigateNow = oldInAuditMitigation.QuestionMitigateNowArray[indexOfArray];
                        this.getOverallAuditStatus();
                    }
                }
            });
        });
    }

    private loadQuestionAnswerImages(question: IClientAuditQuestion, answer: IClientAuditAnswer, auditQuestionArtifacts: IAuditQuestionArtifact[], answerArtifacts?: IAuditAnswerArtifact[]): void {
        this.numImages = 0;

        // load question artifacts
        if (question.Artifacts != null && question.Artifacts.length > 0) {
            const questionArtifacts: IAuditQuestionArtifact[] = auditQuestionArtifacts.filter((artifact: IAuditQuestionArtifact) => artifact.AuditQuestionID === question.ID);
            if (questionArtifacts != null && questionArtifacts.length > 0) {
                _.each(questionArtifacts, (artifact: IClientAuditQuestionArtifact) => {
                    artifact.OriginalArtifactUrl = artifact.Url;
                    artifact.Url = this.setImageUrl(artifact, "AuditQuestionArtifact");
                    question.images.push(artifact);
                    this.numImages++;
                });
            }
        }

        // load answer images
        if ((answer != null && answer.HasArtifact) || (answerArtifacts.filter((artifact: IAuditAnswerArtifact) => artifact.AuditAnswerID === answer.ID).length > 0)) {
            const auditAnswerArtifacts: IAuditAnswerArtifact[] = answerArtifacts.filter((artifact: IAuditAnswerArtifact) => artifact.AuditAnswerID === answer.ID);
            _.each(auditAnswerArtifacts, (artifact: IAuditAnswerArtifact) => {
                answer.HasArtifact = true;
                artifact.Url = this.setImageUrl(artifact, "AnswerArtifact");
                answer.images.push(artifact);
                this.numImages++;
            });
        }
    }

    private createHyperLinks(description: string): string {
        let ok = description.indexOf(")[");
        const http = "http://";
        const https = "https://";
        while (ok !== -1) {
            const questionFirstPart = description.substr(0, description.indexOf("("));
            const questionText = description.substr(ok + 1);
            let href = questionText.substr(1, questionText.indexOf("]") - 1);
            if (href.indexOf(http) === -1 && href.indexOf(https) === -1) {
                href = http + href;
            }
            const textPart = description.substr(0, description.indexOf(")"));
            const text = textPart.substr(description.indexOf("(") + 1);
            const hiperLink = "<a href='" + href + "' target='_blank'>" + text + "</a>";
            const questionSecondPart = questionText.substr(questionText.indexOf("]") + 1);
            description = questionFirstPart + hiperLink + questionSecondPart;
            ok = description.indexOf(")[");
        }
        return description;
    }

    private deleteSavedArtifacts(callback: any): void {
        const artifacts = this.auditDetail.Artifacts;
        if (this.tools.isApp() && artifacts != null && artifacts.length > 0) {
            let count = 0;
            _.each(artifacts, (artifact: IArtifact) => {
                const artifactKey = this.storage.buildCurrentStorageKey("AuditDetailArtifact", artifact.ID);
                this.storage.deleteObject(artifactKey).subscribe(() => {
                    count++;

                    if (count === artifacts.length) {
                        if (callback) { callback(); }
                    }
                }, (err: any) => {
                    this.log.error("failed to delete artifact file " + JSON.stringify(err));
                });
            });
        } else {
            if (callback) { callback(); }
        }
    }

    private setImageUrl(img: any, entityName: string): string {
        let url = this.artifact.getImageUrl(img);

        if (!this.storage.isDoc(img.FileFormatID) && this.tools.isApp() && !this.isFromAdHocAudits && url.indexOf(cordova.file.dataDirectory) === -1) {
            const artifactKey = this.storage.buildCurrentStorageKey(entityName, img.ID);
            url = cordova.file.dataDirectory + this.storage.keyAsString(artifactKey, null, img.FileFormatID);
        }

        return url;
    }

    private saveUploadedImages(callback: any): void {
        _.each(this.uploaderObjs, (obj: any) => {
            const tauth = `Token ${this.token}`;
            if (obj.uploader != null && obj.uploader.queue.length > 0) {
                _.each(obj.uploader.queue, (item: FileItem) => {
                    item.headers = [{ name: "AuditAnswerID", value: obj.answerID }];
                });
                obj.uploader.authTokenHeader = "Authorization";
                obj.uploader.authToken = tauth;
                obj.uploader.uploadAll();
            }
        });

        // The uploadAll() function has no callback, so just wait a few ms before we return
        setTimeout(() => {
            callback();
        }, 1000);
    }

    // save uploaded files on Save to server if HTTPRequest fails
    private sendUploaderLocalFilesToServer(callback: any): void {
        let uploadedFileArray = this.savedUploadedFileArray;
        uploadedFileArray = this.removeNotSupportedFileTypesFromFileSystem(uploadedFileArray);
        if (uploadedFileArray && uploadedFileArray.length > 0) {
            const details: any[] = [];
            details.push(this.auditDetail);
            // synced added/deleted artifact
            this.auditDetailService.syncAuditAnswerArtifacts(details).takeUntil(this.destroy$).subscribe((artifacts: any) => {
                // clear savedUploadedFileArray since artifacts were already synced
                this.savedUploadedFileArray = [];
                const answerKey = this.storage.buildCurrentStorageKey("AnswerArtifact", this.auditID);

                if (this.tools.isApp()) {
                    this.storage.storeObject(answerKey, this.savedUploadedFileArray).subscribe(() => {
                        if (callback) { callback(); }
                    }, (err: any) => {
                        this.log.error("error in sendUploaderLocalFilesToServer " + JSON.stringify(err));
                        if (callback) { callback(); }
                    });
                } else {
                    const keyStr: string = this.storage.keyAsString(answerKey);
                    this.local.setItem(keyStr, this.savedUploadedFileArray);
                    if (callback) { callback(); }
                }
            });
        } else {
            if (callback) { callback(); }
        }
    }

    private removeNotSupportedFileTypesFromFileSystem(uploadedFileArray: any[]): any[] {
        const validFilesArray: any[] = [];
        _.each(uploadedFileArray, (file: any) => {
            if (file.FileFormatID && file.auditAnswerArtifactID === 0) {// only keep files with defined and greater than 0 fileFormatID
                validFilesArray.push(file);
            }
        });
        return validFilesArray;
    }

    private removeAuditUploadedAnswerFileFromFileSystem(file: any, isArtifact: boolean): void {
        let filtered: any[];
        if (isArtifact) {
            filtered = this.savedUploadedFileArray.filter((oldData: any) => oldData.imageName === file.Filename && oldData.answerID === file.AuditAnswerID);
        } else {
            filtered = this.savedUploadedFileArray.filter((oldData: any) => oldData.imageName === file.imageName && oldData.answerID === file.answerID && oldData.Url === file.Url);
        }

        if (filtered && filtered.length > 0) {
            const fileIndex = this.savedUploadedFileArray.indexOf(filtered[0]);
            this.savedUploadedFileArray.splice(fileIndex, 1);
        }

        const answerKey = this.storage.buildCurrentStorageKey("AnswerArtifact", this.auditID);

        if (this.tools.isApp()) {
            this.storage.storeObject(answerKey, this.savedUploadedFileArray).subscribe(() => {
            }, (err: any) => {
                this.log.error("error in removeAuditUploadedAnswerFileFromFileSystem " + JSON.stringify(err));
            });
        } else {
            const keyStr: string = this.storage.keyAsString(answerKey);
            this.local.setItem(keyStr, this.savedUploadedFileArray);
        }
    }

    private saveFileToDeleteToFileSystem(file: any): void {
        this.saveAuditInFileSystem(() => {
            const key = this.storage.buildCurrentStorageKey("AnswerArtifact", "FileToDelete_" + this.auditID);
            let fileToDeleteArray: any[] = [];
            this.getFileContent(key, (data: any[]) => {
                fileToDeleteArray = data;
                fileToDeleteArray.push(file);

                if (this.tools.isApp()) {
                        this.storage.storeObject(key, fileToDeleteArray).subscribe(() => {
                    }, (err: any) => {
                        this.log.error("error in fileToDeleteArray " + JSON.stringify(err));
                    });
                } else {
                    const keyStr: string = this.storage.keyAsString(key);
                    this.local.setItem(keyStr, fileToDeleteArray);
                }
            });
        });
    }

    private loadUploadedFilesFromFileSystem(answer: any): void {
        if (this.savedUploadedFileArray && this.savedUploadedFileArray.length > 0) {
            _.each(this.savedUploadedFileArray, (oldFile: any) => {
                if (oldFile.auditID === this.auditID && oldFile.auditAnswerArtifactID === 0 && oldFile.answerID === answer.ID) {
                    this.showLastSavedOfflineDate = true;
                    const file: IAuditAnswerArtifact = { ID: oldFile.auditAnswerArtifactID, AuditAnswerID: oldFile.answerID, ArtifactID: 0, OrganizationID: this.current.info.org.ID,
                                                         Filename: oldFile.imageName, Url: oldFile.Url, FileFormatID: oldFile.FileFormatID };
                    const itemIndex = answer.images.indexOf(file);
                    if (itemIndex === -1) {
                        answer.images.push(file);
                    }
                }
            });
            this.log.debug("loaded uploaded files for the audit {" + this.auditID + "} from the file system");
        }
    }

    private slideChange(idx: number): void {
        this.imageIndex = idx;
    }

    private removeEmptyGroupQuestions(): void {
        if (this.groupQuestions) {
            const results: any[] = this.questionGroups.filter((group: any) => group.Questions.length > 0);
            if (results.length !== 0) {
                this.questionGroups = results;
            }
        }
    }

    private isNullOrEmpty(data): boolean {
      return (data === null || data === "" || (data !== null && data.trim().length === 0));
    }

    private writeArtifacts(objArtifacts: any = null, artifactEntityName: string = null) {
      if (objArtifacts && objArtifacts.length > 0) {
        for (const artifact of objArtifacts) {
          let url: string = "";
          const fileFormatID: number = artifact.FileFormatID;

          url = (this.storage.isDoc(fileFormatID)) ? artifact.ArtifactUrl : artifact.Url;

          if (url !== null) {
            this.log.debug("audit conduct: downloading artifact..." + artifact.ID + " file: " + artifact.FileName);

            const artifactKey = this.storage.buildCurrentStorageKey(artifactEntityName, artifact.ID, artifact.Filename, artifact.FileFormatID);
            this.storage.storeObject(artifactKey, artifact).subscribe(
              () => {
                this.log.debug("storage.service: audit artifact: " + artifact.ID + "stored");
              },
              (err: any) => {
                this.log.error("storage.service: failed to store artifact - " + JSON.stringify(err));
                this.log.debug("End Test: Storage Service");
              },
            );
          }
        }
      }
    }
}
