// State
import { select, Store } from "@ngrx/store";
import * as fromRoot from "app/reducers";
import * as fromAuth from "app/auth/reducers";
import {
  InitApp,
} from "app/auth/actions/session";

// Services
import { NotifyService } from "@core/services";

export class AppAbstract {
  progress$ = this.store.pipe(select(fromRoot.getProgress));
  appIsReady$ = this.store.pipe(select(fromAuth.getAppIsReady));

  constructor(protected store: Store<fromRoot.State>,
              protected notify: NotifyService) {

    this.store.pipe(
      select(fromAuth.getLoggedIn),
    ).subscribe(isLoggedIn => {
      this.notify.loggedIn.value = isLoggedIn;
    });

    this.store.dispatch(new InitApp());

  }
}
