// Angular node modules
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter,
    ViewChild, NgZone, Inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { NgForm } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { LoggerService } from "../../../core/services/logger.service";

// Services
import { CurrentInfoService } from "../../../core/services/current.service";
import { LocalizeService } from "../../../core/services/localize.service";
import { Config } from "../../../core/services/config.service";
import { LocalService } from "../../../core/services/local.service";
import { NotifyService } from "../../../core/services/notify.service";
import { ApiService } from "../../../core/services/api.service";
import { IdentityService } from "../../../core/services/identity.service";
import { ToolsService } from "../../../core/services/tools.service";
import { AuditDetailService } from "../../../core/services/common/audit-detail.service";
import { StorageService, StorageKey } from "../../../core/services/storage.service";

// Components
import { ConfirmationModalComponent,
    IConfirmationModalData } from "../../confirmation/confirmation-modal.component";

// Models
import { IIssueClassification, IAuditDetail, IAuditConfig, IDocumentRev, IAuditLineItem,
    IClientAuditQuestion, IAuditAnswer, IClientAuditAnswer, IAuditAnswerArtifact, ICustomCategory,
    ICustomItem, ICustomCategoryLineItem, IShift, IAudit, IClientAudit, IAuditQuestionGroup, IUser,
    IRank, IArtifact, IMitigationActivity, IMitigationDetail, IAuditQuestion, QuestionResponseTypes,
    MitigationResponseOption, PassRangeTypes, EaseAuditStatus, ArtifactTypes, ICustomFieldValue,
    ICompanySite, ISite } from "../../../core/models/ease-models";

// ReactiveX components
import { Observable, ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UiNotificationService } from "../../../core/services/common/ui-notification.service";

@Component({
    selector: "ease-audit-detail",
    styleUrls: ["./../../../conduct/conduct-audit/audit-conduct.component.scss", "../../navigation/admin-control-bar.component.scss"],
    templateUrl: "./audit-detail.component.html",
})

export class AuditDetailComponent implements OnDestroy, OnInit {
    @Input() public isPrintMode: boolean;
    @Input() public auditID: number = null;
    @Input() public hideDelete: boolean = false;
    destroy$ = new Subject();

    // Bound to directive
    isModalView: boolean;

    isSiteView: boolean = false;

    public failedAnswers: IAuditAnswer[];
    public auditor: IUser;
    public generatedBy: IUser;
    public auditQuestions: IClientAuditQuestion[] = [];
    public audit: IClientAudit;
    public auditDetail: IAuditDetail;
    public auditConfig: IAuditConfig;

    public loading: boolean = false;
    public isCordova: boolean = false;
    public failedToLoadAudit: boolean = false;
    public groupQuestions: boolean = false;
    public auditDoc: IDocumentRev;
    public numImages: number = 0;

    public isMissedOrComplete: boolean = false;
    public isInlineCall: boolean = false;
    public showThirdPartyInfo: boolean = false;
    public totalMaxScore: number = 0;
    public isResendingEmail: boolean = false;
    private userDetail: IUser;

    private totalScore: number = 0;
    private shifts: IShift[];
    private auditShift: IShift;

    private showSupplierSelection: boolean = false;
    private parentPage: string;

    private dialogConfig: MatDialogConfig = new MatDialogConfig();

    constructor(public current: CurrentInfoService,
                public tools: ToolsService,
                private route: ActivatedRoute,
                private localize: LocalizeService,
                private config: Config,
                private local: LocalService,
                private notify: NotifyService,
                private api: ApiService,
                private identity: IdentityService,
                private router: Router,
                private log: LoggerService,
                private auditDetailService: AuditDetailService,
                private storage: StorageService,
                private zone: NgZone,
                private dialog: MatDialog,
                private uiNotify: UiNotificationService) {
    }

    ngOnInit(): void {
        this.initAuditResult();
        this.notify.broadcast("hide-top-nav", this.isPrintMode);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    public deleteAudit(): void {
        const data: IConfirmationModalData = { title: this.localize.getLocalizedString("_DeleteAudit_"),
                                               message: this.localize.getLocalizedString("_AreYouSureDeleteAudit_"),
                                               isConfirmationDialog: true,
                                               confirmButtonText: this.localize.getLocalizedString("_Delete_"),
                                               cancelButtonText: this.localize.getLocalizedString("_Cancel_") };

        this.dialogConfig.data = data;

        const dialogRef = this.dialog.open(ConfirmationModalComponent, this.dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
            if (result) {
                this.auditDetailService.deleteAudit(this.auditID).pipe(takeUntil(this.destroy$)).subscribe(() => {
                    const url = this.route.snapshot.root.children[0].routeConfig.path === "report" ? "/report/all-audits" : "/";
                    this.router.navigate([url]);
                });
            }
        });
    }

    public initAuditResult(): void {
        this.loading = true;
        this.notify.broadcast("full-page-block", true);
        this.log.debug("audit detail component onInit: " + this.loading);
        const id = parseInt(this.route.snapshot.params.id, 10);
        this.auditID = (this.auditID == null) ? id : this.auditID;

        this.isCordova = this.tools.isCordova();
        const prep = this.route.snapshot.queryParams.prep === "true";

        this.auditDetailService.getAuditByID(this.auditID, "Audits", false, true, prep)
            .pipe(takeUntil(this.destroy$))
            .subscribe((content: any) => {
            this.log.debug("getAuditByID returned data: " + content);
            if (content) {
                this.zone.run(() => {
                    this.auditDetail = content.auditDetail;
                    this.initAuditDetail(this.auditID);
                    this.loading = false;
                    this.notify.broadcast("full-page-block", false);
                });
            } else {
                this.log.debug("auditID not found: " + this.auditID);
            }
        });
    }

    public resendEmail(): void {
      this.isResendingEmail = true;
      const resendEmail: boolean = true;
      this.auditDetailService.resendEmail(this.auditID, resendEmail).subscribe((resuls) => {
        this.uiNotify.open("_EmailSuccessfullyResent_");
        this.isResendingEmail = false;
      }, (result) => {
        this.uiNotify.open("_FailedToResendEmail_");
      });
    }

    initAuditDetail(id: number): void {
        if (this.auditDetail != null) {
            this.getAuditDoc(id, this.auditDetail);
            // this.getShiftsByAuditSite();
            this.populateFailureInfo();
            this.checkThirdPartyInfo();

            if (this.audit.Status === EaseAuditStatus.Complete ||
                this.audit.Status === EaseAuditStatus.CompletedLate || this.audit.Status === EaseAuditStatus.Missed) {
                this.isMissedOrComplete = true;
            }

            this.getTotal();

            this.showSupplierSelection = this.auditConfig.AuditTargets.indexOf(5) > -1
                && this.current.info.org.IsSupplierEnabled;

        }
    }

    private getAuditDoc(paramID: number, auditDetail: IAuditDetail): void {
        const audit: IAudit = auditDetail.Audit;
        const auditDoc: IDocumentRev = auditDetail.DocumentRev;
        const auditQuestionGroups: IAuditQuestionGroup[] = auditDetail.AuditQuestionGroups;
        const artifacts: IArtifact[] = auditDetail.Artifacts;

        this.auditID = paramID;

        this.auditConfig = this.auditDetail.AuditConfiguration;
        this.audit = audit as IClientAudit;
        this.audit.StatusLabel = this.auditDetailService.getAuditTranslatedStatus(this.audit.Status);
        this.log.debug("audit.NonConformanceData: " + audit.NonConformanceData);

        if (this.audit == null) {
            // Unable to retrieve audit.  Most likely a permissioning issue
            this.failedToLoadAudit = true;
            this.loading = false;
        } else {
            if (audit.DocumentRevID != null) {
                this.auditDoc = auditDoc;
                if (this.auditDoc == null) {
                    // Unable to retrieve audit.  Most likely a permissioning issue
                    this.failedToLoadAudit = true;
                    this.loading = false;
                    return;
                }

                const props = this.auditDoc.Document.Properties.hasOwnProperty("OnePageAudit") ?
                    this.auditDoc.Document.Properties : this.auditDoc.Properties;

                this.getAuditQuestions(this.auditID, auditDetail);
            } else {
                this.log.debug("DocumentRevID is null");
                if (this.audit.Status === EaseAuditStatus.NotStarted ||
                    this.audit.Status === EaseAuditStatus.Missed || this.audit.Status === 5) {
                    this.log.debug("audit status: " + this.audit.Status);
                    this.getAuditQuestions(this.auditID, auditDetail);
                }
            }
        }
    }

    // Goes through the list of answers and creates a list of non-conforming answers
    private populateFailureInfo(): void {
        this.failedAnswers = [];
        this.auditQuestions.forEach((q: IClientAuditQuestion) => {
            if (q.AuditAnswer.IssueClassification != null) {
                this.failedAnswers.push(q.AuditAnswer);
            }
        });
    }

    private checkThirdPartyInfo(): void {
        this.showThirdPartyInfo = this.audit.DateEntered != null;
    }

    private getAuditQuestions(auditID: number, auditDetail: IAuditDetail): void {
        this.numImages = 0;
        if (auditID != null) {
            this.auditQuestions = [];

            let auditLineItem: IAuditLineItem[] = auditDetail.AuditLineItems;
            const auditAnswerArtifacts: IAuditAnswerArtifact[] = auditDetail.AuditAnswerArtifacts;

            // _.orderBy(auditLineItem, [lineItem => lineItem.SequenceNum], ["asc"]);
            auditLineItem = auditLineItem.sort((a, b) => {
                return (a.SequenceNum - b.SequenceNum);
            });
            auditLineItem.forEach((li: IAuditLineItem, index) => {
                const question: IClientAuditQuestion = li.AuditQuestion as IClientAuditQuestion;
                const answer: IClientAuditAnswer = question.AuditAnswer as IClientAuditAnswer;

                question.images = [];
                answer.images = [];
                question.currentImageIndex = 0;
                answer.IsOptionalMitigationSelected = false; // default to false
                // set the "IsRequired" field for each question
                question.IsRequired = li.IsRequired;
                question.AltResponsiblePartyID = li.AltResponsiblePartyID;
                question.AuditQuestionGroupID = li.AuditQuestionGroupID;
                question.AuditAnswer.MitigationActivity = [];
                const questionItems = (question != null && question.QuestionText != null) ?
                    question.QuestionText.split(" ") : [];
                if (questionItems.length > 0) {
                    for (const questionItem of questionItems) {
                        question.WordBreakAll = questionItem.length > 50 ? true : false;
                    }
                }

                this.auditQuestions.push(question);

            });
        }
    }

    private getTotal(): void {
        this.auditQuestions.forEach((question: IClientAuditQuestion) => {
            if (question.QuestionType === QuestionResponseTypes.Point) {
                // add score only for scored documents
                if (question.AuditAnswer.PointValue > 1) {
                    this.totalScore += question.AuditAnswer.PointValue - 1;
                }
                this.totalMaxScore += question.PointValue;
            }
        });
    }
}
