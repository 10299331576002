import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as _ from "lodash";
import {
  CurrentInfoService,
  IdentityService,
  LocalizeService,
  NotifyService,
  ToolsService,
  UserService,
  FeedbackService,
  LocalService,
} from "@core/services";

import { MatDialog } from "@angular/material";

// State
import { Store } from "@ngrx/store";
import * as fromAuth from "app/auth/reducers";
import {
  ChangeLanguage,
} from "app/auth/actions/session";

import { ChangePasswordModalContainerComponent } from "./change-password-modal-container.component";
import { DialogConfigSm, DialogConfigMd, InputDialogComponentData } from "@shared/dialogs";
import { ConfirmationDialogService } from "@shared/confirmation/confirmation-dialog.service";
import { CookieService } from "ngx-cookie";
import { Subject } from "rxjs";
import { InputDialogComponent } from "@shared/dialogs/input-dialog/input-dialog.component";

@Component({
  templateUrl: "./userprofile.component.html",
})
export class UserProfileComponent implements OnInit, OnDestroy {

  public user: any = null;
  public roles: any = null;
  public sites: any = null;
  public activeSite: any = {};
  public activeSiteID: number;
  public langCode: string = null;
  public enrollBetaDialogOpen = false;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private store: Store<fromAuth.State>,
              public current: CurrentInfoService,
              public notify: NotifyService,
              public identity: IdentityService,
              public router: Router,
              public localize: LocalizeService,
              public dialog: MatDialog,
              public confirm: ConfirmationDialogService,
              public userService: UserService,
              public cookieService: CookieService,
              public tools: ToolsService,
              public feedbackService: FeedbackService,
              public local: LocalService,
              private http: HttpClient) {
  }

  public ngOnInit() {
    this.langCode = this.localize.languageCode;
    this.onCurrentReady();
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onCurrentReady() {
    this.user = this.current.info.user;

    this.roles = _.uniqBy(this.current.info.roles, "Name");

    this.sites = this.current.info.sites;
    this.activeSite = this.current.info.activeSite;
    this.activeSiteID = this.current.info.activeSite.ID;
  }

  public setEnrollBeta() {
    this.enrollBetaDialogOpen = true;

    const config = new DialogConfigSm<InputDialogComponentData>();
    config.data = {
      title: "_EnrollBeta_",
      description: "_UnenrollBetaWarning_",
      confirmButtonText: "_OK_",
      minRows: 1,
      maxRows: 5,
      maxLength: 500,
    };

    const dialogRef = this.dialog.open(InputDialogComponent, config);

    dialogRef.afterClosed().takeUntil(this.destroy$).subscribe(result => {
      if (result === false) {
        this.user.EnrollBeta = !this.user.EnrollBeta;
        this.enrollBetaDialogOpen = false;
        return;
      }
      this.userService.updateUserSetting(this.user, true).subscribe(a => {
        if (result.length > 0) {
          const feedback = {
            subject: `${this.user.Email} - beta unenroll`,
            text: result,
          };
          this.feedbackService.send(feedback).subscribe();
        }
        this.http.post("/reports/clearlayouts", null).subscribe(_ => {
          this.cookieService.remove("EnrollBeta");
          this.identity.logout();
          this.local.clear();
          window.location.assign(window.location.origin);
        });
      });
    });
  }

  public onLanguageChange() {
    this.store.dispatch(new ChangeLanguage({
      Language: this.langCode,
      ActiveSiteID: this.activeSiteID,
    }));
  }

  public resetPasswordHandler() {
    const config = new DialogConfigMd();
    const dialogRef = this.dialog.open(ChangePasswordModalContainerComponent, config);

    dialogRef.afterClosed().takeUntil(this.destroy$).subscribe(result => {
    });
  }
}
