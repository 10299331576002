// RxJS
import { of } from "rxjs";
import { map, tap, switchMap, exhaustMap, catchError, withLatestFrom, filter } from "rxjs/operators";

// Angular
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// State
import { Store, select } from "@ngrx/store";
import { Effect, Actions, ofType } from "@ngrx/effects";

import * as fromAuth from "../reducers";

import {
  Logout,

  Impersonate,
  ImpersonateFailure,
  ImpersonateSuccess,

  Authenticate,
  AuthenticateSuccess,
  AuthenticateFailure,

  AuthenticateSSO,
  AuthenticateSSOSuccess,
  AuthenticateSSOFailure,

  Authorize,
  AuthorizeSuccess,
  AuthorizeFailure,

  RefreshToken,
  RefreshTokenSuccess,
  RefreshTokenFailure,

  RequestRestore,
  RequestRestoreSuccess,
  RequestRestoreFailure,

  ChangePassword,
  ChangePasswordSuccess,
  ChangePasswordFailure,

  CreatePassword,
  CreatePasswordSuccess,
  CreatePasswordFailure,

  LoginRedirect,
  HomeRedirectAfterImpersonate,

  AuthActionTypes,
} from "../actions/auth";
import {
  GetTranslation,
  GetCustomTranslation,
} from "../actions/session";

// Services
import {
  LocalizeService,
  LocalService,
} from "@core/services";
import { UiNotificationService } from "@core/services/common/ui-notification.service";
import { AuthService } from "../services/auth.service";

// Models
import {
  AuthenticationPayload,
  RequestPasswordPayload,
} from "../models/user";
import { AuthenticationErrorCodes, RestorePasswordErrorCodes } from "@app/auth/models/api";

@Injectable()
export class AuthEffects {
  @Effect()
  authenticate$ = this.actions$.pipe(
    ofType(AuthActionTypes.Authenticate),
    map((action: Authenticate) => action.payload),
    exhaustMap((auth: AuthenticationPayload) =>
      this.auth
        .authenticate(auth)
        .pipe(
          map(res => new AuthenticateSuccess({
            ...res,
            Login: auth.Login,
            RememberMe: auth.RememberMe,
            TokenRefreshDate: (new Date()).toString(),
          })),
          catchError(err => of(new AuthenticateFailure(err.error && err.error.Errors && err.error.Errors[ 0 ] || {
            Code: AuthenticationErrorCodes.BadCredentials,
            Message: "Authenticate Failure",
          }))),
        ),
    ),
  );

  @Effect()
  authenticateSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.AuthenticateSuccess),
    map((action: AuthenticateSuccess) => action.payload),
    tap(res => {
      this.local.setItem("RememberMe", res.RememberMe);
      if (res.RememberMe) {
        this.local.setItem("Login", res.Login);
      } else {
        this.local.removeItem("Login");
      }
      this.local.setItem("Token", res.Token);
      this.local.setItem("TokenRefreshDate", res.TokenRefreshDate);
      this.local.setItem("Language", res.Language);
    }),
    map(_ => new Authorize()),
  );

  @Effect()
  authenticateSSO$ = this.actions$.pipe(
    ofType(AuthActionTypes.AuthenticateSSO),
    map((action: AuthenticateSSO) => action.payload),
    exhaustMap(auth =>
      this.auth
        .authenticateSSO(auth)
        .pipe(
          map(res => new AuthenticateSSOSuccess({
            ...res,
            TokenRefreshDate: (new Date()).toString(),
          })),
          catchError(err => of(new AuthenticateSSOFailure(err.error.Errors && err.error.Errors[ 0 ] || {
            Code: AuthenticationErrorCodes.BadCredentials,
            Message: "Authenticate SSO Failure",
          }))),
        ),
    ),
  );

  @Effect()
  authenticateSSOSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.AuthenticateSSOSuccess),
    map((action: AuthenticateSSOSuccess) => action.payload),
    tap(res => {
      this.local.setItem("Token", res.Token);
      this.local.setItem("TokenRefreshDate", res.TokenRefreshDate);
      this.local.setItem("Language", res.Language);
    }),
    map(_ => new Authorize()),
  );

  @Effect()
  impersonate$ = this.actions$.pipe(
    ofType<Impersonate>(AuthActionTypes.Impersonate),
    map(action => action.payload),
    exhaustMap(id =>
      this.auth
        .impersonate(id)
        .pipe(
          map(res => new ImpersonateSuccess({
            ...res,
            TokenRefreshDate: (new Date()).toString(),
          })),
          catchError(err => of(new ImpersonateFailure(err.error && err.error.Errors && err.error.Errors[ 0 ] || {
            Code: -1,
            Message: "Impersonate Failure",
          }))),
        ),
    ),
  );

  @Effect()
  impersonateSuccess$ = this.actions$.pipe(
    ofType<ImpersonateSuccess>(AuthActionTypes.ImpersonateSuccess),
    map(action => action.payload),
    tap(res => {
      this.local.setItem("Token", res.Token);
      this.local.setItem("TokenRefreshDate", res.TokenRefreshDate);
      this.local.setItem("Language", res.Language);
    }),
    switchMap(_ => [
      new Authorize(),
      new HomeRedirectAfterImpersonate(),
    ]),
  );

  @Effect()
  authorize$ = this.actions$.pipe(
    ofType(AuthActionTypes.Authorize),
    exhaustMap(_ =>
      this.auth.authorize()
        .pipe(
          map(res => new AuthorizeSuccess(res)),
          catchError(error => of(new AuthorizeFailure(error))),
        ),
    ),
  );

  @Effect()
  authorizeSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.AuthorizeSuccess),
    map((action: AuthorizeSuccess) => action.payload),
    tap(userInfo => this.local.setItem("User", userInfo)),
    switchMap(data => [
      new GetTranslation(data.user.Language),
      new GetCustomTranslation(data.activeSite.ID),
    ]),
  );

  @Effect()
  refreshToken$ = this.actions$.pipe(
    ofType(AuthActionTypes.RefreshToken),
    map((action: RefreshToken) => action.payload),
    withLatestFrom(this.store.pipe(select(fromAuth.getLanguage))),
    filter(([ siteId, language ]) => {
      return !!siteId && !!language;
    }),
    exhaustMap(([ siteId, language ]) => {
      return this.auth
        .refreshToken(siteId, language)
        .pipe(
          map(res => {
            res.TokenRefreshDate = (new Date()).toString();

            return res;
          }),
          tap(res => {
            this.local.setItem("Token", res.Token);
            this.local.setItem("TokenRefreshDate", res.TokenRefreshDate);
          }),
          switchMap(res => [
            new RefreshTokenSuccess(res),
            new Authorize(),
          ]),
          catchError(error => of(new RefreshTokenFailure(error))),
        );
    }),
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    tap(() => this.router.navigate([ "/" ])),
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(
      AuthActionTypes.Logout,
      AuthActionTypes.LogoutFromDropDown,
      AuthActionTypes.LogoutFromInterceptor,
      AuthActionTypes.LogoutFromGuard,
    ),
    map(() => {
      this.local.removeItem("Token");
      this.local.removeItem("TokenRefreshDate");
      this.local.removeItem("User");

      return new LoginRedirect();
    }),
  );

  @Effect()
  requestRestore$ = this.actions$.pipe(
    ofType<RequestRestore>(AuthActionTypes.RequestRestore),
    map((action: RequestRestore) => action.payload),
    exhaustMap((payload: RequestPasswordPayload) =>
      this.auth
        .requestPassword(payload)
        .pipe(
          map(_ => new RequestRestoreSuccess()),
          catchError(err => {
            return of(new RequestRestoreFailure(err.error.Email));
          }),
        ),
    ),
  );

  @Effect({ dispatch: false })
  successRestoreRedirect$ = this.actions$.pipe(
    ofType<RequestRestoreSuccess>(AuthActionTypes.RequestRestoreSuccess),
    tap(_ => {
      this.router.navigate([ "/restore-success" ]);
    }),
  );

  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType<ChangePassword>(AuthActionTypes.ChangePassword),
    map(action => action.payload),
    exhaustMap(payload =>
      this.auth
        .setPassword(payload)
        .pipe(
          map(_ => new ChangePasswordSuccess()),
          catchError(err => of(new ChangePasswordFailure(err.error && err.error.Errors && err.error.Errors[ 0 ] || {
            Code: RestorePasswordErrorCodes.Unknown,
            Message: "Change Password Failure",
          }))),
        ),
    ),
  );

  @Effect({ dispatch: false })
  successChangePasswordRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.ChangePasswordSuccess),
    tap(_ => {
      this.router.navigate([ "/password-change-success" ]);
    }),
  );

  @Effect()
  createPassword$ = this.actions$.pipe(
    ofType<CreatePassword>(AuthActionTypes.CreatePassword),
    map(action => action.payload),
    exhaustMap(payload =>
      this.auth
        .setPassword(payload)
        .pipe(
          map(_ => new CreatePasswordSuccess()),
          catchError(err => of(new CreatePasswordFailure(err.error && err.error.Errors && err.error.Errors[ 0 ] || {
            Code: RestorePasswordErrorCodes.Unknown,
            Message: "Create Password Failure",
          }))),
        ),
    ),
  );

  @Effect({ dispatch: false })
  successCreatePasswordRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.CreatePasswordSuccess),
    tap(_ => {
      this.router.navigate([ "/password-create-success" ]);
    }),
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginRedirect),
    map((action: Logout) => action.payload),
    tap(returnUrl => {
      const extras = returnUrl
        ? { queryParams: { returnUrl } }
        : {};

      this.router.navigate([ "/login" ], extras);
    }),
  );

  @Effect({ dispatch: false })
  homeRedirect$ = this.actions$.pipe(
    ofType(
      AuthActionTypes.HomeRedirect,
      AuthActionTypes.HomeRedirectFromRolesGuard,
      AuthActionTypes.HomeRedirectAfterImpersonate,
    ),
    tap(_ => this.router.navigate([ "/" ])),
  );

  @Effect({ dispatch: false })
  authedRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.AuthedRedirect),
    tap(_ => {
      const message = this.localize.getLocalizedString("_Auth:AuthedRedirect_");

      this.uiNotify.open(message);

      this.router.navigate([ "/" ]);
    }),
  );

  @Effect({ dispatch: false })
  sysAdminRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.SysAdminRedirect),
    tap(() => {
      this.router.navigate([ "/system-admin" ]);
    }),
  );

  @Effect({ dispatch: false })
  ssoLoginRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.SSOLoginRedirect),
    tap(() => {
      window.location.href = "/account/LoginSSO?returnUrl=" + encodeURIComponent(window.location.href);
    }),
  );

  constructor(private actions$: Actions,
              private store: Store<fromAuth.State>,
              private router: Router,
              private auth: AuthService,
              private localize: LocalizeService,
              private uiNotify: UiNotificationService,
              private local: LocalService) {
  }
}
