﻿import { Injectable } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";

@Injectable()
export class AnalyticsService {

    constructor(private log: LoggerService) {
    }

    public init(analyticsAppID: string): void {
        const w: any = window as any;
        w.heapLoaded = false;

        if (analyticsAppID !== "") {
            /* tslint:disable */
            w.heap = w.heap || [], w.heap.load = function (e:any, t:any) { w.heap.appid = e, w.heap.config = t = t || {}; var r = t.forceSSL || "https:" === document.location.protocol, a = document.createElement("script"); a.type = "text/javascript", a.async = !0, a.src = (r ? "https:" : "http:") + "//cdn.heapanalytics.com/js/heap-" + e + ".js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(a, n); for (var o = function (e:any) { return function () { w.heap.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], c = 0; c < p.length; c++) w.heap[p[c]] = o(p[c]) };
            /* tslint:enable */
            this.log.debug("analytics.service: load appID=" + analyticsAppID);
            w.heap.load(analyticsAppID);
            w.heapLoaded = true;
        }
    }

    public identify(login: string): void {
        const heap: any = (window as any).heap;
        if (heap != null && heap !== "") {
            this.log.debug("analytics.service: user set to " + login);
            heap.identify(login);
        }
    }

    public addUserProperties(properties: object): void {
        const heap: any = (window as any).heap;
        if (heap != null && heap !== "") {
            this.log.debug("analytics.service: user properties set to " + JSON.stringify(properties));
            heap.addUserProperties(properties);
        }
    }

    public addEventProperties(properties: object): void {
        const heap: any = (window as any).heap;
        if (heap != null && heap !== "") {
            this.log.debug("analytics.service: event properties set to " + JSON.stringify(properties));
            heap.addEventProperties(properties);
        }
    }
}
