import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {LocalizeService} from "../../../core/services/localize.service";
import {ICapaEventLineItem, IClientCapaEvent, IDocumentRev, ISite, IUser} from "../../../core/models/ease-models";
import {FileItem, FileUploader} from "ng2-file-upload";
import {IdentityService} from "../../../core/services/identity.service";
import {UiNotificationService} from "../../../core/services/common/ui-notification.service";
import {StorageService} from "../../../core/services/storage.service";
import {LocalService} from "../../../core/services/local.service";
import {NotifyService} from "../../../core/services/notify.service";
import {CorrectiveActionsDataAccessService} from "../../services/corrective-actions-data-access.service";
import {CurrentInfoService} from "../../../core/services/current.service";
import {ConfirmationDialogService} from "../../../shared/confirmation/confirmation-dialog.service";
import {FormControl, FormGroup, FormBuilder, FormArray, Validators} from "@angular/forms";
import {FileUploadData, FileUploadTypes} from "@shared/file-upload/file-upload.class";
import {FileUploadService} from "@shared/file-upload/file-upload.service";
import {Observable} from "rxjs";
import {IDocumentArtifact} from "@core/models/ease-models";
import {CapaDocumentLineItem} from "../../../manage/documents/document-tasks/documet-task.model";

@Component({
  templateUrl: "./add-capa-event-modal.component.html",
})

export class AddCApaEventModalComponent implements OnInit {

  @Output() public imageUploaded = new EventEmitter<any>();

  public isLoading: boolean = true;
  public capaEventForm: FormGroup;
  public isSavingCapaEvent: boolean = false;

  fileUploadData: FileUploadData;
  isReadOnly: boolean = false;
  private capaDocumentRevs: IDocumentRev[];

  private userSites: ISite[];
  private capaEvent: IClientCapaEvent;
  private capaOwners: IUser[];
  private mandatoryCapaOwner: IUser;
  private optionalCapaOwners: IUser[];
  private capaApprovers: IUser[];
  private mandatoryCapaApprover: IUser;
  private optionalCapaApprovers: IUser[];
  private minDate: Date;
  private capaMaxDueDays: number = 0;

  constructor(
    public dialogRef: MatDialogRef<AddCApaEventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private localize: LocalizeService, private identity: IdentityService, private uiNotify: UiNotificationService, private storage: StorageService,
    private notify: NotifyService, private local: LocalService, private dataAccess: CorrectiveActionsDataAccessService, private current: CurrentInfoService,
    private confirm: ConfirmationDialogService, private formBuilder: FormBuilder, private fileUpload: FileUploadService) {
  }

  ngOnInit() {
    this.optionalCapaOwners = [];
    this.optionalCapaApprovers = [];
    this.initModal(this.data);
    this.capaEventForm = this.formBuilder.group({
      document: new FormControl({value: this.capaEvent.DocumentRevID, disabled: this.isReadOnly}, [Validators.required]),
      title: new FormControl({value: this.capaEvent.Name, disabled: this.isReadOnly}, [Validators.required, Validators.maxLength(150)]),
      site: new FormControl({value: this.capaEvent.SiteID, disabled: true}, [Validators.required]),
      mandatoryCapaOwner: new FormControl({value: this.mandatoryCapaOwner, disabled: true}, [Validators.required]),
      optionalCapaOwners: this.formBuilder.array([]),
      mandatoryCapaApprover: new FormControl({value: this.mandatoryCapaApprover, disabled: true}, [Validators.required]),
      optionalCapaApprovers: this.formBuilder.array([]),
      dueDate: new FormControl(this.capaEvent.DueDate, [Validators.required]),
      summary: new FormControl({disabled: this.isReadOnly}, [Validators.maxLength(2000)]),
      uploads: new FormControl(),
    });

    this.fileUpload.initFileUploadData(FileUploadTypes.CorrectiveAction, []).subscribe((results) => {
      this.fileUploadData = results;
    });
  }

  public initModal(data) {
    // just create a new dummy object for now
    this.capaEvent = {
      ID: 0, CreatedByUserID: 0, OrganizationID: 0, Name: "", CapaSettingID: 0, CapaStatus: 0, ValidationStatus: 0, DocumentID: 0, DocumentRevID: 0, GracePeriodDays: 0,
      PastDueDays: 0, EscapePointCustomItemID: null, Summary: "", IsDeleted: false, PotentialImpactCustomItemID: null, SeverityCustomItemID: null, SiteID: 0 };
    this.getCapaDocumentRevs();
  }

  public cancelHandler() {
    this.dialogRef.close(false);
  }
  public saveHandler() {
    this.isSavingCapaEvent = true;
    // add the list of selected capa owners
    this.setCapaOwnerIDs();
    // add the list of selected capa approvers
    this.setCapaApproversIDs();

    const dueDate = new Date(this.capaEvent.DueDate);

    const payload = {...this.capaEvent};
    payload.DueDate = new Date(Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate())).toISOString();

    this.dataAccess.addCapaEvent(payload).subscribe((capaEventID: number) => {
      if (capaEventID > 0) {
        this.fileUpload.postFileUploadChange(capaEventID, this.fileUploadData).subscribe(() => {
          this.isSavingCapaEvent = false;
          this.dialogRef.close(capaEventID);
        });
      }
    });
  }

  private getCapaDocumentRevs(): void {
    this.isLoading = true;
    this.dataAccess.getDocumentRev({ details: true, liveDocsOnly: true, includeDocLocations: true, documentTypeID: 2 }).subscribe((data: IDocumentRev[]) => {
      if (data && data.length) {
        this.capaDocumentRevs = data.sort((a, b) => a.Document.Name.localeCompare(b.Document.Name));
      }
      this.isLoading = false;
    });
  }

  private onCapaDocChanged(): void {

    // Update list of Sites based on Document scope
    this.capaEvent.SiteID = null;
    const filtered = this.capaDocumentRevs.filter((item) => item.ID === this.capaEvent.DocumentRevID);
    if (filtered.length > 0) {
      const doc = filtered[0].Document;
      this.dataAccess.getSite({ q: "scope", p: doc.ScopeID}).subscribe((data: ISite[]) => {
        this.userSites = data;
        if (this.userSites && this.userSites.length) {
          this.capaEventForm.controls.site.enable();
        }
        if (filtered[0].featureFlags.advancedCorrectiveActions) {
          this.dataAccess.getCapaDocumentMaxDueDays(filtered[0].ID).subscribe((maxDueDays) => {
            this.capaMaxDueDays = maxDueDays;
          });
        }
      });
    }
  }

  // for now include only Admin role. We may expand this to include Managers
  private getUsersForSiteScope(callback): void {
    if (this.capaEvent && this.capaEvent.SiteID > 0) {
      const selectedSite = this.userSites.filter(site => site.ID === this.capaEvent.SiteID)[0];
      const d = new Date();
      const siteDateWithOffset = new Date(d.setTime(d.getTime() + selectedSite.TimeZoneOffset * 60 * 1000));

      this.capaEventForm.controls.dueDate.setValue(null);
      this.minDate = new Date(siteDateWithOffset.getUTCFullYear(), siteDateWithOffset.getUTCMonth(), siteDateWithOffset.getUTCDate());
      this.minDate = this.minDate.addDays(this.capaMaxDueDays);

      this.dataAccess.getUsersForSiteScope({ siteID: this.capaEvent.SiteID, filteredRoles: "SiteAdmin|Manager", includeScopedUsers: true }).subscribe((data: IUser[]) => {
        if (data && data.length > 0) {
          const managers = data.filter((item) => item.UserRoles
            .filter((ur: any) => ur.Role.Name === "Manager").length > 0);
          this.capaOwners = managers;
          this.mandatoryCapaOwner = null;
          this.optionalCapaOwners = [];

          const users = data.map(x => Object.assign({}, x));
          const admins = users.filter((item) => item.UserRoles
            .filter((ur: any) => ur.Role.Name === "SiteAdmin").length > 0);
          this.capaApprovers = admins;
          // init the approver list to the user creating the CAPA event
          const selectedUser = this.capaApprovers.filter((item: IUser) => item.ID === this.current.info.user.ID);
          if (selectedUser != null && selectedUser.length > 0) {
            this.mandatoryCapaApprover = selectedUser[0];
            this.capaEventForm.controls.mandatoryCapaOwner.enable();
            this.capaEventForm.controls.mandatoryCapaApprover.enable();
          }
          this.optionalCapaApprovers = [];
        }
        if (callback) {callback(); }
      });
    }
  }

  // user may be an CAPA approver or a CAPA Owner
  private addOptionalCAPAUser(mandatoryUser, users: IUser[], optionalUsers: IUser[], isApprover = false) {
    let userToAdd = null;
    // get the first available user (not already selected)
    if (mandatoryUser) {
      if (users && users.length) {
        for (const i of users) {
          if (i === mandatoryUser) {
            continue;
          }
          let found = false;
          if (optionalUsers) {
            for (const j of optionalUsers) {
              if (i.ID === j.ID) {
                found = true;
                break;
              }
            }
          }
          if (!found) {
            userToAdd = i;
            break;
          }
        }
        if (userToAdd) {
          optionalUsers.push(userToAdd);
          this.addOptionalCapaUserControl(userToAdd, isApprover);
        }
      }
    }
  }

  private removeOptionalCapaUser(optionalCAPAUsers: IUser[], id: number, isApprover = false) {
    if (id > 0) {
      let indexToRemove = -1;
      if (optionalCAPAUsers && optionalCAPAUsers.length) {
        for (let i = 0; i < optionalCAPAUsers.length; i++) {
          if (optionalCAPAUsers[i].ID === id) {
            indexToRemove = i;
            break;
          }
        }
      }
      if (indexToRemove >= 0) {
        optionalCAPAUsers[indexToRemove].IsSelected = false;
        optionalCAPAUsers.splice(indexToRemove, 1);
        this.deleteCapaUserControl(indexToRemove, isApprover);
      }
    }
  }

  private addOptionalCapaUserControl(userToAdd, isApprover) {
    const controlName = (isApprover) ? "optionalCapaApprovers" : "optionalCapaOwners";
    // control refers to your formarray
    const control = this.capaEventForm.controls[controlName] as FormArray;
    // add new formgroup
    control.push(this.initOptionalCapaUserRows(userToAdd, isApprover));
  }

  private initOptionalCapaUserRows(userToAdd: IUser, isApprover: boolean) {
    const result = (isApprover) ? this.formBuilder.group({optionalCapaApprover: userToAdd})
      : this.formBuilder.group({optionalCapaOwner: userToAdd});
    return result;
  }

  private deleteCapaUserControl(index: number, isApprover: boolean) {
    // control refers to your formarray
    const control = (isApprover) ? this.capaEventForm.controls["optionalCapaApprovers"] as FormArray : this.capaEventForm.controls["optionalCapaOwners"] as FormArray;
    // remove the chosen row
    control.removeAt(index);
  }

  private setCapaOwnerIDs() {
    if (this.capaEvent) {
      this.capaEvent.CapaEventOwnerIDs = [];
      this.capaEvent.CapaEventOwnerIDs.push(this.mandatoryCapaOwner.ID);
      if (this.optionalCapaOwners && this.optionalCapaOwners.length) {
        for (const i of this.optionalCapaOwners) {
          this.capaEvent.CapaEventOwnerIDs.push(i.ID);
        }
      }
    }
  }

  private setCapaApproversIDs() {
    if (this.capaEvent) {
      this.capaEvent.CapaEventApproverIDs = [];
      this.capaEvent.CapaEventApproverIDs.push(this.mandatoryCapaApprover.ID);
      if (this.optionalCapaApprovers && this.optionalCapaApprovers.length) {
        for (const i of this.optionalCapaApprovers) {
          this.capaEvent.CapaEventApproverIDs.push(i.ID);
        }
      }
    }
  }

  private removeOptionalCapaOwnerHandler(optionalCAPAUsers: IUser[], id: number) {

    this.confirm.open("_RemoveCAPAOwner_", "_AreYouSureRemoveCAPAOwner_", true, "_RemoveCAPAOwner_", "_Cancel_");

    this.confirm.getResponse().first().subscribe((response) => {

      if (response === true) {
        this.removeOptionalCapaUser(optionalCAPAUsers, id);
      }
    });
  }

  private removeOptionalCapaApproverHandler(optionalCAPAUsers: IUser[], id: number) {

    this.confirm.open("_RemoveCAPAApprover_", "_AreYouSureRemoveCAPAApprover_", true, "_RemoveCAPAApprover_", "_Cancel_");

    this.confirm.getResponse().first().subscribe((response) => {

      if (response === true) {
        this.removeOptionalCapaUser(optionalCAPAUsers, id, true);
      }
    });
  }
}
