
import { Injectable } from "@angular/core";
import { IJob, IRank, IUserListViewPreferences, ListViewName } from "../../models/ease-models";
import { UiNotificationService } from "./ui-notification.service";
import { ApiService } from "../api.service";
import { AppSettingsService } from "../app-settings.service";
import { CurrentInfoService } from "../current.service";
import { LocalizeService } from "../localize.service";

import { MatSnackBar } from "@angular/material";
import * as _ from "lodash";
import {Observable,  Subject } from "rxjs";

@Injectable()
export class ColumnSettingsService {

    private auditList: any[] = [
        { Name: "ID", Label: "_ID_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Name", Label: "_AuditName_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DueDateUDF", Label: "_Due_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "CompletedDateUDF", Label: "_Completed_", IsSelected: false, IsEnabled: true, LabelName: ""},
        { Name: "Auditor", Label: "_Auditor_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Status", Label: "_Status_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Result", Label: "_Result_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Location", Label: "_Location_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Department", Label: "_Department_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Layer", Label: "_Layer_", IsSelected: true, IsEnabled: false, LabelName: "" },
        { Name: "Shift", Label: "_Shift_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Site", Label: "_Site_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "JobCode", Label: "_JobCode_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Customer", Label: "_Customer_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "TemplateType", Label: "_TemplateType_", IsSelected: false, IsEnabled: true, LabelName: "" },
    ];
    private allMitigations: any[] = [
        { Name: "ID", Label: "_ID_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Name", Label: "_AuditDescription_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DueDateUDF", Label: "_Due_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DateOpenedUDF", Label: "_DateOpened_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DateClosedUDF", Label: "_DateClosed_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Auditor", Label: "_Auditor_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ResponsibleParty", Label: "_ResponsibleParty_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Status", Label: "_Status_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Location", Label: "_Location_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Department", Label: "_Department_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Layer", Label: "_Layer_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Shift", Label: "_Shift_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Site", Label: "_Site_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Urgency", Label: "_Urgency_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "JobCode", Label: "_JobCode_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Customer", Label: "_Customer_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Countermeasure", Label: "_Countermeasures_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "TemplateType", Label: "_TemplateType_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "QuestionTitle", Label: "_QuestionTitle_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "QuestionText", Label: "_QuestionText_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Failure", Label: "_Failure_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ApprovingParty", Label: "_ApprovingParty_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ApprovingPartyComments", Label: "_ApprovingPartyComments_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "AuditorComments", Label: "_AuditorComments_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ResponsiblePartyComments", Label: "_ResponsiblePartyComments_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Supplier", Label: "_Supplier_", IsSelected: false, IsEnabled: true, LabelName: "" },
    ];
    private openMitigations: any[] = [
        { Name: "ID", Label: "_ID_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Name", Label: "_AuditDescription_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "DueDateUDF", Label: "_Due_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DateOpenedUDF", Label: "_DateOpened_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Auditor", Label: "_Auditor_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ResponsibleParty", Label: "_ResponsibleParty_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Status", Label: "_Status_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Location", Label: "_Location_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Department", Label: "_Department_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Layer", Label: "_Layer_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Shift", Label: "_Shift_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Site", Label: "_Site_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Urgency", Label: "_Urgency_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "JobCode", Label: "_JobCode_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "Customer", Label: "_Customer_", IsSelected: false, IsEnabled: false, LabelName: "" },
        { Name: "ResponsiblePartyComments", Label: "_ResponsiblePartyComments_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "QuestionTitle", Label: "_QuestionTitle_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Failure", Label: "_Failure_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "TemplateType", Label: "_TemplateType_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "QuestionText", Label: "_QuestionText_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ApprovingParty", Label: "_ApprovingParty_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "ApprovingPartyComments", Label: "_ApprovingPartyComments_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "AuditorComments", Label: "_AuditorComments_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Supplier", Label: "_Supplier_", IsSelected: false, IsEnabled: true, LabelName: "" },
    ];
    private userList: any[] = [
        { Name: "Name", Label: "_Name_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Email", Label: "_Email_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Department", Label: "_Department_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Layer", Label: "_Layer_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Shift", Label: "_Shift_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Roles", Label: "_Roles_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Scope", Label: "_Scope_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "Status", Label: "_Status_", IsSelected: true, IsEnabled: true, LabelName: "" },
    ];
    private jobList: any[] = [
        { Name: "JobCode", Label: "_JobCode_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Description", Label: "_Description_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Status", Label: "_Status_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "Customer", Label: "_Customer_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "JobType", Label: "_JobType_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "ResponsibleParty", Label: "_ResponsibleParty_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "StartDate", Label: "_StartDate_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "CreationDate", Label: "_CreationDate_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "EndDate", Label: "_EndDate_", IsSelected: true, IsEnabled: true, LabelName: "" },
    ];
    private capaEventList: any[] = [
        { Name: "CapaEventName", Label: "_Name_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DocumentName", Label: "_Document_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "ID", Label: "_ID_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "SiteName", Label: "_Site_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DueDateUDF", Label: "_DueDate_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "DateOpenedUDF", Label: "_DateOpened_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "CapaOwners", Label: "_CapaOwners_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "CapaApprovers", Label: "_CapaApprovers_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "StatusString", Label: "_Status_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "SeverityString", Label: "_Severity_", IsSelected: true, IsEnabled: true, LabelName: "" },
        { Name: "EscapePointString", Label: "_EscapePoint_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "EscapeValueString", Label: "_EscapeValue_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "DateClosedUDF", Label: "_DateClosed_", IsSelected: false, IsEnabled: true, LabelName: "" },
        { Name: "PercentComplete", Label: "_PercentComplete_", IsSelected: false, IsEnabled: true, LabelName: "" },

    ];
    public listViewOption: any;
    public listViewItems: any;
    public listViewOriginal: any[] = [];
    public current: any;
    public userListViewPreferences: IUserListViewPreferences;
    public userPreferencesSubject = new Subject<IUserListViewPreferences>();
    public ranks: IRank[];
    public enableUrgency: boolean = false;
    public originalOpenMitigations: any[] = [];
    public originalAllMitigations: any[] = [];
    public originalAllAudits: any[] = [];
    public originalUserList: any[] = [];
    public originalJobList: any[] = [];
    public originalCapaEventList: any[] = [];

    constructor(private api: ApiService, private localize: LocalizeService, private uiNotify: UiNotificationService,
                private Current: CurrentInfoService, private AppSettings: AppSettingsService) {

        this.getRanks();
        this.originalOpenMitigations = _.cloneDeep(this.openMitigations);
        this.originalAllAudits = _.cloneDeep(this.auditList);
        this.originalUserList = _.cloneDeep(this.userList);
        this.originalAllMitigations = _.cloneDeep(this.allMitigations);
        this.originalJobList = _.cloneDeep(this.jobList);
        this.originalCapaEventList = _.cloneDeep(this.capaEventList);

    }

    public getUserListViewPreferences(listViewOption: any): void {

        this.api.Query("UserListViewPreferences", { name: listViewOption }, (data: any) => {
            this.userListViewPreferences = data[0];
            this.userPreferencesSubject.next(this.userListViewPreferences);
        });

        switch (listViewOption) {
            case ListViewName[ListViewName._AllAudits_]:
                this.listViewOriginal = _.cloneDeep(this.auditList);
                break;
            case ListViewName[ListViewName._AllMitigations_]:
                this.listViewOriginal = _.cloneDeep(this.allMitigations);
                break;
            case ListViewName[ListViewName._OpenMitigations_]:
                this.listViewOriginal = _.cloneDeep(this.openMitigations);
                break;
            case ListViewName[ListViewName._UserList_]:
                this.listViewOriginal = _.cloneDeep(this.userList);
                break;
            case ListViewName[ListViewName._JobList_]:
                this.listViewOriginal = _.cloneDeep(this.jobList);
                break;
            case ListViewName[ListViewName._CapaEventList_]:
                this.listViewOriginal = _.cloneDeep(this.capaEventList);
                break;
        }
    }

    public resetChanges(listViewOption: any, isApplyChanges: boolean = true): void {
        switch (listViewOption) {
            case ListViewName[ListViewName._AllAudits_]:
                this.listViewItems = _.cloneDeep(this.originalAllAudits);
                break;
            case ListViewName[ListViewName._AllMitigations_]:
                this.listViewItems = _.cloneDeep(this.originalAllMitigations);
                break;
            case ListViewName[ListViewName._OpenMitigations_]:
                this.listViewItems = _.cloneDeep(this.originalOpenMitigations);
                break;
            case ListViewName[ListViewName._UserList_]:
                this.listViewItems = _.cloneDeep(this.originalUserList);
                break;
            case ListViewName[ListViewName._JobList_]:
                this.listViewItems = _.cloneDeep(this.originalJobList);
                break;
            case ListViewName[ListViewName._CapaEventList_]:
                this.listViewItems = _.cloneDeep(this.originalCapaEventList);
                break;
        }

        if (isApplyChanges) { this.applyChanges(this.listViewItems, listViewOption); }
        return this.listViewItems;
    }

    public displayOptions(listViewOption: any): any {
        switch (listViewOption) {
            case ListViewName[ListViewName._AllAudits_]:
                this.checkEnabledFields(this.auditList);
                this.listViewItems = this.auditList.filter((lvi: any) =>  lvi.IsEnabled == true);
                break;
            case ListViewName[ListViewName._AllMitigations_]:
                this.checkEnabledFields(this.allMitigations);
                this.listViewItems = this.allMitigations.filter((lvi: any) =>  lvi.IsEnabled == true);
                break;
            case ListViewName[ListViewName._OpenMitigations_]:
                this.checkEnabledFields(this.openMitigations);
                this.listViewItems = this.openMitigations.filter((lvi: any) =>  lvi.IsEnabled == true);
                break;
            case ListViewName[ListViewName._UserList_]:
                this.checkEnabledFields(this.userList);
                this.listViewItems = this.userList.filter((lvi: any) =>  lvi.IsEnabled == true);
                break;
            case ListViewName[ListViewName._JobList_]:
                this.checkEnabledFields(this.jobList);
                this.listViewItems = this.jobList.filter((lvi: any) =>  lvi.IsEnabled == true);
                break;
            case ListViewName[ListViewName._CapaEventList_]:
                this.checkEnabledFields(this.capaEventList);
                this.listViewItems = this.capaEventList.filter((lvi: any) =>  lvi.IsEnabled == true);
                break;
        }

        if (this.userListViewPreferences != null && this.userListViewPreferences.Columns != null) {
            //get list view preferences of the current user and match it with default preferences
            let jsonListViewPreferences = JSON.parse(this.userListViewPreferences.Columns);
            if (jsonListViewPreferences.length > 0) {
                let columnItems = jsonListViewPreferences;
                _.each(columnItems, (columnItem) => {
                    let lvItem = this.listViewItems.filter((item: any) =>  item.Name == Object.keys(columnItem)[0]);
                    if (lvItem.length > 0) {
                        lvItem[0].IsSelected = columnItem[Object.keys(columnItem)[0]];
                    }
                });
            }
        } else {
            //if no selected columns, get default selected columns
            if (this.listViewItems.filter((lvi: any) =>  lvi.IsSelected == true).length == 0) {
                this.resetChanges(listViewOption, false);
            }
        }

        //localize column labels
        _.each(this.listViewItems, (item) => {
            item.LabelName = this.localize.getLocalizedString(item.Label);
        });
        this.listViewItems = _.orderBy(this.listViewItems, [(lvi: any) => lvi.LabelName], ["asc"]);  //sort by "LabelName" ascending
        return this.listViewItems;
    }

    public checkEnabledFields(listItems: any) {
        this.checkJobEnabled(listItems);
        this.checkCustomerEnabled(listItems);
        this.checkLPAEnabled(listItems);
        this.checkShiftEnabled(listItems);
        this.checkUrgencyEnabled(listItems);
        this.checkCountermeasuresEnabled(listItems);
        this.checkSupplierEnabled(listItems);
    }

    public checkJobEnabled(listItems: any): void {
        let item = listItems.filter((job: any) => job.Name == "JobCode");
        if (item.length > 0) {
            if (this.Current.info.isJobTypeEnabled) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public checkCustomerEnabled(listItems: any): void {
        let item = listItems.filter((listItem: any) => listItem.Name == "Customer");
        if (item.length > 0) {
            if (this.Current.info.isCustomerTypeEnabled) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public checkSupplierEnabled(listItems: any): void {
        let item = listItems.filter((listItem: any) => listItem.Name == "Supplier");
        if (item.length > 0) {
            if (this.Current.info.isSupplierEnabled) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public checkLPAEnabled(listItems: any): void {
        let item = listItems.filter((listItem: any) => listItem.Name == "Layer");

        if (item.length > 0) {
            if (this.AppSettings.currentAppSettings.isLpaEnabled) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public checkShiftEnabled(listItems: any): void {
        let item = listItems.filter((listItem: any) => listItem.Name == "Shift");
        if (item.length > 0) {
            if (this.Current.info.activeSite.UseShifts) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public checkUrgencyEnabled(listItems: any): void {

        let item = listItems.filter((listItem: any) => {listItem.Name == "Urgency"; });
        if (item.length > 0) {
            if (this.enableUrgency) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public checkCountermeasuresEnabled(listItem: any): void {

        let item = listItem.filter((listItem: any) => listItem.Name == "Countermeasure");
        if (item.length > 0) {
            if (this.AppSettings.currentAppSettings.isCountermeasuresEnabled) {
                item[0].IsEnabled = true;
            } else {
                item[0].IsEnabled = false;
            }
        }
    }

    public applyChanges(listViewItems: any[], listViewOption: any): void {

        let hasSelectedColumn = listViewItems.filter((listViewItem: any) => listViewItem.IsSelected == true).length > 0;
        if (hasSelectedColumn) {
            let listViewColumns: any[] = [];
            _.each(listViewItems, (lv) => {
                let jsonItem = {};
                jsonItem[lv.Name] = lv.IsSelected;
                listViewColumns.push(jsonItem);
            });

            let jsonObj = listViewColumns;

            let listViewPreferences = this.userListViewPreferences;
            if (listViewPreferences == null) {
                this.userListViewPreferences = { ListViewName: listViewOption, Columns: jsonObj };
                this.userListViewPreferences.Columns = JSON.stringify(jsonObj);

                this.userPreferencesSubject.next(this.userListViewPreferences);

                this.api.Add("userListViewPreferences", this.userListViewPreferences, (id: any) => {
                    this.getUserListViewPreferences(listViewOption);
                }, null, null);
            } else {
                let jsonListViewPreferences = JSON.parse(this.userListViewPreferences.Columns);

                if (jsonListViewPreferences.length > 0) {
                    jsonListViewPreferences = listViewColumns;
                } else {
                    jsonListViewPreferences.push(jsonObj);
                }
                this.userListViewPreferences.Columns = JSON.stringify(jsonListViewPreferences);

                this.userPreferencesSubject.next(this.userListViewPreferences);

                this.api.Update("userListViewPreferences", this.userListViewPreferences.ID, this.userListViewPreferences, (success: any) => {

                }, null, null);
            }
        } else {
            this.uiNotify.open("_AtLeastOneColumnSelected_");
        }

    }

    public getColumnWidth(columnName: any, gridColumnItems: any[]): number {
        let visibleColumns = gridColumnItems.filter((item) =>  item.Label == columnName);
        let columnWidth: number;
        if (visibleColumns != null && visibleColumns.length > 0) {
            columnWidth = visibleColumns[0].ColumnWidth;
        }

        return columnWidth;
    }

    public checkColumnDisplay(columnName: any, gridColumnItems: any[]): boolean {
        let isVisible: boolean = false;
        if (gridColumnItems != null) {
            let visibleColumns = gridColumnItems.filter((item) =>  item.Label == columnName);

            if (visibleColumns != null && visibleColumns.length > 0) {
                isVisible = visibleColumns[0].IsSelected;
            }
        }

        return isVisible;
    }

    private getRanks(): void {
        this.api.Query("Rank", {}, (data: IRank[]) => {
            this.ranks = data;
            if (this.ranks.length > 1) {
                this.enableUrgency = true;
            }
        });
    }

    public getUserPreferencesChanges(): Observable<IUserListViewPreferences> {

        return this.userPreferencesSubject.asObservable();
    }
}