import {
  Component, Input, forwardRef, EventEmitter, Output, ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IArtifact } from "@core/models/ease-models";
import { FileItem } from "ng2-file-upload";
import { FileUploadData } from "@shared/file-upload/file-upload.class";
import { DialogManagerService } from "@app/results/dialog-manager.service";
import { HttpClient } from "@angular/common/http";
import { ImageDialogService } from "@shared/dialogs/image-dialog/image-dialog.service";

@Component({
  selector: "ease-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadComponent),
    },
  ],
})

export class FileUploadComponent implements ControlValueAccessor {

  @Input() public fileUploadData: FileUploadData = null;
  @Input() public buttonOnly = false;
  @Input() public displayLabel = true;
  @Input() public displayDropArea = true;
  @Input() public disabled: boolean = false;
  // NOTE: this output will be deprecated
  @Output() public fileUploadDataChange: EventEmitter<any> = new EventEmitter<any>();

  public hasDropZoneOver: boolean = false;

  constructor(private cd: ChangeDetectorRef,
              private http: HttpClient,
              private imageDialog: ImageDialogService) {}

  public onChange: any = () => {};
  public onTouched: any = () => {};

  public writeValue(data: FileUploadData) {
    if (data) {
      this.fileUploadData = data;
      this.cd.detectChanges();
    }
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  public fileOverBase(e: any): void {
    this.hasDropZoneOver = e;
    if (!this.hasDropZoneOver) {
      this.dataChanged();
      this.fileUploadDataChange.emit(true);
    }
  }

  public removeArtifact(artifact: IArtifact): void {
    artifact.FlaggedForDeletion = true;
    artifact.Url = "";
    this.dataChanged();
  }

  public removeFromQueue(item: FileItem): void {
    const index = this.fileUploadData.uploader.queue.indexOf(item);
    this.fileUploadData.filePreviewPaths.splice(index, 1);
    item.remove();
    this.dataChanged();
    this.fileUploadDataChange.emit(true);
  }

  public showUploadedArtifact(artifact): void {
    this.http.get(`${this.fileUploadData.getApiName()}/${artifact.ID}?thumbnail=true`)
      .subscribe((data: any) => {
        this.imageDialog.getArtifactImage(data.Url, artifact.FileFormatID);
    });
  }

  public showQueuedArtifact(url): void {
    this.imageDialog.getArtifactImage(url, 0);
  }

  private dataChanged(): void {
    this.fileUploadData.updateTotalArtifacts();
    this.writeValue(this.fileUploadData);
    this.onChange(this.fileUploadData);
  }
}
