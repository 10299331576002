import { SessionActionsUnion, SessionActionTypes } from "./../actions/session";
import { AuthActionsUnion, AuthActionTypes } from "./../actions/auth";

export interface State {
  ready: boolean;
  loading: number;
  translates: any[] | null;
  customTranslates: any[] | null;
  languageCode: string | null;
}

export const initialState: State = {
  ready: false,
  loading: 0,
  translates: null,
  customTranslates: null,
  languageCode: null,
};

export function reducer(state = initialState, action: SessionActionsUnion | AuthActionsUnion): State {
  switch (action.type) {
    case SessionActionTypes.RestoreSessionSuccess:
    case SessionActionTypes.RestoreSessionFailure:
    case AuthActionTypes.AuthorizeSuccess: {
      return {
        ...state,
        ready: true,
      };
    }

    case AuthActionTypes.Authorize: {
      return {
        ...state,
        ready: false,
      };
    }

    case SessionActionTypes.ChangeLanguage: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case SessionActionTypes.ChangeLanguageSuccess:
    case SessionActionTypes.ChangeLanguageFailure: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case SessionActionTypes.GetTranslation: {
      return {
        ...state,
        translates: null,
      };
    }

    case SessionActionTypes.GetTranslationSuccess: {
      return {
        ...state,
        translates: action.payload,
      };
    }

    case SessionActionTypes.GetCustomTranslation: {
      return {
        ...state,
        customTranslates: null,
      };
    }

    case SessionActionTypes.GetCustomTranslationSuccess: {
      return {
        ...state,
        customTranslates: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getReady = (state: State) => state.ready;
export const getLoading = (state: State) => state.loading;
export const getTranslates = (state: State) => state.translates;
export const getCustomTranslates = (state: State) => state.customTranslates;
